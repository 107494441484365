import { isPartnerMMT } from "@tm/insurance/utils";
import { FunnelVars, PageName } from "./IAnalytics";

const LOB_NAME_TM_INS = isPartnerMMT()
  ? "desktop tripmoney insurance"
  : "tripmoney insurance";

const LOB_NAME_PS_INS = isPartnerMMT()
  ? "desktop ps tripmoney insurance"
  : "ps tripmoney insurance";

const FUNNEL_SITE_SECTION = isPartnerMMT()
  ? "desktop tripmoney insurance Funnel"
  : "tripmoney insurance Funnel";

const LANDING_SITE_SECTION = "landing";

const PAGE_NAMES_MAP_MMT = {
  LANDING: "desktop:landing:mmt_insurance:homepage",
  LISTING: "desktop:funnel:mmt_insurance:listing",
  REVIEW: "desktop:funnel:mmt_insurance:review",
  PAYMENT: "desktop:funnel:mmt_insurance:payment",
  THANK_YOU_POLICY: "desktop:funnel:mmt_insurance:TY_policy",
  THANK_YOU_PASSPORT: "desktop:funnel:mmt_insurance:TY_passport",
  ADD_PASSPORT: "desktop:funnel:mmt_insurance:add_passport",
  ORDER_SUMMARY: "desktop:post_Sales:mmt_insurance:order_summary",
  ORDER_DETAILS: "desktop:post_Sales:mmt_insurance:order_details",
};

const PAGE_NAMES_MAP_GI = {
  LANDING: "landing:tripmoney insurance:landing",
  LISTING: "funnel:tripmoney insurance:listing",
  REVIEW: "funnel:tripmoney insurance:review",
  PAYMENT: "funnel:tripmoney insurance:payment",
  THANK_YOU_POLICY: "funnel:tripmoney insurance:thankyoupolicy",
  THANK_YOU_PASSPORT: "funnel:tripmoney insurance:thankyoupassport",
  ADD_PASSPORT: "funnel:tripmoney insurance:addpassport",
  ORDER_SUMMARY: "post sales:ps_tripmoney insurance:order_summary",
  ORDER_DETAILS: "post sales:ps_tripmoney insurance:order_details",
};

const PAGE_NAMES_MAP = isPartnerMMT() ? PAGE_NAMES_MAP_MMT : PAGE_NAMES_MAP_GI;

export const pageNameCategoryMap: Record<PageName, FunnelVars> = {
  LANDING: {
    pageName: PAGE_NAMES_MAP["LANDING"],
    lob: LOB_NAME_TM_INS,
    siteSection: LANDING_SITE_SECTION,
  },
  LISTING: {
    pageName: PAGE_NAMES_MAP["LISTING"],
    lob: LOB_NAME_TM_INS,
    siteSection: FUNNEL_SITE_SECTION,
  },
  REVIEW: {
    pageName: PAGE_NAMES_MAP["REVIEW"],
    lob: LOB_NAME_TM_INS,
    siteSection: FUNNEL_SITE_SECTION,
  },
  PAYMENT: {
    pageName: PAGE_NAMES_MAP["PAYMENT"],
    lob: LOB_NAME_TM_INS,
    siteSection: FUNNEL_SITE_SECTION,
  },
  THANK_YOU_POLICY: {
    pageName: PAGE_NAMES_MAP["THANK_YOU_POLICY"],
    lob: LOB_NAME_TM_INS,
    siteSection: FUNNEL_SITE_SECTION,
  },
  THANK_YOU_PASSPORT: {
    pageName: PAGE_NAMES_MAP["THANK_YOU_PASSPORT"],
    lob: LOB_NAME_TM_INS,
    siteSection: FUNNEL_SITE_SECTION,
  },
  ADD_PASSPORT: {
    pageName: PAGE_NAMES_MAP["ADD_PASSPORT"],
    lob: LOB_NAME_TM_INS,
    siteSection: FUNNEL_SITE_SECTION,
  },
  ORDER_DETAILS: {
    pageName: PAGE_NAMES_MAP["ORDER_DETAILS"],
    lob: LOB_NAME_PS_INS,
    siteSection: "NA",
  },
  ORDER_SUMMARY: {
    pageName: PAGE_NAMES_MAP["ORDER_SUMMARY"],
    lob: LOB_NAME_PS_INS,
    siteSection: "NA",
  },
};
