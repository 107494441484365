import styled from "styled-components";

export const NoInsurancePlanStyle = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
