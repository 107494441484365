import { FlexComp, SpanTag, ImageTag } from "@tm/static";
import AnchorTag from "@forex/Forex/TextStylesForex/AnchorTag";
import { ImageWpr } from "./AddOrSelectTicket.style";
import { theme } from "@tm/utility/global";

import RightTick from "libs/ui/src/lib/static/icons/RightTick.svg";
import Close from "libs/ui/src/lib/static/icons/Close.svg";
import PreviewEye from "@forex/Forex/Icon/PreviewEye";
import { SelectedFileProps } from "./type";

const SelectedFile = ({
  id,
  fileName,
  onCloseClick = () => {},
  documentLink,
  handlePreiewEyeClick,
  ticketData,
}: SelectedFileProps) => {
  return (
    <FlexComp
      padding="12px"
      bgColor={theme.blueText2}
      borderRadius="12px"
      margin="0 0 8px 0"
      alignItems="center"
      justifyContent="space-between"
    >
      <FlexComp flexDirection="row" alignItems="center" padding="0" margin="0">
        <ImageTag
          width="16px"
          height="16px"
          src={RightTick.src}
          margin="0 8px 0 0"
        />
        <SpanTag fontSize="xs">{fileName}</SpanTag>
      </FlexComp>
      <FlexComp flexDirection="row" alignItems="center" padding="0" margin="0">
        <AnchorTag
          hrefProp={documentLink(ticketData)}
          target="_blank"
          handleClick={handlePreiewEyeClick(ticketData)}
        >
          <PreviewEye />
        </AnchorTag>
        <ImageWpr onClick={() => onCloseClick(id)}>
          <ImageTag
            width="20px"
            height="20px"
            src={Close.src}
            margin="0 0 0 20px"
          />
        </ImageWpr>
      </FlexComp>
    </FlexComp>
  );
};

export default SelectedFile;
