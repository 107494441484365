import React from "react";

import { CliptextStyleComp } from "./TextStyles.style";

interface CliptextStyle {
  id?: string;
  children?: any;
  margin?: string;
}

const CliptextStyle: React.FC<CliptextStyle> = ({ children, margin, id }) => {
  return (
    <CliptextStyleComp margin={margin} id={id}>
      {children}
    </CliptextStyleComp>
  );
};

export default CliptextStyle;
