import { useDevice } from "libs/ui-widgets/src/lib/hooks/useDevice";
import { cardCtaProps } from "../../../mockData";
import { RightButtonContainer } from "../LandingCardFeature.style";
import AnchorTag from "../../ui-atoms/TextStyles/AnchorTag";
import ImageTag from "../../ui-atoms/ImageTag/ImageTag";
import { ArrowIcon } from "../../../icons/ArrowIcon";
import FlexComp from "../../ui-atoms/FlexComp/FlexComp";
import LandingButton from "../../ui-atoms/LandingButton/LandingButton";
import SpanTag from "../../ui-atoms/TextStyles/SpanTag";
import { useGlobalSelector } from "libs/ui-widgets/src/lib/hooks/useGlobalSelector";
import { ListingReducerType } from "libs/ui-widgets/src/lib/store/forexListingReducer";

type CardCtaPersuasionProps = {
  leftBtnClickHandler?: (e: any) => void;
  rightBtnClickHandler?: (e: any) => void;
  cardData: cardCtaProps;
  id?: string;
  arrowIconSrc?: string;
  bestRateApplicableTxt?: string;
};

export default function CardCtaPersuasion({
  rightBtnClickHandler,
  leftBtnClickHandler,
  cardData,
  id,
  arrowIconSrc,
  bestRateApplicableTxt,
}: CardCtaPersuasionProps) {
  const device = useDevice();
  const { experiments } = useGlobalSelector(
    (state: ListingReducerType) => state || {}
  );
  const isMiniDashboardSwitchBtnExpEnabled =
    experiments?.swap_mini_dashboard_btns?.enabled;

  const arrowIcon = arrowIconSrc ? (
    <ImageTag src={arrowIconSrc} />
  ) : (
    <ArrowIcon />
  );

  const rightClickBtnHandlerWrapper = (
    e: Event | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    rightBtnClickHandler && rightBtnClickHandler(e);
  };

  const leftClickBtnHandlerWrapper = (
    event: Event | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    leftBtnClickHandler && leftBtnClickHandler(event);
  };

  function renderRightButtonMiniDashboard() {
    if (!cardData?.linkBtnInMobile) {
      return <></>;
    }

    // Render load currency button if props coming.
    if (!!isMiniDashboardSwitchBtnExpEnabled && !!cardData?.addText) {
      return (
        <LandingButton
          id="ListingPage_load_currency_btn"
          margin="0px 0px 0px auto"
          onClick={leftClickBtnHandlerWrapper}
          fontSize="12px"
          customHeight="32px"
          customTitle={cardData?.addText}
        />
      );
    }
    return cardData?.isPrimaryButton ? (
      <LandingButton
        customTitle={cardData?.linkBtnInMobile?.label}
        margin="0px 0px 0px auto"
        onClick={rightClickBtnHandlerWrapper}
        fontSize="12px"
        customHeight="32px"
        id={id}
      />
    ) : (
      <AnchorTag
        id={id}
        width="fit-content"
        fontSize={"xs"}
        fontWeight="bold"
        margin={"0px 0 0 auto"}
        handleClick={rightClickBtnHandlerWrapper}
      >
        {cardData?.linkBtnInMobile?.label}
      </AnchorTag>
    );
  }

  if (device === "lg") {
    return (
      <RightButtonContainer onClick={rightClickBtnHandlerWrapper}>
        <AnchorTag id={id} width="fit-content" fontSize="sm" fontWeight="bold">
          {cardData?.linkBtnInMobile?.label}
        </AnchorTag>

        {arrowIcon}
      </RightButtonContainer>
    );
  }

  return (
    <FlexComp
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      padding="16px 0px 0px"
    >
      {/* Show View Dashboard when experiment is enabled in the left side of card */}
      {!!isMiniDashboardSwitchBtnExpEnabled && !!cardData?.addText && (
        <AnchorTag
          id={id}
          width="fit-content"
          fontSize={"xs"}
          fontWeight="bold"
          margin={"0px"}
          handleClick={rightClickBtnHandlerWrapper}
        >
          {cardData?.linkBtnInMobile?.label}
        </AnchorTag>
      )}
      {/* Show load currency when experiment is disabled in the left side of the card and addText is true */}
      {!isMiniDashboardSwitchBtnExpEnabled && !!cardData?.addText && (
        <AnchorTag
          id={"ListingPage_load_currency_btn"}
          width="fit-content"
          fontSize={"xs"}
          fontWeight="bold"
          margin={"0px"}
          handleClick={leftClickBtnHandlerWrapper}
        >
          {cardData.addText}
        </AnchorTag>
      )}
      {device === "sm" && bestRateApplicableTxt && (
        <FlexComp
          bgColor="linear-gradient(90deg, #ECEAF8 0%, rgba(255, 255, 255, 0) 93.75%)"
          padding="4px"
          margin="0 0 0 -16px"
        >
          <SpanTag color="#3A27B0" fontSize="xs">
            {bestRateApplicableTxt}
          </SpanTag>
        </FlexComp>
      )}
      {renderRightButtonMiniDashboard()}
    </FlexComp>
  );
}
