import React from "react";

import { FlexCompStyle } from "./FlexComp.style";

interface flexInterface {
  children?: React.ReactNode;
  flexDirection?: string;
  alignItems?: string;
  alignSelf?: string;
  justifyContent?: string;
  gap?: string;
  margin?: string;
  borderBottom?: string;
  borderTop?: string;
  padding?: string;
  flex?: string;
  bgColor?: string;
  borderRadius?: string;
  height?: string;
  flexShrink?: string;
  handleClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  width?: string;
  boxShadow?: string;
  isCursorPointer?: boolean;
  flexWrap?: "wrap" | "no-wrap";
  id?: string;
}

const FlexComp: React.FC<flexInterface> = ({
  children,
  flexDirection,
  alignItems,
  justifyContent,
  gap,
  margin,
  borderBottom,
  borderTop,
  padding,
  flex,
  bgColor,
  borderRadius,
  height,
  flexShrink,
  handleClick,
  boxShadow,
  width,
  isCursorPointer = false,
  flexWrap,
  alignSelf,
  id,
}) => {
  return (
    <FlexCompStyle
      id={id}
      flexDirection={flexDirection}
      alignItems={alignItems}
      justifyContent={justifyContent}
      isCursorPointer={isCursorPointer ? true : handleClick ? true : false}
      gap={gap}
      margin={margin}
      borderBottom={borderBottom}
      borderTop={borderTop}
      padding={padding}
      flex={flex}
      bgColor={bgColor}
      borderRadius={borderRadius}
      height={height}
      flexShrink={flexShrink}
      width={width}
      boxShadow={boxShadow}
      onClick={handleClick}
      flexWrap={flexWrap}
      alignSelf={alignSelf}
    >
      {children}
    </FlexCompStyle>
  );
};

export default FlexComp;
