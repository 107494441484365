import { ROUTE_CONSTANTS } from "./routeConstants";
import { INavigationActions, IRouter } from "./types";

let router: IRouter;

export const setRouter = (routerInstance: any) => {
  if (!routerInstance) {
    throw new Error(
      "Couldn't set router,routerInstance object is null or undefined"
    );
  }
  router = routerInstance;
};

function getURLFromRouteAndParams(route: string, params?: any) {
  const searchParams = new URLSearchParams(params).toString();
  const queryJoin = searchParams.length > 0 ? "?" : "";
  return `${route}${queryJoin}${searchParams}`;
}

function forceRedirectOnWwwSubdomain(pathName: string) {
  if (typeof location === "undefined") return;
  const host = new URL(location.href).host;
  switch (host) {
    case "insurance.makemytrip.com":
      location.href = `https://www.makemytrip.com${pathName}`;
      break;
    case "insurance.goibibo.com":
      location.href = `https://www.goibibo.com${pathName}`;
      break;
    default:
      router.push(pathName);
  }
}

function forceRedirectOnInsuranceSubdomain(pathName: string) {
  if (typeof location === "undefined") return;
  const host = new URL(location.href).host;
  switch (host) {
    case "www.makemytrip.com":
      location.href = `https://insurance.makemytrip.com${pathName}`;
      break;
    case "www.goibibo.com":
      location.href = `https://insurance.goibibo.com${pathName}`;
      break;
    default:
      router.push(pathName);
  }
}

function injectCmpInParams(params?: any) {
  const cmpVal = sessionStorage.getItem("cmp");
  if (typeof cmpVal === "string" && cmpVal != "") {
    params = {
      ...params,
      cmp: cmpVal,
    };
  }
  return params;
}

export const navigationActions: INavigationActions = {
  navigateToLanding: (
    params?: any,
    shouldForceRedirectOnWwwSubdomain = true
  ) => {
    params = injectCmpInParams(params);
    const pathName = getURLFromRouteAndParams(
      ROUTE_CONSTANTS.LANDING_PAGE,
      params
    );
    shouldForceRedirectOnWwwSubdomain
      ? forceRedirectOnWwwSubdomain(pathName)
      : router.push(pathName);
  },
  navigateToListing: (params?: any) => {
    params = injectCmpInParams(params);
    forceRedirectOnInsuranceSubdomain(
      getURLFromRouteAndParams(ROUTE_CONSTANTS.LISTING_SCREEN, params)
    );
  },
  navigateToTravellerDetails: (params?: any) => {
    params = injectCmpInParams(params);
    forceRedirectOnInsuranceSubdomain(
      getURLFromRouteAndParams(ROUTE_CONSTANTS.TRAVELLER_DETAILS, params)
    );
  },
  navigateToAddPassport: (params?: any) => {
    params = injectCmpInParams(params);
    forceRedirectOnInsuranceSubdomain(
      getURLFromRouteAndParams(ROUTE_CONSTANTS.ADD_PASSPORT, params)
    );
  },
  navigateToOrderConfirmed: (params?: any) => {
    params = injectCmpInParams(params);
    forceRedirectOnInsuranceSubdomain(
      getURLFromRouteAndParams(ROUTE_CONSTANTS.ORDER_CONFIRMED, params)
    );
  },
  navigateToOrderListing: (params?: any) => {
    params = injectCmpInParams(params);
    forceRedirectOnInsuranceSubdomain(
      getURLFromRouteAndParams(ROUTE_CONSTANTS.ORDER_LISTING, params)
    );
  },
  navigateToOrderDetails: (params?: any) => {
    params = injectCmpInParams(params);
    forceRedirectOnInsuranceSubdomain(
      getURLFromRouteAndParams(ROUTE_CONSTANTS.ORDER_DETAILS, params)
    );
  },
  navigateToDownloadPolicy: (params?: any) => {
    params = injectCmpInParams(params);
    forceRedirectOnInsuranceSubdomain(
      getURLFromRouteAndParams(ROUTE_CONSTANTS.DOWNLOAD_POLICY, params)
    );
  },
  goBack: ({ forceExit }: { forceExit?: boolean } = {}) => {
    if (forceExit) {
      window.history.go(-(window.history.length - 2));
    } else {
      router.back();
    }
  },
  navigateToPageByRouteName: (route: string, params?: any) => {
    params = injectCmpInParams(params);
    forceRedirectOnInsuranceSubdomain(getURLFromRouteAndParams(route, params));
  },
};
