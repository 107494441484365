import { useEffect, useMemo } from "react";

import { useGlobalSelector } from "../useGlobalSelector";
import { useDevice } from "../useDevice";

import {
  getDeliveryDetailsContent,
  getMobileTitle,
  getTickerData,
  prepareForexCardsForDesktop,
  prepareForexCardsV3,
  prepareNonPinnedForexCardsMobile,
  preparePinnedForexCardsMobile,
} from "../../utils/componentPropsMapper";
import {
  CARD_TYPE,
  COOKIES,
  GI_SUB_DOMAIN,
  HOST,
  MMT_SUB_DOMAIN,
  USER_TYPE,
  VERSIONS,
} from "../../constants";
import { ListingReducerType } from "../../store/forexListingReducer";
import { getHostName, persistSpecificParamInCookie } from "../../utils";
import { ForexProducts, PersonaType, ProductType } from "../../types";

const useForexWidgetProps = () => {
  const device = useDevice();
  const {
    forex_rates,
    persona,
    products = [],
    delivery_details,
    isErrorDeliveryDetails,
    version,
  } = useGlobalSelector((state: ListingReducerType) => state);

  const deliveryDetailsContent: {
    ctaText: string;
    text: string;
    checkPincode: boolean;
    checkPincodeLabel: string;
  } | null = useMemo(() => {
    const host = getHostName();
    if (host !== HOST.MAKEMYTRIP) {
      return getDeliveryDetailsContent(
        { ...delivery_details },
        isErrorDeliveryDetails,
        version
      );
    } else {
      return null;
    }
  }, [delivery_details, isErrorDeliveryDetails, version]);

  const deliveryDetailsContentV3: {
    ctaText: string;
    text: string;
    checkPincode: boolean;
    checkPincodeLabel: string;
  } | null = useMemo(() => {
    const host = getHostName();
    if (host !== HOST.MAKEMYTRIP) {
      return getDeliveryDetailsContent(
        { ...delivery_details },
        isErrorDeliveryDetails,
        VERSIONS.V2
      );
    } else {
      return null;
    }
  }, [delivery_details, isErrorDeliveryDetails, version]);

  useEffect(() => {
    if (products?.length) {
      persistSpecificParamInCookie(
        COOKIES.CURRENCY_DEEPLOGIN_ALLOWED,
        String(true),
        { path: "/", domain: MMT_SUB_DOMAIN }
      );
      persistSpecificParamInCookie(
        COOKIES.CURRENCY_DEEPLOGIN_ALLOWED,
        String(true),
        { path: "/", domain: GI_SUB_DOMAIN }
      );
    }
  }, [products]);
  // Ticker data preparation.
  const tickerData = useMemo(() => getTickerData(forex_rates), [forex_rates]);

  const prepareCards = (
    products: ForexProducts,
    device: string | null,
    version: number | null | undefined,
    persona: PersonaType | null | undefined
  ) => {
    // TODO - will remove after migration to v3 is done
    const pinnedCards = preparePinnedForexCardsMobile(
      products,
      device,
      version
    );
    const [pinnedCardsV2, cardsV2] = prepareForexCardsV3(
      products,
      device,
      version,
      persona
    );
    const cards =
      device === "lg"
        ? prepareForexCardsForDesktop(products, device, version)
        : prepareNonPinnedForexCardsMobile(products, device, version);
    return { pinnedCards, pinnedCardsV2, cards, cardsV2 };
  };

  const { pinnedCards, pinnedCardsV2, cards, cardsV2 } = useMemo(
    () => prepareCards(products, device, version, persona),
    [products, device, version, persona]
  );
  const versionHandlers = {
    [VERSIONS.V3]: {
      landingPinnedCardShowcaseData: pinnedCardsV2,
      landingCardShowcaseData: cardsV2,
    },
    [VERSIONS.V2]: {
      landingPinnedCardShowcaseData: pinnedCards,
      landingCardShowcaseData: cards,
    },
    default: {
      landingPinnedCardShowcaseData: pinnedCards,
      landingCardShowcaseData: cards,
    },
  };
  const { landingPinnedCardShowcaseData, landingCardShowcaseData } =
    versionHandlers[version || VERSIONS.V2];

  const mobileTitle = getMobileTitle(persona, device);

  return {
    mobileTitle,
    showTickerAtBottomInMobile: false,
    landingPinnedCardShowcaseData,
    landingCardShowcaseData,
    deliveryDetailsContent,
    version: version || VERSIONS.V2,
    deliveryDetailsContentV3,
  };
};

export default useForexWidgetProps;
