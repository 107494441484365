import { CurrencySEOMarkdown } from "../CurrencySEOMarkdown";

export default function HowToConvert({ currencyData }: any) {
  return (
    <>
      <div className="separator"></div>
      <h2 className="h2">{currencyData?.how_to_convert?.title}</h2>
      <CurrencySEOMarkdown className="description mt10">
        {currencyData?.how_to_convert?.sub_title}
      </CurrencySEOMarkdown>
      <div className="convertCurrency">
        <ul className="ccList">
          {currencyData?.how_to_convert?.list?.map(
            (item: any, index: number) => (
              <li className="ccList__item" key={index}>
                <h4 className="heading">{item?.title}</h4>
                <CurrencySEOMarkdown className="desc">
                  {item?.desc}
                </CurrencySEOMarkdown>
              </li>
            )
          )}
        </ul>
      </div>
    </>
  );
}
