import { trackSeoPageLoad } from "@tm/utility/analytics";
import { PRODUCT_TYPE, SEO_REDIRECTIONS } from "../../static/constants";
import { addUtmParamsToUrl, checkIfVaildCardHolder } from "../../static/utils";
import { ForexProductsStyle } from "./ForexProducts.styles";

export default function ForexProduct({
  currencyData,
  pageName,
  templateType = "",
}: any) {
  const handleRedirection = async (index: number) => {
    if (index == 0) {
      trackSeoPageLoad({
        pageName: pageName,
        cardClicked: "Forex_card",
      });
      const isVaildCardHolder = await checkIfVaildCardHolder();
      if (isVaildCardHolder) {
        window.open(
          `${SEO_REDIRECTIONS?.CARD_LOAD}${addUtmParamsToUrl(
            PRODUCT_TYPE.CARD,
            templateType
          )}`
        );
      } else {
        window.open(
          `${SEO_REDIRECTIONS?.CARD_BUY}${addUtmParamsToUrl(
            PRODUCT_TYPE.CARD,
            templateType
          )}`
        );
      }
    } else {
      trackSeoPageLoad({
        pageName: pageName,
        cardClicked: "Forex_currency_notes",
      });
      window.open(
        `${SEO_REDIRECTIONS?.CURRENCY_BUY}${addUtmParamsToUrl(
          PRODUCT_TYPE.CURRENCY,
          templateType
        )}`
      );
    }
  };

  return (
    <>
      <ForexProductsStyle />
      <div className="getForex">
        <p className="getForex__title">
          <span className="big">Get Forex products at your doorstep</span>{" "}
          <span className="small">we offer</span>{" "}
          <span className="medium">same day delivery</span>{" "}
          <span className="small">in 185+ indian cities</span>
        </p>
        <ul className="getForex__list">
          {currencyData?.forex_products?.map((product: any, index: number) => (
            <li
              className="getForex__listItem"
              key={index}
              onClick={() => handleRedirection(index)}
            >
              <span className="getForex__listItemImg">
                <img
                  src={product?.img}
                  alt="multi currency"
                  width="140"
                  height="94"
                />
              </span>
              <div className="getForex__listItemDesc">
                <h3 className="title">
                  {product?.title}
                  <span className="arrow"></span>
                </h3>
                <p className="desc">
                  {product?.sub_title?.map((desc: string, ind: number) => (
                    <span key={ind}>{desc}</span>
                  ))}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
