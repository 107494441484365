import styled from "styled-components";

export const WeekDayWrap = styled.div`
  background-color: #fff;
  padding: 2px 0;
  position: sticky;
  top: 49px;
  z-index: 1;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  span {
    font-size: 12px;
    font-weight: 400;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
