import DeliveryInProgressIconSvg from "../../icons/delInProgress.svg";
import OrderUnavailable from "../../icons/orderUnavaiable.svg";
import OrderCancelled from "../../icons/orderCancelled.svg";
import OrderSuccess from "../../icons/orderSuccess.svg";
import StatusTrack from "./StatusTrack";

type IconType =
  | "delivery-in-progress"
  | "order-unavailable"
  | "order-cancelled"
  | "order-success"
  | "activation-done";

interface StatusInterFace {
  statusIcon?: any;
  statusTitle?: string;
  statusInfo?: string;
  statusCta?: string;
  handleClick?: () => void;
  crdStatus?: "SUCCESS" | "CANCELLED" | "INPROGRESS" | "UNAVAILABLE";
  type: IconType;
}

function getIcon(type: IconType) {
  switch (type) {
    case "delivery-in-progress":
      return DeliveryInProgressIconSvg.src;
    case "order-unavailable":
      return OrderUnavailable.src;
    case "order-cancelled":
      return OrderCancelled.src;
    case "order-success":
      return OrderSuccess.src;
    case "activation-done":
      return "https://tripmoneycmsimgak.mmtcdn.com/img/green_Oval_8ec7190974.svg";
    default:
      return null;
  }
}

export default function StatusTrackContainer(props: StatusInterFace) {
  const icon = getIcon(props.type);
  if (!icon) {
    return <></>;
  }

  return <StatusTrack {...props} statusIcon={icon} />;
}
