import React, { useEffect } from "react";

import { theme } from "@tm/utility/global";

import {
  CommonModalDweb,
  FlexComp,
  LandingButton,
  SpanTag,
  InsPlanModalCommonInfo,
  BenefitItem,
  FormattedText,
} from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { getAnalytics } from "@tm/insurance/analytics";

import { IMoreBenfitsModal } from "./type";
import { AmountWrap } from "./InsurancePlans.style";

const analytics = getAnalytics();

const MoreBenefits = ({
  moreBenfitsTitle,
  data,
  tag,
  handleClose,
  handleBuyButton,
  isAddOnBenefits,
  customBtnTitle,
}: IMoreBenfitsModal) => {
  const device = useBreakpointV2();

  useEffect(() => {
    const productType: "insurance" | "assistance" | "combo" =
      // @ts-ignore
      data?.packName?.text?.value?.toLowerCase?.();
    analytics?.trackListingEvents({
      // @ts-ignore
      actionType: `details_pop_shown_${productType}`,
    });
  }, []);

  return (
    <CommonModalDweb handleClick={handleClose} customTitle={moreBenfitsTitle}>
      <InsPlanModalCommonInfo
        data={data}
        isAddOnBenefits={isAddOnBenefits}
        tag={tag}
      />
      <FlexComp flexDirection="column" gap="24px" margin="0 0 20px">
        {data?.moreBenefits &&
          Object.keys(data.moreBenefits)?.map((item, index: number) => {
            return (
              <BenefitItem
                key={`${index}`}
                isAddOnBenefits={isAddOnBenefits}
                data={data.moreBenefits?.[item]}
                benefitName={item}
              />
            );
          })}
      </FlexComp>
      {isAddOnBenefits && (
        <AmountWrap boxShadow="0">
          {data?.buyBtn && (
            <LandingButton
              customWidth={"-webkit-fill-available"}
              customTitle={customBtnTitle}
              onClick={() => handleBuyButton?.(data)}
            />
          )}
        </AmountWrap>
      )}

      {data?.insAmount && (
        <AmountWrap>
          <FlexComp gap="0" flexDirection="column">
            <SpanTag fontSize="md" fontWeight="bold" color={theme.blackText}>
              <FormattedText value={data?.insAmount} />
            </SpanTag>
            <SpanTag fontSize="xs" color={theme.greyText}>
              <FormattedText value={data?.paxPerDay} />
            </SpanTag>
          </FlexComp>

          {data?.buyBtn && (
            <LandingButton
              customWidth={device == "sm" ? "158px" : "unset"}
              customTitle={data.buyBtn}
              onClick={() => handleBuyButton?.(data)}
            />
          )}
        </AmountWrap>
      )}
    </CommonModalDweb>
  );
};

export default MoreBenefits;
