import styled from "styled-components";
import { deviceType } from "@tm/static/utils";
import {
  AnchorTagStyle,
  ParaTagStyle,
  SpanTagStyle,
} from "../TextStyles/TextStyles.style";
import { CheckButtonStyle } from "../CheckButton/CheckButton.style";
import { theme } from "@tm/utility/global";

export interface InsurancePlanStyleProp {
  chevOpen?: boolean;
}

export interface IVendorWrapProps {
  margin?: string;
}

export interface IAmountWrapProps {
  boxShadow?: string;
}

export const InsurancePlansStyle = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 30px;
  picture {
    display: inherit;
  }
  @media ${deviceType.mobile} {
    padding: 24px 16px;
    border-radius: 16px 16px 0 0;
  }
`;

export const InsurancePlansCompStyle = styled.div`
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
`;

export const PlanHeader = styled.div`
  background: #f6f6f6;
  border-radius: 8px 8px 0px 0px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  ${CheckButtonStyle} {
    flex-direction: row-reverse;
    gap: 10px;
  }
`;

export const InsurancePlanSection = styled.div`
  background: #fff;
  padding: 16px;
`;

export const InsuranceTypeSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex-shrink: 0;
  width: 54%;
  @media ${deviceType.mobile} {
    flex-shrink: unset;
    width: 80%;
  }
`;

export const InsurancePlanTypeHd = styled.div`
  background: linear-gradient(
      0deg,
      rgba(0, 140, 255, 0.04),
      rgba(0, 140, 255, 0.04)
    ),
    #f6f8ff;
  border-radius: 8px 8px 0px 0px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${AnchorTagStyle} {
    padding-bottom: 10px;
    margin-bottom: -10px;
  }
  @media ${deviceType.mobile} {
    padding: 12px 8px;
  }
`;

export const InsurancePlanTypeSection = styled.div`
  background: linear-gradient(
    149.61deg,
    rgba(239, 255, 254, 0.5) 0.83%,
    rgba(230, 237, 255, 0.368088) 26.25%,
    rgba(234, 246, 255, 0.5) 97.19%
  );
  border-radius: 0 0 8px 8px;
  padding: 12px 16px;
`;

export const ActivityStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 12px;
  border-top: 1px solid #e2e2e2;
  margin-top: 12px;
`;

export const TravelAssistanceSection = styled.div`
  background: rgba(228, 251, 244, 0.5);
  border-radius: 4px;
  padding: 8px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PopularTag = styled.div<InsurancePlanStyleProp>`
  background: linear-gradient(87.29deg, #c86dd7 0%, #3023ae 100%);
  border-radius: 0px 16px 16px 0px;
  padding: 1px 8px 2px;
  margin-left: -16px;
  flex-shrink: 0;
  @media ${deviceType.mobile} {
    border-radius: 16px;
    margin-left: 0;
  }
`;

export const AmountWrap = styled.div<IAmountWrapProps>`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: ${(p) =>
    p.boxShadow ? p.boxShadow : "0px 1px 7px rgba(0, 0, 0, 0.2)"};
  padding: 16px 30px;
  position: sticky;
  bottom: -20px;
  margin: auto -30px -20px;
  @media ${deviceType.mobile} {
    padding: 8px 20px;
  }
`;

export const EmptyBorder = styled.div`
  width: 100%;
  height: 1px;
  background: #e2e2e2;
`;

export const GreyChevWrap = styled.div<InsurancePlanStyleProp>`
  cursor: pointer;
  flex-shrink: 0;
  transition: 0.5s ease-in;
  transform-origin: center;
  width: 32px;
  height: 32px;
  display: flex;

  ${(p) =>
    p.chevOpen
      ? `
    transform:rotate(180deg);
   
    `
      : `
    transform:rotate(0deg);
   
    `}
`;

export const BenefitName = styled(SpanTagStyle)`
  flex-shrink: 0;
  text-transform: uppercase;
  @media ${deviceType.mobile} {
    font-size: 12px;
    font-weight: 600;
    color: ${theme.greyText6};
  }
`;

export const BenefitPrice = styled(SpanTagStyle)`
  flex-shrink: 0;
`;

export const TravAssistWraning = styled.div`
  background: #ffedd1;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  svg {
    flex-shrink: 0;
  }
`;

export const PlanSectionItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  gap: 30px;
  ${ParaTagStyle} {
    width: "max-content";
  }
  @media ${deviceType.mobile} {
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
  }
`;

export const VendorWrap = styled.div<IVendorWrapProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  ${(p) => p.margin && `margin:${p.margin};`}
  img {
    border-radius: 4px;
  }
`;

export const TagWrap = styled.div`
  display: flex;
  background: linear-gradient(88.44deg, #12714f 0.02%, #1ca099 100.01%);
  padding: 2px 8px 2px 8px;
  border-radius: 16px;
  height: 20px;
  color: #ffffff;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  width: fit-content;
  margin: 0px 0px 10px 0px;
`;
