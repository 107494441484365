import styled from "styled-components";

export const UserCont = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-right: 30px;
  cursor: pointer;
  ::after {
    display: inline-flex;
    border: solid white;
    border-width: 0 2px 2px 0;
    padding: 3px;
    content: "";
    transform: rotate(45deg);
    position: absolute;
    right: 0;
    top: 6px;
  }
`;
export const UserIcon = styled.span`
  width: 21px;
  height: 21px;
  background: linear-gradient(222.16deg, #ff684a 5.2%, #ff4959 96.35%);
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 12px;
  font-weight: 900;
  color: #fff;
`;
