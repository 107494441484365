import Head from "next/head";

const CurrencyDocumet = ({ currency, hasHeroImage, id }: any) => {
  return (
    <Head>
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {currency?.allow_indexing ? null : <meta name="robots" content="none" />}
      <meta name="description" content={currency?.meta_desc} />
      <meta name="keywords" content={currency?.keywords} />
      <link
        rel="preload"
        href="https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjx4wXiWtFCc.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="canonical"
        href={`https://www.makemytrip.com/tripmoney/currency/${id}`}
      />
      {hasHeroImage ? (
        <link
          rel="preload"
          as="image"
          href={currency?.forex_solution?.forex_sol?.src}
        />
      ) : null}
      <title>{currency?.title}</title>
    </Head>
  );
};

export default CurrencyDocumet;
