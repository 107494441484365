import RegionModalDweb from "../RegionModalDweb/RegionModalDweb";
import FormattedText from "../FormattedText/FormattedText";
import { PopularDescriptionWrap } from "./DescriptionModal.style";
import { SpanTag } from "@tm/static";
import { theme } from "@tm/utility/global";
interface ModalInterface {
  value?: string;
  handleDescriptionModal?: any;
  title?: string;
}
const DescriptionModal = ({
  handleDescriptionModal,
  value,
  title,
}: ModalInterface) => {
  return (
    <RegionModalDweb handleClick={handleDescriptionModal} customTitle={title}>
      <PopularDescriptionWrap>
        <FormattedText
          value={"Countries Covered: "}
          baseStyle={{
            fontWeight: "bold",
            color: theme.greyText,
            fontSize: "xs",
          }}
        />
        <FormattedText value={value} />
      </PopularDescriptionWrap>
    </RegionModalDweb>
  );
};

export default DescriptionModal;
