import React, { ReactNode } from "react";

import { PaymentStatusCompStyle, Confetti } from "./PaymentStatusComp.style";
import { SpanTag, ParaTag, ImageTag } from "@tm/static";
import { theme } from "@tm/utility/global";

interface PaymentStatusProp {
  title?: string;
  infoTxt?: string;
  subInfoTxt?: string;
  imageIcon?: ReactNode;
}

const PaymentStatusComp = ({
  title,
  infoTxt,
  subInfoTxt,
  imageIcon,
}: PaymentStatusProp) => {
  return (
    <PaymentStatusCompStyle>
      {imageIcon && <Confetti>{imageIcon}</Confetti>}
      {title && (
        <ParaTag
          fontSize="large"
          fontWeight="bold"
          margin="0 0 8px"
          color={theme.blackText}
        >
          {title}
        </ParaTag>
      )}
      {infoTxt && (
        <SpanTag margin="0 0 2px" fontSize="xs" color={theme.greyText}>
          {infoTxt}
        </SpanTag>
      )}
      {subInfoTxt && (
        <SpanTag fontSize="xs" color={theme.greyText}>
          {subInfoTxt}
        </SpanTag>
      )}
    </PaymentStatusCompStyle>
  );
};

export default PaymentStatusComp;
