import { CurrencySEOMarkdown } from "../CurrencySEOMarkdown";

export default function Introduction({ currencyData }: any) {
  return (
    <div>
      <CurrencySEOMarkdown className="planTrip">
        {currencyData?.introduction?.para}
      </CurrencySEOMarkdown>
    </div>
  );
}
