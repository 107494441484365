import styled from "styled-components";

type ImageProps = {
  width?: string;
  margin?: string;
  showCursorPointer?: boolean;
};

export const ImageTagStyle = styled.img<ImageProps>`
  display: inline-block;
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  ${(props) => props.showCursorPointer && ` cursor: pointer;`}
  flex-shrink:0;
`;
