/**
 * Custom React hook for handling authentication.
 *
 * @returns An object with the following properties:
 * - authenticationProcessCompleted: A boolean value indicating whether the authentication process has completed.
 */
import { useEffect, useState } from "react";
import { AuthFactory } from "../services/AuthFactory";

export const useAuthentication = () => {
  const [isAuthenticated, updateIsAuthenticated] = useState<Boolean | null>(
    null
  );

  useEffect(() => {
    const authService = new AuthFactory();
    (async () => {
      try {
        const authenticated = await authService.authenticateUser();
        updateIsAuthenticated(authenticated);
      } catch (error) {
        console.error(error);
        updateIsAuthenticated(false);
      }
    })();
  }, []);

  return {
    authenticationProcessCompleted: isAuthenticated !== null,
  };
};
