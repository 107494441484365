import { ArrowIcon } from "@tm/static/icons";
import { useBreakpoint } from "@tm/ui/hooks";
import { Fragment } from "react";
import { theme } from "@tm/utility/global";

import {
  MarqueeBanner,
  IconTextTag,
  ImageTag,
  CliptextStyle,
  DeliveryPersuasion,
  FlexComp,
  H4Tag,
  SpanTag,
  ParaTag,
  LandingCardFeature,
} from "@tm/static";

import {
  BMFListingContainer,
  LandingCardFeatureCtr,
  ListingTopContainer,
  MarkerContainer,
  SpecialAttention,
} from "./ListingPageComponents.style";

import {
  landingCardShowcaseDataProps,
  landingIconTextTagDataProps,
  landingMarqueeDataProps,
} from "@tm/static/data";
import ListingPageHeader, { ListingPageHeaderProps } from "./ListingPageHeader";

interface pinnedAndShowcaseCrdFtrDataProps {
  data: landingCardShowcaseDataProps[];
  onClick?: (item: landingCardShowcaseDataProps, idx?: number) => void;
  loadCurrenyBtnHandler: (data: landingCardShowcaseDataProps) => void;
  recommendedBtnHandler: (data: landingCardShowcaseDataProps) => void;
  startFromWhereLeftHandler: (data: landingCardShowcaseDataProps) => void;
  docsPendingHandler: (data: landingCardShowcaseDataProps) => void;
}

interface TopShowcaseProps {
  isMarqueeOnBottom?: boolean;
  sectionBgImgURI?: string;
  otherProductsText?: string;
  iconTextTag?: {
    label: string;
    subLabel?: string;
    btnHandler: () => void;
  };
  deliveryPersuasion?: {
    text: string;
    editLocationHandler: () => void;
    upArrow?: string;
    checkPincode: false;
    checkPincodeLabel: string;
    checkPincodeHandler: () => void;
    arrowIcon?: string;
    ctaText?: string;
    defaultIconComp?: React.ReactNode;
  };
  landingTitle?: {
    textOne?: string;
    textTwo?: string;
    textThree?: string;
    textFour?: string;
  };
  arrowIcon?: string;
  pinnedShowcaseCardData?: pinnedAndShowcaseCrdFtrDataProps;
  otherShowcaseCardsData?: pinnedAndShowcaseCrdFtrDataProps;
  marqueeData?: {
    leftTitle: string;
    data: landingMarqueeDataProps[];
  };
  mobileStickyHeadingData?: ListingPageHeaderProps;
  mobileTitle?: string;
  isDeliveryPersuasionInsideSctn?: boolean;
}

const TopShowcase = ({
  sectionBgImgURI,
  otherProductsText,
  iconTextTag,
  deliveryPersuasion,
  landingTitle,
  marqueeData,
  otherShowcaseCardsData,
  pinnedShowcaseCardData,
  isMarqueeOnBottom = false,
  mobileStickyHeadingData,
  mobileTitle,
  isDeliveryPersuasionInsideSctn,
}: TopShowcaseProps) => {
  const device = useBreakpoint();

  return (
    <ListingTopContainer bgImage={sectionBgImgURI}>
      {device === "lg" ? (
        // ONLY IN DESKTOP
        <Fragment>
          <BMFListingContainer>
            <FlexComp flexDirection="column" padding="24px">
              <FlexComp
                gap="6px"
                padding="0px"
                alignItems="center"
                justifyContent="center"
                margin="0px 0px 24px 0px"
              >
                {landingTitle?.textOne && (
                  <ParaTag fontSize="xxlarge">{landingTitle.textOne}</ParaTag>
                )}
                {landingTitle?.textTwo &&
                  landingTitle?.textThree &&
                  landingTitle?.textFour && (
                    <ParaTag
                      margin="4px 0px 0px"
                      color={theme.greyText}
                      fontSize="large"
                    >
                      {landingTitle.textTwo}{" "}
                      <SpanTag
                        color={theme.greenText}
                        fontSize="large"
                        fontWeight="bold"
                      >
                        {landingTitle.textThree}
                      </SpanTag>{" "}
                      {landingTitle.textFour}{" "}
                    </ParaTag>
                  )}
              </FlexComp>
              {otherShowcaseCardsData && (
                <LandingCardFeatureCtr>
                  {otherShowcaseCardsData.data.map((item, index) => {
                    return (
                      <LandingCardFeature
                        data={item}
                        key={index}
                        idx={index}
                        padding="16px"
                        border="1px #e2e2e2 solid"
                        onClick={otherShowcaseCardsData.onClick}
                        loadCurrenyBtnHandler={
                          otherShowcaseCardsData.loadCurrenyBtnHandler
                        }
                        recommendedBtnHandler={
                          otherShowcaseCardsData.recommendedBtnHandler
                        }
                        startFromWhereLeftHandler={
                          otherShowcaseCardsData.startFromWhereLeftHandler
                        }
                        docsPendingHandler={
                          otherShowcaseCardsData.docsPendingHandler
                        }
                      />
                    );
                  })}
                </LandingCardFeatureCtr>
              )}
              {iconTextTag && (
                <IconTextTag
                  margin="8px 0px 0px"
                  label={iconTextTag.label}
                  subLabel={iconTextTag.subLabel}
                  onClick={iconTextTag.btnHandler}
                />
              )}
            </FlexComp>
          </BMFListingContainer>
          {marqueeData && !isMarqueeOnBottom && (
            <MarqueeBanner
              margin="24px 0px 0px"
              marqueeData={marqueeData.data}
              leftTagText={marqueeData.leftTitle}
            />
          )}
        </Fragment>
      ) : (
        // ONLY IN MOBILE VIEW
        <Fragment>
          {mobileStickyHeadingData && (
            <ListingPageHeader {...mobileStickyHeadingData} />
          )}
          <FlexComp flexDirection="column" padding="16px">
            {mobileTitle && (
              <CliptextStyle>
                <H4Tag>{mobileTitle}</H4Tag>
              </CliptextStyle>
            )}
            {deliveryPersuasion && !isDeliveryPersuasionInsideSctn && (
              <DeliveryPersuasion
                fontSize="sm"
                padding="0px"
                bgColor=""
                margin={"16px 0px 12px 0px"}
                {...deliveryPersuasion}
              />
            )}
          </FlexComp>
          {marqueeData && !isMarqueeOnBottom && (
            <MarqueeBanner
              leftTagText={marqueeData.leftTitle}
              margin={device === "lg" ? "0px 0px 16px" : "0px 0px 16px"}
              marqueeData={marqueeData.data}
            />
          )}
          <MarkerContainer>
            {pinnedShowcaseCardData &&
              pinnedShowcaseCardData.data.map((item) => (
                <SpecialAttention key={item.id}>
                  <FlexComp padding="24px">
                    <LandingCardFeature
                      data={item}
                      onClick={pinnedShowcaseCardData.onClick}
                      loadCurrenyBtnHandler={
                        pinnedShowcaseCardData.loadCurrenyBtnHandler
                      }
                      recommendedBtnHandler={
                        pinnedShowcaseCardData.recommendedBtnHandler
                      }
                      startFromWhereLeftHandler={
                        pinnedShowcaseCardData.startFromWhereLeftHandler
                      }
                      docsPendingHandler={
                        pinnedShowcaseCardData.docsPendingHandler
                      }
                    />
                  </FlexComp>
                </SpecialAttention>
              ))}

            <SpecialAttention withShadow={true}>
              <FlexComp flexDirection="column" padding="24px">
                {otherProductsText && (
                  <ParaTag fontSize="large" fontWeight="bold">
                    {otherProductsText}
                  </ParaTag>
                )}
                {deliveryPersuasion && (
                  <DeliveryPersuasion
                    fontSize="sm"
                    padding="0px"
                    bgColor=""
                    margin={"6px 0px 16px 0px"}
                    {...deliveryPersuasion}
                  />
                )}
                {otherShowcaseCardsData &&
                  otherShowcaseCardsData.data.map((item, idx) => {
                    return (
                      <LandingCardFeature
                        data={item}
                        border={
                          idx + 1 < otherShowcaseCardsData.data.length
                            ? "1px #e2e2e2 solid"
                            : ""
                        }
                        padding={
                          idx + 1 < otherShowcaseCardsData.data.length
                            ? "0px 0px 16px 0px"
                            : "0px"
                        }
                        margin={
                          idx + 1 < otherShowcaseCardsData.data.length
                            ? "0px 0px 16px 0px"
                            : "0px"
                        }
                        borderWidth={
                          idx + 1 < otherShowcaseCardsData.data.length
                            ? "0px 0px 1px 0px"
                            : "0px"
                        }
                        onClick={otherShowcaseCardsData.onClick}
                        loadCurrenyBtnHandler={
                          otherShowcaseCardsData.loadCurrenyBtnHandler
                        }
                        recommendedBtnHandler={
                          otherShowcaseCardsData.recommendedBtnHandler
                        }
                        startFromWhereLeftHandler={
                          otherShowcaseCardsData.startFromWhereLeftHandler
                        }
                        docsPendingHandler={
                          otherShowcaseCardsData.docsPendingHandler
                        }
                      />
                    );
                  })}
              </FlexComp>
              {iconTextTag && (
                <IconTextTag
                  label={iconTextTag.label}
                  subLabel={iconTextTag.subLabel}
                  onClick={iconTextTag.btnHandler}
                />
              )}
            </SpecialAttention>
          </MarkerContainer>
        </Fragment>
      )}
    </ListingTopContainer>
  );
};

export default TopShowcase;
