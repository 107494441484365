import { useEffect } from "react";

import {
  LandingCrdCompareStyle,
  LandingCrdCompareTitle,
  VideoPlay,
  LabelList,
  Flex,
  LandingCrdCompareCrdList,
  CardImg,
  CardFeature,
  LabelWrap,
  CardName,
} from "./LandingCardCompare.style";

import { ImageTag, LandingButton } from "@tm/static";
import { insertBrOnNewLine } from "@tm/static/utils";
import { landingCardCompareDataProps } from "@tm/static/data";
import { RoundPlayIcon, RoundTickIcon, RoundCrossIcon } from "@tm/static/icons";

export interface LandingCardCompareProps {
  data?: landingCardCompareDataProps[];
  title?: string;
  playBtnText?: {
    normalText: string;
    durationText: string;
    onClick: () => void;
  };
  rowLabelsArr?: any[];
  tickIcon?: string;
  crossIcon?: string;
  applyBtnHandler?: (item: landingCardCompareDataProps, idx: number) => void;
}

const LandingCardCompare = ({
  data,
  title,
  playBtnText,
  applyBtnHandler = () => {},
  rowLabelsArr,
  tickIcon,
  crossIcon,
}: LandingCardCompareProps) => {
  useEffect(() => {
    equalHeights("equalHeight0");
    equalHeights("equalHeight1");
    equalHeights("equalHeight2");
    equalHeights("equalHeight3");
    equalHeights("equalHeight4");
  }, []);

  const equalHeights = (compName: string) => {
    let findClass = document.getElementsByClassName(compName);
    let tallest = 0;
    // Loop over matching divs
    for (let i = 0; i < findClass.length; i++) {
      let ele = findClass[i] as HTMLDivElement;
      let eleHeight = ele.offsetHeight;
      tallest = eleHeight > tallest ? eleHeight : tallest;
    }
    for (let i = 0; i < findClass.length; i++) {
      (findClass[i] as HTMLDivElement).style.height = tallest + "px";
    }
  };

  return (
    <LandingCrdCompareStyle>
      {title && (
        <LandingCrdCompareTitle
          dangerouslySetInnerHTML={{
            __html: insertBrOnNewLine(title),
          }}
        />
      )}
      {playBtnText && (
        <VideoPlay onClick={playBtnText.onClick}>
          <RoundPlayIcon />
          {playBtnText.normalText}{" "}
          <span className="time">{playBtnText.durationText}</span>
        </VideoPlay>
      )}
      <Flex>
        {rowLabelsArr && (
          <LabelList>
            {rowLabelsArr.map((item, idx) => {
              return (
                <li key={idx} className={`equalHeight${idx}`}>
                  {item}
                </li>
              );
            })}
            {/* <li>
                        <a href="#" ariaa-label="ShowMore">
                            {rowLabelsArr[rowLabelsArr.length - 1]}
                        </a>
                    </li> */}
          </LabelList>
        )}
        {data && (
          <LandingCrdCompareCrdList>
            {data.map((item, index) => {
              return (
                <li key={index}>
                  <CardImg>
                    {item.crdImg && (
                      <ImageTag src={item.crdImg} alt={"card-img"} />
                    )}
                  </CardImg>
                  <CardFeature lastItem={index == data.length - 1}>
                    <CardName>{item.crdName}</CardName>
                    <LabelWrap className="equalHeight0" greyBg={true}>
                      {item.onBoarding &&
                        (tickIcon ? (
                          <ImageTag src={tickIcon} />
                        ) : (
                          <RoundTickIcon />
                        ))}
                      {!item.onBoarding &&
                        (crossIcon ? (
                          <ImageTag src={crossIcon} />
                        ) : (
                          <RoundCrossIcon />
                        ))}
                    </LabelWrap>
                    {item.access && (
                      <LabelWrap
                        flexColumn={true}
                        isBothIconAndText={
                          item.access.icon && !!item.access.text
                        }
                        className="equalHeight1"
                      >
                        {item.access.icon ? (
                          <RoundTickIcon />
                        ) : (
                          <RoundCrossIcon />
                        )}
                        {item.access.text && (
                          <span className="subInfo">{item.access.text}</span>
                        )}
                      </LabelWrap>
                    )}
                    {/* {!item.access && (
                                        <LabelWrap className="equalHeight1">
                                          {crossIcon ? <ImageTag src={crossIcon}/> :  <RoundCrossIcon />}
                                        </LabelWrap>
                                    )} */}
                    <LabelWrap
                      className="equalHeight2"
                      greyBg={true}
                      flexColumn={true}
                    >
                      <span className="infoTitle">{item.forexRate.rate}</span>
                      <span className="subInfo">{item.forexRate.info}</span>
                    </LabelWrap>
                    <LabelWrap className="equalHeight3">
                      {item.delivery}
                    </LabelWrap>
                    {item.charges && (
                      <LabelWrap
                        className="equalHeight4"
                        greyBg={true}
                        flexColumn={true}
                      >
                        {item.charges &&
                          item.charges.map((item2, index2) => {
                            return (
                              <span className="infoTitle" key={index2}>
                                {item2}
                              </span>
                            );
                          })}
                      </LabelWrap>
                    )}
                    {!item.charges && <span>--</span>}
                    {item.btn && (
                      <LandingButton
                        customWidth="100%"
                        margin="20px 0 0"
                        customTitle={item.btn}
                        onClick={() => applyBtnHandler(item, index)}
                      />
                    )}
                  </CardFeature>
                </li>
              );
            })}
          </LandingCrdCompareCrdList>
        )}
      </Flex>
    </LandingCrdCompareStyle>
  );
};

export default LandingCardCompare;
