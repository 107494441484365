import styled from "styled-components";
import { AnchorTagStyle } from "../TextStyles/TextStyles.style";

export interface HeaderStyleProps {
  opened?: boolean;
  closed?: boolean;
  displayWhiteHeader?: boolean;
  insuranceHeader?: boolean;
}

export const LandingHeaderStyle = styled.header`
  height: 80px;
  width: 100%;
  background: rgba(0, 0, 0);
  color: rgba(255, 255, 255, 0.6);
  z-index: 40;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;

  .displayNone {
    display: none;
  }
`;

export const LandingHeaderWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  position: relative;
  /* position: fixed;
    top: 0;
    left: 0;
    right: 0; */
  z-index: 100;
  background: #000;
  z-index: 40;
`;

export const LandingHeaderWhiteStyle = styled(LandingHeaderStyle)`
  background: #fff;
  color: #000;
  z-index: 40;
  ${LandingHeaderWrap} {
    background: #fff;
  }
`;

export const TmLogo = styled.a`
  width: 140px;
  height: 40px;
  flex-shrink: 0;
  opacity: 0;
`;

export const LandingHeaderList = styled.ul`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 32px;
  cursor: pointer;
`;

export const LandingHeaderItem = styled.li<HeaderStyleProps>`
  position: relative;
  & a {
    color: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  &:first-child {
    margin-right: 16px;
  }

  &:first-child:after {
    content: "";
    position: absolute;
    border: solid #008cff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    top: 3px;
    right: -22px;
    transition: ease-in 0.4s;
    ${(p) =>
      p.opened &&
      `
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        top: 9px;
        `}
    ${(p) =>
      p.closed &&
      `
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        `}
  }
`;

export const LandingHeaderBtn = styled.button`
  width: 90px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-weight: 900;
  color: #008cff;
  font-size: 16px;
  line-height: 20px;
  margin-left: 45px;
  border-radius: 96px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(93.66deg, #249083 0%, #249054 100%), #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none;
  cursor: pointer;
`;

export const ProductLinks = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  padding: 24px 16px;
  position: absolute;
  top: 35px;
  left: 0;
  width: 230px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  a {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4a4a4a;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  cursor: pointer;

  svg {
    margin-left: 8px;
  }
`;

export const ImageIcon = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 100%;
  margin-right: 10px;
  background: #000;
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const LandingHeaderMob = styled.div`
  background: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 4;
  ${AnchorTagStyle} {
    display: flex;
  }
`;

export const LandingHeaderMobBlack = styled.div<HeaderStyleProps>`
  background: transparent;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: sticky;
  top: 0;
  z-index: 4;
  ${(p) =>
    p.displayWhiteHeader &&
    `
    background:#fff;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.2);
    `}
  ${AnchorTagStyle} {
    display: flex;
  }
  picture {
    width: 75px;
    height: 24px;
  }
  ${(p) =>
    p.insuranceHeader &&
    `
    position:fixed;
    top:0;
    width:100%;
    left:0;
    right:0;
    `}
`;
