import { deviceType } from "@tm/static/utils";
import styled from "styled-components";

type ProductDescriptionStyleProps = {
  bgImg: string;
};

export const ProductDescriptionStyle = styled.div<ProductDescriptionStyleProps>`
  background: ${(props) => `url(${props.bgImg});`};
  /* width: 480px; */
  padding: 26px 16px 16px;

  @media screen and ${deviceType.mobile} {
    padding: 28px 30px 46px;
  }
`;

export const CloseIconCtr = styled.div`
  text-align: right;
  text-align: -webkit-right;
`;
