import styled from "styled-components";
type CalenderType = {
  isWithinRange?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
};
export const CalenderWrap = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 10px;
`;

export const CalenderMonthName = styled.div`
  background-color: #fff;
  padding: 20px 16px;
  font-size: 16px;
  font-weight: 700;
  color: #4a4a4a;
  display: flex;
  align-items: center;
`;

export const CalenderDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-bottom: 1px solid #e7e7e7;
  background-color: #f2f2f2;
`;

export const CalenderDate = styled.div<CalenderType>`
  border-left: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
  padding: 20px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: relative;
  ${(p) => p.isSelected && ` background-color: #008cff;`}
  ${(p) => p.isWithinRange && `background-color: #f0f8ff;`}
        ${(p) => p.isDisabled && ` pointer-events: none;`}
`;
