import { theme } from "@tm/utility/global";
import styled from "styled-components";

export const Container = styled.div``;
export const Cta = styled.a`
  font-size: 12px;
  line-height: 16px;
  color: ${theme.blueText};
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
`;

export const GpsIconWpr = styled.div`
  margin: 0 2px 0 0;
`;
