import {
  CARD_TYPE,
  DEFAULT_CN_FLOW_VERSION,
  DEFAULT_MX_FLOW_VERSION,
} from "..";
import { CardType } from "../../types";

export const defaultForexCardsData = {
  [CARD_TYPE.INR_CARD]: {
    id: CARD_TYPE.INR_CARD,
    logoSrc:
      "https://tripmoneycmsimgak.mmtcdn.com/img/mini_tripmoney_card_img_60eaa9307b.png",
    // logoDesc: {
    //     boldText: 'SBM Bank',
    //     normalText: 'Powered by',
    // },
    label: "TripMoney Global Card",
    // subLabel: 'for the Casual Frequent Traveller',
    featuresArr: [
      {
        id: "1",
        label: "No pre-load required",
      },
      {
        id: "2",
        label: "Real-time exchange rates",
      },
      {
        id: "3",
        label: "VISA rates applicable",
      },
    ],
    deliveryLabel: null,
    isleftOffBefore: null,
    isRecommended: null,
    isUnavailableAtLoc: null,
    loadCurrency: null,
    docsPending: null,
    isRecommendNotification: false,
    viewOrdersPersuasion: null,
    backendPersuasion: [],
    startOrder: null,
  } as CardType,
  [CARD_TYPE.MULTI_CURRENCY_CARD]: {
    id: CARD_TYPE.MULTI_CURRENCY_CARD,
    cta_redirect: DEFAULT_MX_FLOW_VERSION,
    version: DEFAULT_MX_FLOW_VERSION,
    logoSrc:
      "https://tripmoneycmsimgak.mmtcdn.com/img/new_bmf_card_714a7ae7ca.png",
    // logoDesc: {
    //     boldText: 'Yes Bank',
    //     normalText: 'Powered by',
    // },
    label: "Multicurrency Card",
    // subLabel: 'for the Luxurious Traveller',
    featuresArr: [
      {
        id: "1",
        label: "Zero forex mark-up",
      },
      {
        id: "2",
        label: "Accepted in 150+ countries",
      },
      {
        id: "3",
        label: "Offers & Cashback",
      },
    ],
    featureArrDesktop: [
      {
        id: "1",
        label: "Zero forex mark-up",
      },
      {
        id: "2",
        label: "Accepted in 150+ countries",
      },
      {
        id: "3",
        label: "Up to 5% more savings over Credit/Debit Cards",
      },
      {
        id: "4",
        label: "Offers & cashback",
      },
    ],
    deliveryLabel: null,
    isleftOffBefore: null,
    isRecommended: null,
    isUnavailableAtLoc: null,
    loadCurrency: null,
    docsPending: null,
    isRecommendNotification: false,
    viewOrdersPersuasion: null,
    backendPersuasion: [],
    startOrder: null,
  } as CardType,
  [CARD_TYPE.CURRENCY]: {
    id: CARD_TYPE.CURRENCY,
    cta_redirect: DEFAULT_CN_FLOW_VERSION,
    version: DEFAULT_CN_FLOW_VERSION,
    logoSrc:
      "https://tripmoneycmsimgak.mmtcdn.com/img/currency_img_small_8bc249fe6d.png",
    // logoDesc: {
    //     boldText: 'RBI',
    //     normalText: 'Verified By',
    // },
    label: "Foreign Currency Notes",
    // subLabel: 'for the Luxurious Traveller',
    featuresArr: [
      {
        id: "1",
        label: "40+ currencies",
      },
      {
        id: "2",
        label: "Best rates",
      },
      {
        id: "3",
        label: "Genuine Notes from RBI licensed companies",
      },
    ],
    featureArrDesktop: [
      {
        id: "1",
        label: "40+ currencies",
      },
      {
        id: "2",
        label: "Best rates",
      },
      {
        id: "3",
        label: "Genuine Notes from RBI licensed companies",
      },
    ],
    deliveryLabel: null,
    isleftOffBefore: null,
    isRecommended: null,
    isUnavailableAtLoc: null,
    loadCurrency: null,
    docsPending: null,
    isRecommendNotification: false,
    viewOrdersPersuasion: null,
    backendPersuasion: [],
    startOrder: null,
  } as CardType,
};
