import { ImageTag, ParaTag, SpanTag } from "@tm/static";
import { DisplayInfoIconTagStyle } from "./DisplayInfoIcon.style";
import { EditIcon } from "@tm/static/icons";

interface DisplayInfoIconTagProps {
  infoText: string;
  label: string;
  subLabel?: string;
  margin?: string | number;
  editBtnHandler?: () => void;
  editIcon?: string;
}

const DisplayInfoIconTag = ({
  infoText,
  label,
  subLabel,
  margin,
  editBtnHandler,
  editIcon,
}: DisplayInfoIconTagProps) => {
  return (
    <DisplayInfoIconTagStyle margin={margin}>
      <div>
        <ParaTag fontSize="sm">{infoText}</ParaTag>
        <ParaTag>
          {label && (
            <SpanTag fontSize="sm" fontWeight="bold">
              {label}
            </SpanTag>
          )}
          {subLabel && (
            <>
              <SpanTag margin="0px 2px" fontSize="sm" fontWeight="bold">
                |
              </SpanTag>
              <SpanTag fontSize="sm" fontWeight="bold">
                {subLabel}
              </SpanTag>
            </>
          )}
        </ParaTag>
      </div>
      <div onClick={editBtnHandler}>
        {editIcon ? (
          <ImageTag src={editIcon} showCursorPointer />
        ) : (
          <EditIcon />
        )}
      </div>
    </DisplayInfoIconTagStyle>
  );
};

export default DisplayInfoIconTag;
