import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { theme } from "@tm/utility/global";
import { SpanTag, AnchorTag } from "@tm/static";

import {
  formatDate,
  getFormFieldErrorMsg,
  isDobValid,
} from "@tm/insurance/utils";
import { DATE_FORMATS } from "@tm/insurance/constants";

import {
  TravellerDescpWrap,
  TravellerDescpStyle,
  TravellerIndexWrap,
  TravellerDescpError,
} from "./AddTravellerDetail.style";
import { ITravellerDescp, ITravellerDetailsForm } from "./type";

const TravellerDescp = ({
  travellerIndex,
  genderTypeSelected,
  secondaryContactData,
  travellerNumber,
  handleEditState,
}: ITravellerDescp) => {
  const { name, dob, gender } = secondaryContactData || {};

  const {
    control,
    formState: { errors },
  } = useFormContext<ITravellerDetailsForm>();

  const error = errors?.travellerData?.[travellerIndex];
  let errorMessage =
    error?.name?.message || error?.dob?.message || error?.gender?.message || "";

  return (
    <TravellerDescpWrap>
      <TravellerDescpStyle>
        <TravellerIndexWrap>
          <SpanTag fontSize="xs" fontWeight="black" color={theme.blackText}>
            {travellerNumber}
          </SpanTag>
        </TravellerIndexWrap>
        <div>
          {name?.isEnabled && (
            <Controller
              control={control}
              name={`travellerData.${travellerIndex}.name`}
              rules={{
                required: {
                  value: name?.isRequired ?? true,
                  message: getFormFieldErrorMsg("name"),
                },
                pattern: {
                  value: new RegExp(name?.regex || ""),
                  message: getFormFieldErrorMsg("name", name?.errorMessage),
                },
              }}
              render={({ field: { value } }) => (
                <SpanTag fontSize="sm" fontWeight="black" margin="0 0 2px">
                  {value || "---"}
                </SpanTag>
              )}
            />
          )}
          <div>
            {gender?.isEnabled && (
              <SpanTag fontSize="xs" color={theme.greyText}>
                {genderTypeSelected ? genderTypeSelected : "---"},
              </SpanTag>
            )}
            {dob?.isEnabled && (
              <Controller
                control={control}
                name={`travellerData.${travellerIndex}.dob`}
                rules={{
                  required: {
                    value: dob?.isRequired ?? true,
                    message: getFormFieldErrorMsg("dob"),
                  },
                  validate: (value) =>
                    isDobValid(value, dob?.regex) ||
                    getFormFieldErrorMsg("dob", dob?.errorMessage),
                }}
                render={({ field: { value } }) => (
                  <SpanTag fontSize="xs" color={theme.greyText}>
                    DOB:{" "}
                    {formatDate(value, DATE_FORMATS["DD/MM/YYYY"]) || "---"}
                  </SpanTag>
                )}
              />
            )}
          </div>
        </div>
        <AnchorTag
          margin="0 0 0 auto"
          fontSize="xs"
          fontWeight="black"
          handleClick={handleEditState}
        >
          Edit
        </AnchorTag>
      </TravellerDescpStyle>
      {errorMessage && (
        <TravellerDescpError>
          <SpanTag fontSize="xs" color={theme.redText2}>
            {errorMessage}
          </SpanTag>
        </TravellerDescpError>
      )}
    </TravellerDescpWrap>
  );
};

export default TravellerDescp;
