import {
  SHOW_DATA_ON_RIGHT_SIDE_IN_FOREX_CARD_LIMIT,
  VERSIONS,
} from "../constants";
import {
  landingCardShowcaseDataProps,
  landingMarqueeDataProps,
} from "../static/mockData";
import LIVETag from "../static/icons/priceTickerLiveTag.svg";
import {
  Coupon,
  LocationFetchingProps,
  OfferPersuationsType,
  RateTickerType,
} from "../types";
import { OFFER_TYPES, convertEpochToDate, toLocaleStringINRFormat } from ".";
import { RecommendedCurrenciesTicker } from "../store/forexListingReducer";

export function getLocationFetchingDataProps(
  locFetchingMode: LocationFetchingProps,
  isLocationFetching: boolean,
  locationFetchingScreenLabel: string | null
): any {
  const props = {
    locFetchingMode: locFetchingMode,
    isLocationFetching: isLocationFetching,
    data: {
      fetchingData: {
        loadingGifSrc:
          "https://tripmoneycmsimgak.mmtcdn.com/img/Ripple_listing_aa2677db1e.gif",
        label: "Loading Forex Products",
      },
      fetchedData: {
        showDeliveryIcon: false,
        label: locationFetchingScreenLabel,
      },
      conditionsText: {
        greenText: "*",
        normalText:
          "Please note that these are tentative dates. The actual dates may vary",
      },
    },
  };
  return props;
}

export function getSectionBgImgURI(
  version: number,
  isLocationFetching?: boolean
) {
  if (version === VERSIONS.V3) {
    if (isLocationFetching) {
      return "https://tripmoneycmsimgak.mmtcdn.com/img/tripmoney_mmt_bg_V3_3af87d2023.avif";
    } else {
      return "https://tripmoneycmsimgak.mmtcdn.com/img/bg_14c7031087.png";
    }
  } else {
    return "https://tripmoneycmsimgak.mmtcdn.com/img/listing_bg_grill_fcf0a7f743.svg";
  }
}

export function getLandingTitle(mobileTitle: string) {
  return {
    textOne: mobileTitle,
    textTwo: "We offer",
    textThree: "same-day delivery",
    textFour: "in 125+ Indian cities",
  };
}

export function getMarqueeData(tickerData: landingMarqueeDataProps[]) {
  return {
    isLoading: tickerData && tickerData.length === 0,
    loadingText: "Fetching LIVE Rates",
    data: tickerData,
    // @ts-ignore
    // Remove this when link is added intead of import
    LIVETagImgURI: LIVETag.src,
  };
}

export function getDiscountBanner(
  offer_persuasion_text: string | null | undefined,
  device: string | null
) {
  return {
    imgSrc:
      device === "lg"
        ? "https://tripmoneycmsimgak.mmtcdn.com/img/discount_Green_6294b6813d.svg"
        : "https://tripmoneycmsimgak.mmtcdn.com/img/discount_White_5206665087.svg",
    label: offer_persuasion_text,
  };
}

export function getOtherProductsText(
  landingPinnedCardShowcaseData: [],
  version: number | null
) {
  return landingPinnedCardShowcaseData.length > 0
    ? version === VERSIONS.V3
      ? "Other Forex Products"
      : "You can also try"
    : "";
}

export function getDeliveryPersuasion(
  deliveryDetailsContent: {
    ctaText: string;
    text: string;
    checkPincode: boolean;
    checkPincodeLabel: string;
  } | null,
  checkPincodeHandler: () => void,
  editLocationHandler: () => void
) {
  return deliveryDetailsContent
    ? {
        checkPincodeHandler,
        editLocationHandler,
        ...deliveryDetailsContent,
      }
    : null;
}

export function getOtherShowcaseCardsData(
  landingCardShowcaseData: any,
  otherShowcaseCardsHandler: (
    item: landingCardShowcaseDataProps
  ) => Promise<void>
) {
  return {
    data: landingCardShowcaseData,
    itemHandler: otherShowcaseCardsHandler,
    isLoading: landingCardShowcaseData && landingCardShowcaseData.length === 0,
    loadingImgSrc:
      "https://tripmoneycmsimgak.mmtcdn.com/img/infinity_Loading_min_f0a0b9bcba.gif",
    loadingText: "Loading Forex Offerings",
    showInfoOnRightSideInCards:
      landingCardShowcaseData.length <=
      SHOW_DATA_ON_RIGHT_SIDE_IN_FOREX_CARD_LIMIT,
  };
}

export function getViewOrderLabel(isLoggedInUser: boolean | null | undefined) {
  return isLoggedInUser ? "VIEW PREVIOUS ORDERS" : "";
}

export function shouldShowDiscountBanner(
  offerPersuasions: OfferPersuationsType[] | null | undefined
) {
  if (!offerPersuasions?.length) {
    return false;
  }

  return true;
}

export function getEffectiveRateBannerLabel({
  offerPersuasions,
  device,
}: {
  offerPersuasions: OfferPersuationsType[] | null | undefined;
  device: string | null;
}) {
  if (!offerPersuasions?.length) {
    return "";
  }

  const firstOffer = offerPersuasions[0];

  if (firstOffer.type === OFFER_TYPES.CROSS_SELL_HOTEL) {
    return `<b>Exclusive Offer for you!</b> ${getHotelCouponDiscountBannerSubLabel(
      firstOffer
    )}`;
  } else if (firstOffer.type === OFFER_TYPES.CROSS_SELL_FLIGHT) {
    return `<b>Exclusive Flyer Offer for you!</b> ${getFlightCouponDiscountBannerSubLabel(
      firstOffer
    )}`;
  } else if (firstOffer.type === OFFER_TYPES.SALE_COUPONS) {
    return `*Net effective rate after cashback is applied`;
  } else {
    return "";
  }
}

export function getDiscountBannerProps(
  offerPersuasions: OfferPersuationsType[] | null | undefined,
  device: string | null
): { imgSrc: string; label: string; subLabel: string } | { label?: string } {
  if (!offerPersuasions?.length) {
    return {};
  }

  const firstOffer = offerPersuasions[0];

  if (!firstOffer) {
    return {};
  }

  if (firstOffer.type === OFFER_TYPES.CROSS_SELL_HOTEL) {
    return getHotelCouponDiscountBannerProps(firstOffer, device);
  } else if (firstOffer.type === OFFER_TYPES.CROSS_SELL_FLIGHT) {
    return getFlightCouponDiscountBannerProps(firstOffer, device);
  } else {
    return getSaleCouponDiscountBannerProps(firstOffer, device);
  }
}

function getSaleCouponDiscountBannerProps(
  offerPersuasion: OfferPersuationsType,
  device: string | null
) {
  const details = offerPersuasion.value?.details?.[0];
  return {
    imgSrc:
      device === "lg"
        ? "https://tripmoneycmsimgak.mmtcdn.com/img/discount_Green_6294b6813d.svg"
        : "https://tripmoneycmsimgak.mmtcdn.com/img/discount_68d1265618.svg",
    label: `Get upto  <span class="greenTextWeb"> INR ${details?.max_cashback} Cashback</span> on Forex Card & Currency`,
    subLabel: "",
  };
}

function getHotelCouponDiscountBannerSubLabel(
  offerPersuasion: OfferPersuationsType
) {
  const details = (offerPersuasion.value?.details as Coupon[])?.[0];
  return details?.coupon_cashback
    ? `Claim <span class="greenTextWeb">${details?.coupon_currency || "INR"} ${
        details?.coupon_cashback
      } Assured Cashback</span> on forex card or currency purchase of minimum INR ${
        offerPersuasion.value?.min_purchase || "3,000"
      }`
    : "";
}

function getHotelCouponDiscountBannerProps(
  offerPersuasion: OfferPersuationsType,
  device: string | null
) {
  return {
    imgSrc:
      device === "lg"
        ? "https://tripmoneycmsimgak.mmtcdn.com/img/discount_Green_6294b6813d.svg"
        : "https://tripmoneycmsimgak.mmtcdn.com/img/discount_68d1265618.svg",
    label:
      `<span class="greenTextWeb">${offerPersuasion.value?.text}</span>` || "",
    subLabel: getHotelCouponDiscountBannerSubLabel(offerPersuasion),
  };
}

function getFlightCouponDiscountBannerSubLabel(
  offerPersuasion: OfferPersuationsType
) {
  let details = offerPersuasion.value?.details;

  if (!details?.[0]?.coupon_cashback) return "";

  const maxCashBackDetails = details.reduce(
    (maxAmountDetail, currentDetail) => {
      return maxAmountDetail?.coupon_cashback !== undefined &&
        currentDetail?.coupon_cashback !== undefined &&
        maxAmountDetail?.coupon_cashback > currentDetail?.coupon_cashback
        ? maxAmountDetail
        : currentDetail;
    }
  );

  return maxCashBackDetails?.coupon_cashback
    ? `Claim <span class="greenTextWeb">${
        maxCashBackDetails?.coupon_currency || "INR"
      } ${
        maxCashBackDetails?.coupon_cashback
      } Assured Cashback</span> on forex card or currency purchase of minimum INR ${
        offerPersuasion.value?.min_purchase || "3,000"
      }`
    : "";
}

function getFlightCouponDiscountBannerProps(
  offerPersuasion: OfferPersuationsType,
  device: string | null
) {
  return {
    imgSrc:
      device === "lg"
        ? "https://tripmoneycmsimgak.mmtcdn.com/img/discount_Green_6294b6813d.svg"
        : "https://tripmoneycmsimgak.mmtcdn.com/img/discount_68d1265618.svg",
    label:
      `<span class="greenTextWeb">${offerPersuasion.value?.text}</span>` || "",
    subLabel: getFlightCouponDiscountBannerSubLabel(offerPersuasion),
  };
}

export function getCouponDetailProps(
  offerPersuasions: OfferPersuationsType[] | null | undefined
) {
  return {
    title: getCouponDetailsTitle(offerPersuasions),
    couponsData: getCouponData(offerPersuasions),
    cashbackSlabs: getCashbackSlabs(offerPersuasions),
    stepsToApply: [],
    tncData: getCouponDetailsTncData(offerPersuasions?.[0]?.value?.tnc || []),
    modalHeading: getCouponDetailsModalHeading(offerPersuasions),
  };
}

function getCouponDetailsModalHeading(
  offerPersuasion: OfferPersuationsType[] | null | undefined
) {
  if (!offerPersuasion?.length) {
    return "";
  }
  if (
    [OFFER_TYPES.CROSS_SELL_HOTEL, OFFER_TYPES.CROSS_SELL_FLIGHT].includes(
      offerPersuasion[0].type || ""
    )
  ) {
    return "Offer Details";
  } else {
    return "Coupon Details";
  }
}

function getCouponDetailsTitle(
  offerPersuasion: OfferPersuationsType[] | null | undefined
) {
  if (!offerPersuasion?.length) {
    return "";
  }
  if (offerPersuasion[0].type === OFFER_TYPES.CROSS_SELL_HOTEL) {
    return "Offer(s) available basis your international hotel bookings";
  } else if (offerPersuasion[0].type === OFFER_TYPES.CROSS_SELL_FLIGHT) {
    return "Offer(s) available because you booked international flight.";
  } else {
    return "";
  }
}

function getCouponDetailsTncData(tncs: string[]) {
  return tncs
    .filter((tncItem) => !!tncItem)
    .map((tncItem, index) => ({ id: String(index), info: tncItem }));
}

function getCashbackSlabs(
  offerPersuasions: OfferPersuationsType[] | null | undefined
): { range: string; cashback: string; id: string }[] {
  if (!offerPersuasions?.length) {
    return [];
  }
  const updateSlab =
    offerPersuasions[0].value?.slab?.map((slab, index) => {
      const updateSlab = { ...slab, id: `offer_slab_${index}` };
      return updateSlab;
    }) || [];
  return updateSlab;
}

function getCouponDetailsLabelForHotel(coupon: Coupon) {
  return `Get <b><span style="color: #2B825E">${
    coupon?.coupon_currency || "INR"
  } ${
    coupon.coupon_cashback
  }</span></b> assured cashback on purchase of forex card or currency. Offer earned via hotel Booking ${
    coupon?.booking_id || ""
  }.`;
}

function getCouponData(
  offerPersuasions: OfferPersuationsType[] | null | undefined
) {
  if (!offerPersuasions?.length) {
    return [];
  }

  if (offerPersuasions?.[0]?.type === OFFER_TYPES.CROSS_SELL_HOTEL) {
    const coupons = (offerPersuasions?.[0]?.value?.details as Coupon[]) || [];
    return coupons
      .filter((coupon: Coupon) => coupon?.coupon_cashback)
      .map((coupon: Coupon, index) => ({
        id: String(index + (coupon?.coupon_cashback || 0)),
        code: `CASHBACK ${coupon?.coupon_currency || "INR"} ${
          coupon.coupon_cashback
        }`,
        details: getCouponDetailsLabelForHotel(coupon),
        expiryText: `Valid till: ${
          coupon?.coupon_expiry ? convertEpochToDate(coupon?.coupon_expiry) : ""
        }`,
        expiryDate: coupon?.coupon_expiry
          ? convertEpochToDate(coupon?.coupon_expiry)
          : "",
      }));
  } else if (offerPersuasions?.[0]?.type === OFFER_TYPES.CROSS_SELL_FLIGHT) {
    const coupons = (offerPersuasions?.[0]?.value?.details as Coupon[]) || [];
    return coupons
      .filter((coupon: Coupon) => coupon?.coupon_cashback)
      .map((coupon: Coupon, index) => ({
        id: String(index + (coupon?.coupon_cashback || 0)),
        code: coupon?.title || "",
        details: coupon?.message || "",
        expiryText: coupon?.period || "",
        expiryDate: coupon?.period || "",
      }));
  } else {
    const coupon = offerPersuasions?.[0]?.value?.details?.[0] || {
      max_cashback: "",
      title: "",
      message: "",
      period: "",
    };
    return [
      {
        id: "sale_coupon_offer",
        code: coupon?.title || "",
        details: coupon?.message || "",
        expiryText: coupon?.period || "",
      },
    ];
  }
}

export function getForexRateTickerProps({
  forexRateTicker,
  offerPersuasions,
}: {
  forexRateTicker?: RecommendedCurrenciesTicker[];
  offerPersuasions?: OfferPersuationsType[] | null;
}) {
  if (!forexRateTicker) {
    return [];
  }
  return forexRateTicker
    .filter(
      (ticker) => ticker.currency_rate?.card || ticker.currency_rate?.currency
    )
    .map((ticker, index) => {
      const cardRate = ticker.currency_rate?.card;
      const cardPrecision = (cardRate ?? 0) >= 1 ? 2 : 4;

      const currencyRate = ticker.currency_rate?.currency;
      const currencyPrecision = (currencyRate ?? 0) >= 1 ? 2 : 4;

      let rateToShow = "-";

      if (cardRate) {
        rateToShow = toLocaleStringINRFormat(
          cardRate,
          cardPrecision,
          cardPrecision
        );
      } else if (currencyRate) {
        rateToShow = toLocaleStringINRFormat(
          currencyRate,
          currencyPrecision,
          currencyPrecision
        );
      } else {
        rateToShow = "-";
      }

      return {
        id: `top_currency_ticker_${index}`,
        currencyCode: ticker.currency_code,
        amount: rateToShow,
        upcomingTripInfo:
          ticker.currency_source === RateTickerType.UPCOMING_TRIP
            ? `For upcoming trip to <b>${ticker.label}</b>`
            : "",
        type: ticker.currency_source,
        conditional: offerPersuasions?.[0]?.type === OFFER_TYPES.SALE_COUPONS,
      };
    });
}

function transformTickerDataForModal(
  rateTicker: RecommendedCurrenciesTicker,
  isMMTSaleCouponAvailable: boolean
) {
  const rateSuffix = isMMTSaleCouponAvailable ? "*" : "";

  return {
    id: rateTicker.currency_name ?? "",
    flag: rateTicker.image_url ?? "",
    currencyName: rateTicker.currency_name ?? "",
    currencyCode: rateTicker.currency_code ?? "",
    cardRate: rateTicker.currency_rate?.card
      ? `${toLocaleStringINRFormat(
          rateTicker.currency_rate?.card,
          4,
          4
        )}${rateSuffix}`
      : "-",
    currencyRate: rateTicker.currency_rate?.currency
      ? `${toLocaleStringINRFormat(
          rateTicker.currency_rate?.currency,
          4,
          4
        )}${rateSuffix}`
      : "-",
  };
}

export function getPropsForTickerModal({
  forexRateTicker,
  offerPersuasions,
}: {
  forexRateTicker?: RecommendedCurrenciesTicker[];
  offerPersuasions?: OfferPersuationsType[] | null;
}) {
  const isMMTSaleCouponAvailable =
    offerPersuasions?.[0]?.type === OFFER_TYPES.SALE_COUPONS;

  const forexRatesData: {
    id: string;
    title: string;
    data: {
      id: string;
      flag: string;
      currencyName: string;
      currencyCode: string;
      cardRate: string;
      currencyRate: string;
    }[];
  }[] = [
    {
      id: "1",
      title: "For upcoming trip",
      data: [],
    },
    { id: "2", title: "Top Currencies", data: [] },
  ];

  forexRateTicker?.forEach((rateTicker) => {
    const tickerTypeToUpdate =
      rateTicker.currency_source === RateTickerType.UPCOMING_TRIP ? 0 : 1;
    forexRatesData[tickerTypeToUpdate].data.push(
      transformTickerDataForModal(rateTicker, isMMTSaleCouponAvailable)
    );
  });

  const filteredForexRatesData = forexRatesData?.filter(
    (rateObject) => rateObject.data.length
  );

  if (isMMTSaleCouponAvailable) {
    return {
      title: `Below are the net effective rates after <b>UPTO INR 7500 CASHBACK</b> coupon is applied.`,
      footerText:
        "Proceed with forex card or currency notes to know net effective rates of any other desired currency.",
      forexRatesData: filteredForexRatesData,
    };
  } else {
    return {
      title: "",
      footerText:
        "Proceed with forex card or currency notes to know rates of any other desired currency.",
      forexRatesData: filteredForexRatesData,
    };
  }
}
