import * as React from "react";
interface iconprop {}
const PersonalBenefitIcon = (props: iconprop) => (
  <svg
    width={165}
    height={165}
    viewBox="0 0 165 165"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={82.0009} cy={82.0009} r={82.0009} fill="#FAFAFA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.6958 43.0661C93.6193 48.6375 104.337 52.2062 115.589 53.5102V53.5164C117.245 53.7223 118.501 55.1635 118.501 56.9541V85.0359C118.501 106.978 103.482 125.87 82.701 129.938C82.5009 130 82.3068 130 82.1066 130C81.9064 130 81.7123 130 81.5122 129.932C60.7311 125.876 45.7124 106.972 45.7124 85.0421V56.9479C45.7124 55.1635 46.968 53.7223 48.6239 53.5164C59.8758 52.2062 70.5939 48.6375 80.5174 43.0661C81.5122 42.517 82.701 42.517 83.6958 43.0661Z"
      fill="url(#paint0_linear_841_15292)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.6958 43.0661C93.6193 48.6375 104.337 52.2062 115.589 53.5102V53.5164C117.245 53.7223 118.501 55.1635 118.501 56.9541V85.0359C118.501 106.978 103.482 125.87 82.701 129.938C82.5009 130 82.3068 130 82.1066 130C81.9064 130 81.7123 130 81.5122 129.932C60.7311 125.876 45.7124 106.972 45.7124 85.0421V56.9479C45.7124 55.1635 46.968 53.7223 48.6239 53.5164C59.8758 52.2062 70.5939 48.6375 80.5174 43.0661C81.5122 42.517 82.701 42.517 83.6958 43.0661Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.6958 40.0661C93.6193 45.6375 104.337 49.2062 115.589 50.5102V50.5164C117.245 50.7223 118.501 52.1635 118.501 53.9541V82.0359C118.501 103.978 103.482 122.87 82.701 126.938C82.5009 127 82.3068 127 82.1066 127C81.9064 127 81.7123 127 81.5122 126.932C60.7311 122.876 45.7124 103.972 45.7124 82.0421V53.9479C45.7124 52.1635 46.968 50.7223 48.6239 50.5164C59.8758 49.2062 70.5939 45.6375 80.5174 40.0661C81.5122 39.517 82.701 39.517 83.6958 40.0661Z"
      fill="url(#paint1_linear_841_15292)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.959 126.999C82.0084 127 82.058 127 82.108 127C82.3082 127 82.5023 127 82.7025 126.938C103.484 122.87 118.502 103.978 118.502 82.0359V53.9541C118.502 52.1635 117.247 50.7223 115.591 50.5164V50.5102C104.339 49.2062 93.6207 45.6375 83.6972 40.0661C83.1546 39.7666 82.5542 39.6304 81.959 39.6577V126.999Z"
      fill="url(#paint2_linear_841_15292)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.959 126.999C82.0084 127 82.058 127 82.108 127C82.3082 127 82.5023 127 82.7025 126.938C103.484 122.87 118.502 103.978 118.502 82.0359V53.9541C118.502 52.1635 117.247 50.7223 115.591 50.5164V50.5102C104.339 49.2062 93.6207 45.6375 83.6972 40.0661C83.1546 39.7666 82.5542 39.6304 81.959 39.6577V126.999Z"
      fill="white"
      fillOpacity={0.2}
    />
    <circle opacity={0.3} cx={106.003} cy={60.002} r={20.998} fill="black" />
    <circle cx={107.003} cy={59.002} r={20.998} fill="white" />
    <path
      d="M116.762 57.2759V61.6741H109.554V69.493H104.667V61.6741H97.459V57.2759H104.667V49.457H109.554V57.2759H116.762Z"
      fill="url(#paint3_linear_841_15292)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_841_15292"
        x1={82.1066}
        y1={42.6543}
        x2={82.1066}
        y2={130}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28624A" />
        <stop offset={1} stopColor="#3DC78E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_15292"
        x1={82.1066}
        y1={39.6543}
        x2={82.1066}
        y2={127}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28624A" />
        <stop offset={1} stopColor="#3DC78E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_841_15292"
        x1={100.23}
        y1={39.6543}
        x2={100.23}
        y2={127}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28624A" />
        <stop offset={1} stopColor="#3DC78E" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_841_15292"
        x1={107.11}
        y1={49.457}
        x2={107.11}
        y2={69.493}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C4AD37" />
        <stop offset={1} stopColor="#FFE55E" />
      </linearGradient>
    </defs>
  </svg>
);
export { PersonalBenefitIcon };
