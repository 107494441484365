import React, { useState } from "react";

import { FlexComp, H2Tag, H5Tag, ImageTag } from "@tm/static";
import { InsFaqCompStyle, InsCompWrap, SearchField } from "./InsFaqComp.style";
import AccordianComp from "../AccordianComp/AccordianComp";

interface accordionProp {
  title?: string;
  popularQues?: {
    title?: string;
    info?: string;
  }[];
  questionType?: string;
  questionTypeIcon?: string;
}

const InsFaqComp = ({
  title,
  popularQues,
  questionType,
  questionTypeIcon,
}: accordionProp) => {
  const [accordion, setAccordion] = useState(-1);

  const handleClickAccordion = (index: number) => {
    accordion == index ? setAccordion(-1) : setAccordion(index);
  };
  return (
    <InsFaqCompStyle>
      <InsCompWrap>
        <FlexComp
          margin="0 0 60px"
          alignItems="center"
          justifyContent="space-between"
        >
          <H2Tag>{title}</H2Tag>
          <SearchField placeholder={"Enter your query"} />
        </FlexComp>
        <H5Tag margin="0 0 32px">
          {questionTypeIcon && (
            <ImageTag margin="0 8px 0 0" src={questionTypeIcon} />
          )}
          {questionType}
        </H5Tag>
        {popularQues &&
          popularQues.map((item, index) => {
            return (
              <AccordianComp
                title={item.title}
                info={item.info}
                handleClickAccordion={() => handleClickAccordion(index)}
                accordionStatus={accordion == index}
              />
            );
          })}
      </InsCompWrap>
    </InsFaqCompStyle>
  );
};

export default InsFaqComp;
