import styled from "styled-components";

export const LandingOfferingContainerStyle = styled.div`
  /* padding-left: 16px; */
  margin-top: 86px;
`;

export const ForexOfferingWpr = styled.ul<any>`
  ${(props) => props.margin && `margin: ${props.margin};`}
  width: 100%;
  padding-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 6px;
  margin-bottom: 40px;
  display: flex;
  overflow-x: scroll;
`;
