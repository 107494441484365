import { useEffect } from "react";
import BlueLoader from "../../icons/BlueLoaderSvg";
import { PageLoaderStyle } from "./style";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import { theme } from "../../css/Global";

export interface PageLoaderProps {
  messages?: Array<string>;
  darkTheme?: boolean;
}

const PageLoader = (props: PageLoaderProps) => {
  const { messages, darkTheme } = props;

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <PageLoaderStyle dark={darkTheme}>
      <BlueLoader />
      {messages?.map((e, i) => {
        return (
          <SpanTag
            fontSize="xs"
            color={darkTheme ? theme.whiteText : theme.greyText}
            fontWeight={i === 0 ? "bold" : "regular"}
            key={new Date().getMilliseconds() + i}
          >
            {e}
          </SpanTag>
        );
      })}
    </PageLoaderStyle>
  );
};

export default PageLoader;
