import React, { useRef, useState } from "react";
import { PanFaqsProps } from "./PanFaqsProps";
import H5Tag from "../../TextStyles/H5Tag";
import {
  AnswerList,
  Cta,
  FaqItem,
  FaqItemHdr,
  PanDtl,
  PanHdr,
  CtaIconWpr,
} from "./PanFaqs.styles";
import ParaTag from "../../TextStyles/ParaTag";
import { ArrowLarge, CloseIcon } from "../../../icons";
import { useBreakpoint } from "../../../../hooks/useBreakpoint";
import Modal from "../../Modal/Modal";
import ImageTag from "../../ImageTag/ImageTag";

const PanFaqs: React.FC<PanFaqsProps> = ({
  data,
  closeModal,
  panFaqHeading,
  closeIcon,
  onCtaClick,
  ctaIcon,
}) => {
  const device = useBreakpoint();
  const elementRef = useRef<any>();
  const [selected, setSelected] = useState<any>(null);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const handleFaqs = (idx: number) => {
    setSelected(selected === idx ? null : idx);
  };

  elementRef?.current?.addEventListener("scroll", (event: any) => {
    setScrollPosition(elementRef?.current.scrollTop);
  });

  return (
    <Modal
      onModalClose={closeModal}
      padding="0px"
      modalType={device === "lg" ? "centered" : "fullPage"}
    >
      <PanHdr shadow={scrollPosition > 10}>
        {device === "lg" ? (
          <>
            {panFaqHeading && <H5Tag>{panFaqHeading}</H5Tag>}
            <div onClick={closeModal}>
              {closeIcon ? (
                <ImageTag src={closeIcon} showCursorPointer />
              ) : (
                <CloseIcon />
              )}
            </div>
          </>
        ) : (
          <div onClick={closeModal}>
            {closeIcon ? (
              <ImageTag src={closeIcon} showCursorPointer />
            ) : (
              <CloseIcon />
            )}
          </div>
        )}
      </PanHdr>
      <PanDtl ref={elementRef}>
        {device === "sm" && panFaqHeading && (
          <H5Tag margin="0 0 16px 0">{panFaqHeading}</H5Tag>
        )}
        {data?.map((item, index) => (
          <FaqItem
            key={`${item.id}`}
            padding={selected === index ? "18px" : ""}
          >
            <FaqItemHdr
              onClick={() => handleFaqs(index)}
              isActive={selected === index}
            >
              <ParaTag fontWeight={selected === index ? "bold" : "regular"}>
                {item.question}
              </ParaTag>
            </FaqItemHdr>
            {selected === index && (
              <>
                <AnswerList>
                  {item.answers &&
                    item.answers.length > 0 &&
                    item.answers.map((answer, index) => (
                      <ParaTag key={`${index}`}>{answer}</ParaTag>
                    ))}
                </AnswerList>
                {item.cta && (
                  <ParaTag margin="18px 0 0 0">
                    <Cta onClick={onCtaClick}>
                      {item.cta}{" "}
                      <CtaIconWpr>
                        {ctaIcon ? <ImageTag src={ctaIcon} /> : <ArrowLarge />}
                      </CtaIconWpr>
                    </Cta>
                  </ParaTag>
                )}
              </>
            )}
          </FaqItem>
        ))}
      </PanDtl>
    </Modal>
  );
};

export default PanFaqs;
