import React, { useState, useRef, useEffect } from "react";
import { Input, InputContainer } from "./OtpPlaceholder.style";
import { FlexComp, ParaTag } from "@tm/static";
import { theme } from "@tm/utility/global";

interface OtpPlaceholderProps {
  numInputs: number;
  onChange: (otp: string) => void;
  error: string;
  loading?: boolean;
  setError?: React.Dispatch<React.SetStateAction<string>>;
}

const OtpPlaceholder = ({
  numInputs,
  onChange,
  error,
  loading,
  setError,
}: OtpPlaceholderProps) => {
  const [otp, setOtp] = useState(new Array(numInputs).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>(
    new Array(numInputs).fill(null)
  );

  useEffect(() => {
    if (otp.length === numInputs) {
      for (let i = 0; i < numInputs; i++) {
        inputRefs.current[i]?.blur();
      }
    }
  }, [loading]);

  const handleClick = (idx: number) => {
    if (setError) {
      setError("");
    }
    const otpString = otp.join("");
    if (idx + 1 > otpString.length && otpString.length < numInputs) {
      inputRefs.current[otpString.length]?.focus();
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const otpValue = e.target.value;
    const newOtp = [...otp];
    newOtp[index] = otpValue;
    setOtp(newOtp);
    onChange(newOtp.join(""));
    if (otpValue && index < numInputs - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]!.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);
      onChange(newOtp.join(""));
      inputRefs.current[index - 1]!.focus();
    }
    if (e.key === "ArrowLeft" && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1]!.focus();
    }
    if (
      e.key === "ArrowRight" &&
      index < numInputs - 1 &&
      inputRefs.current[index + 1]
    ) {
      inputRefs.current[index + 1]!.focus();
    }
    if (e.key.match(/[0-9]/)) {
      // clear the current and all subsequent inputs if the current input is not empty and a new value is entered
      const newOtp = [...otp];
      for (let i = index; i < numInputs; i++) {
        newOtp[i] = "";
        inputRefs.current[i]!.value = "";
      }
      setOtp(newOtp);
      onChange(newOtp.join(""));
      inputRefs.current[index]!.value = "";
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text");
    const pastedOtp = pastedData.slice(0, numInputs).split("");
    const newOtp = [...otp];
    pastedOtp.forEach((digit, index) => {
      newOtp[index] = digit;
    });
    setOtp(newOtp);
    onChange(newOtp.join(""));
  };

  const inputs = [];
  for (let i = 0; i < numInputs; i++) {
    inputs.push(
      <Input
        style={{
          marginRight: `${i < numInputs - 1 ? 8 : 0}px`,
          color: !!error ? theme.redText2 : theme.blackText,
        }}
        type="text"
        id={`input-${i}`}
        key={`input-${i}`}
        inputMode="numeric"
        onPaste={handlePaste}
        value={otp[i]}
        onChange={(e) => handleChange(e, i)}
        onKeyDown={(e) => handleKeyDown(e, i)}
        maxLength={1}
        pattern="[0-9]"
        ref={(ref) => (inputRefs.current[i] = ref)}
        hasError={!!error}
        onClick={() => handleClick(i)}
        disabled={loading ? true : false}
      />
    );
  }

  return (
    <FlexComp flexDirection="column">
      <InputContainer>{inputs}</InputContainer>
      {!!error && (
        <ParaTag
          fontSize="xs"
          fontWeight="regular"
          color={theme.redText2}
          margin="8px 0px 0px 0px"
        >
          {error}
        </ParaTag>
      )}
    </FlexComp>
  );
};

export default OtpPlaceholder;
