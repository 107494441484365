import React from "react";
import { FlexComp, FormattedText, ImageComponent, SpanTag } from "@tm/static";
import { TextNodeType } from "../FormattedText/FormattedText";
import {
  DomesticTripStyle,
  TagStyle,
  ContainerStyle,
  ArrowButtonWrap,
  BannerStyle,
} from "./DomesticTravelDetail.style";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { getAnalytics } from "@tm/insurance/analytics";
import { navigationActions } from "@tm/insurance/navigation";
import { theme } from "@tm/utility/global";

interface IDomesticTripProps {
  title?: TextNodeType;
  trips?: {
    bookingId: string;
    paxInsured: number;
    tag?: {
      title?: TextNodeType;
      startColor?: string;
      endColor?: string;
    };
    title?: TextNodeType;
    subtitle?: TextNodeType;
    description?: TextNodeType;
    image?: string;
    banner?: {
      backgroundColor?: string;
      description?: TextNodeType;
    };
  }[];
}

const analytics = getAnalytics();

const DomesticTrip = ({ trips, title }: IDomesticTripProps) => {
  const device = useBreakpointV2();

  const tripData = trips?.[0];

  const handleDomesticClick = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(searchParams.entries());

    const params = {
      ...queryParams,
      isUpcomingTrip: true,
      bookingId: trips?.[0]?.bookingId,
    };
    analytics?.trackLandingEvents({
      actionType: "upcoming_domestic_trips_clicked",
    });
    navigationActions.navigateToTravellerDetails(params);
  };

  return (
    <ContainerStyle flexDirection="column" gap="10px">
      {title && (
        <FormattedText
          value={title}
          baseStyle={{
            color: theme.blackText,
          }}
        />
      )}
      <DomesticTripStyle>
        <ContainerStyle>
          <TagStyle
            startColor={tripData?.tag?.startColor}
            endColor={tripData?.tag?.endColor}
          >
            <FormattedText value={tripData?.tag?.title} />
          </TagStyle>
        </ContainerStyle>

        <FlexComp
          alignItems="center"
          justifyContent="space-between"
          padding="8px 15px 12px 16px"
          handleClick={handleDomesticClick}
        >
          <SpanTag>
            <FormattedText
              value={tripData?.description}
              baseStyle={{
                color: "#4A4A4A",
                fontWeight: "400px",
                fontSize: "12px",
              }}
            />
            <FlexComp margin="7px 0px 0px 0px" isCursorPointer={true}>
              <ImageComponent
                width="32px"
                height="32px"
                margin="0px 8px 0px 0px"
                src={tripData?.image}
              />
              <div>
                <FormattedText
                  value={tripData?.title}
                  baseStyle={{
                    color: "#000000",
                    fontWeight: "700",
                    fontSize: "14px",
                  }}
                />
                <ContainerStyle>
                  <FormattedText
                    value={tripData?.subtitle}
                    baseStyle={{
                      color: "#4A4A4A",
                      fontWeight: "400",
                      fontSize: "12px",
                    }}
                  />
                </ContainerStyle>
              </div>
            </FlexComp>
          </SpanTag>
          <ArrowButtonWrap>
            <ImageComponent
              width="9px"
              height="9px"
              src="https://tripmoneycmsimgak.mmtcdn.com/img/Arrow_Right_Blue_448003ff39.svg"
            />
          </ArrowButtonWrap>
        </FlexComp>
        <BannerStyle>
          <FormattedText value={tripData?.banner?.description} />
        </BannerStyle>
      </DomesticTripStyle>
    </ContainerStyle>
  );
};

export default DomesticTrip;
