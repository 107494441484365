import { useDevice } from "../../../hooks/useDevice";
import Modal from "../Modal/Modal";
import { CloseIcon } from "../../icons/CloseIcon";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import { theme } from "../../css/Global";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import {
  CurrencyInfoContainer,
  ForexRatesBody,
  ForexRatesDetails,
  ForexRatesFtr,
  ForexRatesHdr,
  ForexRatesLeftContainer,
  ForexRatesRightContainer,
} from "./ForexRatesModal.style";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import SectionDividers from "../SectionDividers/SectionDividers";
import { ForexRatesModalProps } from "./types";

const ForexRatesModal = ({
  modalHeading,
  onModalClose,
  title,
  forexRatesData,
  footerText,
}: ForexRatesModalProps) => {
  const device = useDevice();

  return (
    <Modal
      modalHeading={modalHeading}
      onModalClose={onModalClose}
      closeButton={<CloseIcon />}
      modalType={device === "lg" ? "centered" : "bottomSheet"}
    >
      <ParaTag
        fontSize="xs"
        color={theme.greyText}
        dangerousText={title}
        margin={"0px 0px 20px"}
      />
      <ForexRatesHdr>
        <SpanTag color={theme.blackText} fontSize="xs" fontWeight="bold">
          Card (₹)
        </SpanTag>
        <SpanTag color={theme.blackText} fontSize="xs" fontWeight="bold">
          Currency (₹)
        </SpanTag>
      </ForexRatesHdr>
      {forexRatesData?.map((item, index) => {
        return (
          <ForexRatesBody key={item?.id || index}>
            <SpanTag color={theme.blackText} fontSize="xs" fontWeight="bold">
              {item?.title}
            </SpanTag>
            <SectionDividers height={"1px"} margin="8px 0px 0px" />
            <ForexRatesDetails>
              {item?.data?.map((rateDetails) => {
                return (
                  <li>
                    <ForexRatesLeftContainer>
                      {rateDetails?.flag && (
                        <SpanTag>{rateDetails.flag}</SpanTag>
                      )}

                      <CurrencyInfoContainer>
                        <SpanTag color={theme.blackText} fontSize="xs">
                          {rateDetails?.currencyName}
                        </SpanTag>
                        <SpanTag color={theme.greyText6} fontSize="xs">
                          {rateDetails?.currencyCode}
                        </SpanTag>
                      </CurrencyInfoContainer>
                    </ForexRatesLeftContainer>
                    <ForexRatesRightContainer>
                      <SpanTag
                        textAlign={"right"}
                        color={theme.blackText}
                        fontSize="xs"
                      >
                        {rateDetails?.cardRate}
                      </SpanTag>
                      <SpanTag
                        textAlign={"right"}
                        customWidth={"70px"}
                        color={theme.blackText}
                        fontSize="xs"
                      >
                        {rateDetails?.currencyRate}
                      </SpanTag>
                    </ForexRatesRightContainer>
                  </li>
                );
              })}
            </ForexRatesDetails>
          </ForexRatesBody>
        );
      })}
      <ForexRatesFtr>
        <ParaTag color={theme.greyText} fontSize="xs">
          {footerText}
        </ParaTag>
      </ForexRatesFtr>
    </Modal>
  );
};

export default ForexRatesModal;
