import styled from "styled-components";
import { SpanTagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "@tm/static/utils";

export interface InputStyleProps {
  margin?: string;
  inputWidth?: string;
  Verify?: boolean;
  disableState?: boolean;
  errorState?: string;
  flex?: string;
}

export const InputWrap = styled.div<InputStyleProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.margin};
  width: ${(props) => props.inputWidth};
  flex: ${(p) => (p.flex ? p.flex : "")};
  flex-shrink: 0;
  width: 100%;
  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #4a4a4a;
    display: block;
    margin-bottom: 8px;
    transition: all 0.3s ease;
    display: inline-block;
  }
`;

export const ErrorTxt = styled(SpanTagStyle)`
  color: #eb2026;
  /* position: absolute;
        top: 60px; */
`;

export const LandingInputStyle = styled.input<InputStyleProps>`
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 10px;
  font-weight: 900;
  font-size: 14px;
  line-height: 19px;
  width: 100%;
  height: 41px;
  &::placeholder {
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.3) !important;
  }

  &:focus + label {
    color: #008cff;
  }

  ${(props) =>
    props.disableState &&
    `
      opacity: 0.3;
      pointer-events: none;
	`}
  ${(props) =>
    props.errorState &&
    `
      border: 1px solid #EB2026;
	`}

@media ${deviceType.mobile} {
    width: calc(100vw - 32px);
  }

  ${(props) =>
    props.Verify &&
    `
      color: #008CFF;
      background: #F9FCFF;
      border: 1px solid #008CFF;
      pointer-events: none;
	`}

  &:focus {
    outline: none;
    border: 1px solid #008cff;
    background: #f9fcff;
  }
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const CalenderWrap = styled.div`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 36px;
`;
