import { getCookieExpiryTime, persistSpecificParamInCookie } from "../common";
import { getCookie } from "../common/cookieUtils";
import { COOKIES, MMT_SUB_DOMAIN, TM_AUTH_EXPIRY_DAYS } from "../constants";
import { AuthAbstract } from "./baseAuth";

export class MMTAndroidAuth extends AuthAbstract {
  partner = "MMT";
  accType = "MMT";

  getSLTToken(): Promise<null | string> {
    return new Promise((resolve, reject) => {
      let tokenSent: boolean = false;

      if (!window || !window?.app_bridge?.getShortLivToken) {
        return reject(
          new Error(
            "Failed to get getShortLiveToken bridge handler in MMT Android"
          )
        );
      }

      window.shortLivToken = (token: string | undefined) => {
        if (!token) {
          return reject(new Error("Failed to get SLT from MMT Android"));
        }
        resolve(token);
        tokenSent = true;
      };

      window.app_bridge.getShortLivToken(
        JSON.stringify({ client: "TM" }),
        "shortLivToken"
      );

      setTimeout(() => {
        if (!tokenSent) {
          console.error("SLT token not sent after 5s in MMT Android");
        }
      }, 5000);
    });
  }

  saveTMAuthInCookie(tmAuth: string) {
    const partnerAuth = this.getPartnerAuthFromCookie();
    persistSpecificParamInCookie(COOKIES.TM_AUTH_KEY, tmAuth, {
      path: "/",
      domain: MMT_SUB_DOMAIN,
      expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
    });
    //Need to keep mapping of mmt_auth and tm_auth to  validate  partner and tm_auth mismatch
    persistSpecificParamInCookie(
      COOKIES.TM_MMT_AUTH_KEY,
      `${tmAuth}___${partnerAuth}`,
      {
        path: "/",
        domain: MMT_SUB_DOMAIN,
        expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
      }
    );
  }

  saveTMAuth(tmAuth: string) {
    this.saveTMAuthInCookie(tmAuth);
  }

  getPartnerAuthFromCookie(): string {
    const mmt_auth = getCookie(COOKIES.MMT_AUTH_KEY, true) || "";
    return mmt_auth;
  }

  getTMAuth(): Promise<string> {
    // TODO - Confirm why bridge method is not used similar to iOS?
    return new Promise((resolve) => {
      resolve(this.getTMAuthFromCookie());
    });
  }

  /**
   * Retrieves the TM_AUTH value from the cookie.
   * @returns The TM_AUTH value from the cookie.
   */
  getTMAuthFromCookie() {
    const tm_auth = getCookie(COOKIES.TM_AUTH_KEY, true) || "";
    return tm_auth;
  }
}
