import styled from "styled-components";

export const LandingButtonStyle: any = styled.button`
  background: var(
    --btnBg,
    linear-gradient(91.91deg, #000000 0.3%, #330e0e 100%)
  );
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.21);
  border-radius: 6px;
  font-weight: 900;
  font-size: var(--btnFont, 16px);
  line-height: 20px;
  color: var(--btnColor, #fff);
  width: var(--customWidth, fit-content);
  height: var(--customHeight, 44px);
  border: none;
  outline: none;
  cursor: pointer;
  margin: var(--btnMargin, unset);
  flex: var(--btnFlex, unset);
  flex-shrink: var(--btnFlexShrink, unset);
  border: var(--btnBorder);
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: var(--btnPadding, 12px);
  text-transform: uppercase;

  ${(props: any) =>
    props.btnIcon &&
    `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:16px;
  }
	`}

  ${(props: any) =>
    props.disableState &&
    `
  background: #CBCBCB;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.3);
  `}
`;

export const ShimmerWrap = styled.div`
  position: absolute;
  width: 68px;
  height: 48px;
  z-index: 12;
  top: -43px;
  animation: shimmmer 2.9s ease-in;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;

  @keyframes shimmmer {
    0% {
      transform: translateX(-311px);
    }
    100% {
      transform: translateX(311px);
    }
  }
`;

export const BtnMainIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LandingButtonWhiteStyle = styled(LandingButtonStyle)`
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #008cff;
`;
