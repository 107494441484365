import React from "react";
import { UserInfoProps } from "./UserInfoProps";
import { UserCont, UserIcon } from "./UserInfo.styles";
import ParaTag from "../TextStyles/ParaTag";

const UserInfo: React.FC<UserInfoProps> = ({
  userName,
  greetingText = "Hey",
  onPress,
}) => {
  return (
    <UserCont onClick={onPress}>
      <UserIcon>{userName.split("")[0]}</UserIcon>
      <ParaTag color="#fff" fontSize="xs">
        {greetingText} {userName}
      </ParaTag>
    </UserCont>
  );
};

export default UserInfo;
