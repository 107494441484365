import styled from "styled-components";
import { SpanTagStyle } from "../TextStyles/TextStyles.style";
import { ImageTagStyle } from "../ImageTag/ImageTag.style";
import { deviceType } from "@tm/static/utils";

export const FeaturesListCompStyle = styled.div<any>`
  display: flex;
  margin: ${(props) => (props.margin ? props.margin : "0px")};

  @media ${deviceType.mobile} {
    flex-direction: column-reverse;
    padding: 32px 16px 56px 16px;
  }
`;

export const FeaturesList = styled.div<any>`
  flex: 0.9;
  padding-top: 50px;
  @media ${deviceType.mobile} {
    padding-top: unset;
  }
`;

export const Ul = styled.ul<any>`
  margin-top: 40px;

  @media ${deviceType.mobile} {
    margin-top: 32px;
  }
`;

export const Li = styled.li<any>`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const YellowBox = styled.span<any>`
  width: 5px;
  height: 14px;
  background: linear-gradient(45deg, #edd287 14.64%, #b8860b 85.36%);
  margin-right: 12px;
  margin-top: 5px;
  border-radius: 3px;
  flex-shrink: 0;
`;

export const ListLabel = styled(SpanTagStyle)<any>`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  flex: 1;
  > span {
    font-weight: 700;
  }

  @media ${deviceType.mobile} {
    font-size: ${(props) => (props.small === true ? "14px" : "18px")};
    line-height: ${(props) => (props.small === true ? "19px" : "24px")};
  }
`;

export const FeatureCardsWpr = styled.div<any>`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  height: 440px;
  margin-left: 16px;
  @media ${deviceType.mobile} {
    height: unset;
    flex-direction: column;
    align-items: unset;
    margin-left: unset;
  }
`;

export const Image = styled(ImageTagStyle)<any>`
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0px;
  right: -60px;
  object-fit: contain;

  @media only screen and (max-width: 1260px) {
    right: 0px;
  }
`;

export const FeatureCards = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  position: relative;

  @media ${deviceType.mobile} {
    flex-wrap: unset;
    flex-direction: column;
    gap: unset;
    row-gap: 16px;
    flex: 1;
    margin-top: 32px;
  }
`;

export const FeatureCard = styled.div<any>`
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 16px;
  width: calc(50% - 8px);

  @media ${deviceType.mobile} {
    width: unset;
  }
`;

export const InfoIconWpr = styled.span<any>`
  margin-left: 8px;
  display: inline-block;
  margin-bottom: -4px;

  @media ${deviceType.mobile} {
    margin-left: 4px;
    margin-bottom: -2px;
  }
`;
