import styled from "styled-components";

import { ParaTagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "@tm/static/utils";

export const PersuasionCrawlerStyle = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px 80px;
  background: #f6f6f6;
  border-radius: 16px;

  @media ${deviceType.mobile} {
    border-radius: unset;
    padding: 25px;
    & {
      overflow-x: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
    }
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
    pointer-events: none;
  }
`;

export const PersuasionItem = styled.div<any>`
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const Label = styled(ParaTagStyle)<any>`
  width: max-content;
  font-family: "Lato";
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;

  @media ${deviceType.mobile} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const SubLabel = styled(ParaTagStyle)<any>`
  width: max-content;
  margin-top: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  @media ${deviceType.mobile} {
    font-size: 12px;
    line-height: 16px;
    margin-top: 0px;
  }
`;
