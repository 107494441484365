import { useGlobalConfig } from "../useGlobalConfig";
import {
  errorFetchingDeliveryDetails,
  fetchDeliveryDetailsAction,
  fetchedPersonaAndVersionOnLoad,
  loadingDeliveryDetailsAction,
} from "./../../store/action";
import { useGlobalDispatch } from "../useGlobalDispatch";
import {
  DELIVERY_DETAILS_FILTER_MODE,
  SESSION_STORAGE_KEYS,
  ERROR_MSGS,
  getSessionPiiDataOpts,
  setSessionPiiDataOpts,
  shortenHeader,
  ERROR_CODE,
} from "../../constants";
import { fetchApiForexListing } from "../../api/fetchApi";
import { useGlobalSelector } from "../useGlobalSelector";
import { getContextUrl, getRequestIdHeaders } from "../../utils";
import {
  getSessionStorageItem,
  setSessionStorageItem,
  removeSessionStorageItem,
} from "../../utils/clientStorageUtils";
import { getForexRatesFilterInfo } from "../useFetchForexRates";
import { ListingReducerType } from "../../store/forexListingReducer";
import { ERROR_TYPE, ForexRates } from "../../types";
import { useDevice } from "../useDevice";
import { useListingPdtLogger } from "../usePdtLogger";
import useListingPdtLoggerV2 from "../useListingPdtLoggerV2";

export interface ILocation {
  latitude?: number | null;
  longitude?: number | null;
  pincode?: number | null;
  city?: string | null;
  city_name?: string | null;
  locus_city_code?: string | null;
  reqId?: string;
}

export interface ILocationBodyV2 {
  query: {
    mandatory: {
      delivery_details?: object;
      persona: boolean;
    };
    precompute: {
      forex_rates: {
        filter: {
          mode: string;
          currencies?: Array<string>;
        };
      };
      products: {
        inr_card: {
          delivery_details: boolean;
          persuasions: boolean;
        };
        multi_cx_card: {
          cta?: boolean;
          delivery_details: boolean;
          persuasions: boolean;
          card_cta?: boolean;
          program_details?: boolean;
        };
        currency: {
          cta?: boolean;
          delivery_details: boolean;
          persuasions: boolean;
        };
      };
      assistance_flow: {
        type: boolean;
      };
    };
  };
  data?: {
    location?: ILocation;
  } | null;
}
export interface ILocationBody {
  query: {
    mandatory: {
      delivery_details?: object;
    };
    precompute: {
      forex_rates: {
        filter: {
          mode: string;
          currencies?: Array<string>;
        };
      };
      persona: boolean;
      products: {
        inr_card: {
          delivery_details: boolean;
          persuasions: boolean;
        };
        multi_cx_card: {
          cta?: boolean;
          delivery_details: boolean;
          persuasions: boolean;
          card_cta?: boolean;
          program_details?: boolean;
        };
        currency: {
          cta?: boolean;
          delivery_details: boolean;
          persuasions: boolean;
        };
      };
      assistance_flow?: {
        type: boolean;
      };
    };
  };
  data?: {
    location?: ILocation;
  } | null;
}

const useFetchDeliveryDetails = ({
  latitude,
  longitude,
  pincode,
  reqId,
}: ILocation) => {
  const dispatch = useGlobalDispatch();
  const { partner } = useGlobalConfig();
  const device = useDevice();
  const {
    forex_rates,
    isLoadingDeliveryDetails,
    isErrorDeliveryDetails,
    errorMessageFetchingDeliveryDetails,
  } = useGlobalSelector((state: ListingReducerType) => state || {});
  const { sendPdtListingError } = useListingPdtLogger();
  const { sendPdtListingErrorV2 } = useListingPdtLoggerV2();

  const fetchUserDeliveryInfo = async (fromSession = false): Promise<any> => {
    const contextUrl = getContextUrl(partner);
    try {
      if (fromSession) {
        dispatch(loadingDeliveryDetailsAction());
        const userLocationSession = getSessionStorageItem(
          SESSION_STORAGE_KEYS.USER_LOCATION_SESSION_KEY,
          true,
          getSessionPiiDataOpts
        );
        const request_id = getSessionStorageItem(
          SESSION_STORAGE_KEYS.REQUEST_ID,
          true
        );
        if (userLocationSession) {
          dispatch(
            fetchDeliveryDetailsAction({
              ...userLocationSession,
            })
          );
          return { userLocationSession, request_id };
        }
      }

      const data = getLocationQuery(
        forex_rates,
        {
          latitude,
          longitude,
          pincode,
        },
        device
      );
      dispatch(loadingDeliveryDetailsAction());
      const response = await fetchApiForexListing(contextUrl, {
        method: "POST",
        data,
        headers: {
          ...getRequestIdHeaders(reqId),
        },
      });
      if (response?.success) {
        const { request_id, delivery_details, persona, version } =
          response?.data || {};

        const userLocationData = {
          latitude,
          longitude,
          ...delivery_details,
        };
        dispatch(fetchDeliveryDetailsAction(userLocationData));
        dispatch(fetchedPersonaAndVersionOnLoad({ persona, version }));
        if (
          userLocationData?.eta_in_days >= 0 &&
          (userLocationData?.pincode || userLocationData?.city)
        ) {
          setSessionStorageItem(
            SESSION_STORAGE_KEYS.USER_LOCATION_SESSION_KEY,
            userLocationData,
            true,
            setSessionPiiDataOpts
          );
          setSessionStorageItem(
            SESSION_STORAGE_KEYS.REQUEST_ID,
            request_id,
            true
          );
          if (userLocationData?.detection_source === "city_fallback") {
            setSessionStorageItem(
              SESSION_STORAGE_KEYS.IS_FALLBACK_CITY_MODE,
              "true",
              true
            );
          }
        }
        return { userLocationData, request_id };
      } else {
        const error = response || {};
        const { type }: { type: ERROR_TYPE } = error || {};

        if (type === "INLINE") {
          dispatch(errorFetchingDeliveryDetails(error));
        } else {
          error.errorDescription = ERROR_MSGS.FAILED_TO_FETCH_LOC;
          sendPdtListingError(error);
          sendPdtListingErrorV2(error);

          dispatch(
            errorFetchingDeliveryDetails({
              description: ERROR_MSGS.FAILED_TO_FETCH_LOC,
            })
          );
        }
        removeSessionStorageItem(
          SESSION_STORAGE_KEYS.USER_LOCATION_SESSION_KEY
        );
        removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      }
    } catch (e: any) {
      sendPdtListingError({
        apiUrl: contextUrl,
        code: e?.message?.includes(ERROR_MSGS.FETCH_ERROR)
          ? ERROR_CODE.NETWORK
          : "",
        description: e?.message?.includes(ERROR_MSGS.FETCH_ERROR)
          ? ERROR_MSGS.FETCH_ERROR
          : e?.message,
      });
      sendPdtListingErrorV2({
        errorDescription: `fetchUserDeliveryInfo: ${e?.message}`,
      });
      dispatch(
        errorFetchingDeliveryDetails({
          description: ERROR_MSGS.FAILED_TO_FETCH_LOC,
        })
      );
      removeSessionStorageItem(SESSION_STORAGE_KEYS.USER_LOCATION_SESSION_KEY);
      removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      console.error(e);
    }
  };

  return {
    isLoadingDeliveryDetails,
    isErrorDeliveryDetails,
    errorMessageFetchingDeliveryDetails,
    fetchUserDeliveryInfo,
  };
};

export default useFetchDeliveryDetails;

export function getLocationQuery(
  forex_rates: ForexRates | null | undefined,
  location: ILocation | undefined,
  device: string | null
): ILocationBodyV2 {
  const { latitude, longitude, pincode, city, city_name, locus_city_code } =
    location || {};

  const filter = getForexRatesFilterInfo(forex_rates);
  const data = getLocationBody({
    latitude,
    longitude,
    pincode,
    city,
    city_name,
    locus_city_code,
  });
  return {
    query: {
      mandatory: {
        delivery_details: {
          filter: {
            mode:
              locus_city_code ||
              getSessionStorageItem(
                SESSION_STORAGE_KEYS.IS_FALLBACK_CITY_MODE,
                true
              ) === "true"
                ? DELIVERY_DETAILS_FILTER_MODE.CITY_FALLBACK
                : DELIVERY_DETAILS_FILTER_MODE.AUTO,
          },
        },
        persona: true,
      },
      precompute: {
        forex_rates: {
          filter,
        },
        products: {
          inr_card: {
            delivery_details: device === "sm" ? true : false,
            persuasions: true,
          },
          multi_cx_card: {
            cta: true,
            delivery_details: device === "sm" ? true : false,
            persuasions: true,
            card_cta: true,
            program_details: true,
          },
          currency: {
            cta: true,
            delivery_details: device === "sm" ? true : false,
            persuasions: true,
          },
        },
        assistance_flow: {
          type: true,
        },
      },
    },
    data,
  };
}

export function getLocationBody({
  latitude,
  longitude,
  pincode,
  city,
  city_name,
  locus_city_code,
}: ILocation) {
  let body = null;
  if (latitude && longitude) {
    body = {
      location: {
        latitude,
        longitude,
        city,
        city_name,
        locus_city_code,
      },
    };
  }
  if (pincode) {
    body = {
      location: {
        pincode,
        city,
        city_name,
        locus_city_code,
      },
    };
  }
  return body;
}
