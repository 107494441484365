import styled from "styled-components";

export const AmountBreakupStyle = styled.div``;

export const BreakupList = styled.ul`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  li {
  }
`;

export const SubBreakupList = styled.div`
  position: relative;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  ::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #757575;
    left: 0;
    top: 10px;
  }
`;
