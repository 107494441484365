import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useEffect } from "react";

export const useForexCardAnimation = () => {
  const ANIMATION_TYPE = "ease-in-out";
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    setTimeout(() => {
      const rootContainerElement = document.getElementById("root_container");
      const currRefElement = document.getElementById("curr_ref");
      const greyRefElement = document.getElementById("grey_ref");
      const padRefElement = document.getElementById("pad_ref");
      const currDelLeftElement = document.getElementById("curr_del_left");
      const bmfCardElement = document.getElementById("bmf_card");
      const forexCardElement = document.getElementById("forex_card");
      const transLeftElement = document.getElementById("trans_left");

      gsap.to(currRefElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currRefElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        width: "100%",
        borderRadius: 0,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(padRefElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currRefElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        width: "1200px",
        ease: ANIMATION_TYPE,
      });
      gsap.to(currDelLeftElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currRefElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        visibility: "visible",
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });

      gsap.to(bmfCardElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: greyRefElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        y: -95,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(forexCardElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: greyRefElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        y: 40,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(transLeftElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: greyRefElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        opacity: 1,
        delay: 0.3,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
    }, 300);
  }, []);
};
