import styled, { keyframes } from "styled-components";
import { theme } from "../../css/Global";
import { deviceType } from "../../utils";

interface MarqueeContainerProps {
  isPaused: boolean;
  isOnBottom: boolean;
}

const dotsFadeInOne = keyframes`
    0% { opacity: 0.3; };
    33% {opacity: 1;}
    100% { opacity: 1; };
`;

const dotsFadeInTwo = keyframes`
    0% { opacity: 1; };
    33% {opacity: 0.3;}
    100% { opacity: 1; };
`;

const dotsFadeInThree = keyframes`
    0% { opacity: 1; };
    66% {opacity: 0.3;}
    100% { opacity: 1; };
`;

const marqueeScroll = keyframes`
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(calc(-100%));
        }
`;

export const MarqueeBarStyle = styled.div<any>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.gap ? props.gap : "0px")};
  border-radius: 4px 0px 0px 4px;
  /* ${(props: any) =>
    !props.isOnBottom &&
    `
        background-color: rgba(74, 74, 74, 0.3);
    `} */
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.padding && `padding: ${props.padding};`}
`;

export const Tag = styled.div`
  padding: 12px 15px;
  border-radius: 4px 0px 0px 4px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  z-index: 2;
  background-color: ${theme.redText3};

  &:after {
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    top: -61px;
    z-index: -1;
    left: -60px;
    background-color: ${theme.redText4};
    transform: rotate(53deg);
  }

  @media screen and ${deviceType.mobile} {
    padding: 12px 6px;
  }
`;

export const DotsAnimation = styled.div<any>`
  padding-top: 6px;
  display: flex;
  & > span {
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin-right: 2px;
    background-color: ${(props) =>
      props.isOnBottom ? theme.yellowText : theme.yellowText4};
  }

  & > span:nth-child(1) {
    animation: ${dotsFadeInOne} linear 2s infinite;
  }

  & > span:nth-child(2) {
    animation: ${dotsFadeInTwo} linear 2s infinite;
  }

  & > span:nth-child(3) {
    animation: ${dotsFadeInThree} linear 2s infinite;
  }

  & > span:last-child {
    margin-right: 0px;
  }
`;

export const MarqueeContainer: any = styled.div`
  /* --gap: 20px; */
  position: relative;
  display: flex;
  user-select: none;
  overflow: hidden;
  width: 100%;

  ${(props: any) =>
    props.isOnBottom &&
    `
        background-color: ${theme.smokeWhite};
    `}

  @media screen and ${deviceType.mobile} {
    ${(props: any) => props.isOnBottom && `padding: 8px;`}
    z-index: 10;
    width: 100%;
    overflow-x: scroll;
  }
`;

export const MarqueeRibbon = styled.ul<MarqueeContainerProps>`
  padding: 0;
  list-style: none;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  /* gap: 20px; */
  min-width: 100%;
  animation: ${marqueeScroll} 20s linear infinite;
  & > li {
    /* display: flex; */
    /* flex: 0 0 auto; */
    /* ${(props: any) =>
      props.isOnBottom
        ? `color: ${theme.blackText};`
        : `color: ${theme.whiteText};`} */
    margin: 0px 10px;
    /* text-align: center; */
  }

  @media screen and ${deviceType.mobile} {
    animation: none;
  }

  ${(props) => props.isPaused && `animation-play-state: paused !important;`}
`;
