import { useEffect, useReducer } from "react";

import {
  getMobileOperatingSystem,
  removeSessionStorageItem as removeSessionStorageData,
  setSessionStorageItem,
} from "@tm/utility";
import { useBreakpoint } from "../useBreakpoint";

export interface IGeoLocation {
  latitude: number | null;
  longitude: number | null;
}

export interface InitialState {
  location: IGeoLocation | null;
  error: string | null;
  isLoading: boolean;
}

const initialState: InitialState = {
  location: null,
  error: null,
  isLoading: false,
};

const LOCATION_FETCH_LOADING = "LOCATION_FETCH_LOADING";
const LOCATION_FETCH_SUCCESS = "LOCATION_FETCH_SUCCESS";
const LOCATION_FETCH_ERROR = "LOCATION_FETCH_ERROR";

const reducer = (
  state = initialState,
  { type, payload }: { type: string; payload?: any }
) => {
  switch (type) {
    case LOCATION_FETCH_LOADING:
      return { ...initialState, isLoading: true };
    case LOCATION_FETCH_SUCCESS:
      return { ...initialState, location: payload };
    case LOCATION_FETCH_ERROR:
      return { ...initialState, error: payload };
    default:
      return state;
  }
};

export const useGeoLocation = (
  isSaveInSession = false,
  userSessionKey = "user_location"
) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const device = useBreakpoint();

  const setUserLocationWeb = () => {
    if (navigator.permissions) {
      dispatch({ type: LOCATION_FETCH_LOADING });
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          dispatch({
            type: LOCATION_FETCH_SUCCESS,
            payload: { latitude, longitude },
          });
        },
        (error) => {
          dispatch({ type: LOCATION_FETCH_ERROR, payload: error });
        }
      );
    } else {
      console.error("Geolocation not supported");
    }
  };

  const setUserLocationAndroid = () => {
    dispatch({ type: LOCATION_FETCH_LOADING });
    // @ts-ignore
    window.fetchUserLocation = (userLocation: string) => {
      const location = JSON.parse(userLocation);
      const { Latitude, Longitude } = location || {};
      if (Latitude && Longitude) {
        dispatch({
          type: LOCATION_FETCH_SUCCESS,
          payload: { latitude: Latitude, longitude: Longitude },
        });
      } else {
        dispatch({
          type: LOCATION_FETCH_ERROR,
          payload: { message: "Location denied" },
        });
      }
    };
    // @ts-ignore
    window?.app_bridge?.fetchLocation("fetchUserLocation");
  };

  const setUserLocation = () => {
    const mobileOS = getMobileOperatingSystem();
    // @ts-ignore
    if (mobileOS.toLowerCase() === "android" && window?.app_bridge) {
      console.log("android");
      setUserLocationAndroid();
      return;
    }
    setUserLocationWeb();
  };

  const setUserLocationIfPermittedDesktop = () => {
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (permissionStatus) {
          if (permissionStatus.state === "granted") {
            setUserLocationWeb();
          } else {
            // Permission not granted, do not request location
            console.error("Permission not granted", permissionStatus.state);
          }
        });
    } else {
      // Geolocation not supported
      console.log("Geolocation not supported");
    }
  };
  const setUserLocationIfPermittedAndroid = () => {
    // TODO: Once method is available on bridge.
  };
  const setUserLocationIfPermittedIOS = () => {
    // TODO: Once method is available on bridge.
  };
  const setUserLocationIfPermitted = () => {
    if (device === "lg") {
      setUserLocationIfPermittedDesktop();
    } else if (device === "sm") {
      // @ts-ignore
      if (window?.app_bridge) {
        const mobileOS = getMobileOperatingSystem();
        if (mobileOS.toLowerCase() === "android") {
          setUserLocationIfPermittedAndroid();
        } else if (mobileOS.toLocaleLowerCase() === "ios") {
          setUserLocationIfPermittedIOS();
        }
      }
    }
  };

  useEffect(() => {
    if (isSaveInSession) {
      setSessionStorageItem(userSessionKey, state.location, true, {
        isPiiData: true,
        shouldStringifyJson: true,
      });
    } else {
      removeSessionStorageData(userSessionKey);
    }
  }, [state.location]);

  return {
    ...state,
    setUserLocation,
    setUserLocationIfPermitted,
  };
};
