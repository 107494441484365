import styled, { keyframes } from "styled-components";

const calenderAnim = keyframes`
    0% {
        /* top: -100vh; */
        bottom: -100%;
        opacity: 0;
    }
    100% {
        bottom: 0;
        opacity: 1;
    }
`;

export const RangeDatePickerStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  left: 0;
  /* top: -100vh; */
  bottom: -100%;
  z-index: 10;
  background-color: #fff;
  animation: ${calenderAnim} 0.3s ease-in-out;
  animation-fill-mode: forwards;
`;

export const ErrorToast = styled.div`
  border-radius: 6px;
  background: #fcdadb;
  padding: 12px 16px;
  margin: 0 16px 16px;
  width: auto;
`;
