export const generateSourceSetImageUrl = (
  cdnUrlWidths: Array<number>,
  imgUrl: string,
  width: number
) => {
  let imgWidths = cdnUrlWidths;
  if (!imgWidths || !Array.isArray(imgWidths) || imgWidths.length === 0)
    imgWidths = [width, width * 2, width * 3];

  return imgWidths
    .map((width) => `${imgUrl}?im=Resize=(${width}) ${width}w`)
    .join(",");
};
