import styled from "styled-components";

type PoweredByStyleProps = {
  margin?: string;
};

export const PoweredByStyle = styled.div<PoweredByStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 18px;
  margin: ${(props) => props.margin};
`;
