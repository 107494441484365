import styled from "styled-components";
import { deviceType } from "../../utils";

export const ForexOfferingsStyle = styled.div<any>`
  width: 285px;
  @media ${deviceType.desktop} {
    display: flex;
    flex: 1;
  }
  overflow: hidden;
  flex-direction: column;
  flex-shrink: 0;
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 3px;
  margin: ${(p) => (p.margin ? p.margin : "")};
`;

export const ForexOfferingCntnt = styled.div`
  padding: 18px;
`;

export const ForexOfferingList = styled.ul`
  padding: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0 0 18px 0;
  li {
    position: relative;
    padding: 0 0 0 18px;
  }
  li:before {
    content: "";
    position: absolute;
    background: #008cff;
    width: 5px;
    height: 14px;
    top: 8px;
    left: 0;
    border-radius: 4px;
  }
`;

export const LabelCtr = styled.div`
  margin-bottom: 20px;
`;

export const CardImgContainer = styled.div`
  width: 590px;
  height: 148px;
`;
