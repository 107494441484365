import { ModalProps } from "./ModalProps";
import { theme } from "../../../css/Global";

import FlexComp from "../../ui-atoms/FlexComp/FlexComp";
import ParaTag from "../../ui-atoms/TextStyles/ParaTag";
import H5Tag from "../../ui-atoms/TextStyles/H5Tag";
import { ModalBackdrop, ModalWrapper } from "./Modal.styles";

const Modal = ({
  children,
  modalWidth,
  modalHeading,
  modalHeadingComp,
  modalSubHeading,
  modalHeadingCtrMargin = "0 0 32px 0",
  closeButton,
  onModalClose,
  padding,
  modalType = "centered",
  backBtn,
  showBackBtn = false,
  stickToTop = false,
}: ModalProps) => {
  return (
    <>
      <ModalWrapper
        padding={padding}
        modalType={modalType}
        modalWidth={modalWidth}
        stickToTop={stickToTop}
      >
        {modalType === "fullPage" && showBackBtn ? (
          <FlexComp
            alignItems="center"
            margin={modalHeadingCtrMargin}
            width="100%"
          >
            {backBtn && <span onClick={onModalClose}>{backBtn}</span>}
            {(modalHeading || modalSubHeading) &&
              (modalSubHeading ? (
                <FlexComp flexDirection="column" margin="0px">
                  {modalHeading && (
                    <ParaTag
                      fontSize="md"
                      margin={"0px 0px 0px 20px"}
                      color={theme.greyText}
                      fontWeight="bold"
                    >
                      {modalHeading}
                    </ParaTag>
                  )}
                  <ParaTag
                    fontSize="xs"
                    margin={"0px 0px 0px 20px"}
                    color={theme.greyText6}
                  >
                    {modalSubHeading}
                  </ParaTag>
                </FlexComp>
              ) : (
                <ParaTag
                  fontSize="md"
                  margin={"0px 0px 0px 20px"}
                  color={theme.greyText}
                  fontWeight="bold"
                >
                  {modalHeading}
                </ParaTag>
              ))}
            {modalHeadingComp && modalHeadingComp}
          </FlexComp>
        ) : (
          (modalHeading || closeButton || modalHeadingComp) && (
            <FlexComp
              alignItems="center"
              justifyContent="space-between"
              margin={modalHeadingCtrMargin}
              width="100%"
              height="100%"
            >
              {(modalHeading || modalSubHeading) && modalSubHeading ? (
                <FlexComp margin={"0px"} flexDirection="column">
                  {modalHeading && <H5Tag>{modalHeading}</H5Tag>}
                  <ParaTag fontSize="xs" color={theme.greyText}>
                    {modalSubHeading}
                  </ParaTag>
                </FlexComp>
              ) : (
                modalHeading && <H5Tag>{modalHeading}</H5Tag>
              )}
              {modalHeadingComp && modalHeadingComp}
              {closeButton && <span onClick={onModalClose}>{closeButton}</span>}
            </FlexComp>
          )
        )}

        {children}
      </ModalWrapper>
      <ModalBackdrop onClick={onModalClose} />
    </>
  );
};

export default Modal;
