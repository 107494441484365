import React, { useEffect, useState } from "react";

import { ImageTag, LandingButton } from "@tm/static";

import {
  ForexCardCompMwebStyle,
  AnimationWpr,
  CardImgStyle,
  Label,
} from "./ForexComp.style";

import { theme } from "@tm/utility/global";

import BMFCardImg from "../../icons/bmf_card_img.png";

import { ForexCardCompMwebProps } from "./types";

const ForexCardCompMweb: React.FC<ForexCardCompMwebProps> = ({
  label,
  imgSrc,
}) => {
  // const [visible, setVisible] = useState(1);

  // useEffect(() => {
  //     const timer = setTimeout(() => {
  //         setVisible(1);
  //     },2000);

  //     return () => {
  //         clearTimeout(timer);
  //     }
  // },[]);

  return (
    <ForexCardCompMwebStyle>
      <AnimationWpr>
        <CardImgStyle>
          <ImageTag src={imgSrc ? imgSrc : BMFCardImg.src} width={"100%"} />
        </CardImgStyle>
        {label && <Label dangerouslySetInnerHTML={{ __html: label }}></Label>}
      </AnimationWpr>
    </ForexCardCompMwebStyle>
  );
};

export default React.memo(ForexCardCompMweb);
