import * as React from "react";
interface iconprop {}
const OthersIcon = (props: iconprop) => (
  <svg
    width={20}
    height={19}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={0.503906}
      width={18}
      height={18}
      rx={1.8}
      stroke="#999999"
      strokeWidth={0.45}
    />
    <rect
      x={1}
      y={0.503906}
      width={18}
      height={18}
      rx={1.8}
      stroke="#999999"
      strokeWidth={0.45}
    />
    <rect
      x={4.6001}
      y={5.00391}
      width={10.8}
      height={1.8}
      rx={0.9}
      fill="url(#paint0_linear_841_15496)"
    />
    <rect
      x={4.6001}
      y={8.60352}
      width={10.8}
      height={1.8}
      rx={0.9}
      fill="url(#paint1_linear_841_15496)"
    />
    <rect
      x={4.6001}
      y={12.2031}
      width={10.8}
      height={1.8}
      rx={0.9}
      fill="url(#paint2_linear_841_15496)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_841_15496"
        x1={4.6001}
        y1={6.80391}
        x2={13.9556}
        y2={2.11922}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7F3F" />
        <stop offset={1} stopColor="#FF3E5E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_15496"
        x1={4.6001}
        y1={10.4035}
        x2={13.9556}
        y2={5.71883}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7F3F" />
        <stop offset={1} stopColor="#FF3E5E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_841_15496"
        x1={4.6001}
        y1={14.0031}
        x2={13.9556}
        y2={9.31844}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7F3F" />
        <stop offset={1} stopColor="#FF3E5E" />
      </linearGradient>
    </defs>
  </svg>
);
export { OthersIcon };
