import { useState } from "react";

export default function CurrencyConverter({
  currConversion,
  hideSeparator,
}: any) {
  const [showViewMore, setShowViewMore] = useState<boolean>(true);
  const [cardsToBeShown, setCardsToBeShown] = useState<number>(4);

  const handleOnClick = () => {
    setShowViewMore(!showViewMore);
    // setCardsToBeShown(14);
  };

  return (
    <>
      {!hideSeparator && <div className="separator"></div>}
      <h2 className="h2">{currConversion?.title}</h2>
      <ul className={`capSulesList grid5 ${!showViewMore ? "showAll" : ""}`}>
        {currConversion?.cards?.map((card: any, index: number) => {
          // if (cardsToBeShown > index) {
          return (
            <li key={`${card?.title}${index}`} className="capsule">
              <a href={card?.href} target="_blank">
                {card?.title}
              </a>
            </li>
          );
          // }
          // return null;
        })}
      </ul>
      {showViewMore && (
        <p className="viewMore">
          <a className="viewMore__cta" onClick={() => handleOnClick()}>
            View More
          </a>
        </p>
      )}
    </>
  );
}
