import styled from "styled-components";
import { deviceType } from "../utils";

export const InnerContentWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  background: white;
  @media screen and ${deviceType.mobile} {
    width: 100%;
  }
`;

export const WhiteBackgroundStyled = styled.div`
  background: white;
`;

export const GreyBackgroundStyled = styled.div`
  background: #f6f6f6;
  padding: 16px;
  gap: 9px;
`;

export const LicenseFooterWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  background: #f6f6f6;
  @media screen and ${deviceType.mobile} {
    width: 100%;
  }
`;
