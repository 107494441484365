import * as React from "react";
interface iconProps {}
const WarningIcon = (props: iconProps) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={16} r={16} fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0004 6.78552C15.2054 6.78552 14.5686 7.44459 14.596 8.2392L14.9973 19.877C15.016 20.4176 15.4596 20.8461 16.0004 20.8461C16.5413 20.8461 16.9849 20.4176 17.0036 19.877L17.4049 8.2392C17.4323 7.44459 16.7955 6.78552 16.0004 6.78552ZM16.0004 25.2107C16.8038 25.2107 17.455 24.5595 17.455 23.7562C17.455 22.9529 16.8038 22.3017 16.0004 22.3017C15.1971 22.3017 14.5459 22.9529 14.5459 23.7562C14.5459 24.5595 15.1971 25.2107 16.0004 25.2107Z"
      fill="#6B4300"
    />
  </svg>
);
export { WarningIcon };
