import React, { useState } from "react";

import { FlexComp, ParaTag, SpanTag } from "@tm/static";
import {
  OrderRightStyle,
  OrderRightHd,
  OrderRightCntnt,
  PriceText,
  MmtServiceFee,
} from "./OrderDetailRightComp.style";
import { theme } from "@tm/utility/global";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";

interface accordionProp {
  title?: string | TextNodeType;
  info?: string | TextNodeType;
  priceBreakup?: {
    title?: string | TextNodeType;
    price?: string | TextNodeType;
  }[];
  mmtServiceFee?: {
    title?: string | TextNodeType;
    price?: string | TextNodeType;
  };
  totalPrice?: string | TextNodeType;
}

const OrderPriceBreakup = ({
  title,
  info,
  priceBreakup,
  totalPrice,
  mmtServiceFee,
}: accordionProp) => {
  return (
    <OrderRightStyle>
      <OrderRightHd>
        {title && (
          <SpanTag fontWeight="bold" fontSize="md">
            <FormattedText value={title} />
          </SpanTag>
        )}
        {info && (
          <SpanTag fontWeight="bold" fontSize="md">
            <FormattedText value={info} />
          </SpanTag>
        )}
      </OrderRightHd>
      <OrderRightCntnt>
        {priceBreakup &&
          priceBreakup.map((item, index) => {
            return (
              <FlexComp justifyContent="space-between" key={index}>
                {item.title && (
                  <SpanTag fontSize="xs" color={theme.greyText7}>
                    <FormattedText value={item.title} />
                  </SpanTag>
                )}
                {item.price && (
                  <PriceText txtfontSize="xs">
                    <FormattedText value={item.price} />
                  </PriceText>
                )}
              </FlexComp>
            );
          })}
        <MmtServiceFee justifyContent="space-between">
          {mmtServiceFee?.title && (
            <SpanTag fontSize="xs" color={theme.blackText}>
              <FormattedText value={mmtServiceFee?.title} />
            </SpanTag>
          )}
          {mmtServiceFee?.price && (
            <PriceText txtfontSize="xs" txtfontWeight={"bold"}>
              <FormattedText value={mmtServiceFee?.price} />
            </PriceText>
          )}
        </MmtServiceFee>
        <FlexComp justifyContent="space-between">
          <SpanTag fontWeight="bold" fontSize="md">
            Total Price
          </SpanTag>
          {totalPrice && (
            <PriceText txtfontWeight="bold" txtfontSize="md">
              <FormattedText value={totalPrice} />
            </PriceText>
          )}
        </FlexComp>
      </OrderRightCntnt>
    </OrderRightStyle>
  );
};

export default OrderPriceBreakup;
