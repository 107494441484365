import styled from "styled-components";

export const FlightInfoWpr = styled.div`
  padding: 12px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 21px;
  position: relative;
`;
export const ButtonWpr = styled.div`
  padding: 8px 30px 24px;
  background-color: #f6f6f6;
  margin: 0 -30px -30px;
  position: fixed;
  bottom: 24px;
  left: 30px;
  width: 100%;
  z-index: 999;
`;
export const ImageWpr = styled.div`
  display: flex;
  align-items: center;
`;
export const UploadWpr = styled.div`
  background: #fff;
  border-radius: 12px;
  margin-bottom: 40px;
`;
