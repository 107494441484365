import React, { Fragment } from "react";
import {
  Modal,
  H4Tag,
  CliptextStyle,
  FlexComp,
  ImageTag,
  ParaTag,
  DeliveryPersuasion,
  SpanTag,
} from "@tm/static";
import ListingPageHeader from "../../ListingPageComponents/ListingPageHeader";

import {
  LocationFetchingStyles,
  LocationLoading,
  AbsIconContainer,
  ConditionsText,
} from "./LocationFetching.style";
import {
  LocationFetchingIcon,
  LocationMarkerWithCheck,
} from "@tm/static/icons";

import { theme } from "@tm/utility/global";

interface LocationFetchingProps {
  arrowIcon?: string;
  headingText?: string;
  userAvatarImgURI?: string;
  loadingText?: string;
  conditionsText?: {
    greenText?: string;
    normalText: string;
  };
  loadingDoneText?: string;
  fetchingIcon?: string;
  fetchedIcon?: string;
  loadingGifURI?: string;
  isFetchingDone?: boolean;
  showDeliveryIcon?: boolean;
  onModalClose?: () => void;
  setIsFetchingDone?: React.Dispatch<React.SetStateAction<boolean>>;
  bgImg?: string;
}

const LocationFetching = ({
  onModalClose = () => {},
  arrowIcon,
  userAvatarImgURI,
  fetchingIcon,
  fetchedIcon,
  loadingText,
  loadingDoneText,
  conditionsText,
  headingText,
  loadingGifURI,
  isFetchingDone = true,
  showDeliveryIcon = false,
  bgImg,
}: LocationFetchingProps) => {
  return (
    <Modal padding="0px" modalType={"fullPage"} onModalClose={onModalClose}>
      <LocationFetchingStyles bgImg={bgImg}>
        <ListingPageHeader
          arrowIcon={arrowIcon}
          userAvatarImgURI={userAvatarImgURI}
        />
        <FlexComp padding="0px 16px">
          <CliptextStyle>
            <H4Tag>{headingText}</H4Tag>
          </CliptextStyle>
        </FlexComp>
        <FlexComp flexDirection="column" flex="1" alignItems="center">
          <LocationLoading>
            {!isFetchingDone ? (
              <AbsIconContainer>
                {fetchingIcon ? (
                  <ImageTag src={fetchingIcon} />
                ) : (
                  <LocationFetchingIcon />
                )}
              </AbsIconContainer>
            ) : (
              <AbsIconContainer>
                {fetchedIcon ? (
                  <ImageTag src={fetchedIcon} />
                ) : (
                  <LocationMarkerWithCheck />
                )}
              </AbsIconContainer>
            )}
            {loadingGifURI && !isFetchingDone && (
              <ImageTag src={loadingGifURI} />
            )}
          </LocationLoading>
          {!isFetchingDone
            ? !!loadingText && (
                <ParaTag
                  fontSize="md"
                  margin="16px 0px 0px"
                  color={theme.greyText}
                >
                  {loadingText}
                </ParaTag>
              )
            : !!loadingDoneText && (
                <Fragment>
                  <DeliveryPersuasion
                    bgColor=""
                    fontSize="sm"
                    padding="0px"
                    text={loadingDoneText}
                    showIcon={showDeliveryIcon}
                    margin={"16px 0px 12px 0px"}
                  />
                  {conditionsText && (
                    <ConditionsText>
                      <ParaTag fontSize="xs" color={theme.greyText}>
                        {!!conditionsText.greenText && (
                          <SpanTag fontSize="xs" color={theme.greenText}>
                            {conditionsText.greenText}
                          </SpanTag>
                        )}{" "}
                        {!!conditionsText.normalText &&
                          conditionsText.normalText}
                      </ParaTag>
                    </ConditionsText>
                  )}
                </Fragment>
              )}
        </FlexComp>
      </LocationFetchingStyles>
    </Modal>
  );
};

export default LocationFetching;
