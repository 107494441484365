import { injectPlatform } from "./internal";
export * from "./OmnitureAnalytics/forexEvents";

export const pushEvent = (eventObj: any) => {
  const isClientSide =
    typeof navigator !== "undefined" && typeof window !== "undefined";
  if (!isClientSide) return;

  injectPlatform(eventObj);

  // @ts-ignore
  window.adobeDataLayer = window.adobeDataLayer || [];
  // @ts-ignore
  window.adobeDataLayer.push(eventObj);
};
