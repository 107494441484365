import styled from "styled-components";

import { RadioButtonStyle } from "../RadioButton/RadioButton.style";
import { deviceType } from "libs/ui-widgets/src/lib/static/utils";
import {
  LandingButtonWhiteStyle,
  LandingButtonStyle,
} from "../LandingButton/LandingButton.style";
import { DobPickerStyle } from "../DatePickerComp/DatePickerComp.style";
export interface AddTravellerStyleProps {
  selected?: boolean;
}
export interface AddButtonStyleProps {
  isDisabled?: boolean;
}

export interface ToastOfferWrapProps {
  pictureHeight?: string;
  picturePadding?: string;
  pictureWidth?: string;
  bottom?: string;
  position?: string;
  width?: string;
}

export interface AddOnPlanWrapProps {
  margin?: string;
  isSelected?: boolean;
}

export const AddTravellerDetailStyle = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  @media ${deviceType.mobile} {
    border-radius: 16px 16px 0 0;
  }
`;

export const AddTravellerHd = styled.div`
  padding: 30px;
  @media ${deviceType.mobile} {
    padding: 24px 16px;
  }
`;

export const AddTravellerComp = styled.div`
  padding: 30px;
  @media ${deviceType.mobile} {
    padding: 24px 16px;
  }
`;

export const StickyBtnWrap = styled.div`
  ${LandingButtonStyle} {
    width: 100%;
  }
  @media ${deviceType.mobile} {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background: #fff;
    padding: 8px 16px;
    left: 0;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.2);
  }
`;

export const TravellerDetailsStyle = styled.div`
  padding: 16px 30px;
  border-top: 1px solid #e8e8e8;
  ${LandingButtonWhiteStyle} {
    span {
      font-size: 12px;
      line-height: 12px;
    }
  }
  @media ${deviceType.mobile} {
    padding: 16px;
  }
  ${DobPickerStyle} {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const TravellerAddShimmer = styled.div`
  padding: 24px 30px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
`;

export const GenderTypeItem = styled.div<AddTravellerStyleProps>`
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  padding: 10px 12px;
  cursor: pointer;
  ${(p) =>
    p.selected &&
    `
    background: #EAF5FF;
    border: 1px solid #008CFF;
    border-radius: 6px;
`}
`;

export const SelectInsurereItem = styled.div<AddTravellerStyleProps>`
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  padding: 10px 12px;
  margin-bottom: 8px;
  cursor: pointer;
  ${(p) =>
    p.selected &&
    `
    background: #EAF5FF;
    border: 1px solid #008CFF;
    border-radius: 6px;
`}
  ${RadioButtonStyle} {
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      top: 31%;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      top: 31%;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      top: 19px;
    }
  }
`;

export const TravellerDescpWrap = styled.div``;

export const TravellerDescpStyle = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
`;

export const TravellerIndexWrap = styled.div`
  background: #f6f6f6;
  border-radius: 18px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TravellerDescpError = styled.div`
  background: #fcdadb;
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
  margin-top: 8px;
`;

export const ConfirmTnc = styled.div`
  background: #f6f6f6;
  border-radius: 6px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  margin-bottom: 8px;
`;

export const PrimaryContactNote = styled.div`
  background: rgba(255, 237, 209, 1);
  border-radius: 8px;
  padding: 12px 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ArrowButtonWrap = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
`;

export const ToastOfferWrap = styled.div<ToastOfferWrapProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 6px 16px 6px 16px;
  ${(props) => props.width && `width: ${props.width};`}
  bottom: ${(props) => (props.bottom ? `${props.bottom}` : "30px")};
  background: #b8f5e0;
  position: ${(props) => (props.position ? `${props.position};` : `sticky`)};
  picture {
    ${(props) => props.pictureHeight && `height: ${props.pictureHeight};`}
    ${(props) => props.pictureWidth && `width: ${props.pictureWidth};`}
        ${(props) =>
      props.picturePadding && `padding: ${props.picturePadding};`}
  }
`;

export const AddButtonWrap = styled.div<AddButtonStyleProps>`
  display: flex;
  ${(props) => (props.isDisabled ? `color: #0000004D;` : `color: #008cff;`)}
  align-item: baseline;
  margin: 14px 0px 14px 33px;
  cursor: pointer;
  align-items: center;
`;

export const AddOnPlanWrap = styled.div<AddOnPlanWrapProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  border: ${(props) =>
    props.isSelected ? `1px solid #008CFF` : `1px solid #e2e2e2`};
  background: ${(props) =>
    props.isSelected
      ? `linear-gradient(0deg, #EAF5FF, #EAF5FF), linear-gradient(0deg, #008CFF, #008CFF)`
      : `linear-gradient(0deg, #f6f6f6, #f6f6f6),linear-gradient(0deg, #e2e2e2, #e2e2e2)`};
  ${(props) => props.margin && `margin: ${props.margin};`}
  position: relative;
  a {
    font-size: 12px;
    font-weight: 700;
  }
  button {
    span {
      font-size: 12px;
    }
  }
  picture {
    display: flex;
    align-items: center;
  }
  img {
    border-radius: 4px;
  }
  ${LandingButtonWhiteStyle} {
    position: static;
    width: 75px;
    height: 32px;
  }
`;

export const TagWrap = styled.div`
  display: flex;
  background: linear-gradient(88.44deg, #12714f 0.02%, #1ca099 100.01%);
  padding: 2px 8px 2px 8px;
  border-radius: 16px;
  height: 20px;
  color: #ffffff;
  align-items: center;
  font-size: 12px;
  position: absolute;
  font-weight: 700;
  top: -10px;
  transform: translateX(-50%);
  margin: 0px 0px 0px 10px;
  z-index: 1;
  @media ${deviceType.desktop} {
    left: 11%;
  }
  @media ${deviceType.mobile} {
    left: 16%;
  }
`;

export const VendorIconWrap = styled.div`
  @media ${deviceType.mobile} {
    picutre {
      align-items: flex-start;
    }
  }
`;

export const WebToastWrap = styled.div`
  div {
    height: 40px;
  }
  @media ${deviceType.mobile} {
    position: fixed;
    bottom: 108px;
    width: 100%;
    & > {
      width: 100%;
    }
    div {
    }
  }
  @media ${deviceType.desktop} {
    & > div {
      margin-left: 30px;
    }
    div {
      width: 502px;
    }
    position: sticky;
    bottom: 120px;
  }
`;
