import { useDevice } from "../../hooks/useDevice";
import {
  GreyBackgroundStyled,
  LicenseFooterWrap,
} from "../../static/CommonStyle/CommonStyles.style";
import SpanTag from "../../static/components/ui-atoms/TextStyles/SpanTag";

const BMFLicenseFooter = () => {
  const device = useDevice();
  return (
    <GreyBackgroundStyled>
      <LicenseFooterWrap>
        <SpanTag color="#757575">
          Book My Forex Pvt. Ltd., a MakeMyTrip group company, is a currency
          exchange service provider authorised by the Reserve Bank of India
          (RBI).
        </SpanTag>
        <SpanTag margin={device === "lg" ? "0 0 0 5px" : ""} color="#757575">
          License number : NDL-FFMC-0093-2023
        </SpanTag>
      </LicenseFooterWrap>
    </GreyBackgroundStyled>
  );
};

export default BMFLicenseFooter;
