import { theme } from "@tm/utility/global";
import styled from "styled-components";

interface TMInputWrapProps {
  margin?: string;
  inputWidth?: string | number;
  flex?: number | string;
  disableState?: boolean;
  placeholder?: string;
  clickable?: boolean;
}

interface TMInputStyleProps {
  isActive?: boolean;
  errorState?: string;
  disableState?: boolean;
  verifiedTag?: string;
  clickable?: boolean;
}

export const TMInputWrap = styled.div<TMInputWrapProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${(props: any) => props.margin};
  width: ${(props: any) => props.inputWidth};
  flex: ${(p: any) => (p.flex ? p.flex : "")};
  flex-shrink: 0;

  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.greyText};
    display: block;
    transition: all 0.3s ease;
    display: inline-block;
    position: absolute;
    left: 10px;
    top: 18px;
    text-transform: uppercase;
    ${(props: any) =>
      props.placeholder &&
      `
    color: ${theme.blueText};
    background: ${theme.blueLight};
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    top: 5px;
    `}
    ${(props: any) =>
      props.disableState &&
      `
        pointer-events: none;
        color: ${theme.lightBlack};
    `}
  }
  ${(props: any) =>
    props.clickable &&
    `
        cursor: pointer;
        background:${theme.whiteText};
        color: ${theme.greyText}
    `}

  label span {
    font-weight: 400;
    text-transform: lowercase;
  }
`;

export const TMInputStyle = styled.input<TMInputStyleProps>`
  background: ${theme.gray97};
  border: 1px solid ${theme.lightGrey};
  border-radius: 4px;
  padding: 26px 10px 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: block;
  width: 100%;
  ${(props: any) =>
    props.verifiedTag &&
    `
        padding-right: 80px;
    `}

  &:focus + label {
    color: ${theme.blueText};
    background: ${theme.blueLight};
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    top: 5px;
    ${(props: any) =>
      props.errorState &&
      `
        color: ${theme.redText2};
    `}
  }
  &:valid + label {
    color: ${theme.greyText6};
    background: ${theme.smokeWhite};
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    top: 5px;
    ${(props: any) =>
      props.isActive &&
      `
            color: ${theme.blueText};
            background: ${theme.blueLight};
        `}
    ${(props: any) =>
      props.placeholder &&
      `
            color: ${theme.blueText};
            background: ${theme.blueLight};
        `}
        ${(props: any) =>
      props.errorState &&
      `
             color: ${theme.redText2};
     `}
         ${(props: any) =>
      props.clickable &&
      `
        background: ${theme.whiteText};
    `}
  }

  ${(props: any) =>
    props.disableState &&
    `
        pointer-events: none;
        color:  ${theme.lightBlack};
        border: 1px solid ${theme.lightSilver};
        background: ${theme.whiteText};
    `}

  ${(props: any) =>
    props.clickable &&
    `
        pointer-events: none;
        background: ${theme.whiteText}  !important;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    `}

    ${(props: any) =>
    props.errorState &&
    `
      border: 1px solid ${theme.redText2} !important;
      background: ${theme.snowWhite}  !important;
    `}

  &:focus {
    outline: none;
    border: 1px solid ${theme.blueText};
    background: ${theme.whiteText};
  }
  &:valid {
    outline: none;
    border: 1px solid ${theme.gainsBoro};
    background: ${theme.smokeWhite};
    ${(props: any) =>
      props.isActive &&
      `
            border: 1px solid ${theme.blueText};
            background: ${theme.blueLight};
        `}
  }
`;

export const VerifiedContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;

export const InputWpr = styled.div`
  position: relative;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;
