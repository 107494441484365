import { useEffect, useState } from "react";

export const useScroll = (timeout = 8000) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const timeId = setTimeout(() => {
      setScroll(true);
    }, timeout);

    return () => {
      clearTimeout(timeId);
    };
  }, [timeout]);

  return {
    scroll,
  };
};
