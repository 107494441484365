import { CARD_TYPE, HOST, REDIRECTION_FLOW, VERSIONS } from "../../constants";
import { landingCardShowcaseDataProps } from "../../static/mockData";
import { ListingReducerType } from "../../store/forexListingReducer";
import { ForexProducts, ProductData, ProductType } from "../../types";
import { getCardValue, getHostName } from "../../utils";
import useFetchCardCtaSignalState from "../useFetchCardCtaSignalState";
import { useGlobalConfig } from "../useGlobalConfig";
import { useGlobalSelector } from "../useGlobalSelector";
import useOpenLoginForm from "../useOpenLoginForm";
import useRedirectionUrls from "../useRedirectionUrls";

const useRedirectForexProductLinksV2 = () => {
  const { products, version, feature_flag } = useGlobalSelector(
    (state: ListingReducerType) => state || {}
  );

  const { isLoggedInUser } = useGlobalConfig();

  const { fetchCardCtaSignalState } = useFetchCardCtaSignalState();

  const { productTilesRedirections } = useRedirectionUrls();
  const { openLoginFormForNonLoggedInUser } = useOpenLoginForm();

  async function redirectForexProductLinksV2(
    item: landingCardShowcaseDataProps,
    reqId: string
  ) {
    if (!item?.version) return;

    item.feature_flag = feature_flag || {};

    if (item?.version === VERSIONS.V1 || item?.id === CARD_TYPE.INR_CARD) {
      if (isLoggedInUser) {
        productTilesRedirections(item);
        return;
      } else {
        await openLoginFormForNonLoggedInUser();
        return;
      }
    }
    const currencyCardData = getCardValue(
      products,
      CARD_TYPE.CURRENCY as ProductType
    );
    // Only for multicurrency card for v2.
    if (!isLoggedInUser) {
      if (item?.id === CARD_TYPE.MULTI_CURRENCY_CARD) {
        productTilesRedirections(item);
        return;
      } else if (item?.id === CARD_TYPE.CURRENCY) {
        productTilesRedirections(item);
      }
    }

    const ctaObj = getCtaObjectForProduct({
      products: products || [],
      productType: item.id as ProductType,
    });

    // if (
    //     item?.id === CARD_TYPE.MULTI_CURRENCY_CARD &&
    //     getHostName() === HOST.MAKEMYTRIP &&
    //     !ctaObj
    // ) {
    //     redirectInterceptHref(
    //         `${getHostName() === HOST.MAKEMYTRIP ? "https://" + HOST.TRIPMONEY_MAKEMYTRIP : ""}/partners/mmt/forex/bmf/onboarding/landing?source=listing`
    //     );
    //     return;
    // }

    // cta object didn't come from product listing api then call fetch signal state api.
    if (!ctaObj && getHostName() !== HOST.MAKEMYTRIP) {
      try {
        const { success, data } =
          (await fetchCardCtaSignalState(item.id as ProductType, reqId)) || {};
        if (!success) {
          return;
        }
        item.feature_flag = data?.feature_flag || {};
        item.cta = data?.cta || {};
        item.cta_redirect = REDIRECTION_FLOW.NEW;
        item.version = REDIRECTION_FLOW.NEW;
        productTilesRedirections(item);
      } catch {
        console.error("Fetch card cta api failed");
      }
    } else {
      productTilesRedirections(item);
    }
  }
  return {
    redirectForexProductLinksV2,
  };
};

export default useRedirectForexProductLinksV2;

function getCtaObjectForProduct({
  products,
  productType,
}: {
  products: ForexProducts;
  productType: ProductType;
}) {
  for (const product of products) {
    for (const [key, value] of Object.entries<ProductData>(product)) {
      if (key === productType) {
        return value?.cta;
      }
    }
  }
  return null;
}
