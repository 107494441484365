import { ForexOfferingCard } from "@tm/static";

import {
  LandingOfferingContainerStyle,
  ForexOfferingWpr,
} from "./LandingOfferingContainer.style";

import { LandingFaqTitle } from "../LandingFaq/LandingFaq.style";
import { landingForexOfferingCardDataProps } from "@tm/static/data";

interface LandingOfferingContainerProps {
  heading?: string;
  data: landingForexOfferingCardDataProps[];
}

const LandingOfferingContainer = ({
  heading,
  data,
}: LandingOfferingContainerProps) => {
  return (
    <LandingOfferingContainerStyle>
      {heading && (
        <LandingFaqTitle marginLeft="16px">{heading}</LandingFaqTitle>
      )}
      <ForexOfferingWpr margin="24px 0px 0px 0px">
        {data.map((item, idx) => (
          <ForexOfferingCard
            key={item.id}
            data={item}
            margin={idx + 1 < data.length ? "0px 18px 0px 0px" : "0px"}
          />
        ))}
      </ForexOfferingWpr>
    </LandingOfferingContainerStyle>
  );
};

export default LandingOfferingContainer;
