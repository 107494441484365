import { useState, useEffect } from "react";

// export const useBreakpoint = () => {
//     const [windowSize, setWindowSize] = useState<number>(1200);

//     function handleResize() {
//         console.log('IN THE FUNCTION: ', window.innerWidth);
//         // Set window width/height to state
//         setWindowSize(window.innerWidth);
//     }

//     useEffect(() => {
//         window.addEventListener('resize', handleResize);
//         handleResize();
//         return () => window.removeEventListener('resize', handleResize);
//     }, []); // Empty array ensures that effect is only run on mount
//     return windowSize;
// };

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState("lg");

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setBreakpoint("sm");
      } else {
        setBreakpoint("lg");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return breakpoint;
};
