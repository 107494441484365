import { OMNITURE_MAP, PAGE_AND_CATEGORY_MAP } from "../constants";
import {
  trackEventMweb,
  getUserCityAndLocation,
  getLogInStatus,
  getCommonOmniData,
} from "../common";
import { getDeviceTypeForOmniture } from "../common";
import type { TrackPageLoadType, TrackPageLoadErrorType } from "../type";

const location: string = getUserCityAndLocation();
const platform: string = getDeviceTypeForOmniture() || "";
const loginStatus: string = getLogInStatus();

// function for page load events
export function trackPageLoad(deps: TrackPageLoadType) {
  try {
    const { pageName: page, partner, tmUserId, upcomingTrip } = deps;
    const { pageName, lob, siteSection } = PAGE_AND_CATEGORY_MAP[page];
    const commonOmniData = getCommonOmniData({
      pageName,
      lob,
      siteSection,
      partner,
      location,
      platform,
      loginStatus,
      tmUserId,
      upcomingTrip,
    });
    const omniData = {
      ...commonOmniData,
    };
    trackEventMweb({
      ...omniData,
      pageName,
    });
  } catch (e) {
    console.error(e);
  }
}

// function for page load error events
export function trackPageError(deps: TrackPageLoadErrorType) {
  try {
    const { pageName: page, errText, partner } = deps;
    const { pageName, lob, siteSection } = PAGE_AND_CATEGORY_MAP[page];
    const commonOmniData = getCommonOmniData({
      pageName,
      lob,
      siteSection,
      partner,
      location,
      platform,
      loginStatus,
    });
    const omniData = {
      ...commonOmniData,
      ...(errText && { [OMNITURE_MAP["errorCode"]]: errText }),
    };
    trackEventMweb({
      ...omniData,
      pageName,
    });
  } catch (e) {
    console.error(e);
  }
}
