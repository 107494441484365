import styled from "styled-components";
import { H2TagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "@tm/static/utils";
import { PARTNER } from "@tm/insurance/constants";

export interface WhyBuyTravInsStyleProps {
  pageTheme?: string;
}

export const WhyBuyTravInsStyle = styled.section<WhyBuyTravInsStyleProps>`
  background: #fff;
  width: 100%;
  z-index: 1;
  ${(p) =>
    p.pageTheme === PARTNER.GI &&
    `
    background: #EFF3F8;
    padding-bottom:80px;
    `}
  ${H2TagStyle} {
    width: 510px;
    @media ${deviceType.mobile} {
      width: 100%;
    }
  }
  @media ${deviceType.mobile} {
    padding: 0 16px;
    ${(p) =>
      p.pageTheme === PARTNER.GI &&
      `
    background: #fff;
    padding-top:16px;
    `}
  }
`;

export const WhyFeatListWrap = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  flex-shrink: 0;
  @media ${deviceType.mobile} {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
  img {
    margin-top: 4px;
  }
`;

export const WhyBuyItem = styled.li`
  display: flex;
  gap: 8px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 300px;
  flex-shrink: 0;
  font-size: 18px;
  line-height: 25px;
  svg {
    flex-shrink: 0;
  }
  @media ${deviceType.mobile} {
    width: 100%;
    box-shadow: none;
  }
`;

export const ShieldWrap = styled.div`
  position: absolute;
  right: -60px;
  z-index: -1;
`;
