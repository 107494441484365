import React, { useEffect, useState } from "react";
import { LandingHeaderMob, LandingHeaderMobBlack } from "./LandingHeader.style";
import { SpanTag, AnchorTag, ImageComponent, FlexComp } from "@tm/static";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import { theme } from "libs/ui-widgets/src/lib/static/css/Global";
import { usePartnerContext } from "@tm/static/partnerContext";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { PARTNER } from "@tm/insurance/constants";
import { LandingHeaderBackButton } from "./LandingHeaderBackButton";
interface LandingHeaderProps {
  handleBackBtn?: () => void;
  headerTitle?: string | TextNodeType;
  children?: React.ReactNode;
  goToMyOrders?: () => void;
  myOrders?: boolean;
  displayWhiteHeader?: boolean;
  partnerType?: string;
  insuranceHeader?: boolean;
}

const LandingHeaderMobile = ({
  handleBackBtn,
  headerTitle,
  children,
  myOrders,
  goToMyOrders,
  displayWhiteHeader,
  partnerType,
  insuranceHeader,
}: LandingHeaderProps) => {
  const [scrollPos, setScrollPos] = useState(0);
  const device = useBreakpointV2();

  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const blackHeaderStyle = {
    display: scrollPos > 40 ? "none" : "block",
  };

  const whiteHeaderStyle = {
    display: scrollPos > 40 ? "block" : "none",
  };

  return (
    <React.Fragment>
      <LandingHeaderMobBlack
        displayWhiteHeader={displayWhiteHeader}
        id="blackHeader"
        style={blackHeaderStyle}
        insuranceHeader={insuranceHeader}
      >
        <FlexComp
          gap="12px"
          alignItems="center"
          justifyContent={displayWhiteHeader ? "flex-start" : "space-between"}
        >
          <LandingHeaderBackButton handleBackBtn={handleBackBtn} />
          {headerTitle && displayWhiteHeader && (
            <SpanTag fontSize="md" fontWeight="bold">
              <FormattedText value={headerTitle} />
            </SpanTag>
          )}
          {device == "sm" && myOrders && (
            <ImageComponent
              width="75px"
              height="24px"
              src={
                partnerType === PARTNER.MMT
                  ? "https://tripmoneycmsimgak.mmtcdn.com/img/Group_46_699159e12c.svg"
                  : "https://tripmoneycmsimgak.mmtcdn.com/img/gi_logo_512_6736e70735.png"
              }
            />
          )}
          {myOrders && (
            <AnchorTag
              color={theme.blueText}
              fontWeight="bold"
              handleClick={goToMyOrders}
            >
              My Orders
            </AnchorTag>
          )}
        </FlexComp>
        {children}
      </LandingHeaderMobBlack>
      <LandingHeaderMob id="whiteHeader" style={whiteHeaderStyle}>
        <FlexComp gap="12px" alignItems="center">
          <LandingHeaderBackButton
            handleBackBtn={handleBackBtn}
            displayWhiteHeader={true}
          />
          {headerTitle && (
            <SpanTag fontSize="md" fontWeight="bold">
              <FormattedText value={headerTitle} />
            </SpanTag>
          )}
          {myOrders && (
            <AnchorTag
              margin="0 0 0 auto"
              fontWeight="bold"
              handleClick={goToMyOrders}
            >
              My Orders
            </AnchorTag>
          )}
        </FlexComp>
        {children}
      </LandingHeaderMob>
    </React.Fragment>
  );
};

export default LandingHeaderMobile;
