import {
  DeviceContextV2,
  DeviceContextV2Type,
} from "../Contexts/DeviceContext";

export function withDeviceProvider(
  comp: JSX.Element,
  device: DeviceContextV2Type
) {
  return (
    <DeviceContextV2.Provider value={device}>{comp}</DeviceContextV2.Provider>
  );
}
