import { SpanTagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "libs/ui-widgets/src/lib/static/utils";
import styled from "styled-components";
import { LandingButtonTertiaryStyle } from "../LandingButton/LandingButton.style";

export const ToastStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background: #4a4a4a;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  width: calc(100% - 32px);
  position: fixed;
  bottom: 30px;
  left: 50%;
  right: 16px;
  gap: 14px;
  z-index: 1;
  max-width: 500px;
  transform: translateX(-50%);
  transition: opacity 0.3s ease;
  flex-direction: row;
`;

export const CloseIconWrap = styled.div`
  width: 28px;
  height: 28px;
  cursor: pointer;
`;

export const ToastIcon = styled.div`
  display: flex;
  flex-shrink: 0;
`;
