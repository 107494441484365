export const theme = {
  blackText: "#000",
  greyText: "#4a4a4a",
  whiteText: "#fff",
  lightGreyText: "#9b9b9b",
  darkText: "#002137",
  greenText: "#2B825E",
  greenText2: "#38A97A",
  greenText3: "#137351",
  greyText2: "#cbcbcb",
  greyText3: "#6b6b6b",
  greyText4: "#9b9b9b",
  greyText5: "#AFAFAF",
  greyText6: "#757575",
  greyText7: "#969696",
  greyText8: "rgba(255, 255, 255, 0.6)",
  greyText9: "rgba(74, 74, 74, 0.6)",
  redText: "#960D11",
  redText2: "#EB2026",
  redText3: "#C51217",
  greenNeonText: "#8BEECD",
  blueNeonText: "rgba(0, 162, 255, 1)",
  blueText: "#008CFF",
  brownText: "#6B4300",
  yellowText: "#CF8100",
  yellowLight: "#FFEDD1",
  darkGreen: "#1E5C43",
};

export const fontWeight = {
  regular: "400",
  medium: "500",
  semiBold: "600",
  bold: "700",
  black: "900",
};

export const fontSize = {
  sm: "10px",
  font12: "12px",
  font14: "14px",
  font16: "16px",
  font18: "18px",
  font20: "20px",
  font22: "22px",
  font24: "24px",
  font26: "26px",
  font28: "28px",
};

export const lineHeight = {
  lineHeight16: "16px",
  lineHeight20: "20px",
  lineHeight22: "22px",
  lineHeight24: "24px",
  lineHeight26: "24px",
  lineHeight32: "32px",
};
