import styled, { keyframes } from "styled-components";
import { deviceType } from "@tm/static/utils";

import { theme } from "@tm/utility/global";

interface MarqueeContainerProps {
  isPaused: boolean;
  isOnBottom: boolean;
}

const marqueeScroll = keyframes`
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 20px));
    }
`;

export const MarqueeBarStyle = styled.div<any>`
  display: flex;
  align-items: center;
  border-radius: 4px 0px 0px 4px;

  ${(props: any) =>
    props.marqueeBarBgColor &&
    `
        background-color: ${props.marqueeBarBgColor};
    `}
  ${(props) => props.margin && `margin: ${props.margin};`}
    ${(props) => props.padding && `padding: ${props.padding};`}
`;

export const Tag = styled.div`
  padding: 12px 15px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  z-index: 2;
  background-color: ${theme.redText3};

  &:after {
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    top: -61px;
    z-index: -1;
    left: -60px;
    background-color: ${theme.redText4};
    transform: rotate(53deg);
  }
`;

export const MarqueeContainer: any = styled.div`
  gap: 20px;
  position: relative;
  display: flex;
  user-select: none;
  overflow: hidden;
  width: 100%;

  ${(props: any) =>
    props.marqueeCtrBgColor &&
    `
        background-color: ${props.marqueeCtrBgColor};
    `}

  @media screen and ${deviceType.mobile} {
    ${(props: any) => props.isOnBottom && `padding: 8px;`}
  }
`;

export const MarqueeRibbon = styled.ul<MarqueeContainerProps>`
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  min-width: 100%;
  animation: ${marqueeScroll} 20s linear infinite;

  & > li {
    /* display: flex; */
    flex: 0 0 auto;
    margin: 0px 10px;
    text-align: center;
  }

  ${(props) => props.isPaused && `animation-play-state: paused !important;`}
`;
