import React from "react";

import { ProcessStepsStyle, ProcessStepsInfo } from "./ProcessSteps.style";

interface processStepType {
  activeIndex?: any;
  data?: any;
  position?: boolean;
  forMweb?: boolean;
  margin?: string;
  forHeader?: boolean;
}

const ProcessSteps = ({
  data,
  activeIndex,
  position,
  forMweb,
  margin,
  forHeader,
}: processStepType) => {
  return (
    <React.Fragment>
      {!forHeader && (
        <ProcessStepsInfo>{data[activeIndex - 1]}</ProcessStepsInfo>
      )}
      <ProcessStepsStyle
        position={position}
        forMweb={forMweb}
        margin={margin}
        forHeader={forHeader}
      >
        {data &&
          data.map((item: any, index: any) => {
            return (
              <li
                className={`${activeIndex >= index + 1 ? "active" : ""}`}
                key={index}
              ></li>
            );
          })}
      </ProcessStepsStyle>
    </React.Fragment>
  );
};

export default ProcessSteps;
