import React from "react";
import { ClaimStructureStyle } from "./ClaimYourInsurance.style";
import { H2Tag, H3Tag, ImageComponent, SpanTag } from "@tm/static";
import { theme } from "@tm/utility/global";
import { TextNodeType } from "../FormattedText/FormattedText";
import RichText from "../RichText/RichText";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { usePartnerContext } from "@tm/static/partnerContext";
import { PARTNER } from "../../constants";

interface ClaimStructureProps {
  claimIndex?: string;
  claimInfo: TextNodeType | string;
  showSpiral?: boolean;
}

const ClaimStructure = ({
  claimIndex,
  claimInfo,
  showSpiral,
}: ClaimStructureProps) => {
  const device = useBreakpointV2();
  const context = usePartnerContext();
  return (
    <ClaimStructureStyle>
      {context === PARTNER.MMT ? (
        <H2Tag color={theme.prussianBlue}>{claimIndex}</H2Tag>
      ) : (
        <H3Tag color={theme.prussianBlue}>{claimIndex}</H3Tag>
      )}
      <SpanTag>
        {" "}
        <RichText value={claimInfo} />
      </SpanTag>
      {!showSpiral && (
        <ImageComponent
          width={device == "lg" ? "57px" : "24px"}
          height={device == "lg" ? "57px" : "24px"}
          src="https://tripmoneycmsimgak.mmtcdn.com/img/grey_Spiral_de40650b4c.svg"
        />
      )}
    </ClaimStructureStyle>
  );
};

export default ClaimStructure;
