import * as React from "react";
interface ConfettiType {}
const Confetti = (props: ConfettiType) => (
  <svg
    width={279}
    height={186}
    viewBox="0 0 279 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse
      cx={167.047}
      cy={57.6006}
      rx={4.08312}
      ry={4.14746}
      fill="#052F11"
    />
    <ellipse
      cx={96.0929}
      cy={58.9172}
      rx={2.05284}
      ry={2.08519}
      fill="#052F11"
    />
    <ellipse
      cx={184.522}
      cy={91.1717}
      rx={2.47839}
      ry={2.51745}
      fill="#052F11"
    />
    <ellipse
      cx={86.4608}
      cy={118.832}
      rx={2.47839}
      ry={2.51745}
      fill="#052F11"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.11 65.0256C145.431 60.2392 153.37 63.527 153.041 69.9673C152.834 74.0228 156.179 77.368 160.234 77.1611C166.674 76.8318 169.963 84.7706 165.176 89.0914C162.162 91.8129 162.162 96.5429 165.176 99.2645C169.963 103.586 166.674 111.524 160.234 111.196C156.179 110.988 152.834 114.333 153.041 118.389C153.37 124.829 145.431 128.117 141.11 123.33C138.389 120.316 133.659 120.316 130.937 123.33C126.615 128.117 118.678 124.829 119.006 118.389C119.214 114.333 115.869 110.988 111.813 111.196C105.373 111.524 102.085 103.586 106.871 99.2645C109.885 96.5429 109.885 91.8129 106.871 89.0914C102.085 84.7706 105.373 76.8318 111.813 77.1611C115.869 77.368 119.214 74.0228 119.006 69.9673C118.678 63.527 126.615 60.2392 130.937 65.0256C133.659 68.0397 138.389 68.0397 141.11 65.0256Z"
      fill="url(#paint0_linear_1129_15509)"
    />
    <path
      d="M141.11 65.0256V65.0256C145.431 60.2392 153.37 63.527 153.041 69.9673C152.834 74.0228 156.179 77.368 160.234 77.1611C166.674 76.8318 169.963 84.7706 165.176 89.0914C162.162 91.8129 162.162 96.5429 165.176 99.2645C169.963 103.586 166.674 111.524 160.234 111.196C156.179 110.988 152.834 114.333 153.041 118.389C153.37 124.829 145.431 128.117 141.11 123.33C138.389 120.316 133.659 120.316 130.937 123.33C126.615 128.117 118.678 124.829 119.006 118.389C119.214 114.333 115.869 110.988 111.813 111.196C105.373 111.524 102.085 103.586 106.871 99.2645C109.885 96.5429 109.885 91.8129 106.871 89.0914C102.085 84.7706 105.373 76.8318 111.813 77.1611C115.869 77.368 119.214 74.0228 119.006 69.9673C118.678 63.527 126.615 60.2392 130.937 65.0256C133.659 68.0397 138.389 68.0397 141.11 65.0256"
      stroke="#052F11"
      strokeWidth={3.84498}
    />
    <path
      d="M121.699 95.1193L130.32 103.74L150.435 83.625"
      stroke="#052F11"
      strokeWidth={3.84498}
      strokeLinecap="round"
    />
    <path
      d="M219.806 11.7625C218.876 11.3514 217.919 11.0267 216.958 10.732C216.001 10.4355 215.032 10.1728 214.055 9.99866C214.179 10.0222 212.729 9.78728 212.793 9.99213C213.88 13.3471 212.352 17.234 210.728 19.8914C209.808 21.3937 208.602 22.5861 207.244 23.4084C206.661 23.7611 206.025 23.9713 205.392 24.1349C205.097 24.212 204.793 24.2482 204.489 24.2843C204.381 24.2979 204.272 24.295 204.161 24.2989C204.015 24.2635 203.873 24.2262 203.727 24.1909C203.129 24.0503 202.418 23.8357 201.797 23.9205C201.257 23.9935 202.173 24.4619 202.13 24.4381C202.896 24.8565 203.711 25.1323 204.516 25.4188C208.78 26.9319 213.235 28.1374 217.038 24.633C219.699 22.1824 222.277 16.6621 220.922 12.4817C220.801 12.0964 220.024 11.8579 219.804 11.7577L219.806 11.7625Z"
      fill="url(#paint1_linear_1129_15509)"
    />
    <path
      d="M250.565 59.0618C250.927 59.222 251.3 59.3486 251.675 59.4635C252.048 59.579 252.425 59.6814 252.806 59.7493C252.758 59.7401 253.323 59.8317 253.298 59.7518C252.874 58.4442 253.47 56.9293 254.103 55.8935C254.462 55.308 254.932 54.8432 255.461 54.5227C255.688 54.3852 255.936 54.3033 256.183 54.2396C256.298 54.2095 256.416 54.1954 256.534 54.1813C256.577 54.176 256.619 54.1772 256.662 54.1757C256.719 54.1894 256.775 54.204 256.832 54.2178C257.065 54.2726 257.342 54.3562 257.584 54.3231C257.794 54.2947 257.437 54.1121 257.454 54.1214C257.156 53.9583 256.838 53.8508 256.524 53.7392C254.862 53.1494 253.126 52.6796 251.644 54.0455C250.606 55.0006 249.602 57.1522 250.13 58.7815C250.177 58.9317 250.48 59.0246 250.566 59.0637L250.565 59.0618Z"
      fill="url(#paint2_linear_1129_15509)"
    />
    <path
      d="M214.489 103.654C215.178 106.788 215.864 109.923 216.553 113.057C216.475 112.707 220.813 110.797 220.718 110.365C220.029 107.231 219.344 104.096 218.655 100.962C218.733 101.312 214.394 103.222 214.489 103.654Z"
      fill="url(#paint3_linear_1129_15509)"
    />
    <path
      d="M173.185 65.741C175.847 66.2212 178.509 66.7015 181.171 67.1817C180.875 67.1294 181.221 61.194 180.855 61.1314C178.193 60.6512 175.531 60.171 172.869 59.6907C173.165 59.743 172.819 65.6784 173.185 65.741Z"
      fill="url(#paint4_linear_1129_15509)"
    />
    <path
      d="M59.1576 118.613C59.383 118.672 60.8262 115.349 60.9577 115.094C61.0742 114.862 62.5512 111.49 62.6988 111.53C59.7428 110.8 57.1597 109.105 54.9999 106.478C55.3043 106.847 51.087 113.108 51.4602 113.566C53.6201 116.194 56.2032 117.888 59.1592 118.618L59.1576 118.613Z"
      fill="url(#paint5_linear_1129_15509)"
    />
    <path
      d="M250.592 107.131C253.176 110.107 256.208 112.201 259.598 113.354C259.8 113.424 260.835 112.793 260.933 112.745C262.003 112.238 263.068 111.717 264.133 111.195C265.199 110.674 265.03 111.617 266.091 111.081C266.122 111.066 267.266 110.348 267.402 110.394C264.012 109.241 260.979 107.148 258.395 104.171C258.093 103.824 255.47 104.675 255.092 104.86C254.756 105.024 250.809 107.379 250.592 107.131Z"
      fill="url(#paint6_linear_1129_15509)"
    />
    <path
      d="M131.166 48.6691C130.889 48.546 129.33 48.1671 129.19 47.8361C130.364 50.6199 129.065 53.399 127.766 55.7004C126.695 57.5972 125.176 59.1399 123.352 59.352L126.264 61.0485C128.603 61.4168 130.365 59.6147 131.699 57.4089C133.053 55.182 134.273 52.267 133.123 49.5447C132.963 49.1619 131.467 48.7973 131.164 48.6642L131.166 48.6691Z"
      fill="url(#paint7_linear_1129_15509)"
    />
    <path
      d="M13.0545 22.8896C12.4546 22.5164 11.832 22.1966 11.2065 21.8981C10.581 21.5995 9.94703 21.3188 9.29882 21.102C9.37842 21.1304 8.41748 20.8213 8.44875 20.967C8.95765 23.3409 7.6726 25.7966 6.39893 27.4171C5.67964 28.3304 4.78692 29.0092 3.82029 29.425C3.40521 29.6027 2.9617 29.6773 2.52387 29.7235C2.31917 29.7448 2.11163 29.7377 1.90694 29.7306C1.83302 29.7271 1.7591 29.7164 1.68518 29.7093C1.59136 29.6702 1.4947 29.6311 1.40088 29.592C1.00854 29.4356 0.542281 29.2189 0.121513 29.2082C-0.245238 29.2011 0.337583 29.6098 0.311995 29.592C0.800997 29.9509 1.33264 30.2246 1.85292 30.4982C4.62203 31.9588 7.54183 33.2346 10.3365 31.273C12.2925 29.9012 14.3935 26.4505 13.7595 23.4937C13.7027 23.2236 13.1966 22.9784 13.0545 22.8896Z"
      fill="url(#paint8_linear_1129_15509)"
    />
    <path
      d="M78.8516 63.6931C80.3129 63.3377 82.2319 60.5231 82.1751 59.3681C82.0955 59.5671 79.7784 59.034 79.8665 58.8848C79.6704 59.9438 77.3732 61.6994 76 61.4044C76.0569 61.4435 77.1429 62.6696 77.285 62.7229C77.3703 62.8579 78.6895 63.7926 78.8516 63.6966V63.6931Z"
      fill="url(#paint9_linear_1129_15509)"
    />
    <path
      d="M95.4083 9.33366C95.5334 9.09911 93.6627 6.20276 93.5234 5.96111C93.3983 5.74077 91.502 2.79113 91.5845 2.63477C89.9355 5.71945 87.7265 8.01875 85 9.48291C85.381 9.27679 88.3491 16.4377 88.821 16.1818C91.5475 14.7176 93.7565 12.4183 95.4055 9.33366H95.4083Z"
      fill="url(#paint10_linear_1129_15509)"
    />
    <path
      d="M14.4083 115.584C14.5334 115.349 12.6627 112.453 12.5234 112.211C12.3983 111.991 10.502 109.041 10.5845 108.885C8.9355 111.969 6.72647 114.269 4 115.733C4.38097 115.527 7.34909 122.688 7.82103 122.432C10.5475 120.968 12.7565 118.668 14.4055 115.584H14.4083Z"
      fill="url(#paint11_linear_1129_15509)"
    />
    <path
      d="M32.333 69.7695C35.1903 68.4084 37.6751 66.2655 39.7078 63.4118C39.8301 63.2412 39.8386 61.9867 39.8471 61.873C39.9467 60.6327 40.0348 59.396 40.1258 58.1557C40.2139 56.9155 40.2992 55.6752 40.3788 54.4349C40.3788 54.3994 40.3788 53.0063 40.4584 52.8926C38.4256 55.7498 35.9408 57.8892 33.0836 59.2503C32.7481 59.4102 32.6969 64.0657 32.6656 64.5064C32.6372 64.8973 32.5747 69.6558 32.333 69.7695Z"
      fill="url(#paint12_linear_1129_15509)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1129_15509"
        x1={130.576}
        y1={35.5895}
        x2={76.2584}
        y2={95.5823}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AFFCD" />
        <stop offset={1} stopColor="#7DBFCC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1129_15509"
        x1={209.278}
        y1={30.6128}
        x2={216.802}
        y2={6.36303}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B4DF86" />
        <stop offset={1} stopColor="#84C743" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1129_15509"
        x1={254.668}
        y1={51.7148}
        x2={251.736}
        y2={61.1663}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B4DF86" />
        <stop offset={1} stopColor="#84C743" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1129_15509"
        x1={215.051}
        y1={108.262}
        x2={220.415}
        y2={106.581}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F71873" />
        <stop offset={1} stopColor="#C6211B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1129_15509"
        x1={172.315}
        y1={62.2785}
        x2={183.483}
        y2={64.0504}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F71873" />
        <stop offset={1} stopColor="#F8A629" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1129_15509"
        x1={60.9026}
        y1={115.893}
        x2={52.0739}
        y2={111.483}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BE1E68" />
        <stop offset={1} stopColor="#B5188D" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1129_15509"
        x1={260.738}
        y1={114.32}
        x2={254.675}
        y2={101.936}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B5188D" />
        <stop offset={1} stopColor="#4E69AE" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1129_15509"
        x1={126.048}
        y1={61.945}
        x2={136.85}
        y2={46.2851}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F71873" />
        <stop offset={1} stopColor="#C6211B" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1129_15509"
        x1={4.83046}
        y1={34.4623}
        x2={12.1426}
        y2={19.2222}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F71873" />
        <stop offset={1} stopColor="#C6211B" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1129_15509"
        x1={80.4465}
        y1={57.648}
        x2={76.8863}
        y2={62.6449}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6DD0E7" />
        <stop offset={1} stopColor="#01A7DB" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1129_15509"
        x1={94.0188}
        y1={6.24712}
        x2={85.4772}
        y2={11.1176}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBE422" />
        <stop offset={1} stopColor="#C8D92B" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1129_15509"
        x1={13.0188}
        y1={112.497}
        x2={4.47722}
        y2={117.368}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBE422" />
        <stop offset={1} stopColor="#C8D92B" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1129_15509"
        x1={40.2976}
        y1={61.8037}
        x2={30.9061}
        y2={61.1345}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8B54A" />
        <stop offset={1} stopColor="#F8A629" />
      </linearGradient>
    </defs>
  </svg>
);
export { Confetti };
