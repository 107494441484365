import React, { useEffect, useState } from "react";

import { OrderListingCompStyle } from "./OrderListingComp.style";
import {
  FlexComp,
  ImageComponent,
  ParaTag,
  SpanTag,
  OrderStatusComp,
  AnchorTag,
} from "@tm/static";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";

import { navigationActions } from "@tm/insurance/navigation";
import { getAnalytics } from "@tm/insurance/analytics";

interface orderListingInterface {
  data?: {
    icon?: string;
    orderDate?: string | TextNodeType;
    bookingId?: string | TextNodeType;
    amount?: string | TextNodeType;
    insurerName?: string | TextNodeType;
    cardInfo?: string | TextNodeType;
    countries?: string | TextNodeType;
    sumInsured?: string | TextNodeType;
    currencyInfo?: string | TextNodeType;
    currency?: string | TextNodeType;
    validity?: string | TextNodeType;
    status?: string;
    isPassportDetailsAvailable?: boolean;
    addPassportCta?: string;
  }[];
}

const analytics = getAnalytics();

const OrderListingComp = ({ data }: orderListingInterface) => {
  const device = useBreakpointV2();

  return (
    <React.Fragment>
      {data?.map((item, index) => {
        const {
          icon,
          orderDate,
          bookingId,
          amount,
          insurerName,
          countries,
          currency,
          validity,
          status,
          isPassportDetailsAvailable,
          sumInsured,
          addPassportCta,
        } = item;

        return (
          <OrderListingCompStyle
            onClick={() => {
              analytics?.trackOrderSummaryEvents({
                actionType: "order_clicked",
              });
              navigationActions.navigateToOrderDetails({
                bookingId: item.bookingId,
              });
            }}
            key={index}
          >
            <ImageComponent width="20px" height="20px" src={icon} />
            <FlexComp gap="12px" flexDirection="column" flex="1">
              <FlexComp>
                <FlexComp
                  flex="2"
                  gap="8px"
                  flexDirection={device == "lg" ? "row" : "column"}
                >
                  <FlexComp flex="1" flexDirection="column" gap="0px">
                    {insurerName && (
                      <ParaTag
                        fontSize={device == "lg" ? "md" : "sm"}
                        fontWeight="black"
                      >
                        <FormattedText value={insurerName} />
                      </ParaTag>
                    )}
                    {countries && (
                      <ParaTag
                        fontSize={device == "lg" ? "sm" : "xs"}
                        color={theme.greyText}
                      >
                        <FormattedText value={countries} />
                      </ParaTag>
                    )}
                  </FlexComp>
                  <FlexComp flex="1" flexDirection="column" gap="2px">
                    {sumInsured && (
                      <ParaTag fontSize={device == "lg" ? "md" : "xs"}>
                        <FormattedText value={sumInsured} />
                      </ParaTag>
                    )}
                    {validity && (
                      <ParaTag fontSize="xs" color={theme.greyText}>
                        <FormattedText value={validity} />
                        {status && (
                          <SpanTag
                            fontWeight="black"
                            color={
                              status == "EXPIRED"
                                ? theme.yellowText
                                : theme.greenText
                            }
                            fontSize="xs"
                          >
                            &nbsp; ({status})
                          </SpanTag>
                        )}
                      </ParaTag>
                    )}
                    {bookingId && (
                      <ParaTag fontSize="xs" color={theme.greyText}>
                        Order ID: &nbsp;
                        <FormattedText value={bookingId} />
                      </ParaTag>
                    )}
                  </FlexComp>
                </FlexComp>
                <FlexComp
                  alignItems="flex-end"
                  flexDirection="column"
                  gap="8px"
                  flex="1"
                >
                  {amount && (
                    <ParaTag fontSize="md" fontWeight="black">
                      <FormattedText value={amount} />
                      {currency && (
                        <SpanTag
                          fontSize="md"
                          fontWeight="black"
                          color={theme.greyText}
                        >
                          <FormattedText value={currency} />
                        </SpanTag>
                      )}
                      &nbsp;
                    </ParaTag>
                  )}
                  {orderDate && (
                    <ParaTag
                      fontSize="xs"
                      color={theme.greyText}
                      margin={device == "sm" ? "auto 0 0" : ""}
                    >
                      <FormattedText value={orderDate} />
                    </ParaTag>
                  )}
                </FlexComp>
                <AnchorTag margin="2px 4px 4px">
                  <ImageComponent
                    width="12px"
                    height="12px"
                    src="https://tripmoneycmsimgak.mmtcdn.com/img/blue_Chev_2800e4f975.svg"
                  />
                </AnchorTag>
              </FlexComp>
              {isPassportDetailsAvailable && item.bookingId && (
                <OrderStatusComp
                  ctatText={addPassportCta}
                  orderStatus={"Pending"}
                  orderPending
                  bookingId={item?.bookingId}
                />
              )}
            </FlexComp>
          </OrderListingCompStyle>
        );
      })}
    </React.Fragment>
  );
};

export default OrderListingComp;
