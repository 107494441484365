import { createContext } from "react";

export type DeviceContextV2Type = "mobile" | "desktop" | "tablet";

interface Styles {
  color?: string;
  "font-size"?: string;
  mobile?: {
    "font-size"?: string;
  };
}

interface TextWithStyles {
  icon?: string;
  value?: string;
  styles?: Styles;
}

export interface TextNodeType {
  text?: string | TextWithStyles | TextWithStyles[];
}
export interface DeviceComponentProps {
  device: DeviceContextV2Type;
  orderListingData: {
    id: number;
    icon: string;
    insurerName: TextNodeType;
    countries: TextNodeType;
    sumInsured: TextNodeType;
    validity: TextNodeType;
    status: string;
    amount: TextNodeType;
    orderDate: TextNodeType;
    bookingId: string;
    isPassportDetailsAvailable: boolean;
    addPassportCta: string;
  }[];
}
export const DeviceContextV2 = createContext<DeviceContextV2Type>("mobile");
const DeviceContext = createContext<string | null>(null);
export default DeviceContext;
