import {
  isUserLoggedin,
  logout,
  loginToPartner,
  loginIfPartnerLoggedin,
  isPartnerLoggedIn,
} from "@tm/auth";

const generateTmAuth = async () => {
  return loginIfPartnerLoggedin();
};

function tmAuthDataHasMobileError(tmAuthData: any) {
  return tmAuthData?.appSocialToken?.error?.code === "4401";
}

export const isUserLoggedInFn = async ({
  sendPdtAppSocialError,
  sendPdtLoginTokenError,
  sendPdtAppSocialErrorV2,
  sendPdtLoginTokenErrorV2,
}: {
  sendPdtAppSocialError: (error: any) => void;
  sendPdtLoginTokenError: (error: any) => void;
  sendPdtAppSocialErrorV2: (error: any) => void;
  sendPdtLoginTokenErrorV2: (error: any) => void;
}): Promise<{ loggedIn: boolean; mobileRequiredError: boolean }> => {
  return new Promise((resolve, reject) => {
    //@ts-ignore
    if (!window?.auth?.status) {
      //@ts-ignore
      window.auth = {
        status: true,
      };
      isPartnerLoggedIn().then(async (isPartnerLogIn) => {
        let isloggedIn = await isUserLoggedin();
        if (isPartnerLogIn && !isloggedIn) {
          const tmAuthData: any = await generateTmAuth();
          if (
            tmAuthData?.loginToken?.success === false ||
            tmAuthData?.appSocialToken?.success === false
          ) {
            if (tmAuthData?.loginToken?.success === false) {
              sendPdtLoginTokenError({
                ...tmAuthData?.loginToken?.error,
                apiUrl: "loginTokenApi",
              });
              sendPdtLoginTokenErrorV2({
                ...tmAuthData?.loginToken?.error,
                apiUrl: "loginTokenApi",
              });
            }
            if (tmAuthData?.appSocialToken?.success === false) {
              sendPdtAppSocialError({
                ...tmAuthData?.appSocialToken?.error,
                apiUrl: "appSocialLoginApi",
              });
              sendPdtAppSocialErrorV2({
                ...tmAuthData?.appSocialToken?.error,
                apiUrl: "appSocialLoginApi",
              });
            }
            //@ts-ignore
            window.auth = {
              status: false,
            };
            resolve({
              loggedIn: false,
              mobileRequiredError: tmAuthDataHasMobileError(tmAuthData),
            });
          }
          isloggedIn = await isUserLoggedin();
          //@ts-ignore
          window.auth = {
            status: false,
          };
          resolve({
            loggedIn: !!isloggedIn,
            mobileRequiredError: tmAuthDataHasMobileError(tmAuthData),
          });
        }
        //@ts-ignore
        window.auth = {
          status: false,
        };
        resolve({ loggedIn: !!isloggedIn, mobileRequiredError: false });
      });
    }
  });
};

export const logoutFromPartner = async () => {
  await logout();
};

export const loginInPartner = async (
  sendPdtLoginPopOpen?: Function,
  sendPdtLoginPopupOpenV2?: Function
) => {
  await sendPdtLoginPopOpen?.();
  await sendPdtLoginPopupOpenV2?.();
  const data = await loginToPartner();
  return data;
};
