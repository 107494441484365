import useSWR from "swr";
import { useContext } from "react";

import { PublicEnv } from "@tm/ui/contexts";

export const usePincode = () => {
  const { proxyCmsPrefix } = useContext(PublicEnv);
  const url = `${proxyCmsPrefix}/jsons?type_in=inrPincodeDeliveryEstimates&type_in=pincodes`;
  const { data } = useSWR<any>(url);

  const pincodeData =
    data?.find((obj: any) => obj.type === "pincodes")?.data || {};
  const deliveryEstimateData =
    data?.find((obj: any) => obj.type === "inrPincodeDeliveryEstimates")
      ?.data || {};

  return {
    pincodeData,
    deliveryEstimateData,
  };
};
