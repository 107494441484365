import { deviceType } from "@tm/static/utils";
import { theme } from "@tm/utility/global";
import styled from "styled-components";

interface FaqItemProps {
  padding?: string;
}
interface FaqItemHdrProps {
  isActive?: boolean;
}
interface PanHdrProps {
  shadow?: boolean;
}

export const PanHdr = styled.div<PanHdrProps>`
  display: flex;
  justify-content: space-between;
  padding: 26px 30px 22px 30px;
  align-items: center;
  @media ${deviceType.mobile} {
    padding: 16px;
  }
  ${(props) =>
    props.shadow &&
    `
        box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
    `}
`;
export const PanDtl = styled.div`
  padding: 0 30px 30px 30px;
  max-height: 390px;
  overflow-y: auto;
  @media ${deviceType.mobile} {
    padding: 0 16px 16px 16px;
    max-height: unset;
    height: calc(100% - 66px);
  }
`;

export const FaqItem = styled.div<FaqItemProps>`
  border-bottom: 1px #e2e2e2 solid;
  ${(props) =>
    props.padding &&
    `
        padding-bottom: ${props.padding};
    `};
`;
export const FaqItemHdr = styled.div<FaqItemHdrProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 0;
  padding-right: 30px;
  cursor: pointer;
  position: relative;
  ::after {
    content: "";
    display: inline-flex;
    border: solid #008cff;
    border-width: 0 2px 2px 0;
    padding: 3px;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%)
      ${(props) => (props.isActive ? "rotate(-135deg)" : "rotate(45deg)")};
  }
`;
export const AnswerList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;
export const Cta = styled.a`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${theme.blueText};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CtaIconWpr = styled.div`
  margin: 0 0 0 10px;
`;
