import { BaseStyles, FontStyles } from "../CurrencySEOCommonUI";
import CurrencyDocument from "./CurrencyDocument";
import { CurrencyRatePageStyle } from "./CurrencyRatePageTemplate.styles";
import Header from "./Header";
import Hero from "./Hero";
import Introduction from "./Introduction";
import BestRate from "./BestRate";
import WhyToBuy from "./WhyToBuy";
import HowToBuy from "./HowToBuy";
import HowBestPrice from "./HowBestPrice";
import OtherServices from "./OtherServices";
import CurrencyInformation from "./CurrencyInformation";
import CurrencyExchangeInformation from "./CurrencyExchangeInformation";
import TopReviewedCurrencies from "./TopReviewedCurrencies";
import OtherConversions from "./OtherConversions";
import Faqs from "./Faqs";
// import { CurrencyFooter } from "./CurrencyFooter";
import Banner from "./Banner";
import ForexProduct from "../CurrencyConverterPageTemplate/ForexProducts";
import { CurrencyFooter } from "../CurrencyPageTemplate/CurrencyFooter";
import { useEffect } from "react";
import { PAGE_NAMES, trackSeoPageLoad } from "@tm/utility/analytics";
import PopularDenomitations from "./PopularDenominations";
import CurrencyExchangeInCity from "./CurrencyExchangeInCity";
import { SEO_TEMPLATE_TYPE } from "@tm/static/utils";

export const CurrencyRatePageTemplate = ({
  id,
  currencyData,
  inrExchangeRate,
  currCodeToCurrRate,
  switchInrExchnageRate,
  canonicalHref,
}: any) => {
  useEffect(() => {
    trackSeoPageLoad({
      pageName: PAGE_NAMES["SEO_CURRENCY_RATE"]?.replace(
        "NAME",
        `${currencyData?.curr_code}`
      ),
      currency: currencyData?.curr_code,
    });
  }, []);

  return (
    <>
      <FontStyles />
      <BaseStyles />
      <CurrencyRatePageStyle />

      <CurrencyDocument
        currencyData={currencyData}
        canonicalHref={canonicalHref}
      />
      <>
        <Header currencyData={currencyData} />
        <Hero
          currencyData={currencyData}
          inrExchangeRate={inrExchangeRate}
          currCodeToCurrRate={currCodeToCurrRate}
          switchInrExchnageRate={switchInrExchnageRate}
          breadcrumb={currencyData?.breadcrumb}
        />

        <div className="pageContainer">
          <ForexProduct
            currencyData={currencyData}
            pageName={PAGE_NAMES["SEO_CURRENCY_RATE"]?.replace(
              "NAME",
              `${currencyData?.curr_code}`
            )}
            templateType={SEO_TEMPLATE_TYPE.CURRENCY_RATE}
          />
          <div className="pageContent">
            <Banner currencyData={currencyData} />
            <Introduction currencyData={currencyData} />
            <BestRate currencyData={currencyData} />
            <WhyToBuy currencyData={currencyData} />
            <HowToBuy currencyData={currencyData} />
            <HowBestPrice currencyData={currencyData} />
            <OtherServices currencyData={currencyData} />
            <PopularDenomitations
              currencyData={currencyData}
              inrExchageRate={inrExchangeRate}
            />
            <CurrencyInformation currencyData={currencyData} />
            <OtherConversions currencyData={currencyData} />
            <Faqs currencyData={currencyData} />
            <TopReviewedCurrencies currencyData={currencyData} />
            <CurrencyExchangeInformation currencyData={currencyData} />
            <CurrencyExchangeInCity currencyData={currencyData} />
          </div>
        </div>

        {currencyData?.footer ? (
          <CurrencyFooter footer={currencyData?.footer} hideTopLinks />
        ) : null}

        {/* <CurrencyFooter footer={currencyData?.footer} /> */}
      </>
    </>
  );
};
