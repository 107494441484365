import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useEffect } from "react";

export const usePrivacyAnimation = () => {
  const ANIMATION_TYPE = "ease-in-out";
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    let timeId = setTimeout(() => {
      const rootContainerElement = document.getElementById("root_container");
      const lockVerElement = document.getElementById("lockVer_ref");
      const lockElement = document.getElementById("lock_ref");
      const lockPrivacyElement = document.getElementById("lock_privacy_ref");

      gsap.to(lockElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: lockPrivacyElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        x: 0,
        y: "31",
        duration: 0.4,
        ease: ANIMATION_TYPE,
      });
      gsap.to(lockVerElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: lockPrivacyElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        opacity: 1,
        duration: 0.4,
        ease: ANIMATION_TYPE,
      });
    }, 300);
    return () => clearTimeout(timeId);
  }, []);
};
