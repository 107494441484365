import * as React from "react";
interface iconProps {
  width?: number;
  height?: number;
  color?: string;
  strokeColor?: string;
}
const GreenThumb = (
  { width = 68, height = 68, color = "#B8F5E0", strokeColor = "#2B825E" },
  props: iconProps
) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.9}>
      <circle cx={34} cy={34} r={34} fill={color} />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.9941 41.7929C26.9114 38.6018 26.7641 35.7716 26.6576 32.4186C26.6341 31.6768 26.4559 30.8953 26.5125 30.1551C26.5311 29.9116 26.6406 29.838 26.8678 29.8269C27.3625 29.8027 27.8573 29.768 28.3499 29.7177C29.2948 29.6211 29.9473 29.0633 30.4908 28.338C30.9359 27.7443 31.3279 27.0957 31.849 26.5774C32.7661 25.6656 33.7474 24.8157 34.7322 23.9753C35.7546 23.1026 36.8145 22.2754 37.5771 21.1426C38.1292 20.3225 38.395 19.4154 38.458 18.4396C38.4616 18.3826 38.4621 18.3253 38.464 18.2681C38.499 17.2409 38.923 16.7623 39.9539 16.6439C40.3108 16.6027 40.6799 16.6116 41.0378 16.6497C41.7315 16.7237 42.1872 17.1822 42.5548 17.7171C43.2321 18.7027 43.4639 19.8199 43.4117 20.9969C43.3307 22.8296 42.5845 24.3854 41.41 25.7702C40.9072 26.363 40.0648 27.5005 39.9939 27.6268C39.9231 27.7531 40.3041 27.7184 40.4401 27.7184C41.4893 27.7183 42.5405 27.6758 43.587 27.7291C44.5062 27.7759 45.4543 27.8099 46.3293 28.06C48.4391 28.6633 48.6169 30.9703 47.448 32.0797C47.2063 32.3091 46.9738 32.5483 46.7509 32.7693C46.5281 32.9903 47.4702 33.4524 47.7787 33.8427C48.4975 34.7517 48.3243 36.0824 47.4152 36.7991C47.0505 37.0866 46.6633 37.3454 46.2576 37.6378C45.8519 37.9303 47.2453 39.2953 46.9276 40.3103C46.5921 41.3814 45.7304 41.7705 44.6845 41.9156C43.6386 42.0606 45.497 43.5138 45.2821 44.1136C45.1757 44.4109 44.1464 44.8917 43.7459 45.0692C43.6406 45.1159 43.5322 45.1506 43.4195 45.1745C42.8085 45.3038 40.679 45.7234 39.469 45.5627C38.1811 45.3917 36.9022 45.1123 35.6408 44.7957C34.0333 44.392 32.4168 44.0987 30.7578 44.1533C29.7504 44.1866 29.1871 44.2321 28.095 44.3186C27.0029 44.4051 27.0382 43.495 26.9941 41.7929Z"
      fill="white"
      stroke={strokeColor}
      strokeWidth={2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7244 30.4868C16.789 32.41 17.1503 41.5624 17.2702 44.5907C17.2917 45.1356 17.7457 45.5566 18.2909 45.5446L22.1877 45.4595C22.7478 45.4473 23.1888 44.9839 23.1647 44.4242C23.032 41.3451 22.6293 32.2028 22.4542 30.4868C22.2428 28.4163 16.6468 28.174 16.7244 30.4868Z"
      fill="#B8F5E0"
      stroke={strokeColor}
      strokeWidth={2}
    />
    <ellipse
      cx={59.4264}
      cy={38.419}
      rx={2.42616}
      ry={2.42089}
      transform="rotate(180 59.4264 38.419)"
      fill="#2B825E"
    />
    <ellipse
      cx={16.9409}
      cy={14.9344}
      rx={1.94093}
      ry={1.93671}
      transform="rotate(180 16.9409 14.9344)"
      fill="#2B825E"
    />
    <ellipse
      cx={32.469}
      cy={55.6078}
      rx={0.970464}
      ry={0.968354}
      transform="rotate(180 32.469 55.6078)"
      fill="#2B825E"
    />
    <ellipse
      cx={22.9409}
      cy={58.9383}
      rx={1.94093}
      ry={1.93671}
      transform="rotate(180 22.9409 58.9383)"
      fill="#2B825E"
    />
  </svg>
);
export { GreenThumb };
