import { ReactMarkdown } from "react-markdown/lib/react-markdown";

export const CityFooter = ({ footer, city }: any) => {
  return (
    <>
      {footer?.section_footer?.[0]?.links
        ? footer?.section_footer?.map((section: any) => (
            <section className="reviewedCurrencies">
              <h3 className="container">{section?.title}</h3>
              <div className="container">
                {section?.links?.map((col: any) => (
                  <ul className="footerLinkCol">
                    {col?.map((link: any) => {
                      const { title, href, target } = link;
                      const linkProps = {
                        href,
                        target,
                      };
                      if (title == `Currency Exchange in ${city}`) return null;
                      return (
                        <li key={title} className="footerLink">
                          <a {...linkProps}>{title}</a>
                        </li>
                      );
                    })}
                  </ul>
                ))}
              </div>
            </section>
          ))
        : null}

      <footer>
        <div className="container">
          {footer?.bottom_footer?.map((col: any) =>
            col.title ? (
              <div key={col?.title} className="item">
                <ReactMarkdown className="linkTitle">
                  {col?.title}
                </ReactMarkdown>
                <ul>
                  {col?.list?.map((link: any) => {
                    const { title, href, target } = link;
                    const linkProps = {
                      href,
                      target,
                    };
                    return (
                      <li key={title}>
                        <a {...linkProps}>{title}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null
          )}
        </div>
      </footer>
    </>
  );
};
