import styled from "styled-components";
import { deviceType } from "@tm/static/utils";
import { InnerContentWrap } from "../../CommonStyle/CommonStyles.style";
import { FlexCompStyle } from "../FlexComp/FlexComp.style";
import { H3TagStyle } from "../TextStyles/TextStyles.style";

type ClaimProps = {};

export const ClaimYourInsuranceStyle = styled.section<ClaimProps>`
  background: #f6f6f6;
  width: 100%;
  padding: 100px 0;
  @media ${deviceType.mobile} {
    padding: 48px 16px;
    ${InnerContentWrap} {
      > ${FlexCompStyle} {
        overflow-y: auto;
        margin-right: -16px;
        margin-left: -16px;
        padding-left: 16px;
        padding-right: 16px;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
`;

export const ClaimStructureStyle = styled.div<ClaimProps>`
  background: #ffffff;
  border-radius: 18.9111px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 226px;
  position: relative;
  flex-shrink: 0;
  > img {
    flex-shrink: 0;
    position: absolute;
    right: -34%;
    top: 40%;
  }
  /* > img:last-child {
        display: none;
    } */
  @media ${deviceType.mobile} {
    padding: 16px;
    width: 180px;
    > img {
      right: -21%;
    }
  }
`;
