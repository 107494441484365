import styled, { css } from "styled-components";
import { SpanTagStyle } from "../TextStyles/TextStyles.style";

export const ProcessStepsWrap = styled.div`
  /* position: relative; */
`;

export const ProcessStepsStyle: any = styled.ul`
  display: flex;
  gap: 4px;
  height: 20px;
  /* position: absolute;
    right: 0; */
  margin: ${(p: any) => (p.margin ? p.margin : "")};
  list-style-type: none;

  ${(props: any) =>
    props.forHeader &&
    `
   height:2px;
   `}

  ${(props: any) =>
    props.position &&
    `
position:relative;
`}
li {
    position: relative;
    min-width: 85px;
    ${(props: any) =>
      props.forMweb &&
      `
   min-width:31px !important;
   `}
    ${(props: any) =>
      props.forHeader &&
      `
   min-width:unset;
   flex:1;
   `}
  }

  li:after {
    position: absolute;
    content: "";
    width: 85px;
    height: 4px;
    background: #ffffff;
    border-radius: 4px;
    left: 0;
    top: -7px;

    ${(props: any) =>
      props.forMweb &&
      `
   width:31px !important;
   top: 12px!important;
   `}
    ${(props: any) =>
      props.forHeader &&
      `
   width:100% !important;
   background: #E2E2E2;
   top: 0px!important;
   `}
  }
  li.active:after {
    background: #008cff;
  }
`;

export const ProcessStepsInfo = styled(SpanTagStyle)`
  white-space: nowrap;
  color: #afafaf;
  font-weight: 700;
`;
