import type { OmniDataType } from "./omniModel";

export function trackEventMweb(omniData: OmniDataType) {
  if (window?.omniscriptLoaded && typeof window.s !== "undefined") {
    for (const [key, value] of Object.entries(omniData)) {
      window.s[key] = value;
    }
    window.s.t();
  } else {
    window.omnidata?.push(omniData);
  }
}
