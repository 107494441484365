import React from "react";

export interface BasicStyleTypes extends React.HTMLAttributes<HTMLDivElement> {
  margin?: any;
  bgColor?: any;
  padding?: any;
}

export interface SVGProps extends React.SVGProps<SVGSVGElement> {
  margin?: string;
  small?: string;
  large?: string;
  width?: string;
  height?: string;
  position?: any;
  bottom?: any;
  right?: any;
  "z-index"?: any;
}

export interface BmfTravellerDetailsTypes
  extends React.HTMLAttributes<HTMLDivElement> {
  margin?: string;
  doc?: any;
}

export interface ForexCurrToLoadStyleTypes
  extends React.HTMLAttributes<HTMLDivElement> {
  bgColor?: any;
  marginLast?: any;
  errorState?: any;
}

export enum CASHBACK_STATUS {
  COMMITTED = "COMMITTED",
  CANCELLED = "CANCELLED",
  CREDITED = "CREDITED",
  AVAILABLE = "AVAILABLE",
}

export const CASHBACK_TEXT: { [key: string]: string } = {
  [CASHBACK_STATUS.COMMITTED]: "Applied",
  [CASHBACK_STATUS.CANCELLED]: "Invalid",
  [CASHBACK_STATUS.CREDITED]: "Credited",
  [CASHBACK_STATUS.AVAILABLE]: "Available",
};

export type CouponStatusType =
  | CASHBACK_STATUS.AVAILABLE
  | CASHBACK_STATUS.COMMITTED
  | CASHBACK_STATUS.CANCELLED
  | CASHBACK_STATUS.CREDITED;

export type SelectedCouponsType = {
  coupon_cashback: number;
  status: CouponStatusType;
  type: string;
  min_order_amount?: number;
};

export const DASHBOARD_STATUS = {
  DOCUMENT_PENDING: "DOCUMENT_PENDING",
  ORDER_SUBMITTED: "ORDER_SUBMITTED",
  ORDER_CANCELLED: "ORDER_CANCELLED",
  PAYMENT_DECLINED: "PAYMENT_DECLINED",
  DEAL_DONE: "DEAL_DONE",
};
