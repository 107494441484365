import { Fragment } from "react";

import {
  OptionsContainer,
  InfoText,
  ModalContentEqualizer,
} from "./ModalScreen.style";
import { H5Tag, ImageTag, SpanTag } from "@tm/static";
import { chooseModalThirdScreenDataProps } from "@tm/static/data";

export interface PurposesScreenProps {
  purposesData?: chooseModalThirdScreenDataProps[];
  title?: string;
  infoBarData?: string;
  itemClickCallback?: (
    item: chooseModalThirdScreenDataProps,
    idx: number
  ) => void;
}

const PurposesScreen = ({
  purposesData,
  title,
  infoBarData,
  itemClickCallback = () => {},
}: PurposesScreenProps) => {
  return (
    <Fragment>
      {title && <H5Tag margin="0px 0px 24px 0px">{title}</H5Tag>}
      {purposesData && (
        <ModalContentEqualizer>
          <OptionsContainer isLastChildNormal={true}>
            {purposesData.map((item, idx) => {
              return (
                <li
                  key={item.id}
                  onClick={() => itemClickCallback(item, idx)}
                  className={item.isSelected ? "active" : ""}
                >
                  {item?.icon && <ImageTag src={item.icon} />}
                  <SpanTag margin={"0px"} fontSize="xs" color="gray">
                    {item.label}
                  </SpanTag>
                </li>
              );
            })}
          </OptionsContainer>
          {infoBarData && <InfoText>{infoBarData}</InfoText>}
        </ModalContentEqualizer>
      )}
    </Fragment>
  );
};

export default PurposesScreen;
