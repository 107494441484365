import styled from "styled-components";

export interface checkButtonProp {
  travellingTo?: boolean;
  margin?: string;
  isDisabled?: boolean;
}

export const CheckButtonStyle = styled.span<checkButtonProp>`
    display: flex;
    align-items: baseline;
    cursor: pointer;
    ${(props) => props.margin && `margin: ${props.margin};`}

    label {
        margin-left: 10px;
        flex: 1;
        width: 100%;
        cursor: pointer;
        user-select: none;
}
    }

    ${(props) =>
      props.travellingTo &&
      `
 label{
  position:absolute;
  left:8px;
  bottom:8px;
 }
`}
    input[type="checkbox"] {
        ${(props) => !props.isDisabled && `cursor: pointer;`}
        visibility: hidden;
        position: relative;
        margin-right: 5px;
        margin-top: unset;
        cursor: pointer;
    }
    @supports (-moz-appearance:none) {

input[type="checkbox"] {
    visibility: visible;
    position: relative;
    margin-right: 5px;
    margin-top: unset;
    cursor: pointer;
    width: 18px;
    height: 18px;
    accent-color: #008CFF;
}
}
    input[type='checkbox']::before {
        width: 16px;
        height: 16px;
        display: flex;
        visibility: visible;
        flex-shrink: 0;
        border: 1px solid #4a4a4a;
        border-radius: 4px;
        background: #fff;
        position: absolute;
        top: 0px;
        left: -2px;
        content: '';
    }
    input[type='checkbox']::after {
        content: '';
        display: block;
        width: 4px;
        height: 9px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        margin: 0px 0 0 4px;
        position: absolute;
        visibility: visible;
        top: 3px;
        left: 0px;
    }
    input[type='checkbox']:checked::before {
        ${(props) =>
          props.isDisabled ? `background: #0000004D;` : `background: #008cff;`}
        border: unset;
    }
`;
