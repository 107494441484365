import styled from "styled-components";

type footerProps = {
  openedLink?: boolean;
  margin?: string;
  flexWrap?: boolean;
};

export const LandingFooterStyle = styled.section`
  background: #000;
  padding-top: 32px;
  padding-bottom: 64px;
  width: 100%;
`;

export const LandingFooterSub = styled.div<footerProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;
  ${(p) => p.openedLink && ``}
`;

export const LandingFooterWrap = styled.div`
  margin: 0 auto;
  width: 1200px;
`;

export const LandingFooterImg = styled.div`
  width: 325px;
  height: 27px;
  margin-bottom: 50px;
`;

export const LandingFooterList = styled.ul<footerProps>`
  display: flex;
  gap: 10px;
  margin-bottom: 26px;
  width: 964px;
  flex-wrap: wrap;
  margin: ${(props) => (props.margin ? props.margin : "")};

  li {
    position: relative;
  }
  li:last-child {
    position: static;
  }
  li:after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #fff;
    top: 12px;
    right: -7px;
  }
  li:last-child:after {
    width: 0;
    height: 0;
  }
  li a {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
  &:last-child {
    margin-bottom: 100px;
  }
  ${(props) =>
    props.flexWrap &&
    `
      height:198px;
      flex-wrap:wrap;
  `}
`;

export const LandingFooterFlex = styled.div`
  display: flex;
  gap: 95px;
  margin-top: 100px;
`;

export const LandingFooterCopyRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  align-self: center;

  span:nth-child(2) {
    font-weight: 600;
    margin-top: 3px;
  }
`;
