interface RoundSearchIconProps {
  color?: string;
  bgColor?: string;
  props?: any;
}

export const RoundSearchIcon = ({
  color = "#008CFF",
  bgColor = "#EAF5FF",
  props,
}: RoundSearchIconProps) => (
  <svg
    width={41}
    height={40}
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.667969} width={40} height={40} rx={20} fill={bgColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.5619 28.385L29.774 28.1729L25.6906 24.0902C26.9474 22.6164 27.6316 20.7734 27.6316 18.8309C27.6316 16.6585 26.7852 14.6153 25.2494 13.0787C22.078 9.90738 16.9184 9.90738 13.7457 13.0786C10.5744 16.2499 10.5744 21.411 13.7456 24.5823C15.289 26.1257 17.3526 26.961 19.4971 26.961C21.415 26.961 23.275 26.2842 24.7564 25.0234L28.8406 29.1063C28.9661 29.2318 29.1332 29.3 29.3069 29.3C29.4807 29.3 29.6485 29.2318 29.774 29.1063C30.0315 28.8488 30.0315 28.4317 29.7745 28.1734L29.5619 28.385ZM24.316 23.6489C21.6593 26.3056 17.3361 26.306 14.6791 23.6489C12.0222 20.9921 12.0222 16.6689 14.6791 14.0121C15.973 12.7194 17.7006 12.0202 19.4971 12.0202C21.295 12.0202 23.0225 12.7193 24.316 14.0121C25.6033 15.2994 26.3114 17.0097 26.3114 18.8309C26.3114 20.6517 25.603 22.3625 24.316 23.6489Z"
      fill={color}
    />
  </svg>
);
