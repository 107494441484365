import * as React from "react";
interface iconprop {}
const PalmIcon = (props: iconprop) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_841_31489"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={31}
      height={32}
    >
      <path
        d="M15.9969 31.4116C24.2811 31.4116 30.9968 24.4792 30.9968 15.9277C30.9968 7.37621 24.2811 0.443848 15.9969 0.443848C7.71274 0.443848 0.99707 7.37621 0.99707 15.9277C0.99707 24.4792 7.71274 31.4116 15.9969 31.4116Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_841_31489)">
      <path
        d="M15.9979 31.4116C24.2821 31.4116 30.9978 24.4792 30.9978 15.9277C30.9978 7.37621 24.2821 0.443848 15.9979 0.443848C7.71372 0.443848 0.998047 7.37621 0.998047 15.9277C0.998047 24.4792 7.71372 31.4116 15.9979 31.4116Z"
        fill="white"
      />
      <path
        d="M11.3066 21.8853V31.9407"
        stroke="#4A4A4A"
        strokeWidth={0.818587}
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16504 25.8278H16.4472L11.3061 22.603L6.16504 25.8278Z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7095 16.0577L11.1371 16.3766L12.0277 15.9456L12.0637 15.4023L12.4913 15.7213L13.382 15.2902L13.4179 14.7469L13.8455 15.0659L14.7361 14.6348L14.7721 14.0916L15.1997 14.4105L16.4465 13.8073C16.4465 13.8073 11.5064 11.7082 9.6123 17.1146L10.6735 16.6009L10.7095 16.0577Z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.85 11.2113L10.9338 11.749L11.8589 12.0943L12.2564 11.7364L12.3403 12.274L13.2654 12.6194L13.6629 12.2614L13.7468 12.7991L14.6718 13.1445L15.0695 12.7866L15.1533 13.3241L16.4483 13.8076C16.4483 13.8076 14.3927 8.7174 9.3501 11.1578L10.4523 11.5692L10.85 11.2113Z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2319 13.7372C16.2319 13.7372 23.3683 17.8456 20.5713 32.2839L22.9199 31.505C22.9199 31.505 23.6983 18.0954 16.8938 13.5894"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9336 19.5866L14.4544 19.5L14.7889 18.5451L14.4422 18.1347L14.9631 18.0482L15.2977 17.0931L14.9508 16.6827L15.4717 16.5962L15.8063 15.6413L15.4596 15.2309L15.9803 15.1443L16.4487 13.8076C16.4487 13.8076 11.5176 15.9294 13.8817 21.1347L14.2803 19.9969L13.9336 19.5866Z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7947 10.6935L21.7587 11.2368L20.868 11.6678L20.4404 11.349L20.4045 11.8921L19.5138 12.3233L19.0862 12.0043L19.0502 12.5476L18.1596 12.9786L17.732 12.6596L17.6961 13.2029L16.4492 13.8064C16.4492 13.8064 18.0462 8.54374 23.2835 10.4989L22.2222 11.0125L21.7947 10.6935Z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0989 7.70335L18.4456 8.11383L18.1111 9.06876L17.5903 9.1552L17.937 9.56568L17.6025 10.5206L17.0816 10.6072L17.4284 11.0175L17.0937 11.9725L16.573 12.059L16.9197 12.4695L16.4515 13.8062C16.4515 13.8062 13.9757 8.91942 19.0184 6.479L18.6198 7.61679L18.0989 7.70335Z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3613 15.5066L21.9637 15.8645L21.0386 15.5191L20.9549 14.9816L20.5572 15.3395L19.6321 14.9941L19.5483 14.4565L19.1507 14.8144L18.2257 14.469L18.1418 13.9314L17.7442 14.2894L16.4492 13.806C16.4492 13.806 21.1833 11.2504 23.5474 16.4558L22.4452 16.0443L22.3613 15.5066Z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8332 14.6856C17.8332 14.9777 17.6039 15.2144 17.3209 15.2144C17.038 15.2144 16.8086 14.9777 16.8086 14.6856C16.8086 14.3935 17.038 14.1567 17.3209 14.1567C17.6039 14.1567 17.8332 14.3935 17.8332 14.6856Z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7837 15.0467C16.7837 15.3229 16.5667 15.5469 16.2991 15.5469C16.0314 15.5469 15.8145 15.3229 15.8145 15.0467C15.8145 14.7704 16.0314 14.5464 16.2991 14.5464C16.5667 14.5464 16.7837 14.7704 16.7837 15.0467Z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0713 14.4856C17.0713 14.7619 16.8543 14.9859 16.5867 14.9859C16.319 14.9859 16.1021 14.7619 16.1021 14.4856C16.1021 14.2093 16.319 13.9854 16.5867 13.9854C16.8543 13.9854 17.0713 14.2093 17.0713 14.4856Z"
        fill="#4A4A4A"
      />
      <mask
        id="mask1_841_31489"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={17}
        y={29}
        width={5}
        height={3}
      >
        <path
          d="M17.1123 29.6724H21.1051V31.7332H17.1123V29.6724Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_841_31489)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1113 31.7332C17.1113 30.595 18.0052 29.6724 19.1077 29.6724C20.2103 29.6724 21.1041 30.595 21.1041 31.7332"
          fill="#4A4A4A"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1074 31.7334C19.1074 30.0901 20.3979 28.7578 21.9899 28.7578C23.5818 28.7578 24.8724 30.0901 24.8724 31.7334"
        fill="#4A4A4A"
      />
    </g>
  </svg>
);
export { PalmIcon };
