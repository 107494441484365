import { navigationActions } from "@tm/insurance/navigation";
import { AnchorTag } from "@tm/static";
import { useReferrerContext } from "@tm/ui-widgets/hooks";
import React from "react";

interface LandingHeaderBackButtonProps {
  handleBackBtn?: () => void;
  displayWhiteHeader?: boolean;
}

export const LandingHeaderBackButton = (
  props: LandingHeaderBackButtonProps
) => {
  const serverReferrer = useReferrerContext();

  const handleBackButton = serverReferrer
    ? () => navigationActions.goBack()
    : () => history.back();

  const { handleBackBtn = handleBackButton, displayWhiteHeader } = props;
  return handleBackBtn ? (
    <AnchorTag handleClick={handleBackBtn}>
      {
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825L13.425 18.6L12 20Z"
            fill="#4A4A4A"
          />
        </svg>
      }
    </AnchorTag>
  ) : (
    <div style={{ width: "24px", height: "24px" }}></div>
  );
};
