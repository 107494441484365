import styled from "styled-components";
import { H2TagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "@tm/static/utils";

export const SafenSecureTravStyle = styled.section`
  width: 100%;
  background: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  ${H2TagStyle} {
    width: 64%;
  }
  @media ${deviceType.mobile} {
    padding: 0 16px;
  }
`;

export const SafenSecureTravCrdStyle = styled.div`
  background: linear-gradient(
      128.03deg,
      rgba(227, 247, 245, 0.36) 2.1%,
      rgba(230, 237, 255, 0.441706) 49.06%,
      rgba(214, 232, 244, 0.6) 80.75%
    ),
    #fcffff;
  border-radius: 14px;
  padding: 24px;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  > img {
    position: absolute;
    right: 30px;
    bottom: 24px;
  }
  @media ${deviceType.mobile} {
    gap: 12px;
  }
`;

export const FeatList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 80%;
  li {
    position: relative;
    padding-left: 14px;
  }
  li:after {
    content: "";
    position: absolute;
    background: #000000;
    border-radius: 2px;
    width: 4px;
    height: 14px;
    top: 6px;
    left: 0;
  }
  @media ${deviceType.mobile} {
    margin-bottom: 12px;
  }
`;

export const SafenSecureBgWrap = styled.div`
  position: absolute;
  top: 17px;
  /* left: 0; */
  right: -122px;
  width: 100%;
  z-index: -1;
  height: 558px;
  img {
    width: 100%;
    height: 558px;
  }
`;
