import { PageName } from "@tm/insurance/analytics";
import { ErrorScreen, ErrorScreenProps } from "../ui-container/ErrorScreen";

export function withError(
  component: JSX.Element,
  error: ErrorScreenProps["error"],
  pageName: PageName
) {
  return error ? (
    <ErrorScreen error={error} pageName={pageName} trackError />
  ) : (
    component
  );
}
