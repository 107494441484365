import React from "react";

import { NoInsurancePlanStyle } from "./NoInsurancePlan.style";
import { theme } from "@tm/utility/global";
import NoInsIcon from "../../icons/NoInsIcon.svg";
import { ImageComponent, ParaTag } from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
interface noInsProp {
  title?: string | TextNodeType;
  info?: string | TextNodeType;
}

const NoInsurancePlan: React.FC<noInsProp> = ({ title, info }) => {
  const device = useBreakpointV2();
  return (
    <NoInsurancePlanStyle>
        {" "}
      <ImageComponent
        width="80px"
        height="80px"
        src="https://tripmoneycmsimgak.mmtcdn.com/img/No_Ins_Icon_55794fe5f5.svg"
      />
      {title && (
        <ParaTag
          margin={device == "lg" ? "24px 0 8px" : "32px 0 32px"}
          fontSize="large"
          fontWeight="bold"
          color={theme.blackText}
        >
          <FormattedText value={title} />
        </ParaTag>
      )}
      {info && (
        <ParaTag fontSize="sm" color={theme.greyText7}>
          <FormattedText value={info} />
        </ParaTag>
      )}
    </NoInsurancePlanStyle>
  );
};

export default NoInsurancePlan;
