import React from "react";

import { SpanTag, ImageTag } from "@tm/static";

import { PoweredByStyle } from "./PoweredBy.style";

import { PoweredByProps } from "./types";

const PoweredBy: React.FC<PoweredByProps> = ({
  label,
  imgSrc,
  margin,
  fontSize = "large",
  fontWeight = "regular",
  imgWidth = "61px",
}) => {
  return (
    <PoweredByStyle margin={margin}>
      {label && (
        <SpanTag fontWeight={fontWeight} fontSize={fontSize} color="#fff">
          {label}
        </SpanTag>
      )}
      {imgSrc && (
        <ImageTag src={imgSrc} width={imgWidth} margin="0px 0px 0px 4px" />
      )}
    </PoweredByStyle>
  );
};

export default PoweredBy;
