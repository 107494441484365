import styled from "styled-components";

import { theme } from "@tm/utility/global";
import { deviceType } from "@tm/static/utils";

export const ChooseLocationStyle = styled.div`
  /* width: 480px; */
  display: flex;
  flex-direction: column;
`;

export const ChooseLocationInrStyle = styled.div`
  padding: 0px 24px 0px 24px;
  min-height: 400px;
  @media screen and ${deviceType.mobile} {
    min-height: unset;
    max-height: 60vh;
    overflow-y: auto;
  }
`;

export const ButtonContainer = styled.div`
  padding: 16px;
  display: flex;

  @media screen and ${deviceType.mobile} {
    box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.25);
  }
`;

export const StepCounter = styled.ul`
  display: flex;
  gap: 2px;

  & > li {
    width: 35px;
    height: 4px;
    border-radius: 4px;
    background: #e2e2e2;
  }

  & > li.activeBar {
    background: ${theme.blueText};
  }
`;
