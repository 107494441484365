import React, { Dispatch, SetStateAction, useState } from "react";
import { List, CellMeasurer, CellMeasurerCache } from "react-virtualized";
import { CountryItem } from "./SelectCountryModalV2.style";
import CheckButton from "../CheckButton/CheckButton";
import FlexComp from "../FlexComp/FlexComp";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { ImageComponent } from "../ImageComponent/ImageComponent";
import FormattedText from "../FormattedText/FormattedText";
import {
  ArrowButtonWrap,
  DescriptionWrap,
  ColumnWrapper,
} from "./CountryList.style";
import { useEffect } from "react";
import AnchorTag from "../TextStyles/AnchorTag";
import { debounce } from "@tm/insurance/utils";
import SpanTag from "libs/ui-widgets/src/lib/static/components/ui-atoms/TextStyles/SpanTag";
import ParaTag from "../TextStyles/ParaTag";
interface CountryListProps {
  countryData: any[];
  countryHandler: (code: string) => void;
  isCheckedHandler: (data: any) => boolean;
  searchQueryHighlighter: any;
  setCountryData: Dispatch<SetStateAction<any[]>>;
  setIsSelectedError: Dispatch<SetStateAction<boolean>>;
}

const CountryList = ({
  countryData,
  countryHandler,
  isCheckedHandler,
  setCountryData,
  searchQueryHighlighter,
  setIsSelectedError,
}: CountryListProps) => {
  const device = useBreakpointV2();
  const windowWidth = window.innerWidth - 60;
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 58,
  });

  const [showViewAllButton, setShowViewAllButton] = useState<boolean>(false);
  useEffect(() => {
    const temp = countryData.map((data: any) => {
      data["showDesc"] = false;
      return data;
    });
    setCountryData(temp);
  }, []);

  const calculateListHeight = (data: any) => {
    const rowCount = data?.length ?? 0;
    const height = rowCount * 100;
    return height;
  };

  const handleDescription = (code: string) => {
    const data = countryData.filter((data: any) => data.code === code)[0];
    if (data.showAllDesc || data.description.length < 80) {
      setShowViewAllButton(false);
      console.log(data.description.length);
      return data.description;
    }
    setShowViewAllButton(true);
    const tempDescData = data.description.substring(0, 80).split(" ");
    tempDescData.pop();
    const descData = tempDescData.join(" ") + "...";
    return descData;
  };

  const handleRegionDescription = (code: string) => {
    let temp = countryData.map((data: any) => {
      if (data.code === code && !data.showDesc) {
        data["showDesc"] = true;
        data["showAllDesc"] = false;
        return data;
      }
      data["showDesc"] = false;
      data["showAllDesc"] = false;
      return data;
    });
    setCountryData(temp);
  };

  const handleErrorToast = debounce(() => {
    setIsSelectedError(false);
  }, 3000);

  const handleCountrySelectError = (country: any) => {
    if (country.isDisabled) {
      setIsSelectedError(true);
      handleErrorToast();
    } else {
      {
        !country.isSelected &&
          !country.showDesc &&
          handleRegionDescription(country.code);
      }
      {
        country.isSelected &&
          country.showDesc &&
          handleRegionDescription(country.code);
      }
      countryHandler(country.code);
    }
  };

  const handleViewAll = (code: string) => {
    let temp = countryData.map((data: any) => {
      if (data.code === code) {
        data["showAllDesc"] = !data["showAllDesc"];
      }
      return data;
    });
    setCountryData(temp);
  };

  const rowRenderer = ({
    index,
    key,
    parent,
  }: {
    index: number;
    key: string;
    parent: any;
  }) => {
    const item = countryData?.[index];
    if (!item) {
      return null; // Return null if item is undefined
    } else if (item.type === "HEADER") {
      return (
        <CellMeasurer cache={cache} parent={parent} rowIndex={index} key={key}>
          <CountryItem
            disableCursor={true}
            margin={device === "lg" ? "0px 17px 0px 0px" : ""}
          >
            <FormattedText
              value={item.name}
              baseStyle={{ fontWeight: "bold", fontSize: "14px" }}
            />
          </CountryItem>
        </CellMeasurer>
      );
    } else if (item.code) {
      return (
        <CellMeasurer cache={cache} parent={parent} rowIndex={index} key={key}>
          <div key={key}>
            <CountryItem
              disableCursor={true}
              margin={device === "lg" ? "0px 17px 0px 0px" : ""}
            >
              <FlexComp>
                <CheckButton
                  handleChange={() => handleCountrySelectError(item)}
                  isChecked={item.isDisabled ? true : isCheckedHandler(item)}
                  isDisabled={item.isDisabled}
                >
                  <FlexComp
                    handleClick={() => handleCountrySelectError(item)}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {item.subtitle ? (
                      <ColumnWrapper>
                        <SpanTag>{searchQueryHighlighter(item.name)}</SpanTag>
                        <SpanTag>
                          <FormattedText
                            value={item.subtitle}
                            baseStyle={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          />
                        </SpanTag>
                      </ColumnWrapper>
                    ) : (
                      searchQueryHighlighter(item.name)
                    )}
                    <FormattedText
                      value={item.countryCount}
                      baseStyle={{
                        padding: "0px 10px 0px 0px",
                        fontSize: "12px",
                        color: "#4A4A4A",
                      }}
                    />
                    <FormattedText
                      value={item.regionName}
                      baseStyle={{
                        fontSize: "12px",
                        color: "#0000004D",
                      }}
                    />
                  </FlexComp>
                </CheckButton>
                {item.type === "REGION" && (
                  <ArrowButtonWrap
                    onClick={() => {
                      handleRegionDescription(item.code);
                    }}
                  >
                    <ImageComponent
                      width="9px"
                      height="9px"
                      margin="0px 0px 0px 0px"
                      src={
                        item.showDesc
                          ? "https://tripmoneycmsimgak.mmtcdn.com/img/Arrow_Up_Blue_21102e8ed6.svg"
                          : "https://tripmoneycmsimgak.mmtcdn.com/img/Arrow_Down_Blue_5d58cf6783.svg"
                      }
                    />
                  </ArrowButtonWrap>
                )}
              </FlexComp>
              {item.showDesc && item.type === "REGION" && (
                <DescriptionWrap>
                  <FormattedText
                    value={item.descTitle}
                    baseStyle={{
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  />
                  <div>
                    <FormattedText
                      value={handleDescription(item.code)}
                      baseStyle={{
                        fontSize: "14px",
                      }}
                    />
                  </div>
                  {showViewAllButton && (
                    <AnchorTag
                      handleClick={() => {
                        handleViewAll(item.code);
                      }}
                      fontWeight="bold"
                    >
                      View All
                    </AnchorTag>
                  )}
                </DescriptionWrap>
              )}
            </CountryItem>
          </div>
        </CellMeasurer>
      );
    }
    return;
  };
  return (
    <List
      width={device == "lg" ? 360 : windowWidth}
      height={calculateListHeight(countryData)}
      rowHeight={cache.rowHeight}
      autoHeight={true}
      rowCount={countryData.length}
      rowRenderer={rowRenderer}
      style={{ minHeight: 600 }}
    />
  );
};

export default CountryList;
