import { theme } from "../../css/Global";
import { ArrowIcon } from "../../icons/ArrowIcon";
import { ChevronDownIcon } from "../../icons/ChevronDownIcon";
import SectionDividers from "../SectionDividers/SectionDividers";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import {
  DeliveryPersuasionComp,
  DeliveryPersuasionStyle,
  Cta,
  ImgWpr,
  DeliveryLocationText,
} from "./DeliveryPersuasion.style";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";
import {
  PageNameMapping,
  SectionNameMapping,
  ElementNameMapping,
  VERSIONS,
} from "../../../constants";
import { getElementUniqueIds } from "../../../utils";

interface deliveryPersuasionProps {
  text?: string;
  fontSize?: string;
  editLocationHandler?: () => void;
  margin?: string;
  padding?: string;
  bgColor?: string;
  upArrow?: string;
  checkPincode?: boolean;
  checkPincodeHandler?: () => void;
  checkPincodeLabel?: string;
  arrowIcon?: string;
  ctaText?: string;
  showIcon?: boolean;
  styleWithFlex?: boolean;
  isTextCenter?: boolean;
  showDivider?: boolean;
  version?: number;
}

const DeliveryPersuasion = ({
  margin,
  padding = "8px 12px",
  bgColor = `linear-gradient(90.01deg, #deedff 0%, rgba(230, 241, 255, 0) 108.89%)`,
  text,
  editLocationHandler,
  upArrow,
  showIcon = true,
  checkPincode = false,
  checkPincodeLabel,
  checkPincodeHandler,
  arrowIcon,
  ctaText,
  isTextCenter = false,
  styleWithFlex = true,
  showDivider = true,
  version,
}: deliveryPersuasionProps) => {
  return checkPincode ? (
    <>
      {checkPincode ? (
        <>
          {showDivider && (
            <SectionDividers
              width={"40px"}
              borderRadius={"15px"}
              margin="0px 0px 12px 0px"
            />
          )}
          <DeliveryPersuasionComp
            bgColor={theme.whiteText}
            padding={"Opx"}
            margin={margin}
          >
            <DeliveryPersuasionStyle
              fontSize="sm"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </DeliveryPersuasionComp>
          <ParaTag margin="0 0 8px 0">
            <Cta onClick={checkPincodeHandler}>
              {checkPincodeLabel}{" "}
              {arrowIcon ? (
                <ImgWpr isFlex={true}>
                  <ImageTag src={arrowIcon} />
                </ImgWpr>
              ) : (
                <ImgWpr isFlex={true}>
                  <ArrowIcon />
                </ImgWpr>
              )}
            </Cta>
          </ParaTag>
        </>
      ) : (
        <DeliveryPersuasionComp
          margin={margin}
          bgColor={bgColor}
          padding={padding}
          isTextCenter={isTextCenter}
          styleWithFlex={styleWithFlex}
        >
          <DeliveryPersuasionStyle fontSize="sm">
            <SpanTag
              color={
                version === VERSIONS.V3 ? theme.blackText : theme.whiteText
              }
              fontSize="sm"
              dangerousText={text}
            />
            {(!!ctaText || showIcon) && (
              <>
                {" "}
                <AnchorTag
                  id={getElementUniqueIds(
                    PageNameMapping.LISTING_PAGE,
                    SectionNameMapping.DELIVERY,
                    ElementNameMapping.DELIVERY_LOC
                  )}
                  fontSize={"sm"}
                  display="inline-flex"
                  handleClick={editLocationHandler}
                >
                  {!!ctaText && (
                    <DeliveryLocationText
                      fontWeight="bold"
                      color={theme.blueText}
                      dangerouslySetInnerHTML={{
                        __html: ctaText,
                      }}
                    ></DeliveryLocationText>
                  )}
                  {showIcon &&
                    (upArrow ? (
                      <ImageTag src={upArrow} />
                    ) : (
                      <ChevronDownIcon
                        sizeY={24}
                        viewBoxPosY={2}
                        viewBoxSizeY={24}
                      />
                    ))}
                </AnchorTag>
              </>
            )}
          </DeliveryPersuasionStyle>
        </DeliveryPersuasionComp>
      )}
    </>
  ) : (
    <DeliveryPersuasionComp
      margin={margin}
      bgColor={bgColor}
      padding={padding}
      isTextCenter={isTextCenter}
      styleWithFlex={styleWithFlex}
    >
      <DeliveryPersuasionStyle fontSize="sm">
        <SpanTag
          color={version === VERSIONS.V3 ? theme.blackText : theme.greyText9}
          fontSize="sm"
          dangerousText={text}
        />
        {(!!ctaText || showIcon) && (
          <>
            {" "}
            <AnchorTag
              id={getElementUniqueIds(
                PageNameMapping.LISTING_PAGE,
                SectionNameMapping.DELIVERY,
                ElementNameMapping.DELIVERY_LOC
              )}
              fontSize={"sm"}
              display="inline-flex"
              handleClick={editLocationHandler}
            >
              {!!ctaText && (
                <DeliveryLocationText
                  fontWeight="bold"
                  color={theme.blueText}
                  dangerouslySetInnerHTML={{
                    __html: ctaText,
                  }}
                ></DeliveryLocationText>
              )}
              {showIcon &&
                (upArrow ? (
                  <ImageTag src={upArrow} />
                ) : (
                  <ChevronDownIcon
                    sizeY={24}
                    viewBoxPosY={2}
                    viewBoxSizeY={24}
                  />
                ))}
            </AnchorTag>
          </>
        )}
      </DeliveryPersuasionStyle>
    </DeliveryPersuasionComp>
  );
};

export default DeliveryPersuasion;
