import React from "react";

import { FlexComp, ShimmerVersion2, ShimmerVersion3 } from "@tm/static";
import { OrderDetailCompStyle } from "./OrderDetailComp.style";

const OrderDetailInsuranceShimmer = () => {
  return (
    <OrderDetailCompStyle>
      <FlexComp margin="0 0 24px" gap="8px">
        <ShimmerVersion3 width="56px" height="56px" />
        <FlexComp flexDirection="column" margin="0">
          <ShimmerVersion2 width="532px" height="28px" />
          <ShimmerVersion2 width="266px" height="16px" />
        </FlexComp>
      </FlexComp>
      <ShimmerVersion2 height="76px" width="100%" />
    </OrderDetailCompStyle>
  );
};

export default OrderDetailInsuranceShimmer;
