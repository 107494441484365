import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { ImageComponent } from "@tm/static";
import { CheckButton } from "@tm/static";
import {
  H6Tag,
  FlexComp,
  SpanTag,
  RadioButton,
  LandingInput,
  FormattedText,
} from "@tm/static";
import { formatDate } from "@tm/insurance/utils";
import { DATE_FORMATS } from "@tm/insurance/constants";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { getAnalytics } from "@tm/insurance/analytics";

import {
  getFormFieldErrorMsg,
  isDobValid,
  subtractYrsFromDate,
} from "@tm/insurance/utils";

import TravellerDescp from "./TravellerDescpV2";
import {
  TravellerDetailsStyle,
  GenderTypeItem,
  ArrowButtonWrap,
} from "./AddTravellerDetail.style";
import { ITravellerDetailsFormV2, ITravellerDetailsProps } from "./type";

const minDate = subtractYrsFromDate(new Date(), 70).toISOString().split("T")[0];
const maxDate = new Date().toISOString().split("T")[0];
const analytics = getAnalytics();

const TravellerDetailsV2 = ({
  travellerIndex,
  count,
  travellerRef,
  isLoading,
  firstErrorIndex,
  travellerNumber,
  genderTypes,
  handleArrowButton,
  travellerSelected,
  handleGenderChange,
  handleTravellerSelect,
  selectedGender,
  saveFormValuesOnBlur,
  secondaryContactData,
  isSelected,
  travellerCount,
  isSavedTravellers,
  isValidForm,
  isEditable,
  isDomesticBooking,
}: ITravellerDetailsProps) => {
  const device = useBreakpointV2();
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<ITravellerDetailsFormV2>();

  const error = errors?.travellerData?.[travellerIndex];

  const { name, dob, gender } = secondaryContactData || {};

  return (
    <TravellerDetailsStyle
      ref={travellerIndex === firstErrorIndex ? travellerRef : null}
    >
      {travellerIndex === 0 && (
        <FlexComp
          alignItems="center"
          justifyContent="space-between"
          margin="0px 0px 20px 0px"
        >
          <FormattedText
            value={isSavedTravellers ? "Saved Travellers" : "Travellers"}
            baseStyle={{
              color: "#4A4A4A",
              fontSize: "14px",
              fontWeight: "700",
            }}
          />
          <FormattedText
            value={
              count?.toString() + "/" + travellerCount?.toString() + " Added"
            }
            baseStyle={{
              color: isValidForm ? "#2B825E" : "#757575",
              fontSize: "14px",
              fontWeight: "700",
            }}
          />
        </FlexComp>
      )}
      <Controller
        control={control}
        name={`travellerData.${travellerIndex}.isSelected`}
        render={({ field: { value } }) => (
          <CheckButton
            isChecked={value}
            isDisabled={!isEditable}
            handleChange={() => {
              if (!isEditable) return;
              if (
                !value &&
                getValues(`travellerData.${travellerIndex}.isSavedTraveller`)
              ) {
                if (getValues(`travellerData.${travellerIndex}.isEdited`)) {
                  analytics?.trackTravDetailsEvents({
                    actionType: "saved_traveller_edit_clicked",
                  });
                } else {
                  analytics?.trackTravDetailsEvents({
                    actionType: "saved_traveller_clicked",
                  });
                }
              }
              handleTravellerSelect &&
                handleTravellerSelect(value, travellerIndex);
              saveFormValuesOnBlur();
            }}
          >
            {!travellerSelected ? (
              <TravellerDescp
                travellerIndex={travellerIndex}
                isSelected={isSelected}
                saveFormValuesOnBlur={saveFormValuesOnBlur}
                travellerNumber={travellerIndex + 1}
                genderTypeSelected={selectedGender}
                isDomesticBooking={isDomesticBooking}
                secondaryContactData={secondaryContactData}
                handleEditState={handleArrowButton}
              />
            ) : (
              <FlexComp alignItems="center" justifyContent="space-between">
                <H6Tag>{travellerNumber}</H6Tag>
                <ArrowButtonWrap onClick={handleArrowButton}>
                  <ImageComponent
                    width="9px"
                    height="9px"
                    margin="0px 0px 0px 0px"
                    src={
                      travellerSelected
                        ? "https://tripmoneycmsimgak.mmtcdn.com/img/Arrow_Up_Blue_21102e8ed6.svg"
                        : "https://tripmoneycmsimgak.mmtcdn.com/img/Arrow_Down_Blue_5d58cf6783.svg"
                    }
                  />
                </ArrowButtonWrap>
              </FlexComp>
            )}
          </CheckButton>
        )}
      />
      <>
        {travellerSelected && (
          <React.Fragment>
            <FlexComp
              flexDirection={device == "lg" ? "row" : "column"}
              margin="16px 0 16px"
              gap="16px"
            >
              {name?.isEnabled && (
                <Controller
                  control={control}
                  name={`travellerData.${travellerIndex}.name`}
                  rules={{
                    required: {
                      value: isSelected ? name?.isRequired ?? true : false,
                      message: getFormFieldErrorMsg("name"),
                    },
                    pattern: {
                      value: new RegExp(name?.regex || ""),
                      message: getFormFieldErrorMsg("name", name?.errorMessage),
                    },
                  }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <LandingInput
                      flex="1"
                      requiredField={name?.isRequired}
                      placeholderTxt={name?.placeholder}
                      labelTxt={name?.title}
                      inputValue={value}
                      errorState={error?.name?.message}
                      disableState={isLoading}
                      handleChange={onChange}
                      handleBlur={() => {
                        saveFormValuesOnBlur(travellerIndex);
                        onBlur();
                      }}
                      autoComplete="off"
                      inputWidth="100%"
                      isDisabled={!isEditable}
                    />
                  )}
                />
              )}
              {dob?.isEnabled && (
                <Controller
                  control={control}
                  name={`travellerData.${travellerIndex}.dob`}
                  rules={{
                    required: {
                      value: isSelected ? dob?.isRequired ?? true : false,
                      message: getFormFieldErrorMsg("dob"),
                    },
                    validate: isSelected
                      ? (value) =>
                          isDobValid(value, dob?.regex) ||
                          getFormFieldErrorMsg("dob", dob?.errorMessage)
                      : {},
                  }}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <LandingInput
                        flex="1"
                        placeholderTxt={dob?.placeholder}
                        requiredField={dob?.isRequired}
                        labelTxt={dob?.title}
                        inputType="date"
                        inputValue={formatDate(
                          value,
                          DATE_FORMATS["YYYY-MM-DD"]
                        )}
                        minValue={minDate}
                        maxValue={maxDate}
                        errorState={error?.dob?.message}
                        disableState={isLoading}
                        handleChange={onChange}
                        handleBlur={() => {
                          saveFormValuesOnBlur(travellerIndex);
                          onBlur();
                        }}
                        inputWidth="100%"
                      />
                    );
                  }}
                />
              )}
            </FlexComp>
            {gender?.isEnabled && (
              <>
                <SpanTag
                  margin="0 0 8px"
                  fontSize="md"
                  fontWeight="bold"
                  color={theme.greyText}
                >
                  <FormattedText value={gender?.title} />
                </SpanTag>
                <FlexComp gap="8px">
                  <Controller
                    control={control}
                    name={`travellerData.${travellerIndex}.gender`}
                    rules={{
                      required: {
                        value: isSelected ? gender?.isRequired ?? true : false,
                        message: getFormFieldErrorMsg("gender"),
                      },
                    }}
                    render={({ field: { value } }) => (
                      <>
                        {genderTypes?.map((item) => (
                          <GenderTypeItem
                            selected={item.label === value}
                            key={item.id}
                            onClick={() => {
                              handleGenderChange(item, travellerIndex);
                              saveFormValuesOnBlur(travellerIndex);
                            }}
                          >
                            <RadioButton activeBtn={item.label === value}>
                              <SpanTag
                                fontSize="xs"
                                fontWeight="bold"
                                color={
                                  item.label === value
                                    ? theme.blueText
                                    : theme.greyText
                                }
                              >
                                {item.label}
                              </SpanTag>
                            </RadioButton>
                          </GenderTypeItem>
                        ))}
                      </>
                    )}
                  />
                </FlexComp>
              </>
            )}
          </React.Fragment>
        )}
      </>

      {/* {travellerIndex === addedTravellerCount && (
                
            )} */}
    </TravellerDetailsStyle>
  );
};

export default TravellerDetailsV2;
