import React from "react";
import { RadioButtonStyle } from "./RadioButton.style";

interface RadioBtnProps {
  children?: React.ReactNode;
  activeBtn?: boolean;
  handleChange?: (inputIndex: number) => void;
  inputIndex?: number;
  inputId?: string;
  isPadding?: boolean;
  showLabel?: boolean;
}

const RadioButton: React.FC<RadioBtnProps> = ({
  children,
  activeBtn,
  handleChange,
  inputIndex,
  inputId,
  isPadding,
  showLabel = true,
}) => {
  const handleRadioChange = () => {
    if (handleChange && inputIndex !== undefined) {
      handleChange(inputIndex);
    }
  };

  return (
    <RadioButtonStyle
      isPadding={isPadding}
      onChange={handleRadioChange}
      showLabel={showLabel}
    >
      <input
        type="radio"
        onChange={handleRadioChange}
        checked={activeBtn}
        id={inputId}
        name="radio-group"
      />
      <label htmlFor={inputId}>{children}</label>
    </RadioButtonStyle>
  );
};

export default RadioButton;
