import { theme } from "@tm/utility/global";
import { deviceType } from "@tm/static/utils";
import styled from "styled-components";

export const SnackbarContainer: any = styled.div`
  background: var(--bgColor, ${theme.lightRed});
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  border-radius: 6px;
  z-index: 1;
  width: 100%;
  ${(props: any) =>
    props.isPosFixed &&
    `
    @media ${deviceType.mobile} {
        position: fixed;
        bottom:  ${(p: any) => (p.bottom ? p.bottom : "16px")};
        left: 16px;
        width: calc(100% - 32px);
    }
    `}
  ${(props: any) => props.containerStyle};
`;

export const SnackbarButton: any = styled.button`
  background-color: transparent;
  color: ${theme.greyText};
  border: none;
  margin-left: 16px;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  font-family: Open Sans;
`;
