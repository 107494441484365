import styled from "styled-components";
import { SpanTagStyle } from "../../TextStyles/TextStyles.style";
import { deviceType } from "@tm/static/utils";

export const ConversionRatesStyle = styled.div<any>`
  padding-top: 12px;
`;

export const BlueBox = styled.div<any>`
  padding: 16px;
  padding-right: 150px;
  background: linear-gradient(
    159.48deg,
    #edfbff 4.69%,
    rgba(236, 241, 255, 0.736176) 27.76%,
    #e7f1f9 92.13%
  );
  border: 1px solid #ffffff;
  border-radius: 8px;

  @media ${deviceType.mobile} {
    padding-right: 100px;
  }
`;

export const LabelsWpr = styled.p<any>`
  display: flex;
  margin-top: 16px;
`;

export const ListItems = styled.ul<any>`
  margin-top: 10px;
`;

export const ListItem = styled.li<any>`
  display: flex;
  padding: 8px;

  &:first-child {
    background: #e4fbf4;
    border-radius: 8px;
  }
  &:not(:first-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  &:last-child {
    border-bottom: unset;
  }
`;

export const LeftCont = styled.div<any>`
  flex: 1;
`;

export const SubLabelWpr = styled.div<any>`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

export const SubLabel = styled(SpanTagStyle)<any>`
  font-size: 12px;
  font-weight: 700;
  > span {
    color: #4a4a4a;
  }
`;
export const Banner = styled(SpanTagStyle)<any>`
  background: linear-gradient(45deg, #43e1a8 14.64%, #219393 85.36%);
  border-radius: 16px;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 8px;
  margin-left: 4px;
`;
