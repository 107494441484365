export const theme = {
  blackText: "#000",
  blackText3: "#022135",
  greyText: "#4a4a4a",
  whiteText: "#fff",
  lightGreyText: "#9b9b9b",
  darkText: "#002137",
  greenText: "#2B825E",
  greenText3: " #009997",
  greenText4: "#38A97A",
  greenText5: "#2B825E",
  greyText2: "#cbcbcb",
  greyText3: "#6b6b6b",
  greyText4: "#9b9b9b",
  greyText5: "#AFAFAF",
  greyText6: "#757575",
  greyText7: "#969696",
  greyText8: "rgba(255, 255, 255, 0.6)",
  greyText9: "rgba(255, 255, 255, 0.8)",
  redText: "#960D11",
  redText2: "#EB2026",
  greenNeonText: "#8BEECD",
  blueNeonText: "rgba(0, 162, 255, 1)",
  blueText: "#008CFF",
  brownText: "#6B4300",
  yellowText: "#CF8100",
  lightBlack: "rgba(0, 0, 0, 0.3)",
  gray97: "#f7f7f7",
  lightGrey: "#d2d2d2",
  blueLight: "#F9FCFF",
  smokeWhite: "#F6F6F6",
  lightSilver: "#D8D8D8",
  snowWhite: "#FFFAFA",
  gainsBoro: "#e2e2e2",
  darkGreen: "#1E5C43",
  lightGreen: "#249054",
  persianGreen: "#009997",
  lightRed: "#fcdadb",
  blackText2: "#1E1E1E",
  whiteText2: "#f5f5f5",
  greenText2: "#E4FBF4",
  redText3: "#A73232",
  redText4: "#C53B3B",
  yellowText2: "#FFEDD1",
  yellowText3: "#FFDA9E",
  yellowText4: "#FFC76B",
  darkYellowText: "#B48B28",
  prussianBlue: "#005499",
  purpleTxt: "#A05ACD",
  berryTxt: "#AF0067",
  greenNeonText2: "#33BA9E",
  blueText2: "#F6FAFF",
};

export const fontWeight = {
  regular: "400",
  medium: "500",
  semiBold: "600",
  bold: "700",
  black: "900",
};

export const fontSize = {
  font10: "10px",
  font12: "12px",
  font14: "14px",
  font16: "16px",
  font18: "18px",
  font20: "20px",
  font22: "22px",
  font24: "24px",
  font26: "26px",
  font28: "28px",
};

export const lineHeight = {
  lineHeight16: "16px",
  lineHeight20: "20px",
  lineHeight24: "24px",
  lineHeight26: "24px",
  lineHeight32: "32px",
};
