import React from "react";
import { CheckButton, FormattedText, SpanTag } from "@tm/static";

import { PlanHeader, PopularTag } from "./InsurancePlans.style";
import { insurancePlanCompType } from "./type";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";

const InsurancePlansHeader = ({
  data,
  handleCompareSelect,
}: insurancePlanCompType) => {
  const device = useBreakpointV2();

  return (
    <PlanHeader>
      {data?.popularTag && device == "lg" && (
        <PopularTag>
          <SpanTag
            fontSize="xs"
            fontWeight="bold"
            color={theme.whiteText}
            textTransform="uppercase"
          >
            <FormattedText value={data?.popularTag} />
          </SpanTag>
        </PopularTag>
      )}
      {data?.packName && (
        <SpanTag
          fontSize={device == "lg" ? "sm" : "xs"}
          fontWeight="bold"
          textTransform="uppercase"
        >
          <FormattedText value={data?.packName} />
        </SpanTag>
      )}
      {data.comparePlans && (
        <CheckButton
          inputIndex={data.productId}
          inputId={data.productId}
          handleChange={
            handleCompareSelect
              ? () => handleCompareSelect(parseInt(data.productId))
              : () => {}
          }
          isChecked={data.selectedForCompare}
          margin="0 0 0 auto"
        >
          <SpanTag fontSize="sm" color={theme.greyText}>
            <FormattedText value={data.comparePlans} />
          </SpanTag>
        </CheckButton>
      )}
    </PlanHeader>
  );
};

export default InsurancePlansHeader;
