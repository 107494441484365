import * as React from "react";
interface iconProps {}
const ActivityStarIcon = (props: iconProps) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={14.4011}
      cy={2.48945}
      r={0.8}
      fill="url(#paint0_linear_841_8153)"
    />
    <circle
      cx={2.53301}
      cy={14.0926}
      r={1.2}
      fill="url(#paint1_linear_841_8153)"
    />
    <circle
      cx={15.6014}
      cy={15.691}
      r={0.4}
      fill="url(#paint2_linear_841_8153)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.38233 1.93811L10.501 5.52421C10.5603 5.71554 10.7317 5.84488 10.925 5.84488H14.5443C15.9517 5.84488 16.537 7.7216 15.3983 8.58362L12.4703 10.799C12.3137 10.9177 12.2483 11.1277 12.3083 11.319L13.4263 14.9045C13.8617 16.2992 12.3297 17.4585 11.191 16.5965L8.26233 14.3811C8.10633 14.2624 7.89433 14.2624 7.73767 14.3811L4.80967 16.5965C3.671 17.4585 2.139 16.2992 2.57366 14.9045L3.69233 11.319C3.75233 11.1277 3.68633 10.9177 3.53033 10.799L0.601662 8.58362C-0.537005 7.7216 0.0483286 5.84488 1.45566 5.84488H5.075C5.26833 5.84488 5.44033 5.71554 5.49967 5.52421L6.61833 1.93811C7.05367 0.544069 8.947 0.544069 9.38233 1.93811Z"
      fill="url(#paint3_linear_841_8153)"
    />
    <path
      d="M10.1404 7.4776L7.14142 10.5528L5.86169 9.24046C5.61903 8.9917 5.22559 8.9917 4.98302 9.24046C4.74036 9.48938 4.74036 9.89276 4.98302 10.1416L6.70208 11.9045C6.82337 12.0288 6.98243 12.091 7.14142 12.091C7.3004 12.091 7.45946 12.0288 7.58075 11.9045L11.019 8.37874C11.2617 8.1299 11.2617 7.72644 11.019 7.47769C10.7765 7.22885 10.383 7.22876 10.1404 7.4776Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_841_8153"
        x1={13.6011}
        y1={3.28945}
        x2={15.2011}
        y2={1.68945}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3D452" />
        <stop offset={1} stopColor="#F09819" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_8153"
        x1={1.33301}
        y1={15.2926}
        x2={3.73301}
        y2={12.8926}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3D452" />
        <stop offset={1} stopColor="#F09819" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_841_8153"
        x1={15.2014}
        y1={16.091}
        x2={16.0014}
        y2={15.291}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3D452" />
        <stop offset={1} stopColor="#F09819" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_841_8153"
        x1={2.38703e-7}
        y1={16.8926}
        x2={16}
        y2={0.892577}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3D452" />
        <stop offset={1} stopColor="#F09819" />
      </linearGradient>
    </defs>
  </svg>
);
export { ActivityStarIcon };
