import React, { useEffect, useState } from "react";

function CountryFormatter({ title }: { title: string }) {
  const [country, setCountry] = useState<string>();
  const [count, setCount] = useState<number | "">();

  useEffect(() => {
    if (title && title?.length > 30) {
      const tempCountry = title?.substring(0, 30)?.split(/[,&]/);
      if (tempCountry.length > 1) {
        tempCountry.pop();
      }

      setCountry(tempCountry?.join(", "));

      const tempCount =
        title && title?.split(/[,&]/).length - tempCountry.length;
      setCount(tempCount);
    } else {
      setCountry(title);
    }
  }, [title]);

  return (
    <>
      {country}
      {title && title?.length > 30 ? (
        <span
          style={{
            fontSize: 12,
            marginLeft: 0,
            color: "#9B9B9B",
          }}
        >
          {` ...  +${count} more`}
        </span>
      ) : null}
    </>
  );
}

export default CountryFormatter;
