import React from "react";

import { ParaTagStyle } from "./TextStyles.style";
interface ParaTagProps {
  color?: string;
  children?: any;
  margin?: any;
  fontSize?: "xxs" | "xs" | "sm" | "md" | "large" | "xlarge" | "xxlarge";
  fontWeight?: "regular" | "medium" | "bold" | "extraBold" | "black";
  customWidth?: any;
  handleClick?: any;
  textAlign?: any;
  lineHeight?: any;
  dangerousText?: any;
  id?: string;
}

const ParaTag: React.FC<ParaTagProps> = ({
  children,
  fontSize = "sm",
  fontWeight = "regular",
  color = "#000000",
  margin,
  textAlign,
  lineHeight,
  dangerousText,
  id,
}) => {
  return dangerousText ? (
    <ParaTagStyle
      id={id}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      margin={margin}
      textAlign={textAlign}
      lineHeight={lineHeight}
      dangerouslySetInnerHTML={{ __html: dangerousText }}
    />
  ) : (
    <ParaTagStyle
      id={id}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      margin={margin}
      textAlign={textAlign}
      lineHeight={lineHeight}
    >
      {children}
    </ParaTagStyle>
  );
};

export default ParaTag;
