import React, { useState } from "react";

import { FlexComp, ParaTag, SpanTag, LandingButtonWhite } from "@tm/static";
import {
  OrderRightStyle,
  OrderRightHd,
  OrderRightCntnt,
} from "./OrderDetailRightComp.style";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";

interface accordionProp {
  title?: string | TextNodeType;
  info?: string | TextNodeType;
  handleInvoice?: () => void;
  amountPaid?: {
    breakupTxt?: string | TextNodeType;
    details?: {
      bank?: string | TextNodeType;
      accNo?: string | TextNodeType;
      amt?: string | TextNodeType;
    };
  };
}

const OrderAmtPaid = ({
  title,
  info,
  handleInvoice,
  amountPaid,
}: accordionProp) => {
  const { breakupTxt, details } = amountPaid ?? {};
  const { bank, accNo, amt } = details ?? {};
  return (
    <OrderRightStyle>
      <OrderRightHd>
        {title && (
          <SpanTag fontWeight="bold" fontSize="md">
            <FormattedText value={title} />
          </SpanTag>
        )}
        {info && (
          <SpanTag fontWeight="bold" fontSize="md">
            <FormattedText value={info} />
          </SpanTag>
        )}
      </OrderRightHd>
      <OrderRightCntnt>
        {breakupTxt && (
          <ParaTag fontWeight="bold" fontSize="xs">
            <FormattedText value={breakupTxt} />
          </ParaTag>
        )}
        <FlexComp justifyContent="space-between" margin="8px 0 16px">
          <FlexComp flexDirection="column" margin="unset">
            {bank && (
              <ParaTag fontSize="xs">
                <FormattedText value={bank} />
              </ParaTag>
            )}
            {accNo && (
              <SpanTag fontSize="xs">
                <FormattedText value={accNo} />
              </SpanTag>
            )}
          </FlexComp>
          {amt && (
            <SpanTag fontSize="xs">
              <FormattedText value={amt} />
            </SpanTag>
          )}
        </FlexComp>
        <LandingButtonWhite
          customWidth="100%"
          customTitle="DOWNLOAD INVOICE"
          onClick={handleInvoice}
        />
      </OrderRightCntnt>
    </OrderRightStyle>
  );
};

export default OrderAmtPaid;
