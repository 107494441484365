import { useEffect, useState } from "react";
import { useBreakpointV2 } from "./useDeviceContext";

export const useDevice = () => {
  const device = useBreakpointV2();
  const [breakpoint, setBreakpoint] = useState(device);

  useEffect(() => {
    if (breakpoint) return;
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setBreakpoint("sm");
      } else {
        setBreakpoint("lg");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoint]);
  return breakpoint;
};
