import { theme } from "@tm/utility/global";
import styled from "styled-components";
import { CheckButtonStyle } from "../CheckButton/CheckButton.style";
import { CommonModalDwebStyle } from "../CommonModalDweb/CommonModalDweb.style";
import { LandingButtonStyle } from "../LandingButton/LandingButton.style";
import { TMInputWrap } from "../TMFloatingInput/TMFloatingInput.style";
import { ToolTipCompWrapper } from "../ToolTipComp/ToolTipComp.style";
import { deviceType } from "libs/ui-widgets/src/lib/static/utils";

export const SelectCountryModalStyle = styled(CommonModalDwebStyle)`
  /* min-height: 525px; */
  .ReactVirtualized__Grid .ReactVirtualized__List {
    width: 100%;
  }
`;

export const CountryItem = styled.div`
  border-bottom: 1px solid #e8e8e8;
  padding: 18px 0;
  position: relative;
  cursor: pointer;
  ${LandingButtonStyle} {
    position: absolute;
    bottom: 16px;
    left: 16px;
  }
  ${CheckButtonStyle} {
    width: 100%;
  }

  .highlight {
    background-color: ${theme.yellowText3};
  }
  ${ToolTipCompWrapper} {
    position: fixed;
  }
`;
export const BtnWrap = styled.div`
  position: sticky;
  bottom: 16px;
  left: 16px;
  width: calc(100% - 32px);
`;

export const CountrySection = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  padding: 12px 0;
`;

export const CountrySectionWrap = styled.div`
  overflow-y: scroll;
  @media ${deviceType.mobile} {
    padding: 16px;
  }
`;

export const PopularRegionCountries = styled.div`
  border-radius: 4px;
  background: #f6f6f6;
  padding: 12px;
  margin-top: 12px;
`;

export const SelectCountrySheetStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0px;
  z-index: 10;
  background-color: #fff;
  ${TMInputWrap} {
    position: sticky;
    top: 0px;
    z-index: 1;
    width: calc(100% - 32px);
    left: 16px;
    padding-top: 16px;
  }
`;

export const SelectCountryHeader = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 16px;
  z-index: 1;
  background: #fff;
  left: -16px;
  right: -16px;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
`;
