import styled from "styled-components";

interface PriceTickerContainerProps {
  height: number;
}

interface TickerListProps {
  height: number;
}

export const PriceTickerContainer = styled.div<PriceTickerContainerProps>`
  overflow: hidden;
  height: ${(props) => props.height}px;
`;

export const TickerList = styled.ul<TickerListProps>`
  list-style: none;
  padding: 0;
  margin: 0;
  transition: all 0.8s ease-in-out;
  > li {
    height: ${(props) => props.height}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
  }
`;
