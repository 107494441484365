import { PARTNER, PartnerType } from "@tm/insurance/constants";
import styled from "styled-components";
import { theme } from "@tm/utility/global";
import { deviceType } from "libs/ui-widgets/src/lib/static/utils";

type ButtonInterface = {
  disableState?: boolean;
  customWidth?: string;
  customHeight?: string;
  margin?: string;
  flex?: string;
  flexShrink?: string;
  txtClipIcon?: boolean;
  btnTheme?: PartnerType;
};

export const LandingButtonStyle = styled.button<ButtonInterface>`
  background-image: ${theme.blueGradient};
  border-radius: 8px;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.21);
  color: #fff;
  width: ${(p) => (p.customWidth ? p.customWidth : "fit-content")};
  height: ${(p) => (p.customHeight ? p.customHeight : "fit-content")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: ${(p) => (p.margin ? p.margin : "")};
  flex: ${(p) => (p.flex ? p.flex : "unset")};
  flex-shrink: ${(p) => (p.flexShrink ? p.flexShrink : "0")};
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  position: relative;
  padding: 12px 20px;
  text-transform: uppercase;

  @media ${deviceType.desktop} {
    ${(p) =>
      p.btnTheme === PARTNER.MMT
        ? `
    background-image:linear-gradient(to right,#53B2FE,#065AF3);
    border-radius:38px;
    `
        : p.btnTheme === PARTNER.GI
        ? `border-radius: 30px;
    background: #FF6E39;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.14);`
        : `
    background: linear-gradient(135deg, #000000 14.64%, #330e0e 85.36%);
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.21);
    border-radius: 6px;
    `}
    ${(props) =>
      props.disableState &&
      `
  background: #CBCBCB;
  border-radius: 4px;
  pointer-events:none;
  span{ color: rgba(0, 0, 0, 0.3);}
  `}
  }

  span {
    font-size: 16px;
    font-weight: 900;
    flex-shrink: 0;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
  ${(props) =>
    props.disableState &&
    `
  background: #CBCBCB;
  border-radius: 4px;
  pointer-events:none;
  span{ color: rgba(0, 0, 0, 0.3);}
  `}
  ${(p) =>
    p.txtClipIcon &&
    `
    span{max-width:78%;}
    `}
`;

export const ShimmerWrap = styled.div`
  position: absolute;
  width: 68px;
  height: 48px;
  z-index: 12;
  top: -43px;
  animation: shimmmer 2.9s ease-in;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;

  @keyframes shimmmer {
    0% {
      transform: translateX(-311px);
    }
    100% {
      transform: translateX(311px);
    }
  }
`;

export const LandingButtonWhiteStyle = styled(LandingButtonStyle)`
  background: ${theme.whiteText};
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  color: ${theme.blueText};
  span {
    color: ${theme.blueText};
    font-size: 16px;
    font-weight: 700;
  }
  ${(props) =>
    props.disableState &&
    `
background: ${theme.greyText2};
color: rgba(0, 0, 0, 0.3);
pointer-events:none;
opacity: 0.7;
`}
`;

export const LandingButtonTertiaryStyle = styled(LandingButtonStyle)`
  background: ${theme.whiteText};
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  border: 1px solid ${theme.blueText};
  height: 32px;
  padding: 9px;
  color: ${theme.blueText};
  span {
    color: ${theme.blueText};
    font-size: 12px;
    font-weight: 700;
  }
  ${(props) =>
    props.disableState &&
    `
background: ${theme.greyText2};
color: rgba(0, 0, 0, 0.3);
pointer-events:none;
opacity: 0.7;
`}
`;

export const LandingButtonBlackOutlineStyle = styled(LandingButtonStyle)`
  background: ${theme.whiteText};
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: ${theme.blackText};
  border: 1px solid black;
  span {
    color: ${theme.blackText};
  }
`;
