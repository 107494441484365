import React from "react";
import { H5Tag, FlexComp, SpanTag, TMFloatingInput, ParaTag } from "@tm/static";
import {
  ConversionRatesStyle,
  HeadingWpr,
  TimerLabel,
  UpComingBannerBorder,
  UpComingBanner,
  UpComingTrip,
  InputFieldsWpr,
  ConvertedValLabel,
  SavedAmountBanner,
  SavedAmountLabelsWpr,
  SavedAmountLabel,
  LinkLabel,
} from "./ConversionRates.style";

import { theme } from "@tm/utility/global";
import { useBreakpoint } from "@tm/ui/hooks";

import { ConversionRatesProps } from "./types";

const ConversionRates: React.FC<ConversionRatesProps> = ({
  data,
  dropDownClick = () => {},
  converterInputs,
  savedAmountBtnClick = () => {},
}) => {
  const { label, timerTxt, upComingTrip, dropDownData, savedAmountBanner } =
    data;

  const { input1, input2, convertedValTxt } = converterInputs;

  const device = useBreakpoint();

  return (
    <ConversionRatesStyle>
      <FlexComp
        alignItems="flex-start"
        flexDirection={device === "lg" ? "row" : "column"}
      >
        {label && (
          <HeadingWpr>
            <H5Tag>{label}</H5Tag>
          </HeadingWpr>
        )}
        {timerTxt && <TimerLabel>{timerTxt}</TimerLabel>}
      </FlexComp>
      {upComingTrip && (
        <UpComingBannerBorder>
          <UpComingTrip>
            {upComingTrip.label && (
              <SpanTag fontWeight="bold">{upComingTrip.label}</SpanTag>
            )}
            {upComingTrip.subLabel && (
              <SpanTag fontSize="xs" margin="2px 0px 0px 0px">
                {upComingTrip.subLabel}
              </SpanTag>
            )}
            {upComingTrip.bannerTxt && (
              <UpComingBanner>{upComingTrip.bannerTxt}</UpComingBanner>
            )}
          </UpComingTrip>
        </UpComingBannerBorder>
      )}
      {dropDownData && (
        <TMFloatingInput
          labelTxt={dropDownData.label}
          handleDropdownPress={dropDownClick}
          fieldType="select"
          inputValue={dropDownData.subLabel}
          handleChange={() => {}}
          margin="16px 0px 0px 0px"
        />
      )}
      {converterInputs && (
        <InputFieldsWpr>
          {converterInputs.input1 && (
            <TMFloatingInput
              labelTxt={input1?.labelTxt}
              inputValue={input1?.inputValue}
              handleChange={input1?.handleChange}
              errorState={input1?.errorState}
              verifiedTag={input1?.verifiedTag}
              maxLength={input1?.maxLength}
              inputWidth={"calc(50% - 4px)"}
            />
          )}
          {converterInputs.input2 && (
            <TMFloatingInput
              labelTxt={input2?.labelTxt}
              inputValue={input2?.inputValue}
              handleChange={input2?.handleChange}
              errorState={input2?.errorState}
              verifiedTag={input2?.verifiedTag}
              maxLength={input2?.maxLength}
              inputWidth={"calc(50% - 4px)"}
              margin="0px 0px 0px 8px"
            />
          )}
        </InputFieldsWpr>
      )}
      {converterInputs?.convertedValTxt && (
        <ConvertedValLabel
          dangerouslySetInnerHTML={{ __html: convertedValTxt }}
        />
      )}
      {savedAmountBanner && (
        <SavedAmountBanner>
          {(savedAmountBanner.label || savedAmountBanner.subLabel) && (
            <SavedAmountLabelsWpr>
              {savedAmountBanner.label && (
                <SavedAmountLabel
                  dangerouslySetInnerHTML={{
                    __html: savedAmountBanner.label,
                  }}
                />
              )}
              {savedAmountBanner.subLabel && (
                <ParaTag color={theme.darkGreen} fontSize="xs">
                  {savedAmountBanner.subLabel}
                </ParaTag>
              )}
            </SavedAmountLabelsWpr>
          )}
          {savedAmountBanner.btnTxt && (
            <LinkLabel
              onClick={savedAmountBtnClick}
              margin="0px 0px 0px auto"
              fontWeight="bold"
            >
              {savedAmountBanner.btnTxt}
            </LinkLabel>
          )}
        </SavedAmountBanner>
      )}
    </ConversionRatesStyle>
  );
};

export default ConversionRates;
