import React, { useState } from "react";
import { InsurancePlansStyle } from "./InsurancePlans.style";
import { insurancePlanType } from "./type";
import { FlexComp, ShimmerVersion2 } from "@tm/static";
import { theme } from "@tm/utility/global";
import InsurancePlansCompShimmer from "./InsurancePlansCompShimmer";
import { useBreakpointV2 } from "@tm/ui-widgets";

const InsurancePlansShimmer = ({ data }: any) => {
  const device = useBreakpointV2();

  return (
    <InsurancePlansStyle>
      <ShimmerVersion2 width="100%" height="28px" margin="0 0 16px" />
      <ShimmerVersion2 width="100%" height="20px" margin="0 0 8px" />
      <FlexComp alignItems="center" margin="0 0 40px" gap="8px">
        <ShimmerVersion2 width="90px" height="32px" />
        <ShimmerVersion2 width="90px" height="32px" />
        <ShimmerVersion2 width="90px" height="32px" />
      </FlexComp>
      {data &&
        data.map((item: any, index: any) => {
          return (
            <React.Fragment key={index}>
              <InsurancePlansCompShimmer data={item} key={index} />
            </React.Fragment>
          );
        })}
    </InsurancePlansStyle>
  );
};

export default InsurancePlansShimmer;
