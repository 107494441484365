import {
  IAnalytics,
  PageLoadEvent,
  PageLoadErrorEvent,
  IOmniData,
  LandingEvents,
  ListingEvents,
  TravDetailsEvents,
  ThankYouEvents,
  AddPassportEvents,
  OrderSummaryEvents,
  OrderDetailsEvents,
} from "./IAnalytics";
import { pageNameCategoryMap } from "./constants";

const omnitureMap = {
  pageName: "eVar15",
  lob: "eVar24",
  siteSection: "m_ch",
  destination: "eVar31",
  ctaAction: "prop52",
  departureDate: "prop4",
  arrivalDate: "prop5",
  noOftravellers: "eVar8",
  travelPurpose: "eVar12",
  sumInsured: "prop46",
  bookingId: "eVar16",
  bookingStatus: "eVar14",
  errorCode: "eVar22",
  cmp: "m_v81",
  experiment: "eVar120",
};

export class MmtAnalytics implements IAnalytics {
  private trackEvent(pageName: string, omniData: IOmniData) {
    if (typeof window !== "undefined") {
      omniData = { ...omniData, pageName };
      if (window.omniscriptLoaded) {
        if (typeof window.s !== "undefined") {
          for (const [key, value] of Object.entries(omniData)) {
            window.s[key] = value;
          }
          window.s?.t?.();
        }
      } else {
        window.omnidata?.push?.(omniData);
      }
    }
  }

  trackPageLoadEvent(event: PageLoadEvent) {
    const { pageName, lob, siteSection } = pageNameCategoryMap[event?.pageName];

    let omniData = {
      [omnitureMap["pageName"]]: pageName,
      [omnitureMap["lob"]]: lob,
      [omnitureMap["siteSection"]]: siteSection,
      ...(event?.bookingId && {
        [omnitureMap["bookingId"]]: event.bookingId,
      }),
      ...(event?.bookingStatus && {
        [omnitureMap["bookingStatus"]]: event.bookingStatus,
      }),
      ...(event?.experiment && {
        [omnitureMap["experiment"]]: event.experiment,
      }),
    };

    if (event.pageName === "LISTING") {
      omniData = {
        ...omniData,
        ...(event.country && {
          [omnitureMap["destination"]]: event.country,
        }),
        ...(event.startDate && {
          [omnitureMap["departureDate"]]: event.startDate,
        }),
        ...(event.endDate && {
          [omnitureMap["arrivalDate"]]: event.endDate,
        }),
        ...(event.purpose && {
          [omnitureMap["travelPurpose"]]: event.purpose,
        }),
        ...(event.travellers && {
          [omnitureMap["noOftravellers"]]: event.travellers,
        }),
      };
    }

    this.trackEvent(pageName, {
      ...omniData,
      ...(event?.bookingId && { purchaseID: event.bookingId }),
    });
  }

  trackPageLoadErrorEvent(event: PageLoadErrorEvent) {
    const { pageName } = pageNameCategoryMap[event?.pageName];

    const omniData = {
      [omnitureMap["pageName"]]: pageName,
      [omnitureMap["ctaAction"]]: event.actionType,
      [omnitureMap["errorCode"]]: event.error,
    };
    this.trackEvent(pageName, omniData);
  }

  trackLandingEvents(event: LandingEvents) {
    const { pageName, lob } = pageNameCategoryMap["LANDING"];
    const omniData = {
      [omnitureMap["pageName"]]: pageName,
      [omnitureMap["lob"]]: lob,
      [omnitureMap["ctaAction"]]: event.actionType,
    };
    if (
      event.actionType === "other_countries_clicked" ||
      event.actionType === "popular_country_selected" ||
      event.actionType === "searched_countries" ||
      event.actionType === "selected_countries"
    ) {
      omniData[omnitureMap["destination"]] = event.payload;
    } else if (event.actionType === "travel_date_selected") {
      omniData[omnitureMap["departureDate"]] = event.payload.departure;
      omniData[omnitureMap["arrivalDate"]] = event.payload.arrival;
    } else if (event.actionType === "error_shown") {
      omniData[omnitureMap["errorCode"]] = event.payload;
    }
    this.trackEvent(pageName, omniData);
  }

  trackListingEvents(event: ListingEvents) {
    const { pageName, lob } = pageNameCategoryMap["LISTING"];
    const omniData = {
      [omnitureMap["pageName"]]: pageName,
      [omnitureMap["lob"]]: lob,
      [omnitureMap["ctaAction"]]: event.actionType,
    };
    if (event.actionType === "error_shown") {
      omniData[omnitureMap["errorCode"]] = event.payload;
    } else if (event.actionType === "sum_insured_selected") {
      omniData[omnitureMap["sumInsured"]] = event.payload;
    }
    this.trackEvent(pageName, omniData);
  }

  trackTravDetailsEvents(event: TravDetailsEvents) {
    const { pageName, lob } = pageNameCategoryMap["REVIEW"];
    const omniData = {
      [omnitureMap["pageName"]]: pageName,
      [omnitureMap["lob"]]: lob,
      [omnitureMap["ctaAction"]]: event.actionType,
    };
    if (event.actionType === "error_shown") {
      omniData[omnitureMap["errorCode"]] = event.payload;
    }
    this.trackEvent(pageName, omniData);
  }

  trackThankYouEvents(event: ThankYouEvents) {
    const { pageName, lob } = pageNameCategoryMap[event.pageName];
    const omniData = {
      [omnitureMap["pageName"]]: pageName,
      [omnitureMap["lob"]]: lob,
      [omnitureMap["ctaAction"]]: event.actionType,
    };
    this.trackEvent(pageName, omniData);
  }

  trackAddPassportEvents(event: AddPassportEvents) {
    const { pageName, lob } = pageNameCategoryMap["ADD_PASSPORT"];
    const omniData = {
      [omnitureMap["pageName"]]: pageName,
      [omnitureMap["lob"]]: lob,
      [omnitureMap["ctaAction"]]: event.actionType,
    };
    this.trackEvent(pageName, omniData);
  }

  trackOrderSummaryEvents(event: OrderSummaryEvents) {
    const { pageName, lob } = pageNameCategoryMap["ORDER_SUMMARY"];
    const omniData = {
      [omnitureMap["pageName"]]: pageName,
      [omnitureMap["lob"]]: lob,
      [omnitureMap["ctaAction"]]: event.actionType,
    };
    this.trackEvent(pageName, omniData);
  }

  trackOrderDetailsEvents(event: OrderDetailsEvents) {
    const { pageName, lob } = pageNameCategoryMap["ORDER_DETAILS"];
    const omniData = {
      [omnitureMap["pageName"]]: pageName,
      [omnitureMap["lob"]]: lob,
      [omnitureMap["ctaAction"]]: event.actionType,
    };
    this.trackEvent(pageName, omniData);
  }
}
