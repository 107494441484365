import {
  deleteUtmParamsFromCookie,
  getUTMParamsFromQuery,
  setUtmParamsInCookie,
} from "../../utils";

const useSetUTMParams = () => {
  function persistUTMParamsInCookie(shouldDeleteCookie = false) {
    const utmParams = getUTMParamsFromQuery();
    if (shouldDeleteCookie) {
      if (Object.keys(utmParams).length === 0) {
        deleteUtmParamsFromCookie();
        return;
      }
    }
    setUtmParamsInCookie(utmParams);
  }

  return {
    persistUTMParamsInCookie,
  };
};

export default useSetUTMParams;
