import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import {
  CommonModalDweb,
  FlexComp,
  LandingButton,
  AnchorTag,
  LandingInput,
  SpanTag,
  RadioButton,
} from "@tm/static";
import { theme } from "@tm/utility/global";

import { editInsuranceDetails } from "@tm/insurance/api";
import {
  getFormFieldErrorMsg,
  getPartnerOnClientSide,
  isDobValid,
} from "@tm/insurance/utils";
import { ERROR_CODES, RESPONSE_STATUS } from "@tm/insurance/constants";
import { ClientError } from "@tm/utility";

import { IEditTraveller, ITravellerData } from "./type";
import { GenderTypeItem } from "./EditTraveller.style";

const minDate = "1970-01-01";
const maxDate = new Date().toISOString().split("T")[0];

const EditTraveller = ({
  index,
  title,
  refId,
  fullName,
  dobValue,
  passportValue,
  genderValue,
  travellerDetails,
  travellerAttributes,
  goBackTxt,
  updateContactTxt,
  handleUpdateDetails,
  handleClose,
}: IEditTraveller) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editError, setEditError] = useState();

  const { name, dob, passport, gender } = travellerAttributes || {};

  const {
    control,
    formState: { errors },
    setValue,
    setError,
    handleSubmit,
  } = useForm<ITravellerData>({
    defaultValues: {
      name: fullName || "",
      dob: dobValue || "",
      passport: passportValue || "",
      gender: genderValue || "",
    },
    mode: "onTouched",
  });

  const handleGenderChange = (value?: string) => {
    if (value) {
      setValue("gender", value);
    }
  };

  const handleServerErrors = (error: any) => {
    const { displaySecondaryError = [] } = error?.validationError || {};
    displaySecondaryError.map((item: any) => {
      const { travellerId } = item;
      if (travellerId === refId) {
        const { name, dob, passport } = item?.displayError;
        if (name) {
          setError("name", {
            message: name,
          });
        }
        if (dob) {
          setError("dob", {
            message: dob,
          });
        }
        if (passport) {
          setError("passport", {
            message: passport,
          });
        }
      }
    });
  };

  const onSubmit: SubmitHandler<ITravellerData> = async (formData) => {
    try {
      const travellerData = travellerDetails.map((item, i) => {
        const { refId, name, dob, passport, gender } = item;
        return {
          refId,
          FullName: i === index ? formData.name?.trim() : name,
          Dob: i === index ? formData.dob : dob,
          Gender: i === index ? formData.gender : gender,
          PassportNo: i === index ? formData.passport : passport,
        };
      });

      const queryParams = new URLSearchParams(window.location.search);
      const bookingId = queryParams.get("bookingId");
      const partner = getPartnerOnClientSide();

      setIsLoading(true);

      const response = await editInsuranceDetails({
        partner,
        bookingID: bookingId,
        data: travellerData,
      });

      const { responseStatus } = response;
      if (responseStatus === RESPONSE_STATUS.SUCCESS) {
        await handleUpdateDetails(formData);
      } else {
        const { Error: error } = response;
        if (!error) {
          throw new Error("Something went wrong");
        }
        handleServerErrors(error);
      }
      setIsLoading(false);
    } catch (err: any) {
      console.log("ERROR IN UPDATING TRAVELLER DETAILS----", { err });
      setIsLoading(false);
      const { Error, error } = err?.response?.data;
      if (Error && Error?.code === ERROR_CODES.DISPLAY_ERROR) {
        handleServerErrors(Error);
      } else {
        setEditError(error);
      }
    }
  };

  if (editError && typeof window !== "undefined") {
    throw new ClientError(editError);
  }

  return (
    <CommonModalDweb customTitle={title} handleClick={handleClose}>
      {name?.isEnabled && (
        <Controller
          control={control}
          name={"name"}
          rules={{
            required: {
              value: name?.isRequired ?? true,
              message: getFormFieldErrorMsg("name"),
            },
            pattern: {
              value: new RegExp(name?.regex || ""),
              message: getFormFieldErrorMsg("name", name?.errorMessage),
            },
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <LandingInput
              requiredField={name?.isRequired}
              placeholderTxt={name?.placeholder}
              labelTxt={name?.title}
              inputValue={value}
              errorState={errors.name?.message}
              disableState={isLoading}
              handleChange={onChange}
              handleBlur={onBlur}
              inputWidth="100%"
              margin="0 0 16px"
            />
          )}
        />
      )}
      {dob?.isEnabled && (
        <Controller
          control={control}
          name={"dob"}
          rules={{
            required: {
              value: dob?.isRequired ?? true,
              message: getFormFieldErrorMsg("dob"),
            },
            validate: (value) =>
              isDobValid(value || "", dob?.regex) ||
              getFormFieldErrorMsg("dob", dob?.errorMessage),
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <LandingInput
              requiredField={dob?.isRequired}
              placeholderTxt={" "}
              labelTxt={dob?.title}
              inputType="date"
              inputValue={value}
              minValue={minDate}
              maxValue={maxDate}
              errorState={errors.dob?.message}
              disableState={isLoading}
              handleChange={onChange}
              handleBlur={onBlur}
              inputWidth="100%"
              margin="0 0 16px"
            />
          )}
        />
      )}
      {passport?.isEnabled && (
        <Controller
          control={control}
          name={"passport"}
          rules={{
            required: {
              value: passport?.isRequired ?? true,
              message: getFormFieldErrorMsg("passport"),
            },
            pattern: {
              value: new RegExp(passport?.regex || ""),
              message: getFormFieldErrorMsg("passport", passport?.errorMessage),
            },
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <LandingInput
              requiredField={passport?.isRequired}
              placeholderTxt={passport?.placeholder}
              labelTxt={passport?.title}
              inputValue={value}
              errorState={errors.passport?.message}
              disableState={isLoading}
              handleChange={onChange}
              handleBlur={onBlur}
              inputWidth="100%"
              margin="0 0 16px"
            />
          )}
        />
      )}
      {gender?.isEnabled && (
        <>
          <SpanTag
            margin="0 0 8px"
            fontSize="md"
            fontWeight="bold"
            color={theme.greyText}
          >
            Gender
          </SpanTag>
          <FlexComp gap="8px">
            <Controller
              control={control}
              name={"gender"}
              rules={{
                required: {
                  value: gender?.isRequired ?? true,
                  message: getFormFieldErrorMsg("gender"),
                },
              }}
              render={({ field: { value } }) => (
                <>
                  {gender?.values?.map((item, index) => (
                    <GenderTypeItem
                      selected={
                        item.label?.toLowerCase() === value?.toLowerCase()
                      }
                      key={item.id}
                      onClick={() => {
                        handleGenderChange(item.label);
                      }}
                    >
                      <RadioButton
                        activeBtn={
                          item.label?.toLowerCase() === value?.toLowerCase()
                        }
                      >
                        <SpanTag
                          fontSize="xs"
                          fontWeight="bold"
                          color={
                            item.label?.toLowerCase() === value?.toLowerCase()
                              ? theme.blueText
                              : theme.greyText
                          }
                        >
                          {item.label}
                        </SpanTag>
                      </RadioButton>
                    </GenderTypeItem>
                  ))}
                </>
              )}
            />
          </FlexComp>
        </>
      )}
      <FlexComp
        justifyContent="space-between"
        alignItems="center"
        margin="32px 0 0"
        gap="10px"
      >
        <LandingButton
          onClick={handleSubmit(onSubmit)}
          customWidth="210px"
          customTitle={updateContactTxt}
          isLoading={isLoading}
        />
        <AnchorTag
          handleClick={handleClose}
          fontSize="md"
          fontWeight="bold"
          textTransform="uppercase"
        >
          {goBackTxt}
        </AnchorTag>
      </FlexComp>
    </CommonModalDweb>
  );
};

export default EditTraveller;
