import { CurrencySEOMarkdown } from "../CurrencySEOMarkdown";

export default function ConvertOnline({ currencyData }: any) {
  return (
    <>
      <h2 className="h2">{currencyData?.convert_curr_to_inr_online?.title}</h2>
      <CurrencySEOMarkdown className="description mt20">
        {currencyData?.convert_curr_to_inr_online?.para}
      </CurrencySEOMarkdown>
    </>
  );
}
