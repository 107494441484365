import styled from "styled-components";

export const PaymentStatusCompStyle = styled.div`
  background: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Confetti = styled.div`
  width: 278px;
  height: 185px;
  animation: confetti 0.5s ease-in;

  @keyframes confetti {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
`;
