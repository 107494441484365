//@ts-ignore
import UAParser from "ua-parser-js";
import { isMobile, isOnServer } from "../..";
import { getCookie } from "../../cookieUtils";

export function getDeviceSpecificInfo() {
  const ua = !isOnServer() ? navigator.userAgent : null;
  const parser = new UAParser().setUA(ua).getResult();
  return {
    os: (parser && parser?.os?.name) || "",
    type: !isMobile() ? "Desktop" : (parser && parser?.device?.type) || "",
    os_version:
      parser && parser.os && parser.os.version ? parser.os.version : "",
    user_agent: ua || "",
  };
}
export function getUtmPdtObj() {
  return {
    funnel_medium:
      getCookie(UTM_PARAMS_KEY.UTM_MEDIUM, false, { path: "/" }) || null,
    funnel_source:
      getCookie(UTM_PARAMS_KEY.UTM_SOURCE, false, { path: "/" }) || null,
    funnel_campaign:
      getCookie(UTM_PARAMS_KEY.UTM_CAMPAIGN, false, { path: "/" }) || null,
  };
}

export const UTM_PARAMS_KEY = {
  UTM_SOURCE: "utm_source",
  UTM_MEDIUM: "utm_medium",
  UTM_CAMPAIGN: "utm_campaign",
  UTM_CONTENT: "utm_content",
};
