import React, { useState } from "react";
import {
  LandingHeaderWhiteStyle,
  LandingHeaderWrap,
  TmLogo,
  LandingHeaderList,
  ProductLinks,
  LandingHeaderBtn,
  Flex,
  LandingHeaderItem,
  ImageIcon,
} from "./LandingHeader.style";
import { TripMoneyLogo } from "@tm/static/icons";
import BlackChev from "../../icons/blackChev.svg";
import { SpanTag, AnchorTag, ImageComponent } from "@tm/static";
import { theme } from "@tm/utility/global";

const productLinks = [
  "Foreign Currency",
  "Forex Cards",
  "Travel Insurance",
  "Travel Loan",
  "Foreign Money Trasfer",
];

interface LandingHeaderProps {
  forexHeader?: boolean;
  loginState?: boolean;
  logoUrl?: string;
}

const LandingHeaderWhite = ({
  forexHeader,
  loginState,
  logoUrl,
}: LandingHeaderProps) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <LandingHeaderWhiteStyle id="header-id">
      <LandingHeaderWrap>
        <AnchorTag>
          <TripMoneyLogo darkHeader />
        </AnchorTag>

        {!loginState && (
          <React.Fragment>
            <LandingHeaderList>
              <LandingHeaderItem
                opened={show}
                closed={!show}
                onClick={handleClick}
              >
                <AnchorTag hrefProp="#" aria-label="products">
                  Products
                </AnchorTag>
                {show && (
                  <ProductLinks>
                    {productLinks &&
                      productLinks.map((item, index) => {
                        return (
                          <AnchorTag key={index} hrefProp="#">
                            {item}
                          </AnchorTag>
                        );
                      })}
                  </ProductLinks>
                )}
              </LandingHeaderItem>
              <LandingHeaderItem>
                <AnchorTag hrefProp="#">Blogs</AnchorTag>
              </LandingHeaderItem>
              <LandingHeaderItem>
                <AnchorTag hrefProp="#">FAQ’s</AnchorTag>
              </LandingHeaderItem>
              <LandingHeaderItem>
                <AnchorTag hrefProp="#">About Us</AnchorTag>
              </LandingHeaderItem>
            </LandingHeaderList>
            <LandingHeaderBtn>LOGIN</LandingHeaderBtn>
          </React.Fragment>
        )}
        {loginState && (
          <Flex>
            <ImageIcon>C</ImageIcon>
            <SpanTag fontSize="xxs" color={theme.blackText} fontWeight="bold">
              Hey Chavi
            </SpanTag>
            <ImageComponent
              width="14px"
              height="14px"
              margin="0 0 0 8px"
              src="https://tripmoneycmsimgak.mmtcdn.com/img/black_Chev_30adf1ebb1.svg"
            />
          </Flex>
        )}
      </LandingHeaderWrap>
    </LandingHeaderWhiteStyle>
  );
};

export default LandingHeaderWhite;
