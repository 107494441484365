interface SafetyIconProps {}

const SafetyIcon = (props: SafetyIconProps) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={10} cy={10} r={8} fill="white" />
    <path
      d="M13.6887 11.7899C13.4135 12.5669 12.9972 13.2425 12.4514 13.7978C11.8301 14.4298 11.0165 14.932 10.0332 15.2902C10.0009 15.3019 9.96724 15.3115 9.93342 15.3186C9.88873 15.3278 9.84326 15.3328 9.79818 15.3334H9.78935C9.74131 15.3334 9.69302 15.3284 9.64513 15.3186C9.61131 15.3115 9.5781 15.3019 9.54599 15.2904C8.56154 14.9328 7.74693 14.4308 7.12498 13.7988C6.57888 13.2436 6.16271 12.5684 5.88787 11.7913C5.3881 10.3786 5.41654 8.82226 5.43943 7.57161L5.43982 7.5524C5.44443 7.44913 5.4474 7.34065 5.44904 7.22086C5.4574 6.63273 5.90631 6.14615 6.47099 6.11336C7.64834 6.04492 8.55912 5.64493 9.33732 4.85481L9.34412 4.8483C9.47334 4.72485 9.63615 4.6643 9.79818 4.66682C9.95443 4.66894 10.1101 4.72941 10.2347 4.8483L10.2413 4.85481C11.0197 5.64493 11.9304 6.04492 13.1078 6.11336C13.6725 6.14615 14.1214 6.63273 14.1297 7.22086C14.1314 7.34147 14.1344 7.44978 14.139 7.5524L14.1392 7.56054C14.162 8.81355 14.1903 10.373 13.6887 11.7899Z"
      fill="#2B825E"
    />
    <path
      d="M12.4773 8.96207L10.122 11.4159L9.61301 11.9461C9.49277 12.0713 9.33507 12.1339 9.17754 12.1339C9.01984 12.1339 8.8623 12.0713 8.74191 11.9461L7.64764 10.8057C7.40717 10.5552 7.40717 10.1495 7.64764 9.89881C7.88778 9.64833 8.27778 9.64833 8.51825 9.89881L9.17754 10.5856L11.6067 8.05518C11.8472 7.80452 12.2372 7.80452 12.4773 8.05518C12.7178 8.30567 12.7178 8.71192 12.4773 8.96207Z"
      fill="white"
    />
  </svg>
);
export { SafetyIcon };
