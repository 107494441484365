import React from "react";
import { ToolTipCompWrapper } from "./ToolTipComp.style";

interface ToolTipType {
  message?: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  width?: string;
  toolTipArrow?: string;
  children?: React.ReactNode;
  handleMouseLeave?: () => void;
  handleMouseEnter?: () => void;
}

const ToolTipComp = ({
  message,
  top,
  left,
  right,
  bottom,
  width,
  toolTipArrow,
  children,
  handleMouseEnter,
  handleMouseLeave,
}: ToolTipType) => {
  return (
    <ToolTipCompWrapper
      width={width}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      toolTipArrow={toolTipArrow}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {/* <WhiteCross cursor={true} /> */}
    </ToolTipCompWrapper>
  );
};

export default ToolTipComp;
