import * as React from "react";
interface iconprop {}
const LuggageImg = (props: iconprop) => (
  <svg
    width={94}
    height={105}
    viewBox="0 0 94 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.170046 78.9453C3.02139 74.5071 91.8243 75.5756 93.0465 78.9453C93.0465 77.3837 93.0465 66.6436 93.0465 65.6573C91.417 61.4657 4.6508 60.8081 0.170046 65.6573C0.170046 67.5477 0.169767 77.2193 0.170046 78.9453Z"
      fill="#E0E0E0"
    />
    <path
      d="M6.8916 43.0586C6.8916 42.6983 7.15953 42.4062 7.49003 42.4062H8.68688C9.01738 42.4062 9.2853 42.6983 9.2853 43.0586V45.8857C9.2853 46.246 9.01738 46.5381 8.68688 46.5381H7.49003C7.15953 46.5381 6.8916 46.246 6.8916 45.8857V43.0586Z"
      fill="#1E1E1E"
    />
    <rect
      x={20.6963}
      y={11.5234}
      width={1.19585}
      height={20.6593}
      fill="#ADADAD"
    />
    <rect
      x={36.7559}
      y={11.5234}
      width={1.19585}
      height={20.6593}
      fill="#ADADAD"
    />
    <path
      d="M20.1892 33.9219C19.8587 33.9219 19.5908 33.6298 19.5908 33.2695V31.9647C19.5908 31.6044 19.8587 31.3123 20.1892 31.3123H22.5829C22.9134 31.3123 23.1814 31.6044 23.1814 31.9647V33.2695C23.1814 33.6298 22.9134 33.9219 22.5829 33.9219H20.1892Z"
      fill="#1E1E1E"
    />
    <path
      d="M35.8582 33.9219C35.5277 33.9219 35.2598 33.6298 35.2598 33.2695V31.9647C35.2598 31.6044 35.5277 31.3123 35.8582 31.3123H38.2519C38.5824 31.3123 38.8503 31.6044 38.8503 31.9647V33.2695C38.8503 33.6298 38.5824 33.9219 38.2519 33.9219H35.8582Z"
      fill="#1E1E1E"
    />
    <path
      d="M48.3198 56.9748C48.3198 55.8938 49.1236 55.0176 50.1151 55.0176H50.913C51.9045 55.0176 52.7083 55.8938 52.7083 56.9748V72.6323C52.7083 73.7132 51.9045 74.5895 50.913 74.5895H50.1151C49.1236 74.5895 48.3198 73.7132 48.3198 72.6323V56.9748Z"
      fill="#1E1E1E"
    />
    <path
      d="M6.8916 82.2012C6.8916 81.8409 7.15953 81.5488 7.49003 81.5488H8.68688C9.01738 81.5488 9.2853 81.8409 9.2853 82.2012V85.0283C9.2853 85.3886 9.01738 85.6807 8.68688 85.6807H7.49003C7.15953 85.6807 6.8916 85.3886 6.8916 85.0283V82.2012Z"
      fill="#1E1E1E"
    />
    <mask
      id="mask0_841_15159"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={7}
      y={32}
      width={45}
      height={66}
    >
      <path
        d="M7.62842 39.1425C7.62842 35.2992 10.4863 32.1836 14.0116 32.1836H45.1297C48.6551 32.1836 51.513 35.2992 51.513 39.1425V90.4645C51.513 94.3078 48.6551 97.4234 45.1297 97.4234H14.0116C10.4863 97.4234 7.62842 94.3078 7.62842 90.4645V39.1425Z"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_841_15159)">
      <path
        d="M7.62842 39.1425C7.62842 35.2992 10.4863 32.1836 14.0116 32.1836H45.1297C48.6551 32.1836 51.513 35.2992 51.513 39.1425V90.4645C51.513 94.3078 48.6551 97.4234 45.1297 97.4234H14.0116C10.4863 97.4234 7.62842 94.3078 7.62842 90.4645V39.1425Z"
        fill="#925454"
      />
      <path
        d="M7.62842 39.1425C7.62842 35.2992 10.4863 32.1836 14.0116 32.1836H45.1297C48.6551 32.1836 51.513 35.2992 51.513 39.1425V90.4645C51.513 94.3078 48.6551 97.4234 45.1297 97.4234H14.0116C10.4863 97.4234 7.62842 94.3078 7.62842 90.4645V39.1425Z"
        fill="url(#paint0_linear_841_15159)"
        fillOpacity={0.8}
      />
      <path
        d="M7.62842 39.1425C7.62842 35.2992 10.4863 32.1836 14.0116 32.1836H45.1297C48.6551 32.1836 51.513 35.2992 51.513 39.1425V90.4645C51.513 94.3078 48.6551 97.4234 45.1297 97.4234H14.0116C10.4863 97.4234 7.62842 94.3078 7.62842 90.4645V39.1425Z"
        fill="url(#paint1_linear_841_15159)"
        fillOpacity={0.2}
      />
      <path
        d="M7.62842 39.1425C7.62842 35.2992 10.4863 32.1836 14.0116 32.1836H45.1297C48.6551 32.1836 51.513 35.2992 51.513 39.1425V90.4645C51.513 94.3078 48.6551 97.4234 45.1297 97.4234H14.0116C10.4863 97.4234 7.62842 94.3078 7.62842 90.4645V39.1425Z"
        fill="url(#paint2_linear_841_15159)"
        fillOpacity={0.4}
      />
      <path
        d="M7.62842 91.9883H11.6179C14.8128 91.9883 17.4027 94.8118 17.4027 98.2948V100.687H7.62842V91.9883Z"
        fill="#1E1E1E"
      />
      <path
        d="M7.62842 91.9883H11.6179C14.8128 91.9883 17.4027 94.8118 17.4027 98.2948V100.687H7.62842V91.9883Z"
        fill="url(#paint3_linear_841_15159)"
        fillOpacity={0.2}
      />
      <path
        d="M51.6807 91.9883H47.6912C44.4963 91.9883 41.9064 94.8118 41.9064 98.2948V100.687H51.6807V91.9883Z"
        fill="#1E1E1E"
      />
      <path
        d="M51.6807 91.9883H47.6912C44.4963 91.9883 41.9064 94.8118 41.9064 98.2948V100.687H51.6807V91.9883Z"
        fill="url(#paint4_linear_841_15159)"
        fillOpacity={0.2}
      />
    </g>
    <ellipse
      cx={14.6263}
      cy={100.358}
      rx={2.89239}
      ry={3.15326}
      fill="#1E1E1E"
    />
    <ellipse
      cx={14.5427}
      cy={100.358}
      rx={1.69554}
      ry={1.84846}
      fill="#D9D9D9"
    />
    <ellipse
      cx={43.7464}
      cy={100.358}
      rx={2.89239}
      ry={3.15326}
      fill="#1E1E1E"
    />
    <ellipse
      cx={43.6628}
      cy={100.358}
      rx={1.69554}
      ry={1.84846}
      fill="#D9D9D9"
    />
    <g filter="url(#filter0_i_841_15159)">
      <path
        d="M13.2446 39.3594C13.2446 38.9991 13.5123 38.707 13.8426 38.707C14.1728 38.707 14.4405 38.9991 14.4405 39.3594V89.5941C14.4405 89.9544 14.1728 90.2465 13.8426 90.2465C13.5123 90.2465 13.2446 89.9544 13.2446 89.5941V39.3594Z"
        fill="black"
        fillOpacity={0.05}
      />
    </g>
    <g filter="url(#filter1_i_841_15159)">
      <path
        d="M21.0791 39.3594C21.0791 38.9991 21.3468 38.707 21.677 38.707C22.0073 38.707 22.275 38.9991 22.275 39.3594V89.5941C22.275 89.9544 22.0073 90.2465 21.677 90.2465C21.3468 90.2465 21.0791 89.9544 21.0791 89.5941V39.3594Z"
        fill="black"
        fillOpacity={0.05}
      />
    </g>
    <g filter="url(#filter2_i_841_15159)">
      <path
        d="M29.2974 39.3594C29.2974 38.9991 29.5651 38.707 29.8953 38.707C30.2255 38.707 30.4932 38.9991 30.4932 39.3594V89.5941C30.4932 89.9544 30.2255 90.2465 29.8953 90.2465C29.5651 90.2465 29.2974 89.9544 29.2974 89.5941V39.3594Z"
        fill="black"
        fillOpacity={0.05}
      />
    </g>
    <g filter="url(#filter3_i_841_15159)">
      <path
        d="M45.3423 39.3594C45.3423 38.9991 45.61 38.707 45.9402 38.707C46.2704 38.707 46.5381 38.9991 46.5381 39.3594V89.5941C46.5381 89.9544 46.2704 90.2465 45.9402 90.2465C45.61 90.2465 45.3423 89.9544 45.3423 89.5941V39.3594Z"
        fill="black"
        fillOpacity={0.05}
      />
    </g>
    <g filter="url(#filter4_i_841_15159)">
      <path
        d="M37.1318 39.3594C37.1318 38.9991 37.3995 38.707 37.7298 38.707C38.06 38.707 38.3277 38.9991 38.3277 39.3594V89.5941C38.3277 89.9544 38.06 90.2465 37.7298 90.2465C37.3995 90.2465 37.1318 89.9544 37.1318 89.5941V39.3594Z"
        fill="black"
        fillOpacity={0.05}
      />
    </g>
    <path
      d="M18.8389 11.9593C18.8389 10.1577 20.2993 8.69727 22.1009 8.69727H36.1228C37.9244 8.69727 39.3848 10.1577 39.3848 11.9593H35.4452C35.4452 11.9593 31.705 11.5243 29.1118 11.5243C26.5187 11.5243 22.7785 11.9593 22.7785 11.9593H18.8389Z"
      fill="#D9D9D9"
    />
    <path
      d="M22.561 11.957V11.3043C22.561 10.9438 22.829 10.6516 23.1595 10.6516H34.5295C34.86 10.6516 35.128 10.9438 35.128 11.3043V11.957C35.128 11.957 32.0361 11.5219 28.8445 11.5219C25.6529 11.5219 22.561 11.957 22.561 11.957Z"
      fill="#1E1E1E"
    />
    <rect
      x={65.4854}
      y={2.82617}
      width={1.19585}
      height={13.7004}
      fill="#ADADAD"
    />
    <path
      d="M64.3726 1.95719C64.3726 0.876266 65.1763 0 66.1678 0Lnan nanL66.1678 0C67.1593 0 67.9631 0.876266 67.9631 1.95719V4.11422C67.9631 4.24406 67.8666 4.34932 67.7475 4.34932H64.5882C64.4691 4.34932 64.3726 4.24406 64.3726 4.11422V1.95719Z"
      fill="#D9D9D9"
    />
    <path
      d="M64.971 18.2676C64.6405 18.2676 64.3726 17.9755 64.3726 17.6152V16.3104C64.3726 15.9501 64.6405 15.658 64.971 15.658H67.3647C67.6952 15.658 67.9631 15.9501 67.9631 16.3104V17.6152C67.9631 17.9755 67.6952 18.2676 67.3647 18.2676H64.971Z"
      fill="#1E1E1E"
    />
    <mask
      id="mask1_841_15159"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={57}
      y={16}
      width={27}
      height={82}
    >
      <path
        d="M57.2822 23.2649C57.2822 19.5417 60.0508 16.5234 63.466 16.5234H77.6287C81.0439 16.5234 83.8124 19.5417 83.8124 23.2649V90.4619C83.8124 94.1851 81.0439 97.2033 77.6287 97.2033H63.466C60.0508 97.2033 57.2822 94.1851 57.2822 90.4619V23.2649Z"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask1_841_15159)">
      <path
        d="M57.2822 16.5234H83.8124V97.2033H57.2822V16.5234Z"
        fill="#444444"
      />
      <path
        d="M57.2822 16.5234H83.8124V97.2033H57.2822V16.5234Z"
        fill="url(#paint5_linear_841_15159)"
        fillOpacity={0.6}
      />
      <path
        d="M57.2822 16.5234H83.8124V97.2033H57.2822V16.5234Z"
        fill="url(#paint6_linear_841_15159)"
        fillOpacity={0.4}
      />
      <g filter="url(#filter5_i_841_15159)">
        <path
          d="M60.4895 25.4395C60.159 25.4395 59.8911 25.1472 59.8911 24.7867C59.8911 24.4262 60.159 24.134 60.4895 24.134H80.2376C80.5681 24.134 80.836 24.4262 80.836 24.7867C80.836 25.1472 80.5681 25.4395 80.2376 25.4395H60.4895Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <g filter="url(#filter6_i_841_15159)">
        <path
          d="M60.4895 34.1406C60.159 34.1406 59.8911 33.8484 59.8911 33.4879C59.8911 33.1274 60.159 32.8352 60.4895 32.8352H80.2376C80.5681 32.8352 80.836 33.1274 80.836 33.4879C80.836 33.8484 80.5681 34.1406 80.2376 34.1406H60.4895Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <g filter="url(#filter7_i_841_15159)">
        <path
          d="M60.4895 42.8359C60.159 42.8359 59.8911 42.5437 59.8911 42.1832C59.8911 41.8227 60.159 41.5305 60.4895 41.5305H80.2376C80.5681 41.5305 80.836 41.8227 80.836 42.1832C80.836 42.5437 80.5681 42.8359 80.2376 42.8359H60.4895Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <g filter="url(#filter8_i_841_15159)">
        <path
          d="M60.4895 51.5352C60.159 51.5352 59.8911 51.2429 59.8911 50.8824C59.8911 50.5219 60.159 50.2297 60.4895 50.2297H80.2376C80.5681 50.2297 80.836 50.5219 80.836 50.8824C80.836 51.2429 80.5681 51.5352 80.2376 51.5352H60.4895Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <g filter="url(#filter9_i_841_15159)">
        <path
          d="M60.4895 60.2363C60.159 60.2363 59.8911 59.9441 59.8911 59.5836C59.8911 59.2231 60.159 58.9309 60.4895 58.9309H80.2376C80.5681 58.9309 80.836 59.2231 80.836 59.5836C80.836 59.9441 80.5681 60.2363 80.2376 60.2363H60.4895Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <g filter="url(#filter10_i_841_15159)">
        <path
          d="M60.4895 68.9316C60.159 68.9316 59.8911 68.6394 59.8911 68.2789C59.8911 67.9184 60.159 67.6262 60.4895 67.6262H80.2376C80.5681 67.6262 80.836 67.9184 80.836 68.2789C80.836 68.6394 80.5681 68.9316 80.2376 68.9316H60.4895Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <g filter="url(#filter11_i_841_15159)">
        <path
          d="M60.4895 77.6309C60.159 77.6309 59.8911 77.3386 59.8911 76.9781C59.8911 76.6176 60.159 76.3254 60.4895 76.3254H80.2376C80.5681 76.3254 80.836 76.6176 80.836 76.9781C80.836 77.3386 80.5681 77.6309 80.2376 77.6309H60.4895Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <path
        d="M64.3799 48.4923C64.3799 47.7717 64.9157 47.1875 65.5767 47.1875C66.2377 47.1875 66.7736 47.7717 66.7736 48.4923V65.4546C66.7736 66.1753 66.2377 66.7594 65.5767 66.7594C64.9157 66.7594 64.3799 66.1753 64.3799 65.4546V48.4923Z"
        fill="#1E1E1E"
      />
      <path
        d="M64.3799 48.4923C64.3799 47.7717 64.9157 47.1875 65.5767 47.1875C66.2377 47.1875 66.7736 47.7717 66.7736 48.4923V65.4546C66.7736 66.1753 66.2377 66.7594 65.5767 66.7594C64.9157 66.7594 64.3799 66.1753 64.3799 65.4546V48.4923Z"
        fill="url(#paint7_linear_841_15159)"
        fillOpacity={0.2}
      />
      <g filter="url(#filter12_i_841_15159)">
        <path
          d="M60.4895 86.332C60.159 86.332 59.8911 86.0398 59.8911 85.6793C59.8911 85.3188 60.159 85.0266 60.4895 85.0266H80.2376C80.5681 85.0266 80.836 85.3188 80.836 85.6793C80.836 86.0398 80.5681 86.332 80.2376 86.332H60.4895Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <path
        d="M56.1694 91.7656H60.1589C63.3538 91.7656 65.9437 94.5891 65.9437 98.0721V100.464H56.1694V91.7656Z"
        fill="#1E1E1E"
      />
      <path
        d="M56.1694 91.7656H60.1589C63.3538 91.7656 65.9437 94.5891 65.9437 98.0721V100.464H56.1694V91.7656Z"
        fill="url(#paint8_linear_841_15159)"
        fillOpacity={0.2}
      />
      <path
        d="M85.2749 91.7656H81.2854C78.0906 91.7656 75.5006 94.5891 75.5006 98.0721V100.464H85.2749V91.7656Z"
        fill="#1E1E1E"
      />
      <path
        d="M85.2749 91.7656H81.2854C78.0906 91.7656 75.5006 94.5891 75.5006 98.0721V100.464H85.2749V91.7656Z"
        fill="url(#paint9_linear_841_15159)"
        fillOpacity={0.2}
      />
    </g>
    <path
      d="M61.373 97.9662C61.373 97.5458 61.6856 97.2051 62.0712 97.2051C62.4568 97.2051 62.7694 97.5458 62.7694 97.9662V102.75C62.7694 103.171 62.4568 103.512 62.0712 103.512C61.6856 103.512 61.373 103.171 61.373 102.75V97.9662Z"
      fill="#1E1E1E"
    />
    <path
      d="M63.2305 97.9662C63.2305 97.5458 63.543 97.2051 63.9286 97.2051C64.3142 97.2051 64.6268 97.5458 64.6268 97.9662V102.75C64.6268 103.171 64.3142 103.512 63.9286 103.512C63.543 103.512 63.2305 103.171 63.2305 102.75V97.9662Z"
      fill="#1E1E1E"
    />
    <path
      d="M62.8765 98.7272C62.8765 98.6071 62.9657 98.5098 63.0758 98.5098C63.1859 98.5098 63.2751 98.6071 63.2751 98.7272V101.989C63.2751 102.109 63.1859 102.207 63.0758 102.207C62.9657 102.207 62.8765 102.109 62.8765 101.989V98.7272Z"
      fill="#D9D9D9"
    />
    <path
      d="M76.6885 97.9662C76.6885 97.5458 77.0011 97.2051 77.3866 97.2051C77.7722 97.2051 78.0848 97.5458 78.0848 97.9662V102.75C78.0848 103.171 77.7722 103.512 77.3866 103.512C77.0011 103.512 76.6885 103.171 76.6885 102.75V97.9662Z"
      fill="#1E1E1E"
    />
    <path
      d="M78.5459 97.9662C78.5459 97.5458 78.8585 97.2051 79.2441 97.2051C79.6296 97.2051 79.9422 97.5458 79.9422 97.9662V102.75C79.9422 103.171 79.6296 103.512 79.2441 103.512C78.8585 103.512 78.5459 103.171 78.5459 102.75V97.9662Z"
      fill="#1E1E1E"
    />
    <path
      d="M78.1919 98.7272C78.1919 98.6071 78.2811 98.5098 78.3912 98.5098C78.5013 98.5098 78.5905 98.6071 78.5905 98.7272V101.989C78.5905 102.109 78.5013 102.207 78.3912 102.207C78.2811 102.207 78.1919 102.109 78.1919 101.989V98.7272Z"
      fill="#D9D9D9"
    />
    <rect
      x={69.9741}
      y={16.5254}
      width={1.62941}
      height={80.5331}
      fill="#313131"
    />
    <rect
      x={70.3496}
      y={16.5254}
      width={0.597467}
      height={80.6799}
      fill="#1E1E1E"
    />
    <line
      x1={70.8482}
      y1={16.6626}
      x2={70.8482}
      y2={96.9212}
      stroke="#616161"
      strokeWidth={0.274477}
      strokeLinecap="round"
      strokeDasharray="0.55 0.55"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.1671 21.3191C69.9571 21.2358 69.7279 21.3348 69.6456 21.5445L68.5976 24.213C68.4778 24.5181 68.6337 24.8759 68.9392 24.9971C69.2447 25.1183 69.575 24.9535 69.6631 24.6358L70.4337 21.8572C70.4942 21.6389 70.3771 21.4024 70.1671 21.3191ZM68.9549 24.7949C69.1618 24.877 69.3907 24.7605 69.466 24.5347C69.5414 24.3089 69.4348 24.0593 69.2279 23.9772C69.021 23.8951 68.7921 24.0116 68.7168 24.2374C68.6414 24.4632 68.748 24.7128 68.9549 24.7949Z"
      fill="#1E1E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.1671 23.2761C69.9571 23.1928 69.7279 23.2919 69.6456 23.5015L68.5976 26.1701C68.4778 26.4751 68.6337 26.8329 68.9392 26.9541C69.2447 27.0753 69.575 26.9105 69.6631 26.5929L70.4337 23.8142C70.4942 23.5959 70.3771 23.3594 70.1671 23.2761ZM68.9549 26.7519C69.1618 26.834 69.3907 26.7175 69.466 26.4917C69.5414 26.2659 69.4348 26.0163 69.2279 25.9342C69.021 25.8521 68.7921 25.9686 68.7168 26.1944C68.6414 26.4202 68.748 26.6698 68.9549 26.7519Z"
      fill="#1E1E1E"
    />
    <g filter="url(#filter13_b_841_15159)">
      <path
        d="M0.170046 65.5781C3.02139 71.7234 91.8243 70.2439 93.0465 65.5781C93.0465 67.7403 93.0465 79.2075 93.0465 80.5731C91.417 86.377 4.6508 87.2874 0.170046 80.5731C0.170046 77.9557 0.169767 67.9679 0.170046 65.5781Z"
        fill="white"
        fillOpacity={0.9}
      />
    </g>
    <g filter="url(#filter14_b_841_15159)">
      <ellipse
        cx={46.5238}
        cy={74.6557}
        rx={27.7}
        ry={30.0893}
        fill="white"
        fillOpacity={0.9}
      />
    </g>
    <path
      d="M35.6133 62.1446C35.6133 62.1446 43.0182 63.9321 46.8579 59.3145C46.8579 64.9748 46.8579 86.5735 46.8579 90.1485C38.2187 87.7652 35.6131 79.5726 35.6133 76.4445C35.6135 73.3164 35.6133 62.1446 35.6133 62.1446Z"
      fill="url(#paint10_linear_841_15159)"
    />
    <path
      d="M58.0112 62.1446C58.0112 62.1446 50.6063 63.9321 46.7667 59.3145C46.7667 64.9748 46.7667 86.5735 46.7667 90.1485C55.4058 87.7652 58.0115 79.5726 58.0112 76.4445C58.011 73.3164 58.0112 62.1446 58.0112 62.1446Z"
      fill="url(#paint11_linear_841_15159)"
    />
    <path
      d="M58.0112 62.1446C58.0112 62.1446 50.6063 63.9321 46.7667 59.3145C46.7667 64.9748 46.7667 86.5735 46.7667 90.1485C55.4058 87.7652 58.0115 79.5726 58.0112 76.4445C58.011 73.3164 58.0112 62.1446 58.0112 62.1446Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M42.4379 71.9122L41.2884 73.1398L41.2884 73.1396C41.2366 73.195 41.2075 73.2698 41.2075 73.348C41.2075 73.4261 41.2366 73.5011 41.2884 73.5563L45.5878 78.1481C45.6956 78.263 45.8702 78.263 45.9781 78.1481L52.6455 71.0275C52.7533 70.9125 52.7533 70.7258 52.6455 70.6109L51.4962 69.3832C51.4445 69.3279 51.3743 69.2969 51.3012 69.2969C51.2279 69.2969 51.1579 69.3279 51.1061 69.3832L45.7829 75.0682L42.828 71.9122C42.7202 71.7973 42.5455 71.7973 42.4379 71.9122Z"
      fill="#95F47D"
    />
    <defs>
      <filter
        id="filter0_i_841_15159"
        x={13.2446}
        y={38.707}
        width={1.1958}
        height={52.9114}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter1_i_841_15159"
        x={21.0791}
        y={38.707}
        width={1.1958}
        height={52.9114}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter2_i_841_15159"
        x={29.2974}
        y={38.707}
        width={1.1958}
        height={52.9114}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter3_i_841_15159"
        x={45.3423}
        y={38.707}
        width={1.1958}
        height={52.9114}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter4_i_841_15159"
        x={37.1318}
        y={38.707}
        width={1.1958}
        height={52.9114}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter5_i_841_15159"
        x={59.8911}
        y={24.1348}
        width={20.9448}
        height={2.67707}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter6_i_841_15159"
        x={59.8911}
        y={32.8359}
        width={20.9448}
        height={2.67707}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter7_i_841_15159"
        x={59.8911}
        y={41.5312}
        width={20.9448}
        height={2.67707}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter8_i_841_15159"
        x={59.8911}
        y={50.2305}
        width={20.9448}
        height={2.67707}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter9_i_841_15159"
        x={59.8911}
        y={58.9316}
        width={20.9448}
        height={2.67707}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter10_i_841_15159"
        x={59.8911}
        y={67.627}
        width={20.9448}
        height={2.67707}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter11_i_841_15159"
        x={59.8911}
        y={76.3262}
        width={20.9448}
        height={2.67707}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter12_i_841_15159"
        x={59.8911}
        y={85.0273}
        width={20.9448}
        height={2.67707}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.37239} />
        <feGaussianBlur stdDeviation={1.37239} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_15159"
        />
      </filter>
      <filter
        id="filter13_b_841_15159"
        x={-10.8092}
        y={54.599}
        width={114.835}
        height={41.6535}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={5.48955} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_841_15159"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_841_15159"
          result="shape"
        />
      </filter>
      <filter
        id="filter14_b_841_15159"
        x={13.3342}
        y={39.0769}
        width={66.379}
        height={71.1568}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2.74477} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_841_15159"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_841_15159"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_841_15159"
        x1={7.84641}
        y1={44.1206}
        x2={51.0265}
        y2={44.4957}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CD4848" />
        <stop offset={0.0414636} stopColor="#A22626" />
        <stop offset={0.957069} stopColor="#992222" />
        <stop offset={1} stopColor="#CD4848" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_15159"
        x1={11.9199}
        y1={33.0584}
        x2={43.1464}
        y2={95.7009}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.131169} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_841_15159"
        x1={50.2112}
        y1={34.8284}
        x2={7.42212}
        y2={93.4964}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.363327} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint3_linear_841_15159"
        x1={12.5156}
        y1={91.9883}
        x2={12.5156}
        y2={100.687}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.184356} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint4_linear_841_15159"
        x1={46.7935}
        y1={91.9883}
        x2={46.7935}
        y2={100.687}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.184356} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint5_linear_841_15159"
        x1={56.9274}
        y1={46.7729}
        x2={83.8127}
        y2={46.7729}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#888888" />
        <stop offset={0.090846} stopColor="#444444" />
        <stop offset={0.935773} stopColor="#444444" />
        <stop offset={1} stopColor="#888888" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_841_15159"
        x1={82.5907}
        y1={18.8961}
        x2={54.3207}
        y2={92.2002}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.218513} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint7_linear_841_15159"
        x1={65.5767}
        y1={47.1875}
        x2={65.5767}
        y2={66.7594}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.184356} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint8_linear_841_15159"
        x1={61.0566}
        y1={91.7656}
        x2={61.0566}
        y2={100.464}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.184356} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint9_linear_841_15159"
        x1={80.3878}
        y1={91.7656}
        x2={80.3878}
        y2={100.464}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.184356} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint10_linear_841_15159"
        x1={35.8243}
        y1={72.6207}
        x2={46.2115}
        y2={72.6231}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopColor="#181E1F" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_841_15159"
        x1={57.8002}
        y1={72.6207}
        x2={47.413}
        y2={72.6231}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopColor="#253033" />
      </linearGradient>
    </defs>
  </svg>
);
export { LuggageImg };
