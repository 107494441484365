import { getDeviceType } from "../common";

export const useBreakpointV2 = () => {
  const device = getDeviceType();
  switch (device) {
    case "mobile":
      return "sm";
    case "desktop":
      return "lg";
    case "tablet":
      return "md";
    default:
      return "md";
  }
};
