import { CurrencySEOMarkdown } from "../CurrencySEOMarkdown";

export default function CurrencyInformation({ currencyData }: any) {
  if (!currencyData?.curr_information) {
    return null;
  }
  return (
    <>
      <div className="separator"></div>
      <h2 className="h2">{currencyData?.curr_information?.title}</h2>
      <h3 className="h3 mt10">{currencyData?.curr_information?.sub_title}</h3>
      <CurrencySEOMarkdown className="description mt10">
        {currencyData?.curr_information?.desc}
      </CurrencySEOMarkdown>
      <div className="exploreBtnWrap">
        <div
          className="exploreBtn"
          onClick={() => {
            window.open(currencyData?.curr_information?.link, "_self");
          }}
        >
          <p className="exploreBtn_text">Explore</p>
        </div>
      </div>
    </>
  );
}
