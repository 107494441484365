export default function PopularDenomitations({
  currencyData,
  inrExchageRate,
}: any) {
  return (
    <>
      <div className="separator"></div>
      <h2 className="h2">{currencyData?.popular_denominations?.title}</h2>
      <div className="conversionRate">
        {currencyData?.popular_denominations?.table?.map(
          (item: any, tableIndex: number) => {
            return (
              <div className="conversionRate__col" key={tableIndex}>
                <h3 className="conversionRate_heading">{item?.title}</h3>
                <table className="conversionTbl">
                  <thead>
                    <tr>
                      <th>
                        <h3>{item?.col_name?.[0]}</h3>
                      </th>
                      <th>
                        <h3>{item?.col_name?.[1]}</h3>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.row_value_left_side?.map(
                      (val: any, index: number) => {
                        if (tableIndex === 0) {
                          const href = `/tripmoney/currency-converter/${currencyData?.curr_code?.toLowerCase?.()}-to-inr/${val}-${currencyData?.curr_code?.toLowerCase?.()}`;
                          return (
                            <tr key={index}>
                              <td>
                                <a href={href} target="_self">
                                  {val} {currencyData?.curr_code}
                                </a>
                              </td>
                              <td>{(val * inrExchageRate).toFixed(2)} INR</td>
                            </tr>
                          );
                        } else if (tableIndex === 1) {
                          return (
                            <tr key={index}>
                              <td>{val} INR</td>
                              <td>
                                {(val / inrExchageRate).toFixed(2)}{" "}
                                {currencyData?.curr_code}
                              </td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      }
                    )}
                  </tbody>
                </table>
              </div>
            );
          }
        )}
      </div>

      {/* Since we don't get last 30/90 days rate, we are keeping this commented out */}
      {/* <div className="separator"></div>

      <div className="statsTableWrapper">
        <div className="statsTableHeader">
          <p>{currencyData?.popular_denominations?.statsTable?.title}</p>
        </div>
        <table
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          className="statsTable"
        >
          <thead>
            <tr>
              {currencyData?.popular_denominations?.statsTable?.statsTableHeader?.map(
                (header: string, index: number) => {
                  return <th key={index}>{header}</th>;
                }
              )}
            </tr>
          </thead>
          <tbody>
            {currencyData?.popular_denominations?.statsTable?.statsTableData?.map(
              (item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{item.title}</td>
                    <td>{item.val1}</td>
                    <td>{item.val2}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div> */}
    </>
  );
}
