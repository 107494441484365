import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

const useHeaderMobileAnimation = (
  setIsLight: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const ANIMATION_TYPE = "ease-in-out";
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const timerId = setTimeout(() => {
      const rootContainerElement = document.getElementById("root_container");
      const hamBlackRefElement = document.getElementById("hamBlackMobile_ref");
      const hamWhiteRefElement = document.getElementById("hamWhiteMobile_ref");
      const tmlogo = document.getElementById("tmlogo_mobile");
      const privacyElement = document.getElementById("privacyMobile_ref");
      const headerMobileElement = document.getElementById("headerMobile_ref");
      const currMobileElement = document.getElementById("currMobile_ref");
      const greyMobileElement = document.getElementById("greyMobile_ref");

      // gsap.to(mobileMapRefElement, {
      //   y: 0,
      //   opacity: 1,
      //   ease: "fade-in",
      //   //delay: 4,
      //   duration: 0.4,
      // });

      const gsapConfig = {
        headerLight: { color: "#000", backgroundColor: "#fff" },
        headerDark: {
          backgroundColor: "#000",
          color: "rgba(255,255,255,0.6)",
        },
        headerGrey: { color: "#000", backgroundColor: "#f5f5f5" },
        headerTransparent: {
          backgroundColor: "transparent",
          color: "rgba(255,255,255,0.6)",
        },
      };

      gsap.to(tmlogo, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currMobileElement,
          start: "top top+=60",
          // toggleActions: "play none restart reset",
          end: "bottom top",
          onEnter: () => {
            setIsLight(false);
            gsap.to(headerMobileElement, gsapConfig.headerGrey);
          },

          onLeaveBack: () => {
            setIsLight(true);
          },
        },
        ease: ANIMATION_TYPE,
      });
      if (document.querySelector("body")?.className === "about-us-mobile") {
        // hero banner section
        gsap.to(headerMobileElement, {
          scrollTrigger: {
            scroller: ".about-us-mobile",
            trigger: "#aboutUsWrapper",
            start: "top top",
            end: "bottom top",
            onEnter: () => {
              setIsLight(false);
              gsap.to(headerMobileElement, gsapConfig.headerGrey);
            },
            onLeaveBack: () => {
              setIsLight(false);
              gsap.to(headerMobileElement, gsapConfig.headerGrey);
            },
          },
          ease: ANIMATION_TYPE,
        });

        // our journey section
        gsap.to(headerMobileElement, {
          scrollTrigger: {
            scroller: ".about-us-mobile",
            trigger: "#ourJourney",
            start: "top 80px",
            end: "bottom top",
            onEnter: () => {
              setIsLight(true);
              gsap.to(headerMobileElement, gsapConfig.headerDark);
            },
            onLeaveBack: () => {
              setIsLight(false);
              gsap.to(headerMobileElement, gsapConfig.headerGrey);
            },
          },
          ease: ANIMATION_TYPE,
        });

        // spotlight section
        gsap.to(headerMobileElement, {
          scrollTrigger: {
            scroller: ".about-us-mobile",
            trigger: "#spotLight",
            start: "top 80px",
            end: "bottom top",
            onEnter: () => {
              setIsLight(false);
              gsap.to(headerMobileElement, gsapConfig.headerLight);
            },
            onLeaveBack: () => {
              setIsLight(true);
              gsap.to(headerMobileElement, gsapConfig.headerDark);
            },
          },
          ease: ANIMATION_TYPE,
        });
      } else {
        gsap.to(hamWhiteRefElement, {
          scrollTrigger: {
            scroller: rootContainerElement,
            trigger: currMobileElement,
            start: "top top+=60",
            // toggleActions: "play none restart reset",
            end: "bottom top",
            onEnter: () => {
              gsap.to(hamWhiteRefElement, { opacity: 1 });
            },
          },
          opacity: 1,
          ease: ANIMATION_TYPE,
        });
        gsap.to(hamWhiteRefElement, {
          scrollTrigger: {
            scroller: rootContainerElement,
            trigger: privacyElement,
            start: "top top+=60",
            // toggleActions: "play none restart reset",
            end: "bottom top",
            onEnter: () => {
              gsap.to(hamWhiteRefElement, { opacity: 1 });
            },
          },
          // opacity: 1,
          ease: ANIMATION_TYPE,
        });
        gsap.to(hamWhiteRefElement, {
          scrollTrigger: {
            scroller: rootContainerElement,
            trigger: greyMobileElement,
            start: "top top+=60",
            // toggleActions: "play none restart reset",
            end: "bottom top",
            onEnter: () => {
              gsap.to(hamWhiteRefElement, { opacity: 1 });
            },
          },
          // opacity: 1,
          ease: ANIMATION_TYPE,
        });
        // gsap.to(hamWhiteRefElement, {
        //   scrollTrigger: {
        //     scroller: rootContainerElement,
        //     trigger: rootContainerElement,
        //     start: "top top+=60",
        //     // toggleActions: "play none restart reset",
        //     end: "bottom top",
        //   },
        //   opacity: 0,
        //   color: "#000",
        //   ease: ANIMATION_TYPE,
        // });
        // gsap.to(hamBlackRefElement, {
        //   scrollTrigger: {
        //     scroller: rootContainerElement,
        //     trigger: rootContainerElement,
        //     start: "top top+=60",
        //     // toggleActions: "play none restart reset",
        //     end: "bottom top",
        //     onEnter: () => {
        //       gsap.to(hamBlackRefElement, {opacity:0,display:"none"})
        //     },

        //     onLeaveBack: () => {
        //       gsap.to(hamBlackRefElement, {opacity:1,display:"inline-block"})
        //     },
        //   },
        //   // opacity: 1,
        //   // color: "#000",
        //   ease: ANIMATION_TYPE,
        // });
        gsap.to(hamBlackRefElement, {
          scrollTrigger: {
            scroller: rootContainerElement,
            trigger: currMobileElement,
            start: "top top+=60",
            // toggleActions: "play none restart reset",
            end: "bottom top",
            onEnter: () => {
              gsap.to(hamBlackRefElement, {
                opacity: 0,
                display: "none",
              });
            },

            onLeaveBack: () => {
              gsap.to(hamBlackRefElement, {
                opacity: 1,
                display: "inline-block",
              });
            },
          },
          // display: "none",
          // opacity: 0,
          // color: "#000",
          ease: ANIMATION_TYPE,
        });
        gsap.to(hamBlackRefElement, {
          scrollTrigger: {
            scroller: rootContainerElement,
            trigger: privacyElement,
            start: "top top+=60",
            // toggleActions: "play none restart reset",
            end: "bottom top",
            onEnter: () => {
              gsap.to(hamBlackRefElement, {
                opacity: 0,
                display: "none",
              });
            },
          },
          // display: "none",
          // opacity: 0,
          ease: ANIMATION_TYPE,
        });
        gsap.to(hamBlackRefElement, {
          scrollTrigger: {
            scroller: rootContainerElement,
            trigger: greyMobileElement,
            start: "top top+=60",
            // toggleActions: "play none restart reset",
            end: "bottom top",
            onEnter: () => {
              gsap.to(hamBlackRefElement, {
                opacity: 0,
                display: "none",
              });
            },
          },
          // display: "none",
          // opacity: 0,
          ease: ANIMATION_TYPE,
        });

        gsap.to(headerMobileElement, {
          scrollTrigger: {
            scroller: rootContainerElement,
            trigger: currMobileElement,
            start: "top top+=60",
            // toggleActions: "play none restart reset",
            end: "bottom top",
            onEnter: () => {
              gsap.to(headerMobileElement, {
                color: "#000",
                backgroundColor: "#fff",
              });
            },

            onLeaveBack: () => {
              gsap.to(headerMobileElement, {
                backgroundColor: "#000",
                color: "rgba(255,255,255,0.6)",
              });
            },
          },
          // background: "#fff",
          // color: "#000",
          ease: ANIMATION_TYPE,
        });
        gsap.to(headerMobileElement, {
          scrollTrigger: {
            scroller: rootContainerElement,
            trigger: privacyElement,
            start: "top top+=60",
            // toggleActions: "play none restart reset",
            end: "bottom top",
            onEnter: () => {
              gsap.to(headerMobileElement, {
                color: "#000",
                backgroundColor: "#fff",
              });
            },

            onLeaveBack: () => {
              gsap.to(headerMobileElement, {
                color: "#000",
                backgroundColor: "#f5f5f5",
              });
            },
          },
          // background: "#fff",
          // color: "#000",
          ease: ANIMATION_TYPE,
        });
        gsap.to(headerMobileElement, {
          scrollTrigger: {
            scroller: rootContainerElement,
            trigger: greyMobileElement,
            start: "top top+=60",
            // toggleActions: "play none restart reset",
            end: "bottom top",
            onEnter: () => {
              gsap.to(headerMobileElement, {
                color: "#000",
                backgroundColor: "#f5f5f5",
              });
            },
            onLeaveBack: () => {
              gsap.to(headerMobileElement, {
                color: "#000",
                backgroundColor: "#fff",
              });
            },
          },
          // background: "#F5F5F5",
          // color: "#000",
          ease: ANIMATION_TYPE,
        });
      }
    }, 300);
    return () => clearTimeout(timerId);
  }, []);
};

export default useHeaderMobileAnimation;
