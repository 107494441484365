//import { symbol } from 'prop-types';
import styled from "styled-components";
import { theme } from "@tm/utility/global";

import { deviceType } from "@tm/static/utils";

export const SelectCountryStyle = styled.div`
  /* margin-top: 116px; */
  background: #fff;
  /* display: flex;
    flex-direction: column; */
`;

export const Scroller = styled.div`
  overflow-y: scroll;
  height: 379px;
  padding-bottom: 50px;

  @media ${deviceType.mobile} {
    height: calc(100vh - 70px);
  }
`;

export const SelectCountryHeader = styled.div`
  height: 70px;
  display: flex;
  flex-direction: column;
`;

export const CountryList = styled.ul`
  li {
    display: flex;
    /* justify-content: space-between; */
    cursor: pointer;
    padding: 16px 0px;
    border-bottom: 1px solid #eee;
  }

  li .highlight {
    background-color: ${theme.yellowText3};
  }

  li:last-child {
    border-bottom: unset;
  }
`;

export const FixedBtn = styled.div`
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const SelectCountrySearch = styled.div`
  padding: 16px;
  width: 100%;
`;
