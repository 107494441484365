import { isPartnerGI, isPartnerMMT } from "@tm/insurance/utils";

import { IAnalytics } from "./IAnalytics";
import { MmtAnalytics } from "./MmtAnalytics";
import { GIAnalytics } from "./GIAnalytics";

let analytics: IAnalytics | null = null;

export function getAnalytics(): IAnalytics | null {
  if (analytics === null && isPartnerMMT()) {
    analytics = new MmtAnalytics();
  } else if (analytics === null && isPartnerGI()) {
    analytics = new GIAnalytics();
  }
  return analytics;
}
