import { ButtonEventType } from "@tm/types";
import { SCLandingButton } from "./LandingButton.style";

export interface ILandingButton {
  customTitle?: string;
  margin?: string;
  customWidth?: string;
  theme?: string;
  disableState?: string | boolean;
  handleClick?: ButtonEventType;
  flexShrink?: string;
  padding?: string;
  background?: string;
  id?: string;
}

const LandingButton = ({
  padding,
  customTitle,
  margin,
  customWidth,
  theme,
  disableState,
  handleClick,
  flexShrink,
  background,
  id,
}: ILandingButton) => {
  return (
    <SCLandingButton
      id={id}
      onClick={handleClick}
      flexShrink={flexShrink}
      disableState={disableState}
      theme={theme}
      margin={margin}
      customWidth={customWidth}
      padding={padding}
      background={background}
    >
      {customTitle}
    </SCLandingButton>
  );
};

export default LandingButton;
