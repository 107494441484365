import {
  LandingOfferingContainerStyle,
  ForexOfferingWpr,
} from "./LandingOfferingContainer.style";
import { useDevice } from "../../../hooks/useDevice";

import { LandingFaqTitle } from "../LandingFaq/LandingFaq.style";
import ForexOfferingCard from "../ForexOfferingCard/ForexOfferingCard";
import { landingForexOfferingCardDataProps } from "../../mockData";
import {
  PageNameMapping,
  ElementNameMapping,
  SectionNameMapping,
} from "../../../constants";
import { getElementUniqueIds } from "../../../utils";

interface LandingOfferingContainerProps {
  heading?: string;
  data: landingForexOfferingCardDataProps[];
  onClickProceed: (item: landingForexOfferingCardDataProps) => void;
}

const LandingOfferingContainer = ({
  heading,
  data,
  onClickProceed,
}: LandingOfferingContainerProps) => {
  const device = useDevice();
  return (
    <LandingOfferingContainerStyle>
      {heading && (
        <LandingFaqTitle
          id={getElementUniqueIds(
            PageNameMapping.LISTING_PAGE,
            SectionNameMapping.OFFERINGS,
            ElementNameMapping.COMP_HEADING
          )}
          marginLeft="16px"
        >
          {heading}
        </LandingFaqTitle>
      )}
      <ForexOfferingWpr margin={`${device === "sm" ? 24 : 32}px 0px 0px 0px`}>
        {data.map((item, idx) => (
          <ForexOfferingCard
            key={item.id}
            data={item}
            onClick={() => onClickProceed(item)}
            margin={idx + 1 < data.length ? "0px 18px 0px 0px" : "0px"}
          />
        ))}
      </ForexOfferingWpr>
    </LandingOfferingContainerStyle>
  );
};

export default LandingOfferingContainer;
