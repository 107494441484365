import {
  ParaTag,
  FlexComp,
  LandingButton,
  CommonModalDweb,
  AnchorTag,
  FormattedText,
} from "@tm/static";
import { theme } from "@tm/utility/global";

import { IAddPassportModal } from "./types";
import {} from "./PassportNumComp.style";

const AddPassportModal = ({
  handleSkip,
  handleClose,
  data,
}: IAddPassportModal) => {
  const { title, info, addBtnText, skipBtnText } = data || {};

  return (
    <CommonModalDweb
      handleClick={handleClose}
      customTitle={title}
      customIcon="https://tripmoneycmsimgak.mmtcdn.com/img/Geeric_caution_b863e71f7f.svg"
      nonClipHeading
    >
      <ParaTag fontSize="md" color={theme.greyText}>
        <FormattedText value={info} />
      </ParaTag>
      <FlexComp
        justifyContent="space-between"
        alignItems="center"
        margin="26px 0 0"
        gap="10px"
      >
        {addBtnText && (
          <LandingButton
            onClick={handleClose}
            customWidth="122px"
            customTitle={addBtnText}
          />
        )}
        {skipBtnText && (
          <AnchorTag handleClick={handleSkip} fontSize="md" fontWeight="bold">
            {skipBtnText}
          </AnchorTag>
        )}
      </FlexComp>
    </CommonModalDweb>
  );
};

export default AddPassportModal;
