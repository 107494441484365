import { getPartner } from ".";
import { getCookie } from "./cookieUtils";
import { APP_SOCIAL_URL, GET_SLT_TOKEN_URL, COOKIES } from "../constants";

interface FetchApiOptions {
  maxRetries?: number;
  retryDelay?: number;
  headers?: Record<string, string>;
  method?: string;
  data?: any;
}

export async function fetchApi(
  url: any,
  options: FetchApiOptions = {},
  timeout = 10000
) {
  const {
    maxRetries = 1,
    retryDelay = 1000,
    headers = {},
    method = "GET",
    data = null,
  } = options;
  let retries = 1;

  while (retries <= maxRetries) {
    try {
      const controller = new AbortController();
      const signal = controller.signal;
      setTimeout(() => controller.abort(), timeout);
      const tmAuthHeaders: any =
        url?.includes("/pdt") ||
        url?.includes(APP_SOCIAL_URL(getPartner())) ||
        url?.includes(GET_SLT_TOKEN_URL(getPartner()))
          ? {}
          : {
              "x-tm-auth": getCookie(COOKIES.TM_AUTH_KEY, true) || "",
              partner: getPartner() || "",
              credentials: "include",
            };

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          ...headers, // merge the provided headers with the default headers,
          ...tmAuthHeaders,
        },
        body: data && JSON.stringify(data),
        signal,
      });

      if (response.status === 500) {
        throw new Error("Internal Server Error");
      }

      if (response.status === 404) {
        throw new Error("Resource Not Found");
      }

      const responseData = await response.json();
      return responseData;
    } catch (error: any) {
      if (error.name === "AbortError") {
        console.error("Request cancelled");
        throw new Error("Request cancelled");
      }

      console.error(error);
      retries++;
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
      continue;
    }
  }

  throw new Error(`Failed to fetch API after ${maxRetries} attempts.`);
}
