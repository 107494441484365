import styled from "styled-components";
import { theme } from "../../css/Global";

interface BulletListItemStyleProps {
  bulletColor: string;
}

export const BulletListItemStyle = styled.li<BulletListItemStyleProps>`
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.greyText};

  &:last-child {
    margin-bottom: 0px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0px;
    width: 4px;
    border-radius: 4px;
    height: 16px;
    background-color: ${(props) => props.bulletColor};
  }
`;
