interface LocationFetchingIconProps {
  props?: any;
}

export const LocationFetchingIcon = ({ props }: LocationFetchingIconProps) => (
  <svg
    width={36}
    height={44}
    viewBox="0 0 36 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36 17.2174C36 26.7263 25 37.3043 18 44C10 36.3478 0 26.7263 0 17.2174C0 7.70849 8.05887 0 18 0C27.9411 0 36 7.70849 36 17.2174Z"
      fill="#008CFF"
    />
    <ellipse cx={18} cy={17.6004} rx={13.5} ry={13.2} fill="white" />
  </svg>
);
