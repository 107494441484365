import { useContext } from "react";
import DeviceContext, { DeviceContextV2 } from "../Contexts/DeviceContext";

export const useDeviceContext = () => {
  return useContext(DeviceContext);
};
export const useDeviceV2 = () => useContext(DeviceContextV2);
export const useBreakpointV2 = () => {
  const device = useDeviceV2();
  switch (device) {
    case "mobile":
      return "sm";
    case "desktop":
      return "lg";
    case "tablet":
      return "md";
    default:
      return "md";
  }
};
