import styled from "styled-components";
import { deviceType } from "@tm/static/utils";

export const InnerContentWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media screen and ${deviceType.mobile} {
    width: 100%;
  }
`;
