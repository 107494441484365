import { Fragment } from "react";

import { H5Tag, TMFloatingInput } from "@tm/static";
import { TMFloatingInputProps } from "../../../TMFloatingInput/types";
import { ModalContentEqualizer } from "./ModalScreen.style";

export interface DeliveryScreenProps {
  title?: string;
  pinCodeDetails?: TMFloatingInputProps;
}

const ScreenFour = ({ title, pinCodeDetails }: DeliveryScreenProps) => {
  return (
    <Fragment>
      {title && <H5Tag margin="0px 0px 24px 0px">{title}</H5Tag>}
      {pinCodeDetails && (
        <ModalContentEqualizer>
          <TMFloatingInput {...pinCodeDetails} />
        </ModalContentEqualizer>
      )}
    </Fragment>
  );
};

export default ScreenFour;
