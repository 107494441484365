import styled from "styled-components";
import { useState } from "react";

import useTimer, { CounterType } from "../../../hooks/useTimer";
import { useDevice } from "../../../hooks/useDevice";
import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import { theme } from "../../css/Global";
import ToolTip from "../ui-atoms/ToolTip/ToolTip";
import { useListingPdtLoggerV2 } from "@tm/ui-widgets/hooks";
import { getEventDetails } from "../../../utils";
import { PDT_EVENTS } from "../../../constants";

const OrderTimeWrapper = styled.div`
  font-size: 14px;
  color: #555;
  display: flex;
`;

const HighlightText = styled.span`
  color: #d98000;
  font-weight: bold;
`;

const InfoIconWrapper = styled.div`
  position: relative;
  display: flex;
`;

interface OrderTimeType {
  orderCutOffText?: string;
  startHours: number;
  startMinutes: number;
  startSeconds?: number;
  counterType?: CounterType;
  stopHours?: number;
  stopMinutes?: number;
  stopSeconds?: number;
  toolTipInfo: string;
}

const OrderTime = ({
  orderCutOffText,
  startHours,
  startMinutes,
  startSeconds = 0,
  counterType = "decrement",
  stopHours = 0,
  stopMinutes = 0,
  stopSeconds = 0,
  toolTipInfo,
}: OrderTimeType) => {
  const { hours, minutes } = useTimer({
    startHours,
    startMinutes,
    startSeconds,
    counterType,
    stopHours,
    stopMinutes,
    stopSeconds,
  });
  const device = useDevice();
  const [showToolTip, setShowToolTip] = useState(false);
  const { sendPdtDataV2 } = useListingPdtLoggerV2();
  const infoHandler = () => {
    setShowToolTip(!showToolTip);
    sendPdtDataV2({
      event_detail: getEventDetails({
        event_name: PDT_EVENTS.ORDER_TIMER_INFO_CLICKED,
      }),
    });
  };
  const closeTooltip = () => {
    setShowToolTip(false);
  };

  return (
    <FlexComp
      margin="0"
      padding="0"
      justifyContent={device === "lg" ? "center" : ""}
      alignItems={device === "lg" ? "center" : ""}
    >
      <OrderTimeWrapper>
        {Number(hours) > 0 || Number(minutes) > 0 ? (
          <SpanTag fontSize={device === "lg" ? "large" : "sm"}>
            Order within{" "}
            <HighlightText>{`${Number(hours) !== 0 ? `${hours}hr` : ""} 
            ${Number(minutes) !== 0 ? `${minutes}min` : ""}`}</HighlightText>
          </SpanTag>
        ) : null}
        {orderCutOffText && (
          <SpanTag
            fontSize={device === "lg" ? "large" : "sm"}
            dangerousText={orderCutOffText}
          ></SpanTag>
        )}
      </OrderTimeWrapper>

      {(Number(hours) > 0 || Number(minutes) > 0 || orderCutOffText) &&
      toolTipInfo ? (
        <InfoIconWrapper>
          <ImageTag
            src="https://tripmoneycmsimgak.mmtcdn.com/img/Info_0a25f3f0f9.svg"
            showCursorPointer
            margin="0px 0px 0px 4px"
            onClick={infoHandler}
            height="18px"
          />
          {showToolTip && (
            <ToolTip
              closeTooltip={closeTooltip}
              arrowPosition={device === "lg" ? "68%" : "134px"}
              style={{
                right: device === "lg" ? "-95px" : "-178px",
                width: device === "lg" ? "320px" : "320px",
                zIndex: 100,
                background: "#007E7D",
                top: "25px",
              }}
              arrowDirection="up"
              bgColor="#007E7D"
            >
              <FlexComp
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <ParaTag color={theme.whiteText} fontWeight="regular">
                  {toolTipInfo}
                </ParaTag>
                <ImageTag
                  src={`https://tripmoneycmsimgak.mmtcdn.com/img/Close_ef36e1a467.png`}
                  width="24px"
                  height="24px"
                  onClick={closeTooltip}
                />
              </FlexComp>
            </ToolTip>
          )}
        </InfoIconWrapper>
      ) : null}
    </FlexComp>
  );
};

export default OrderTime;
