import { useEffect } from "react";
import { useDevice } from "../useDevice";

export const useManageBodyScrollForLocFetching = (
  isPageLoading: boolean,
  isLocationFetching: boolean
) => {
  const device = useDevice();

  useEffect(() => {
    if (device !== "sm") return;

    if (isPageLoading || isLocationFetching) {
      window.scrollTo({
        left: 0,
        top: 0,
      });
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }

    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [isPageLoading, isLocationFetching, device]);
};
