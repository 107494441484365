import React, { useRef, useEffect, useState } from "react";
import {
  H2Tag,
  CliptextStyle,
  H4Tag,
  FormattedText,
  Shimmer,
  FlexComp,
  ImageComponent,
  AnchorTag,
  ParaTag,
} from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";
import ArrowRight from "@tm/static/icons/ArrowRight";

import { TextNodeType } from "../FormattedText/FormattedText";
import {
  DesktopLayoutStyleSectionWrap,
  DesktopLayoutStyleSection,
  DesktopLayoutLeftSection,
  DesktopLayoutRightSection,
  DesktopLayoutBotttomSection,
} from "./DesktopLayout.style";
import BlueOneWayArr from "../../icons/blueOneWayArr.svg";
import { usePartnerContext } from "@tm/static/partnerContext";
import { PARTNER } from "@tm/insurance/constants";

interface LayoutProp {
  title?: TextNodeType;
  leftComp?: React.ReactNode;
  rightComp?: React.ReactNode;
  bottomComp?: React.ReactNode;
  headingComp?: React.ReactNode;
  height?: string;
  alignCenter?: boolean;
  leftSectionMargin?: string | undefined;
  rightSectionGap?: string | undefined;
  rightSectionHeight?: string | undefined;
  leftSectionHeight?: boolean | undefined;
  landingMargin?: boolean | undefined;
  subTitle?: string | TextNodeType;
  shimmer?: boolean;
  landingPageProp?: boolean;
  goToMyOrders?: () => void;
}

const DesktopLayout = ({
  title,
  leftComp,
  rightComp,
  bottomComp,
  headingComp,
  height,
  alignCenter,
  leftSectionMargin,
  rightSectionHeight,
  leftSectionHeight,
  landingMargin,
  subTitle,
  shimmer,
  landingPageProp,
  goToMyOrders,
}: LayoutProp) => {
  const context = usePartnerContext();
  const device = useBreakpointV2();
  // console.log(context);
  const [windowHeight, setWindowHeight] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    if (typeof window !== "undefined") {
      setWindowHeight(window.innerHeight);
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, []);

  return (
    <DesktopLayoutStyleSectionWrap
      id={"pageId"}
      height={height}
      pageTheme={context ? context : undefined}
      landingPageProp={landingPageProp}
    >
      <DesktopLayoutStyleSection
        landingMargin={landingMargin}
        alignCenter={alignCenter}
        pageTheme={context ? context : undefined}
      >
        <DesktopLayoutLeftSection
          leftSectionHeight={leftSectionHeight}
          leftSectionMargin={leftSectionMargin}
          pageTheme={context ? context : undefined}
        >
          <div>
            {shimmer ? (
              <FlexComp flexDirection="column" gap="4px">
                {subTitle && <Shimmer width="375px" height="38px" />}
                <Shimmer width="100%" height="56px" />
              </FlexComp>
            ) : (
              <>
                {subTitle &&
                  (device == "lg" ? (
                    <H4Tag
                      margin={device == "lg" ? "40px 0 0" : ""}
                      color="#CF8100"
                    >
                      <FormattedText value={subTitle} />
                    </H4Tag>
                  ) : (
                    <ParaTag fontSize="md" fontWeight="black" color="#CF8100">
                      <FormattedText value={subTitle} />
                    </ParaTag>
                  ))}
                {device == "lg" ? (
                  <H2Tag>
                    {title ? <FormattedText value={title} /> : null}
                  </H2Tag>
                ) : (
                  <H4Tag>
                    {title ? <FormattedText value={title} /> : null}
                  </H4Tag>
                )}
              </>
            )}
          </div>
          {leftComp}
        </DesktopLayoutLeftSection>
        <DesktopLayoutRightSection rightSectionHeight={rightSectionHeight}>
          {context && landingPageProp && device == "lg" && (
            <FlexComp
              alignSelf="flex-end"
              isCursorPointer
              gap="4px"
              alignItems="center"
              margin="0 0 8px"
            >
              <AnchorTag fontWeight="bold" handleClick={goToMyOrders}>
                My Orders
              </AnchorTag>
              <ArrowRight width={16} height={16} />
            </FlexComp>
          )}
          {rightComp}
        </DesktopLayoutRightSection>
      </DesktopLayoutStyleSection>
      {bottomComp && (
        <DesktopLayoutBotttomSection>{bottomComp}</DesktopLayoutBotttomSection>
      )}
    </DesktopLayoutStyleSectionWrap>
  );
};

export default DesktopLayout;
