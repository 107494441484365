import { useState } from "react";

export default function OtherConversions({ currencyData }: any) {
  const [showViewMore, setShowViewMore] = useState<boolean>(true);

  const handleOnClick = () => {
    setShowViewMore(!showViewMore);
  };

  return (
    <>
      <div className="separator"></div>
      <h2 className="h2">{currencyData?.other_conversions?.title}</h2>
      <ul className={`capSulesList grid5 ${!showViewMore ? "showAll" : ""}`}>
        {currencyData?.other_conversions?.cards?.map(
          (card: any, index: number) => {
            const cardTitleCurrency = card?.title.split(" ")[1];
            if (currencyData?.curr_code !== cardTitleCurrency) {
              return (
                <li key={`${card?.title}${index}`} className="capsule">
                  <a href={card?.href} target="_self" rel="noreferrer">
                    {card?.title}
                  </a>
                </li>
              );
            }
            return null;
          }
        )}
      </ul>
      <p className="viewMore">
        <a className="viewMore__cta" onClick={() => handleOnClick()}>
          {showViewMore ? "View More" : "View Less"}
        </a>
      </p>
    </>
  );
}
