import React, { useState } from "react";
import {
  FlexComp,
  LandingButton,
  SpanTag,
  ParaTag,
  AnchorTag,
  InsurancePlanTypeSectionComp,
  InsurancePlansHeader,
  ToolTipComp,
  ImageComponent,
  FormattedText,
} from "@tm/static";

import {
  InsurancePlansCompStyle,
  InsurancePlanSection,
  InsurancePlanTypeHd,
  InsuranceTypeSection,
  PopularTag,
} from "./InsurancePlans.style";
import { insurancePlanCompType } from "./type";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { getAnalytics } from "@tm/insurance/analytics";

const analytics = getAnalytics();

const InsurancePlansComp = ({
  data,
  handleMoreBenefits,
  handleTravAssist,
  handleCompareSelect,
  handleBuyButton,
}: insurancePlanCompType) => {
  const device = useBreakpointV2();
  const productType: "insurance" | "assistance" | "combo" =
    // @ts-ignore
    data?.packName?.text?.value?.toLowerCase?.();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <InsurancePlansCompStyle>
      <InsurancePlansHeader
        handleCompareSelect={
          handleCompareSelect
            ? () => handleCompareSelect(parseInt(data?.productId))
            : () => {}
        }
        data={data}
      />
      <InsurancePlanSection>
        <FlexComp gap="16px" justifyContent="space-between" margin="0 0 16px">
          <InsuranceTypeSection>
            {data?.insuranceType && (
              <ParaTag fontSize="md" fontWeight="black">
                <FormattedText value={data?.insuranceType} />
              </ParaTag>
            )}
            <FlexComp gap="8px" alignItems="center">
              {data?.popularTag && device == "sm" && (
                <PopularTag>
                  <SpanTag
                    fontSize="xs"
                    fontWeight="bold"
                    color={theme.whiteText}
                    textTransform="uppercase"
                  >
                    <FormattedText value={"Popular"} />
                  </SpanTag>
                </PopularTag>
              )}
              {data?.planExtraInfo && (
                <SpanTag fontSize="xs" color={theme.greyText}>
                  <FormattedText value={data?.planExtraInfo} />
                </SpanTag>
              )}
            </FlexComp>
          </InsuranceTypeSection>
          <FlexComp
            margin="0 0 0 auto"
            gap="0"
            flexDirection="column"
            alignItems={"flex-end"}
            flexShrink="0"
            width={device == "lg" ? "16%" : "25%"}
          >
            {data?.discountText && (
              <SpanTag fontSize="xs" fontWeight="bold" color={theme.greenText}>
                <FormattedText value={data?.discountText} />
              </SpanTag>
            )}
            <FlexComp gap="2px" alignItems="center">
              {data?.originalPrice && (
                <FormattedText
                  value={data?.originalPrice}
                  baseStyle={{
                    fontSize: 12,
                    fontWeight: "regular",
                    color: `${theme.greyText6}`,
                    textDecoration: "line-through",
                    textDecorationThickness: 0.7,
                    textAlign: "right",
                    whiteSpace: "nowrap",
                  }}
                />
              )}
              <FormattedText
                value={data?.insAmount}
                baseStyle={{
                  fontSize: "md",
                  fontWeight: "bold",
                  color: "#000",
                  textAlign: "right",
                }}
              />
            </FlexComp>
            {data?.paxPerDay && (
              <SpanTag fontSize="xs" color={theme.greyText}>
                <FormattedText value={data?.paxPerDay} />
              </SpanTag>
            )}
            {data?.ctaLink && device == "sm" && (
              <AnchorTag
                color={theme.blueText}
                fontSize="xs"
                hrefProp={data?.tncLink}
                targetProp="_blank"
                handleClick={() => {
                  analytics?.trackListingEvents({
                    actionType: `TnC_clicked_${productType}`,
                  });
                }}
              >
                <FormattedText value={data?.ctaLink} />
              </AnchorTag>
            )}
          </FlexComp>
          {/* TODO: change contract */}
          {data?.buyBtn && device == "lg" && handleBuyButton && (
            <LandingButton
              onClick={() => handleBuyButton(data)}
              customWidth="fit-content"
              customTitle={data?.buyBtn}
            />
          )}
        </FlexComp>
        <InsurancePlanTypeHd>
          <FlexComp gap="8px" alignItems="center">
            {data?.insIcon && (
              <ImageComponent width="24px" height="24px" src={data?.insIcon} />
            )}
            <SpanTag fontSize="sm" fontWeight="black" color={theme.greyText}>
              {data?.insPlanName && <FormattedText value={data?.insPlanName} />}
              {data?.insurerNumber && (
                <AnchorTag
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  fontSize="sm"
                  fontWeight="black"
                >
                  {device == "lg" ? "&nbsp;" : ""}
                  <FormattedText value={data?.insurerNumber} />
                  {isHovered && (
                    <ToolTipComp
                      handleMouseEnter={handleMouseEnter}
                      handleMouseLeave={handleMouseLeave}
                      left="40%"
                      top="23px"
                      toolTipArrow="top"
                      width="247px"
                    >
                      <SpanTag fontSize="sm" color={theme.whiteText}>
                        {data?.insurerDetails?.insurer && (
                          <FormattedText
                            value={data?.insurerDetails?.insurer}
                          />
                        )}
                      </SpanTag>
                      <br />
                      <br />
                      <SpanTag fontSize="sm" color={theme.whiteText}>
                        {data?.insurerDetails?.details && (
                          <FormattedText
                            value={data?.insurerDetails?.details}
                          />
                        )}
                      </SpanTag>
                    </ToolTipComp>
                  )}
                </AnchorTag>
              )}
              {isHovered && (
                <ToolTipComp
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  left="40%"
                  top="23px"
                  toolTipArrow="top"
                  width="247px"
                >
                  <SpanTag fontSize="sm" color={theme.whiteText}>
                    {data?.insurerDetails?.insurer && (
                      <FormattedText value={data?.insurerDetails?.insurer} />
                    )}
                  </SpanTag>
                  <br />
                  <br />
                  <SpanTag fontSize="sm" color={theme.whiteText}>
                    {data?.insurerDetails?.details && (
                      <FormattedText value={data?.insurerDetails?.details} />
                    )}
                  </SpanTag>
                </ToolTipComp>
              )}
            </SpanTag>
          </FlexComp>
          {data?.ctaLink && device == "lg" && (
            <AnchorTag
              color={theme.blueText}
              fontSize="xs"
              fontWeight="black"
              hrefProp={data?.tncLink}
              targetProp="_blank"
              handleClick={() => {
                analytics?.trackListingEvents({
                  actionType: `TnC_clicked_${productType}`,
                });
              }}
            >
              <FormattedText value={data?.ctaLink} />
            </AnchorTag>
          )}
        </InsurancePlanTypeHd>
        <InsurancePlanTypeSectionComp
          data={data}
          handleMoreBenefits={handleMoreBenefits}
          handleTravAssist={handleTravAssist}
        />
        {/* TODO: add this in contract */}
        {/* {data?.travelAssistance && (
                    <TravelAssistanceSection>
                        {data?.travelAssistance.icon && (
                            <ImageComponent
                                width={'18px'}
                                height={'18px'}
                                src={data?.travelAssistance.icon}
                            />
                        )}
                        <SpanTag fontSize="xs" fontWeight="bold">
                            {data?.travelAssistance?.title && (
                                <FormattedText
                                    value={data?.travelAssistance?.title}
                                />
                            )}
                        </SpanTag>
                        <SpanTag fontSize="xs" color={theme.greyText}>
                            {data?.travelAssistance?.subTitle && (
                                <FormattedText
                                    value={data?.travelAssistance?.subTitle}
                                />
                            )}
                        </SpanTag>
                        <AnchorTag
                            fontSize="xs"
                            fontWeight="bold"
                            margin="0 0 0 auto"
                        >
                            {data?.travelAssistance?.ctaLink && (
                                <FormattedText
                                    value={data?.travelAssistance?.ctaLink}
                                />
                            )}
                        </AnchorTag>
                    </TravelAssistanceSection>
                )} */}
        {data?.buyBtn && device == "sm" && handleBuyButton && (
          <LandingButton
            onClick={() => handleBuyButton(data)}
            customWidth="100%"
            customTitle={data?.buyBtn}
            margin="16px 0 0"
          />
        )}
      </InsurancePlanSection>
    </InsurancePlansCompStyle>
  );
};

export default InsurancePlansComp;
