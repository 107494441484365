import React from "react";
import { ShimmerVersion3, ShimmerVersion2 } from "@tm/static";
import {
  AddTravellerDetailStyle,
  AddTravellerHd,
  TravellerAddShimmer,
} from "./AddTravellerDetail.style";
import TravellerDetailsShimmer from "./TravellerDetailsShimmer";

const AddTravellerDetailShimmer = ({}) => {
  return (
    <AddTravellerDetailStyle>
      <AddTravellerHd>
        <ShimmerVersion2 width="100%" height="28px" margin="0 0 8px" />
        <ShimmerVersion2 width="266px" height="16px" />
      </AddTravellerHd>
      <TravellerDetailsShimmer />
      {Array.from({ length: 3 }).map((_item, index) => (
        <TravellerAddShimmer key={`${index}`}>
          <ShimmerVersion2 width="75px" height="20px" />
          <ShimmerVersion3 width="60px" height="32px" />
        </TravellerAddShimmer>
      ))}
    </AddTravellerDetailStyle>
  );
};

export default AddTravellerDetailShimmer;
