import styled from "styled-components";
import {
  BG_COLOR,
  BLACK_COLOR,
  BLUE_COLOR,
  TEXT_COLOR,
  WHITE_COLOR,
} from "@tm/utility/theme";
import { IHeader } from ".";

export const LandingHeader = styled.div<IHeader>`
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  color: ${(p) => (p.isLight ? `${WHITE_COLOR[60]}` : "#000")};
  z-index: 40;

  & .displayNone {
    display: none;
  }

  ${(props) =>
    props.forexHeader &&
    `
  background: ${BG_COLOR.primary};
  color: ${BLACK_COLOR[60]};
  top:-80px;
  `}
`;

export const LandingHeaderWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  position: relative;
`;

export const TMLogo = styled.a<IHeader>`
  width: 140px;
  height: 40px;
  flex-shrink: 0;
`;

export const LandingHeaderList = styled.ul`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 32px;
  cursor: pointer;
  list-style-type: none;

  & li {
    position: relative;
  }
  & li a {
    color: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
  }
  & li:first-child {
    margin-right: 16px;
  }

  // & li:first-child:after {
  //   content: "";
  //   position: absolute;
  //   border: solid ${BLUE_COLOR[300]};
  //   border-width: 0 2px 2px 0;
  //   display: inline-block;
  //   padding: 4px;
  //   top: 3px;
  //   right: -22px;
  //   transition : ease-in 0.4s;
  // }
  // & li:first-child.opened:after{
  //   transform: rotate(-135deg);
  //   -webkit-transform: rotate(-135deg);
  //   top: 9px;
  // }
  // & li:first-child.closed:after{
  //   transform: rotate(45deg);
  //   -webkit-transform: rotate(45deg);
  // }
`;

export const ProductLinks = styled.div`
  background: ${BG_COLOR.primary};
  box-shadow: 0px 2px 16px ${BLACK_COLOR[16]};
  border-radius: 6px;
  padding: 24px 16px;
  position: absolute;
  top: 35px;
  left: 0;
  width: 230px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & a {
    width: 100%;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${TEXT_COLOR.medium};
  }
`;
