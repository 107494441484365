import { CurrencyRatePageStyle } from "./style";

type BreadCrumbItemType = {
  name: string;
  link: string;
};

type BreadCrumbProps = {
  breadCrumbData: Array<BreadCrumbItemType>;
};

const BreadCrumb = ({ breadCrumbData }: BreadCrumbProps) => {
  if (!breadCrumbData || !breadCrumbData.length) {
    return null;
  }
  return (
    <>
      <CurrencyRatePageStyle />
      <ul className="breadCrumb">
        {breadCrumbData.map((item, index) => (
          <li key={index}>
            <a href={item.link?.toLowerCase()}>{item.name}</a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default BreadCrumb;
