import styled from "styled-components";
type ToolTipStyleProps = {
  toolTipArrow?: string;
  selected?: boolean;
  left?: string;
  right?: string;
  top?: string;
  width?: string;
  bottom?: string;
};

export const ToolTipCompWrapper = styled.div<ToolTipStyleProps>`
  position: absolute;
  background: #1a7971;
  border: 4px solid #1a7971;
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  width: ${(props) => props.width};
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  border-radius: 4px;
  /* display: flex;
    align-items: center;
    justify-content: space-between; */
  gap: 20px;
  z-index: 1;

  color: #ffffff;
  &:after,
  &:before {
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(0, 0, 0, 0);
    border-width: 13px;
  }
  &:before {
    border-color: rgba(0, 0, 0, 0);
    border-width: 13px;
  }

  ${(props) =>
    props.toolTipArrow == "top" &&
    `
&:after, &:before {
	bottom: 100%;
	left: 50%;
}

&:after {
	border-bottom-color: #1A7971;
	margin-top: -30px;
}
&:before {
	border-bottom-color: #1A7971;
	margin-top: -36px;
}

`}
  ${(props) =>
    props.toolTipArrow == "bottom" &&
    `
&:after, &:before {
	top: 100%;
	left: 50%;
}

&:after {
	border-top-color: #1A7971;;
	margin-left: -34px;
}
&:before {
	border-top-color: #1A7971;
	margin-left: -36px;
}

`}
${(props) =>
    props.toolTipArrow == "right" &&
    `
&:after, &:before {
	left: 100%;
	top: 50%;
}

&:after {
	border-left-color: #1A7971;
	margin-top: -30px;
}
&:before {
	border-left-color: #1A7971;
	margin-top: -36px;
}

`}
${(props) =>
    props.toolTipArrow == "left" &&
    `
&:after, &:before {
	right: 100%;
	top: 50%;
}

&:after {
	border-right-color: #1A7971;
	margin-top: -17px;
}
&:before {
	border-right-color: #1A7971;
	margin-top: -19px;
}

`}
`;
