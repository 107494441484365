import { IPdtDataObjV2 } from "../types";
import {
  getComponentsPdt,
  getDeviceContext,
  getDeviceId,
  getDeviceIdStatus,
  getEventDetails,
  getEventTrackingContext,
  getJourneyIdFromCookie,
  getPageContext,
  getPageName,
  getPageUrl,
  getPlatform,
  getTmUserDetails,
  getUserContext,
  isOnServer,
} from "../..";
import {
  COOKIES,
  LOB,
  LOB_CATEGORY,
  PARTNER,
  PageNameMapping,
} from "../../../constants";
import { getDeviceSpecificInfo, getUtmPdtObj } from "./commonpdtUtils";
import { BridgeMethods } from "../../bridges";

interface ICommonPdtDataV2 {
  userId?: string | null;
  puid?: string | null;
  partner?: string | null;
  lobCategory?: string | null;
  lobFunnelType?: string;
  funnelStep?: string;
}
export const getCommonPdtDataV2 = ({
  userId,
  puid,
  partner,
  lobCategory,
  lobFunnelType,
  funnelStep,
}: ICommonPdtDataV2): IPdtDataObjV2 => {
  if (isOnServer()) return {} as IPdtDataObjV2;

  let app_ver = !isOnServer() ? BridgeMethods.getAppVersion() : null;

  app_ver = `${
    partner === PARTNER.MMT
      ? PARTNER.MMT.toUpperCase()
      : PARTNER.GI.toUpperCase()
  }_${app_ver}`;

  const { os, user_agent, os_version } = getDeviceSpecificInfo();
  return {
    event_tracking_context: getEventTrackingContext({
      ...getUtmPdtObj(),
      template_id: 61857,
      topic_name: "tripmoney_forex_b2c_pdt_client_logging",
      timestamp: new Date().getTime(),
      env: "prod",
      journey_id: getJourneyIdFromCookie(partner),
    }),
    event_detail: getEventDetails({
      event_type: "NA",
      event_name: "NA",
      event_timestamp: new Date().getTime(),
      user_details: getTmUserDetails({
        tm_uuid: `${userId ?? "NA"}`,
        partner,
      }),
      components: getComponentsPdt({
        bridge_status: getDeviceIdStatus(),
      }),
    }),
    user_context: getUserContext({
      // TODO - ask if nullable
      uuid: `${puid ?? "NA"}`,
      is_logged_in: userId ? true : false,
    }),
    device_context: getDeviceContext({
      os,
      os_version,
      app_ver,
      user_agent,
      platform: getPlatform() || "NA",
      device_id: getDeviceId(partner) || "NA",
    }),
    page_context: getPageContext({
      page_name: getPageName(), // nullable, string
      prev_page_name: PageNameMapping.LISTING_PAGE, // nullable, string
      lob: LOB.FOREX,
      lob_funnel: lobFunnelType,
      lob_category: lobCategory,
      page_url: getPageUrl(),
      funnel_step: funnelStep,
    }),
  };
};
export function getLobCategory() {
  if (isOnServer()) {
    return "NA";
  }
  const url = window.location.pathname;
  if (/currency/i.test(url)) {
    return LOB_CATEGORY.CN;
  } else if (/onboarding/i.test(url)) {
    return LOB_CATEGORY.PC;
  } else {
    return "";
  }
}
