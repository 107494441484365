interface ArrowRightProps {
  width: number;
  height: number;
}
const ArrowRight = ({ width, height }: ArrowRightProps) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 6.86518L21 6.86182L1 6.86518Z"
        fill="#008CFF"
      />
      <path
        d="M1 6.86518L21 6.86182"
        stroke="#008CFF"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M14.8595 13L20.2086 7.65621C20.5731 7.29196 20.5731 6.70132 20.2075 6.33707L14.8595 1"
        stroke="#008CFF"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};
export default ArrowRight;
