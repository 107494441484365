import React from "react";
import {
  ImageComponent,
  FlexComp,
  H5Tag,
  FormattedText,
  SpanTag,
} from "@tm/static";
import { theme } from "@tm/utility/global";

import { TextNodeType } from "../FormattedText/FormattedText";

import { GreatChoiceStyle } from "./GreatChoice.style";

interface GreatChoiceProp {
  title?: TextNodeType;
  info?: string | TextNodeType;
  icon?: string;
  background?: string;
  radius?: string;
  padding?: string;
}

const GreatChoice = ({
  title,
  info,
  icon,
  background,
  padding,
  radius,
}: GreatChoiceProp) => {
  const data: TextNodeType = {
    text: [
      {
        value: "Over ",
      },
      {
        value: "15k",
        styles: {
          fontWeight: "bold",
        },
      },
      {
        value: " Travellers insured in the last 3 months.",
      },
    ],
  };
  return (
    <GreatChoiceStyle background={background} padding={padding} radius={radius}>
      <FlexComp margin="0 0 8px" alignItems="center" gap="12px">
        {icon && <ImageComponent src={icon} height="32" width="32" />}

        {title && (
          <H5Tag color={theme.greenText}>
            <FormattedText value={title} />
          </H5Tag>
        )}
      </FlexComp>
      {(info || data) && (
        <SpanTag color={theme.greyText}>
          <FormattedText value={info ? info : data} />
        </SpanTag>
      )}
    </GreatChoiceStyle>
  );
};

export default GreatChoice;
