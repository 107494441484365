import styled from "styled-components";
import { FlexCompStyle } from "../FlexComp/FlexComp.style";
import { SpanTagStyle } from "../TextStyles/TextStyles.style";

export const OrderRightStyle = styled.div`
  background: #ffffff;
  border-radius: 16px;
`;

export const OrderRightHd = styled.div`
  padding: 20px 20px 12px;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const OrderRightCntnt = styled.div`
  padding: 12px 20px;
`;

export const PriceText = styled(SpanTagStyle)`
  flex-shrink: 0;
`;

export const MmtServiceFee = styled(FlexCompStyle)`
  margin-top: 12px;
  padding-top: 12px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
`;
