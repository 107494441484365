import styled from "styled-components";

export const AddCurrencyCardStyle = styled.div<any>`
  padding: 20px 12px;
  border-radius: 8px;
  background: ${(props) =>
    props.isError
      ? "linear-gradient(159.48deg,#FFF9ED 4.69%,rgba(255,239,236,0.736176) 27.76%,#F8F9E7 92.13%)"
      : "linear-gradient(159.48deg, #EDFBFF 4.69%, rgba(236, 241, 255, 0.736176) 27.76%, #E7F1F9 92.13%)"};
`;
