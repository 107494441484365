import BreadCrumb from "@forex/Forex/Breadcrumb";
import { CurrencySEOMarkdown } from "../CurrencySEOMarkdown";
import ForexCalculator from "../CurrencyConverterPageTemplate/ForexCalculator";
import { SEO_TEMPLATE_TYPE } from "@tm/static/utils";

export default function Hero({
  currencyData,
  inrExchangeRate,
  currCodeToCurrRate,
  switchInrExchnageRate,
  breadcrumb,
}: any) {
  const heading1 = currencyData?.hero?.title?.split("Currency Converter")[0];
  return (
    <section className="heroSection">
      <div className="heroSection__inner">
        <BreadCrumb breadCrumbData={breadcrumb || []} />
        <div className="heroContent">
          <div className="heroContent__left">
            <h1 className="inline">{heading1}</h1>
            <p>{currencyData?.hero?.desc}</p>
            <div className="heroContent_container">
              <span className="exchange">
                Exchange Rate &nbsp;<span>1&nbsp;</span>
                {currencyData?.curr_code} = &nbsp;
                <span>
                  {Number(inrExchangeRate).toFixed(
                    Number(inrExchangeRate) > 1 ? 2 : 4
                  )}
                  &nbsp;
                </span>{" "}
                INR
              </span>
              {currencyData?.hero?.offer_text && (
                <div className="hero_cashback">
                  <img src={currencyData?.hero?.offer_icon} alt="cashback" />
                  <CurrencySEOMarkdown className="hero_cashback_text">
                    {currencyData?.hero?.offer_text}
                  </CurrencySEOMarkdown>
                </div>
              )}
            </div>
          </div>
          <ForexCalculator
            currencyData={currencyData}
            inrExchangeRate={inrExchangeRate}
            currCodeToCurrRate={currCodeToCurrRate}
            switchInrExchnageRate={switchInrExchnageRate}
            templateType={SEO_TEMPLATE_TYPE.CURRENCY_RATE}
          />
        </div>
      </div>
    </section>
  );
}
