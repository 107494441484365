import styled from "styled-components";

interface ToolTipContProps {
  width?: string;
  bgColor?: string;
  arrowDirection?: "up" | "down" | "right" | "left";
  arrowPosition?: string;
}

export const ToolTipCont = styled.div<ToolTipContProps>`
  width: ${(props) => props.width || "400px"};
  background-color: ${(props) => props.bgColor || "#005756"};
  padding: 8px;
  border-radius: 4px;
  position: absolute;

  ${(props) => {
    const arrowStyles = `
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border: solid transparent;
            border-color: rgba(0, 87, 86, 0);
        `;

    switch (props.arrowDirection) {
      case "up":
        return `
                    ::after {
                        ${arrowStyles}
                        bottom: 100%;
                        left: ${props.arrowPosition || "50%"};
                        border-bottom-color: ${props.bgColor || "#005756"};
                        border-width: 8px;
                        margin-left: -8px;
                    }
                `;
      case "right":
        return `
                    ::after {
                        ${arrowStyles}
                        left: 100%;
                        top: ${props.arrowPosition || "50%"};
                        border-left-color: ${props.bgColor || "#005756"};
                        border-width: 8px;
                        margin-top: -8px;
                    }
                `;
      case "down":
        return `
                    ::after {
                        ${arrowStyles}
                        top: 100%;
                        left: ${props.arrowPosition || "50%"};
                        border-top-color: ${props.bgColor || "#005756"};
                        border-width: 8px;
                        margin-left: -8px;
                    }
                `;
      case "left":
        return `
                    ::after {
                        ${arrowStyles}
                        right: 100%;
                        top: ${props.arrowPosition || "50%"};
                        border-right-color: ${props.bgColor || "#005756"};
                        border-width: 8px;
                        margin-top: -8px;
                    }
                `;
      default:
        return "";
    }
  }}
`;

export const ToolTipBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0;
`;
