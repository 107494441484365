import React from "react";

import PlanBenefit from "./PlanBenefit";

import {
  BenefitDescHeader,
  BenefitRow,
  BenefitCol1,
  BenefitCol2,
  BenefitCol3,
} from "./ComparePlan.style";

import { PlanTableProps } from "./types";
import { FormattedText, SpanTag } from "@tm/static";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";

const PlanTable = ({ data, quoteID1, quoteID2 }: PlanTableProps) => {
  const device = useBreakpointV2();
  return (
    <>
      {data?.header && (
        <BenefitRow lastRow>
          <BenefitCol1></BenefitCol1>
          <BenefitCol2 firstRow>
            <SpanTag fontSize="xs" fontWeight="black" color={theme.purpleTxt}>
              {data?.header?.[quoteID1] && (
                <FormattedText value={data?.header?.[quoteID1]} />
              )}
            </SpanTag>
          </BenefitCol2>
          <BenefitCol3 firstRow>
            <SpanTag fontSize="xs" fontWeight="black" color={theme.berryTxt}>
              {data?.header?.[quoteID2] && (
                <FormattedText value={data?.header?.[quoteID2]} />
              )}
            </SpanTag>
          </BenefitCol3>
        </BenefitRow>
      )}
      {data?.body &&
        data?.body.map((item: any, index: number) => {
          return (
            <React.Fragment>
              {item.type == "benefit" ? (
                <PlanBenefit
                  key={index}
                  benefitData={item}
                  quoteID1={quoteID1}
                  quoteID2={quoteID2}
                />
              ) : (
                <BenefitDescHeader>
                  <BenefitCol1>
                    <SpanTag
                      fontSize="xs"
                      color={device == "lg" ? theme.blackText : theme.greyText7}
                      fontWeight={device == "lg" ? "black" : "bold"}
                      textTransform={device == "sm" ? "uppercase" : undefined}
                    >
                      {item.label.text}
                    </SpanTag>
                  </BenefitCol1>
                  <BenefitCol2></BenefitCol2>
                  <BenefitCol3></BenefitCol3>
                </BenefitDescHeader>
              )}
            </React.Fragment>
          );
        })}
    </>
  );
};

export default PlanTable;
