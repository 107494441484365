import React, { useState } from "react";
import {
  CheckButton,
  FlexComp,
  Tags,
  ParaTag,
  ImageComponent,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { PlanTypeStyle } from "./FilterPlans.style";

interface FilterPlansProp {
  data?: {
    id: number;
    planTitle?: string;
    planInfo?: string;
    planIcon?: string;
    isSelected?: boolean;
  }[];
  tagData?: {
    id?: number;
    name?: string;
    isSelected?: boolean;
  }[];
  title?: string;
  handleClickTab?: (index: number) => void;
}

const PlanTypeComp = ({
  title,
  data,
  tagData,
  handleClickTab,
}: FilterPlansProp) => {
  const [planData, setPlanData] = useState(data);

  const planTypeHandler = (id: number) => {
    setPlanData(
      planData?.map((item) => {
        if (id === item.id) {
          item.isSelected = !item.isSelected;
        }
        return item;
      })
    );
  };
  return (
    <PlanTypeStyle>
      {title && (
        <ParaTag
          fontSize="md"
          fontWeight="bold"
          color={theme.blackText}
          margin="0 0 16px"
        >
          {title}
        </ParaTag>
      )}
      {tagData && <Tags handleClickTab={handleClickTab} data={tagData} />}
      {data &&
        data.map((item, index: number) => {
          return (
            <CheckButton
              // inputIndex={item.id}
              inputId={item.id}
              margin={index != data.length - 1 ? "0 0 16px" : ""}
              isChecked={item.isSelected}
              handleChange={() => planTypeHandler(item.id)}
            >
              <FlexComp gap="10px" justifyContent="space-between">
                <FlexComp gap="0" flexDirection="column">
                  <ParaTag
                    fontSize="md"
                    color={theme.blackText}
                    margin="0 0 4px"
                  >
                    {item.planTitle}
                  </ParaTag>
                  {item.planInfo && (
                    <ParaTag fontSize="xs" color={theme.greyText7}>
                      {item.planInfo}
                    </ParaTag>
                  )}
                </FlexComp>
                {item.planIcon && (
                  <ImageComponent
                    width={"24px"}
                    height={"24px"}
                    src={item.planIcon}
                  />
                )}
              </FlexComp>
            </CheckButton>
          );
        })}
    </PlanTypeStyle>
  );
};

export default PlanTypeComp;
