import React, { MutableRefObject } from "react";

import { SpanTag, FlexComp, FormattedText } from "@tm/static";
import { BlueChev } from "../../icons";
import { theme } from "@tm/utility/global";
import {
  TravelDateWigetStyle,
  DateSeparator,
  ChevStateWrap,
} from "./TravellingToWidget.style";
import { LONG_WEEK_DAYS, MONTHS } from "@tm/static/utils";
export { LONG_WEEK_DAYS, MONTHS } from "../../utils/index";
import { TextNodeType } from "../FormattedText/FormattedText";

interface TravelDateWigetProps {
  showTravDate?: boolean;
  handleTravDate?: () => void;
  startDate?: Date | null;
  endDate?: Date | null;
  ref?: (instance: HTMLDivElement | null) => void;
  travDateTitle?: string | TextNodeType;
}

const TravelDateWiget = ({
  showTravDate,
  handleTravDate,
  ref,
  travDateTitle,
  startDate,
  endDate,
}: TravelDateWigetProps) => {
  return (
    <TravelDateWigetStyle ref={ref} onClick={handleTravDate}>
      <FlexComp gap="8px">
        {travDateTitle && (
          <SpanTag
            textTransform="uppercase"
            color={showTravDate ? theme.blueText : theme.greyText}
            fontWeight={"bold"}
            fontSize="xs"
          >
            <FormattedText value={travDateTitle} />
          </SpanTag>
        )}
        <ChevStateWrap open={showTravDate}>
          <BlueChev />
        </ChevStateWrap>
      </FlexComp>
      <FlexComp gap="4px">
        <FlexComp isCursorPointer flexDirection="column" gap="0">
          <SpanTag fontSize="md" fontWeight="black">
            {startDate?.getDate()} &nbsp;
            {startDate
              ? MONTHS[parseInt(startDate?.getMonth().toString().slice(-2))]
              : null}
            &nbsp;
            <SpanTag fontSize="sm" fontWeight="regular">
              '{startDate?.getFullYear().toString().slice(-2)}
            </SpanTag>
          </SpanTag>
          <SpanTag fontSize="xs" color={theme.greyText6}>
            {startDate && LONG_WEEK_DAYS[startDate.getDay()]}
          </SpanTag>
        </FlexComp>
        <DateSeparator>-to-</DateSeparator>
        <FlexComp isCursorPointer flexDirection="column" gap="0">
          <SpanTag fontSize="md" fontWeight="black">
            {endDate
              ? endDate.getDate()
              : startDate
              ? startDate.getDate() ===
                new Date(
                  startDate.getFullYear(),
                  startDate.getMonth() + 1,
                  0
                ).getDate()
                ? 1
                : startDate.getDate() + 1
              : null}
            &nbsp;
            {endDate
              ? MONTHS[parseInt(endDate.getMonth().toString().slice(-2))]
              : startDate
              ? startDate.getDate() ===
                new Date(
                  startDate.getFullYear(),
                  startDate.getMonth() + 1,
                  0
                ).getDate()
                ? MONTHS[
                    parseInt((startDate.getMonth() + 1).toString().slice(-2))
                  ]
                : MONTHS[parseInt(startDate.getMonth().toString().slice(-2))]
              : null}
            &nbsp;
            <SpanTag fontSize="sm" fontWeight="regular">
              '
              {endDate
                ? endDate.getFullYear().toString().slice(-2)
                : startDate
                ? startDate.getDate() ===
                  new Date(
                    startDate.getFullYear(),
                    startDate.getMonth() + 1,
                    0
                  ).getDate()
                  ? startDate.getMonth() === 11
                    ? (startDate.getFullYear() + 1).toString().slice(-2)
                    : startDate.getFullYear().toString().slice(-2)
                  : startDate.getFullYear().toString().slice(-2)
                : null}
            </SpanTag>
          </SpanTag>
          <SpanTag fontSize="xs" color={theme.greyText6}>
            {endDate
              ? LONG_WEEK_DAYS[endDate.getDay()]
              : startDate
              ? startDate.getDate() ===
                new Date(
                  startDate.getFullYear(),
                  startDate.getMonth() + 1,
                  0
                ).getDate()
                ? LONG_WEEK_DAYS[
                    new Date(
                      startDate.getFullYear(),
                      startDate.getMonth() + 1,
                      1
                    ).getDay()
                  ]
                : LONG_WEEK_DAYS[startDate.getDay()]
              : null}
          </SpanTag>
        </FlexComp>
      </FlexComp>
    </TravelDateWigetStyle>
  );
};

export default TravelDateWiget;
