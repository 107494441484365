import React, { useEffect, useRef, useState } from "react";
import DescriptionModal from "../DescriptionModal/DescriptionModal";
import { theme } from "@tm/utility/global";
import WebToast from "../Toast/WebToast";
import {
  SelectCountryModalV2,
  SpanTag,
  H5Tag,
  LandingButton,
  TravellingToWidget,
  SectionDividers,
  ParaTag,
  FormattedText,
  SelectCountryModalSheetV2,
  FlexComp,
} from "@tm/static";
import {
  removeSessionStorageItem,
  setSessionStorageItem,
  getSessionStorageItem,
  useBreakpointV2,
} from "@tm/ui-widgets";
import { navigationActions } from "@tm/insurance/navigation";
import {
  replaceHistory,
  replaceHistoryRemoveParams,
} from "@tm/insurance/utils";
import { STORAGE_KEYS } from "@tm/insurance/constants";
import { getAnalytics } from "@tm/insurance/analytics";

import PopularCountryComp from "./PopularCountryCompV2";
import UpcomingTrips from "./UpcomingTrips";

import { ITravellingTo } from "./types";
import { convertDateToUnix } from "../../utils";

import {
  TravellingToStyle,
  TravelNote,
  TravellingToStyleWrap,
  DiscountingNote,
} from "./TravelDetails.style";
import { usePartnerContext } from "@tm/static/partnerContext";

const analytics = getAnalytics();

const TravellingTo = ({
  travellingToSection,
  upcomingTrip,
  calendarDatesLimit,
  travelSearchBanner,
  btnDisabledState = true,
  nextPageRoute,
}: ITravellingTo) => {
  const device = useBreakpointV2();
  const context = usePartnerContext();
  const {
    otherCountryTxt,
    travelNote,
    travDateTitle,
    travCountTitle,
    travPurpTitle,
    travelPurposeTxt,
    popularCountryTxt,
    travelBtnTxt,
    travelSearchForm,
  } = travellingToSection || {};

  const { label, error_message, countrySelectErrMsg, placeHolder, value } =
    travelSearchForm.regionCountryList;

  const [popularCountries, setPopularCountries] = useState<any[]>([]);
  const [countryData, setCountryData] = useState<any[]>([]);
  const [showOtherCountry, setOtherCountry] = useState(false);
  const [countValue, setCountValue] = useState<number>(0);
  const [calenderValue, setCalenderValue] = useState<any>();
  const [showCountry, setShowCountry] = useState<any>([]);
  const [isCountryError, setIsCountryError] = useState(false);
  const [descriptionTxt, setDescriptionTxt] = useState<string>("");
  const [isSelectedError, setIsSelectedError] = useState<boolean>(false);
  const [descTitle, setDescTitle] = useState<string>("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const selectedCountries = queryParams.get("countries");
    let selectedCountryCodes: string | string[] | undefined;

    if (selectedCountries) {
      selectedCountryCodes = selectedCountries?.split(",");
    } else if (upcomingTrip?.countryCode) {
      selectedCountryCodes = upcomingTrip?.countryCode;
      replaceHistory("countries", upcomingTrip?.countryCode);
    }
    let totalCountryData = value.map((data: any) => {
      if (selectedCountryCodes?.includes(data.code)) {
        return { ...data, isSelected: true };
      }
      return { ...data };
    });
    const selectedRegionCodes = totalCountryData
      .filter((data: any) => data.isSelected && data.type === "REGION")
      .map((data: any) => data.code);

    selectedRegionCodes.map((code: string) => {
      totalCountryData = totalCountryData.map((country: any) => {
        if (country.regionCodes && country.regionCodes.includes(code)) {
          return { ...country, isDisabled: true };
        }
        return { ...country };
      });
    });
    setCountryData(totalCountryData);
    let popularCountryData = totalCountryData.filter(
      (country: any) => country.isSelected || country.isPopular
    );

    popularCountryData = [
      ...popularCountryData.filter(
        (country: any) => country.isSelected && !country.isDisabled
      ),
      ...popularCountryData.filter((country: any) => country.isDisabled),
      ...popularCountryData.filter(
        (country: any) => !country.isSelected && !country.isDisabled
      ),
    ];
    setShowCountry([...popularCountryData]);
    setPopularCountries([...popularCountryData]);
  }, [value, upcomingTrip]);

  const populateTravelDetails = (
    countryCodes: any,
    startDate?: string,
    endDate?: string,
    travellers?: string
  ) => {
    if (countryCodes) {
      replaceHistory("countries", countryCodes);
    }
    if (startDate) {
      replaceHistory("startDate", startDate);
    }
    if (endDate) {
      replaceHistory("endDate", endDate);
    }
    if (travellers) {
      replaceHistory("travellers", travellers);
    }
  };

  const handleUpcomingTripClick = () => {
    replaceHistory("isUpcomingTrip", "true");
    removeSessionStorageItem(STORAGE_KEYS.TRAVELLER_DETAILS);

    analytics?.trackLandingEvents({
      actionType: "upcoming_trips_clicked",
    });

    const { countryCode, travelDuration, travellerNum } = upcomingTrip || {};

    const selectedCountry = countryData?.find(
      (country: any) => country.code === countryCode
    );

    populateTravelDetails(
      selectedCountry?.code,
      travelDuration?.startDate?.toString(),
      travelDuration?.endDate?.toString(),
      travellerNum?.toString()
    );

    if (nextPageRoute) {
      navigationActions.navigateToPageByRouteName(
        `${nextPageRoute}&isUpcomingTrip=true`
      );
    } else {
      const data = {
        startDate: travelDuration?.startDate?.toString(),
        endDate: travelDuration?.endDate?.toString(),
        travellers: travellerNum?.toString?.(),
        countries: [countryCode],
        isUpcomingTrip: true,
      };
      navigationActions.navigateToListing(data);
    }
  };

  const handleShowOtherCountry = () => {
    if (!showOtherCountry) {
      let countryDataArr = countryData?.map((country: any) => {
        return { ...country, isSelected: false, isDisabled: false };
      });
      popularCountries.map((data: any) => {
        countryDataArr = countryDataArr?.map((country: any) => {
          if (country.code === data.code) {
            return {
              ...country,
              isSelected: data.isSelected,
            };
          }
          return { ...country };
        });
      });
      const selectedRegionCodes = countryDataArr
        .filter((data: any) => data.isSelected && data.type === "REGION")
        .map((data: any) => data.code);

      selectedRegionCodes.map((code: string) => {
        countryDataArr = countryDataArr.map((country: any) => {
          if (country.regionCodes && country.regionCodes.includes(code)) {
            return { ...country, isDisabled: true };
          }
          return { ...country };
        });
      });
      setCountryData(countryDataArr);
    }
    setOtherCountry(!showOtherCountry);
  };

  const handleDescriptionModal = (country: any) => {
    const clickedTime = getSessionStorageItem("clickedTime")
      ? getSessionStorageItem("clickedTime")
      : {};
    const currentTime = new Date().getTime();
    const code = country.code;
    if (
      !clickedTime[country.code] ||
      currentTime - parseInt(clickedTime[country.code], 10) >= 1800000
    ) {
      clickedTime[code] = currentTime.toString();
      setSessionStorageItem("clickedTime", {
        ...clickedTime,
      });
      return country.description;
    }
    return "";
  };

  const handlePopularCountrySelect = (code: string) => {
    setIsCountryError(false);
    let popularCountryArr = popularCountries?.map((country: any) => {
      if (country.code === code) {
        if (country["isSelected"]) {
          country["isSelected"] = false;
          return { ...country };
        }
        if (country.type === "REGION") {
          setDescriptionTxt(handleDescriptionModal(country));
          setDescTitle(country.descTitle);
        }
        return { ...country, isSelected: true, isDisabled: false };
      }
      return { ...country, isDisabled: false };
    });

    const selectedPopRegionCodes = popularCountryArr
      .filter((country: any) => country.isSelected && country.type === "REGION")
      .map((country: any) => {
        return country.code;
      });

    selectedPopRegionCodes.map((item: any) => {
      popularCountryArr = popularCountryArr.map((country: any) => {
        if (country.regionCodes && country.regionCodes.includes(item)) {
          return { ...country, isDisabled: true };
        }
        return { ...country };
      });
    });
    setPopularCountries(popularCountryArr);
    setShowCountry(popularCountryArr);
    const countries: string = popularCountryArr
      .filter((data: any) => data.isSelected && !data.isDisabled)
      .map((data: any) => data.code)
      .join(",");
    if (countries) {
      replaceHistory("countries", countries);
    } else {
      replaceHistoryRemoveParams("countries");
    }
  };

  const onSubmit = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(searchParams.entries());

    const countries = searchParams.get("countries");
    const isUpcomingTrip = searchParams.get("isUpcomingTrip");

    const countryCodes = countries?.split(",");

    if (!countryCodes) {
      setIsCountryError(true);
      analytics?.trackLandingEvents({
        actionType: "error_shown",
        payload: error_message,
      });
    }

    if (isUpcomingTrip) {
      replaceHistoryRemoveParams("isUpcomingTrip");
      removeSessionStorageItem(STORAGE_KEYS.TRAVELLER_DETAILS);
    }

    if (calenderValue && countValue && countryCodes?.length) {
      const selectedPopCountries = (popularCountries ?? [])
        .filter((country) => countryCodes?.includes(country.code))
        .map((item) => item.name)
        .join("|");

      analytics?.trackLandingEvents({
        actionType: "popular_country_selected",
        payload: selectedPopCountries,
      });

      const params = {
        ...queryParams,
        startDate: convertDateToUnix(calenderValue.from).toString(),
        endDate: calenderValue.to
          ? convertDateToUnix(calenderValue.to).toString()
          : searchParams.get("endDate"),
        travellers: countValue,
        countries: countryCodes,
      };

      analytics?.trackLandingEvents({
        actionType: "explore_plan_click",
      });

      navigationActions.navigateToListing(params);
    }
  };

  const [scrollPerformed, setScrollPerformed] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const button = buttonRef.current;

    if (button) {
      const isButtonInView =
        button.getBoundingClientRect().bottom < window.innerHeight; //button is in view returns true

      if (!isButtonInView && scrollPerformed == false) {
        const buttonVisible = device === "lg" ? 20 : 200;
        const scrollAmount =
          button.getBoundingClientRect().bottom -
          window.innerHeight +
          buttonVisible;

        button.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        window.scrollBy(0, scrollAmount);
        setScrollPerformed(true);
      }
    }
  }, []);

  return (
    <TravellingToStyleWrap>
      {travelSearchBanner ? (
        <DiscountingNote pageTheme={context ? context : "mmt"}>
          {travelSearchBanner?.icon && (
            <img
              width="24px"
              height="24px"
              src={travelSearchBanner?.icon}
              style={{
                animation: "rotate 2s linear infinite",
              }}
              loading="eager"
            />
          )}
          <FlexComp
            flexDirection={device === "sm" ? "column" : "row"}
            margin="0px 0px 0px 4px"
            gap={device === "lg" ? "4px" : "0px"}
          >
            {travelSearchBanner?.title && (
              <FormattedText
                value={travelSearchBanner?.title}
                baseStyle={{
                  fontWeight: "bold",
                  fontSize: device === "lg" ? "16px" : "12px",
                  color: theme.greenText5,
                }}
              />
            )}
            {travelSearchBanner?.description && (
              <FormattedText
                value={travelSearchBanner?.description}
                baseStyle={{
                  fontWeight: "regular",
                  fontSize: device === "lg" ? "14px" : "12px",
                  marginTop: device === "lg" ? "2px" : "0px",
                  color: theme.greenText5,
                }}
              />
            )}
          </FlexComp>
        </DiscountingNote>
      ) : (
        <TravelNote pageTheme={context ? context : "mmt"}>
          <FormattedText value={travelNote} />
        </TravelNote>
      )}

      <TravellingToStyle>
        <H5Tag color={theme.blackText}>
          <FormattedText value={label} />
        </H5Tag>
        <ParaTag margin="0px 0px 20px 0px">
          <FormattedText value={placeHolder} baseStyle={{ color: "#717171" }} />
        </ParaTag>
        {upcomingTrip && (
          <UpcomingTrips
            country={upcomingTrip?.country}
            countryCode={upcomingTrip?.countryCode}
            travelDuration={upcomingTrip?.travelDuration}
            travellerNum={upcomingTrip?.travellerNum}
            travellerTitle={upcomingTrip?.travellerTitle}
            handleUpcomingTripClick={handleUpcomingTripClick}
          />
        )}

        <SpanTag
          color={theme.greyText7}
          fontWeight="bold"
          fontSize="xs"
          margin="2px 0 8px"
        >
          {popularCountryTxt && <FormattedText value={"Popular Destination"} />}
        </SpanTag>

        <PopularCountryComp
          otherCountryTxt={otherCountryTxt}
          handleShowOtherCountry={handleShowOtherCountry}
          popCountry={showCountry}
          setIsSelectedError={setIsSelectedError}
          handlePopularCountrySelect={handlePopularCountrySelect}
        />

        {isCountryError && (
          <ParaTag
            color={theme.redText2}
            fontSize="xs"
            margin={device === "sm" ? "0 0 8px" : "8px 0 0"}
          >
            {error_message}
          </ParaTag>
        )}

        {device == "lg" && (
          <SectionDividers
            margin="14px -30px 14px"
            backgroundColor={"#FFFFFF"}
            xs
          />
        )}

        <TravellingToWidget
          travelPurposeTxt={travelPurposeTxt}
          travCountTitle={travCountTitle}
          travPurpTitle={travPurpTitle}
          travDateTitle={travDateTitle}
          btnDisabledState={btnDisabledState}
          setCountValue={setCountValue}
          calendarDatesLimit={calendarDatesLimit}
          setCalenderValue={setCalenderValue}
          upcomingTravellerNum={upcomingTrip?.travellerNum}
          upcomingFromDate={upcomingTrip?.travelDuration?.startDate}
          upcomingToDate={upcomingTrip?.travelDuration?.endDate}
        />

        {travelBtnTxt && (
          <LandingButton
            buttonref={buttonRef}
            customWidth="100%"
            customTitle={travelBtnTxt}
            onClick={onSubmit}
          />
        )}
        <ParaTag color={theme.greyText} margin="16px 0 0">
          {travelSearchForm?.disclaimer}
        </ParaTag>
        {showOtherCountry && device == "lg" && (
          <SelectCountryModalV2
            countryData={countryData}
            handleSelectCountry={handleShowOtherCountry}
            setCountryData={setCountryData}
            setShowCountry={setShowCountry}
            showCountry={showCountry}
            setIsCountryError={setIsCountryError}
            popularCountries={popularCountries}
            setPopularCountries={setPopularCountries}
            customSubTitle={placeHolder}
            countrySelectErrMsg={countrySelectErrMsg}
            handleDescriptionModal={handleDescriptionModal}
          />
        )}
        {showOtherCountry && device == "sm" && (
          <SelectCountryModalSheetV2
            countryData={countryData}
            showCountry={showCountry}
            handleSelectCountry={handleShowOtherCountry}
            setCountryData={setCountryData}
            setShowCountry={setShowCountry}
            setIsCountryError={setIsCountryError}
            popularCountries={popularCountries}
            setPopularCountries={setPopularCountries}
            customSubTitle={placeHolder}
            countrySelectErrMsg={countrySelectErrMsg}
            handleDescriptionModal={handleDescriptionModal}
          />
        )}
        {descriptionTxt && (
          <DescriptionModal
            handleDescriptionModal={() => setDescriptionTxt("")}
            value={descriptionTxt}
            title={descTitle}
          />
        )}
        {isSelectedError && device === "lg" && (
          <WebToast
            toastTxt={countrySelectErrMsg}
            position="fixed"
            height="46px"
            width="320px"
            bottom="100px"
            right="50px"
          />
        )}
        {isSelectedError && device === "sm" && (
          <WebToast
            toastTxt={countrySelectErrMsg}
            position="fixed"
            height="40px"
            bottom="10px"
          />
        )}
      </TravellingToStyle>
    </TravellingToStyleWrap>
  );
};

export default TravellingTo;
