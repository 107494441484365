import styled from "styled-components";

import { deviceType } from "@tm/static/utils";

export const PoweredByContainer = styled.div`
  border-left: 1px #e2e2e2 solid;
  padding-left: 32px;

  & > ul {
    display: flex;
    align-items: center;
  }

  & > ul > li {
    display: flex;
    align-items: center;
  }

  & > ul > li:not(:last-child) {
    margin-right: 4px;
  }

  @media screen and ${deviceType.mobile} {
    height: auto;
    overflow-y: unset;
  }
`;
