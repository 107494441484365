interface ChevronDownIconProps {}

export const ChevronDownIcon = (props: ChevronDownIconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8583 13.3941C11.9364 13.4722 12.063 13.4722 12.1411 13.3941L16.2423 9.29289C16.6329 8.90237 17.266 8.90237 17.6566 9.29289C18.0471 9.68342 18.0471 10.3166 17.6566 10.7071L12.7068 15.6569C12.3163 16.0474 11.6831 16.0474 11.2926 15.6569L6.34285 10.7071C5.95232 10.3166 5.95232 9.68342 6.34285 9.29289C6.73337 8.90237 7.36654 8.90237 7.75706 9.29289L11.8583 13.3941Z"
      fill="#008CFF"
    />
  </svg>
);
