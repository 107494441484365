import { PageNameMapping, UTM_PARAMS_KEY } from "../../constants";
import { getPokusExpVersion } from "../../utils";
import { getCookie } from "../../utils/cookieUtils";

interface ExperimentDetail {
  exp_experiment_variant_id: ReturnType<typeof getPokusExpVersion>;
  exp_experiment_version: number | null | undefined;
}

type ExperimentDetailsList = ExperimentDetail[];

export const getPdtErrorData = (
  error: any,
  description: string = "",
  user_details: any,
  device: any,
  context: any,
  exp_experiment_details_list: ExperimentDetailsList
) => {
  const errorLog = error ? error : false;
  return [
    {
      context: context,
      user_details: user_details ? user_details : null,
      device: device ? device : null,
      page: {
        url: window.location.href || null,
        error: errorLog ? "true" : null,
        error_details: errorLog
          ? {
              api_url: errorLog?.apiUrl || null,
              code: errorLog
                ? errorLog.code
                  ? errorLog.code
                  : null
                : "NO_CODE_FOUND",
              description: errorLog
                ? errorLog.errorTitle ||
                  errorLog.errorDescription ||
                  errorLog.description ||
                  errorLog.message ||
                  description
                : "Unknown error",
            }
          : null,
        utm: getUtmPdtObj(),
        page_source: PageNameMapping.LISTING_PAGE,
      },
      exp_experiment_details_list,
    },
  ];
};

export const setPdtCtaAndCommonPdtData = (
  user_details: any,
  device: any,
  ctaData: any,
  context: any,
  version: number | null | undefined,
  partner: string | null | undefined,
  pageName?: string
) => {
  const exp_experiment_details_list = [
    {
      exp_experiment_variant_id: getPokusExpVersion({
        partner,
      }),
      exp_experiment_version: version,
    },
  ];
  return [
    {
      context: context,
      page: {
        url: window.location.href || null,
        utm: getUtmPdtObj(),
        page_source: PageNameMapping.LISTING_PAGE,
      },
      user_details: user_details ? user_details : null,
      device: device ? device : null,
      cta: {
        label: ctaData?.label ? ctaData.label : null,
        cta_info: {
          primary_value: ctaData?.primary_value || null,
          secondary_value: ctaData?.secondary_value || null,
          others: ctaData?.others ? ctaData.others : null,
        },
      },
      exp_experiment_details_list,
    },
  ];
};

export const setPdtEventData = (
  user_details: any,
  device: any,
  otherData: any,
  context: any,
  eventCta: string,
  exp_experiment_details_list: ExperimentDetailsList | null = null
) => {
  return [
    {
      context: context,
      page: {
        url: window.location.href || null,
        utm: getUtmPdtObj(),
        page_source: PageNameMapping.LISTING_PAGE,
      },
      user_details: user_details ? user_details : null,
      device: device ? device : null,
      cta: {
        label: eventCta ? eventCta : null,
      },
      exp_experiment_details_list: exp_experiment_details_list
        ? exp_experiment_details_list
        : null,
      ...otherData,
    },
  ];
};

export const getPdtOtherDataSchema = () => {
  return {
    funnel_details: {
      delivery_details: {
        latitude: null,
        longitude: null,
        location_source: null,
        pincode: null,
        city: null,
        location_permission: null,
      },
      forex_listing_details: {
        persuasions: {
          delivery_tracking: {
            is_visible: null,
          },
          backend: {
            is_visible: null,
          },
          drop_off: {
            is_visible: null,
          },
          mini_dashboard: {
            is_visible: null,
          },
          upload_docs: {
            is_visible: null,
          },
        },
        unavailable_Location: {
          is_visible: null,
        },
        default: {
          is_visible: null,
        },
      },
      rate_ticker: {
        currencies: null,
      },
    },
  };
};

export const setPdtLocationDetectedSchema = (
  locationSource: any,
  permissionDetails: any,
  delivery_details: any
) => {
  return {
    latitude: `${delivery_details?.latitude}` || null,
    longitude: `${delivery_details?.longitude}` || null,
    location_source: locationSource || null,
    pincode: `${delivery_details?.pincode}` || null,
    city: `${delivery_details?.city}` || null,
    location_permission: permissionDetails || null,
  };
};

function getUtmPdtObj() {
  return {
    medium: getCookie(UTM_PARAMS_KEY.UTM_MEDIUM, false, { path: "/" }) || null,
    source: getCookie(UTM_PARAMS_KEY.UTM_SOURCE, false, { path: "/" }) || null,
    campaign:
      getCookie(UTM_PARAMS_KEY.UTM_CAMPAIGN, false, { path: "/" }) || null,
  };
}
