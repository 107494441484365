import React, { useState } from "react";
import {
  SpanTag,
  ShimmerVersion2,
  FlexComp,
  ShimmerVersion3,
  FormattedText,
} from "@tm/static";

import {
  InsurancePlansCompStyle,
  PlanHeader,
  InsurancePlanSection,
} from "./InsurancePlans.style";
import { insurancePlanCompType } from "./type";
import { useBreakpointV2 } from "@tm/ui-widgets";

const InsurancePlansCompShimmer = ({ data }: insurancePlanCompType) => {
  const device = useBreakpointV2();
  return (
    <InsurancePlansCompStyle>
      <PlanHeader>
        {data?.packName && (
          <SpanTag
            // color={data?.packNameColor}
            fontSize="sm"
            fontWeight="bold"
            textTransform="uppercase"
          >
            <FormattedText value={data?.packName} />
          </SpanTag>
        )}
      </PlanHeader>
      <InsurancePlanSection>
        <FlexComp
          gap="16px"
          margin="0 0 16px"
          alignItems="center"
          justifyContent="space-between"
        >
          <div>
            <ShimmerVersion2
              width={device == "lg" ? "248px" : "200px"}
              height="22px"
              margin="0 0 6px"
            />
            <ShimmerVersion2
              width={device == "lg" ? "248px" : "200px"}
              height="16px"
            />
          </div>
          <ShimmerVersion3
            width={device == "lg" ? "120px" : "100px"}
            height="40px"
          />
        </FlexComp>
        <ShimmerVersion2 width="100%" height="150px" />
      </InsurancePlanSection>
    </InsurancePlansCompStyle>
  );
};

export default InsurancePlansCompShimmer;
