import { theme } from "@tm/utility/global";
import styled from "styled-components";
import { PartnerType } from "../../constants";
import { PARTNER } from "@tm/insurance/constants";

export interface TextStyleProps {
  margin?: string;
  txtfontSize?: string;
  txtfontWeight?: string;
  textAlign?: string;
  lineHeight?: string;
  textTransform?: string;
  txtfontFamily?: string;
  txtcolor?: string;
  disableState?: boolean;
  context?: PartnerType;
}

const renderFont = (txtfontSize: string) => {
  switch (txtfontSize) {
    case "xxs":
      return `
        font-size: 10px;
        line-height: 16px;
      `;
    case "xs":
      return `
        font-size: 12px;
        line-height: 16px;
      `;
    case "sm":
      return `
        font-size: 0.875rem;
        line-height: 20px;
      `;
    case "md":
      return `
        font-size: 1em;
        line-height: 22px;
      `;
    case "large":
      return `
        font-size: 18px;
        line-height: 24px;
      `;
    case "xlarge":
      return `
        font-size: 1.375rem;
        line-height: 26px;
      `;
    case "xxlarge":
      return `
        font-size: 1.5em;
        line-height: 32px;
      `;
    case "xxxlarge":
      return `
        font-size: 1.75em;
        line-height: 32px;
      `;
    default:
      return `
        font-size: 0.875em;
        line-height: 20px;
      `;
  }
};

const renderFontWeight = (txtfontWeight: string, context?: PartnerType) => {
  switch (txtfontWeight) {
    case "regular":
      return `
          font-weight: 400;
        `;
    case "bold":
      return `
          ${context === PARTNER.MMT ? "font-weight: 700" : "font-weight: 700"};
      `;
    case "black":
      return `
           ${context === PARTNER.MMT ? "font-weight: 900" : "font-weight: 700"};
        `;
    default:
      return `
      font-weight: 400;
    `;
  }
};

export const H1TagStyle = styled.h1<TextStyleProps>`
  font-size: ${(p) => (p.context === PARTNER.MMT ? "56px" : "32px")}; /* 56px */
  line-height: ${(p) => (p.context === PARTNER.MMT ? "65px" : "37px")};
  font-weight: ${(p) => (p.context === PARTNER.MMT ? "900" : "700")};
  margin: ${(p) => (p.margin ? p.margin : "")};
  color: ${(p) => (p.txtcolor ? p.txtcolor : "#000")};
  font-variant-numeric: lining-nums proportional-nums;
`;

export const H2TagStyle = styled.h2<TextStyleProps>`
  font-size: ${(p) => (p.context === PARTNER.MMT ? "36px" : "32px")}; /* 48px */
  line-height: ${(p) => (p.context === PARTNER.MMT ? "44px" : "37px")};
  font-weight: ${(p) => (p.context === PARTNER.MMT ? "900" : "700")};
  margin: ${(p) => (p.margin ? p.margin : "")};
  color: ${(p) => (p.txtcolor ? p.txtcolor : "#000")};
  font-variant-numeric: lining-nums proportional-nums;
`;

export const H3TagStyle = styled.h3<TextStyleProps>`
  font-size: ${(p) => (p.context === PARTNER.MMT ? "32px" : "24px")};
  line-height: ${(p) => (p.context === PARTNER.MMT ? "38px" : "28px")};
  font-weight: ${(p) => (p.context === PARTNER.MMT ? "900" : "700")};
  margin: ${(p) => (p.margin ? p.margin : "")};
  color: ${(p) => (p.txtcolor ? p.txtcolor : "#000")};
  text-align: var(--textAlign, left);
  font-variant-numeric: lining-nums proportional-nums;
`;

export const H4TagStyle = styled.h4<TextStyleProps>`
  font-size: ${(p) => (p.context === PARTNER.MMT ? "24px" : "22px")};
  line-height: ${(p) => (p.context === PARTNER.MMT ? "28px" : "26px")};
  font-weight: ${(p) => (p.context === PARTNER.MMT ? "900" : "700")};
  margin: ${(p) => (p.margin ? p.margin : "")};
  color: ${(p) => (p.txtcolor ? p.txtcolor : "#000")};
  font-variant-numeric: lining-nums proportional-nums;
`;

export const H5TagStyle = styled.h5<TextStyleProps>`
  font-size: ${(p) => (p.context === PARTNER.MMT ? "22px" : "18px")};
  line-height: ${(p) => (p.context === PARTNER.MMT ? "26px" : "21px")};
  font-weight: ${(p) => (p.context === PARTNER.MMT ? "900" : "700")};
  margin: ${(p) => (p.margin ? p.margin : "")};
  color: ${(p) => (p.txtcolor ? p.txtcolor : "#000")};
  font-variant-numeric: lining-nums proportional-nums;
`;

export const H6TagStyle = styled.h6<TextStyleProps>`
  font-size: ${(p) => (p.context === PARTNER.MMT ? "18px" : "16px")};
  line-height: ${(p) => (p.context === PARTNER.MMT ? "21px" : "18px")};
  font-weight: ${(p) => (p.context === PARTNER.MMT ? "900" : "700")};
  margin: ${(p) => (p.margin ? p.margin : "")};
  color: ${(p) => (p.txtcolor ? p.txtcolor : "#000")};
  font-variant-numeric: lining-nums proportional-nums;
`;

export const ParaTagStyle = styled.p<TextStyleProps>`
  ${(p) =>
    p.txtfontSize
      ? `
    ${renderFont(p.txtfontSize)}
  `
      : ``}
  ${(p) =>
    p.txtfontWeight &&
    `
    ${renderFontWeight(p.txtfontWeight, p.context ? p.context : undefined)}
  `}
    margin: ${(p) => p.margin && p.margin};
  text-align: ${(p) => p.textAlign && p.textAlign};
  color: ${(p) => p.txtcolor && p.txtcolor};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  text-transform: ${(p) => p.textTransform && p.textTransform};
  & > .boldText {
    font-weight: 700;
  }
  word-break: break-word;
`;

export const SpanTagStyle = styled.span<TextStyleProps>`
  ${(p) => (p.txtfontSize ? `${renderFont(p.txtfontSize)}` : `inherit`)}
  ${(p) =>
    p.txtfontWeight &&
    `
    ${renderFontWeight(p.txtfontWeight, p.context ? p.context : undefined)}
  `}
    display: inline-block;
  color: ${(p) => p.txtcolor && p.txtcolor};
  margin: ${(p) => p.margin && p.margin};
  text-transform: ${(p) => p.textTransform && p.textTransform};
  word-break: break-word;
`;

export const AnchorTagStyle = styled.a<TextStyleProps>`
  ${(p) => (p.txtfontSize ? `${renderFont(p.txtfontSize)}` : ``)}
  ${(p) =>
    p.txtfontWeight &&
    `
    ${renderFontWeight(p.txtfontWeight, p.context ? p.context : undefined)}
  `}
    margin: ${(p) => p.margin && p.margin};
  display: inline-block;
  color: ${(p) => (p.txtcolor ? p.txtcolor : theme.blueText)};
  text-align: ${(p) => p.textAlign && p.textAlign};
  text-transform: ${(p) => p.textTransform && p.textTransform};
  ${(p) =>
    p.disableState &&
    `
    opacity:0.5;
    pointer-events:none;
    `}
`;

export const CliptextStyleComp = styled.div<TextStyleProps>`
  background: linear-gradient(
    92.2deg,
    #ffe2e8 0.47%,
    #fffcfc 25.07%,
    #8cc8ea 64.68%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: fit-content;
  font-weight: 600;
  margin: ${(props) => props.margin && props.margin};
`;
