import React from "react";
import { ParaTag, AnchorTag, FlexComp, SpanTag } from "@tm/static";

import { CashBackStyle } from "./CashBackComp.style";
import { CASHBACK_STATUS, CASHBACK_TEXT } from "@tm/libs/types";
import { theme } from "@forex/Forex/style/Global.style";

interface CashBackProp {
  cashBackAmt?: string;
  statusCta?: string;
  cashbackStatus?: string;
  info?: string;
  showComp?: boolean;
}

const CashBackComp = ({
  cashBackAmt,
  info,
  cashbackStatus,
  statusCta,
  showComp,
}: CashBackProp) => {
  return (
    cashBackAmt &&
    cashbackStatus && (
      <CashBackStyle showComp={showComp} cashbackStatus={cashbackStatus}>
        <FlexComp gap="20px" justifyContent="space-between">
          <FlexComp flexWrap="wrap" gap="4px">
            <SpanTag fontSize="xs" fontWeight="bold">
              {cashBackAmt}
            </SpanTag>
            <SpanTag
              color={
                cashbackStatus === CASHBACK_STATUS.CANCELLED
                  ? theme.redText
                  : theme.darkGreen
              }
              fontSize="xs"
              fontWeight="bold"
            >
              {cashbackStatus ? CASHBACK_TEXT[cashbackStatus] || "" : ""}
            </SpanTag>
          </FlexComp>
          {statusCta && (
            <AnchorTag fontSize="xs" fontWeight="bold">
              {statusCta}
            </AnchorTag>
          )}
        </FlexComp>
        {info && (
          <ParaTag color={theme.greyText} fontSize="xs">
            {info}
          </ParaTag>
        )}
      </CashBackStyle>
    )
  );
};

export default CashBackComp;
