import styled from "styled-components";

export interface TagStyleProps {
  isSelected?: boolean;
}

export const TagsStyle = styled.div`
  display: flex;
  overflow-x: scroll;
  gap: 8px;
  flex-shrink: 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TagItem = styled.div<TagStyleProps>`
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  padding: 4px 10px;
  cursor: pointer;
  flex-shrink: 0;

  ${(p) =>
    p.isSelected &&
    `
background: #F8FBFF;
border: 1px solid #008CFF;
border-radius: 6px;
`}
`;
