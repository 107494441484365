import { useState } from "react";

import { useDevice } from "../../../hooks/useDevice";
import Modal from "../Modal/Modal";
import {
  AvailInstructions,
  CashbackSlabContainer,
  CashbackSlabHeadingRow,
  CashbackSlabRow,
  Container,
  // AvailInstructionsItems,
  CouponItem,
  // SlabItem,
  TncContainer,
  TncListItems,
} from "./CouponDetailsModal.style";
import { CloseIcon } from "../../icons/CloseIcon";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import { theme } from "../../css/Global";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";

type tncData = {
  id?: string;
  info?: string;
};

type applySteps = {
  id?: string;
  info?: string;
};

export type couponData = {
  id?: string;
  code?: string;
  details?: string;
  expiryDate?: string;
  expiryText?: string;
};

export type CashbackSlab = {
  id: string;
  range: string;
  cashback: string;
};

interface CouponDetailsModalProps {
  modalHeading?: string;
  onModalClose: () => void;
  title?: string;
  couponsData: couponData[] | [];
  tncData?: tncData[];
  availInstructions?: string;
  stepsToApply?: applySteps[] | [];
  cashbackSlabs?: CashbackSlab[] | [];
}

const CouponDetailsModal = ({
  modalHeading,
  onModalClose,
  title,
  couponsData,
  tncData,
  availInstructions,
  stepsToApply,
  cashbackSlabs,
}: CouponDetailsModalProps) => {
  const device = useDevice();

  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const renderCouponItem = (item: couponData) => {
    return (
      <CouponItem key={item?.id}>
        <ParaTag fontSize="sm" fontWeight="bold" color={theme.blackText}>
          {item?.code || ""}
        </ParaTag>
        <ParaTag
          fontSize="xs"
          color={theme.greyText}
          dangerousText={item?.details || ""}
          margin={"8px 0px 0px"}
        />
        <ParaTag fontSize="xs" color={theme.greyText6} margin={"4px 0px 0px"}>
          {item?.expiryText || ""}
        </ParaTag>
      </CouponItem>
    );
  };

  const renderHowToAvail = () => {
    return (
      <AvailInstructions>
        <ParaTag fontSize="sm" fontWeight="bold" color={theme.greyText6}>
          How to avail Offer
        </ParaTag>
        <ParaTag fontSize="xs" color={theme.greyText} margin={"8px 0px 0px"}>
          {availInstructions}
        </ParaTag>
        {/* <AvailInstructionsItems>
                    {availInstructions?.map(
                        (item) => item && <li id={item}>{item}</li>
                    )}
                </AvailInstructionsItems> */}
      </AvailInstructions>
    );
  };

  const renderTncSection = () => {
    return (
      <TncContainer>
        <ParaTag fontSize="sm" fontWeight="bold" color={theme.greyText6}>
          Terms & Conditions
        </ParaTag>
        <TncListItems>
          {tncData?.map((item) => (
            <li
              id={item.id}
              dangerouslySetInnerHTML={{ __html: item.info || "" }}
            />
          ))}
        </TncListItems>
      </TncContainer>
    );
  };

  const renderSteps = () => {
    return (
      <TncContainer id="steps_id">
        <ParaTag fontSize="sm" fontWeight="bold" color={theme.greyText6}>
          How to Apply
        </ParaTag>
        <TncListItems>
          {stepsToApply?.map((item) => (
            <li id={item.id}>{item.info}</li>
          ))}
        </TncListItems>
      </TncContainer>
    );
  };

  const renderSlabs = () => {
    const length = cashbackSlabs ? cashbackSlabs.length : 0;
    if (!length) {
      return <></>;
    }
    return (
      <TncContainer id="cashback_slabs_id">
        <ParaTag fontSize="sm" fontWeight="bold" color={theme.greyText6}>
          Cashback Slabs
        </ParaTag>
        <CashbackSlabContainer>
          <CashbackSlabHeadingRow>
            <div>
              <ParaTag textAlign={"center"} fontWeight="bold" fontSize="sm">
                Slabs (in INR)
              </ParaTag>
            </div>
            <div>
              <ParaTag textAlign={"center"} fontWeight="bold" fontSize="sm">
                Cashback (INR)
              </ParaTag>
            </div>
          </CashbackSlabHeadingRow>

          {cashbackSlabs?.map((item, index) => {
            return (
              <CashbackSlabRow
                key={item.id}
                borderBottom={index === length - 1 && "unset"}
              >
                <div>
                  <ParaTag textAlign={"center"} fontSize="xs">
                    {item.range}
                  </ParaTag>
                </div>
                <div>
                  <ParaTag textAlign={"center"} fontSize="xs">
                    {item.cashback}
                  </ParaTag>
                </div>
              </CashbackSlabRow>
            );
          })}
        </CashbackSlabContainer>
      </TncContainer>
    );
  };

  return (
    <Modal
      modalHeading={modalHeading}
      onModalClose={onModalClose}
      closeButton={<CloseIcon />}
      modalType={device === "lg" ? "centered" : "bottomSheet"}
    >
      <Container>
        <ParaTag fontSize="sm" color={theme.greyText6} fontWeight="bold">
          {title}
        </ParaTag>
        {renderCouponItem(couponsData[0])}
        {showAll &&
          couponsData?.slice(1)?.map((item) => renderCouponItem(item))}
        {couponsData?.length > 1 && (
          <AnchorTag
            fontSize="sm"
            fontWeight="bold"
            onClick={toggleShowAll}
            margin="8px 0px 0px"
          >
            {!showAll ? `+ ${couponsData.length - 1} More Offer` : "Show Less"}
          </AnchorTag>
        )}
        {availInstructions && renderHowToAvail()}
        {cashbackSlabs && renderSlabs()}
        {/* {stepsToApply && renderSteps()} */}
        {renderTncSection()}
      </Container>
    </Modal>
  );
};

export default CouponDetailsModal;
