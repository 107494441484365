import styled from "styled-components";
import { SpanTagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "@tm/static/utils";
import { CommonModalDwebStyle } from "../CommonModalDweb/CommonModalDweb.style";
import { ToastStyle } from "../Toast/Toast.style";

export interface TravellingToStyleProps {
  width?: string;
  travelCount?: boolean;
  open?: boolean;
  selected?: boolean;
  isDiabled?: boolean;
}

export const TravellingToWidgetStyle = styled.div`
  display: flex;
  margin-bottom: 24px;
  position: relative;
  @media ${deviceType.mobile} {
    flex-direction: column;
    ${CommonModalDwebStyle} {
      height: 300px;
    }
  }
  ${ToastStyle} {
    position: absolute;
    top: -20px;
    left: -20px;
    background: #005756;
    bottom: auto;
  }
`;

export const TravelPurposeWigetStyle = styled.div`
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  flex: 2;
  cursor: pointer;
  position: relative;
  border-left: none;
`;

export const TravellerCountWigetStyle = styled.div`
  background: #ffffff;
  border: 1px solid #e7e7e7;
  padding: 8px 16px;
  flex: 1.1;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  @media ${deviceType.mobile} {
    border-radius: 0 0 8px 8px;
  }
`;

export const TravellerCountWigetShimmerStyle = styled(TravellerCountWigetStyle)`
  display: flex;
  gap: 20px;
  align-items: end;
`;

export const TravelDateWigetStyle = styled.div`
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-right: none;
  flex: 1;
  cursor: pointer;
  position: relative;
  @media ${deviceType.mobile} {
    border-right: 1px solid #e7e7e7;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
  }
`;

export const TravelDateWigetShimmerStyle = styled(TravelDateWigetStyle)`
  display: flex;
  gap: 20px;
  align-items: end;
`;

export const WidgetList = styled.div<TravellingToStyleProps>`
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: ${(p) => (p.width ? p.width : "111px")};
  height: 213px;
  overflow-y: scroll;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 1;
  ${(p) =>
    p.travelCount &&
    `
left:250px;
`}
`;

export const ListItem = styled.div<TravellingToStyleProps>`
  padding: 8px 16px;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${(p) =>
    p.selected &&
    `
    ${SpanTagStyle}{
        color:#008CFF;
        font-weight:700;
    }
    `}
  @media ${deviceType.mobile} {
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
      margin-bottom: 60px;
    }
  }
`;

export const EmptySpace = styled.div`
  width: 100%;
  height: 60px;
  padding: 18px;
`;

export const ChevWrap = styled.div`
  margin-left: auto;
`;

export const DateSeparator = styled(SpanTagStyle)`
  flex-shrink: 0;
`;

export const ChevStateWrap = styled.div<TravellingToStyleProps>`
  transition: 0.5s ease-in;
  ${(p) =>
    p.open &&
    `
transform:rotate(180deg);
`}
`;

export const BtnWrap = styled.div`
  background: var(--base-white, #fff);
  box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0px;
  right: 0px;
`;

export const CounterWrap = styled.div`
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 0px 8px;
  display: flex;
  gap: 14px;
  align-items: center;
  width: fit-content;
  height: 40px;
  button {
    background: transparent;
    cursor: pointer;
    display: flex;
    padding: 0;
  }
  span {
    word-break: unset;
  }
`;

export const CounterBtn = styled.button<TravellingToStyleProps>`
  ${(p) =>
    p.isDiabled &&
    `
pointer-events:none;
opacity:0.5;
`}
`;
