interface TickIcon {}

const TickIcon = (props: TickIcon) => (
  <svg
    width={8}
    height={5}
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.4917 2.69399L3.02111 4.2234L6.58973 0.654785"
      stroke="white"
      strokeLinecap="round"
    />
  </svg>
);
export { TickIcon };
