import React from "react";
import { FormattedText, ImageComponent, SpanTag } from "@tm/static";
import { theme } from "@tm/utility/global";

import { BtnProp } from "./ButtonType";
import { LandingButtonTertiaryStyle } from "./LandingButton.style";

const LandingButtonTertiary = ({
  title,
  disableState,
  onClick,
  btnLeftIcon,
  btnRightIcon,
}: BtnProp) => {
  return (
    <LandingButtonTertiaryStyle onClick={onClick} disableState={disableState}>
      {btnLeftIcon && (
        <ImageComponent width="24px" height="24px" src={btnLeftIcon} />
      )}
      {title && (
        <SpanTag color={theme.blueText} fontSize="xs" fontWeight="bold">
          <FormattedText value={title} />
        </SpanTag>
      )}
      {btnRightIcon && (
        <ImageComponent
          width="16px"
          height="16px"
          margin="0 0 0 auto"
          src={btnRightIcon}
        />
      )}
    </LandingButtonTertiaryStyle>
  );
};

export default LandingButtonTertiary;
