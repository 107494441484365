import React from "react";
import { SpanTag, ParaTag, FormattedText, ImageComponent } from "@tm/static";
import {
  FilterBottomCompStyle,
  FilterLeftSection,
  FilterRightSection,
  FilterNumber,
} from "./FilterBottomComp.style";
import { theme } from "libs/ui-widgets/src/lib/static/css/Global";
import { TextNodeType } from "../FormattedText/FormattedText";
import FilterIcon from "../../icons/filterIcon.svg";

interface FilterProp {
  filtersList?: {
    title?: TextNodeType;
    planInfoMob?: TextNodeType;
  }[];
  handleFilterClick?: () => void;
}

const FilterBottomComp = ({ filtersList, handleFilterClick }: FilterProp) => {
  return (
    <FilterBottomCompStyle>
      <FilterLeftSection>
        {filtersList?.map((item, index) => {
          return (
            <div key={`${index}`}>
              {item.title && (
                <ParaTag
                  color={theme.whiteText}
                  fontWeight="bold"
                  fontSize="xs"
                >
                  <FormattedText value={item.title} />
                </ParaTag>
              )}
              {item.planInfoMob && (
                <SpanTag color={theme.whiteText} fontSize="xs">
                  (<FormattedText value={item.planInfoMob} />)
                </SpanTag>
              )}
            </div>
          );
        })}
      </FilterLeftSection>
      <FilterRightSection onClick={handleFilterClick}>
        <ImageComponent
          width="28px"
          height="28px"
          src="https://tripmoneycmsimgak.mmtcdn.com/img/filter_Icon_eab36b44ec.svg"
        />
        <FilterNumber>{filtersList?.length}</FilterNumber>
        <SpanTag fontSize="xs" fontWeight="bold" color={theme.whiteText}>
          Filter
        </SpanTag>
      </FilterRightSection>
    </FilterBottomCompStyle>
  );
};

export default FilterBottomComp;
