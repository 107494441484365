import styled, { keyframes } from "styled-components";
import { deviceType } from "@tm/static/utils";
import { SectionDividersStyle } from "../SectionDividers/SectionDividers.style";
import { AnchorTagStyle, H5TagStyle } from "../TextStyles/TextStyles.style";
import { TMInputWrap } from "../TMFloatingInput/TMFloatingInput.style";

const modalAnim = keyframes`
  0% {
    bottom: -100%;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
`;

type ModalStyleProps = {
  customWidth?: string;
  nonClipHeading?: boolean;
  customHeight?: string;
};

export const CommonModalDwebStyle = styled.div<ModalStyleProps>`
  position: fixed;
  width: ${(p) => (p.customWidth ? p.customWidth : "420px")};
  height: ${(p) => (p.customHeight ? p.customHeight : "420px")};
  max-height: 600px;
  min-height: 260px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #ffffff;
  border-radius: 12px;
  padding: 0px 30px 20px;
  z-index: 50;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  ${SectionDividersStyle} {
    margin: 0 -30px;
  }
  ${TMInputWrap} {
    position: sticky;
    top: 74px;
    z-index: 1;
  }
  @media ${deviceType.mobile} {
    width: 100%;
    bottom: 0;
    border-radius: 12px 12px 0 0;
    top: unset;
    animation: ${modalAnim} 0.3s ease-in-out;
    ${TMInputWrap} {
      top: 70px;
    }
  }
`;

export const CommonModalHeader = styled.div<ModalStyleProps>`
  position: sticky;
  top: 0;
  z-index: 1;
  margin-left: -30px;
  margin-right: -30px;
  background: #fff;
  padding: 20px 30px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  ${H5TagStyle} {
    display: flex;
    flex-direction: column;
    gap: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 81%;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
  ${(p) =>
    p.nonClipHeading &&
    `
     ${H5TagStyle} {
        white-space: unset;
        overflow: auto;
        width: 100%;
        span {
            overflow: auto;
            white-space: unset;
            max-width: 100%;
        }
    }
    `}
  @media ${deviceType.mobile} {
    font-size: 14px;
    line-height: 19px;
  }
`;

export const CrossIconWrap = styled.div`
  flex-shrink: 0;
  cursor: pointer;
`;
