import * as React from "react";
interface iconprop {}
const LowestPriceIcon = (props: iconprop) => (
  <svg
    width={33}
    height={32}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16.002} cy={16} r={16} fill="#D8E5F7" />
    <rect
      x={0.00195312}
      width={32}
      height={32}
      fill="#D8D8D8"
      fillOpacity={0.01}
    />
    <circle
      cx={16.002}
      cy={16.0001}
      r={9.33333}
      transform="rotate(45 16.002 16.0001)"
      fill="white"
      stroke="#1E1E1E"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M13.002 12.25H18.8608"
      stroke="#1E1E1E"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M13.002 14.7422H18.8608"
      stroke="#1E1E1E"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M13.2729 17.2344L16.6171 21.1589"
      stroke="#1E1E1E"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M13.3452 12.25H14.6157C16.1777 12.25 17.4577 13.3706 17.4577 14.7416C17.4577 16.1127 16.1777 17.2349 14.6157 17.2349H13.3452"
      stroke="#1E1E1E"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path d="M13.002 12.25H18.8608" stroke="#1E1E1E" strokeLinecap="round" />
    <path d="M13.002 14.7422H18.8608" stroke="#1E1E1E" strokeLinecap="round" />
    <path
      d="M13.2729 17.2344L16.6171 21.1589"
      stroke="#1E1E1E"
      strokeLinecap="round"
    />
    <path
      d="M13.3452 12.25H14.6157C16.1777 12.25 17.4577 13.3706 17.4577 14.7416C17.4577 16.1127 16.1777 17.2349 14.6157 17.2349H13.3452"
      stroke="#1E1E1E"
      strokeLinecap="round"
    />
  </svg>
);
export { LowestPriceIcon };
