import { createContext, Dispatch } from "react";
import { ActionTypeOptions } from "../types";

type Action = { type: string; payload?: any; options?: ActionTypeOptions };

type DispatchFunction = Dispatch<Action>;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const DispatchContext = createContext<DispatchFunction>();

export default DispatchContext;
