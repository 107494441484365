import styled from "styled-components";

export const ForexRatesDetails = styled.ul`
  > li {
    flex-direction: "row";
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    padding: 8px 8px 8px 0px;
  }

  > li:last-child {
    border-bottom: unset;
  }
`;
export const ForexRatesHdr = styled.div`
  display: flex;
  text-align: right;
  gap: 25px;
  justify-content: flex-end;
  margin-bottom: -23px;
  span:last-child {
    width: 73px;
  }
`;
export const ForexRatesBody = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 12px;
  }
`;
export const ForexRatesFtr = styled.div`
  margin: 20px -30px -30px -30px;
  background-color: #eaf5ff;
  padding: 8px 16px;
`;

export const ForexRatesLeftContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const CurrencyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ForexRatesRightContainer = styled.div`
  display: flex;
  margin-left: 20px;
  gap: 20px;
`;
