import styled from "styled-components";
import { deviceType } from "libs/ui-widgets/src/lib/static/utils";

export const TravAssistantStyle = styled.div`
  border-radius: 16px;
  border: 1px solid #e2e2e2;
  background: #fff;
  padding: 16px;
  @media ${deviceType.mobile} {
    border-radius: 0;
  }
`;
