export const PriceTickerData = [
  {
    id: "1",
    currencyCode: "THB",
    amount: "45.20 INR",
    conditional: true,
    upcomingTripInfo: "For upcoming trip to <b>Thailand</b>",
  },
  {
    id: "2",
    currencyCode: "EUR",
    amount: "92.03 INR",
    conditional: true,
    upcomingTripInfo: "",
  },
  {
    id: "3",
    currencyCode: "USD",
    amount: "82.32 INR",
    conditional: true,
    upcomingTripInfo: "",
  },
  {
    id: "4",
    currencyCode: "AED",
    amount: "45.25 INR",
    conditional: true,
    upcomingTripInfo: "",
  },
];
