import styled from "styled-components";

import { theme } from "@tm/utility/global";
import { deviceType } from "@tm/static/utils";

type ListingTopContainerProps = {
  bgImage?: string;
};

type BMFListingContainerProps = {
  padding?: string;
  margin?: string;
};

export const BMFListingContainer = styled.div<BMFListingContainerProps>`
  width: 1200px;
  margin: ${(props) => (props.margin ? props.margin : "0px auto")};
  ${(props) => props.padding && `padding: ${props.padding};`}
  background-color: ${theme.whiteText};

  @media screen and ${deviceType.mobile} {
    width: 100%;
  }
`;

export const ArrowIconContainer = styled.span`
  transform: scale(-1);
`;

export const ListingTopContainer = styled.div<ListingTopContainerProps>`
  background-color: ${theme.blackText};
  padding: 100px 0px 0px;

  @media screen and ${deviceType.mobile} {
    background-image: ${(props) => `url(${props.bgImage})`};
    background-repeat: no-repeat;
    background-size: 100%;
    padding-bottom: unset;
    padding: unset;
  }
`;

export const LandingCardFeatureCtr = styled.div`
  display: flex;
  gap: 6px;

  @media screen and ${deviceType.mobile} {
    flex-direction: column;
  }
`;

export const MarkerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and ${deviceType.mobile} {
    padding: 0px 16px 0px;
  }
`;

type SpecialAttentionProps = {
  withShadow?: boolean;
};

export const SpecialAttention = styled.div<SpecialAttentionProps>`
  /* padding: 24px; */
  overflow: hidden;
  border-radius: 16px;
  background: white;

  ${(props) =>
    props.withShadow && `box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);`}
`;
