// prettier-ignore
//@ts-ignore
import { setCookie as setGdprCookie, getCookie as getGdprCookie, removeCookie as removeGdprCookie, itemType } from 'MMT-UI/storages/client/cookieStorage';
import { getUtilOptions } from ".";

/**
@param isEssential: Value should be true if it's a part of code functionality, else false if it's not core (eg:- third party or advertising related)
*/
// Note:- modifying options for backward compatibity in case of web, can remove this after commons release

const { ES, N_ES } = itemType;

function getEssentialItemType(isEssential: boolean) {
  return isEssential ? ES : N_ES;
}

export function setCookie(
  name: string,
  value: string,
  isEssential: boolean = false,
  options: Record<string, string | boolean | number> | undefined
) {
  options = getUtilOptions(options);
  setGdprCookie(name, value, options, getEssentialItemType(isEssential));
}

export function getCookie(
  name: string,
  isEssential: boolean = false,
  options: Record<string, string | boolean | number> | undefined = {}
): string | null {
  options = getUtilOptions(options);
  return getGdprCookie(name, options, getEssentialItemType(isEssential));
}

export function removeCookie(
  name: string,
  options: Record<string, string | boolean | number> | undefined = {}
) {
  removeGdprCookie(name, options);
}
