import axios, {
  AxiosRequestConfig,
  AxiosInstance,
  AxiosError,
  AxiosRequestHeaders,
} from "axios";

import { API_BASE_URL_HTTP, API_TIMEOUT_IN_MS } from "./apiConstants";

interface IAxiosRequestWithRetryConfig extends AxiosRequestConfig {
  retry?: number;
}

export const RETRY_CONFIG: IAxiosRequestWithRetryConfig = {
  retry: 1,
};

const AXIOS_HTTP_CONFIG: IAxiosRequestWithRetryConfig = {
  baseURL: API_BASE_URL_HTTP,
  timeout: API_TIMEOUT_IN_MS,
  withCredentials: true,
  retry: 1,
};

const AXIOS_HTTPS_CONFIG: IAxiosRequestWithRetryConfig = {
  timeout: API_TIMEOUT_IN_MS,
  withCredentials: true,
  retry: 1,
};

if (process.env["NODE_ENV"] === "development" && process.env["PROXY_PORT"]) {
  AXIOS_HTTP_CONFIG.proxy = {
    protocol: "http:",
    host: "localhost",
    port: parseInt(`${process.env["PROXY_PORT"]}`),
  };
}

export const API_HTTP: AxiosInstance = axios.create(AXIOS_HTTP_CONFIG);

export const API_HTTPS: AxiosInstance = axios.create(AXIOS_HTTPS_CONFIG);

function enhanceRequestHeaders(headers?: AxiosRequestHeaders) {
  const {
    cookie = "",
    "mobile-device": mobile_device,
    cmp,
    ...ignoredHeaders
  } = headers || {};

  let x_tm_device = mobile_device === "true" ? "mobile" : "desktop";

  if (typeof navigator !== "undefined") {
    x_tm_device = /Mobile|Android|BlackBerry/.test(navigator.userAgent)
      ? "mobile"
      : "desktop";
  }

  if (typeof cmp === "string" && cmp != "") {
    return {
      cmp,
      cookie,
      "x-tm-device": x_tm_device,
      "X-Tm-Client": "desktop",
    };
  }

  return {
    cookie,
    "x-tm-device": x_tm_device,
    "X-Tm-Client": "desktop",
  };
}

API_HTTP.interceptors.request.use(function (
  config: IAxiosRequestWithRetryConfig
) {
  config.headers = enhanceRequestHeaders(config.headers);
  return config;
});

API_HTTPS.interceptors.request.use(function (
  config: IAxiosRequestWithRetryConfig
) {
  const { cookie, ...headersWithoutCookie } = enhanceRequestHeaders(
    config.headers
  );
  config.headers = headersWithoutCookie;
  return config;
});

if (process.env["NODE_ENV"] === "development") {
  // API_HTTP.interceptors.response.use(function(value) {
  //     const {data} = value;
  //     console.dir({data}, {depth: 10})
  //     return value;
  // })
}

interface ICustomAxiosError extends AxiosError {
  config: IAxiosRequestWithRetryConfig;
}

const retryAxiosRequest = (error: ICustomAxiosError) => {
  const { config, response, request } = error;
  const { url } = config;

  console.error(
    [
      "API RESPONSE ERROR:",
      JSON.stringify({
        url,
        status: response?.status,
        responseData: response?.data,
        responseHeaders: response?.headers,
        requestBody: config?.data,
        requestHeaders: config?.headers,
      }),
    ].join(" | ")
  );

  if ((response?.status && response.status < 400) || !config || !config.retry) {
    return Promise.reject(error);
  }

  //decrease retry count by 1
  config.retry -= 1;

  // Return Promise to retry the request
  return API_HTTP.request(config);
};

API_HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return retryAxiosRequest(error);
  }
);

API_HTTPS.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return retryAxiosRequest(error);
  }
);
