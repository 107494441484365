import styled from "styled-components";

export const ForexOfferingsStyle = styled.div<any>`
  width: 285px;
  flex-shrink: 0;
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 3px;
  margin: ${(p) => (p.margin ? p.margin : "")};
`;

export const ForexOfferingCntnt = styled.div`
  padding: 18px;
`;

export const ForexOfferingList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 18px;
  li {
    position: relative;
    padding-left: 18px;
  }
  li:before {
    content: "";
    position: absolute;
    background: #008cff;
    width: 5px;
    height: 14px;
    top: 8px;
    left: 0;
    border-radius: 4px;
  }
`;
