import { useState } from "react";

export default function TopReviewedCurrencies({ currencyData }: any) {
  const [showViewMore, setShowViewMore] = useState<boolean>(true);

  const handleOnClick = () => {
    setShowViewMore(!showViewMore);
  };

  return (
    <>
      <div className="separator"></div>
      <h2 className="h2">{currencyData?.footer?.section_footer?.[0]?.title}</h2>
      <ul className={`currencyList grid5 ${!showViewMore ? "showAll" : ""}`}>
        {currencyData?.footer?.section_footer?.[0]?.links?.map(
          (card: any, index: number) => {
            return card?.map((item: any) => {
              return (
                <li key={`${item?.title}${index}`}>
                  <a href={item?.href} target="_self" rel="noreferrer">
                    {item?.icon && (
                      <span className="currency__icon">
                        <img
                          src={item?.icon}
                          alt="currency"
                          width="65"
                          height="65"
                        />
                      </span>
                    )}
                    {item?.title}
                  </a>
                </li>
              );
            });
          }
        )}
      </ul>
      <p className="viewMore">
        <a className="viewMore__cta" onClick={() => handleOnClick()}>
          {showViewMore ? "View More" : "View Less"}
        </a>
      </p>
    </>
  );
}
