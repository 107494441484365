import {
  PageNameMapping,
  ElementNameMapping,
} from "libs/ui-widgets/src/lib/constants";
import { BackendPersuasionProps } from "libs/ui-widgets/src/lib/types";
import {
  getElementUniqueIds,
  getCamelCaseStr,
} from "libs/ui-widgets/src/lib/utils";
import { landingCardShowcaseDataProps } from "../../../mockData";
import PersuasionTile from "../PersuasionTile";

export default function BackendPersuasion(props: {
  cardData: landingCardShowcaseDataProps;
  persuasionProps: BackendPersuasionProps;
  index: string;
}) {
  return (
    <PersuasionTile
      flexId={getElementUniqueIds(
        PageNameMapping.LISTING_PAGE,
        getCamelCaseStr(props.cardData.label),
        ElementNameMapping.PERSUASION_TILE.concat(props.index)
      )}
      key={props.index}
      margin="24px 0px 0px"
      text={{
        normalText: props.persuasionProps.value,
      }}
      isHtml
      bgColor={props.persuasionProps.theme}
    />
  );
}
