import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";

import {
  H5Tag,
  LandingInput,
  ParaTag,
  SpanTag,
  LandingButton,
  AnchorTag,
  FormattedText,
  FlexComp,
} from "@tm/static";
import { theme } from "@tm/utility/global";

import { formatDate, getFormFieldErrorMsg } from "@tm/insurance/utils";
import { DATE_FORMATS } from "@tm/insurance/constants";
import { getAnalytics } from "@tm/insurance/analytics";

import {
  PassportNumCompStyle,
  PassportHd,
  PassportItem,
  ButtonWrap,
} from "./PassportNumComp.style";
import { IPassportNumComp, IPassportDetailsForm } from "./types";

const analytics = getAnalytics();

const PassportNumComp = ({
  isLoading,
  title,
  info,
  travellerData,
  submitCtaText,
  skipCtaText,
  passportFormData,
  handleSubmitClick,
  handleAddPassportModal,
}: IPassportNumComp) => {
  const { isRequired, label, placeHolder, pattern, maxLength, error_message } =
    passportFormData || {};

  const travellerCount = travellerData?.length || 0;

  const { control, formState, handleSubmit } = useForm<IPassportDetailsForm>({
    defaultValues: {
      //array name should match with field array name in useFieldAray()
      passportData: Array.from({ length: travellerCount }).map((_, index) => ({
        name: travellerData?.[index]?.name || "",
        passport: travellerData?.[index]?.passportNum || "",
      })),
    },
    mode: "onTouched",
  });

  const { fields } = useFieldArray({
    control,
    name: "passportData", // unique name for field array
  });

  const { errors } = formState;

  const onSubmit: SubmitHandler<IPassportDetailsForm> = (formData) => {
    analytics?.trackAddPassportEvents({ actionType: "submit_clicked" });
    analytics?.trackAddPassportEvents({ actionType: "details_filled" });

    const { passportData } = formData || {};

    const reqData = travellerData?.map((item, index) => {
      const { refId, name, dob = "", gender } = item || {};
      return {
        refId,
        FullName: name,
        Dob: formatDate(dob, DATE_FORMATS["YYYY-MM-DD"]),
        Gender: gender,
        PassportNo: passportData[index].passport,
      };
    });

    handleSubmitClick(reqData);
  };

  return (
    <PassportNumCompStyle>
      <PassportHd>
        <H5Tag>
          <FormattedText value={title} />
        </H5Tag>
        <ParaTag fontSize="sm" color={theme.greyText}>
          <FormattedText value={info} />
        </ParaTag>
      </PassportHd>
      {fields.map((item, index) => {
        const error = errors?.passportData?.[index];

        return (
          <PassportItem itemIndex={index == fields.length - 1} key={index}>
            <Controller
              control={control}
              name={`passportData.${index}.passport`}
              rules={{
                required: {
                  value: isRequired ?? true,
                  message: getFormFieldErrorMsg("passport"),
                },
                pattern: {
                  value: new RegExp(pattern || ""),
                  message: getFormFieldErrorMsg("passport", error_message),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <SpanTag
                    fontSize="md"
                    fontWeight="bold"
                    color={theme.blackText}
                  >
                    {item.name}
                  </SpanTag>
                  <LandingInput
                    requiredField={isRequired}
                    placeholderTxt={placeHolder}
                    labelTxt={label}
                    errorState={error?.passport?.message}
                    inputValue={value}
                    maxInputLength={maxLength}
                    disableState={isLoading}
                    handleChange={onChange}
                    handleBlur={onBlur}
                  />
                </>
              )}
            />
          </PassportItem>
        );
      })}
      <ButtonWrap alignItems="center" gap="24px">
        <LandingButton
          isLoading={isLoading}
          customTitle={submitCtaText}
          onClick={handleSubmit(onSubmit)}
        />
        {skipCtaText && (
          <FlexComp>
            <AnchorTag
              fontSize="md"
              fontWeight="bold"
              handleClick={handleAddPassportModal}
            >
              {skipCtaText}
            </AnchorTag>
          </FlexComp>
        )}
      </ButtonWrap>
    </PassportNumCompStyle>
  );
};

export default PassportNumComp;
