import React, { useEffect, useState } from "react";
import { WeekDayWrap } from "./WeekDays.style";
// import './WeekDays.css';

interface WeekDayType {
  top?: number;
}

const WeekDays = ({ top }: WeekDayType) => {
  return (
    <WeekDayWrap style={{ top: top }}>
      <span>Sun</span>
      <span>Mon</span>
      <span>Tue</span>
      <span>Wed</span>
      <span>Thu</span>
      <span>Fri</span>
      <span>Sat</span>
    </WeekDayWrap>
  );
};

export default WeekDays;
