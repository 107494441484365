import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";

import {
  H5Tag,
  ParaTag,
  FlexComp,
  LandingInput,
  SpanTag,
  CheckButton,
  LandingButton,
  FormattedText,
  CountryCodeInput,
  Tags,
  ImageComponent,
  AnchorTag,
  LandingButtonWhite,
  MoreBenefits,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { getFormFieldErrorMsg } from "@tm/insurance/utils";
import { getAnalytics } from "@tm/insurance/analytics";
import {
  AddTravellerComp,
  ConfirmTnc,
  PrimaryContactNote,
  AddOnPlanWrap,
  TagWrap,
  VendorIconWrap,
  StickyBtnWrap,
} from "./AddTravellerDetail.style";
import { ITravellerCommon, ITravellerDetailsForm } from "./type";
const analytics = getAnalytics();

const TravellerCommonComp = ({
  primaryContactData,
  title,
  info,
  note,
  primaryContactDetails,
  saveFormValuesOnBlur,
  confirmTnC,
  totalAmt,
  selectedCountry,
  isLoading,
  handleBuy,
  handleTncCheckbox,
  addOnPlans,
  addOnfilters,
  handleAddOnFilters,
  handleAddOnSelect,
  handleCountryCodeModal,
  componentRef,
}: ITravellerCommon) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<ITravellerDetailsForm>();
  const device = useBreakpointV2();

  const { email, mobile } = primaryContactData || {};
  const [showAddOnBenefits, setShowAddOnBenefits] = useState<number>(-1);

  const tncError = errors.tncs?.find?.((item) => item?.message);

  return (
    <AddTravellerComp ref={componentRef}>
      {title && (
        <H5Tag margin="0 0 16px">
          <FormattedText value={title} />
        </H5Tag>
      )}
      {confirmTnC?.map((tnc, index) => (
        <Controller
          key={index}
          control={control}
          name={`tncs.${index}`}
          rules={{
            required: {
              value: true,
              message: "Please confirm",
            },
          }}
          render={({ field: { value } }) => (
            <ConfirmTnc>
              <CheckButton
                inputId="confirmTnC"
                handleChange={() => {
                  handleTncCheckbox?.(index);
                }}
                isChecked={value}
              >
                <p>
                  <FormattedText value={tnc} />
                </p>
              </CheckButton>
            </ConfirmTnc>
          )}
        />
      ))}

      {confirmTnC && tncError && (
        <ParaTag margin="0px 0 0 4px" color={theme.redText2} fontSize="xs">
          {tncError.message}
        </ParaTag>
      )}

      <ParaTag
        margin={confirmTnC ? "16px 0 45px" : "16px 0"}
        fontSize="sm"
        color={theme.greyText7}
      >
        <FormattedText value={info} />
      </ParaTag>

      {primaryContactDetails && (
        <React.Fragment>
          <FlexComp
            flexDirection={device == "lg" ? "row" : "column"}
            gap="16px"
          >
            <Controller
              control={control}
              name={"primaryContactData.mobile"}
              rules={{
                required: {
                  value: mobile?.isRequired ?? true,
                  message: getFormFieldErrorMsg("mobile"),
                },
                pattern: {
                  value: new RegExp(mobile?.regex || ""),
                  message: getFormFieldErrorMsg("mobile", mobile?.errorMessage),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <CountryCodeInput
                  errorState={errors?.primaryContactData?.mobile?.message}
                  inputValue={value}
                  handleChange={onChange}
                  handleBlur={() => {
                    saveFormValuesOnBlur?.();
                    onBlur();
                  }}
                  placeholderTxt={mobile?.placeholder}
                  requiredField={mobile?.isRequired}
                  labelTxt={mobile?.title}
                  inputType="tel"
                  disableState={isLoading}
                  autoComplete="off"
                  flex="1"
                  inputWidth={device == "lg" ? "50%" : "100%"}
                  handleCountryCodeModal={handleCountryCodeModal}
                  countryFlag={selectedCountry?.countryFlag}
                  countryCode={selectedCountry?.countryCode || "+91"}
                />
              )}
            />
            <Controller
              control={control}
              name={"primaryContactData.email"}
              rules={{
                required: {
                  value: email?.isRequired ?? true,
                  message: getFormFieldErrorMsg("email"),
                },
                pattern: {
                  value: new RegExp(email?.regex || ""),
                  message: getFormFieldErrorMsg("email", email?.errorMessage),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <LandingInput
                  inputValue={value}
                  handleChange={onChange}
                  handleBlur={() => {
                    saveFormValuesOnBlur?.();
                    onBlur();
                  }}
                  placeholderTxt={email?.placeholder}
                  requiredField={email?.isRequired}
                  labelTxt={email?.title}
                  errorState={errors?.primaryContactData?.email?.message}
                  disableState={isLoading}
                  autoComplete="off"
                  flex="1"
                  inputWidth={device == "lg" ? "50%" : "100%"}
                />
              )}
            />
          </FlexComp>
          {note && (
            <PrimaryContactNote>
              <SpanTag color={theme.brownText} fontWeight="bold">
                <FormattedText value={note.title} />
              </SpanTag>
              <SpanTag color={theme.brownText}>
                <FormattedText value={note?.info} />
              </SpanTag>
            </PrimaryContactNote>
          )}
        </React.Fragment>
      )}

      {/* NOT IN CURRENT SCOPE : Choose from multiple insurers*/}
      {/* {selectInsurer &&
                selectInsurer.map((item, index) => {
                    return (
                        <SelectInsurereItem
                            key={index}
                            onClick={() => handleRadioBtnChange(index)}
                            selected={radioBtnSelect == index}
                        >
                            <RadioButton
                                activeBtn={radioBtnSelect == index}
                                handleChange={() => handleRadioBtnChange(index)}
                            >
                                <FlexComp gap="14px" alignItems="cenetr">
                                    {item.icon && (
                                        <ImageComponent
                                            width="48px"
                                            height="48px"
                                            src={item.icon}
                                        />
                                    )}
                                    <div>
                                        <ParaTag
                                            margin="0 0 2px"
                                            fontSize="sm"
                                            fontWeight="bold"
                                            color={theme.greyText}
                                        >
                                            {item.title}
                                        </ParaTag>
                                        <ParaTag>
                                            <SpanTag
                                                fontWeight="bold"
                                                fontSize="xs"
                                                color={theme.greenText}
                                            >
                                                {item.settleMentRatio} &nbsp;
                                            </SpanTag>
                                            <SpanTag
                                                fontSize="xs"
                                                color={theme.greyText}
                                            >
                                                {item.settleTxt}
                                            </SpanTag>
                                        </ParaTag>
                                    </div>
                                </FlexComp>
                            </RadioButton>
                        </SelectInsurereItem>
                    );
                })} */}

      {confirmTnC && (
        <StickyBtnWrap>
          <LandingButton
            isLoading={isLoading}
            onClick={handleBuy}
            customTitle={`BUY FOR ${totalAmt}`}
          />
        </StickyBtnWrap>
      )}

      {addOnfilters && (
        <FlexComp
          alignItems={device == "lg" ? "center" : "flex-start"}
          justifyContent="space-between"
          margin="0 0 30px"
          gap="10px"
          flexDirection={device == "lg" ? "row" : "column"}
        >
          <Tags handleClickTab={handleAddOnFilters} data={addOnfilters} />
        </FlexComp>
      )}

      {addOnPlans &&
        addOnPlans.map((item, index) => {
          return (
            <AddOnPlanWrap
              margin={item?.tag ? "20px 0px 14px 0px" : "0px 0px 14px 0px"}
              isSelected={item?.isSelected}
            >
              {showAddOnBenefits === index && (
                <MoreBenefits
                  data={item?.modal}
                  tag={item?.tag}
                  moreBenfitsTitle={"Add-Ons"}
                  customBtnTitle={
                    item?.isSelected
                      ? `Buy for ${item?.comboProductDetails?.totalAmountToPay}`
                      : item?.modal?.buyBtn
                  }
                  isAddOnBenefits={true}
                  handleClose={() => {
                    setShowAddOnBenefits(-1);
                  }}
                  handleBuyButton={() => {
                    if (item?.isSelected) {
                      analytics?.trackTravDetailsEvents({
                        actionType: "Buy_in_bs",
                      });
                      handleBuy?.();
                    } else {
                      analytics?.trackTravDetailsEvents({
                        actionType: "Added_in_bs",
                      });
                      handleAddOnSelect?.(item?.planCode);
                    }
                    setShowAddOnBenefits(-1);
                  }}
                />
              )}
              {item?.tag && (
                <TagWrap>
                  <FormattedText value={item?.tag} />
                </TagWrap>
              )}
              <FlexComp
                flexDirection="row"
                justifyContent="space-between"
                margin={item?.tag ? "6px 0 0" : "0"}
              >
                <FlexComp
                  flexDirection="row"
                  gap={device === "lg" ? "14px" : "8px"}
                >
                  {item?.planIcon && (
                    <VendorIconWrap>
                      <ImageComponent
                        src={item?.planIcon}
                        height={device === "lg" ? "48px" : "32px"}
                        width={device === "lg" ? "48px" : "32px"}
                      />
                    </VendorIconWrap>
                  )}
                  <FlexComp flexDirection="column">
                    <FormattedText
                      value={item?.primary?.title}
                      baseStyle={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: theme.greyText,
                      }}
                    />
                    <FlexComp
                      flexDirection={device === "lg" ? "row" : "column"}
                      gap={device === "lg" ? "8px" : "0px"}
                    >
                      <FormattedText value={item?.primary?.description} />
                      <AnchorTag
                        margin={
                          device === "lg"
                            ? "3px 0px 0px 0px"
                            : "-2px 0px 0px 0px"
                        }
                        handleClick={() => {
                          analytics?.trackTravDetailsEvents({
                            actionType: "View_detail_clicked",
                          });
                          setShowAddOnBenefits(index);
                        }}
                      >
                        {item?.primary?.cta?.value}
                      </AnchorTag>
                    </FlexComp>
                  </FlexComp>
                </FlexComp>
                <FlexComp
                  flexDirection={device === "lg" ? "row" : "column"}
                  gap={device === "lg" ? "12px" : "8px"}
                  alignItems=" center"
                >
                  <FlexComp flexDirection="column">
                    <FlexComp
                      justifyContent="right"
                      alignItems="center"
                      gap="8px"
                    >
                      <FormattedText
                        value={item?.secondary?.title}
                        baseStyle={{
                          fontSize: "14px",
                          fontWeight: "700",
                          padding: "1.5px 0px 1.5px 0px",
                        }}
                      />
                      {item?.isSelected && (
                        <ImageComponent
                          src={item?.addedIcon}
                          height="16px"
                          width="16px"
                        />
                      )}
                    </FlexComp>
                    <FormattedText
                      value={item?.secondary?.description}
                      baseStyle={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: theme.greyText6,
                      }}
                    />
                  </FlexComp>
                  {item?.isSelected ? (
                    <AnchorTag
                      handleClick={() => {
                        analytics?.trackTravDetailsEvents({
                          actionType: "Remove_clicked",
                        });
                        handleAddOnSelect?.(item?.planCode);
                      }}
                      margin="6px 12.5px 6px 12.5px"
                    >
                      {item?.secondary?.cta?.toggleValue}
                    </AnchorTag>
                  ) : (
                    <LandingButtonWhite
                      customTitle={item?.secondary?.cta?.value}
                      onClick={() => {
                        analytics?.trackTravDetailsEvents({
                          actionType: item?.addPlanEvent,
                        });
                        analytics?.trackTravDetailsEvents({
                          actionType: "Add_button_clicked",
                        });
                        handleAddOnSelect?.(item?.planCode);
                      }}
                      customHeight="32px"
                      customWidth="75px"
                    />
                  )}
                </FlexComp>
              </FlexComp>
            </AddOnPlanWrap>
          );
        })}
    </AddTravellerComp>
  );
};

export default TravellerCommonComp;
