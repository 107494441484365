import styled from "styled-components";

const renderFont: any = (fontSize: any) => {
  switch (fontSize) {
    case "xxs":
      return `
          font-size: 10px;
          line-height: 16px;
        `;
    case "xs":
      return `
          font-size: 12px;
          line-height: 16px;
        `;
    case "sm":
      return `
          font-size: 14px;
          line-height: 20px;
        `;
    case "md":
      return `
        font-size: 16px;
        line-height: 24px;
      `;
    case "xlarge":
      return `
        font-size: 22px;
        line-height: 32px;
      `;
    case "xxlarge":
      return `
        font-size: 24px;
        line-height: 32px;
      `;
    case "xxxlarge":
      return `
        font-size: 28px;
        line-height: 32px;
      `;
    default:
      return `
      font-size: 14px;
      line-height: 20px;
    `;
  }
};

const renderFontWeight: any = (fontWeight: any) => {
  switch (fontWeight) {
    case "regular":
      return `
          font-weight: 400;
        `;
    case "medium":
      return `
        font-weight: 500;
      `;
    case "semiBold":
      return `
        font-weight: 600;
      `;
    case "bold":
      return `
        font-weight: 700;
      `;
    case "black":
      return `
      font-weight: 900;
        `;
    default:
      return `
      font-weight: 400;
    `;
  }
};

export const H1TagStyle: any = styled.h1`
  font-size: 56px;
  line-height: 72px;
  font-weight: 600;
  margin: var(--margin, 0);
  font-family: "Raleway";
  color: var(--color, #000);

  span {
    font-size: 56px;
    line-height: 72px;
    font-weight: 600;
    margin: var(--margin, 0);
    font-family: "Raleway";
    color: var(--color, #000);
  }
`;

export const H2TagStyle: any = styled.h2`
  font-size: 48px;
  line-height: 64px;
  font-weight: 600;
  margin: var(--margin, 0);
  font-family: "Raleway";
  color: var(--color, #000);
`;

export const H3TagStyle: any = styled.h3`
  font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  margin: var(--margin, 0);
  font-family: "Raleway";
  color: var(--color, #000);
  text-align: var(--textAlign, left);
`;

export const H4TagStyle: any = styled.h4`
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  margin: var(--margin, 0);
  font-family: "Raleway";
  color: var(--color, #000);
`;

export const H5TagStyle: any = styled.h5`
  font-size: 24px;
  line-height: 32px;
  margin: var(--margin, 0);
  font-family: "Raleway";
  color: var(--color, #000);
  font-weight: var(--fontWeight, 600);
  span {
    font-size: 24px;
    line-height: 32px;
    font-family: "Raleway";
    font-weight: var(--fontWeight, 600);
  }
`;

export const H6TagStyle: any = styled.h6`
  font-size: 16px;
  line-height: 25px;
  font-weight: var(--fontWeight, 600);
  margin: var(--margin, 0);
  font-family: "Raleway";
  color: var(--color, #000);
`;

export const ParaTagStyle: any = styled.p`
  font-family: "Open Sans";
  ${(p: any) =>
    p.fontSize
      ? `
    ${renderFont(p.fontSize)}
  `
      : ``}
  ${(p: any) =>
    p.fontWeight &&
    `
    ${renderFontWeight(p.fontWeight)}
  `}
  margin: ${(p: any) => p.margin && p.margin};
  text-align: ${(p: any) => p.textAlign && p.textAlign};
  color: ${(p: any) => p.color && p.color};
  flex-shrink: ${(p: any) => p.flexShrink && p.flexShrink};
`;

export const SpanTagStyle: any = styled.span`
  font-family: "Open Sans";
  ${(p: any) =>
    p.fontSize &&
    `
    ${renderFont(p.fontSize)}
  `}
  ${(p: any) =>
    p.fontWeight &&
    `
    ${renderFontWeight(p.fontWeight)}
  `}
  color: ${(p: any) => p.color && p.color};
  margin: ${(p: any) => p.margin && p.margin};
  text-align: ${(p: any) => p.textAlign && p.textAlign};
  flex-shrink: ${(p: any) => p.flexShrink && p.flexShrink};
  align-self: ${(p: any) => p.alignSelf && p.alignSelf};
  display: inline-block;
  ${(p: any) =>
    p.lineHeight &&
    `line-height:${p.lineHeight};
  `}
  ${(p: any) =>
    p.width &&
    `
    width:${p.width}
  `}
`;

export const AnchorTagStyle: any = styled.a`
  font-family: "Open Sans";
  ${(p: any) =>
    p.fontSize &&
    `
    ${renderFont(p.fontSize)}
  `}
  ${(p: any) =>
    p.fontWeight &&
    `
    ${renderFontWeight(p.fontWeight)}
  `}
  margin: ${(p: any) => p.margin && p.margin};
  display: inline-block;
  color: ${(p: any) => p.color && p.color};
  text-align: ${(p: any) => p.textAlign && p.textAlign};
  flex-shrink: ${(p: any) => p.flexShrink && p.flexShrink};
`;

export const CliptextStyleComp: any = styled.div`
  background: linear-gradient(
    92.2deg,
    #ffe2e8 0.47%,
    #fffcfc 25.07%,
    #8cc8ea 64.68%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: fit-content;
  font-weight: 600;
`;
