import { createContext } from "react";

export interface IGlobalConfig {
  partner?: string | null;
  isBot?: boolean | null;
  isLoggedInUser?: boolean | null;
  onLoginClick?: () => void;
  mobileNumberRequiredError?: boolean | null;
}

const GlobalConfigContext = createContext<IGlobalConfig>({});

export default GlobalConfigContext;
