import { useEffect, useState } from "react";
import {
  SESSION_STORAGE_KEYS,
  getSessionPiiDataOpts,
  setSessionPiiDataOpts,
} from "../../constants";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/clientStorageUtils";
import { getQueryParamsFromUrl } from "../../utils";

export default function useItineraryId() {
  const ITINERARY_ID_KEY = "itinerary_id";

  const [itineraryId, setItineraryId] = useState<string>("");
  const [isItineraryIdFetched, setIsItineraryIdFetched] =
    useState<boolean>(false);

  useEffect(() => {
    let id: string = "";
    const urlItineraryIdParam = getQueryParamsFromUrl()[ITINERARY_ID_KEY] || "";

    if (urlItineraryIdParam) {
      if (Array.isArray(urlItineraryIdParam)) {
        id = urlItineraryIdParam[0];
      } else {
        id = urlItineraryIdParam;
      }
    } else {
      const sessionItineraryId = getSessionStorageItem(
        SESSION_STORAGE_KEYS.ITINERARY_ID,
        true,
        getSessionPiiDataOpts
      );
      id = sessionItineraryId || "";
    }

    setSessionStorageItem(
      SESSION_STORAGE_KEYS.ITINERARY_ID,
      id,
      true,
      setSessionPiiDataOpts
    );
    setIsItineraryIdFetched(true);
    setItineraryId(id);
  }, []);

  return {
    isItineraryIdFetched,
    itineraryId,
  };
}
