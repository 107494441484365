import styled from "styled-components";
import { deviceType } from "@tm/static/utils";

export const OrderListingCompStyle = styled.div`
  background: #fff;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 16px;
  border-radius: 16px;
  cursor: pointer;
  /* div {
        flex: 1;
    } */
  div:last-child {
    /* align-self: flex-end; */
  }
  img {
    display: inline-block;
    margin-bottom: auto;
  }
  @media ${deviceType.mobile} {
    max-height: 100%;
    gap: 12px;
    border-radius: 0;
  }
`;
