import { useRef } from "react";

interface ITimer {
  initialTime: number;
  interval?: number;
}

export const useDefaultLocationTimer = ({
  initialTime = 0,
  interval = 1000,
}: ITimer) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const timeRef = useRef<number>(initialTime);

  const startTimer = (callback: any) => {
    if (timerRef.current === null) {
      timerRef.current = setInterval(() => {
        console.log("time:", timeRef.current);
        if (timeRef.current > 0) {
          timeRef.current -= interval;
        } else {
          callback();
          resetTimer();
        }
      }, interval);
    }
  };

  const resetTimer = () => {
    if (timerRef.current !== null) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    timeRef.current = initialTime;
  };

  const stopTimer = () => {
    if (timerRef.current !== null) {
      clearInterval(timerRef.current);
    }
  };

  return {
    startTimer,
    resetTimer,
  };
};
