export enum RESPONSE_STATUS {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum PARTNER {
  MMT = "mmt",
  GI = "gi",
}

export enum CONTEXT {
  INSURANCE = "insurance",
  STANDALONE = "standalone",
  STANDALONE_V2 = "standalone_v2",
}

export enum PLATFORM {
  WEB = "web",
}

export enum LOB {
  STANDALONE = "standalone",
}

export enum ERROR_CODES {
  DISPLAY_ERROR = "DISPLAY_ERROR",
}

export enum UTM_PARAMS {
  UTM_SOURCE = "utm_source",
  UTM_MEDIUM = "utm_medium",
  UTM_CAMPAIGN = "utm_campaign",
  UTM_INS_CAMPAIGN = "utm_ins_campaign",
}

export enum DATE_FORMATS {
  "DDD" = "ddd",
  "YY" = "yy",
  "YYYY" = "yyyy",
  "D MMM" = "d MMM",
  "DD/MM/YYYY" = "dd/MM/yyyy",
  "DD MMM YY" = "dd MMM'yy",
  "YYYY-MM-DD" = "yyyy-MM-dd",
}

export const MONTHS_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export enum STORAGE_KEYS {
  TRAVELLER_DETAILS = "traveller-details",
}

export const FORM_FIELDS_MAP = Object.freeze({
  name: "name",
  dob: "date of birth",
  gender: "gender",
  mobile: "mobile number",
  email: "email id",
  passport: "passport number",
});

export type FormFieldType =
  | "name"
  | "dob"
  | "gender"
  | "mobile"
  | "email"
  | "passport";

export const GENDER_VALUES = [
  {
    id: "1",
    label: "Male",
    isSelected: false,
  },
  {
    id: "2",
    label: "Female",
    isSelected: false,
  },
  {
    id: "3",
    label: "Others",
    isSelected: false,
  },
];

export const TXN_SUCCESS_REDIRECT_TIME = 5000;
export const TXN_FAILURE_REDIRECT_TIME = 5000;

export enum PASSPORT_DETAILS_PAGE_ID {
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  ORDER_CONFIRMED = "ORDER_CONFIRMED",
}

export const TRIPMONEY_DOMAIN_NAME = {
  MMT: [
    "tripmoney.makemytrip.com",
    "insurance.makemytrip.com",
    "www.makemytrip.com",
  ],
  GI: ["tripmoney.goibibo.com", "insurance.goibibo.com", "www.goibibo.com"],
  TRIPMONEY: ["www.tripmoney.com"],
};

export enum PARTNER_DOMAIN_NAME {
  MMT = ".makemytrip.com",
  GI = ".goibibo.com",
  TRIPMONEY = "tripmoney.com",
}

export const TRAVELLER_NUMBER_CONSTANTS = {
  MAX_TRAVELLERS: 20,
  MIN_TRAVELLERS: 1,
};
