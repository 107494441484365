import * as React from "react";
interface iconprop {}
const QuickLinks = (props: iconprop) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1.50391}
      width={18}
      height={18}
      rx={4.5}
      stroke="#979797"
      strokeWidth={0.9}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3283 6.25372C14.2738 5.19928 12.5643 5.19928 11.5099 6.2537L9.25326 8.51032C8.16258 9.60105 8.26356 11.3389 9.25326 12.3287C9.419 12.4944 9.60237 12.6296 9.79601 12.7406L10.2079 12.3287C10.478 12.0585 10.3829 11.7427 10.3781 11.5148C10.3188 11.4728 10.2611 11.4273 10.2079 11.3741C9.69998 10.8662 9.67714 9.99563 10.2079 9.4649C10.2867 9.38608 12.4166 7.25617 12.4644 7.20833C12.9909 6.68185 13.8472 6.68185 14.3736 7.20833C14.9001 7.73481 14.9001 8.59105 14.3736 9.11752L12.882 10.6091C12.9252 10.8478 13.184 11.4131 13.0509 12.3457C13.0574 12.3393 13.0652 12.3352 13.0716 12.3287L15.3283 10.0721C16.3827 9.01767 16.3827 7.30816 15.3283 6.25372Z"
      fill="url(#paint0_linear_841_15485)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6393 9.94311C11.4736 9.77738 11.2902 9.64224 11.0966 9.53125L10.6847 9.94311C10.4145 10.2132 10.5096 10.5291 10.5145 10.757C10.5738 10.799 10.6315 10.8444 10.6847 10.8977C11.1926 11.4056 11.2154 12.2761 10.6847 12.8069C10.6057 12.8859 8.29938 15.1922 8.25323 15.2384C7.72675 15.7648 6.87051 15.7648 6.34404 15.2384C5.81756 14.7119 5.81756 13.8556 6.34404 13.3292L8.01054 11.6627C7.96741 11.424 7.70853 10.8587 7.8417 9.92608C7.8352 9.93247 7.82737 9.93663 7.82089 9.94309L5.38943 12.3746C4.33503 13.429 4.33503 15.1385 5.38943 16.193C6.44387 17.2474 8.15335 17.2474 9.20779 16.193L11.6393 13.7615C12.7097 12.691 12.6512 10.955 11.6393 9.94311Z"
      fill="url(#paint1_linear_841_15485)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_841_15485"
        x1={11.5802}
        y1={16.9162}
        x2={19.9781}
        y2={9.22726}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0772C" />
        <stop offset={1} stopColor="#F95776" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_15485"
        x1={7.7774}
        y1={21.2597}
        x2={16.3763}
        y2={13.3953}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0772C" />
        <stop offset={1} stopColor="#F95776" />
      </linearGradient>
    </defs>
  </svg>
);
export { QuickLinks };
