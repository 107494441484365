import {
  ImageComponent,
  H5Tag,
  FlexComp,
  ParaTag,
  SpanTag,
  AnchorTag,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { formatDate } from "@tm/insurance/utils";
import { DATE_FORMATS } from "@tm/insurance/constants";
import { navigationActions } from "@tm/insurance/navigation";
import { getAnalytics } from "@tm/insurance/analytics";
import FormattedText from "../FormattedText/FormattedText";
import {
  ListingEditDetailsStyle,
  ListingSpan,
} from "./ListingEditDetails.style";
import CountryFormatter from "../CountryFormatter";

const analytics = getAnalytics();
interface ListingEditDetailsProps {
  mode?: string | null;
  title?: string | null;
  travelPurpose?: string | null;
  travelDates?: {
    startDate?: Date;
    endDate?: Date;
  };
  travellerCount?: number;
  showEditIcon: boolean;
}

const ListingEditDetails = ({
  mode,
  title,
  travelPurpose,
  travelDates,
  travellerCount,
  showEditIcon,
}: ListingEditDetailsProps) => {
  const device = useBreakpointV2();

  const { startDate, endDate } = travelDates || {};

  const handleEditClick = () => {
    analytics?.trackListingEvents({ actionType: "edit_clicked" });

    const referrer = document.referrer;
    const hostname = referrer ? new URL(referrer).hostname : "";

    if (hostname === "www.makemytrip.com" || hostname === "www.goibibo.com") {
      navigationActions.goBack();
    }
    const queryParams = new URLSearchParams(window.location.search);
    const params = {
      countries: queryParams.get("countries"),
      startDate: queryParams.get("startDate"),
      endDate: queryParams.get("endDate"),
      travellers: queryParams.get("travellers"),
    };
    navigationActions.navigateToLanding(params, false);
  };

  return (
    <ListingEditDetailsStyle>
      <FlexComp
        alignItems="enter"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        {device == "lg" ? (
          <H5Tag color={theme.blackText}>
            {title && <CountryFormatter title={title} />}
          </H5Tag>
        ) : (
          <ParaTag fontSize="md" fontWeight="black" color={theme.blackText}>
            {title && <CountryFormatter title={title} />}
          </ParaTag>
        )}
        {showEditIcon && (
          <AnchorTag handleClick={handleEditClick}>
            <ImageComponent
              width="25px"
              height="25px"
              src="https://tripmoneycmsimgak.mmtcdn.com/img/edit_Icon_be6c24806a.svg"
            />
          </AnchorTag>
        )}
      </FlexComp>
      <FlexComp>
        {mode && (
          <ListingSpan color={theme.blackText}>
            <SpanTag fontSize="md" color={theme.blackText}>
              <FormattedText value={mode} />
            </SpanTag>
          </ListingSpan>
        )}
        {startDate && endDate && (
          <ListingSpan color={theme.blackText}>
            <SpanTag color={theme.blackText} fontSize="md" fontWeight="black">
              {formatDate(startDate, DATE_FORMATS["D MMM"])}'
            </SpanTag>
            {formatDate(startDate, DATE_FORMATS.YY)} -To-{" "}
            <SpanTag color={theme.blackText} fontSize="md" fontWeight="black">
              {formatDate(endDate, DATE_FORMATS["D MMM"])}'
            </SpanTag>
            {formatDate(endDate, DATE_FORMATS.YY)}
          </ListingSpan>
        )}
        {travellerCount && (
          <ListingSpan color={theme.blackText}>
            <SpanTag color={theme.blackText} fontSize="md" fontWeight="bold">
              {travellerCount}&nbsp;
            </SpanTag>
            <SpanTag color={theme.blackText} fontSize="md">
              {travellerCount > 1 ? "Travellers" : "Traveller"}
            </SpanTag>
          </ListingSpan>
        )}

        {/* {travelPurpose && (
                    <ListingSpan
                        color={theme.blackText}
                        dangerouslySetInnerHTML={{ __html: travelPurpose }}
                    />
                )} */}
      </FlexComp>
    </ListingEditDetailsStyle>
  );
};

export default ListingEditDetails;
