import {
  PageNameMapping,
  ElementNameMapping,
} from "libs/ui-widgets/src/lib/constants";
import { DropOffPersuasionProps } from "libs/ui-widgets/src/lib/types";
import {
  getElementUniqueIds,
  getCamelCaseStr,
} from "libs/ui-widgets/src/lib/utils";
import { RoundEcllipseIcon } from "../../../icons/RoundEcllipseIcon";
import { landingCardShowcaseDataProps } from "../../../mockData";
import ImageTag from "../../ui-atoms/ImageTag/ImageTag";
import PersuasionTile from "../PersuasionTile";
import { PersuasionHandler } from "../Persuasion";
import { MouseEvent } from "react";

export default function DropOffPersuasion(props: {
  cardData: landingCardShowcaseDataProps;
  persuasionProps: DropOffPersuasionProps;
  handlers: PersuasionHandler;
}) {
  return (
    <span
      onClick={(e: MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        props.handlers.startFromWhereLeftHandler(props.cardData);
      }}
    >
      <PersuasionTile
        id={getElementUniqueIds(
          PageNameMapping.LISTING_PAGE,
          getCamelCaseStr(props.cardData.label),
          ElementNameMapping.COMPLETE_ORDER
        )}
        margin="24px 0px 0px"
        text={props.persuasionProps.text}
        bgColor={"#EAF5FF"}
        linkBtn={{
          ...props.persuasionProps?.linkBtn,
          onClick: props.handlers.startFromWhereLeftHandler
            ? (e) => {
                props.handlers.startFromWhereLeftHandler(props.cardData);
                e.stopPropagation();
              }
            : () => {
                console.log("link button");
              },
        }}
        leftIcon={
          props.persuasionProps.iconSrc ? (
            <ImageTag src={props.persuasionProps.iconSrc} />
          ) : (
            <RoundEcllipseIcon width="50px" height="50px" color="#125BD3" />
          )
        }
      />
    </span>
  );
}
