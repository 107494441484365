import { ListingReducerType } from "../../store/forexListingReducer";
import { useDevice } from "../useDevice";
import { useGlobalSelector } from "../useGlobalSelector";

const useFetchBannerData = () => {
  const { banners } = useGlobalSelector((state: ListingReducerType) => state);

  const device = useDevice();

  const { orders, campaign, marketing } = banners || {};

  const campaignData = device === "lg" ? campaign?.desktop : campaign?.mobile;
  const marketingData =
    device === "lg" ? marketing?.desktop : marketing?.mobile;

  return {
    bannerOrdering: orders,
    campaignData,
    marketingData,
  };
};

export default useFetchBannerData;
