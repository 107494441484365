import { useEffect, useState } from "react";
import { isUserLoggedInFn } from "../../utils/authUtils";
import { useListingPdtLogger } from "../usePdtLogger";
import useListingPdtLoggerV2 from "../useListingPdtLoggerV2";

const useIsLoggedInUser = (): (boolean | null)[] => {
  const [loggedInUser, setLoggedInUser] = useState<boolean | null>(null);
  const { sendPdtAppSocialError, sendPdtLoginTokenError } =
    useListingPdtLogger();
  const { sendPdtAppSocialErrorV2, sendPdtLoginTokenErrorV2 } =
    useListingPdtLoggerV2();
  const [mobileRequiredError, updateMobileRequiredError] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const response = await isUserLoggedInFn({
        sendPdtAppSocialError,
        sendPdtLoginTokenError,
        sendPdtAppSocialErrorV2,
        sendPdtLoginTokenErrorV2,
      });
      setLoggedInUser(response.loggedIn);
      updateMobileRequiredError(response.mobileRequiredError);
    })();
  }, []);

  return [loggedInUser, mobileRequiredError];
};

export default useIsLoggedInUser;
