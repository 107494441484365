import * as React from "react";
interface iconprop {}
const TravBenefitIcon = (props: iconprop) => (
  <svg
    width={165}
    height={165}
    viewBox="0 0 165 165"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={82.0009} cy={82.0009} r={82.0009} fill="#FAFAFA" />
    <path
      d="M41.8389 33.3675C41.8389 33.3675 67.1926 39.179 80.339 24.166C80.339 42.569 80.339 112.791 80.339 124.414C50.7596 116.665 41.8381 90.0294 41.8389 79.8593C41.8396 69.6893 41.8389 33.3675 41.8389 33.3675Z"
      fill="#F4EDED"
    />
    <path
      d="M118.525 33.3675C118.525 33.3675 93.1712 39.179 80.0248 24.166C80.0248 42.569 80.0248 112.791 80.0248 124.414C109.604 116.665 118.526 90.0294 118.525 79.8593C118.524 69.6893 118.525 33.3675 118.525 33.3675Z"
      fill="#F4EDED"
    />
    <path
      d="M112.134 120.622L103.593 112.081L112.134 103.54C114.492 101.182 118.316 101.182 120.674 103.54C123.033 105.899 123.033 109.723 120.674 112.081L112.134 120.622Z"
      fill="#868686"
    />
    <path
      d="M95.365 80.6122L49.6895 55.045C47.3478 53.7375 44.3848 54.1524 42.4852 56.0522L37.166 61.3714L75.8434 100.134L95.365 80.6122Z"
      fill="#ADADAD"
    />
    <path
      d="M124.874 130.316L99.6327 84.8828L80.1113 104.404L118.545 142.838L123.864 137.519C125.764 135.619 126.179 132.656 124.874 130.316Z"
      fill="#ADADAD"
    />
    <path
      d="M60.9086 77.9161L52.3677 69.3752L65.179 56.5638C67.5376 54.2053 71.3614 54.2053 73.7198 56.5638C76.0783 58.9224 76.0783 62.7462 73.7198 65.1046L60.9086 77.9161Z"
      fill="#868686"
    />
    <path
      d="M95.365 77.6122L49.6895 52.045C47.3478 50.7375 44.3848 51.1524 42.4852 53.0522L37.166 58.3714L75.8434 97.1337L95.365 77.6122Z"
      fill="url(#paint0_linear_841_15271)"
    />
    <path
      d="M124.875 127.316L99.6342 81.8828L80.1128 101.404L118.547 139.838L123.866 134.519C125.766 132.619 126.18 129.656 124.875 127.316Z"
      fill="url(#paint1_linear_841_15271)"
    />
    <path
      d="M73.3841 131.468L64.8434 140.008L56.3026 122.927L39.2207 114.386L47.7615 105.845L61.982 108.706L106.102 58.0272C110.03 53.5006 116.906 52.7749 121.688 57.5578C126.428 62.2978 125.746 69.216 121.219 73.1446L70.5229 117.247L73.3841 131.468Z"
      fill="url(#paint2_linear_841_15271)"
    />
    <path
      d="M73.3841 131.468L64.8434 140.008L56.3026 122.927L39.2207 114.386L47.7615 105.845L61.982 108.706L106.102 58.0272C110.03 53.5006 116.906 52.7749 121.688 57.5578C126.428 62.2978 125.746 69.216 121.219 73.1446L70.5229 117.247L73.3841 131.468Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M71.3841 130.468L62.8434 139.008L54.3026 121.927L37.2207 113.386L45.7615 104.845L59.982 107.706L106.102 55.0272C110.03 50.5006 116.906 49.7749 121.688 54.5578C126.428 59.2978 125.746 66.216 121.219 70.1446L68.5229 116.247L71.3841 130.468Z"
      fill="url(#paint3_linear_841_15271)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_841_15271"
        x1={69.498}
        y1={89.5}
        x2={36.6247}
        y2={57.7455}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008CFF" />
        <stop offset={1} stopColor="#59D7FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_15271"
        x1={102.872}
        y1={81.8828}
        x2={109.5}
        y2={139}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0268BC" />
        <stop offset={1} stopColor="#59D7FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_841_15271"
        x1={105.998}
        y1={55}
        x2={67.4981}
        y2={128.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3A3A3" />
        <stop offset={1} stopColor="#D9D9D9" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_841_15271"
        x1={103.998}
        y1={54}
        x2={65.498}
        y2={127.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3A3A3" />
        <stop offset={1} stopColor="#D9D9D9" />
      </linearGradient>
    </defs>
  </svg>
);
export { TravBenefitIcon };
