import {
  PANCardWpr,
  FetchedNameContainer,
  IconContainer,
  PANInputContainer,
  InfoLeftContainer,
  PANReqdContainer,
} from "./PANCardComp.style";
import { ImageTag, ParaTag, TMFloatingInput } from "@tm/static";
import { InfoIcon, SafetyIcon } from "../../icons";
import { theme } from "@tm/utility/global";
import { TickIcon } from "../../icons/TickIcon";

interface PANCardCompProps {
  panInputDetails: {
    labelTxt?: string;
    panInputValue?: string;
    handlePanChange?: (e: any) => void;
    panErrorState?: string;
    verifiedTag?: string;
    maxLength?: number;
  };
  panReqdTxt?: string;
  infoClickHandler?: () => void;
  containerStyle?: {};
  nameInputDetails?: {
    labelTxt?: string;
    nameInputValue?: string;
    handleNameChange?: (e: any) => void;
    nameErrorState?: string;
    maxLength?: number;
  };
  fetchedName?: string;
  showNameDetails?: boolean;
  safetyIcon?: string;
  infoIcon?: string;
  fetchedFromPanTxt?: string;
}

const PANCardComp = ({
  panInputDetails,
  containerStyle,
  panReqdTxt,
  infoClickHandler,
  fetchedName,
  nameInputDetails,
  showNameDetails = false,
  safetyIcon,
  infoIcon,
  fetchedFromPanTxt,
}: PANCardCompProps) => {
  return (
    <PANCardWpr containerStyle={containerStyle}>
      <PANInputContainer>
        <TMFloatingInput
          labelTxt={panInputDetails.labelTxt}
          inputValue={panInputDetails?.panInputValue}
          handleChange={panInputDetails?.handlePanChange}
          errorState={panInputDetails?.panErrorState}
          verifiedTag={panInputDetails?.verifiedTag}
          maxLength={panInputDetails?.maxLength}
        />
        {panReqdTxt && (
          <PANReqdContainer>
            <InfoLeftContainer>
              {safetyIcon ? <ImageTag src={safetyIcon} /> : <SafetyIcon />}
              <ParaTag margin={"0px 4px"} color={theme.greyText}>
                {panReqdTxt}
              </ParaTag>
            </InfoLeftContainer>

            {infoIcon ? (
              <div onClick={infoClickHandler}>
                {<ImageTag src={infoIcon} showCursorPointer />}
              </div>
            ) : (
              <div onClick={infoClickHandler}>
                <InfoIcon color={theme.blueText} />
              </div>
            )}
          </PANReqdContainer>
        )}
      </PANInputContainer>
      {showNameDetails &&
        (fetchedName ? (
          <FetchedNameContainer>
            <IconContainer>
              <TickIcon />
            </IconContainer>
            <ParaTag fontSize="sm">{fetchedName}</ParaTag>
            {fetchedFromPanTxt && (
              <ParaTag fontSize="xs" color={theme.greyText6}>
                -{fetchedFromPanTxt}
              </ParaTag>
            )}
          </FetchedNameContainer>
        ) : (
          <TMFloatingInput
            labelTxt={nameInputDetails?.labelTxt}
            inputValue={nameInputDetails?.nameInputValue}
            handleChange={nameInputDetails?.handleNameChange}
            errorState={nameInputDetails?.nameErrorState}
            maxLength={nameInputDetails?.maxLength}
          />
        ))}
    </PANCardWpr>
  );
};

export default PANCardComp;
