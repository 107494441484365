interface ArrowIconInterface {
  color?: string;
  size?: number;
  cursor?: string;
  margin?: string;
}

export const ArrowIcon = ({
  color = "#008cff",
  size = 16,
  cursor = "pointer",
  margin = "0px",
  ...props
}: ArrowIconInterface) => (
  <svg
    style={{ cursor: cursor, margin: margin }}
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99984 13.8333L7.04984 12.9L10.7832 9.16663H2.6665V7.83329H10.7832L7.04984 4.09996L7.99984 3.16663L13.3332 8.49996L7.99984 13.8333Z"
      fill={color}
    />
  </svg>
);
