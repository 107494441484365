import React, { useState, useEffect } from "react";
import { DayPicker, DayClickEventHandler, DateRange } from "react-day-picker";
import { isAfter, isBefore } from "date-fns";

import { FormattedText, SpanTag } from "@tm/static";
import { theme } from "@tm/utility/global";

import { calendarLabelFormatter } from "../../utils/index";

import {
  DatePickerWrap,
  DateTab,
  DateLabel,
  DateTabList,
  ErrorToast,
} from "./DatePickerComp.style";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface datePickerInterface {
  startDateLabel?: string;
  endDateLabel?: string;
  from?: string | Date;
  to?: string | Date;
  maxEndDate?: Date;
  handleDayClick: DayClickEventHandler | undefined;
  dateTab: number | undefined;
  handleDateTab?: (index: number) => void;
  selected?: DateRange | undefined;
  showToast?: boolean;
  toastMessage?: string;
}

const DatePickerComp = ({
  startDateLabel,
  endDateLabel,
  from,
  to,
  maxEndDate,
  dateTab,
  handleDateTab,
  handleDayClick,
  selected,
  showToast,
  toastMessage,
}: datePickerInterface) => {
  const weekDayNameFormatter = (date: Date) => {
    return <span>{date.toDateString()[0]}</span>;
  };

  const monthNameFormatter = (date: Date) => {
    return (
      <span>
        <b>{MONTHS[date.getMonth()]}</b>
        &nbsp;
        <span>{date.getFullYear().toString().slice(-2)}</span>
      </span>
    );
  };

  const disabledDays = (date: Date) => {
    return (
      isBefore(date, new Date()) || !!(maxEndDate && isAfter(date, maxEndDate))
    );
  };

  return (
    <DatePickerWrap id="datePickerContainer">
      <DateTab>
        <DateTabList onClick={() => handleDateTab?.(0)}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.175 3.49118V2.35529H9.90518V3.49118H6.09566V2.35529H4.82582C4.82582 1.94366 4.82582 3.49118 4.82582 3.49118L3.55597 3.49118C2.85121 3.49118 2.29248 4.00233 2.29248 4.62707L2.28613 12.5783C2.28613 13.2031 2.85121 13.7142 3.55597 13.7142H12.4449C13.1433 13.7142 13.7147 13.2031 13.7147 12.5783V4.62707C13.7147 4.00233 13.1433 3.49118 12.4449 3.49118H11.175ZM12.4449 12.5783H3.55597V5.76296H12.4449V12.5783ZM5.01319 6.89886H7.2989V9.18457H5.01319V6.89886Z"
              fill="#9B9B9B"
            />
          </svg>
          <SpanTag fontSize="sm">{startDateLabel}</SpanTag>
          <DateLabel activeTab={dateTab == 0}>
            <SpanTag
              fontSize="sm"
              fontWeight={dateTab == 0 ? "bold" : "regular"}
              color={dateTab == 0 ? theme.blueText : theme.greyText}
            >
              {from ? calendarLabelFormatter(from) : "Select"}
            </SpanTag>
          </DateLabel>
        </DateTabList>
        <DateTabList>-</DateTabList>
        <DateTabList onClick={() => handleDateTab?.(1)}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.175 3.49118V2.35529H9.90518V3.49118H6.09566V2.35529H4.82582C4.82582 1.94366 4.82582 3.49118 4.82582 3.49118L3.55597 3.49118C2.85121 3.49118 2.29248 4.00233 2.29248 4.62707L2.28613 12.5783C2.28613 13.2031 2.85121 13.7142 3.55597 13.7142H12.4449C13.1433 13.7142 13.7147 13.2031 13.7147 12.5783V4.62707C13.7147 4.00233 13.1433 3.49118 12.4449 3.49118H11.175ZM12.4449 12.5783H3.55597V5.76296H12.4449V12.5783ZM5.01319 6.89886H7.2989V9.18457H5.01319V6.89886Z"
              fill="#9B9B9B"
            />
          </svg>
          <SpanTag fontSize="sm">{endDateLabel}</SpanTag>
          <DateLabel activeTab={dateTab == 0}>
            <SpanTag
              fontWeight={dateTab == 1 ? "bold" : "regular"}
              fontSize="sm"
              color={dateTab == 1 ? theme.blueText : theme.greyText}
            >
              {to ? calendarLabelFormatter(to) : "Select"}
            </SpanTag>
          </DateLabel>
        </DateTabList>
      </DateTab>
      <DayPicker
        mode="range"
        numberOfMonths={2}
        selected={selected}
        disabled={disabledDays}
        onDayClick={handleDayClick}
        formatters={{
          formatWeekdayName: weekDayNameFormatter,
          formatCaption: monthNameFormatter,
        }}
      />
      {showToast && (
        <ErrorToast>
          <SpanTag color={theme.redText}>
            <FormattedText value={toastMessage} />
          </SpanTag>
        </ErrorToast>
      )}
    </DatePickerWrap>
  );
};

export default DatePickerComp;
