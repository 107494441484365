import { useEffect, useState } from "react";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../utils/clientStorageUtils";
import { LOCAL_STORAGE_KEYS, PDT_EVENTS } from "../../constants";
import { useGlobalSelector } from "../useGlobalSelector";
import { ListingReducerType } from "../../store/forexListingReducer";
import { useListingPdtLogger } from "../usePdtLogger";
import useListingPdtLoggerV2 from "../useListingPdtLoggerV2";
import { getEventDetails } from "../../utils";

export default function useCoachmarks() {
  const currency_active_orders = useGlobalSelector(
    (state: ListingReducerType) => state.persona?.currency_active_orders
  ) as unknown as number | null;
  const [showCurrencyOrderCoachmark, updateShowCurrencyOrderCoachmark] =
    useState(false);

  const { sendPdtCoachmarkView, sendPdtCoachmarkButtonClick } =
    useListingPdtLogger();
  const { sendPdtDataV2 } = useListingPdtLoggerV2();

  function isCoachmarkBridgeAvailable() {
    // This will be updated when bridge name is defined
    return false;
  }

  useEffect(() => {
    const currencyOrderCoachmarkShown = getLocalStorage(
      LOCAL_STORAGE_KEYS.CURRENCY_ORDER_COACHMARK_KEY
    );
    if (
      currency_active_orders &&
      !currencyOrderCoachmarkShown &&
      isCoachmarkBridgeAvailable()
    ) {
      updateShowCurrencyOrderCoachmark(true);
      setLocalStorage(LOCAL_STORAGE_KEYS.CURRENCY_ORDER_COACHMARK_KEY, true);
      sendPdtCoachmarkView();
      sendPdtDataV2({
        event_detail: getEventDetails({
          event_name: PDT_EVENTS.COACHMARK_VIEWED,
        }),
      });
    }
  }, [currency_active_orders]);

  function closeCurrencyOrderCoachmark(e: any) {
    e.stopPropagation();
    updateShowCurrencyOrderCoachmark(false);
    sendPdtCoachmarkButtonClick();
    sendPdtDataV2({
      event_detail: getEventDetails({
        event_name: PDT_EVENTS.COACKMARK_BUTTON_CLICK,
      }),
    });
  }

  return {
    showCurrencyOrderCoachmark,
    closeCurrencyOrderCoachmark,
  };
}
