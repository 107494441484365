import React, { useState, useEffect } from "react";
import { FlexComp, OtpPlaceholder, Spinner } from "@tm/static";

interface OtpCompProps {
  errorMsg?: string;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
}

const OtpComp = ({ errorMsg = "", setOtp }: OtpCompProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleOtpChange = (newOtp: string) => {
    setOtp(newOtp);
    if (newOtp.length === 6) {
      setLoading(true);
    } else {
      setError("");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      setError("");
    }
    let timer = setTimeout(() => {
      if (loading) {
        setError(errorMsg);
        setLoading(false);
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [loading]);

  return (
    <FlexComp alignItems="center">
      <OtpPlaceholder
        numInputs={6}
        onChange={handleOtpChange}
        error={error}
        setError={setError}
        loading={loading}
      />
      {loading && (
        <Spinner
          thickness={2}
          spinnerWidth={22}
          customStyle={{ margin: "0px 20px" }}
        />
      )}
    </FlexComp>
  );
};

export default OtpComp;
