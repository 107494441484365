import { useDevice } from "../../hooks/useDevice";
import { WhiteBackgroundStyled } from "../../static/CommonStyle/CommonStyles.style";
import HappyClientsBanner from "../../static/components/HappyClientsBanner/HappyClientsBanner";
import { BMFListingContainer } from "../../static/components/ListingPageComponents/ListingPageComponents.style";
import { poweredByLogos } from "../../static/mockData";

const TrustUs = (props: any) => {
  const device = useDevice();
  return (
    <WhiteBackgroundStyled>
      <BMFListingContainer
        id={props?.id}
        margin={device === "lg" ? "0px auto" : "-50px 0px 0px"}
      >
        <HappyClientsBanner
          rightText="12,00,000+"
          leftText="Why Trust Us"
          rightSubText="Happy Travellers with Forex Transactions"
          poweredByText="Forex Powered by"
          poweredByLogosArr={poweredByLogos}
        />
      </BMFListingContainer>
    </WhiteBackgroundStyled>
  );
};

export default TrustUs;
