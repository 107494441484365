import React, { useState } from "react";
import {
  LandingFooterStyle,
  LandingFooterWrap,
  LandingFooterImg,
  LandingFooterSub,
  LandingFooterList,
  LandingFooterFlex,
  LandingFooterCopyRight,
} from "./LandingFooter2.style";
import { InnerContentWrap } from "../../CommonStyle/CommonStyles.style";
import MmtInitiative from "../../icons/MmtInitiative.png";
import CitiesIcon from "../../icons/CitiesIcon.svg";
import OthersIcon from "../../icons/OthersIcon.svg";
import QuickLinks from "../../icons/QuickLinks.svg";
import DnldIcon from "../../icons/DnldIcon.svg";
import FollowIcon from "../../icons/FollowIcon.svg";
import BlogIcon from "../../icons/BlogIcon.svg";
import TwitterIcon from "../../icons/twitterIcon.png";
import FaceBookIcon from "../../icons/facebookIcon.png";
import LinkedinIcon from "../../icons/linkedinIcon.png";
import GooglePlayLogo from "../../icons/googlePlayLogo.png";
import AppleStoreLogo from "../../icons/appleStoreLogo.png";
import { theme } from "@tm/utility/global";
import { FlexComp, SpanTag, AnchorTag, ImageComponent } from "@tm/static";

const footerLinks = {
  cities: [
    "Mumbai",
    "Bengaluru",
    "Chennai",
    "Pune",
    "Hyderabad",
    "Kolkata",
    "Lucknow",
    "Jaipur",
    "Surat",
    "Bhopal",
    "Goa",
    "Noida",
    "Thane",
    "Varanasi",
  ],
  blog: [
    "Mumbai",
    "Bengaluru",
    "Chennai",
    "Pune",
    "Hyderabad",
    "Kolkata",
    "Lucknow",
    "Jaipur",
    "Surat",
    "Bhopal",
    "Goa",
    "Noida",
    "Thane",
    "Varanasi",
    "Mumbai",
    "Bengaluru",
    "Chennai",
    "Pune",
    "Hyderabad",
    "Kolkata",
    "Lucknow",
    "Jaipur",
    "Surat",
    "Bhopal",
    "Goa",
    "Noida",
    "Thane",
    "Varanasi",
    "Mumbai",
    "Bengaluru",
    "Chennai",
    "Pune",
    "Hyderabad",
    "Kolkata",
    "Lucknow",
    "Jaipur",
    "Surat",
    "Bhopal",
    "Goa",
    "Noida",
    "Thane",
    "Varanasi",
  ],
  quickLinks: [
    "Mumbai",
    "Bengaluru",
    "Chennai",
    "Pune",
    "Hyderabad",
    "Kolkata",
    "Lucknow",
    "Jaipur",
    "Surat",
    "Bhopal",
    "Goa",
    "Noida",
    "Thane",
    "Varanasi",
  ],
  others: [
    "About us",
    "Privacy Policy",
    "User Agreement",
    " Terms of Services",
    " Mail us",
  ],
};

interface landingFooterInterface {}

const LandingFooter = ({}: landingFooterInterface) => {
  const [link, setLink] = useState(-1);

  const handleClick = (index: number) => {
    index != index ? setLink(index) : setLink(-1);
  };

  return (
    <LandingFooterStyle>
      <InnerContentWrap>
        <LandingFooterWrap>
          <LandingFooterImg>
            <ImageComponent
              width="325px"
              height="27px"
              src={MmtInitiative.src}
            />
          </LandingFooterImg>
          <LandingFooterSub>
            <ImageComponent width="20px" height="20px" src={CitiesIcon.src} />
            <SpanTag fontSize="sm" fontWeight="bold" color={theme.whiteText}>
              BUSINESS CITIES
            </SpanTag>
          </LandingFooterSub>

          <LandingFooterList>
            {footerLinks.cities &&
              footerLinks.cities.map((item, index) => {
                return (
                  <li key={index}>
                    <AnchorTag
                      color={theme.whiteText}
                      aria-label="cities"
                      hrefProp="#"
                    >
                      {item}
                    </AnchorTag>
                  </li>
                );
              })}
          </LandingFooterList>

          <LandingFooterSub>
            <ImageComponent width="20px" height="20px" src={BlogIcon.src} />
            <SpanTag fontSize="sm" fontWeight="bold" color={theme.whiteText}>
              {" "}
              BLOG
            </SpanTag>
          </LandingFooterSub>

          <LandingFooterList>
            {footerLinks.blog &&
              footerLinks.blog.map((item, index) => {
                return (
                  <li key={index}>
                    <AnchorTag
                      color={theme.whiteText}
                      aria-label="blog"
                      hrefProp="#"
                    >
                      {item}
                    </AnchorTag>
                  </li>
                );
              })}
          </LandingFooterList>
          <LandingFooterSub>
            <ImageComponent width="20px" height="20px" src={QuickLinks.src} />
            <SpanTag fontSize="sm" fontWeight="bold" color={theme.whiteText}>
              {" "}
              QUICK LINKS
            </SpanTag>
          </LandingFooterSub>

          <LandingFooterList>
            {footerLinks.quickLinks &&
              footerLinks.quickLinks.map((item, index) => {
                return (
                  <li key={index}>
                    <AnchorTag color={theme.whiteText} hrefProp="#">
                      {item}
                    </AnchorTag>
                  </li>
                );
              })}
          </LandingFooterList>

          <LandingFooterSub>
            <ImageComponent
              width="20px"
              height="20px"
              src="https://tripmoneycmsimgak.mmtcdn.com/img/other_Icon_5614215b70.png"
            />
            <SpanTag fontSize="sm" fontWeight="bold" color={theme.whiteText}>
              {" "}
              OTHERS
            </SpanTag>
          </LandingFooterSub>
          <LandingFooterList>
            {footerLinks.others &&
              footerLinks.others.map((item, index) => {
                return (
                  <li key={index}>
                    <AnchorTag color={theme.whiteText} hrefProp="#">
                      {item}
                    </AnchorTag>
                  </li>
                );
              })}
          </LandingFooterList>

          <LandingFooterFlex>
            <div>
              <LandingFooterSub>
                <ImageComponent
                  width="18px"
                  height="18px"
                  src={FollowIcon.src}
                />
                <SpanTag
                  fontSize="sm"
                  fontWeight="bold"
                  color={theme.whiteText}
                >
                  FOLLOW US
                </SpanTag>
              </LandingFooterSub>
              <FlexComp gap="16px">
                <AnchorTag hrefProp="#">
                  <ImageComponent
                    width="27px"
                    height="27px"
                    src={TwitterIcon.src}
                  />
                </AnchorTag>
                <AnchorTag hrefProp="#">
                  <ImageComponent
                    width="27px"
                    height="27px"
                    src="https://tripmoneycmsimgak.mmtcdn.com/img/fb_Icon_8eab71f726.png"
                  />
                </AnchorTag>
                <AnchorTag aria-label="linkedin" hrefProp="#">
                  <ImageComponent
                    width="27px"
                    height="27px"
                    src="https://tripmoneycmsimgak.mmtcdn.com/img/Linkedin_Icon_fec59ba5c7.png"
                  />
                </AnchorTag>
              </FlexComp>
            </div>
            <div>
              <LandingFooterSub>
                <ImageComponent
                  width="18px"
                  height="18px"
                  src="https://tripmoneycmsimgak.mmtcdn.com/img/download_Icon_f3c8f1d5d2.png"
                />
                <SpanTag
                  fontSize="sm"
                  fontWeight="bold"
                  color={theme.whiteText}
                >
                  DOWNLOAD
                </SpanTag>
              </LandingFooterSub>

              <FlexComp gap="10px">
                <AnchorTag hrefProp="#">
                  <ImageComponent
                    width="128px"
                    height="36px"
                    src="https://tripmoneycmsimgak.mmtcdn.com/img/play_Store_Icon_ddbbe2de64.png"
                  />
                </AnchorTag>
                <AnchorTag hrefProp="#">
                  <ImageComponent
                    width="128px"
                    height="36px"
                    src="https://tripmoneycmsimgak.mmtcdn.com/img/app_Store_Icon_07d258a1b2.png"
                  />
                </AnchorTag>
              </FlexComp>
            </div>
            <LandingFooterCopyRight>
              <SpanTag fontSize="xs" color={theme.whiteText} fontWeight="bold">
                {" "}
                © 2022 MAKEMYTRIP PVT.LTD
              </SpanTag>
              <SpanTag fontSize="xs" color={theme.whiteText} fontWeight="bold">
                Country India
              </SpanTag>
            </LandingFooterCopyRight>
          </LandingFooterFlex>
        </LandingFooterWrap>
      </InnerContentWrap>
    </LandingFooterStyle>
  );
};

export default LandingFooter;
