import { useState } from "react";

import { landingFaqDataProps } from "@tm/static/data";

import {
  LandingFaqStyle,
  LandingFaqList,
  LandingFaqTitle,
  LandingFaqQues,
} from "./LandingFaq.style";
import { InnerContentWrap } from "../../CommonStyle/CommonStyles.style";
import { useBreakpointV2 } from "@tm/ui-widgets";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
interface FaqType {
  customTitle?: string;
  customFaq?: string;
  faq: {
    ques?: TextNodeType | string;
    info?: string;
  }[];
  margin?: string;
}

const LandingFaq = ({ customTitle, customFaq, faq, margin }: FaqType) => {
  const [exp, setExp] = useState(-1);
  const device = useBreakpointV2();

  const handleClick = (index: number) => {
    exp != index ? setExp(index) : setExp(-1);
  };

  return (
    <LandingFaqStyle margin={margin} customFaq={customFaq}>
      <InnerContentWrap>
        {customTitle && (
          <LandingFaqTitle
            marginLeft={device === "sm" ? "16px" : ""}
            marginBottom="16px"
          >
            {customTitle && <FormattedText value={customTitle} />}
          </LandingFaqTitle>
        )}
        <LandingFaqList customFaq={customFaq}>
          {faq.map((item, index: number) => {
            return (
              <li
                className={`${exp == index ? "open" : "close"}`}
                key={index}
                onClick={() => handleClick(index)}
              >
                <LandingFaqQues customFaq={customFaq}>
                  {item.ques && <FormattedText value={item.ques} />}
                </LandingFaqQues>
                {exp == index && (
                  <p>{item.info && <FormattedText value={item.info} />}</p>
                )}
              </li>
            );
          })}
        </LandingFaqList>
      </InnerContentWrap>
    </LandingFaqStyle>
  );
};

export default LandingFaq;
