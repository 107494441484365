import BMFLogo from "../BMFLogo";

export default function Header({ currencyData }: any) {
  return (
    <header>
      <div className="headerInner">
        <a href={currencyData?.header?.logo_redirection}>
          <BMFLogo />
        </a>
      </div>
    </header>
  );
}
