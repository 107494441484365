import React, { useState } from "react";

import {
  CarouselCntWrp,
  CarouselCont,
  CarouselSlider,
  CarouselElmnt,
  SlideShowDot,
  SlideShowDotsCnt,
} from "./MarketingBanner.style";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import { useDevice } from "../../../hooks/useDevice";
import { useListingPdtLogger } from "../../../hooks/usePdtLogger";
import { useGlobalConfig } from "../../../hooks/useGlobalConfig";
import { Goibibo, MakeMyTrip, PDT_EVENTS } from "../../../constants";
import useListingPdtLoggerV2 from "../../../hooks/useListingPdtLoggerV2";
import { getEventDetails } from "../../../utils";

interface ICarouselData {
  link?: string;
  cta?: string;
  partner_to_link?: {
    mmt?: string;
    gi?: string;
  };
  partner_to_cta?: {
    mmt?: string;
    gi?: string;
  };
}

interface MarketingBannerProps {
  carouselData?: ICarouselData[];
  delay?: number;
}

const MarketingBanner: React.FC<MarketingBannerProps> = ({
  carouselData,
  delay = 5,
}) => {
  const { sendPdtMarketingBannerCtaClickEvent } = useListingPdtLogger();
  const { sendCTAData } = useListingPdtLoggerV2();

  const { partner } = useGlobalConfig();

  const device = useDevice();

  const [activeIdx, setActiveIdx] = useState<number>(0);

  const handleOnClick = (item: ICarouselData) => {
    sendPdtMarketingBannerCtaClickEvent();
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.MARKETING_BANNER_CTA,
    });
    sendCTAData({ eventDetail });

    let cta: string | undefined | null = "";
    if (partner === MakeMyTrip) {
      cta = item?.partner_to_cta?.mmt;
    } else if (partner === Goibibo) {
      cta = item?.partner_to_cta?.gi;
    }
    if (cta) {
      window.location.href = cta;
    }
  };

  const getLinkBasedOnPartner = (item: ICarouselData) => {
    if (partner === MakeMyTrip) {
      return item?.partner_to_link?.mmt || "";
    } else if (partner === Goibibo) {
      return item?.partner_to_link?.gi || "";
    }
    return "";
  };

  const renderCarouselItems = carouselData?.map(
    (item: ICarouselData, idx: number) => (
      <CarouselElmnt key={"banner" + idx} onClick={() => handleOnClick(item)}>
        <ImageTag
          src={getLinkBasedOnPartner(item)}
          height={device === "sm" ? "180px" : "280px"}
          width="100%"
        />
      </CarouselElmnt>
    )
  );

  const renderDots = carouselData?.map((_, idx: number) => (
    <SlideShowDot
      key={"banner" + _}
      isActive={idx === activeIdx}
      onClick={() => updateActiveIndex(idx)}
    />
  ));

  const updateActiveIndex = (idx: number) => {
    if (idx === carouselData?.length) setActiveIdx(0);
    else if (idx < 0) setActiveIdx((carouselData?.length ?? 1) - 1);
    else setActiveIdx(idx);
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (activeIdx === carouselData?.length - 1) setActiveIdx(0);
  //     else setActiveIdx((prevIdx) => prevIdx + 1);
  //   }, delay * 1000);
  //   return () => clearTimeout(timer);
  // }, [activeIdx, carouselData?.length, delay]);

  return (
    <CarouselCntWrp>
      <CarouselCont>
        <CarouselSlider translate={-activeIdx * 100}>
          {renderCarouselItems}
        </CarouselSlider>
      </CarouselCont>
      {(carouselData?.length ?? 0) > 1 && (
        <SlideShowDotsCnt>{renderDots}</SlideShowDotsCnt>
      )}
    </CarouselCntWrp>
  );
};

export default MarketingBanner;
