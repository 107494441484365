export const API_BASE_URL =
  "https://forex.makemytrip.com/forex-client-backend/cb";

export const API_ENDPOINT = {
  GET_FOREX_RATES: `${API_BASE_URL}/card-management/api/v1/forex-rate/PC_CN`,
};

export const PROGRAMS = {
  FOREX_BMF_MAKEMYTRIP: "FOREX_BMF_MAKEMYTRIP",
};
