import { CARD_TYPE, FAQ_CLICK_HERE_LINK_ID, PARTNER } from "../../constants";
import { PersuasionObject, StartOrderPersuasionProps } from "../../types";
import { BtnProp } from "../components/ui-atoms/LandingButton/ButtonType";

export interface persuasionDataProps {
  id: string;
  boldText?: string;
  normalText: string;
}

export interface footerDataProps {
  id: string;
  label: string;
  listPoints: {
    id: string;
    label: string;
  }[];
  icon: string;
}

export type aboveAndBelowTextListItemProps =
  | { id: string; text: string; linkText?: string; href?: string }
  | { id: string; text?: string; linkText: string; href?: string };

// ADDTHEM
export interface landingFaqDataProps {
  ques: string;
  answer: {
    id?: string;
    aboveTextList?: aboveAndBelowTextListItemProps[];
    pointsList?: { id: string; label?: string; info: string }[];
    belowTextList?: aboveAndBelowTextListItemProps[];
  }[];
}

export interface landingCardCompareDataProps {
  crdRef?: string;
  crdName: string;
  crdImg?: string;
  onBoarding: boolean;
  access: {
    icon?: boolean;
    text?: string;
  };
  forexRate: {
    rate: string;
    info: string;
  };
  delivery: string;
  charges: string[];
  btn?: string;
}

export interface landingMarqueeDataProps {
  id: string;
  text?: string;
  boldText?: string;
  blueText?: string;
}

export interface slctCtryMdlDataCcyListItemProps {
  id: string;
  countryName: string;
  currency: string;
  currencySubLabel?: string;
  isChecked: boolean;
}

export interface selectCountryModalDataProps {
  id: string;
  leftLabel: string;
  rightLabel: string;
  currencyList: slctCtryMdlDataCcyListItemProps[];
}

export interface chooseModalSecScreenDataProps {
  id: string;
  label: string;
  isSelected?: boolean;
}

export interface chooseModalThirdScreenDataProps {
  id: string;
  label: string;
  icon: string;
  isSelected?: boolean;
}

export interface cardCtaProps {
  label?: string;
  subLabel?: string;
  btnOptions?: BtnProp;
  linkBtnInMobile?: {
    label?: string;
    hrefProp?: string;
    onClick?: () => void;
  };
  toAdd?: boolean;
  addText?: string;
  isLoaded?: boolean;
  isLoadedIcon?: string;
  isPrimaryButton?: boolean;
}
//ADDTHEM
export interface landingCardShowcaseDataProps {
  id?: typeof CARD_TYPE[keyof typeof CARD_TYPE];
  cta_redirect?: number;
  version?: number;
  logoSrc: string;
  label: string;
  subLabel?: string;
  deliveryLabel?: string;
  arrowIconSrc?: string;
  order_id?: string;
  featuresArr?: {
    id: string;
    label: string;
  }[];
  featureArrDesktop?: {
    id: string;
    label: string;
  }[];
  feature_flag: {
    solve_reload?: boolean;
  };
  backendPersuasion?: Array<{
    theme: string;
    value: string;
  }>;
  isleftOffBefore?: {
    text?: {
      blackBoldText?: string;
      greenBoldText?: string;
      normalText?: string;
    };
    iconSrc?: string;
    linkBtn?: {
      label: string;
      onClick: () => void;
    };
  };
  isRecommended?: {
    text: {
      blackBoldText?: string;
      greenBoldText?: string;
      normalText?: string;
    };
    iconSrc?: string;
    linkBtn?: {
      label: string;
      onClick: () => void;
    };
  };
  isUnavailableAtLoc?: {
    normalText?: string;
    linkText?: {
      text: string;
      hrefLink: string;
    };
  };
  docsPending?: {
    label?: string;
    btnProps: BtnProp;
    iconSrc?: string;
    order_id?: string;
    redirect_to?: string;
  };
  viewOrdersPersuasion?: {
    linkBtnInMobile?: {
      label?: string;
      hrefProp?: string;
      onClick?: () => void;
    };
    label?: string;
    btnProps: BtnProp;
    iconSrc?: string;
  };
  logoDesc: {
    normalText: string;
    boldText?: string;
  };
  cta?: {
    user_state?: string;
    user_state_data?: {
      order_id?: string;
      next_signal_state: string;
      current_signal_state: string;
      previous_signal_state: string;
      signal_data?: {
        checkout_url: string;
      };
    };
  };

  isRecommendNotification?: boolean;
  onClick?: () => void;
  loadCurrency?: cardCtaProps;
  startOrder: StartOrderPersuasionProps;
  persuasions: PersuasionObject[];
  program_details: {
    type: string;
  };
  showBestRate?: boolean;
}

export interface newCardFeaturesModalDataProps {
  id: string;
  boldText: string;
  normalText: string;
}

export interface chooseModalCountryDataProps {
  id: string;
  label: string;
  isSelected: boolean;
  logoSrc: string;
}

export interface landingForexOfferingCardDataProps {
  id: string;
  cardTitle: string;
  cardInfo?: string;
  cardImg: string;
  cardFeatList: {
    id: string;
    boldText?: string;
    normalText: string;
  }[];
  ctaTxt: string;
  cta?: {
    user_state?: string;
    user_state_data?: {
      order_id?: string;
      next_signal_state: string;
      current_signal_state: string;
      previous_signal_state: string;
      signal_data?: {
        checkout_url: string;
      };
    };
  };
  cta_redirect?: number;
  version?: number;
}

export interface panFaqsDataProps {
  id: string;
  question: string;
  answers: string[];
  cta: string;
}

export interface cardCongratsImgDataProps {
  className: string;
  src: string;
  altTag: string;
}

export interface landingIconTextTagDataProps {
  label: string;
  subLabel?: string;
  btnHandler?: () => void;
}

export const persuasionData: persuasionDataProps[] = [
  {
    id: "1",
    boldText: "Best rates.",
    normalText: "Upto 10% savings over Airport Counter rates",
  },
  {
    id: "2",
    boldText: "100+ RBI-licensed money changes",
    normalText: "across 50+ cities",
  },
  {
    id: "3",
    boldText: "Attractive offers",
    normalText: "& cashback",
  },
];

// aboveText?: string;
// pointsList?: { id: string; label: string; info: string }[];
// belowText?: string;

//ADDTHEM
export const landingCardFaqData: landingFaqDataProps[] = [
  {
    ques: `What is a Multicurrency Card?`,
    answer: [
      {
        id: "1",
        aboveTextList: [
          {
            id: "1",
            text: `A Multicurrency Card is a prepaid card designed specifically for international travelers. It is fully compliant with RBI regulations and provides peace of mind with 100% acceptance at all merchants in over 150 countries. It also provides digital controls within the app, allowing you to lock/unlock the card instantly and offers several benefits, without the need to link your bank account to the card.`,
          },
          {
            id: FAQ_CLICK_HERE_LINK_ID.WHAT_IS_MX_CARD_CLICK_HERE,
            linkText: "Click here",
          },
          {
            id: "1",
            text: `to know more.`,
          },
        ],
      },
    ],
  },
  {
    ques: `Who should use Multicurrency Card?`,
    answer: [
      {
        id: "1",
        aboveTextList: [
          {
            id: "1",
            text: "Multicurrency Card is ideal for anyone who values peace of mind during international trips and wants to avoid all forex markups and hidden charges.",
          },
        ],
      },
    ],
  },
  {
    ques: `Is there any documentation or KYC required?`,
    answer: [
      {
        id: "1",
        aboveTextList: [
          {
            id: "1",
            text: "Yes, as per RBI guidelines the traveller needs to upload his/her PAN card, Indian passport (front & back), Air ticket after making payment for forex transactions on Makemytrip. These will be verified before processing the order.",
          },
        ],
      },
    ],
  },
  {
    ques: `Why should I not use a Debit/Credit card instead?`,
    answer: [
      {
        id: "1",
        aboveTextList: [
          {
            id: "1",
            text: "Using a Debit/Credit card for international transactions may come with several drawbacks such as high markups (up to 5%), additional service charges, hidden fees, low acceptance at certain places, higher risk of scams, potential misuse in case of loss, negative impact on your CIBIL score, and fluctuating exchange rates. You can avoid all these by opting for Multicurrency Card.",
          },
        ],
      },
    ],
  },
  {
    ques: "Does this card give reward points?",
    answer: [
      {
        id: "1",
        aboveTextList: [
          {
            id: "1",
            text: `No, this card does not offer reward points. However, it is still the most rewarding option due to its lowest forex rates and cashback offers.`,
          },
        ],
      },
    ],
  },
  {
    ques: `How do I get this card?`,
    answer: [
      {
        id: "1",
        aboveTextList: [
          {
            id: "1",
            text: `You can easily order this card online within 2 minutes. Simply enter your PAN (Permanent Account Number) and delivery address, and the card will be delivered to your doorstep within 48 hours.`,
          },
          {
            id: FAQ_CLICK_HERE_LINK_ID.HOW_TO_GET_CARD_CLICK_HERE,
            linkText: "Click here",
          },
          {
            id: "1",
            text: `to order now.`,
          },
        ],
      },
    ],
  },
  {
    ques: `How can I load money into my card?`,
    answer: [
      {
        id: "1",
        aboveTextList: [
          {
            id: "1",
            text: "You can load money instantly using UPI, NetBanking, and Debit Card. You can top up your card anytime, even during your international trip.                                                                                                                                                                                                             ",
          },
        ],
      },
    ],
  },
  {
    ques: `What if I don’t want to use the card later?`,
    answer: [
      {
        id: "1",
        aboveTextList: [
          {
            id: "1",
            text: `If you no longer wish to use the card, you have the option to withdraw the unused money instantly. Additionally, you can choose to temporarily lock the card or close it anytime.`,
          },
        ],
      },
    ],
  },
];

export type FaqDataProps = {
  faqData: landingFaqDataProps[];
  id: string;
  label: string;
  isSelected: boolean;
};

const FAQ_IDS = {
  CARD_FAQ: "card_faq",
  CURRENCY_FAQ: "currency_faq",
};

export const landingFaqData: landingFaqDataProps[] = landingCardFaqData;

export const landingFaqDataV2Generator = (
  partner: string | null | undefined
) => {
  return [
    {
      faqData: landingCardFaqData,
      id: FAQ_IDS.CARD_FAQ,
      label: "Multicurrency Card",
      isSelected: true,
    },
    {
      faqData: landingCurrencyFaqDataGenerator(partner),
      id: FAQ_IDS.CURRENCY_FAQ,
      label: "Foreign Currency",
      isSelected: false,
    },
  ];
};

const landingCurrencyFaqDataGenerator = (
  partner: string | null | undefined
) => {
  return [
    {
      ques: `Who should buy foreign currency notes?`,
      answer: [
        {
          id: "1",
          aboveTextList: [
            {
              id: "1",
              text: `Every international traveller should purchase some foreign currency notes, before their trip. Currency notes are always a good option to prevent any potential inconvenience while spending in a foreign country, especially in case your forex or credit card are not accepted in some outlets or for local conveyance. `,
            },
            {
              id: FAQ_CLICK_HERE_LINK_ID.ORDER_CURRENCY_CLICK_HERE,
              linkText: "Click here to order now!",
            },
          ],
        },
      ],
    },
    {
      ques: `Why should I buy foreign currency notes from ${
        partner === PARTNER.GI ? "Goibibo" : "Makemytrip"
      }?`,
      answer: [
        {
          id: "1",
          aboveTextList: [
            {
              id: "1",
              text: "We offer best exchange rates, genuine notes from RBI licensed companies, doorstep delivery across 50+ cities, 40+ currencies available, instant customer support and many more.  ",
            },
            {
              id: FAQ_CLICK_HERE_LINK_ID.ORDER_CURRENCY_CLICK_HERE,
              linkText: "Click here to order now!",
            },
          ],
        },
      ],
    },
    {
      ques: `How can I place the order for currency notes?`,
      answer: [
        {
          id: "1",
          aboveTextList: [
            {
              id: "1",
              text: "Ordering foreign currency notes through MakeMyTrip takes just two minutes. All you need to do is provide the currency and amount required, along with your PAN and address details. That's it! Your currency notes will be delivered to your address within the specified time. ",
            },
            {
              id: FAQ_CLICK_HERE_LINK_ID.ORDER_CURRENCY_CLICK_HERE,
              linkText: "Click here to order now!",
            },
          ],
        },
      ],
    },
    {
      ques: "How many days before travel should I buy currency notes?",
      answer: [
        {
          id: "1",
          aboveTextList: [
            {
              id: "1",
              text: `Purchasing foreign currency notes well in advance of your travel is a prudent practice to prevent potential stock shortages, unforeseen delays, or fluctuations in exchange rates. You can buy foreign currency notes within 60 days of your trip start date. Advance purchase prior to this 60-day period is not allowed. `,
            },
            {
              id: FAQ_CLICK_HERE_LINK_ID.ORDER_CURRENCY_CLICK_HERE,
              linkText: "Click here to order now!",
            },
          ],
        },
      ],
    },
    {
      ques: "What’s my foreign exchange entitlement (limit) for personal / leisure travel?",
      answer: [
        {
          id: "1",
          aboveTextList: [
            {
              id: "1",
              text: `Not exceeding US Dollars 2,50,000 in a financial year. If not consumed any part of it for any other purpose stated as per RBI's guidelines covering the new liberalized Remittance Scheme (LRS). Limit can be utilized in one or more visits abroad for personal/leisure purposes. <br/> <br/>
  
  If an individual has already remitted any amount under the Liberalized Remittance Scheme in a financial year, then the applicable limit for traveling purposes for such an individual would be reduced from USD 250,000 by the amount so remitted `,
            },
            {
              id: FAQ_CLICK_HERE_LINK_ID.ORDER_CURRENCY_CLICK_HERE,
              linkText: "Click here to order now!",
            },
          ],
        },
      ],
    },
    {
      ques: "Is there a separate limit for carrying currency notes?",
      answer: [
        {
          id: "1",
          aboveTextList: [
            {
              id: "1",
              text: `Travelers are allowed to purchase foreign currency notes/coins only up to US$ 3000 individually. Exceptions to this rule are the following:  <br/> <br/>
  
  (a) travellers proceeding to Iraq and Libya can draw foreign exchange in the form of foreign currency notes and coins not exceeding US$ 5000 or its equivalent  <br/> <br/>
  
  (b) travellers proceeding to the Islamic Republic of Iran, the Russian Federation, and other Republics of the Commonwealth of Independent States who can draw entire foreign exchange in the form of foreign currency notes or coins. <br/> <br/>
  
  (c ) For travelers proceeding for Haj/ Umrah pilgrimage, the full amount of BTQ entitlement (USD 250, 000) in cash or up to the cash limit as specified by the Haj Committee of India, may be released by the ADs and FFMCs. <br/> <br/>
  
  Minimum order limit for foreign currency is set at INR 3000 `,
            },
            {
              id: FAQ_CLICK_HERE_LINK_ID.ORDER_CURRENCY_CLICK_HERE,
              linkText: "Click here to order now!",
            },
          ],
        },
      ],
    },
    {
      ques: `Is there any documentation or KYC required?`,
      answer: [
        {
          id: "1",
          aboveTextList: [
            {
              id: "1",
              text: "Yes, as per RBI guidelines the traveller needs to upload his/her PAN card, Indian passport (front & back), Air ticket and Visa (if not on arrival), after making payment for forex transactions on Makemytrip. These will be verified before processing the order. ",
            },
            {
              id: FAQ_CLICK_HERE_LINK_ID.ORDER_CURRENCY_CLICK_HERE,
              linkText: "Click here to order now!",
            },
          ],
        },
      ],
    },
    {
      ques: `Can I sell back my left over foreign currency notes?`,
      answer: [
        {
          id: "1",
          aboveTextList: [
            {
              id: "1",
              text: `Any remaining foreign currency notes that you have after your international trip can be conveniently exchanged through the BookMyForex app or website. This allows you to convert your leftover foreign currency back into your home currency, ensuring that your funds don't go to waste.`,
            },
            {
              id: FAQ_CLICK_HERE_LINK_ID.ORDER_CURRENCY_CLICK_HERE,
              linkText: "Click here to order now!",
            },
          ],
        },
      ],
    },
    {
      ques: `Is there anything else I should be aware of while purchasing foreign currency notes?`,
      answer: [
        {
          id: "1",
          aboveTextList: [
            {
              id: "1",
              text: `Yes, you should make payment for forex transactions from traveller’s bank account, whose PAN number is provided. As per RBI guidelines, name in PAN should match with the Bank account holder name.`,
            },
            {
              id: FAQ_CLICK_HERE_LINK_ID.ORDER_CURRENCY_CLICK_HERE,
              linkText: "Click here to order now!",
            },
          ],
        },
      ],
    },
  ];
};

export const poweredByLogos = [
  {
    logoSrc:
      "https://tripmoneycmsimgak.mmtcdn.com/img/bmf_transaprent_2_ee2f21b3a8.png",
    width: 130,
  },
];

export const landingForexOfferingCardData: landingForexOfferingCardDataProps[] =
  [
    // {
    //     id: CARD_TYPE.INR_CARD,
    //     cardTitle: 'Global Cash Card',
    //     cardInfo: 'for Casual Travelers',
    //     cardImg: 'https://tripmoneycmsimgak.mmtcdn.com/img/Global_Cash_Card_edf224f322.png',
    //     cardFeatList: [
    //         {
    //             id: '1',
    //             boldText: '',
    //             normalText: 'No pre-load required',
    //         },
    //         {
    //             id: '2',
    //             boldText: '',
    //             normalText: 'Spend globally at no forex mark-up',
    //         },
    //         {
    //             id: '3',
    //             boldText: '',
    //             normalText: 'Manage card digitally on the app',
    //         },
    //     ],
    //     ctaTxt: 'Proceed',

    // },
    {
      id: CARD_TYPE.MULTI_CURRENCY_CARD,
      cardTitle: "Multicurrency Card",
      // cardInfo: '', //'for Business Travelers',
      cardImg:
        "https://tripmoneycmsimgak.mmtcdn.com/img/tilted_bmf_card_953aed2863.png",
      cardFeatList: [
        {
          id: "1",
          boldText: "Zero forex markup.",
          normalText: "Get actual interbank rates",
        },
        {
          id: "2",
          boldText: "No hidden charges.",
          normalText: "Zero reload, unload & annual fee",
        },
        {
          id: "3",
          boldText: "Save upto 5%",
          normalText: "over your Credit/Debit cards",
        },
      ],
      ctaTxt: "Know More",
    },
    {
      id: CARD_TYPE.CURRENCY,
      cardTitle: "Foreign Currency Notes",
      // cardInfo: '', //'for Last Minute Plans',
      cardImg:
        "https://tripmoneycmsimgak.mmtcdn.com/img/Mask_group2_9e45a9dc44.svg",
      cardFeatList: [
        {
          id: "1",
          boldText: "40+ currencies available.",
          normalText: "Genuine currency notes",
        },
        {
          id: "2",
          boldText: "Best exchange rates.",
          normalText: "Lowest rates in the market",
        },
        {
          id: "3",
          boldText: "100+ RBI-licensed money changers",
          normalText: "across 50+ cities",
        },
      ],
      ctaTxt: "Order Now",
    },
  ];
