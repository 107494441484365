interface EnergyIconProps {}

export const EnergyIcon = (props: EnergyIconProps) => (
  <svg
    width={8}
    height={14}
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.93292 14C2.65306 14 2.43717 13.7589 2.47715 13.4867L3.19678 8.55556H0.398211C-0.305429 8.55556 0.134346 7.97222 0.150338 7.94889C1.15782 6.21444 2.66905 3.64778 4.66803 0.225556C4.74799 0.0855555 4.90791 0 5.06782 0C5.34768 0 5.56357 0.241111 5.52359 0.513333L4.80396 5.44444H7.61053C7.93036 5.44444 8.10627 5.59222 7.93036 5.95778C5.29971 10.4222 3.77249 13.0278 3.33271 13.7744C3.25275 13.9144 3.10083 14 2.93292 14Z"
      fill="#2B825E"
    />
  </svg>
);
