// prettier-ignore
//@ts-ignore
import { setItem as setLocalStorageItem,getItem as getLocalStorageItem, removeItem as removeLocalStorageItem, itemType } from 'MMT-UI/storages/client/localStorage';
// prettier-ignore
//@ts-ignore
import { setItemBySession, getItemBySession, removeItemBySession } from 'MMT-UI/storages/client/sessionStorage';
import { getUtilOptions } from ".";

/**
@param isEssential: Value should be true if it's a part of code functionality, else false if it's not core (eg:- third party or advertising related)
*/
// Note:- modifying options for backward compatibity in case of web, can remove this after commons release

const { ES, N_ES } = itemType;

function getEssentialItemType(isEssential: boolean) {
  return isEssential ? ES : N_ES;
}

// local storage utils
export function setLocalStorage(
  key: string,
  value: any,
  isEssential: boolean = false,
  options: Record<string, string | boolean | number> | undefined = {
    shouldStringifyJson: true,
  }
) {
  options = getUtilOptions(options);
  setLocalStorageItem(key, value, options, getEssentialItemType(isEssential));
}

export function getLocalStorage(
  key: string,
  isEssential: boolean = false,
  options: Record<string, string | boolean | number> | undefined = {
    shouldParseJson: true,
  }
) {
  options = getUtilOptions(options);
  return getLocalStorageItem(key, options, getEssentialItemType(isEssential));
}

export function deleteLocalStorage(key: string) {
  removeLocalStorageItem(key);
}

// sesstion storage utils
export function setSessionStorageItem(
  key: string,
  value: any,
  isEssential: boolean = false,
  options: Record<string, string | boolean | number> | undefined = {
    shouldStringifyJson: true,
  }
) {
  options = getUtilOptions(options);
  setItemBySession(key, value, options, getEssentialItemType(isEssential));
}

export function getSessionStorageItem(
  key: string,
  isEssential: boolean = false,
  options: Record<string, string | boolean | number> | undefined = {
    shouldParseJson: true,
  }
): any {
  options = getUtilOptions(options);
  return getItemBySession(key, options, getEssentialItemType(isEssential));
}

export function removeSessionStorageItem(key: string) {
  removeItemBySession(key);
}
