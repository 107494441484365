import { theme } from "@tm/utility/global";
import styled from "styled-components";

type GreatChoiceStyleProps = {
  background?: string;
  padding?: string;
  radius?: string;
};

export const GreatChoiceStyle = styled.div<GreatChoiceStyleProps>`
  ${(props) => props.background && `background: ${props.background}`};
  ${(props) => props.radius && `border-radius: ${props.radius}`};
  ${(props) => props.padding && `padding: ${props.padding}`};

  /* margin-top: 60px; */
`;

export const ChoiceText = styled.p`
  position: relative;
  margin-top: 16px;
  color: ${theme.blackText};
`;
