import styled from "styled-components";

interface ToolTipContProps {
  width?: string;
  bgColor?: string;
  arrowDirection?: "up" | "down" | "right" | "left";
  arrowPosition?: string;
}

export const ToolTipCont = styled.div<ToolTipContProps>`
  width: ${(props) => (props.width ? props.width : "400px")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#005756")};
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  /* Top */
  ${(props) =>
    props.arrowDirection === "up" &&
    `
        ::after {
            bottom: 100%;
            left: ${props.arrowPosition ? props.arrowPosition : "50%"};
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(0, 87, 86, 0);
            border-bottom-color: ${props.bgColor ? props.bgColor : "#005756"};
            border-width: 8px;
            margin-left: -8px;
        }
    `}
  /* Right */
    ${(props) =>
    props.arrowDirection === "right" &&
    `
        ::after {
            left: 100%;
            top: ${props.arrowPosition ? props.arrowPosition : "50%"};
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(0, 87, 86, 0);
            border-left-color: ${props.bgColor ? props.bgColor : "#005756"};
            border-width: 8px;
            margin-top: -8px;
        }
    `}
    /* Bottom */
    ${(props) =>
    props.arrowDirection === "down" &&
    `
        ::after {
            top: 100%;
            left: ${props.arrowPosition ? props.arrowPosition : "50%"};
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(0, 87, 86, 0);
            border-top-color: ${props.bgColor ? props.bgColor : "#005756"};
            border-width: 8px;
            margin-left: -8px;
        }
    `}
    /* Left */
    ${(props) =>
    props.arrowDirection === "left" &&
    `
        ::after {
            right: 100%;
            top: ${props.arrowPosition ? props.arrowPosition : "50%"};
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(0, 87, 86, 0);
            border-right-color: ${props.bgColor ? props.bgColor : "#005756"};
            border-width: 8px;
            margin-top: -8px;
        }
    `}
`;

export const ToolTipBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
`;
