import styled from "styled-components";
import { deviceType } from "@tm/static/utils";
import { H5TagStyle } from "../TextStyles/TextStyles.style";
import { PARTNER, PartnerType } from "@tm/insurance/constants";

export interface SecureTravOfferStyleProps {
  pageTheme?: PartnerType;
}

export const SecureTravOfferStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  svg {
    flex-shrink: 0;
  }
  ${H5TagStyle} {
    font-size: 22px;
    font-weight: 900;
  }
`;

export const SecureTravItem = styled.div`
  display: flex;
  gap: 24px;
  @media ${deviceType.mobile} {
    gap: 8px;
  }
`;

export const SecureTravImgWrap = styled.div<SecureTravOfferStyleProps>`
  background: linear-gradient(
    125.09deg,
    #474747 0%,
    rgba(255, 255, 255, 0) 64.55%
  );
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 72px;
  height: 80px;
  ${(p) =>
    p.pageTheme === PARTNER.MMT
      ? `
     background: linear-gradient(113deg, rgba(211, 211, 211, 0.30) 0%, rgba(255, 255, 255, 0.00) 87.26%);
    `
      : p.pageTheme === PARTNER.GI
      ? `background: linear-gradient(113deg, rgba(211, 211, 211, 0.30) 0%, rgba(255, 255, 255, 0.00) 87.26%);`
      : `
    `}
`;

export const SecureTravContent = styled.div`
  align-self: center;
`;
