import styled from "styled-components";
import { theme } from "../../css/Global";

export const Container = styled.div``;
export const Cta = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.blueText};
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-left: 5px;
  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }
`;

export const GpsIconWpr = styled.div`
  margin: 0 2px 0 0;
`;

export const StickyWpr = styled.div<any>`
  position: sticky;
  background-color: ${theme.smokeWhite};
  padding: 16px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  margin: 0px -32px -32px -32px;
  bottom: -32px;
  left: 0;
  right: 0;
  z-index: 2;
`;
