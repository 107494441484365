import styled from "styled-components";
import {
  AnchorTagStyle,
  ParaTagStyle,
  SpanTagStyle,
} from "../TextStyles/TextStyles.style";
import { theme } from "@tm/utility/global";
import { deviceType } from "@tm/static/utils";

export const ConversionRatesStyle = styled.div<any>`
  flex: 1;
  margin-left: 100px;
  background: #fff;
  padding: 24px;
  border-radius: 12px;

  @media ${deviceType.mobile} {
    flex: unset;
    margin: 32px 16px 0px 16px;
    padding: 24px 16px;
  }
`;

export const HeadingWpr = styled.div<any>`
  max-width: 200px;
`;

export const TimerLabel = styled(SpanTagStyle)<any>`
  font-weight: 700;
  color: #fff;
  background: ${(props) =>
    props.bg ? props.bg : "linear-gradient(90deg, #03BAE1 0%, #3A7BD5 100%)"};
  border-radius: 16px;
  width: max-content;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  flex-shrink: 0;
  padding: 2px 8px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "auto")};

  @media ${deviceType.mobile} {
    margin-left: unset;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "8px")};
  }
`;

export const UpComingBannerBorder = styled.div<any>`
  padding: 1px;
  background: linear-gradient(225deg, #3023ae 14.64%, #c86dd7 85.36%);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 16px;
`;

export const UpComingTrip = styled.div<any>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 26px 12px 16px 12px;
  background-color: #fff;
  border-radius: 7px;
`;

export const UpComingBanner = styled(SpanTagStyle)<any>`
  position: absolute;
  top: 0px;
  left: 0px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #fff;
  background: linear-gradient(225deg, #3023ae 14.64%, #c86dd7 85.36%);
  border-radius: 0px 0px 8px 0px;
  padding: 2px 8px;
`;

export const InputFieldsWpr = styled.div<any>`
  display: flex;
  margin-top: 16px;
`;

export const ConvertedValLabel = styled(ParaTagStyle)<any>`
  margin-top: 8px;

  > span {
    font-weight: 700;
  }
`;

export const SavedAmountBanner = styled.div<any>`
  display: flex;
  align-items: center;
  background: ${theme.greenText2};
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 16px;
`;

export const SavedAmountLabelsWpr = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SavedAmountLabel = styled(ParaTagStyle)<any>`
  color: ${theme.darkGreen};

  > span {
    font-weight: 700;
  }
`;

export const LinkLabel = styled(AnchorTagStyle)<any>`
  flex-shrink: 0;
  margin-left: 8px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
`;
