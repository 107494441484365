import { useDevice } from "../../../hooks/useDevice";
import { useFreezeBodyScroll } from "../../../hooks/useFreezeBodyScroll";
import Modal from "../Modal/Modal";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";

const DowntimeModal = () => {
  const device = useDevice();
  useFreezeBodyScroll(true);
  return (
    <Modal
      modalHeading={"Oops!"}
      onModalClose={() => {}}
      modalType={device === "lg" ? "centered" : "fullPage"}
    >
      <ParaTag
        fontSize={device === "lg" ? "md" : "sm"}
        lineHeight={device === "lg" ? "18px" : "20px"}
        margin="32px 0px 16px 0"
      >
        Site Under Maintenance: We're making things even better. Be back soon!
      </ParaTag>
    </Modal>
  );
};

export default DowntimeModal;
