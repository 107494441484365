interface FooterDownloadIconProps {
  props?: any;
}

export const FooterDownloadIcon = ({ props }: FooterDownloadIconProps) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1734_41315)">
      <rect
        x={1}
        y={1.36914}
        width={18}
        height={18}
        rx={4.5}
        stroke="#979797"
        strokeWidth={0.9}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.82002 16.8491L5.5 11.8091H8.37999V5.3291H11.26V11.8091H14.14L9.82002 16.8491Z"
        fill="url(#paint0_linear_1734_41315)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1734_41315"
        x1={9.01132}
        y1={23.4588}
        x2={21.2341}
        y2={15.4704}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0772C" />
        <stop offset={1} stopColor="#F95776" />
      </linearGradient>
      <clipPath id="clip0_1734_41315">
        <rect
          width={20}
          height={20}
          fill="white"
          transform="translate(0 0.369141)"
        />
      </clipPath>
    </defs>
  </svg>
);
