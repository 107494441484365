import {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { List } from "react-virtualized";

import { theme } from "@tm/utility/global";
import {
  CheckButton,
  FlexComp,
  TMFloatingInput,
  CommonModalDweb,
  LandingButton,
  ParaTag,
  SpanTag,
} from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";
import {
  replaceHistory,
  replaceHistoryRemoveParams,
} from "@tm/insurance/utils";
import { getAnalytics } from "@tm/insurance/analytics";

import PopularRegion from "./PopularRegion";
import {
  CountryItem,
  BtnWrap,
  CountrySection,
  CountrySectionWrap,
} from "./SelectCountryModal.style";

const analytics = getAnalytics();
interface SelectCountryModalProps {
  handleSelectCountry?: () => void;
  countryData?: {
    code: string;
    flag?: string | undefined;
    img?: string | undefined;
    isMandatory?: boolean | undefined;
    isPopular?: boolean | undefined;
    keywords?: string | undefined;
    name?: string | undefined;
  }[];
  popularRegion?: {
    id: number;
    regionName?: string;
    regionalCountryTxt?: string;
    countryList?: string[];
    isSelected?: boolean;
    regionInfo?: string;
  }[];
  preSelectedCountry: {
    code: string;
    isMandatory: boolean;
    isPopular: boolean;
    name: string;
    keywords: string;
    flag: string;
    img: string;
  }[];
  setShowCountry: any;
  setIsCountryError: Dispatch<SetStateAction<boolean>>;
  popularCountryProp?: {
    code: string;
    isMandatory: boolean;
    isPopular: boolean;
    name: string;
    keywords: string;
    flag: string;
    img: string;
  }[];
  setPopularCountryProp?: Dispatch<SetStateAction<any[] | undefined>>;
}

const SelectCountryModal = ({
  handleSelectCountry,
  countryData,
  popularRegion,
  preSelectedCountry,
  setShowCountry,
  setIsCountryError,
  popularCountryProp,
  setPopularCountryProp,
}: SelectCountryModalProps) => {
  const device = useBreakpointV2();
  const windowWidth = window.innerWidth - 60;
  const [countriesData, setCountriesData] = useState(countryData);
  const [selectedCountries, setSelectedCountries] = useState<any>([]);
  const [numOfCount, setNumCount] = useState(0);
  const [searchTxt, setSearchTxt] = useState("");
  const rowHeight = 57;

  const calculateListHeight = () => {
    const rowCount = countriesData?.length ?? 0;
    const height = rowCount * rowHeight;
    console.log("calHeight", height);
    return height;
  };

  useEffect(() => {
    setSelectedCountries(preSelectedCountry);
  }, []);

  useEffect(() => {
    if (countryData) {
      const count = countryData?.filter(
        (country: any) => country.isSelected === true
      );

      setNumCount(count?.length);
    }
  }, [countryData]);

  const debouncedCountryHandler = useCallback(
    debounce((code: string) => {
      let countryArr: any = [],
        tempPopularCountry: any = [];

      if (code) {
        countryArr = countryData?.filter((country: any) => {
          if (country.code === code && !country.isSelected) {
            country.isSelected = true;
          } else if (country.code === code && country.isSelected) {
            country.isSelected = false;
          }
          return country;
        });
        setSelectedCountries(countryArr);

        tempPopularCountry = popularCountryProp?.filter((country: any) => {
          if (country.code === code && !country.isSelected) {
            country.isSelected = true;
          } else if (country.code === code && country.isSelected) {
            country.isSelected = false;
          }
          return country;
        });
        tempPopularCountry &&
          setPopularCountryProp &&
          setPopularCountryProp(tempPopularCountry);

        const count = countryArr.filter(
          (country: any) => country.isSelected === true
        );

        setNumCount(count.length);
      }
    }, 300),
    [countryData, popularCountryProp]
  );

  useEffect(() => {
    debouncedCountryHandler(searchTxt);
  }, [searchTxt]);

  const searchQueryHighlighter = (ele: string) => {
    const withSpecialCharsSupport = searchTxt?.replace(
      /[.*+?^${}()|[\]\\]/g,
      "\\$&"
    );

    const searchRegex = new RegExp(`(${withSpecialCharsSupport})`, "ig");

    const highlighted = ele.replace(
      searchRegex,
      (match) => `<span class="highlight">${match}</span>`
    );

    return <ParaTag dangerousText={highlighted} />;
  };

  useEffect(() => {
    const filteredDataList = searchTxt
      ? countryData?.filter((items) =>
          items.name?.toLowerCase().includes(searchTxt.toLowerCase())
        )
      : countryData;
    setCountriesData(filteredDataList);
  }, [searchTxt]);

  const isCheckedHandler = useCallback(
    (data: any) => {
      return data.isSelected;
    },
    [selectedCountries]
  );

  const rowRenderer = ({ index, key }: { index: number; key: string }) => {
    const item = countriesData && countriesData[index];

    if (!item) {
      return null; // Return null if item is undefined
    }
    if (item.code)
      return (
        <div key={key}>
          <CountryItem onClick={() => debouncedCountryHandler(item.code)}>
            <CheckButton isChecked={isCheckedHandler(item)}>
              <FlexComp alignItems="center" justifyContent="space-between">
                {/* <SpanTag>{item.currencyName}</SpanTag> */}

                {item.name && searchQueryHighlighter(item.name)}
              </FlexComp>
            </CheckButton>
          </CountryItem>
        </div>
      );
    return;
  };

  const onCountrySubmit = () => {
    setIsCountryError(false);

    // selected popular countries
    const selectedPopCountries =
      popularCountryProp?.filter((item: any) => item.isSelected) ?? [];

    // Popular countries that are not selected
    const unselectedPopCountries =
      popularCountryProp?.filter((item: any) => !item.isSelected) ?? [];

    const selected = selectedCountries.filter((item: any) => item.isSelected);
    const selectedPopCntryCode = selectedPopCountries.map(
      (item: any) => item.code
    );

    // selected non-popular countries
    const selectedNonPopCountries = selected.filter(
      (item: any) => !selectedPopCntryCode.includes(item.code)
    );

    setShowCountry([
      ...selectedPopCountries,
      ...selectedNonPopCountries,
      ...unselectedPopCountries,
    ]);

    const selectedCountryNames = selected.map((item: any) => item.name);

    replaceHistoryRemoveParams("countries");
    replaceHistory("countries", selectedCountryNames.join(","));

    analytics?.trackLandingEvents({
      actionType: "other_countries_clicked",
      payload: selectedNonPopCountries.map((c: any) => c.name).join("|"),
    });

    handleSelectCountry && handleSelectCountry();
  };

  return (
    <CommonModalDweb
      handleClick={handleSelectCountry}
      customTitle="Select Country"
    >
      <TMFloatingInput
        handleChange={(e) => setSearchTxt(e.target.value)}
        inputValue={searchTxt}
        labelTxt="Search Country Name"
      />
      {/* <PopularRegion popularRegion={popularRegion} /> */}
      <CountrySectionWrap>
        {/* <CountrySection>
                    <SpanTag fontSize="sm" fontWeight="bold">
                        Popular Country
                    </SpanTag>
                </CountrySection> */}
        {countriesData?.length ? (
          <List
            width={device == "lg" ? 360 : windowWidth}
            height={calculateListHeight()}
            rowHeight={rowHeight}
            rowCount={countriesData.length}
            rowRenderer={rowRenderer}
            style={{ minHeight: calculateListHeight() }}
          />
        ) : (
          <ParaTag fontSize="sm" color={theme.greyText} margin="16px 0 16px">
            No Country Found! Please enter correct name
          </ParaTag>
        )}
      </CountrySectionWrap>
      <BtnWrap>
        <LandingButton
          customWidth="100%"
          customTitle={
            "Selected " + `${numOfCount != 0 ? "(" + numOfCount + ")" : ""}`
          }
          onClick={onCountrySubmit}
        />
      </BtnWrap>
    </CommonModalDweb>
  );
};

const debounce = <T extends any[]>(
  func: (...args: T) => void,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: T) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export default SelectCountryModal;
