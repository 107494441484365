import Head from "next/head";
import { serverRuntimeConfig } from "@tm/utility";
const { adobeDtmScriptURL } = serverRuntimeConfig();
import { NewRelicSnippet } from "@tm/ui/ui-container/NewRelicSnippet";

export const LandingPageDocument = ({
  metaTags,
  preloadedImages,
  children,
}: any) => {
  return (
    <>
      <Head>
        {metaTags.map((tag: any) => (
          <meta key={tag.name} name={tag.name} content={tag.content} />
        ))}
        {preloadedImages.map((img: string, index: number) => (
          <link key={img + "-" + index} rel="preload" href={img} as="image" />
        ))}
        <link
          rel="preload"
          href="https://fonts.gstatic.com/s/raleway/v28/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2"
          as="font"
          crossOrigin=""
        />
        <NewRelicSnippet isProd={process.env["ENV"] !== "local"} />
        <script src={adobeDtmScriptURL} async></script>
      </Head>
      {children}
    </>
  );
};
