import { useDevice } from "../../hooks/useDevice";
import Modal from "../../static/components/Modal/Modal";
import LandingButton from "../../static/components/ui-atoms/LandingButton/LandingButton";
import ParaTag from "../../static/components/ui-atoms/TextStyles/ParaTag";

interface EmailLoginModalTypes {
  onClose?: () => void;
}

const EmailLoginModal = ({ onClose }: EmailLoginModalTypes) => {
  const device = useDevice();
  return (
    <Modal
      modalHeading={"Login Error"}
      onModalClose={onClose ? onClose : () => {}}
      modalType={device === "lg" ? "centered" : "bottomSheet"}
    >
      <ParaTag
        fontSize={device === "lg" ? "md" : "sm"}
        lineHeight={device === "lg" ? "18px" : "20px"}
        margin="32px 0 16px 0"
      >
        Please login with mobile number to proceed further.
      </ParaTag>
      <LandingButton customTitle="OK" onClick={onClose} customWidth="100%" />
    </Modal>
  );
};

export default EmailLoginModal;
