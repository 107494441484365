import React from "react";
import { insurancePlanCompType } from "./type";
import { theme } from "@tm/utility/global";
import {
  AnchorTag,
  SpanTag,
  ParaTag,
  FormattedText,
  FlexComp,
  ImageComponent,
} from "@tm/static";
import { VendorWrap, TagWrap } from "./InsurancePlans.style";

const InsPlanModalCommonInfo = ({
  data,
  isAddOnBenefits,
  tag,
}: insurancePlanCompType) => {
  return (
    <React.Fragment>
      {data?.packName && (
        <ParaTag
          fontSize="xs"
          fontWeight="black"
          // color={data?.packNameColor}
          margin="0 0 4px"
        >
          <FormattedText value={data?.packName} />
        </ParaTag>
      )}
      {tag && (
        <TagWrap>
          <FormattedText value={tag} />
        </TagWrap>
      )}
      {data.insuranceType && (
        <VendorWrap margin={isAddOnBenefits ? "0px 0px 8px 0px" : "0"}>
          {data?.vendorDisplayIcon && (
            <ImageComponent
              src={data?.vendorDisplayIcon}
              height="34px"
              width="32px"
              // margin="0px 0px 4px 0px"
            ></ImageComponent>
          )}
          <ParaTag
            fontSize={isAddOnBenefits ? "md" : "large"}
            margin="0 0 4px"
            color={theme.blackText}
            fontWeight="black"
          >
            <FormattedText value={data?.insuranceType} />
          </ParaTag>
        </VendorWrap>
      )}
      {data.insPlanName && (
        <ParaTag fontSize="xs" margin="0 0 4px">
          <SpanTag fontSize="xs" color={theme.greyText7}>
            {data?.vendorDisplayPrefix}{" "}
            <FormattedText value={data?.insPlanName} />
          </SpanTag>
        </ParaTag>
      )}
      {data?.ctaLink && (
        <AnchorTag
          fontSize="xs"
          margin="0 0 16px"
          hrefProp={data?.tncLink}
          targetProp="_blank"
        >
          <FormattedText value={data?.ctaLink} />
        </AnchorTag>
      )}
      {data?.planExtraInfo && (
        <SpanTag fontSize="xs" color={theme.greyText7} margin="0 0 14px">
          <FormattedText value={data?.planExtraInfo} />
        </SpanTag>
      )}
      {data?.service && (
        <SpanTag fontSize="xs" color={theme.greyText7} margin="0 0 14px">
          <FormattedText value={data?.service} />
        </SpanTag>
      )}
    </React.Fragment>
  );
};

export default InsPlanModalCommonInfo;
