import React, { useState } from "react";

import { ShimmerVersion3 } from "@tm/static";
import { ManageInsuranceStyleShimmer } from "./ManageInsurance.style";
interface manageInsProp {
  title?: string;
  info?: string;
  orderBtn?: string;
  handleManageOrder?: () => void;
}

const ManageInsuranceShimmer = ({}: manageInsProp) => {
  return (
    <ManageInsuranceStyleShimmer>
      <ShimmerVersion3 margin="auto 0 0" height="44px" width="100%" />
    </ManageInsuranceStyleShimmer>
  );
};

export default ManageInsuranceShimmer;
