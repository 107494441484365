import styled from "styled-components";
import { deviceType } from "@tm/static/utils";
import { PARTNER, PartnerType } from "@tm/insurance/constants";
import { AnchorTagStyle } from "../TextStyles/TextStyles.style";
import { theme } from "@tm/utility/global";
export interface TravelDetailsStyleProps {
  listSelected?: boolean;
  selected?: boolean;
  pageTheme?: PartnerType;
}

export const TravelDetailsStyle = styled.div`
  background: #ffffff;
  padding: 30px;
`;
export const TravellingToStyle = styled.div`
  background: #ffffff;
  padding: 30px;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  position: relative;
  @media ${deviceType.mobile} {
    padding: 16px;
    margin-bottom: 36px;
  }
`;

export const PopularCountry = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  label {
    left: 0 !important;
    position: absolute;
    bottom: 8px;
    z-index: 1;
  }
  @media ${deviceType.mobile} {
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 14px;
    width: calc(100vw - 64px);
  }
`;

export const PopularCountryItem = styled.div<TravelDetailsStyleProps>`
  background: linear-gradient(228.82deg, #43c6ac 2.05%, #191654 93.35%);
  border: 1px solid #fff;
  border-radius: 6px;
  z-index: 1;
  padding: 8px;
  overflow: hidden;
  width: 80px;
  height: 82px;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.24);
  ${(p) =>
    p.listSelected &&
    `
        border: 1px solid #008cff;
        `}
  > div {
    cursor: pointer;
  }
  picture {
    height: 82px;
  }
  @media ${deviceType.mobile} {
    width: unset;
    height: 82px;
    picture {
      height: 82px;
    }
  }
`;

export const OtherCountyBtn = styled.div`
  display: flex;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.24);
  flex-direction: column;
  gap: 8px;
  width: 80px;
  height: 82px;
  background: #fff;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  @media ${deviceType.mobile} {
    width: unset;
    height: 82px;
    gap: 6px;
  }
`;

export const CountryImg = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0;
  z-index: -1;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  picture {
    width: 100%;
  }
  picture img {
    width: 80px;
    height: 80px;
  }
  :after {
    content: "";
    position: absolute;
    border-radius: 0px 0px 6px 6px;
    background: linear-gradient(
      180deg,
      rgba(106, 106, 106, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
    width: 100%;
    height: 32px;
    bottom: 1px;
    left: 0;
  }
  @media ${deviceType.mobile} {
    picture img {
      width: 100%;
      height: 82px;
    }
  }
`;

export const TravelNote = styled.div<TravelDetailsStyleProps>`
  background: linear-gradient(
    85.23deg,
    rgba(67, 225, 168, 0.5) 0%,
    rgba(33, 147, 147, 0.5) 100%
  );
  border-radius: 8px 8px 0px 0px;
  padding: 8px 78px;
  margin: 0 auto;
  color: #fff;
  font-size: 16px;
  width: 550px;
  text-align: center;
  em {
    font-weight: 600;
    color: #8beecd;
    font-style: normal;
  }
  @media ${deviceType.mobile} {
    width: 92%;
    padding: 12px;
    font-size: 12px;
    text-align: center;
    padding-bottom: 24px;
    margin-bottom: -12px;
  }
  @media ${deviceType.desktop} {
    ${(p) =>
      p.pageTheme === PARTNER.MMT
        ? `
        background: linear-gradient(45deg, #43E1A8 0%, #219393 100%);
    `
        : p.pageTheme === PARTNER.GI
        ? `background: #18A160;`
        : ``}
  }
`;

export const DiscountingNote = styled.div<TravelDetailsStyleProps>`
  border-radius: 8px 8px 0px 0px;
  padding: 8px 0px 8px 16px;
  margin: 0 auto;
  color: ${theme.greyText5};
  width: 550px;
  display: flex;
  align-items: center;
  @media ${deviceType.mobile} {
    width: 92%;
    padding: 12px;
    padding-bottom: 24px;
    margin-bottom: -12px;
    background: #e4fbf4;
  }
  @media ${deviceType.desktop} {
    background: #e4fbf4;
  }
`;

export const TravellingToStyleWrap = styled.div``;

export const UpcomingTripStyle = styled.div<TravelDetailsStyleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 6px;
  padding: 24px 12px 12px;
  margin-bottom: 16px;
  position: relative;
  cursor: pointer;
  ${(p) =>
    p.selected &&
    `
    background: #EAF5FF;
    border: 1px solid #008CFF;
  `}
  ${AnchorTagStyle} {
    width: 24px;
    height: 24px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid transparent;
    background: ${theme.purpleGradient};
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: 6px;
  }
`;

export const UpcomingTripTag = styled.div`
  background: ${theme.purpleGradient};
  padding: 2px 6px;
  border-radius: 6px 0px 6px 0px;
  width: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
`;

export const BgText = styled.span`
  background: radial-gradient(
      59.37% 231.91% at 3.11% 23.44%,
      rgba(255, 255, 255, 0.24) 0%,
      rgba(255, 255, 255, 0.144) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
  position: absolute;
  top: -59px;
  right: 6px;
`;

export const TripUpcoming = styled.div`
  background: linear-gradient(
    91.22deg,
    #9c005e 1.88%,
    rgba(22, 20, 60, 0.2) 100%
  );
  padding: 2px 8px;
  color: #fff;
  position: absolute;
  bottom: 24px;
  left: -10px;
  white-space: nowrap;
  width: 100%;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  text-transform: uppercase;
  text-wrap: wrap;
`;

export const CountryNameTxt = styled.span`
  display: -webkit-box;
  color: #ffffff;
  font-size: 12px;
  -webkit-line-clamp: 2;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  white-space: normal;
  width: 75%;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
`;
