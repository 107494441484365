interface RoundEcllipseProps {
  color?: string;
  props?: any;
}

export const RoundEcllipseIcon = ({
  color = "#CF8100",
  props,
}: RoundEcllipseProps) => (
  <svg
    width={26}
    height={25}
    fill="none"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.2}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.068 9.49978C15.068 6.79315 12.8738 4.59899 10.1672 4.59899C7.46058 4.59899 5.26642 6.79315 5.26642 9.49978C5.26642 12.2064 7.46057 14.4006 10.1672 14.4006C12.8738 14.4006 15.068 12.2064 15.068 9.49978ZM10.1672 2.63867C13.9565 2.63867 17.0283 5.7105 17.0283 9.49978C17.0283 13.2891 13.9565 16.3609 10.1672 16.3609C6.37792 16.3609 3.3061 13.2891 3.3061 9.49978C3.3061 5.7105 6.37792 2.63867 10.1672 2.63867Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.068 9.49978C15.068 6.79315 12.8738 4.59899 10.1672 4.59899C9.62588 4.59899 9.18705 4.16016 9.18705 3.61883C9.18705 3.0775 9.62588 2.63867 10.1672 2.63867C13.9565 2.63867 17.0283 5.7105 17.0283 9.49978C17.0283 13.2891 13.9565 16.3609 10.1672 16.3609C9.62588 16.3609 9.18705 15.9221 9.18705 15.3807C9.18705 14.8394 9.62588 14.4006 10.1672 14.4006C12.8738 14.4006 15.068 12.2064 15.068 9.49978Z"
      fill={color}
    />
  </svg>
);
