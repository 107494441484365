import styled from "styled-components";

interface ILandingFooterList {
  margin?: string;
  flexWrap?: string;
}
export const LandingFooter = styled.section`
  background: #000;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  padding-top: 32px;
  padding-bottom: 64px;
`;

export const LandingFooterSub = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;

export const IconMargin = styled.div`
  margin: 0 16px 0 0;
`;

export const LandingFooterWrap = styled.div`
  margin: 0 auto;
  width: 1200px;
`;

export const LandingFooterImg = styled.div`
  width: 325px;
  height: 27px;
  margin-bottom: 50px;
`;

export const LandingFooterList = styled.ul<ILandingFooterList>`
  margin-bottom: 26px;
  margin: ${(props) => props.margin};

  li {
    line-height: 16px;
    display: inline-block;
  }

  li:not(:first-child):before {
    content: "•";
    display: inline-block;
    margin: 0 10px;
    font-size: 1.8em;
    line-height: 16px;
    vertical-align: middle;
  }

  li a {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
  }
  li a:hover {
    text-decoration: underline;
  }
  &:last-child {
    margin-bottom: 100px;
  }
  ${(props) =>
    props.flexWrap &&
    `
height:198px;
flex-wrap:wrap;
    `}
`;

export const LandingFooterFlex = styled.div`
  display: flex;
  gap: 95px;
  margin-top: 100px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const LandingFooterCopyRight = styled.div`
  font-family: "Open Sans";
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  align-self: center;

  & span:nth-child(2) {
    font-weight: 600;
    margin-top: 3px;
  }
`;
