import React, { useState } from "react";
import {
  FlexComp,
  ImageComponent,
  ParaTag,
  SpanTag,
  LandingButtonWhite,
} from "@tm/static";
import {
  OrderDetailsStyle,
  OrderDetailsSectionOne,
  OrderDetailsSectionTwo,
  OrderDetailsSectionThree,
  OrderDetailsList,
} from "./OrderDetails.style";
import { theme } from "@tm/utility/global";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import { useBreakpointV2 } from "@tm/ui-widgets";

interface orderDetailsProp {
  margin?: string;
  orderDetails?: {
    planName?: string | TextNodeType;
    packName?: string | TextNodeType;
    insPackName?: string | TextNodeType;
    packColor?: string;
    packImg?: string;
    orderDetailAmt?: {
      orderDetail?: string | TextNodeType;
      info?: string | TextNodeType;
    }[];
  };
  downloadPolicy?: {
    buttonTitle: string;
    isEnabled: boolean;
    leftIconUrl: string;
    rightIconUrl: string;
  };
  navigateToDownloadPolicy?: () => void;
}

const OrderDetails = ({
  orderDetails,
  margin,
  downloadPolicy,
  navigateToDownloadPolicy,
}: orderDetailsProp) => {
  const device = useBreakpointV2();
  const {
    planName,
    packName,
    insPackName,
    packColor,
    packImg,
    orderDetailAmt,
  } = orderDetails ?? {};
  return (
    <OrderDetailsStyle margin={margin}>
      <OrderDetailsSectionOne>
        <SpanTag fontWeight="black">Plan details</SpanTag>
        {insPackName && (
          <SpanTag fontSize="xs" fontWeight="black" color={packColor}>
            <FormattedText value={insPackName} />
          </SpanTag>
        )}
      </OrderDetailsSectionOne>
      <OrderDetailsSectionTwo>
        <ImageComponent
          width="59px"
          height="59px"
          src={packImg ? packImg : ""}
        />
        <FlexComp flexDirection="column">
          {planName && (
            <SpanTag
              fontSize={device == "lg" ? "large" : "sm"}
              fontWeight="black"
              color={theme.blackText}
            >
              <FormattedText value={planName} />
            </SpanTag>
          )}
          {packName && (
            <SpanTag
              fontSize={device == "lg" ? "sm" : "xs"}
              color={theme.greyText7}
            >
              <FormattedText value={packName} />
            </SpanTag>
          )}
        </FlexComp>
      </OrderDetailsSectionTwo>
      <OrderDetailsList>
        {orderDetailAmt &&
          orderDetailAmt?.map((item, index) => {
            return (
              <FlexComp
                justifyContent="space-between"
                borderBottom={"1px dashed #dbdbdb"}
                padding={
                  index == (orderDetailAmt?.length ?? 0) - 1
                    ? "0 30px 8px"
                    : "0 30px 8px"
                }
                margin="0 0 8px"
                key={index}
              >
                {item.orderDetail && (
                  <SpanTag
                    fontSize={index == 0 ? "sm" : "xs"}
                    color={theme.greyText}
                    fontWeight={index == 0 ? "black" : "regular"}
                  >
                    <FormattedText value={item.orderDetail} />
                  </SpanTag>
                )}
                {item.info && (
                  <SpanTag
                    fontSize={index == 0 ? "md" : "xs"}
                    color={theme.blackText}
                    fontWeight="black"
                  >
                    <FormattedText value={item.info} />
                  </SpanTag>
                )}
              </FlexComp>
            );
          })}
      </OrderDetailsList>
      {device == "sm" && downloadPolicy && downloadPolicy?.isEnabled ? (
        <LandingButtonWhite
          btnLeftIcon={downloadPolicy?.leftIconUrl}
          btnRightIcon={downloadPolicy?.rightIconUrl}
          customTitle={downloadPolicy?.buttonTitle}
          customWidth="calc(100% - 24px)"
          margin="0 auto"
          onClick={navigateToDownloadPolicy}
        />
      ) : null}
    </OrderDetailsStyle>
  );
};

export default OrderDetails;
