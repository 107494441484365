import { theme } from "@tm/utility/global";
import styled from "styled-components";

type CashBackAvailStyleProps = {
  background?: string | undefined | null;
  padding?: string | undefined | null;
};

export const CashBackAvailStyle = styled.div<CashBackAvailStyleProps>`
  background: ${(props) => props.background || theme.greenText2};
  margin-top: 8px;
  padding: ${(props) => props.padding || "8px"};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
