import {
  IAnalytics,
  PageLoadEvent,
  PageLoadErrorEvent,
  LandingEvents,
  ListingEvents,
  TravDetailsEvents,
  ThankYouEvents,
  AddPassportEvents,
  OrderSummaryEvents,
  OrderDetailsEvents,
} from "./IAnalytics";

import { pageNameCategoryMap } from "./constants";
import { pushEvent } from "../..";

type EventType = "pageView" | "tm_ins_listing_view" | "ctaClick";

interface IPageInfo {
  pageName: string;
  lob?: string;
  section?: string;
  abVariant?: string;
}

interface GIOmniDataType {
  event: EventType;
  page: {
    pageInfo: IPageInfo;
  };
  booking?: {
    pax?: string;
    routeCity?: string;
    departureDate?: string;
    returnDate?: string;
    purposeOfTravel?: string;
    sumInsuredValue?: string;
    bookingId?: string;
    paymentStatus?: string;
  };
  cta?: {
    name: string;
  };
  error?: string;
}
export class GIAnalytics implements IAnalytics {
  trackPageLoadEvent(event: PageLoadEvent) {
    const { pageName, lob, siteSection } = pageNameCategoryMap[event?.pageName];

    let omniData: GIOmniDataType = {
      event: "pageView",
      page: {
        pageInfo: {
          pageName,
          lob,
          section: siteSection,
          ...(event.experiment && {
            abVariant: event.experiment,
          }),
        },
      },
      booking: {
        ...(event.bookingId && {
          bookingId: event.bookingId,
        }),
        ...(event.bookingStatus && {
          paymentStatus: event.bookingStatus,
        }),
      },
    };

    if (event.pageName === "LISTING") {
      omniData = {
        ...omniData,
        event: "tm_ins_listing_view",
        booking: {
          ...(event.country && {
            routeCity: event.country,
          }),
          ...(event.startDate && {
            departureDate: event.startDate,
          }),
          ...(event.endDate && {
            returnDate: event.endDate,
          }),
          ...(event.purpose && {
            purposeOfTravel: event.purpose,
          }),
          ...(event.travellers && {
            pax: event.travellers,
          }),
        },
      };
    }

    pushEvent(omniData);
  }

  trackPageLoadErrorEvent(event: PageLoadErrorEvent) {
    const { pageName } = pageNameCategoryMap[event?.pageName];

    const omniData: GIOmniDataType = {
      event: "pageView",
      page: {
        pageInfo: {
          pageName,
        },
      },
      cta: {
        name: event.actionType,
      },
      error: event.error,
    };

    pushEvent(omniData);
  }

  trackLandingEvents(event: LandingEvents) {
    const { pageName, lob } = pageNameCategoryMap["LANDING"];
    let omniData: GIOmniDataType = {
      event: "ctaClick",
      page: {
        pageInfo: {
          pageName,
          lob,
        },
      },
      cta: {
        name: event.actionType,
      },
    };

    if (
      event.actionType === "other_countries_clicked" ||
      event.actionType === "popular_country_selected" ||
      event.actionType === "searched_countries" ||
      event.actionType === "selected_countries"
    ) {
      omniData = {
        ...omniData,
        booking: {
          routeCity: event.payload,
        },
      };
    } else if (event.actionType === "travel_date_selected") {
      omniData = {
        ...omniData,
        booking: {
          departureDate: event.payload.departure,
          returnDate: event.payload.arrival,
        },
      };
    } else if (event.actionType === "error_shown") {
      omniData = {
        ...omniData,
        error: event.payload,
      };
    }
    pushEvent(omniData);
  }

  trackListingEvents(event: ListingEvents) {
    const { pageName, lob } = pageNameCategoryMap["LISTING"];
    let omniData: GIOmniDataType = {
      event: "ctaClick",
      page: {
        pageInfo: {
          pageName,
          lob,
        },
      },
      cta: {
        name: event.actionType,
      },
    };

    if (event.actionType === "sum_insured_selected") {
      omniData = {
        ...omniData,
        booking: {
          sumInsuredValue: event.payload,
        },
      };
    }
    pushEvent(omniData);
  }

  trackTravDetailsEvents(event: TravDetailsEvents) {
    const { pageName, lob } = pageNameCategoryMap["REVIEW"];
    let omniData: GIOmniDataType = {
      event: "ctaClick",
      page: {
        pageInfo: {
          pageName,
          lob,
        },
      },
      cta: {
        name: event.actionType,
      },
    };
    if (event.actionType === "error_shown") {
      omniData = {
        ...omniData,
        error: event.payload,
      };
    }
    pushEvent(omniData);
  }

  trackThankYouEvents(event: ThankYouEvents) {
    const { pageName, lob } = pageNameCategoryMap[event.pageName];
    const omniData = {
      event: "ctaClick",
      page: {
        pageInfo: {
          pageName,
          lob,
        },
      },
      cta: {
        name: event.actionType,
      },
    };
    pushEvent(omniData);
  }

  trackAddPassportEvents(event: AddPassportEvents) {
    const { pageName, lob } = pageNameCategoryMap["ADD_PASSPORT"];
    const omniData = {
      event: "ctaClick",
      page: {
        pageInfo: {
          pageName,
          lob,
        },
      },
      cta: {
        name: event.actionType,
      },
    };
    pushEvent(omniData);
  }

  trackOrderSummaryEvents(event: OrderSummaryEvents) {
    const { pageName, lob } = pageNameCategoryMap["ORDER_SUMMARY"];
    const omniData = {
      event: "ctaClick",
      page: {
        pageInfo: {
          pageName,
          lob,
        },
      },
      cta: {
        name: event.actionType,
      },
    };
    pushEvent(omniData);
  }

  trackOrderDetailsEvents(event: OrderDetailsEvents) {
    const { pageName, lob } = pageNameCategoryMap["ORDER_DETAILS"];
    const omniData = {
      event: "ctaClick",
      page: {
        pageInfo: {
          pageName,
          lob,
        },
      },
      cta: {
        name: event.actionType,
      },
    };
    pushEvent(omniData);
  }
}
