import { generateRequestId } from "..";
import {
  IEventDetailInput,
  IEventTrackingContext,
  IExperimentDetails,
  IPageContextInput,
  IPdtDataObjV2,
} from "./types";

class PdtSchemaUpdater {
  private data: IPdtDataObjV2;

  constructor(commonPdtData: IPdtDataObjV2) {
    this.data = commonPdtData;
  }

  mergeDeviceContext(deviceContext: any): this {
    this.data = {
      ...this.data,
      device_context: {
        ...this.data?.device_context,
        ...deviceContext,
      },
    };
    return this;
  }

  mergeEventDetailsData(eventDetail: IEventDetailInput): this {
    this.data = {
      ...this.data,
      event_detail: {
        ...this.data?.event_detail,
        ...eventDetail,
        event_name:
          eventDetail.event_name || this.data?.event_detail?.event_name,
        event_type:
          eventDetail.event_type || this.data?.event_detail?.event_type,
      },
    };
    return this;
  }

  mergePageContextData(pageContextData?: IPageContextInput): this {
    this.data = {
      ...this.data,
      page_context: {
        ...this.data?.page_context,
        ...(pageContextData || {}),
        lob: pageContextData?.lob || this.data.page_context?.lob,
      },
    };
    return this;
  }

  mergeEventTrackingContext(
    eventTrackingContext?: IEventTrackingContext
  ): this {
    this.data = {
      ...this.data,
      event_tracking_context: {
        ...this.data?.event_tracking_context,
        ...eventTrackingContext,
      },
    };
    return this;
  }

  mergeExperimentDetails(experimentDetails?: IExperimentDetails): this {
    if (experimentDetails) {
      this.data = {
        ...this.data,
        experiment_details: {
          ...this.data?.experiment_details,
          ...experimentDetails,
        },
      };
    }
    return this;
  }
  getSchema() {
    return this.data;
  }
}

export default PdtSchemaUpdater;
