import React, { useEffect, useState } from "react";
import { ToastStyle, CloseIconWrap, ToastIcon } from "./Toast.style";
import {
  SpanTag,
  AnchorTag,
  FormattedText,
  ImageComponent,
  LandingButtonTertiary,
  FlexComp,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { TextNodeType } from "../FormattedText/FormattedText";
import { useBreakpointV2 } from "@tm/ui-widgets";
interface ToastProp {
  greyBgCross?: string;
  toastCtaTxt?: string;
  planSelectedTxt?: TextNodeType | string;
  handleClickCta?: () => void;
  handleClose?: () => void;
  autoCloseDuration?: number | null;
  toastCloseIcon?: string;
  toastIcon?: string;
  isDisable?: boolean;
  showToast?: boolean;
  width?: string;
  height?: string;
}

const ToastCompare = ({
  toastCtaTxt,
  planSelectedTxt,
  handleClickCta,
  handleClose,
  toastCloseIcon,
  toastIcon,
  isDisable,
  width,
  height,
}: ToastProp) => {
  const device = useBreakpointV2();

  return (
    <ToastStyle>
      <FlexComp gap="4px" alignItems="center">
        {toastIcon && device == "lg" && (
          <ToastIcon>
            <ImageComponent
              width={width ? width : "50px"}
              height={height ? height : "50px"}
              src={toastIcon}
            />
          </ToastIcon>
        )}
        <SpanTag
          fontSize={device == "lg" ? "md" : "xs"}
          color={theme.whiteText}
        >
          {planSelectedTxt && <FormattedText value={planSelectedTxt} />}
        </SpanTag>
      </FlexComp>
      <LandingButtonTertiary
        title={toastCtaTxt}
        onClick={handleClickCta}
        disableState={isDisable}
      />
      {toastCloseIcon && (
        <CloseIconWrap onClick={handleClose}>
          <ImageComponent width="28px" height="28px" src={toastCloseIcon} />
        </CloseIconWrap>
      )}
    </ToastStyle>
  );
};

export default ToastCompare;
