import { Fragment } from "react";
import { H5Tag } from "@tm/static";
import { ModalContentEqualizer, OptionsContainer } from "./ModalScreen.style";
import { chooseModalSecScreenDataProps } from "@tm/static/data";

export interface DaysScreenProps {
  optionsData?: chooseModalSecScreenDataProps[];
  title?: string;
  itemClickCallback?: (
    item: chooseModalSecScreenDataProps,
    idx: number
  ) => void;
}

const DaysScreen = ({
  optionsData,
  title,
  itemClickCallback = () => {},
}: DaysScreenProps) => {
  return (
    <Fragment>
      {title && <H5Tag margin="0px 0px 24px 0px">{title}</H5Tag>}
      {optionsData && (
        <ModalContentEqualizer>
          <OptionsContainer>
            {optionsData.map((item, idx) => {
              return (
                <li
                  key={item.id}
                  onClick={() => itemClickCallback(item, idx)}
                  className={item.isSelected ? "active" : ""}
                >
                  {item.label}
                </li>
              );
            })}
          </OptionsContainer>
        </ModalContentEqualizer>
      )}
    </Fragment>
  );
};

export default DaysScreen;
