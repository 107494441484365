import { useEffect, useRef, useState } from "react";
import {
  AnchorTag,
  Modal,
  OtpComp,
  ParaTag,
  TMFloatingInput,
  LandingButton,
  SpanTag,
} from "@tm/static";
import { CloseIcon } from "../../../icons";
import { useBreakpoint } from "../../../../hooks/useBreakpoint";
import { theme } from "@tm/utility/global";

import { EditNumberProps } from "./types";

const EditNumber = ({
  flowState = 1,
  onModalClose,
  closeButton = <CloseIcon />,
  editMobileNo,
  btnDisableState,
  continueBtnHandler,
  verifyMobileNo,
  otpDetails,
  setOtp,
}: EditNumberProps) => {
  const device = useBreakpoint();
  const [resendOtp, setResendOtp] = useState(true);
  const [timer, setTimer] = useState(0);
  const resendTimer = useRef<any>();

  const handleResendOtp = (): void => {
    if (resendOtp) {
      setResendOtp(false);
      setTimer(verifyMobileNo?.resendOtpTimer || 10);
    }
  };

  useEffect(() => {
    if (timer > 0) {
      resendTimer.current = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setResendOtp(true);
    }

    return () => {
      clearTimeout(resendTimer.current);
    };
  }, [timer]);

  return (
    <Modal
      modalHeading={
        flowState === 1
          ? editMobileNo?.modalHeading
          : verifyMobileNo?.modalHeading
      }
      onModalClose={onModalClose}
      closeButton={closeButton}
      modalType={device === "lg" ? "centered" : "bottomSheet"}
    >
      <ParaTag fontSize="sm" fontWeight="regular" color={theme.greyText}>
        {flowState === 1
          ? editMobileNo?.subHeading
          : verifyMobileNo?.subHeading}
      </ParaTag>
      {flowState === 1 ? (
        <>
          <TMFloatingInput
            labelTxt={editMobileNo?.editInput?.labelTxt}
            inputValue={editMobileNo?.editInput?.inputValue}
            handleChange={editMobileNo?.editInput?.handleChange}
            errorState={editMobileNo?.editInput?.errorState}
            verifiedTag={editMobileNo?.editInput?.verifiedTag}
            maxLength={editMobileNo?.editInput?.maxLength}
            margin="36px 0px 0px 0px"
            type="tel"
            inputMode="numeric"
          />
          <LandingButton
            customTitle={editMobileNo?.btnLabel}
            margin="24px 0 0 0"
            customWidth="100%"
            disableState={btnDisableState}
            onClick={continueBtnHandler}
          />
        </>
      ) : (
        <>
          <ParaTag fontSize="sm" fontWeight="bold" margin="36px 0px 8px 0px">
            {otpDetails?.enterOtpLabel}
          </ParaTag>
          <OtpComp setOtp={setOtp} errorMsg={otpDetails?.errorMsg} />
          {resendOtp && (
            <AnchorTag
              fontSize="xs"
              fontWeight="bold"
              margin="22px 0px 0px 0px"
              handleClick={handleResendOtp}
            >
              {otpDetails?.resendOtpLabel}
            </AnchorTag>
          )}
          {!resendOtp && (
            <ParaTag
              fontSize="xs"
              fontWeight="bold"
              margin="22px 0px 0px 0px"
              color={"rgba(155,155,155,0.3)"}
            >
              {otpDetails?.resendOtpLabel}
              <SpanTag
                fontSize="xs"
                fontWeight="bold"
                color={theme.greyText4}
                margin="0px 0px 0px 4px"
              >{`00:${timer >= 10 ? timer : `0${timer}`}`}</SpanTag>
            </ParaTag>
          )}
        </>
      )}
    </Modal>
  );
};

export default EditNumber;
