import styled from "styled-components";

export const FilterPlansStyle = styled.span`
  position: relative;
`;

export const PlanTypeStyle = styled.div`
  background: #ffffff;
  padding: 16px 0px;
`;

export const BtnWrap = styled.div`
  padding: 16px 30px;
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 20px 20px;
  position: sticky;
  bottom: -20px;
  margin: auto -30px -20px;
`;
