import styled, { keyframes, css } from "styled-components";

import { deviceType } from "@tm/static/utils";
import { theme } from "@tm/utility/global";

// ANIMATIONS
// Scale confetti from 0.5x to 1x
const confettiScale = keyframes`
    0% { transform: scale(0.5); }
    100% { transform: scale(1); }
`;

// Hide confetti
const hideConfetti = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`;

// Move up the rest of the container
const moveUpCtr = keyframes`
    0% { transform: translateY(0px); }
    100% { transform: translateY(-120px); }
`;

// The main card transform
const envelopeCardAnim = keyframes`
    0% {
        transform: scale(1);
    }

    100% {
        border-radius: 10px;
        transform: scale(1.2);
        border: 0.5px solid rgba(255, 255, 255, 0.4);
    }
`;

// envelope animation
const moveCardToBottom = keyframes`
    100% {
        top: 340px;
        height: 0px;
    }
`;

// show bottom info animation
const showBottomInfo = keyframes`
    0% { opacity: 0; };
    100% { opacity: 1; };
`;

const cardUp = keyframes`
    0% {
        padding-top: 30px;
    }
    100% {
        padding-top: 0px;
    }
`;

// COMPONENTS
export const CardCongratsStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  background: linear-gradient(0deg, #454545 0%, rgba(0, 0, 0, 1) 30%);

  @media ${deviceType.mobile} {
    height: 100%;
  }
`;

export const CardInnerStyle = styled.div`
  padding-top: 30px;
  text-align: center;
  /* animation: ${cardUp} 0.5s ease-in; */
  animation-fill-mode: forwards;
`;

export const Confetti = styled.div`
  width: 170px;
  margin: 0px auto;
  animation: ${confettiScale} 0.7s ease-in, ${hideConfetti} 0.5s linear 0.7s;
  animation-fill-mode: forwards;
`;

export const MoveUpContainer = styled.div`
  animation: ${moveUpCtr} 0.8s ease-in 1.2s;
  animation-fill-mode: forwards;
`;

export const FeaturesCtr = styled.div`
  /* background: red; */
`;

export const PointTag = styled.span`
  color: ${theme.greenText};
  margin: 0px 4px;
`;

export const EnvelopeCtr = styled.div`
  margin: 30px auto 75px;
  width: 196px;
  position: relative;
  z-index: 1;
  transition: 0.1s all;

  .envFront {
    position: absolute;
    left: -1px;
    top: 13px;
    z-index: 3;
    animation: ${moveCardToBottom} 1s ease-in 1.2s;
    animation-fill-mode: forwards;
  }

  .envBack {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    animation: ${moveCardToBottom} 1s ease-in 1.2s;
    animation-fill-mode: forwards;
  }

  .envCard {
    position: absolute;
    z-index: 2;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    top: 6px;
    left: 0px;
    animation: ${envelopeCardAnim} 3s ease 1.2s;
    animation-fill-mode: forwards;
    filter: drop-shadow(0px 3px 24px #121512);
  }
`;

export const BulletListContainer = styled.ul`
  list-style-type: none;
  text-align: left;

  & > li:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const CardInfoContainer = styled.div`
  margin-top: 210px;
  max-width: 370px;
  opacity: 0;
  animation: ${showBottomInfo} 1s ease-in 2s;
  animation-fill-mode: forwards;
`;

export const ButtonContainer = styled.div<any>`
  position: absolute;
  text-align: center;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  bottom: 16px;
  opacity: ${(props) => (props.withAnimation ? 0 : 1)};
  ${(props) =>
    props.withAnimation &&
    css`
      animation: ${showBottomInfo} 1s ease-in 2s;
      animation-fill-mode: forwards;
    `}

  @media ${deviceType.mobile} {
    width: calc(100% - 32px);
  }
`;
