import React from "react";
import { ToolTipProps } from "./ToolTipProps";
import { ToolTipCont, ToolTipBackdrop } from "./ToolTip.styles";

const ToolTip = ({
  width,
  children,
  bgColor,
  arrowDirection = "down",
  arrowPosition,
  style,
  closeTooltip,
}: ToolTipProps) => {
  return (
    <>
      <ToolTipCont
        width={width}
        arrowDirection={arrowDirection}
        arrowPosition={arrowPosition}
        bgColor={bgColor}
        style={style}
      >
        {children}
      </ToolTipCont>
      <ToolTipBackdrop onClick={(e: any) => closeTooltip && closeTooltip(e)} />
    </>
  );
};

export default ToolTip;
