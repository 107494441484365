import React from "react";
import {
  InsuranceStatsStyle,
  InsuranceStatsWrap,
} from "./InsuranceStats.style";
import { H4Tag, H5Tag, ParaTag } from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { usePartnerContext } from "@tm/static/partnerContext";

interface InsuranceStatsProps {
  data?: {
    title: string;
    info: string;
  }[];
}

const InsuranceStats = ({ data }: InsuranceStatsProps) => {
  const context = usePartnerContext();
  const device = useBreakpointV2();
  return (
    <InsuranceStatsWrap id="insurance-stats">
      <InsuranceStatsStyle pageTheme={context ? context : undefined}>
        {data &&
          data.map((item, index) => {
            return (
              <div key={index}>
                {device == "lg" ? (
                  <H4Tag margin="0 0 4px">{item.title}</H4Tag>
                ) : (
                  <H5Tag margin="0 0 4px">{item.title}</H5Tag>
                )}
                <ParaTag fontSize={device == "lg" ? "md" : "xs"}>
                  {item.info}
                </ParaTag>
              </div>
            );
          })}
      </InsuranceStatsStyle>
    </InsuranceStatsWrap>
  );
};

export default InsuranceStats;
