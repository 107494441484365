import styled from "styled-components";

interface PersuasionBannerStyle {
  bgImage?: string;
  margin?: string | number;
}

export const PersuasionBannerStyle = styled.div<PersuasionBannerStyle>`
  ${(props) => props.margin && `margin: ${props.margin};`}
  padding: 40px 60px;
  border-radius: 25px;
  background-image: ${(props) => `url(${props.bgImage})`};
  background-size: 100% 100%;
  min-height: 400px;
  background-repeat: no-repeat;
`;

export const BulletListStyle = styled.ul`
  list-style: none;
  margin-bottom: 40px;
  padding: 0;
`;
