import styled from "styled-components";
import { ParaTagStyle, H2TagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "@tm/static/utils";

export const FeaturesDemoListStyle = styled.div<any>`
  margin: ${(props) => props.margin};
  padding: 40px 0px 0px 0px;
  margin-bottom: 110px;

  @media ${deviceType.mobile} {
    padding: 48px 0px 0px 0px;
    margin-bottom: 56px;
  }
`;

export const Label = styled(H2TagStyle)<any>`
  @media ${deviceType.mobile} {
    font-size: 24px;
    line-height: 28px;
    padding: 0px 16px;
  }
`;

export const CardsWpr = styled.ul<any>`
  display: flex;
  margin-top: 56px;
  justify-content: space-between;

  @media ${deviceType.mobile} {
    padding: 0px 16px;
    margin-top: 40px;
    & {
      overflow-x: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
    }
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }
`;

export const Card = styled.li<any>`
  width: 300px;
  flex-shrink: 0;
`;

export const CardLabel = styled(ParaTagStyle)<any>`
  margin-top: 17px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  > span {
    font-weight: 700;
  }
`;
