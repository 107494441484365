import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerCont = styled.span`
  display: inline-block;
  height: 22px;
  width: 22px;
  animation: ${rotate} 1s linear infinite;
  border-right-color: transparent;
  border-radius: 50%;
  margin: -2px auto;
`;
