import * as React from "react";
interface followInterface {}
const FollowIcon = (props: followInterface) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1.00391}
      width={18}
      height={18}
      rx={4.5}
      stroke="#979797"
      strokeWidth={0.9}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0415 4.96289C11.9299 4.96299 10.9047 5.57839 10.3601 6.57268C9.81554 5.57839 8.79033 4.96299 7.67872 4.96289C5.97851 4.96289 4.6001 6.58084 4.6001 8.32515C4.6001 12.7063 10.3601 15.7629 10.3601 15.7629C10.3601 15.7629 16.1201 12.7063 16.1201 8.32515C16.1201 6.58084 14.7417 4.96289 13.0415 4.96289Z"
      fill="url(#paint0_linear_841_15511)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_841_15511"
        x1={9.28186}
        y1={21.9595}
        x2={21.7595}
        y2={10.3613}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0772C" />
        <stop offset={1} stopColor="#F95776" />
      </linearGradient>
    </defs>
  </svg>
);
export { FollowIcon };
