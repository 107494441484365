interface LocationPinIconProps {
  color?: string;
}

export const LocationPinIcon = ({
  color = "#fff",
  ...props
}: LocationPinIconProps) => (
  <svg
    width={17}
    height={20}
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.25 7.82535C16.25 12.1476 11.2847 16.9558 8.125 19.9993C4.51389 16.521 0 12.1476 0 7.82535C0 3.50313 3.63769 -0.000732422 8.125 -0.000732422C12.6123 -0.000732422 16.25 3.50313 16.25 7.82535Z"
      fill={color}
    />
    <ellipse cx={8.125} cy={7.99927} rx={6.09375} ry={6} fill="black" />
  </svg>
);
