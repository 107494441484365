import React from "react";
import {
  LabelCtr,
  ForexOfferingsStyle,
  ForexOfferingCntnt,
  CardImgContainer,
} from "./ForexOfferingCard.style";
import { landingForexOfferingCardDataProps } from "../../mockData";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import BulletListItem from "../BulletListItem/BulletListItem";
import { theme } from "../../css/Global";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";
import { PageNameMapping, ElementNameMapping } from "../../../constants";
import { getElementUniqueIds, getCamelCaseStr } from "../../../utils";

interface OptionsList {
  data: landingForexOfferingCardDataProps;
  margin?: string;
  onClick: (item: landingForexOfferingCardDataProps) => void;
}

const ForexOfferings: React.FC<OptionsList> = ({ data, margin, onClick }) => {
  return (
    <ForexOfferingsStyle margin={margin}>
      <CardImgContainer>
        <ImageTag src={data.cardImg} />
      </CardImgContainer>
      <ForexOfferingCntnt>
        {(data.cardTitle || data.cardInfo) && (
          <LabelCtr>
            {data.cardTitle && (
              <ParaTag fontSize="large" fontWeight="bold">
                {data.cardTitle}
              </ParaTag>
            )}
            {data.cardInfo && (
              <SpanTag fontSize="md" fontWeight="bold">
                {data.cardInfo}
              </SpanTag>
            )}
          </LabelCtr>
        )}
        <ul>
          {data.cardFeatList.map((item) => {
            return (
              <BulletListItem key={item.id} bulletColor={theme.blueText}>
                {item.boldText && (
                  <SpanTag fontWeight="bold">{item.boldText}</SpanTag>
                )}{" "}
                {item.normalText}
              </BulletListItem>
            );
          })}
        </ul>
        <FlexComp
          margin="22px 0px 0px"
          justifyContent="center"
          width="auto"
          height="auto"
        >
          <AnchorTag
            id={getElementUniqueIds(
              PageNameMapping.LISTING_PAGE,
              getCamelCaseStr(data.cardTitle),
              ElementNameMapping.KNOW_MORE_BTN
            )}
            fontSize="sm"
            fontWeight="bold"
            textAlign="center"
            onClick={() => onClick(data)}
          >
            {data.ctaTxt}
          </AnchorTag>
        </FlexComp>
      </ForexOfferingCntnt>
    </ForexOfferingsStyle>
  );
};

export default ForexOfferings;
