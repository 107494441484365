import styled from "styled-components";
import { deviceType } from "@tm/static/utils";
import { theme } from "@tm/utility/global";
import { SpanTagStyle } from "../TextStyles/TextStyles.style";

type DeliveryPersuasionCompProps = {
  margin?: string;
  padding?: string;
  bgColor?: string;
  checkPincode?: boolean;
};

export const DeliveryPersuasionStyle = styled(SpanTagStyle)<any>`
  border-radius: 4px;
  width: fit-content;
  color: ${theme.greyText};
  font-size: 18px;
  line-height: 24px;

  .boldText {
    font-weight: 700;
  }

  .blueText {
    color: ${theme.blueText};
  }

  .greenText {
    color: ${theme.greenText};
  }

  @media ${deviceType.mobile} {
    font-size: 14px;
    line-height: 19px;
  }
`;

export const DeliveryLocationStyle: any = styled(SpanTagStyle)<any>`
  font-size: ${(props: any) => (props?.fontSize ? props.fontSize : "18px")};
  color: ${theme.blueText};

  font-weight: bold;
  margin: 0px 0px 0px 4px;

  @media ${deviceType.mobile} {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DeliveryPersuasionComp = styled.div<DeliveryPersuasionCompProps>`
  display: flex;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  background: ${(props) => props.bgColor};
  flex-direction: ${(props) => (props.checkPincode ? "column" : "row")};
  align-items: ${(props) => (props.checkPincode ? "flex-start" : "center")};
  flex-wrap: wrap;
`;

export const Cta = styled.a<any>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};
`;
export const ImgWpr = styled.div`
  margin: 0 0 0 6px;
`;
