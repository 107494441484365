import { PDT_EVENTS } from "../../constants";
import { getEventDetails, shouldOpenMMTLogin } from "../../utils";
import { loginInPartner } from "../../utils/authUtils";
import { useGlobalConfig } from "../useGlobalConfig";
import useListingPdtLoggerV2 from "../useListingPdtLoggerV2";
import { useListingPdtLogger } from "../usePdtLogger";

const useOpenLoginForm = () => {
  const { onLoginClick } = useGlobalConfig();
  const { sendPdtLoginPopOpen } = useListingPdtLogger();
  const { sendCTAData } = useListingPdtLoggerV2();

  const sendPdtLoginPopupOpenV2 = () => {
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.LOGIN_POP_UP_OPEN,
    });
    sendCTAData({ eventDetail });
  };
  const openLoginFormForNonLoggedInUser = async () => {
    if (shouldOpenMMTLogin()) {
      sendPdtLoginPopOpen();
      sendPdtLoginPopupOpenV2();
      onLoginClick?.();
      return;
    }
    await loginInPartner(sendPdtLoginPopOpen, sendPdtLoginPopupOpenV2);
    return;
  };

  return {
    openLoginFormForNonLoggedInUser,
  };
};

export default useOpenLoginForm;
