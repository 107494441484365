import React from "react";

import { AnchorTagStyle } from "./TextStyles.style";

interface AnchorTagProps {
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  children: any;
  handleClick?: any;
  hrefProp?: any;
  textAlign?: any;
  margin?: string;
  target?: string;
  flexShrink?: string;
  id?: string;
}

const AnchorTag: React.FC<AnchorTagProps> = ({
  children,
  fontSize = "sm",
  fontWeight = "regular",
  color,
  handleClick,
  hrefProp,
  textAlign,
  margin,
  target,
  flexShrink,
  id,
}) => {
  return (
    <AnchorTagStyle
      textAlign={textAlign}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      onClick={handleClick}
      href={hrefProp}
      margin={margin}
      target={target}
      flexShrink={flexShrink}
      id={id}
    >
      {children}
    </AnchorTagStyle>
  );
};

export default AnchorTag;
