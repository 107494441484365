import { MouseEventHandler } from "react";
import { PersuasionObject, PersuasionType } from "../../../types";
import { getPersuasionProp } from "../../../utils/componentPropsMapper";
import { landingCardShowcaseDataProps } from "../../mockData";
import BackendPersuasion from "./Persuasions/BackendPersuasion";
import DeliveryStatusPersuasion from "./Persuasions/DeliveryStatusPersuasion";
import DeliveryTrackingPersuasion from "./Persuasions/DeliveryTrackingPersuasion";
import DropOffPersuasion from "./Persuasions/DropOffPersuasion";
import MiniDashboardPersuasion from "./Persuasions/MiniDashboardPersuasion";
import UnavailableLocationPersuasion from "./Persuasions/UnavailableLocationPersuasion";
import UploadDocsPersuasion from "./Persuasions/UploadDocsPersuasion";
import ViewOrdersPersuasion from "./Persuasions/ViewOrdersPersuasion";
import CrossSellPersuasion from "./Persuasions/CrossSellPersuasion";
import { FreeCardOffer } from "./Persuasions/ProductOffers";
import { excludePersuasionList } from "../../../constants";
import ReloadTripPersuasion from "./Persuasions/ReloadTripPersuasion";

export interface PersuasionHandler {
  onClickAnotherLocation: Function;
  docsPendingHandler: Function;
  loadCurrenyBtnHandler: Function;
  recommendedBtnHandler: Function;
  startFromWhereLeftHandler: Function;
  deliveryUnavailbleClickHandler: MouseEventHandler<HTMLSpanElement>;
  sendCrossSellPdtEvent: Function;
  reloadTripHandler: Function;
}

interface PersuasionInterface {
  cardData: landingCardShowcaseDataProps;
  persuasionData: PersuasionObject;
  index: string;
  handlers: PersuasionHandler;
}

interface PersuasionListingInterface {
  persuasions?: PersuasionObject[];
  cardData: landingCardShowcaseDataProps;
  handlers: PersuasionHandler;
}

const perusasionTypeToComponentMapping: Record<PersuasionType, any> = {
  backend: BackendPersuasion,
  dropoff: DropOffPersuasion,
  delivery_tracking: DeliveryStatusPersuasion,
  mini_dashboard: MiniDashboardPersuasion,
  upload_docs: UploadDocsPersuasion,
  view_orders: ViewOrdersPersuasion,
  activation_in_progress: DeliveryStatusPersuasion,
  start_order: () => {},
  undeliverable: UnavailableLocationPersuasion,
  upcoming_trips: DeliveryStatusPersuasion,
  cross_sell: CrossSellPersuasion,
  free_card: FreeCardOffer,
  reload_discovery: ReloadTripPersuasion,
};

export default function PersuasionListing(props: PersuasionListingInterface) {
  return (
    <>
      {props.persuasions?.map((persuasionData, key) => {
        if (excludePersuasionList?.includes(persuasionData?.type || "")) {
          return null;
        }
        return (
          <Persuasion
            handlers={props.handlers}
            index={"persuasion_" + key}
            cardData={props.cardData}
            persuasionData={persuasionData}
            key={`${persuasionData.type}${key}`}
          />
        );
      })}
    </>
  );
}

function Persuasion(props: PersuasionInterface) {
  if (
    !props.persuasionData.type ||
    !perusasionTypeToComponentMapping[props.persuasionData.type]
  ) {
    return <></>;
  }

  const persuasionProps = getPersuasionProp(
    props.persuasionData.type,
    props.persuasionData.data
  );
  const PersuasionComponent =
    perusasionTypeToComponentMapping[props.persuasionData.type];

  return (
    <PersuasionComponent
      type={props.persuasionData.type}
      handlers={props.handlers}
      cardData={props.cardData}
      persuasionProps={persuasionProps}
      index={props.index}
    />
  );
}
