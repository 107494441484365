import { fetchApiForexListing } from "../../api/fetchApi";
import {
  FOREX_RATES_FILER_MODE,
  SESSION_STORAGE_KEYS,
  ERROR_MSGS,
  getSessionPiiDataOpts,
  shortenHeader,
  ERROR_CODE,
} from "../../constants";
import { fetchForexCurrencyRateAction } from "../../store/action";
import { ListingReducerType } from "../../store/forexListingReducer";
import { DeliveryDetails, ForexRates } from "../../types";
import {
  generateRequestId,
  getContextUrl,
  getRequestIdHeaders,
} from "../../utils";
import { getSessionStorageItem } from "../../utils/clientStorageUtils";
import { useGlobalConfig } from "../useGlobalConfig";
import { useGlobalDispatch } from "../useGlobalDispatch";
import { useGlobalSelector } from "../useGlobalSelector";
import useListingPdtLoggerV2 from "../useListingPdtLoggerV2";
import { useListingPdtLogger } from "../usePdtLogger";

const useFetchForexRatex = () => {
  const dispatch = useGlobalDispatch();
  const { partner } = useGlobalConfig();
  const { forex_rates } = useGlobalSelector(
    (state: ListingReducerType) => state || {}
  );
  const { sendPdtRateTickerViewEvent, sendPdtListingError } =
    useListingPdtLogger();

  const { sendPdtListingErrorV2 } = useListingPdtLoggerV2();

  const { sendPdtRateTickerViewEventV2 } = useListingPdtLoggerV2();

  const fetchForexRates = async () => {
    const contextUrl = getContextUrl(partner);
    let response;
    try {
      const userLocationData = getSessionStorageItem(
        SESSION_STORAGE_KEYS.USER_LOCATION_SESSION_KEY,
        true,
        getSessionPiiDataOpts
      );
      const data = getBody(forex_rates, userLocationData);
      const reqId = generateRequestId();
      try {
        response = await fetchApiForexListing(contextUrl, {
          method: "POST",
          data,
          headers: {
            ...getRequestIdHeaders(reqId),
          },
        });
      } catch (e: any) {
        const error = {
          apiUrl: contextUrl,
          code: e?.message?.includes(ERROR_MSGS.FETCH_ERROR)
            ? ERROR_CODE.NETWORK
            : "",
          description: e?.message?.includes(ERROR_MSGS.FETCH_ERROR)
            ? ERROR_MSGS.FETCH_ERROR
            : e?.message,
        };
        sendPdtListingError(error);
        sendPdtListingErrorV2(error);
        console.error(e);
        return e;
      }
      const forexRates = response?.data?.forex_rates;
      if (response?.success) {
        if (!forexRates || !Object.keys(forexRates).length) {
          const error = {
            errorDescription: ERROR_MSGS.FOREX_RATES_NOT_FOUND,
          };
          sendPdtListingError(error);
          sendPdtListingErrorV2(error);
        } else {
          sendPdtRateTickerViewEvent(response?.data?.forex_rates);
          sendPdtRateTickerViewEventV2(response?.data?.forex_rates, reqId);
          dispatch(fetchForexCurrencyRateAction(response?.data?.forex_rates));
        }
      } else {
        if (response?.success === false) {
          //ERROR PDT
          const error = {
            errorDescription: ERROR_MSGS.FOREX_RATES_SUCCESS_ERROR,
          };
          sendPdtListingError(error);
          sendPdtListingErrorV2(error);
        }
      }
    } catch (e: any) {
      //ERROR PDT
      const error = {
        apiUrl: contextUrl,
        code: e?.message?.includes(ERROR_MSGS.FETCH_ERROR)
          ? ERROR_CODE.NETWORK
          : "",
        description: e?.message?.includes(ERROR_MSGS.FETCH_ERROR)
          ? ERROR_MSGS.FETCH_ERROR
          : e?.message,
      };
      sendPdtListingError(error);
      sendPdtListingErrorV2(e, ERROR_MSGS.FOREX_RATES_API_ERROR);
      console.error(e);
    }
    return response;
  };
  return {
    fetchForexRates,
  };
};
export default useFetchForexRatex;

export function getBody(
  forex_rates: ForexRates | null | undefined,
  delivery_details: DeliveryDetails | null | undefined
) {
  const filter = getForexRatesFilterInfo(forex_rates);
  return {
    query: {
      mandatory: {
        forex_rates: {
          filter,
        },
      },
    },
    data: {
      location: {
        ...delivery_details,
      },
    },
  };
}

export function getForexRatesFilterInfo(forex_rates?: ForexRates | null) {
  let filter = null;
  if (forex_rates && Object.keys(forex_rates).length > 0) {
    filter = {
      mode: FOREX_RATES_FILER_MODE.SELECT,
      currencies: Object.keys(forex_rates),
    };
  } else {
    filter = {
      mode: FOREX_RATES_FILER_MODE.TOP,
    };
  }
  return filter;
}
