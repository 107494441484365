import React from "react";

import {
  ImageComponent,
  LandingButton,
  ParaTag,
  FormattedText,
  AnchorTag,
  FlexComp,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { navigationActions } from "@tm/insurance/navigation";
import { TextNodeType } from "../FormattedText/FormattedText";
import {
  ManageInsuranceStyle,
  TitleText,
  InfoText,
} from "./ManageInsurance.style";
interface manageInsProp {
  title?: TextNodeType;
  info?: TextNodeType;
  orderBtn?: string;
  handleManageOrder?: () => void;
  goHomeText?: string | TextNodeType;
}

const ManageInsurance = ({
  title,
  info,
  orderBtn,
  handleManageOrder,
  goHomeText,
}: manageInsProp) => {
  const gotoHome = () => {
    navigationActions.navigateToLanding();
  };

  return (
    <ManageInsuranceStyle>
      <ImageComponent
        width={"104px"}
        height={"104px"}
        src="https://tripmoneycmsimgak.mmtcdn.com/img/manage_Ins_Bg_410326bba8.svg"
      />
      <TitleText>
        <FormattedText value={title} />
      </TitleText>
      {info && (
        <InfoText>
          <FormattedText value={info} />
        </InfoText>
      )}
      <LandingButton
        btnRightIcon="https://tripmoneycmsimgak.mmtcdn.com/img/white_Chev_8257cb46d7.svg"
        customWidth="100%"
        customTitle={orderBtn}
        onClick={handleManageOrder}
      />
      {/* CB */}
      {goHomeText && (
        <FlexComp
          margin="16px 0 0"
          isCursorPointer
          alignItems="center"
          gap="4px"
          handleClick={gotoHome}
        >
          <AnchorTag textTransform="uppercase" fontSize="xs" fontWeight="black">
            <FormattedText value={goHomeText} />
          </AnchorTag>
          <ImageComponent
            width="16px"
            height="16px"
            src="https://tripmoneycmsimgak.mmtcdn.com/img/right_arrow_4a1f2bfef9.svg"
          />
        </FlexComp>
      )}
    </ManageInsuranceStyle>
  );
};

export default ManageInsurance;
