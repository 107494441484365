import React, {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import WebToast from "../Toast/WebToast";
import CountryList from "./CountryList";

import { theme } from "@tm/utility/global";
import {
  FlexComp,
  TMFloatingInput,
  CommonModalDweb,
  LandingButton,
  ParaTag,
  SpanTag,
  ReactPortalComp,
  FormattedText,
  AnchorTag,
} from "@tm/static";
import { debounce } from "@tm/insurance/utils";
import {
  replaceHistory,
  replaceHistoryRemoveParams,
} from "@tm/insurance/utils";
import { getAnalytics } from "@tm/insurance/analytics";

import {
  CountrySectionWrap,
  SelectCountrySheetStyle,
  SelectCountryHeader,
  BtnWrap,
} from "./SelectCountryModalV2.style";

const analytics = getAnalytics();
interface SelectCountryModalProps {
  handleSelectCountry: () => void;
  countryData: {
    code?: string;
    countryCount?: string;
    descTitle?: string;
    description?: string;
    isPopular?: boolean;
    keywords?: string;
    img?: string;
    subTitle?: string;
    isMandatory?: string;
    regionNames?: string;
    regionCodes?: string[];
    name?: string;
    type?: string;
  }[];
  countrySelectErrMsg?: string;
  popularRegion?: {
    id: number;
    regionName?: string;
    regionalCountryTxt?: string;
    countryList?: string[];
    isSelected?: boolean;
    regionInfo?: string;
  }[];
  showCountry: {
    code?: string;
    countryCount?: string;
    descTitle?: string;
    description?: string;
    isPopular?: boolean;
    keywords?: string;
    img?: string;
    subTitle?: string;
    isMandatory?: string;
    regionNames?: string;
    regionCodes?: string[];
    name?: string;
    type?: string;
  }[];
  customSubTitle?: string;
  setShowCountry: Dispatch<SetStateAction<any[] | undefined>>;
  setIsCountryError: Dispatch<SetStateAction<boolean>>;
  popularCountries?: {
    code: string;
    isMandatory: boolean;
    isPopular: boolean;
    name: string;
    keywords: string;
    flag: string;
    img: string;
  }[];
  handleDescriptionModal: (code: string) => string;
  setCountryData: Dispatch<SetStateAction<any[]>>;
  setPopularCountries: Dispatch<SetStateAction<any[]>>;
}

const SelectCountryModalSheet = ({
  handleSelectCountry,
  countryData,
  setShowCountry,
  setCountryData,
  showCountry,
  countrySelectErrMsg,
  setIsCountryError,
  popularCountries,
  setPopularCountries,
  handleDescriptionModal,
  customSubTitle,
}: SelectCountryModalProps) => {
  const [countriesData, setCountriesData] = useState<any[]>([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [finalCountryData, setFinalCountryData] = useState<any>([]);
  const [isSelectedError, setIsSelectedError] = useState<boolean>(false);
  const searchedCountriesOmn = useRef<string[]>([]);
  const selectedCountriesOmn = useRef<string[]>([]);
  useEffect(() => {
    const selectedCountry = countryData?.filter(
      (data: any) => data.isSelected && !data.isDisabled
    );
    const modalData = [
      ...countryData.filter((data: any) => !data.isSelected || data.isDisabled),
    ];
    if (selectedCountry.length) {
      const selectedHeader = {
        type: "HEADER",
        name: "Selected Countries",
        code: "SELECTED_COUNTRIES",
      };
      setFinalCountryData([
        { ...selectedHeader },
        ...selectedCountry,
        ...modalData,
      ]);
    } else {
      setFinalCountryData([...modalData]);
    }
  }, [countryData]);

  const handleSearchtxt = debounce(() => {
    const filteredCountriesData = finalCountryData.filter(
      (item: any) =>
        item.type !== "HEADER" &&
        item.name?.toLowerCase().includes(searchTxt.toLowerCase())
    );
    setCountriesData([...filteredCountriesData]);
  }, 300);

  useEffect(() => {
    if (searchTxt) {
      handleSearchtxt();
    } else {
      const countriesDataArr = finalCountryData.map((data: any) => {
        if (data.type !== "HEADER") {
          return { ...data };
        }
      });
      setCountriesData(countriesDataArr);
    }
  }, [searchTxt, finalCountryData]);

  const countryHandler = (code: string) => {
    handleDescriptionModal(
      finalCountryData.filter((data: any) => data.code === code)[0]
    );
    let countryArr = finalCountryData?.map((country: any) => {
      if (country.code === code) {
        if (country["isSelected"]) {
          country["isSelected"] = false;
          return { ...country };
        }
        if (selectedCountriesOmn?.current?.length >= 30) {
          selectedCountriesOmn.current = [
            ...selectedCountriesOmn?.current?.slice(1),
            country.code,
          ];
        } else {
          selectedCountriesOmn.current = [
            ...selectedCountriesOmn?.current,
            country.code,
          ];
        }
        return { ...country, isSelected: true, isDisabled: false };
      }
      return { ...country, isDisabled: false };
    });

    const selectedCountryCodes = countryArr
      .filter((country: any) => country.isSelected && country.type === "REGION")
      .map((country: any) => {
        return country.code;
      });
    selectedCountryCodes.map((item: any) => {
      countryArr = countryArr.map((country: any) => {
        if (country.regionCodes && country.regionCodes.includes(item)) {
          return { ...country, isDisabled: true };
        }
        return { ...country };
      });
    });
    setFinalCountryData(countryArr);
    const popularCountryData = countryArr.filter(
      (country: any) => country.isSelected || country.isPopular
    );
    setPopularCountries([
      ...popularCountryData.filter(
        (country: any) => country.isSelected && !country.isDisabled
      ),
      ...popularCountryData.filter((country: any) => country.isDisabled),
      ...popularCountryData.filter(
        (country: any) => !country.isSelected && !country.isDisabled
      ),
    ]);
    const selected = countryArr.filter(
      (country: any) => country.isSelected === true
    );
  };

  const searchQueryHighlighter = (ele: string) => {
    const withSpecialCharsSupport = searchTxt?.replace(
      /[.*+?^${}()|[\]\\]/g,
      "\\$&"
    );

    const searchRegex = new RegExp(`(${withSpecialCharsSupport})`, "ig");

    const highlighted = ele.replace(
      searchRegex,
      (match) => `<span class="highlight">${match}</span>`
    );

    return <ParaTag dangerousText={highlighted} />;
  };

  const isCheckedHandler = useCallback(
    (data: any) => {
      return data.isSelected;
    },
    [finalCountryData]
  );

  const handleCrossClick = () => {
    setPopularCountries(showCountry);
    if (searchedCountriesOmn?.current?.length) {
      analytics?.trackLandingEvents({
        actionType: "searched_countries",
        payload: searchedCountriesOmn?.current?.join("|") + "|",
      });
      searchedCountriesOmn.current = [];
    }
    let tempCountryData: any = finalCountryData.map((country: any) => {
      return { ...country, isSelected: false, isDisabled: false };
    });
    showCountry.map((data: any) => {
      tempCountryData = tempCountryData.map((country: any) => {
        if (data.code === country.code) {
          return {
            ...country,
            isSelected: data.isSelected,
            isDisabled: data.isDisabled,
          };
        }
        return { ...country };
      });
    });

    setFinalCountryData({ ...tempCountryData });
    handleSelectCountry();
  };

  const onCountrySubmit = () => {
    setIsCountryError(false);
    if (searchedCountriesOmn?.current?.length) {
      analytics?.trackLandingEvents({
        actionType: "searched_countries",
        payload: searchedCountriesOmn?.current?.join("|") + "|",
      });
      searchedCountriesOmn.current = [];
    }
    if (selectedCountriesOmn?.current?.length) {
      analytics?.trackLandingEvents({
        actionType: "selected_countries",
        payload: selectedCountriesOmn?.current?.join("|"),
      });
      selectedCountriesOmn.current = [];
    }
    let countryDataArr = countryData.map((country: any) => {
      return { ...country, isSelected: false, isDisabled: false };
    });

    finalCountryData.map((data: any) => {
      countryDataArr = countryDataArr.map((country: any) => {
        if (country.code === data.code) {
          return {
            ...country,
            isSelected: data.isSelected,
            isDisabled: data.isDisabled,
          };
        }
        return { ...country };
      });
    });
    setCountryData(countryDataArr);
    setShowCountry(popularCountries);

    const selectedCountryCodes = finalCountryData
      .filter((item: any) => item.isSelected && !item.isDisabled)
      .map((item: any) => item.code);

    replaceHistoryRemoveParams("countries");
    replaceHistory("countries", selectedCountryCodes.join(","));
    const selectedNonPopCountries = finalCountryData.filter(
      (data: any) => data.isSelected && !data.isPopular
    );
    analytics?.trackLandingEvents({
      actionType: "other_countries_clicked",
      payload: selectedNonPopCountries.map((c: any) => c.name).join("|"),
    });

    handleSelectCountry && handleSelectCountry();
  };

  const handleSearchTxt = (event: any) => {
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      if (searchedCountriesOmn?.current?.length >= 30) {
        searchedCountriesOmn.current = [
          ...searchedCountriesOmn?.current?.slice(1),
          searchTxt,
        ];
      } else {
        searchedCountriesOmn.current = [
          ...searchedCountriesOmn?.current,
          searchTxt,
        ];
      }
    }
    setSearchTxt((_) => event.target.value);
  };
  return (
    <ReactPortalComp>
      <SelectCountrySheetStyle>
        <SelectCountryHeader>
          <AnchorTag handleClick={handleCrossClick}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                fill="#808080"
              />
            </svg>
          </AnchorTag>

          <FlexComp flexDirection="column">
            <ParaTag fontSize="md" fontWeight="bold" color={theme.greyText}>
              <FormattedText value="Select Destinations" />
            </ParaTag>
            <SpanTag fontSize="xs" color={theme.greyText6}>
              <FormattedText value={customSubTitle} />
            </SpanTag>
          </FlexComp>
        </SelectCountryHeader>
        <TMFloatingInput
          handleChange={(e) => handleSearchTxt(e)}
          inputValue={searchTxt}
          labelTxt="Search Country/Region"
        />
        <CountrySectionWrap>
          {countriesData?.length ? (
            <>
              {searchTxt ? (
                <CountryList
                  countryData={countriesData}
                  countryHandler={countryHandler}
                  isCheckedHandler={isCheckedHandler}
                  searchQueryHighlighter={searchQueryHighlighter}
                  setCountryData={setCountriesData}
                  setIsSelectedError={setIsSelectedError}
                />
              ) : (
                <CountryList
                  countryData={finalCountryData}
                  countryHandler={countryHandler}
                  isCheckedHandler={isCheckedHandler}
                  searchQueryHighlighter={searchQueryHighlighter}
                  setCountryData={setFinalCountryData}
                  setIsSelectedError={setIsSelectedError}
                />
              )}
            </>
          ) : (
            <ParaTag
              fontSize="sm"
              color={theme.greyText}
              margin="30px 0px 0px 0px"
            >
              No Country Found! Please enter correct name
            </ParaTag>
          )}
        </CountrySectionWrap>
        {isSelectedError && (
          <WebToast
            toastTxt={countrySelectErrMsg}
            position="fixed"
            height="40px"
            bottom="75px"
          />
        )}

        <BtnWrap position="fixed" bottom="0px" background="white">
          <LandingButton
            margin="12px 16px 12px 16px"
            customWidth="92%"
            customTitle={"Add Destination"}
            onClick={onCountrySubmit}
          />
        </BtnWrap>
      </SelectCountrySheetStyle>
    </ReactPortalComp>
  );
};

export default SelectCountryModalSheet;
