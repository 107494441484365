import React, { Fragment } from "react";
import { AnchorTag, FlexComp, ParaTag, SpanTag } from "@tm/static";

import { breakpoint } from "@tm/static/utils";
import { useBreakpoint } from "@tm/ui/hooks";

import { PersuasionTileStyles, LabelTag } from "./PersuasionTile.style";
import { theme } from "@tm/utility/global";
import { BtnProp } from "../LandingButton/ButtonType";

interface PersuasionTileProps {
  bgColor?: string;
  margin?: string;
  leftIcon?: React.ReactNode;
  linkBtn?: {
    label?: string;
    onClick?: () => void;
  };
  text?: {
    blackBoldText?: string;
    greenBoldText?: string;
    normalText?: string;
    linkText?: {
      text: string;
      hrefLink: string;
    };
  };
  normalTextColor?: string;
}

const PersuasionTile = ({
  bgColor,
  leftIcon,
  text,
  margin,
  normalTextColor = "black",
  linkBtn,
}: PersuasionTileProps) => {
  const device = useBreakpoint();

  const paraTagRenderer = () => {
    return (
      <ParaTag fontSize="xs">
        {text && text.blackBoldText && (
          <SpanTag fontSize="xs" fontWeight="bold" color={theme.blackText}>
            {text.blackBoldText}
          </SpanTag>
        )}{" "}
        {text && text.greenBoldText && (
          <SpanTag color={theme.greenText} fontSize="xs" fontWeight="bold">
            {text.greenBoldText}
          </SpanTag>
        )}{" "}
        {text && (
          <SpanTag fontSize="xs" color={normalTextColor}>
            {text.normalText}
          </SpanTag>
        )}{" "}
        {/* {text && text.linkText && (
                    <AnchorTag
                        fontSize="xs"
                        fontWeight="bold"
                        hrefProp={text.linkText.hrefLink}
                    >
                        {text.linkText.text}
                    </AnchorTag>
                )} */}
      </ParaTag>
    );
  };

  return (
    <PersuasionTileStyles margin={margin} bgColor={bgColor}>
      {device === "lg" ? (
        <Fragment>
          <FlexComp gap="4px" alignItems="center" flex="1" padding="0">
            {leftIcon && leftIcon}
            {paraTagRenderer()}
          </FlexComp>
          {linkBtn && (
            <AnchorTag
              margin="0px 0px 0px 8px"
              fontSize="xs"
              fontWeight="bold"
              handleClick={linkBtn.onClick}
            >
              {linkBtn.label}
            </AnchorTag>
          )}
        </Fragment>
      ) : (
        <FlexComp gap="12px" flex="1" alignItems="center" padding="0">
          {leftIcon && leftIcon}
          <FlexComp gap="0px" flexDirection="column" flex="1" padding="0">
            {paraTagRenderer()}
            {linkBtn && (
              <AnchorTag
                handleClick={linkBtn.onClick}
                fontSize="xs"
                fontWeight="bold"
              >
                {linkBtn.label}
              </AnchorTag>
            )}
          </FlexComp>
        </FlexComp>
      )}
    </PersuasionTileStyles>
  );
};

export default PersuasionTile;
