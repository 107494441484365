import styled from "styled-components";
import { deviceType } from "@tm/static/utils";

export const CurrenciesListStyle = styled.ul<any>`
  height: 60vh;
  overflow-y: scroll;
  @media ${deviceType.mobile} {
    width: 100%;
  }
`;

export const ListItem = styled.li<any>`
  display: flex;
  align-items: center;
  padding: 16px 0px;
  border-bottom: 1px solid #e8e8e8;
`;

export const LabelWpr = styled.div<any>`
  flex: 1;
  display: flex;
  align-items: center;
`;
