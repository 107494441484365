import styled from "styled-components";

export const SectionDividersStyle: any = styled.div`
  background: #f2f2f2;
  width: var(--tm-width, unset);
  height: var(--tm-height, 4px);
  border-radius: var(--borderRadius, 0px);
  margin: var(--margin, 0px);
  ${(p: any) => p.xs && `height: 1px;`};
  ${(p: any) => p.small && `height: 10px;`};
  ${(p: any) => p.large && `height: 25px;`};
  flex-shrink: 0;
`;
