import { getRedirectionUrl, openOutsideUriGi } from "./utils";
import { AddOrSelectDocData, GoIbibo } from "./utils";
import { AnchorTag, Modal, ParaTag, Toast } from "@tm/static";
import { CloseIcon } from "@tm/static/icons";
import Warning from "@tm/static/icons/Warning.svg";
import { useBreakpoint } from "@tm/ui/hooks";
import { getMobileOperatingSystem } from "@tm/utility";
import { theme } from "@tm/utility/global";
import { IOS } from "libs/auth/src/lib/constants";
import FlightInfo from "./FlightInfo";
import SelectedFile from "./SelectedFile";
import { useState } from "react";
import { AddOrSelectTicketProps, UpcomingBookingData } from "./type";
import { UploadTicketImage } from "./UploadTicketImage";
import LandingButton from "@forex/ui/ui-container/LandingButton/LandingButton";

const AddOrSelectTicket = ({
  onClose,
  onSubmitClick,
  selectedTicketCount,
  ticketData,
  partner,
  prefetchedTickets,
  onRemoveImage,
  onTicketSelect,
  onUploadClick,
  onEditClick,
  handleDisabledTextClick,
  showToast,
  setShowToast,
  renderUploadImage,
}: AddOrSelectTicketProps) => {
  const device = useBreakpoint();
  const [showingMoreItems, setShowingMoreItems] = useState(false);

  const documentLink = (item: any) => {
    const url = getRedirectionUrl(item?.absolute_url || item?.url);
    return partner === GoIbibo &&
      getMobileOperatingSystem()?.toLowerCase() === IOS
      ? ""
      : url;
  };

  const handlePreiewEyeClick = (item: any) => {
    const url = item?.absolute_url || item?.url;
    return partner === GoIbibo &&
      getMobileOperatingSystem()?.toLowerCase() === IOS
      ? openOutsideUriGi(url)
      : null;
  };

  const upcomingTripTickets = prefetchedTickets?.slice(
    0,
    showingMoreItems ? prefetchedTickets?.length : 2
  );

  return (
    <Modal
      onModalClose={onClose}
      modalType={device === "lg" ? "centered" : "bottomSheet"}
      modalHeading={AddOrSelectDocData.heading}
      closeButton={<CloseIcon />}
      modalHeadingCtrMargin="0 0 20px 0"
    >
      <ParaTag color={theme.greyText} margin="0 0 20px 0">
        {AddOrSelectDocData.subHeading}
      </ParaTag>
      {upcomingTripTickets.map((upcomingTripTicket: UpcomingBookingData) => (
        <div key={upcomingTripTicket?.id}>
          {upcomingTripTicket?.isSelected ? (
            <SelectedFile
              id={upcomingTripTicket?.id}
              fileName={upcomingTripTicket?.fileName}
              onCloseClick={(id) => onRemoveImage(id)}
              handlePreiewEyeClick={handlePreiewEyeClick}
              documentLink={documentLink}
              ticketData={upcomingTripTicket}
            />
          ) : (
            <FlightInfo
              ticket={upcomingTripTicket}
              disabledText={selectedTicketCount === 2}
              onSelectClick={(id) => onTicketSelect(id)}
              selectedTicketCount={selectedTicketCount}
            />
          )}
        </div>
      ))}
      {prefetchedTickets?.length > 2 && (
        <AnchorTag
          fontWeight="bold"
          fontSize="xs"
          handleClick={() => setShowingMoreItems(!showingMoreItems)}
        >
          {showingMoreItems ? "VIEW LESS" : "VIEW MORE"}
        </AnchorTag>
      )}
      <ParaTag
        color={theme.greyText6}
        fontSize="xs"
        textAlign="center"
        margin="30px 0px 20px"
      >
        -OR-
      </ParaTag>
      <UploadTicketImage
        disabledText={selectedTicketCount === 2}
        onUploadClick={(response) => onUploadClick(response)}
        handlePreiewEyeClick={handlePreiewEyeClick}
        documentLink={documentLink}
        handleDisabledText={handleDisabledTextClick}
        ticketsData={ticketData}
        onEditClick={onEditClick}
        renderUploadImage={renderUploadImage}
      />

      <LandingButton
        customTitle="SUBMIT DOCUMENTS"
        handleClick={onSubmitClick}
        customWidth="100%"
        disableState={selectedTicketCount == 0}
      />
      {showToast && (
        <Toast
          planSelectedTxt={AddOrSelectDocData.toastMsg}
          toastIcon={Warning.src}
          onTimerEnd={() => setShowToast(false)}
        />
      )}
    </Modal>
  );
};

export default AddOrSelectTicket;
