import { format, sub, Duration, addDays } from "date-fns";

import { DATE_FORMATS } from "../constants";

export const convertDateToUnix = (date?: Date) => {
  if (date) {
    return Math.floor(new Date(date).getTime() / 1000);
  }
  return;
};

export const convertUnixToDate = (unix?: number | null) => {
  if (unix) {
    return new Date(unix * 1000);
  }
  return;
};

export const formatDate = (date: Date | string, dateFormat: DATE_FORMATS) => {
  if (date) {
    try {
      date = new Date(date);
      return format(date, dateFormat);
    } catch (error) {
      console.log("Error in formatting given date", `${date} | ${dateFormat}`);
    }
  }
  return "";
};

export const addDaysToDate = (date: Date, days = 1) => {
  try {
    return addDays(date, days);
  } catch (error) {
    console.log("Error in adding given days to date", `${date} | ${days}`);
  }
  return;
};

export const subtractDurationFromDate = (
  date: Date | string,
  duration: Duration
) => {
  if (date) {
    try {
      date = new Date(date);
      return sub(date, duration);
    } catch (error) {
      console.log(
        "Error in subtracting given duration from date",
        `${date} | ${duration}`
      );
    }
  }
  return new Date();
};

export const subtractYrsFromDate = (date: Date | string, years: number) => {
  if (date && years) {
    try {
      return subtractDurationFromDate(date, { years });
    } catch (error) {
      console.log(
        "Error in subtracting given years from date",
        `${date} | ${years}`
      );
    }
  }
  return new Date();
};

export const isValidDate = (dateString: string): boolean => {
  const dateRegex = /^(0?[1-9]|[1-2][0-9]|3[0-1])\/(0?[1-9]|1[0-2])\/\d{4}$/;

  if (!dateRegex.test(dateString)) {
    return false;
  }

  const [day, month, year] = dateString.split("/").map(Number);

  const isValidDay = day >= 1 && day <= 31;
  const isValidMonth = month >= 1 && month <= 12;
  const isValidYear = year >= 1000 && year <= 9999;

  return isValidDay && isValidMonth && isValidYear;
};
