import React from "react";
import MarkdownText from "../MarkdownText/MarkdownText";
import { TextNodeType } from "libs/ui-widgets/src/lib/Contexts/DeviceContext";
import FormattedText from "../FormattedText/FormattedText";

export interface RichTextProps {
  value?: string | TextNodeType;
}

const RichText = ({ value }: RichTextProps) => {
  if (typeof value === "string") {
    return <MarkdownText value={value} />;
  }
  return <FormattedText value={value} />;
};

export default RichText;
