import React from "react";

import { Modal } from "@tm/static";

import { CloseIcon } from "../../../icons/CloseIcon";

import { useBreakpoint } from "../../../../hooks/useBreakpoint";

import { InterBankingProps } from "./types";

const InterBanking: React.FC<InterBankingProps> = ({
  onModalClose,
  closeButton = <CloseIcon />,
  children = <></>,
  modalHeading,
}) => {
  const device = useBreakpoint();

  return (
    <Modal
      modalHeading={modalHeading}
      onModalClose={onModalClose}
      closeButton={closeButton}
      modalHeadingCtrMargin={
        device === "lg" ? "0px 0px 8px 0px" : "0px 0px 14px 0px"
      }
      modalWidth={610}
      modalType={device === "lg" ? "centered" : "bottomSheet"}
    >
      {children}
    </Modal>
  );
};

export default InterBanking;
