import * as React from "react";
interface iconProps {}
const NoInsIcon = (props: iconProps) => (
  <svg
    width={80}
    height={81}
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={40} cy={40.9628} r={40} fill="black" fillOpacity={0.08} />
    <path
      d="M40.0002 50.9953C33.7648 50.9953 28.7099 45.9404 28.7099 39.705H15.8066V59.0598H64.1937V39.705H51.2905C51.2905 45.9404 46.2356 50.9953 40.0002 50.9953Z"
      fill="#BCBCBC"
    />
    <path
      d="M57.5 23.4628H22.5L17 38.9628H28.5V39.4628C28.5 45.8141 33.6487 50.9628 40 50.9628C46.3513 50.9628 51.5 45.8141 51.5 39.4628V38.9628H63.5L57.5 23.4628Z"
      fill="#888888"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.9476 39.4177L58.496 22.4822C58.3767 22.1691 58.0766 21.9628 57.7419 21.9628H22.2581C21.9234 21.9628 21.6233 22.1691 21.504 22.4822L15.0524 39.4177C15.0228 39.5008 15.0071 39.5878 15.0055 39.6756C15.0055 39.6809 15.0041 39.6855 15.0028 39.6902C15.0014 39.6948 15 39.6994 15 39.7047V59.0596C15 59.5049 15.3611 59.866 15.8065 59.866H64.1936C64.6389 59.866 65 59.5049 65 59.0596V39.7047C65 39.6994 64.9986 39.6948 64.9972 39.6902C64.9959 39.6855 64.9945 39.6809 64.9945 39.6756C64.9929 39.5878 64.9772 39.5008 64.9476 39.4177ZM22.8137 23.5757H57.1863L63.0233 38.8983H51.2903C50.845 38.8983 50.4839 39.2594 50.4839 39.7047C50.4839 45.4948 45.7901 50.1886 40 50.1886C34.2099 50.1886 29.5161 45.4948 29.5161 39.7047C29.5161 39.2594 29.155 38.8983 28.7097 38.8983H16.9768L22.8137 23.5757ZM16.6129 40.5112V58.2531H63.3871V40.5112H52.07C51.6479 46.8667 46.3697 51.8058 40 51.8058C33.6303 51.8058 28.3521 46.8667 27.93 40.5112H16.6129Z"
      fill="#4A4A4A"
    />
    <path
      d="M20.6449 55.0265H19.8385V52.6071C19.8385 52.1618 19.4774 51.8007 19.032 51.8007C18.5867 51.8007 18.2256 52.1618 18.2256 52.6071V55.8329C18.2256 56.2783 18.5867 56.6394 19.032 56.6394H20.6449C21.0903 56.6394 21.4514 56.2783 21.4514 55.8329C21.4514 55.3876 21.0903 55.0265 20.6449 55.0265Z"
      fill="white"
    />
    <path
      d="M25.4838 55.0273H23.8709C23.4255 55.0273 23.0645 55.3883 23.0645 55.8337C23.0645 56.2791 23.4255 56.6402 23.8709 56.6402H25.4838C25.9292 56.6402 26.2903 56.2791 26.2903 55.8337C26.2903 55.3883 25.9292 55.0273 25.4838 55.0273Z"
      fill="white"
    />
  </svg>
);
export { NoInsIcon };
