import { formatDate } from ".";
import { CARD_TYPE, DATE_FORMATS } from "../constants";
import { getFutureDate } from "../static/utils";
import { CrossSellDataType } from "../store/forexListingReducer";

// Function to get cross sell component data
export function getCrossSellComponentData({
  crossSellProduct,
  crossSellAddress,
  crossSellCards,
  crossSellDeliveryDetails,
  crossSellPii,
  crossSellUpcomingTrips,
}: CrossSellDataType) {
  const { date, monthName, suffix, day } = getFutureDate(
    Number(crossSellDeliveryDetails?.eta_in_days)
  );
  let deliveryLabel = "";
  deliveryLabel += ` ${day}, ${date}${suffix} ${monthName}`;

  const deliveryDate = deliveryLabel;
  const tripDetailsDate = crossSellUpcomingTrips?.startDate
    ? formatDate(
        crossSellUpcomingTrips?.startDate,
        DATE_FORMATS["dd MMM, yyyy"]
      )
    : "";
  const tripDetail = [
    {
      id: "1",
      label: "Destination",
      info:
        crossSellUpcomingTrips?.tripDetails
          ?.map((e) => e?.countryName)
          .join(",") || "",
    },
    { id: "2", label: "Travel Start Date", info: tripDetailsDate },
    {
      id: "3",
      label: "Currency to Load",
      info: crossSellCards?.map((e) => e?.acqCurr).join(",") || "",
    },
  ];
  const btnTitle = `BUY ${
    crossSellProduct === CARD_TYPE.MULTI_CURRENCY_CARD ? "CARD" : "CURRENCY"
  }`;
  const isCardCrossSell = crossSellProduct === CARD_TYPE.MULTI_CURRENCY_CARD;
  const heading = `${
    crossSellProduct === CARD_TYPE.MULTI_CURRENCY_CARD
      ? "Forex Card"
      : "Foreign Currency Notes"
  }, Just One Step Away!`;
  const additionalInfo =
    crossSellProduct === CARD_TYPE.MULTI_CURRENCY_CARD
      ? `BookMyForex Card is the trusted choice of more than <b>1 Lakh Travellers</b>`
      : `90% Forex card users also <b>buy foreign currency notes</b> for their trip`;

  return {
    deliveryDate,
    tripDetail,
    btnTitle,
    isCardCrossSell,
    heading,
    additionalInfo,
  };
}
