import React, { useEffect, useState } from "react";
import {
  LandingTestimonialsStyle,
  SlideDots,
  SlideShowContainer,
  SlideShowSLider,
  LandingTestTitle,
  PersonName,
  Date,
  LandingTest,
  LandingTestWrap,
  LandingTestImg,
  StarRatingWrap,
  StarRating,
  LandingTestCntnt,
  LandingTextCrd,
  TestimonialTag,
  TestimonialContent,
  SlideShowDotItem,
} from "./LandingTestimonials.style";
import { StarEmpty, StarFull } from "../../icons";
import { InnerContentWrap } from "../../CommonStyle/CommonStyles.style";
import { FlexComp, ImageComponent, ParaTag, SpanTag } from "@tm/static";
import { theme } from "@tm/utility/global";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import Heart from "../../icons/heart.svg";
import { useBreakpointV2 } from "@tm/ui-widgets";

interface testimonialType {
  customTitle?: TextNodeType | string;
  test: {
    personImg?: {
      src: string;
    };
    personCard?: TextNodeType | string;
    personCrdClass?: string;
    personName?: TextNodeType | string;
    date?: TextNodeType | string;
    personRating?: number;
    testInfo?: TextNodeType | string;
    testimonialTag?: string[];
  }[];
}

const LandingTestimonials = ({ customTitle, test }: testimonialType) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(test?.length);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);
  const device = useBreakpointV2();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setLength(test?.length);
  }, [test]);

  const next = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
    if (isHovered) {
      resetTimeout();
    }
  };

  const timeoutRef: React.MutableRefObject<number | null> = React.useRef<
    number | null
  >(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = window.setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex === test?.length - 1 ? 0 : prevIndex + 1
        ),
      2500
    );

    return () => {
      resetTimeout();
      timeoutRef.current = window.setTimeout(
        () =>
          setCurrentIndex((prevIndex) =>
            prevIndex === test?.length - 1 ? 0 : prevIndex + 1
          ),
        2500
      );
    };
  }, [currentIndex]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    resetTimeout();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    resetTimeout();
    timeoutRef.current = window.setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex === test?.length - 1 ? 0 : prevIndex + 1
        ),
      2500
    );
  };

  return (
    <LandingTestimonialsStyle>
      <InnerContentWrap>
        <LandingTestTitle>
          {customTitle && <FormattedText value={customTitle} />}{" "}
          <ImageComponent
            width={device == "lg" ? "66px" : "32px"}
            height={device == "lg" ? "66px" : "32px"}
            src="https://tripmoneycmsimgak.mmtcdn.com/img/heart_9289dd4a1d.svg"
          />
        </LandingTestTitle>
        <SlideShowContainer
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <SlideShowSLider
            style={{
              transform: `translateX(-${
                currentIndex * (device === "lg" ? 50 : 100)
              }%)`,
            }}
          >
            {test &&
              test.map((item, index: number) => {
                return (
                  <LandingTest key={index}>
                    <LandingTestWrap>
                      {device === "lg" && (
                        <LandingTestImg>
                          {item.personImg && (
                            <ImageComponent
                              width="150"
                              height="250"
                              alt="hello"
                              src={item.personImg.src}
                            />
                          )}
                          <LandingTextCrd cardType={item.personCrdClass}>
                            {item.personCard && (
                              <FormattedText value={item.personCard} />
                            )}
                          </LandingTextCrd>
                        </LandingTestImg>
                      )}
                      <LandingTestCntnt>
                        <TestimonialContent>
                          {device !== "lg" && item.personImg && (
                            <img
                              width="84"
                              height="87"
                              alt=""
                              src={item.personImg.src}
                            />
                          )}
                          <div>
                            {/* <Date>
                                                            {item.date && (
                                                                <FormattedText
                                                                    value={
                                                                        item.date
                                                                    }
                                                                />
                                                            )}
                                                        </Date> */}
                            <PersonName>
                              {item.personName && (
                                <FormattedText value={item.personName} />
                              )}
                            </PersonName>
                            <StarRatingWrap>
                              <StarRating>
                                {Array.from(
                                  {
                                    length: 5,
                                  },
                                  (_, index) => (
                                    <StarEmpty
                                      key={index}
                                      height={device !== "lg" ? 16 : 25}
                                      width={device !== "lg" ? 16 : 26}
                                    />
                                  )
                                )}
                              </StarRating>
                              <StarRating starRateFull={true}>
                                {Array.from(
                                  {
                                    length: Number(item.personRating),
                                  },
                                  (_, index) => (
                                    <StarFull
                                      key={index}
                                      height={device !== "lg" ? 16 : 25}
                                      width={device !== "lg" ? 16 : 26}
                                    />
                                  )
                                )}
                              </StarRating>
                            </StarRatingWrap>
                            {device !== "lg" && (
                              <LandingTextCrd>
                                {item.personCard && (
                                  <FormattedText value={item.personCard} />
                                )}
                              </LandingTextCrd>
                            )}
                            {/* <FlexComp
                                                            gap={
                                                                device === 'lg'
                                                                    ? '8px'
                                                                    : 'unset'
                                                            }
                                                            margin={
                                                                device === 'lg'
                                                                    ? '0 0 16px'
                                                                    : 'unset'
                                                            }
                                                        >
                                                            {item.testimonialTag &&
                                                                device ===
                                                                    'lg' &&
                                                                item.testimonialTag.map(
                                                                    (
                                                                        item2,
                                                                        index2: number
                                                                    ) => {
                                                                        return (
                                                                            <TestimonialTag
                                                                                key={
                                                                                    index2
                                                                                }
                                                                            >
                                                                                <SpanTag fontSize="md">
                                                                                    {item2 && (
                                                                                        <FormattedText
                                                                                            value={
                                                                                                item2
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </SpanTag>
                                                                            </TestimonialTag>
                                                                        );
                                                                    }
                                                                )}
                                                        </FlexComp> */}
                          </div>
                        </TestimonialContent>
                        <ParaTag fontSize="md" color={theme.greyText}>
                          {item.testInfo && (
                            <FormattedText value={item.testInfo} />
                          )}
                        </ParaTag>
                      </LandingTestCntnt>
                    </LandingTestWrap>
                  </LandingTest>
                );
              })}
          </SlideShowSLider>

          <SlideDots>
            {test?.map((_, idx: number) => (
              <SlideShowDotItem
                active={currentIndex === idx}
                key={idx}
                onClick={() => {
                  setCurrentIndex(idx);
                }}
              ></SlideShowDotItem>
            ))}
          </SlideDots>
        </SlideShowContainer>
      </InnerContentWrap>
    </LandingTestimonialsStyle>
  );
};

export default LandingTestimonials;
