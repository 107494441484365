import * as React from "react";
interface blueArrProp {}
const BlueOneWayArr = (props: blueArrProp) => (
  <svg
    width={19}
    height={9}
    viewBox="0 0 19 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.167627 4.08643L3.46856 0.686272C3.69224 0.491411 4.12115 0.410971 4.37116 0.636272C4.61741 0.858172 4.61008 1.28853 4.36476 1.51131L2.04379 3.89893H18.3811C18.7229 3.89893 19 4.16757 19 4.49895C19 4.83033 18.7229 5.09897 18.3811 5.09897H2.04379L4.36476 7.48659C4.57596 7.69169 4.61499 8.13719 4.37116 8.36163C4.12738 8.58605 3.68306 8.51435 3.46856 8.31161L0.167627 4.91147C0.0546741 4.79511 -0.000370026 4.65859 1.90735e-06 4.49895C0.00577927 4.35595 0.0664959 4.19067 0.167627 4.08643Z"
      fill="#008CFF"
    />
  </svg>
);
export { BlueOneWayArr };
