import styled from "styled-components";
import { deviceType } from "@tm/static/utils";

type dnldProp = {
  firstChild?: boolean;
};

export const DnldPolicyComponentStyle = styled.div`
  border-radius: 6px;
  background: #fff;
  padding: 20px;
  @media ${deviceType.mobile} {
    padding: 16px;
  }
`;

export const TravNumber = styled.div`
  border-radius: 100%;
  background: #f6f6f6;
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PolicyItem = styled.div<dnldProp>`
  display: flex;
  gap: 18px;
  align-items: baseline;
  padding: 12px 16px;
  border: 1px solid #e2e2e2;
  border-top: none;
  ${(p) => p.firstChild && `border-top: 1px solid #e2e2e2;`}
`;
