import { theme } from "@tm/utility/global";
import React from "react";
import { TravAssistantStyle } from "./TravAssistant.style";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import {
  FlexComp,
  ImageComponent,
  ParaTag,
  SpanTag,
  AnchorTag,
} from "@tm/static";
import CovidIcon from "../../icons/CovidIcon.svg";
import BluePhone from "../../icons/bluePhone.svg";

interface TravAssistantProps {
  cardTitle?: TextNodeType;
  cardInfo?: TextNodeType;
  helpLineNo?: string;
  cardIcon?: string;
}

const TravAssistant = ({
  cardTitle,
  cardInfo,
  helpLineNo,
  cardIcon,
}: TravAssistantProps) => {
  return (
    <TravAssistantStyle>
      <FlexComp
        gap="8px"
        borderBottom="1px solid #e2e2e2"
        padding="0 0 8px"
        margin="0 0 10px"
      >
        <ImageComponent width="24px" height="24px" src={cardIcon} />
        <div>
          {cardTitle && (
            <ParaTag fontSize="md" fontWeight="bold" color={theme.greenText}>
              <FormattedText value={cardTitle} />
            </ParaTag>
          )}
          {cardInfo && (
            <SpanTag>
              <FormattedText value={cardInfo} />
            </SpanTag>
          )}
        </div>
      </FlexComp>
      <FlexComp alignItems="center" gap="8px">
        <ImageComponent
          width="24px"
          height="24px"
          src="https://tripmoneycmsimgak.mmtcdn.com/img/blue_Phone_8b0592cec7.svg"
        />
        {helpLineNo && (
          <AnchorTag fontWeight="bold">
            <FormattedText value={helpLineNo} />
          </AnchorTag>
        )}
      </FlexComp>
    </TravAssistantStyle>
  );
};

export default TravAssistant;
