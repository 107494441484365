import { MiniDashboardPersuasionProps } from "libs/ui-widgets/src/lib/types";
import { landingCardShowcaseDataProps } from "../../../mockData";
import LandingButton from "../../ui-atoms/LandingButton/LandingButton";
import AnchorTag from "../../ui-atoms/TextStyles/AnchorTag";
import { PersuasionHandler } from "../Persuasion";

export default function MiniDashboardPersuasion(props: {
  cardData: landingCardShowcaseDataProps;
  persuasionProps: MiniDashboardPersuasionProps & { btnOptions: any };
  handlers: PersuasionHandler;
}) {
  return props.persuasionProps.btnOptions ? (
    <LandingButton
      {...props.persuasionProps.btnOptions}
      onClick={
        props.handlers.loadCurrenyBtnHandler
          ? () => props.handlers.loadCurrenyBtnHandler(props.cardData)
          : () => {
              console.log("landing button: load currency");
            }
      }
      fontSize="12px"
      margin="25px 0px 0px"
      customHeight="32px"
      customWidth="100%"
    />
  ) : (
    <AnchorTag
      width="fit-content"
      fontSize="xs"
      fontWeight="extraBold"
      // margin="12px 0px 0px"
      handleClick={
        props.handlers.loadCurrenyBtnHandler
          ? () => props.handlers.loadCurrenyBtnHandler(props.cardData)
          : () => {
              console.log("load currency");
            }
      }
    >
      {props.persuasionProps?.btnOptions?.customTitle}
    </AnchorTag>
  );
}
