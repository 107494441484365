import React from "react";
import { SpanTag, AnchorTag, FlexComp, ImageTag } from "@tm/static";
import { StatusTrackStyle } from "./StatusTrack.style";
import { theme } from "libs/ui-widgets/src/lib/static/css/Global";
import { useBreakpoint } from "@tm/ui/hooks";

interface StatusInterFace {
  statusIcon?: any;
  statusTitle?: string;
  statusInfo?: string;
  statusCta?: string;
  handleClick?: () => void;
  crdStatus?: "SUCCESS" | "CANCELLED" | "INPROGRESS" | "UNAVAILABLE";
}

const StatusTrack: React.FC<StatusInterFace> = ({
  statusIcon,
  statusTitle,
  statusInfo,
  statusCta,
  handleClick,
  crdStatus,
}) => {
  const device = useBreakpoint();
  return (
    <StatusTrackStyle crdStatus={crdStatus}>
      <FlexComp alignItems="center" gap="8px">
        {statusIcon && <ImageTag src={statusIcon} width="24px" height="24px" />}
        <FlexComp alignItems="flex-start" gap="0" flexDirection="column">
          {statusTitle && (
            <SpanTag
              color={
                crdStatus == "SUCCESS"
                  ? theme.darkGreen
                  : crdStatus == "CANCELLED"
                  ? theme.redText
                  : crdStatus === "UNAVAILABLE"
                  ? theme.brownText
                  : theme.blackText
              }
              fontSize={device == "lg" ? "md" : "xs"}
              fontWeight="bold"
            >
              {statusTitle}
            </SpanTag>
          )}
          {statusInfo && (
            <SpanTag
              color={
                crdStatus == "SUCCESS"
                  ? theme.greyText
                  : crdStatus == "CANCELLED"
                  ? theme.redText
                  : crdStatus === "UNAVAILABLE"
                  ? theme.brownText
                  : theme.blackText
              }
              fontSize={device == "lg" ? "sm" : "xs"}
            >
              {statusInfo}
            </SpanTag>
          )}
        </FlexComp>
      </FlexComp>
      {statusCta && (
        <AnchorTag fontSize="xs" fontWeight="bold" handleClick={handleClick}>
          {statusCta}
        </AnchorTag>
      )}
    </StatusTrackStyle>
  );
};

export default StatusTrack;
