import React, { createContext, useContext } from "react";
import { PARTNER, PartnerType } from "@tm/insurance/constants";

const PartnerContext = createContext<PartnerType | null>(null);

export const usePartnerContext = () => {
  const context = useContext(PartnerContext);
  if (context === null) {
    console.error(
      "usePartnerContext must be used within a PartnerContextProvider"
    );
  }
  return context;
};

export const PartnerContextProvider: React.FC<{
  hostname?: string;
  children: React.ReactNode;
  partnerType?: any;
}> = ({ hostname, children, partnerType }) => {
  React.useEffect(() => {
    const element = document.getElementById("pageId") as HTMLElement;
    element.classList.remove("mmtTheme", "giTheme");
    if (partnerType === PARTNER.MMT) {
      element.classList.add("mmtTheme");
    } else if (partnerType === PARTNER.GI) {
      element.classList.add("giTheme");
    }
  }, [partnerType]);

  return (
    <PartnerContext.Provider value={partnerType}>
      {children}
    </PartnerContext.Provider>
  );
};
