import { MakeMyTrip } from "../constants";
import { getAuth } from "../api/auth";
import {
  loginUser,
  reloginUser,
  reloginEmailUser,
  getSLToken,
  saveTMToken,
} from "./bridgeMethods";
import { getPartner } from "../common";

declare global {
  interface Window {
    onLoginSuccess: (auth?: string) => void;
    onLoginCancelled: () => void;
    logoutUser: () => void;
    shortLivToken: (token?: string) => void;
  }
}

export async function fetchTmAuthFromSLT(
  authData: any,
  appSocialLoginResponse: any
) {
  const response = await getAuth(authData);
  if (response?.message === "SUCCESS") {
    const catchTmAuth = response?.data?.profileLoginResponse?.[0]?.authToken;
    saveTMToken(catchTmAuth);
    appSocialLoginResponse(true);
  } else if (response.code === 4401) {
    appSocialLoginResponse(response.code);
  } else {
    appSocialLoginResponse(response.code);
  }
}

export function loginWithMobile({ props, isUserLogged }: any) {
  const partner = getPartner();

  async function generateTmAuth(token?: string) {
    const authData = JSON.stringify({
      accType: partner === MakeMyTrip ? "MMT" : "GI",
      authToken: token,
    });
    await fetchTmAuthFromSLT(authData, isUserLogged);
  }

  window.onLoginSuccess = (auth?: string) => {
    getSLToken(partner);
  };

  window.onLoginCancelled = () => {
    isUserLogged(false);
  };

  window.shortLivToken = (token?: string) => {
    generateTmAuth(token);
  };

  loginUser(props?.loginOptions);
}

export function editNumberReLogin({ props, isUserLogged }: any) {
  const partner = getPartner();

  async function generateTmAuth(token?: string) {
    const authData = JSON.stringify({
      accType: partner === MakeMyTrip ? "MMT" : "GI",
      authToken: token,
    });
    await fetchTmAuthFromSLT(authData, isUserLogged);
  }

  window.onLoginSuccess = (auth?: string) => {
    getSLToken(partner);
  };

  window.onLoginCancelled = () => {
    isUserLogged(false);
  };

  window.shortLivToken = (token?: string) => {
    generateTmAuth(token);
  };

  reloginUser(props?.loginOptions);
}

export const generateNewAuth = () => {
  const partner = getPartner();
  const promise = new Promise((resolve, reject) => {
    const isNewAuthGenerated = (response: boolean | number) => {
      if (response) {
        resolve(true);
      } else {
        reject(response);
      }
    };

    async function generateTmAuth(token?: string) {
      const authData = JSON.stringify({
        accType: partner === MakeMyTrip ? "MMT" : "GI",
        authToken: token,
      });
      await fetchTmAuthFromSLT(authData, isNewAuthGenerated);
    }

    window.shortLivToken = async (token?: string) => {
      await generateTmAuth(token);
    };
    getSLToken(partner);
  });

  return promise;
};
