import { useDevice } from "../../../hooks/useDevice";
import { theme } from "../../css/Global";
import { ArrowIcon } from "../../icons/ArrowIcon";
import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import { IconTextTagStyle } from "./IconTextTag.style";

interface IconTextTagProps {
  label: string;
  subLabel?: string;
  icon?: string;
  margin?: string;
  onClick?: () => void;
}

const IconTextTag = ({
  label,
  subLabel,
  icon,
  margin,
  onClick,
}: IconTextTagProps) => {
  const device = useDevice();

  return device ? (
    <IconTextTagStyle onClick={onClick} margin={margin}>
      <FlexComp
        gap="4px"
        padding="0px"
        isCursorPointer={true}
        flexDirection={device === "lg" ? "row" : "column"}
      >
        <ParaTag fontSize="sm" fontWeight="bold" color={theme.blackText}>
          {label}
        </ParaTag>
        {subLabel && (
          <ParaTag fontSize="sm" color={theme.greyText}>
            {subLabel}
          </ParaTag>
        )}
      </FlexComp>
      {icon ? <ImageTag src={icon} showCursorPointer /> : <ArrowIcon />}
    </IconTextTagStyle>
  ) : (
    <></>
  );
};

export default IconTextTag;
