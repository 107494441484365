import React from "react";
import { ClaimYourInsuranceStyle } from "./ClaimYourInsurance.style";
import { H2Tag, FlexComp, H5Tag } from "@tm/static";
import { theme } from "@tm/utility/global";
import ClaimStructure from "./ClaimStructure";
import { InnerContentWrap } from "../../CommonStyle/CommonStyles.style";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { usePartnerContext } from "@tm/static/partnerContext";

interface ClaimYourInsuranceProps {
  data?: {
    claimInfo: TextNodeType | string;
    claimIndex?: string;
  }[];
  title?: TextNodeType;
  info?: TextNodeType;
}

const ClaimYourInsurance = ({ data, title, info }: ClaimYourInsuranceProps) => {
  const device = useBreakpointV2();
  const context = usePartnerContext();
  return (
    <ClaimYourInsuranceStyle>
      <InnerContentWrap>
        {device == "lg" ? (
          <React.Fragment>
            <H2Tag>{title && <FormattedText value={title} />}</H2Tag>
            <H2Tag color={theme.greenText} margin="0 0 86px">
              {info && <FormattedText value={info} />}
            </H2Tag>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <H5Tag>{title && <FormattedText value={title} />}</H5Tag>
            <H5Tag color={theme.greenText5} margin="0 0 20px">
              {info && <FormattedText value={info} />}
            </H5Tag>
          </React.Fragment>
        )}
        <FlexComp gap={device == "lg" ? "100px" : "48px"}>
          {data &&
            data.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <ClaimStructure
                    key={index}
                    claimInfo={item.claimInfo}
                    claimIndex={item.claimIndex}
                    showSpiral={index == data.length - 1}
                  />
                </React.Fragment>
              );
            })}
        </FlexComp>
      </InnerContentWrap>
    </ClaimYourInsuranceStyle>
  );
};

export default ClaimYourInsurance;
