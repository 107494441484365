import styled from "styled-components";

type DisplayInfoIconTagStyleProps = {
  margin?: string | number;
};

export const DisplayInfoIconTagStyle = styled.div<DisplayInfoIconTagStyleProps>`
  display: flex;
  flex: 1;
  margin: ${(props) => props.margin};
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(93.36deg, #f2eae6 -17.08%, #ffffff 118.39%);
  border-radius: 4px;
  padding: 12px;
`;
