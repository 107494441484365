import React from "react";
import {
  ImageComponent,
  SpanTag,
  FlexComp,
  AnchorTag,
  ParaTag,
  FormattedText,
  MoreBenefits,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { navigationActions } from "@tm/insurance/navigation";
import { getAnalytics } from "@tm/insurance/analytics";
import { IBenefitsGroup, IComboProductDetails } from "@tm/insurance/api";
import { ListingTravelDetailsStyle } from "./ListingTravelDetails.style";
import { TextNodeType } from "../FormattedText/FormattedText";

interface ListingTravelDetailsProps {
  editIcon?: string;
  data?: {
    planType?: TextNodeType;
    planName?: TextNodeType;
    sumInsured?: TextNodeType;
    amountToPay?: TextNodeType;
    editEnabled?: boolean;
    priceDetails?: {
      title: TextNodeType;
      originalPrice?: TextNodeType;
      discountedPrice?: TextNodeType;
    };
  };
  tnc?: {
    title?: TextNodeType;
    url?: string;
  };
  vendorDisplayIcon?: string;
  previewBenefits?: {
    benefits: IBenefitsGroup[];
    ctaText?: TextNodeType | string;
  };
  handleTnc?: () => void;
  handleAmountBreakup?: () => void;
  handleViewBenefits?: () => void;
  addOnProductDetails?: IComboProductDetails;
}

const analytics = getAnalytics();

const ListingTravelDetails = ({
  data,
  tnc,
  handleTnc,
  handleAmountBreakup,
  handleViewBenefits,
  previewBenefits,
  vendorDisplayIcon,
  addOnProductDetails,
}: ListingTravelDetailsProps) => {
  const device = useBreakpointV2();

  const {
    planType,
    planName,
    sumInsured,
    amountToPay,
    editEnabled,
    priceDetails,
  } = data || {};

  const handleEditClick = () => {
    analytics?.trackTravDetailsEvents({
      actionType: "edit_product_clicked",
    });

    const referrer = document.referrer;
    const hostname = referrer ? new URL(referrer).hostname : "";

    if (hostname === "www.makemytrip.com" || hostname === "www.goibibo.com") {
      navigationActions.goBack();
    }
    const queryParams = new URLSearchParams(window.location.search);
    const params = {
      countries: queryParams.get("countries"),
      startDate: queryParams.get("startDate"),
      endDate: queryParams.get("endDate"),
      travellers: queryParams.get("travellers"),
    };
    navigationActions.navigateToListing(params);
  };

  return (
    <ListingTravelDetailsStyle>
      <FlexComp margin="0 0 4px" justifyContent="space-between">
        <div>
          {planType && (
            <ParaTag fontSize="xs" fontWeight="black" margin="0 0 4px">
              <FormattedText value={planType} />
            </ParaTag>
          )}
          {addOnProductDetails?.productTitle ? (
            <ParaTag color={theme.blackText} fontWeight="black" fontSize="md">
              <FormattedText value={addOnProductDetails?.productTitle} />
            </ParaTag>
          ) : (
            planName && (
              <ParaTag color={theme.blackText} fontWeight="black" fontSize="md">
                <FormattedText value={planName} />
              </ParaTag>
            )
          )}
        </div>
        {previewBenefits ? (
          <ImageComponent width="38px" height="38px" src={vendorDisplayIcon} />
        ) : (
          editEnabled && (
            <AnchorTag handleClick={handleEditClick}>
              <ImageComponent
                width="25px"
                height="25px"
                src="https://tripmoneycmsimgak.mmtcdn.com/img/edit_Icon_be6c24806a.svg"
              />
            </AnchorTag>
          )
        )}
      </FlexComp>
      <FlexComp
        borderBottom={
          previewBenefits ? "" : "1px solid rgba(242, 242, 242, 0.2)"
        }
        alignItems={device == "lg" ? "center" : "flex-start"}
        gap="6px"
        margin="0 0 8px"
        padding="0 0 8px"
        flexDirection={device == "lg" ? "row" : "column"}
      >
        {sumInsured && (
          <div>
            <SpanTag
              color={theme.blackText}
              fontSize={device == "lg" ? "sm" : "xs"}
            >
              <FormattedText value={sumInsured} />
            </SpanTag>
          </div>
        )}
      </FlexComp>

      {previewBenefits && (
        <FlexComp
          borderBottom="1px solid rgba(242, 242, 242, 0.2)"
          flexDirection="column"
          margin="-8px 0 4px"
          padding="0 0 4px"
        >
          {previewBenefits?.benefits?.[0].features?.map?.((item) => {
            return (
              <FlexComp justifyContent="space-between">
                <FlexComp justifyContent="space-between">
                  <ImageComponent
                    width="16px"
                    height="16px"
                    src={item?.icon}
                    margin="0 20px 4px 0"
                  />
                  <SpanTag color={theme.blackText} fontSize={"xs"}>
                    <FormattedText value={item.name} />
                  </SpanTag>
                </FlexComp>
                <SpanTag
                  color={theme.blackText}
                  fontSize={"xs"}
                  fontWeight="bold"
                >
                  <FormattedText value={item.cover} />
                </SpanTag>
              </FlexComp>
            );
          })}
          <AnchorTag targetProp="_blank" handleClick={handleViewBenefits}>
            <FormattedText
              value={previewBenefits?.ctaText}
              baseStyle={{ fontWeight: "700", fontSize: "12px" }}
            />
          </AnchorTag>
        </FlexComp>
      )}

      <FlexComp alignItems="center" gap="4px" justifyContent="space-between">
        {priceDetails ? (
          <FlexComp alignItems="center" gap="4px">
            <SpanTag
              color={theme.blackText}
              fontSize={device == "lg" ? "md" : "sm"}
            >
              {priceDetails?.title && (
                <FormattedText value={priceDetails?.title} />
              )}
              {priceDetails?.originalPrice && (
                <FormattedText
                  value={
                    addOnProductDetails?.originalAmountToPay
                      ? addOnProductDetails?.originalAmountToPay
                      : priceDetails?.originalPrice
                  }
                  baseStyle={{
                    textDecoration: "line-through",
                    textDecorationThickness: 0.8,
                  }}
                />
              )}
              {priceDetails?.discountedPrice && (
                <FormattedText
                  value={
                    addOnProductDetails?.totalAmountToPay
                      ? addOnProductDetails?.totalAmountToPay
                      : priceDetails?.discountedPrice
                  }
                  baseStyle={{ fontWeight: 900 }}
                />
              )}
            </SpanTag>
          </FlexComp>
        ) : (
          amountToPay && (
            <FlexComp alignItems="center" gap="4px">
              <SpanTag
                color={theme.blackText}
                fontSize={device == "lg" ? "md" : "sm"}
              >
                <FormattedText value={amountToPay} />
              </SpanTag>
            </FlexComp>
          )
        )}
        {/* <ImageComponent width="20px"  height="20px" src="https://tripmoneycmsimgak.mmtcdn.com/img/Info_a5b6c601f7.svg"/> */}

        {/* NOT IN CURRENT SCOPE : Amount Breakup*/}
        {/* <FlexComp handleClick={handleAmountBreakup}>
                    <ImageComponent
                        width="18px"
                        height="18px"
                        src={InfoIcon.src}
                    />
                </FlexComp> */}
        {tnc?.title && (
          <AnchorTag
            targetProp="_blank"
            handleClick={handleTnc}
            hrefProp={
              addOnProductDetails?.tncLink
                ? addOnProductDetails?.tncLink
                : tnc.url
            }
            fontSize="xs"
            fontWeight="black"
          >
            <FormattedText value={tnc.title} />
          </AnchorTag>
        )}
      </FlexComp>
    </ListingTravelDetailsStyle>
  );
};

export default ListingTravelDetails;
