

// Holidays array
const holidays = [
    { date: "2023-03-08", name: "Holi" },
    { date: "2023-03-30", name: "Ram Navami" },
    { date: "2023-04-04", name: "Mahavir Jayanti" },
    { date: "2023-04-07", name: "Good Friday" },
    { date: "2023-04-22", name: "Eid-ul-fitr" },
    { date: "2023-05-01", name: "Labor Day" },
    { date: "2023-05-05", name: "Budhha Purnima" },
    { date: "2023-07-29", name: "Muharram" },
    { date: "2023-08-15", name: "Independence Day" },
    { date: "2023-08-29", name: "Onam" },
    { date: "2023-08-30", name: "Raksha Bandhan" },
    { date: "2023-09-07", name: "Ganesh Chaturthi" },
    { date: "2023-10-02", name: "Gandhi Jayanti" },
    { date: "2023-10-24", name: "Dussehra" },
    { date: "2023-10-28", name: "Valmiki Jayanti" },
    { date: "2023-11-12", name: "Diwali" },
    { date: "2023-11-27", name: "Guru Nanak Jayanti" },
    { date: "2023-12-25", name: "Christmas" },
    { date: "2024-01-01", name: "New Year's Day" },
];

// Get all days in a month
export function getDaysInMonth(year, month, showHolidays) {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const days = [];
    let holidaysCount = 0;

    for (let i = 1; i <= daysInMonth; i++) {
        if (showHolidays) {
            const dateTimestamp = Date.UTC(year, month, i);
            let isHoliday = false;
            let holidayName = "";

            holidays.forEach(holiday => {
                const holidayDate = new Date(holiday.date);
                const holidayTimestamp = Date.UTC(holidayDate.getFullYear(), holidayDate.getMonth(), holidayDate.getDate());

                if (dateTimestamp === holidayTimestamp) {
                    isHoliday = true;
                    holidayName = holiday.name;
                    holidaysCount++;
                }
            });

            days.push({ date: new Date(year, month, i), isHoliday: isHoliday, holidayName: holidayName });
        } else {
            days.push({ date: new Date(year, month, i) });
        }
    }

    return { days, holidaysCount };
}

// Get all calendar data
export function getCalendarData(startDate, endDate, showHolidays) {
    let currentDate = startDate ? new Date(startDate) : new Date();
    const lastDate = endDate ? new Date(endDate) : new Date(currentDate.getTime() + (365 * 24 * 60 * 60 * 1000));

    let monthData = [];
    while (currentDate <= lastDate) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const firstDay = new Date(year, month, 1).getDay();
        const lastDay = new Date(year, month + 1, 0).getDate();
        const daysInMonth = getDaysInMonth(year, month, showHolidays);

        monthData.push({
            month: month,
            year: year,
            daysInMonth: daysInMonth,
            firstDayOfWeek: firstDay
        });

        currentDate.setMonth(month + 1);
        currentDate.setDate(1);
    }
    
    return monthData;
}

// Generate empty cell before start date
export function emptyCellBefore(data, daysInMonth) {
    const daysBefore = new Array(data)
        .fill(null)
        .map((_, i) => <div key={`empty-${i}`} className="empty" />);

    return daysBefore;
}

// Generate empty cell after end date
export function emptyCellAfter(data, daysInMonth) {
    const emptyDaysAfter = new Array(7 - ((emptyCellBefore().length + daysInMonth.days.length) % 7))
        .fill(null)
        .map((_, i) => <div key={`empty-after-${i}`} className="empty" />);

    return emptyDaysAfter;
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0, so we add 1 to get the correct month.
    const year = date.getFullYear() % 100; // Get the last two digits of the year.
  
    return `${day}/${month}/${year}`;
  };