import { useState, useEffect, useRef } from "react";

const useCountTimer = (count = 0, interval = 15000) => {
  const [startCount, setStartCount] = useState(count);
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setStartCount((startCount) => startCount + 1);
    }, interval);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return {
    count: startCount,
  };
};

export default useCountTimer;
