import React from "react";
import { FormattedText, ImageComponent, SpanTag } from "@tm/static";
import { theme } from "@tm/utility/global";

import { BtnProp } from "./ButtonType";
import { LandingButtonWhiteStyle } from "./LandingButton.style";

const LandingButtonWhite = ({
  customTitle,
  margin,
  customWidth,
  customHeight,
  disableState,
  onClick,
  flexShrink,
  flex,
  btnLeftIcon,
  btnRightIcon,
}: BtnProp) => {
  return (
    <LandingButtonWhiteStyle
      onClick={onClick}
      customWidth={customWidth}
      customHeight={customHeight}
      margin={margin}
      flexShrink={flexShrink}
      flex={flex}
      disableState={disableState}
    >
      {btnLeftIcon && (
        <ImageComponent width="24px" height="24px" src={btnLeftIcon} />
      )}
      {customTitle && (
        <SpanTag color={theme.blueText} fontSize="md" fontWeight="bold">
          <FormattedText value={customTitle} />
        </SpanTag>
      )}
      {btnRightIcon && (
        <ImageComponent
          width="16px"
          height="16px"
          margin="0 0 0 auto"
          src={btnRightIcon}
        />
      )}
    </LandingButtonWhiteStyle>
  );
};

export default LandingButtonWhite;
