interface LocationIconProps {
  color?: string;
  props?: any;
}

export const LocationIcon = ({
  color = "#2B825E",
  props,
}: LocationIconProps) => (
  <svg
    width={9}
    height={10}
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.18195 3.91311C8.18195 6.07426 5.68191 8.4784 4.09098 10.0002C2.27276 8.261 0 6.07426 0 3.91311C0 1.75196 1.83159 0 4.09098 0C6.35036 0 8.18195 1.75196 8.18195 3.91311Z"
      fill={color}
    />
    <ellipse cx={4.09106} cy={3.53368} rx={2} ry={1.95556} fill="white" />
  </svg>
);
