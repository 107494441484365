export const GET_FOREX_LISTING_DATA = "GET_FOREX_LISTING_DATA";

export const FETCH_FOREX_CURRENCY_RATE = "FETCH_FOREX_CURRENCY_RATE";

export const IS_LOADING_DELIVERY_DETAILS = "IS_LOADING_DELIVERY_DETAILS";
export const FETCH_DELIVERY_DETAILS = "FETCH_DELIVERY_DETAILS";
export const IS_ERROR_FETCHING_DELIVERY_DETAILS =
  "IS_ERROR_FETCHING_DELIVERY_DETAILS";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";

export const LOADING_LISTING_PAGE_LOCATION_CHANGE =
  "LOADING_LISTING_PAGE_LOCATION_CHANGE";
export const FETCH_LISTING_PAGE_LOCATION_CHANGE =
  "FETCH_LISTING_PAGE_LOCATION_CHANGE";
export const ERROR_LISTING_PAGE_LOCATION_CHANGE =
  "ERROR_LISTING_PAGE_LOCATION_CHANGE";

export const LOADING_CARD_CTA_SIGNAL_STATE = "LOADING_CARD_CTA_SIGNAL_STATE";
export const FETCH_CARD_CTA_SIGNAL_STATE = "FETCH_CARD_CTA_SIGNAL_STATE";
export const ERROR_CARD_CTA_SIGNAL_STATE = "ERROR_CARD_CTA_SIGNAL_STATE";
export const PERSONA_AND_VERSION_FETCHED_ON_LOAD =
  "PERSONA_AND_VERSION_FETCHED_ON_LOAD";

export const UPDATE_LOGIN_EMAIL_MODAL_STATE = "UPDATE_LOGIN_EMAIL_MODAL_STATE";

export const FETCH_CROSS_SELL_DATA = "FETCH_CROSS_SELL_DATA";
