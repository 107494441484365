import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
`;

export const ShimmerComp: any = styled.div`
  animation: ${shimmer} 1s linear infinite;
  background: ${(p: any) =>
    p.background
      ? p.background
      : "linear-gradient(to right, #2F2F2F 8%, #393939 18%, #2F2F2F 33%)"};
  background-size: 800px 104px;
  position: relative;
  border-radius: 4px;
  width: ${(p: any) => (p.width ? p.width : "100%")};
  height: ${(p: any) => (p.height ? p.height : "14px")};
  margin: ${(p: any) => (p.margin ? p.margin : "")};
  position: relative;
  flex-shrink:0;
  padding: ${(p: any) => (p.padding ? p.padding : "")};
  display: flex;
  ${(p: any) => p.flex && `flex: ${p.flex}`};
  align-items:flex-end;
}`;
