import React, { useEffect, useState } from "react";
import { ToastStyle, CloseIconWrap, ToastIcon } from "./Toast.style";
import {
  SpanTag,
  AnchorTag,
  FormattedText,
  ImageComponent,
  LandingButtonWhite,
  FlexComp,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { TextNodeType } from "../FormattedText/FormattedText";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
interface ToastProp {
  greyBgCross?: string;
  toastCtaTxt?: string;
  planSelectedTxt?: TextNodeType | string;
  handleClickCta?: () => void;
  handleClose?: () => void;
  autoCloseDuration?: number | null;
  toastCloseIcon?: string;
  toastIcon?: string;
  isDisable?: boolean;
  showToast?: boolean;
  width?: string;
  height?: string;
  onTimerEnd?: () => void | any;
}

const Toast = ({
  toastCtaTxt,
  planSelectedTxt,
  handleClickCta,
  handleClose,
  autoCloseDuration = 3000,
  toastCloseIcon,
  toastIcon,
  isDisable,
  showToast,
  width,
  height,
  onTimerEnd = () => {},
}: ToastProp) => {
  const device = useBreakpoint();
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (autoCloseDuration) {
      timer = setTimeout(() => {
        if (visible) {
          setVisible(false);
          onTimerEnd();
        }
      }, autoCloseDuration);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [autoCloseDuration, visible]);
  return visible ? (
    <ToastStyle>
      <FlexComp gap={device == "lg" ? "16px" : "4px"} alignItems="center">
        {toastIcon && (
          <ToastIcon>
            <ImageComponent
              width={width ? width : "24px"}
              height={height ? height : "24px"}
              src={toastIcon}
            />
          </ToastIcon>
        )}
        <SpanTag
          fontSize={device == "lg" ? "md" : "xs"}
          color={theme.whiteText}
        >
          {planSelectedTxt && <FormattedText value={planSelectedTxt} />}
        </SpanTag>
        {toastCtaTxt && (
          <LandingButtonWhite
            customTitle={toastCtaTxt}
            onClick={handleClickCta}
            disableState={isDisable}
          />
        )}
      </FlexComp>
      {toastCloseIcon && (
        <CloseIconWrap onClick={handleClose}>
          <ImageComponent width="28px" height="28px" src={toastCloseIcon} />
        </CloseIconWrap>
      )}
    </ToastStyle>
  ) : null;
};

export default Toast;
