import {
  ArrowIconContainer,
  AvatarIconWpr,
  CoachmarkContainer,
  CoachmarkInnerContainer,
  CounterTag,
  GIAvatarIcon,
  MMTAvatarIcon,
  MMTAvatarIconV3,
} from "./ListingPageComponents.style";
import { BtnProp } from "../ui-atoms/LandingButton/ButtonType";
import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import {
  ElementNameMapping,
  PARTNER,
  PageNameMapping,
  SectionNameMapping,
  VERSIONS,
} from "../../../constants";
import { getElementUniqueIds } from "../../../utils";
import { HeaderBackIcon } from "../../icons/HeaderBackIcon";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";
import Header from "../Header/Header";
import { BackIcon } from "../../icons/BackIcon";
import { theme } from "../../css/Global";
import { useGlobalConfig } from "../../../hooks/useGlobalConfig";
import Portal from "../Portal/Portal";
import CoachmarkArrow from "../../icons/coachmarkArrow.png";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import LandingButton from "../ui-atoms/LandingButton/LandingButton";

export interface ListingPageHeaderProps {
  arrowIcon?: string;
  isLoggedIn?: boolean;
  userAvatarImgURI?: string;
  loginBtnProps?: BtnProp;
  onClickBack?: () => void;
  handleLoginClick?: () => void;
  isScrolled?: boolean;
  rightCtaTxt?: string;
  handleAvatarClick?: () => void;
  orderCount?: string | number;
  showCoachmark?: boolean;
  coachmarkTitle?: string;
  coachmarkBtnTitle?: string;
  coachmarkClickHandler?: (e: any) => void;
  version: number;
}

const ListingPageHeader = ({
  arrowIcon,
  isLoggedIn = false,
  userAvatarImgURI,
  loginBtnProps,
  onClickBack,
  handleLoginClick,
  isScrolled,
  rightCtaTxt,
  handleAvatarClick,
  orderCount,
  showCoachmark = false,
  coachmarkTitle = "Find all your ongoing and past currency order here",
  coachmarkBtnTitle = "OK GOT IT",
  coachmarkClickHandler,
  version,
}: ListingPageHeaderProps) => {
  const { partner } = useGlobalConfig();
  // prettier-ignore
  return (
        <>
            <FlexComp
                padding="16px"
                alignItems={'center'}
                justifyContent="space-between"
                width={'100%'}
            >
                <ArrowIconContainer
                    id={getElementUniqueIds(
                        PageNameMapping.LISTING_PAGE,
                        SectionNameMapping.HEADER,
                        ElementNameMapping.BACK_BUTTON
                    )}
                    onClick={onClickBack}
                >
                    {arrowIcon ? <ImageTag src={arrowIcon} /> : <BackIcon />}
                </ArrowIconContainer>

                {!isLoggedIn ? (
                    <AnchorTag
                        fontWeight="bold"
                        fontSize="sm"
                        handleClick={handleLoginClick}
                        id={getElementUniqueIds(
                            PageNameMapping.LISTING_PAGE,
                            SectionNameMapping.HEADER,
                            ElementNameMapping.RIGHT_BUTTON
                        )}
                    >
                        {rightCtaTxt}
                    </AnchorTag>
                ) : (
                    userAvatarImgURI && (
                        // <ImageTag width="24px" src={userAvatarImgURI} />
                        <AnchorTag
                            handleClick={handleAvatarClick}
                            id={getElementUniqueIds(
                                PageNameMapping.LISTING_PAGE,
                                SectionNameMapping.HEADER,
                                ElementNameMapping.RIGHT_BUTTON
                            )}
                        >
                            <AvatarIconWpr>
                                {partner === PARTNER.MMT ? (
                                    version === VERSIONS.V3 ? <MMTAvatarIconV3 /> : <MMTAvatarIcon /> 
                                ) : (
                                    <GIAvatarIcon />
                                )}
                                {orderCount && (
                                    <CounterTag>{orderCount}</CounterTag>
                                )}
                            </AvatarIconWpr>
                            {showCoachmark && (
                                <Portal id='coachmarkPortal'>
                                    <CoachmarkContainer>
                                        <CoachmarkInnerContainer>
                                            <AvatarIconWpr>
                                                {partner === PARTNER.MMT ? (
                                                    version === VERSIONS.V3 ? <MMTAvatarIconV3 /> : <MMTAvatarIcon /> 
                                                ) : (
                                                    <GIAvatarIcon />
                                                )}
                                                <CounterTag>
                                                    {orderCount}
                                                </CounterTag>
                                            </AvatarIconWpr>
                                            {/* @ts-ignore */}
                                            <ImageTag src={CoachmarkArrow.src}
                                                height="38px"
                                                margin="10px 0px 0px 0px"
                                            />
                                            <SpanTag
                                                color="#fff"
                                                fontSize="sm"
                                                fontWeight="bold"
                                                customWidth={'70%'}
                                                margin={'10px 0px 0px 0px'}
                                                textAlign={'right'}
                                            >
                                                {coachmarkTitle}
                                            </SpanTag>
                                            <LandingButton
                                                background="#fff"
                                                customTitle={coachmarkBtnTitle}
                                                fontSize={'12px'}
                                                color="#008CFF"
                                                padding="12px 20px"
                                                margin="16px 0px 0px"
                                                onClick={
                                                    coachmarkClickHandler as any
                                                }
                                            />
                                        </CoachmarkInnerContainer>
                                    </CoachmarkContainer>
                                </Portal>
                            )}
                        </AnchorTag>
                    )
                )}
            </FlexComp>
            {isScrolled && (
                <Header
                    bgColor={theme.whiteText}
                    showShadow
                    titleText="Forex Listing"
                    backArrow={<BackIcon />}
                    backBtnHandler={onClickBack}
                    handleRightBtnClick={handleLoginClick}
                    rightCtaTitle={rightCtaTxt}
                    showRightCta={!isLoggedIn}
                    headerWprId={getElementUniqueIds(
                        PageNameMapping.LISTING_PAGE,
                        SectionNameMapping.HEADER,
                        ElementNameMapping.BACK_BUTTON
                    )}
                    userInfoId={getElementUniqueIds(
                        PageNameMapping.LISTING_PAGE,
                        SectionNameMapping.HEADER,
                        ElementNameMapping.RIGHT_BUTTON
                    )}
                />
            )}
        </>
    );
};

export default ListingPageHeader;
