import ForexCards from "../ForexCards";

interface IForexCardsWidgetProps {
  id?: string;
  onLoginClick?: () => void;
  getonLoginCallback?: (fn: () => void) => void;
}

export const ForexCardsWidget = (props: IForexCardsWidgetProps) => {
  return <ForexCards {...props} />;
};

export default ForexCardsWidget;
