import styled from "styled-components";
import { theme } from "../../css/Global";
import { SpanTagStyle } from "../ui-atoms/TextStyles/TextStyles.style";
import { deviceType } from "../../utils";

export const LabelContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: baseline;
  margin-right: 10px;

  @media ${deviceType.mobile} {
    flex-direction: column;
    flex: 1;
  }
`;

export const DiscountLabel = styled(SpanTagStyle)<any>`
  font-weight: ${(props) => (props?.fontWeight ? props.fontWeight : 700)};
  font-size: ${(props) => (props?.fontSize ? props.fontSize : "12px")};
  line-height: ${(props) => (props?.lineHeight ? props.lineHeight : "16px")};
  color: ${(props) => (props?.color ? props.color : theme.blackText)};
  padding-top: ${(props) => props?.paddingTop ?? "0px"};
  .greenTextMob {
    color: #5ee8ba;
    font-weight: 700;
  }
  .greenTextMobV3 {
    color: #1e5c43;
    font-weight: 700;
  }
  .greenTextWeb {
    color: #1e5c43;
    font-weight: 700;
  }
`;

export const DiscountSubLabel = styled(SpanTagStyle)<any>`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #1e5c43;
  .greenTextMob {
    color: #5ee8ba;
    font-weight: 700;
  }
  .greenTextMobV3 {
    color: #1e5c43;
    font-weight: 700;
  }
  .greenTextWeb {
    color: #1e5c43;
    font-weight: 700;
  }

  @media ${deviceType.mobile} {
    font-weight: 400;
    color: ${(props) => (props?.color ? props.color : theme.whiteText)};
  }
`;
