import React from "react";
import {
  ParaTag,
  LandingButtonWhite,
  AnchorTag,
  FormattedText,
} from "@tm/static";
import { theme } from "@tm/utility/global";

import { TextNodeType } from "../../../static/components/FormattedText/FormattedText";
import { AddPassportDetailStyle } from "./AddPassportDetail.style";

interface AddPassportProp {
  data: {
    addPasstitle: TextNodeType;
    addPassInfo?: TextNodeType;
    addPassportCta?: {
      title: TextNodeType;
      url: string;
    };
    addPassportBtn: {
      title?: string;
      iconLeft?: string;
      iconRight?: string;
      url?: string;
    };
  };
  handlePassportBtn?: () => void;
}

const AddPassportDetail = ({ data, handlePassportBtn }: AddPassportProp) => {
  const { addPasstitle, addPassportBtn, addPassInfo, addPassportCta } = data;

  return (
    <AddPassportDetailStyle>
      <ParaTag fontSize="md">
        <FormattedText value={addPasstitle} />
      </ParaTag>
      <LandingButtonWhite
        btnLeftIcon={addPassportBtn.iconLeft}
        btnRightIcon={addPassportBtn.iconRight}
        customWidth="100%"
        customTitle={addPassportBtn.title}
        onClick={handlePassportBtn}
        margin="16px 0 0"
      />
      {/* {addPassInfo && (
                <ParaTag fontSize="md" color={theme.greyText}>
                    <FormattedText value={addPassInfo} />
                </ParaTag>
            )}
            {addPassportCta && (
                <AnchorTag fontWeight="bold" fontSize="sm">
                    <FormattedText value={addPassportCta.title} />
                </AnchorTag>
            )} */}
    </AddPassportDetailStyle>
  );
};

export default AddPassportDetail;
