import React from "react";
import { theme } from "@tm/utility/global";

import { H1TagStyle } from "./TextStyles.style";
import { usePartnerContext } from "@tm/static/partnerContext";

type Colors = typeof theme;
type ColorKey = keyof Colors;
interface H1TagProps {
  color?: Colors[ColorKey];
  children?: React.ReactNode;
  margin?: string;
}

const H1Tag: React.FC<H1TagProps> = ({ children, color, margin }) => {
  const context = usePartnerContext();
  return (
    <H1TagStyle
      context={context ? context : undefined}
      txtcolor={color}
      margin={margin}
    >
      {children}
    </H1TagStyle>
  );
};

export default H1Tag;
