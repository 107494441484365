import styled from "styled-components";
import { deviceType } from "@tm/static/utils";

export const CardImgStyle = styled.div<any>`
  display: flex;
  justify-content: center;
  margin-top: -83px;

  @media ${deviceType.mobile} {
    margin-top: -95px;
  }
`;

//Dweb

export const ForexCardCompDwebStyle = styled.div<any>`
  background: #fff;
  border-radius: 12px;
  width: 405px;
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-top: 83px;
`;

export const BottomContainer = styled.div<any>`
  border-radius: 8px;
  background: #fff;
  margin-top: -6px;
  position: relative;
  padding-top: 6px;

  > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    padding: 4px 16px;

    > span {
      font-weight: 700;
    }
  }
`;

//Dweb

//Mweb

export const AnimationWpr = styled.div<any>`
  @keyframes zeroOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes downUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0vh);
    }
  }
  position: relative;
  z-index: 10;
  animation: zeroOpacity 2s, downUp 3s 2s;
`;

export const ForexCardCompMwebStyle = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px;
  margin-top: 14px;
  background: #fff;
`;

export const Label = styled.p<any>`
  background-color: #eaf5ff;
  text-align: center;
  width: 100%;
  border-radius: 4px;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 4px 16px;

  > span {
    font-weight: 700;
  }
`;

//Mweb
