import {
  OMNITURE_MAP,
  PAGE_AND_CATEGORY_MAP,
  PAGE_NAMES,
  OMNI_EVENTS_MAP,
} from "../constants";
import {
  trackEventMweb,
  getUserCityAndLocation,
  getLogInStatus,
  getCommonOmniData,
} from "../common";
import { getDeviceTypeForOmniture } from "../common";
import type { TrackListingClickType } from "../type";

const { CTA_INFO } = OMNI_EVENTS_MAP;

//  listing click events
export function trackListingClickEvents(deps: TrackListingClickType) {
  try {
    const { actionType, partner, tmUserId } = deps;
    const { pageName, lob, siteSection } =
      PAGE_AND_CATEGORY_MAP[PAGE_NAMES["LISTING"]];
    const location: string = getUserCityAndLocation();
    const platform: string = getDeviceTypeForOmniture() || "";
    const loginStatus = getLogInStatus();
    const commonOmniData = getCommonOmniData({
      pageName,
      lob,
      siteSection,
      partner,
      location,
      platform,
      loginStatus,
      tmUserId,
    });
    const omniData = {
      ...commonOmniData,
      [OMNITURE_MAP[CTA_INFO]]: actionType,
    };
    trackEventMweb({
      ...omniData,
      pageName,
    });
  } catch (e) {
    console.error(e);
  }
}
