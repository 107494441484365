import React from "react";

import { H4TagStyle } from "./TextStyles.style";
interface H4TagProps {
  color?: "black" | "gray" | "blue" | any;
  children?: any;
  margin?: any;
  id?: string;
  as?: string;
}

const H4Tag: React.FC<H4TagProps> = ({
  children,
  margin,
  color,
  id,
  as = "h4",
}) => {
  return (
    <H4TagStyle
      id={id}
      style={{ "--color": color, "--margin": margin }}
      as={as}
    >
      {children}
    </H4TagStyle>
  );
};

export default H4Tag;
