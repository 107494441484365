import React from "react";
import {
  SafenSecureTravStyle,
  SafenSecureBgWrap,
} from "./SafenSecureTrav.style";
import { InnerContentWrap } from "../../CommonStyle/CommonStyles.style";
import SafenSecureTravCrd from "./SafenSecureTravCrd";
import { FlexComp, H2Tag, H5Tag, ImageComponent } from "@tm/static";
import SafenSecureBg from "../../icons/safenSecureBg.svg";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import { useBreakpointV2 } from "@tm/ui-widgets";

interface SafenSecureTravProps {
  title?: TextNodeType;
  data?: {
    title: TextNodeType;
    featList: TextNodeType[];
    poweredBy: string[];
    cardImg: string;
  }[];
}

const SafenSecureTrav = ({ title, data }: SafenSecureTravProps) => {
  const device = useBreakpointV2();
  return (
    <SafenSecureTravStyle>
      <SafenSecureBgWrap>
        <ImageComponent
          width="100%"
          height="558px"
          src="https://tripmoneycmsimgak.mmtcdn.com/img/safen_Secure_Bg_200cfd8d5c.svg"
        />
      </SafenSecureBgWrap>
      <InnerContentWrap>
        {device == "lg" ? (
          <H2Tag margin="0 0 60px">
            {title && <FormattedText value={title} />}
          </H2Tag>
        ) : (
          <H5Tag margin="0 0 20px">
            {title && <FormattedText value={title} />}
          </H5Tag>
        )}
        <FlexComp
          gap={device == "lg" ? "60px" : "20px"}
          flexDirection={device == "sm" ? "column" : "row"}
          flexWrap="wrap"
        >
          {data &&
            data.map((item, index: number) => {
              return <SafenSecureTravCrd key={index} {...item} />;
            })}
        </FlexComp>
      </InnerContentWrap>
    </SafenSecureTravStyle>
  );
};

export default SafenSecureTrav;
