import * as React from "react";
interface iconprop {}
const SearchIcon = (props: iconprop) => (
  <svg
    width={23}
    height={23}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={23} height={23} rx={11.5} fill="#EAF5FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6153 16.3216L16.7372 16.1996L14.3893 13.8521C15.112 13.0046 15.5053 11.9449 15.5053 10.828C15.5053 9.57884 15.0187 8.40401 14.1356 7.52048C12.3121 5.69696 9.34528 5.69696 7.52096 7.52042C5.69744 9.34394 5.69744 12.3116 7.52093 14.1351C8.40839 15.0225 9.59496 15.5028 10.828 15.5028C11.9308 15.5028 13.0003 15.1136 13.8521 14.3887L16.2005 16.7364C16.2727 16.8085 16.3688 16.8477 16.4687 16.8477C16.5686 16.8477 16.6651 16.8085 16.7372 16.7363C16.8853 16.5883 16.8853 16.3484 16.7375 16.1999L16.6153 16.3216ZM13.5995 13.5983C12.0719 15.1259 9.58607 15.1262 8.05826 13.5983C6.53058 12.0707 6.53058 9.58483 8.05826 8.05715C8.8023 7.31388 9.79565 6.9118 10.8287 6.9118C11.8624 6.9118 12.8558 7.31383 13.5995 8.05718C14.3397 8.79736 14.7469 9.78078 14.7469 10.828C14.7469 11.8749 14.3395 12.8587 13.5995 13.5983Z"
      fill="#008CFF"
    />
  </svg>
);
export { SearchIcon };
