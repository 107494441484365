interface InfoIconProps {
  color?: string;
  cursor?: string;
  width?: number;
  height?: number;
}

const InfoIcon = ({
  cursor = "pointer",
  color,
  width = 16,
  height = 16,
  ...props
}: InfoIconProps) => (
  <svg
    style={{ cursor: cursor }}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.600098 7.99998C0.600098 12.0869 3.91319 15.4 8.0001 15.4C12.087 15.4 15.4001 12.0869 15.4001 7.99998C15.4001 3.91307 12.087 0.599976 8.0001 0.599976C3.91319 0.599976 0.600098 3.91307 0.600098 7.99998ZM14.4001 7.99998C14.4001 11.5346 11.5347 14.4 8.0001 14.4C4.46547 14.4 1.6001 11.5346 1.6001 7.99998C1.6001 4.46535 4.46547 1.59998 8.0001 1.59998C11.5347 1.59998 14.4001 4.46535 14.4001 7.99998ZM8.67002 7.32318V11.732C8.67002 12.102 8.37008 12.4019 8.0001 12.4019C7.63011 12.4019 7.33018 12.102 7.33018 11.732V7.32318C7.33018 6.95319 7.63011 6.65326 8.0001 6.65326C8.37008 6.65326 8.67002 6.95319 8.67002 7.32318ZM8.79646 5.51487C8.84592 5.40887 8.87066 5.29462 8.87066 5.17422C8.87066 5.05465 8.84628 4.93973 8.79782 4.83127C8.751 4.72648 8.68784 4.634 8.6088 4.55495C8.52894 4.47509 8.43531 4.4123 8.32921 4.36714C8.22161 4.32136 8.10785 4.29838 7.98954 4.29838C7.87042 4.29838 7.75737 4.32168 7.65223 4.36841C7.54976 4.41395 7.45911 4.47707 7.38144 4.55702C7.30423 4.63651 7.24305 4.72941 7.19831 4.83454C7.15252 4.94214 7.12954 5.05591 7.12954 5.17422C7.12954 5.29334 7.15285 5.40638 7.19958 5.51152C7.24433 5.61222 7.30514 5.7023 7.38144 5.78085C7.45911 5.8608 7.54976 5.92392 7.65223 5.96946C7.75737 6.01619 7.87042 6.0395 7.98954 6.0395C8.10785 6.0395 8.22161 6.01651 8.32921 5.97073C8.43531 5.92558 8.52894 5.86278 8.6088 5.78292C8.6869 5.70482 8.74965 5.61518 8.79646 5.51487Z"
      fill={color || "#4A4A4A"}
    />
  </svg>
);
export { InfoIcon };
