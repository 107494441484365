import React from "react";

import {
  InputWrap,
  LandingInputStyle,
  ErrorTxt,
  CalenderWrap,
} from "./LandingInput.style";
import { AnchorTag, ImageComponent, SpanTag } from "@tm/static";
import { theme } from "@tm/utility/global";

interface InputFiledProp {
  placeholderTxt?: string;
  labelTxt?: string;
  disableState?: boolean;
  inputWidth?: string;
  margin?: string;
  inputValue?: string | number;
  handleChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  handleFocus?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  handleBlur?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  Verify?: boolean;
  flex?: string;
  requiredField?: boolean;
  handleKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  maxInputLength?: number | undefined;
  inputType?: React.HTMLInputTypeAttribute | undefined;
  errorState?: string | undefined;
  maxValue?: string;
  minValue?: string;
  autoComplete?: string;
  calender?: boolean;
  handleOpenCalender?: () => void;
  autofocus?: boolean;
  isDisabled?: boolean;
}

const LandingInput: React.FC<InputFiledProp> = ({
  placeholderTxt,
  labelTxt,
  disableState,
  inputWidth,
  margin,
  errorState,
  inputValue,
  handleChange,
  Verify,
  flex,
  requiredField,
  handleKeyDown,
  maxInputLength,
  inputType,
  handleFocus,
  handleBlur,
  maxValue,
  minValue,
  autoComplete = "on",
  calender,
  handleOpenCalender,
  autofocus,
  isDisabled,
}) => {
  const handleMouseWheel = (event: any) => {
    event.preventDefault();
  };
  return (
    <InputWrap
      margin={margin}
      flex={flex}
      inputWidth={inputWidth}
      Verify={Verify}
    >
      {labelTxt && (
        <label htmlFor={labelTxt}>
          {labelTxt}
          {requiredField && (
            <SpanTag fontWeight="bold" color={theme.redText2}>
              *
            </SpanTag>
          )}
        </label>
      )}
      <LandingInputStyle
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        value={inputValue}
        errorState={errorState}
        id={labelTxt}
        disableState={disableState}
        placeholder={placeholderTxt}
        maxLength={maxInputLength}
        type={inputType}
        min={minValue}
        max={maxValue}
        onWheel={handleMouseWheel}
        autoComplete={autoComplete}
        autoFocus={autofocus}
        inputWidth={inputWidth}
        disabled={isDisabled}
      />
      {errorState && (
        <ErrorTxt
          txtfontSize="xs"
          margin="4px 0 0"
          txtcolor={theme.redText}
          txtfontWeight="bold"
        >
          {errorState}
        </ErrorTxt>
      )}
      {calender && (
        <CalenderWrap onClick={handleOpenCalender}>
          <ImageComponent
            width="24px"
            height="24px"
            src="https://tripmoneycmsimgak.mmtcdn.com/img/Calendar_b8e9e22a64.svg"
          />
        </CalenderWrap>
      )}
    </InputWrap>
  );
};

export default LandingInput;
