import styled from "styled-components";

import { theme } from "@tm/utility/global";
import { deviceType } from "@tm/static/utils";

export const ModalContentEqualizer = styled.div`
  min-height: 300px;
`;
export const CountriesContainer = styled.ul`
  display: grid;
  gap: 8px;
  margin-bottom: 24px;
  grid-template-columns: repeat(3, 1fr);

  & > li:last-child {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.24);

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  & > li > span:nth-child(2) {
    position: absolute;
    left: 8px;
    bottom: 8px;
  }
`;

export const CountryLiPoint = styled.li<any>`
  height: 130px;
  cursor: pointer;
  position: relative;
  flex: 0 0 32%;
  border-radius: 6px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.24);
  background: linear-gradient(
    180deg,
    rgba(106, 106, 106, 0) -2.78%,
    rgba(0, 0, 0, 0.7) 95.83%
  );
  background-image: ${(props) => `url(${props.bgImage})`};
  background-size: 100% 100%;
`;

interface OptionsContainerProps {
  isLastChildNormal?: boolean;
}

export const OptionsContainer = styled.ul<OptionsContainerProps>`
  display: grid;
  gap: 12px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  margin-bottom: 24px;
  grid-template-columns: repeat(2, 1fr);

  & > li {
    display: flex;
    gap: 8px;
    cursor: pointer;
    padding: 20px 12px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    border: 1px solid #e8e8e8;

    @media ${deviceType.mobile} {
      padding: 8px 12px;
    }
  }

  & > li.active {
    border: 1px solid ${theme.blueText};
  }

  ${(props) =>
    !props.isLastChildNormal &&
    `
        & > li:last-child {
            grid-column: span 2;
        }
    `}
`;

export const InfoText = styled.p`
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 32px;
  background: ${theme.yellowText2};
  border-radius: 8px;
`;
