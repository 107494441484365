import styled from "styled-components";
import { theme } from "../../css/Global";
import { deviceType } from "../../utils";

export const CouponItem = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding: 12px 16px 16px;
  margin-top: 8px;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
`;

export const TncContainer = styled.div<any>`
  margin: 24px 0px;
`;
export const TncListItems = styled.ul<any>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
  li {
    font-size: 12px;
    font-weight: 400;
    color: ${theme.greyText};
    padding-left: 14px;
    position: relative;
  }
  li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 6px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${theme.blueText};
  }
`;

export const AvailInstructions = styled.div<any>`
  margin: 24px 0px;
`;

export const Container = styled.div<any>`
  max-height: 50vh;
  overflow-y: auto;
`;

// export const AvailInstructionsItems = styled.ul<any>`
//     display: flex;
//     flex-direction: column;
//     gap: 4px;
//     margin-top: 8px;
//     padding-left: 12px;
//     li {
//         font-size: 12px;
//         font-weight: 400;
//         color: ${theme.greyText};
//         padding-left: 14px;
//         position: relative;
//     }
//     li::before {
//         content: '';
//         position: absolute;
//         left: 0;
//         top: 6px;
//         width: 4px;
//         height: 4px;
//         border-radius: 4px;
//         background-color: ${theme.blackText};
//     }
// `;

export const CashbackSlabContainer = styled.div`
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  margin: 8px 0px 0px;
`;
export const CashbackSlabHeadingRow = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${(p: any) =>
    p.borderBottom ? p.borderBottom : "1px solid #e2e2e2"};
  background: #f6f6f6;
  > div {
    flex: 1;
    padding: 10px 8px;
    justify-content: space-around;
    align-items: center;
    &:first-child {
      border-right: 1px solid #e2e2e2;
    }
  }
`;

export const CashbackSlabRow = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${(p: any) =>
    p.borderBottom ? p.borderBottom : "1px solid #e2e2e2"};
  > div {
    flex: 1;
    padding: 10px 14px;
    &:first-child {
      border-right: 1px solid #e2e2e2;
    }
  }
`;
