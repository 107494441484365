import { useEffect, useReducer, useRef } from "react";
import { getMobileOperatingSystem } from "../../static/utils";
import { useDevice } from "../useDevice";
import {
  PARTNER,
  WAITING_TIME_LOCATION_FETCING,
  ERROR_MSGS,
} from "../../constants";
import { useListingPdtLogger } from "../usePdtLogger";
import { useDefaultLocationTimer } from "../useDefaultLocationTimer";
import useListingPdtLoggerV2 from "../useListingPdtLoggerV2";

export interface IGeoLocation {
  latitude: number | null;
  longitude: number | null;
}

export interface InitialState {
  location: IGeoLocation | null;
  error: string | null;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}

const initialLocation = { latitude: null, longitude: null };
const initialState: InitialState = {
  location: initialLocation,
  error: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
};

const LOCATION_FETCH_LOADING = "LOCATION_FETCH_LOADING";
const LOCATION_FETCH_SUCCESS = "LOCATION_FETCH_SUCCESS";
const LOCATION_FETCH_ERROR = "LOCATION_FETCH_ERROR";

const reducer = (
  state: InitialState,
  { type, payload }: { type: string; payload?: any }
) => {
  switch (type) {
    case LOCATION_FETCH_LOADING:
      return { ...initialState, isLoading: true };
    case LOCATION_FETCH_SUCCESS:
      return { ...initialState, location: payload, isSuccess: true };
    case LOCATION_FETCH_ERROR:
      return { ...initialState, isError: true, error: payload };
    default:
      return state;
  }
};

const FETCH_USER_LOC_FUNCTION = "fetchUserLocation";

export const useGeoLocation = (partner?: string | null) => {
  const timerRef = useRef<NodeJS.Timer | undefined>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const device = useDevice();
  const { sendPdtListingError } = useListingPdtLogger();
  const { sendPdtListingErrorV2 } = useListingPdtLoggerV2();

  const { startTimer, resetTimer } = useDefaultLocationTimer({
    initialTime: 5 * 1000,
    interval: 1000,
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.fetchUserLocation = (userLocation) => {
      resetTimer();
      try {
        if (userLocation === "null") {
          timerRef.current = setTimeout(() => {
            dispatch({
              type: LOCATION_FETCH_ERROR,
              payload: { message: "Location denied" },
            });
          }, 20);
          return;
        }
        const location = JSON.parse(userLocation);
        const { Latitude, Longitude } = location || {};
        if (Latitude && Longitude) {
          timerRef.current = setTimeout(() => {
            dispatch({
              type: LOCATION_FETCH_SUCCESS,
              payload: { latitude: Latitude, longitude: Longitude },
            });
          }, 20);
        } else {
          timerRef.current = setTimeout(() => {
            dispatch({
              type: LOCATION_FETCH_ERROR,
              payload: { message: "Location denied" },
            });
          }, 20);
        }
      } catch {
        timerRef.current = setTimeout(() => {
          dispatch({
            type: LOCATION_FETCH_ERROR,
            payload: { message: "Location denied" },
          });
        }, 20);
      }
    };
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const setUserLocationWeb = () => {
    if (navigator.permissions) {
      dispatch({ type: LOCATION_FETCH_LOADING });
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          resetTimer();
          dispatch({
            type: LOCATION_FETCH_SUCCESS,
            payload: { latitude, longitude },
          });
        },
        (error) => {
          resetTimer();
          dispatch({ type: LOCATION_FETCH_ERROR, payload: error });
        },
        {
          timeout: 10000,
        }
      );
    } else {
      resetTimer();
      dispatch({
        type: LOCATION_FETCH_ERROR,
        payload: {
          message: ERROR_MSGS.GEOLOCATION_NOT_SUPPORTED,
        },
      });
      const error = {
        errorDescription: ERROR_MSGS.GEOLOCATION_NOT_SUPPORTED,
      };
      sendPdtListingError(error);
      sendPdtListingErrorV2(error);
      console.error(ERROR_MSGS.GEOLOCATION_NOT_SUPPORTED);
    }
  };

  const setUserLocationAndroid = () => {
    try {
      dispatch({
        type: LOCATION_FETCH_LOADING,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window?.app_bridge?.fetchLocation) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.app_bridge.fetchLocation(FETCH_USER_LOC_FUNCTION, true);
      } else {
        resetTimer();
        timerRef.current = setTimeout(() => {
          dispatch({
            type: LOCATION_FETCH_ERROR,
            payload: {
              error: "Fetch location method is not available",
            },
          });
        }, WAITING_TIME_LOCATION_FETCING);
      }
    } catch (e: any) {
      resetTimer();
      timerRef.current = setTimeout(() => {
        dispatch({
          type: LOCATION_FETCH_ERROR,
          payload: {
            error: e?.message,
          },
        });
      }, WAITING_TIME_LOCATION_FETCING);
      const errorObj = {
        ...e,
        message: `${e.message} setUserLocationAndroid fetchLocation`,
      };
      sendPdtListingError(errorObj);
      sendPdtListingErrorV2(errorObj);
    }
  };

  const setUserLocation = () => {
    startTimer(() => {
      dispatch({
        type: LOCATION_FETCH_ERROR,
        payload: initialState.location,
      });
    });
    const mobileOS = getMobileOperatingSystem();
    if (
      mobileOS.toLowerCase() === "android" &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (window?.app_bridge || window?.mmt_android_bridge)
    ) {
      setUserLocationAndroid();
      return;
    }
    setUserLocationWeb();
  };

  const setUserLocationIfPermittedDesktop = () => {
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (permissionStatus) {
          if (permissionStatus.state === "granted") {
            setUserLocationWeb();
          } else {
            // Permission not granted, do not request location
            resetTimer();
            dispatch({
              type: LOCATION_FETCH_SUCCESS,
              payload: initialState.location,
            });
            console.error("Permission not granted", permissionStatus.state);
          }
        });
    } else {
      // Geolocation not supported
      resetTimer();
      dispatch({
        type: LOCATION_FETCH_SUCCESS,
        payload: initialState.location,
      });
      console.log(ERROR_MSGS.GEOLOCATION_NOT_SUPPORTED);
    }
  };

  const setUserLocationIfPermittedAndroid = () => {
    // TODO: Once method is available on bridge.
    // TODO: Add version conditions.
    try {
      dispatch({ type: LOCATION_FETCH_LOADING });
      //@ts-ignore
      if (window?.app_bridge?.fetchLocation) {
        //@ts-ignore
        window.app_bridge.fetchLocation(FETCH_USER_LOC_FUNCTION, false);
      } else {
        resetTimer();
        timerRef.current = setTimeout(() => {
          dispatch({
            type: LOCATION_FETCH_ERROR,
            payload: {
              error: "Method fetchLocation is not available",
            },
          });
        }, WAITING_TIME_LOCATION_FETCING);
      }
    } catch (e: any) {
      resetTimer();
      dispatch({
        type: LOCATION_FETCH_ERROR,
        payload: {
          error: e.message,
        },
      });
      const errorObj = {
        ...e,
        message: `${e.message} setUserLocationIfPermittedAndroid fetchLocation`,
      };
      sendPdtListingError(errorObj);
      sendPdtListingErrorV2(errorObj);
    }
  };

  const setUserLocationIfPermittedIOS = () => {
    // TODO: Once method is available on bridge.

    // TODO Apply bridge conditions.
    let location = { ...initialLocation };
    try {
      if (partner === PARTNER.MMT) {
        //@ts-ignore
        location = window?.app_bridge?.lastKnownLocation();
      } else if (partner === PARTNER.GI) {
        location = JSON.parse(
          //@ts-ignore
          window?.app_bridge?.fetchLocation(JSON.stringify(true))
        );
      }
      if (location?.latitude && location?.longitude) {
        resetTimer();
        dispatch({
          type: LOCATION_FETCH_SUCCESS,
          payload: location,
        });
      } else {
        resetTimer();
        dispatch({
          type: LOCATION_FETCH_ERROR,
          payload: {
            error: "Unable to fetch location",
          },
        });
      }
    } catch (e: any) {
      resetTimer();
      dispatch({
        type: LOCATION_FETCH_ERROR,
        payload: {
          error: e?.message,
        },
      });
    }
  };

  const setUserLocationIfPermitted = () => {
    startTimer(() => {
      dispatch({
        type: LOCATION_FETCH_ERROR,
        payload: initialState.location,
      });
    });
    if (device === "lg") {
      setUserLocationIfPermittedDesktop();
    } else if (device === "sm") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window?.app_bridge) {
        const mobileOS = getMobileOperatingSystem();
        if (mobileOS.toLowerCase() === "android") {
          setUserLocationIfPermittedAndroid();
        } else if (mobileOS.toLocaleLowerCase() === "ios") {
          setUserLocationIfPermittedIOS();
        }
      } else {
        setUserLocationIfPermittedDesktop();
      }
    }
  };

  return {
    ...state,
    setUserLocation,
    setUserLocationIfPermitted,
  };
};
