import {
  FlexComp,
  LandingButton,
  Modal,
  Spinner,
  TMFloatingInput,
  ParaTag,
  ImageTag,
  SnackBar,
  PincodeCities,
} from "@tm/static";

import { Container, Cta, GpsIconWpr } from "./EditLocation.styles";
import { theme } from "@tm/utility/global";

import { CloseIcon, GpsIcon } from "../../../icons";

import { useBreakpoint } from "@tm/ui/hooks";

import { EditLocationProps } from "./types";

const EditLocation = ({
  onModalClose,
  modalHeading,
  closeButton,
  pinCodeLabel,
  pinCodeDetails,
  continueBtnTitle,
  btnDisableState,
  continueBtnHandler,
  spinnerTxt,
  showLoader = false,
  detectLocationHandler,
  detectLocationTxt,
  gpsIcon,
  showErrorSnackbar,
  errorDetails,
  citiesList,
  showServicableCities,
  citiesSectionTitle,
  showMoreBtnTxt,
}: EditLocationProps) => {
  const device = useBreakpoint();

  return (
    <Modal
      modalHeading={modalHeading}
      onModalClose={onModalClose}
      closeButton={
        closeButton ? (
          <ImageTag src={closeButton} showCursorPointer />
        ) : (
          <CloseIcon />
        )
      }
      modalType={device === "lg" ? "centered" : "bottomSheet"}
    >
      <Container>
        {!showLoader ? (
          <>
            {pinCodeLabel && (
              <ParaTag
                fontSize={device === "lg" ? "md" : "sm"}
                lineHeight={device === "lg" ? "18px" : "20px"}
                margin="32px 0 16px 0"
              >
                {pinCodeLabel}
              </ParaTag>
            )}

            <FlexComp
              flexDirection="row"
              flex="1"
              justifyContent="center"
              alignItems="center"
            >
              <TMFloatingInput
                flex={1}
                labelTxt={pinCodeDetails?.labelTxt}
                inputValue={pinCodeDetails?.inputValue}
                handleChange={pinCodeDetails?.handleChange}
                errorState={pinCodeDetails?.errorState}
                verifiedTag={pinCodeDetails?.verifiedTag}
                maxLength={pinCodeDetails?.maxLength}
              />

              <Cta onClick={detectLocationHandler}>
                {
                  <GpsIconWpr>
                    {gpsIcon ? <ImageTag src={gpsIcon} /> : <GpsIcon />}
                  </GpsIconWpr>
                }
                {detectLocationTxt}
              </Cta>
            </FlexComp>

            {showErrorSnackbar && (
              <SnackBar
                containerStyle={{ marginTop: 16 }}
                buttonText={errorDetails?.btnText}
                message={errorDetails?.errorMsg}
                onButtonClick={errorDetails?.snackbarCloseHandler}
                isPosFixed={false}
              />
            )}

            {showServicableCities && (
              <PincodeCities
                title={citiesSectionTitle}
                citiesList={citiesList}
                showMoreBtnTxt={showMoreBtnTxt}
              />
            )}

            <LandingButton
              customTitle={continueBtnTitle}
              margin="24px 0 0 0"
              customWidth="100%"
              disableState={btnDisableState}
              onClick={continueBtnHandler}
            />
          </>
        ) : (
          <>
            <FlexComp justifyContent="center" margin="32px 0 16px 0">
              <Spinner thickness={3} spinnerWidth={30} />
            </FlexComp>
            {spinnerTxt && (
              <ParaTag
                fontSize={"md"}
                lineHeight={"24px"}
                margin="12px 0 16px 0"
                color={theme.greyText}
                textAlign="center"
              >
                {spinnerTxt}
              </ParaTag>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
};

export default EditLocation;
