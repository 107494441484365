import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useEffect } from "react";

export const useHeaderAnimation = (
  setIsLight: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const ANIMATION_TYPE = "ease-in-out";
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const timerId = setTimeout(() => {
      const rootContainerElement = document.getElementById("root_container");
      const bmfValueProp = document.getElementById("curr_ref");
      const landingMapLeft = document.getElementById("LandingMapLeft");
      const tmlogo = document.getElementById("tmlogo");

      const privacyElement = document.getElementById("privacy_ref");
      const greyRefElement = document.getElementById("grey_ref");
      const headerElement = document.getElementById("header_ref");

      const forexCardWidget = document.getElementById("id_forex_card_widget");
      const forexListingTrustUs = document.getElementById(
        "forex_listing_trust_us"
      );

      gsap.to(tmlogo, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: bmfValueProp,
          start: "top top+=80",
          //toggleActions: "play none restart reset",
          end: "bottom top",
          onEnter: () => {
            setIsLight(false);
          },
          onLeaveBack: () => {
            setIsLight(true);
          },
        },
      });

      function registerHeaderBackgroundTransition() {
        const gsapConfig = {
          headerLight: { color: "#000", backgroundColor: "#fff" },
          headerDark: {
            backgroundColor: "#000",
            color: "rgba(255,255,255,0.6)",
          },
          headerGrey: { color: "#000", backgroundColor: "#f5f5f5" },
          headerTransparent: {
            backgroundColor: "transparent",
            color: "rgba(255,255,255,0.6)",
          },
        };

        if (document.querySelector("body")?.className === "about-us") {
          // hero banner section
          gsap.to(headerElement, {
            scrollTrigger: {
              scroller: ".about-us",
              trigger: "#aboutUsWrapper",
              start: "top top",
              end: "bottom top",
              onEnter: () => {
                setIsLight(false);
                gsap.to(headerElement, gsapConfig.headerGrey);
              },
              onLeaveBack: () => {
                setIsLight(false);
                gsap.to(headerElement, gsapConfig.headerGrey);
              },
            },
            ease: ANIMATION_TYPE,
          });

          // our journey section
          gsap.to(headerElement, {
            scrollTrigger: {
              scroller: ".about-us",
              trigger: "#ourJourney",
              start: "top 80px",
              end: "bottom top",
              onEnter: () => {
                setIsLight(true);
                gsap.to(headerElement, gsapConfig.headerDark);
              },
              onLeaveBack: () => {
                setIsLight(false);
                gsap.to(headerElement, gsapConfig.headerGrey);
              },
            },
            ease: ANIMATION_TYPE,
          });

          // spotlight section
          gsap.to(headerElement, {
            scrollTrigger: {
              scroller: ".about-us",
              trigger: "#spotLight",
              start: "top 80px",
              end: "bottom top",
              onEnter: () => {
                setIsLight(false);
                gsap.to(headerElement, gsapConfig.headerLight);
              },
              onLeaveBack: () => {
                setIsLight(true);
                gsap.to(headerElement, gsapConfig.headerDark);
              },
            },
            ease: ANIMATION_TYPE,
          });
        } else if (
          document.querySelector("body")?.className === "forex-listing-page"
        ) {
          gsap.to(headerElement, {
            scrollTrigger: {
              scroller: ".forex-listing-page",
              trigger: forexCardWidget,
              start: "top top+=80",
              end: "bottom top",
              onEnter: () => {
                setIsLight(true);
                gsap.to(headerElement, gsapConfig.headerDark);
              },
              onLeaveBack: () => {
                setIsLight(false);
                gsap.to(headerElement, gsapConfig.headerLight);
              },
            },
            ease: ANIMATION_TYPE,
          });

          gsap.to(headerElement, {
            scrollTrigger: {
              scroller: ".forex-listing-page",
              trigger: forexListingTrustUs,
              start: "top top+=80",
              end: "bottom top",
              onEnter: () => {
                setIsLight(false);
                gsap.to(headerElement, gsapConfig.headerLight);
              },
              onLeaveBack: () => {
                setIsLight(true);
                gsap.to(headerElement, gsapConfig.headerDark);
              },
            },
            ease: ANIMATION_TYPE,
          });
        } else {
          gsap.to(headerElement, {
            scrollTrigger: {
              scroller: rootContainerElement,
              trigger: landingMapLeft,
              start: "top top+=80",
              end: "bottom top",
              onEnter: () => {
                gsap.to(headerElement, gsapConfig.headerDark);
              },
              onLeaveBack: () => {
                gsap.to(headerElement, gsapConfig.headerTransparent);
              },
            },
            ease: ANIMATION_TYPE,
          });

          gsap.to(headerElement, {
            scrollTrigger: {
              scroller: rootContainerElement,
              trigger: bmfValueProp,
              start: "top top+=80",
              end: "bottom top",
              onEnter: () => {
                gsap.to(headerElement, gsapConfig.headerLight);
              },
              onLeaveBack: () => {
                gsap.to(headerElement, gsapConfig.headerDark);
              },
            },
            ease: ANIMATION_TYPE,
          });

          gsap.to(headerElement, {
            scrollTrigger: {
              scroller: rootContainerElement,
              trigger: greyRefElement,
              start: "top top+=60",
              end: "bottom top",
              onEnter: () => {
                gsap.to(headerElement, gsapConfig.headerGrey);
              },
              onLeaveBack: () => {
                gsap.to(headerElement, gsapConfig.headerLight);
              },
            },
            ease: ANIMATION_TYPE,
          });

          gsap.to(headerElement, {
            scrollTrigger: {
              scroller: rootContainerElement,
              trigger: privacyElement,
              start: "top top+=80",
              // toggleActions: "play none restart reset",
              end: "bottom top",
              onEnter: () => {
                gsap.to(headerElement, gsapConfig.headerLight);
              },
              onLeaveBack: () => {
                gsap.to(headerElement, gsapConfig.headerGrey);
              },
            },
            ease: ANIMATION_TYPE,
          });
        }
      }

      registerHeaderBackgroundTransition();
    }, 300);

    return () => {
      clearTimeout(timerId);
    };
  }, []);
};
