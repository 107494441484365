import { HeaderProps } from "./HeaderProps";
import { HeaderCont, HeaderInner, HeaderLft, HeaderRht } from "./Header.styles";
import UserInfo from "../UserInfo/UserInfo";
import ParaTag from "../TextStyles/ParaTag";

const Header = ({
  logo,
  userName,
  bgColor = "#000",
  titleText,
  backArrow,
  headerType,
  children,
  userInfoHandler,
  greetingText,
  showShadow = false,
  backBtnHandler,
  headerWprId,
  backBtnId,
}: HeaderProps) => {
  return (
    <HeaderCont id={headerWprId} bgColor={bgColor} showShadow={showShadow}>
      <HeaderInner headerType={headerType}>
        <HeaderLft>
          {backArrow && (
            <span id={backBtnId} onClick={backBtnHandler}>
              {backArrow}
            </span>
          )}
          {logo && logo}
          {titleText && (
            <ParaTag
              fontSize="md"
              color="#4a4a4a"
              fontWeight="bold"
              margin="0 0 0 15px"
            >
              {titleText}
            </ParaTag>
          )}
        </HeaderLft>
        {userName && (
          <HeaderRht>
            <UserInfo
              userName={userName}
              onPress={userInfoHandler}
              greetingText={greetingText}
            />
          </HeaderRht>
        )}
      </HeaderInner>
      {children}
    </HeaderCont>
  );
};

export default Header;
