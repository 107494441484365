import { GetServerSidePropsContext } from "next/types";
import { getHostName, isOnServer } from "@tm/ui-widgets";
import {
  DATE_FORMATS,
  FormFieldType,
  FORM_FIELDS_MAP,
  PARTNER,
  PARTNER_DOMAIN_NAME,
  UTM_PARAMS,
  TRIPMONEY_DOMAIN_NAME,
} from "../constants";
import { formatDate } from "./dateUtils";
import { getHost } from "../../getHost";
import { IncomingHttpHeaders } from "http";

export const getPlatform = {
  WEB: "web",
};

export const setURLQueryParams = (
  params: Record<string, string | number | boolean> = {}
) => {
  const usp = new URLSearchParams();
  Object.keys(params).forEach((e) => {
    if (params[e]) {
      usp.set(e, params[e].toString());
    }
  });
  return usp.toString();
};

export const getUtmParams = () => {
  if (typeof window !== "undefined") {
    const utm_source = sessionStorage.getItem(UTM_PARAMS.UTM_SOURCE);
    const utm_medium = sessionStorage.getItem(UTM_PARAMS.UTM_MEDIUM);
    const utm_ins_campaign = sessionStorage.getItem(
      UTM_PARAMS.UTM_INS_CAMPAIGN
    );
    return { utm_source, utm_medium, utm_ins_campaign };
  }
  return;
};

export const persistUtmParams = (queryParam: URLSearchParams) => {
  const utm_source = queryParam.get(UTM_PARAMS.UTM_SOURCE);
  const utm_medium = queryParam.get(UTM_PARAMS.UTM_MEDIUM);
  const utm_campaign = queryParam.get(UTM_PARAMS.UTM_CAMPAIGN);

  if (utm_source) {
    sessionStorage.setItem(UTM_PARAMS.UTM_SOURCE, utm_source);
  }

  if (utm_medium) {
    sessionStorage.setItem(UTM_PARAMS.UTM_MEDIUM, utm_medium);
  }

  if (utm_campaign) {
    sessionStorage.setItem(UTM_PARAMS.UTM_INS_CAMPAIGN, utm_campaign);
  }
};

export const replaceHistory = (param: string, value: string) => {
  if (typeof window !== "undefined") {
    const baseUrl = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    value?.length > 0 ? searchParams.set(param, value) : null;
    const url = `${baseUrl}${
      searchParams.toString().length > 0 ? "?" + searchParams : ""
    }`;
    window.history.replaceState(
      { ...window.history.state, as: url, url: url },
      "",
      url
    );
  }
};

export const replaceHistoryRemoveParams = (param: string) => {
  const baseUrl = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.delete(param);

  const url = `${baseUrl}${
    searchParams.toString().length > 0 ? "?" + searchParams : ""
  }`;
  window.history.replaceState(
    { ...window.history.state, as: url, url: url },
    "",
    url
  );
};

export const getCookieByName = (cookieName: string) => {
  const cookie: Record<string, string> = {};
  document.cookie.split(";").forEach((el) => {
    const [key, value] = el.split("=");
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
};

export const truncateString = (value: string, maxLength: number): string => {
  if (!value) {
    return "";
  }
  if (value.length > maxLength) {
    return value.substring(0, maxLength) + "...";
  }
  return value;
};

export const generateSourceSetImageUrl = (
  cdnUrlWidths: Array<number>,
  imgUrl: string,
  width: number
) => {
  let imgWidths = cdnUrlWidths;
  if (!imgWidths || !Array.isArray(imgWidths) || imgWidths.length === 0)
    imgWidths = [width, width * 2, width * 3];

  return imgWidths
    .map((width) => `${imgUrl}?im=Resize=(${width}) ${width}w`)
    .join(",");
};

export const getFormFieldErrorMsg = (
  field: FormFieldType,
  errorMsg?: string
) => {
  if (errorMsg) {
    return errorMsg;
  } else {
    const fieldName = FORM_FIELDS_MAP[field] || field;
    return `Please enter ${fieldName}`;
  }
};

export const isDobValid = (value: string | Date, regex?: string) => {
  const formattedDate = formatDate(value, DATE_FORMATS["DD/MM/YYYY"]);
  const isValidDate = new RegExp(regex || "").test(formattedDate);
  return isValidDate;
};

export const getPartnerOnClientSide = () => {
  const hostname = getHostName() || "";
  if (hostname.includes(PARTNER_DOMAIN_NAME.MMT)) {
    return PARTNER.MMT;
  } else if (hostname.includes(PARTNER_DOMAIN_NAME.GI)) {
    return PARTNER.GI;
  } else if (hostname.includes(PARTNER_DOMAIN_NAME.TRIPMONEY)) {
    return PARTNER.TRIPMONEY;
  } else {
    console.error(
      `Error detecting partner. Unsupported domain hostname - ${hostname}`
    );
    return PARTNER.TRIPMONEY;
  }
};

export const getPartnerOnServerSide = (headers: IncomingHttpHeaders) => {
  if (isOnServer()) {
    const host = getHost(headers);

    if (typeof host === "string") {
      if (TRIPMONEY_DOMAIN_NAME.GI.includes(host)) {
        return PARTNER.GI;
      } else {
        return PARTNER.MMT;
      }
    }
  }
  return PARTNER.MMT;
};

export const isPartnerMMT = () => {
  return getPartnerOnClientSide() === PARTNER.MMT;
};

export const isPartnerGI = () => {
  return getPartnerOnClientSide() === PARTNER.GI;
};

export const isFieldValid = (regex: string, updatedField: string): boolean => {
  try {
    const validator = new RegExp(regex || "");
    return validator?.test(updatedField);
  } catch (error) {
    return false;
  }
};

export const debounce = <T extends any[]>(
  func: (...args: T) => void,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: T) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};
