import { Fragment, useState } from "react";
import { theme } from "../../css/Global";

import {
  LandingFaqStyle,
  LandingFaqList,
  LandingFaqTitle,
  LandingFaqQues,
  LandingInnerPointsCtr,
  TabItem,
} from "./LandingFaq.style";
import { InnerContentWrap } from "../../CommonStyle/CommonStyles.style";
import {
  FaqDataProps,
  aboveAndBelowTextListItemProps,
  landingFaqDataProps,
} from "../../mockData";
import { useDevice } from "../../../hooks/useDevice";
import { useGlobalConfig } from "../../../hooks/useGlobalConfig";
import {
  PageNameMapping,
  SectionNameMapping,
  ElementNameMapping,
  PDT_EVENTS,
} from "../../../constants";
import { getElementUniqueIds, getEventDetails } from "../../../utils";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";
import { useListingPdtLogger } from "../../../hooks/usePdtLogger";
import { trackListingClickEvents } from "../../../analytics";
import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import useListingPdtLoggerV2 from "../../../hooks/useListingPdtLoggerV2";

interface FaqType {
  customTitle?: string;
  customFaq?: string;
  faq: FaqDataProps[];
  margin?: string | number;
  id?: string;
  padding?: string;
  faqAnswerLinkTextOnClick?: (item: aboveAndBelowTextListItemProps) => void;
  onTabClick: (tabName: string) => void;
}

const LandingFaqV2 = ({
  customTitle,
  customFaq,
  faq,
  margin,
  id,
  padding,
  faqAnswerLinkTextOnClick,
  onTabClick,
}: FaqType) => {
  const [exp, setExp] = useState(-1);
  const device = useDevice();
  const { sendPdtFaqClickEvent } = useListingPdtLogger();
  const { sendPdtDataV2 } = useListingPdtLoggerV2();
  const { partner } = useGlobalConfig();

  const [data, setData] = useState(faq);

  const handleClick = (index: any, item: landingFaqDataProps) => {
    exp != index ? setExp(index) : setExp(-1);
    sendPdtDataV2({
      event_detail: getEventDetails({
        event_name: PDT_EVENTS.FAQ_CTA,
        event_value: item.ques,
      }),
    });
    sendPdtFaqClickEvent(item.ques);
    trackListingClickEvents({
      actionType: `faq_${index + 1}_clicked`,
      partner,
    });
  };

  const textWithLinkRenderer = (textList: aboveAndBelowTextListItemProps[]) => {
    return textList.map((item) => {
      if (item.linkText) {
        return (
          <Fragment>
            {" "}
            <AnchorTag
              fontSize={device === "lg" ? "md" : "sm"}
              handleClick={
                faqAnswerLinkTextOnClick
                  ? (e: React.MouseEvent<HTMLAnchorElement>) => {
                      e.stopPropagation();
                      faqAnswerLinkTextOnClick(item);
                    }
                  : () => {}
              }
              hrefProp={item.href}
            >
              {item.linkText}
            </AnchorTag>{" "}
          </Fragment>
        );
      }

      return <span dangerouslySetInnerHTML={{ __html: item.text! }}></span>;
    });
  };

  const handleTabClick = (item: FaqDataProps) => {
    onTabClick(item.id);
    setData(
      data?.map((dataItem) =>
        dataItem?.id === item?.id
          ? { ...dataItem, isSelected: true }
          : { ...dataItem, isSelected: false }
      )
    );
  };

  return (
    <LandingFaqStyle
      id={id}
      margin={margin}
      customFaq={customFaq}
      padding={padding}
    >
      <InnerContentWrap>
        {customTitle && (
          <LandingFaqTitle
            id={getElementUniqueIds(
              PageNameMapping.LISTING_PAGE,
              SectionNameMapping.FAQs,
              ElementNameMapping.COMP_HEADING
            )}
            marginLeft={device === "sm" ? "16px" : ""}
            marginBottom="16px"
          >
            {customTitle}
          </LandingFaqTitle>
        )}
        <FlexComp flexDirection="row" gap="8px" margin="0px 0px 0px 16px">
          {data?.map((item) => {
            return (
              <TabItem
                onClick={() => handleTabClick(item)}
                isSelected={item.isSelected}
              >
                {item.label}
              </TabItem>
            );
          })}
        </FlexComp>

        {data && (
          <LandingFaqList customFaq={customFaq}>
            {data
              ?.find((item) => item.isSelected)
              ?.faqData?.map((item, index: any) => {
                return (
                  <li
                    id={getElementUniqueIds(
                      PageNameMapping.LISTING_PAGE,
                      SectionNameMapping.FAQs.concat(index),
                      ElementNameMapping.DOWN_ARROW
                    )}
                    className={`${exp == index ? "open_forex" : "close_forex"}`}
                    key={index}
                    onClick={() => handleClick(index, item)}
                  >
                    <LandingFaqQues customFaq={customFaq}>
                      {item.ques}
                    </LandingFaqQues>
                    {true && (
                      <div
                        className={`pointCtr ${
                          exp === index ? "open_forex" : ""
                        }`}
                      >
                        {item.answer &&
                          item.answer.map((item) => {
                            return (
                              <div key={item.id}>
                                {item.aboveTextList && (
                                  <ParaTag
                                    color={theme.blackText3}
                                    margin="0px 0px 16px"
                                    fontSize={device === "lg" ? "md" : "sm"}
                                  >
                                    {textWithLinkRenderer(item.aboveTextList)}
                                  </ParaTag>
                                )}
                                {item.pointsList && (
                                  <LandingInnerPointsCtr>
                                    {item.pointsList.map((ele) => {
                                      return (
                                        <div key={ele.id}>
                                          {ele.label && (
                                            <ParaTag
                                              dangerousText={ele.label}
                                              color={theme.blackText3}
                                              fontSize={
                                                device === "lg" ? "md" : "sm"
                                              }
                                            />
                                          )}
                                          {ele.info && (
                                            <ParaTag
                                              dangerousText={ele.info}
                                              color={theme.blackText3}
                                              fontSize={
                                                device === "lg" ? "md" : "sm"
                                              }
                                            />
                                          )}
                                        </div>
                                      );
                                    })}
                                  </LandingInnerPointsCtr>
                                )}
                                {item.belowTextList && (
                                  <ParaTag
                                    margin="0px 0px 24px"
                                    fontSize={device === "lg" ? "md" : "sm"}
                                    color={theme.blackText3}
                                  >
                                    {textWithLinkRenderer(item.belowTextList)}
                                  </ParaTag>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </li>
                );
              })}
          </LandingFaqList>
        )}
      </InnerContentWrap>
    </LandingFaqStyle>
  );
};

export default LandingFaqV2;
