import { getAuth } from "../api/tmAuth";
import {
  AuthAbstract,
  GIAndroidAuth,
  GIIosAuth,
  MMTAndroidAuth,
  MMTIOSAuth,
} from "../classes";
import {
  getAppSocialLoginQuery,
  getUserDeviceOS,
  isPartnerHost,
} from "../common";
import { ANDROID, IOS, LOGIN_TYPE, SOURCE, UNKNOWN } from "../constants";

/**
 * AuthFactory class is responsible for creating an instance of Auth based on the platform provided.
 */
export class AuthFactory {
  private auth: AuthAbstract | null = null;

  /**
   * Constructs an instance of AuthFactory.
   * @param platform - The platform for which the Auth instance needs to be created ('iOS' or 'Android').
   * @throws Error - If an invalid platform is provided.
   */
  constructor() {
    const device: "android" | "ios" | null = getUserDeviceOS();
    const authClasses = {
      [SOURCE.MAKEMYTRIP]: {
        [ANDROID]: MMTAndroidAuth,
        [IOS]: MMTIOSAuth,
      },
      [SOURCE.GOIBIBO]: {
        [ANDROID]: GIAndroidAuth,
        [IOS]: GIIosAuth,
      },
    };

    const source: string | undefined = [SOURCE.GOIBIBO, SOURCE.MAKEMYTRIP].find(
      isPartnerHost
    );

    if (source && device) {
      const AuthClass: any = authClasses[source][device];
      if (AuthClass) {
        this.auth = new AuthClass();
      } else {
        console.info(`AuthFactory: ${source} device not found`);
      }
    } else {
      console.log("AuthFactory: Invalid partner found");
    }
  }

  /**
   * Authenticates the user.
   * @returns A Promise that resolves to a boolean indicating whether the user is authenticated or not.
   */
  async authenticateUser(): Promise<boolean> {
    if (this.auth === null) {
      return false;
    }

    if (!this.auth.getPartnerAuthFromCookie()) {
      console.error("Partner token not found");
      return false;
    }

    let tmAuth = await this.auth.getTMAuth();

    // This case runs for iOS where TM Auth is returned from bridge handler and we need to save in cookie
    if (tmAuth && !this.auth.getTMAuthFromCookie()) {
      this.auth.saveTMAuthInCookie(tmAuth);
    }

    if (!tmAuth) {
      tmAuth = await this.generateTmAuth();
      if (tmAuth) this.auth.saveTMAuth(tmAuth);
    }

    return Boolean(tmAuth);
  }

  /**
   * Generates the TM_AUTH value by making an API call.
   * @returns A Promise that resolves to the generated TM_AUTH value or null if it couldn't be generated.
   */
  async generateTmAuth(): Promise<string | null> {
    if (!this.auth) {
      return null;
    }
    const authToken = await this.auth.getSLTToken();
    const reqBody = {
      accType: this.auth.accType,
      authToken: authToken,
      query: getAppSocialLoginQuery,
    };
    const tmAuthResponse = await getAuth(reqBody);
    const tmAuth: string | null =
      tmAuthResponse?.data?.profileLoginResponse?.[0]?.authToken || null;
    const loginInfoList =
      tmAuthResponse?.data?.profileLoginResponse?.[0]?.userDetails?.extendedUser
        ?.loginInfoList || [];
    const isMobileRegistered = loginInfoList.find(
      (e: any) => e?.loginType === LOGIN_TYPE.MOBILE
    );
    if (!isMobileRegistered) {
      return null;
    }
    return tmAuth;
  }
}
