import { landingCardShowcaseDataProps } from "@tm/static/data";

import {
  LandingCardFeatureStyle,
  PointsContainer,
  // LandingCardFeatureInnerStyle,
  NotLocationText,
  AbsTag,
  LogoContainer,
} from "./LandingCardFeature.style";

import { theme } from "@tm/utility/global";
import { useBreakpoint } from "@tm/ui/hooks";
import {
  ArrowIcon,
  DocsUploadIcon,
  LocationIcon,
  RoundEcllipseIcon,
  RoundTickIcon,
} from "@tm/static/icons";

import {
  FlexComp,
  ImageTag,
  ParaTag,
  SpanTag,
  PersuasionTile,
  LandingButton,
  AnchorTag,
} from "@tm/static";

interface LandingCardFeatureProps {
  data: landingCardShowcaseDataProps;
  margin?: string;
  padding?: string;
  border?: string;
  borderWidth?: string;
  idx?: number;
  onClick?: (data: landingCardShowcaseDataProps, idx?: number) => void;
  loadCurrenyBtnHandler?: (data: landingCardShowcaseDataProps) => void;
  recommendedBtnHandler?: (data: landingCardShowcaseDataProps) => void;
  startFromWhereLeftHandler?: (data: landingCardShowcaseDataProps) => void;
  docsPendingHandler?: (data: landingCardShowcaseDataProps) => void;
}

const LandingCardFeature = ({
  margin,
  padding,
  border,
  borderWidth,
  data,
  idx,
  loadCurrenyBtnHandler,
  recommendedBtnHandler,
  startFromWhereLeftHandler,
  docsPendingHandler,
  onClick = () => {},
}: LandingCardFeatureProps) => {
  const device = useBreakpoint();

  const {
    logoSrc,
    arrowIconSrc,
    label,
    subLabel,
    featuresArr,
    loadCurrency,
    isleftOffBefore,
    isRecommended,
    logoDesc,
    isRecommendNotification,
    deliveryLabel,
    docsPending,
    isUnavailableAtLoc,
  } = data;

  const arrowIcon = arrowIconSrc ? (
    <ImageTag src={arrowIconSrc} />
  ) : (
    <ArrowIcon />
  );

  return (
    <LandingCardFeatureStyle
      margin={margin}
      border={border}
      padding={padding}
      borderWidth={borderWidth}
    >
      {isRecommendNotification && (
        <AbsTag>
          <SpanTag color={theme.greenText}>Recommended</SpanTag>
        </AbsTag>
      )}
      <FlexComp
        flex="1"
        padding="0px"
        gap={device === "lg" ? "0px" : "16px"}
        flexDirection={device === "lg" ? "column" : "row"}
      >
        <LogoContainer>
          <ImageTag src={logoSrc} width={device === "lg" ? "120px" : "100%"} />
          {logoDesc && (
            <ParaTag
              margin={device === "lg" ? "0px 0px 6px" : "0px"}
              fontSize={device === "sm" ? "xxs" : "xs"}
              color={theme.greyText}
            >
              {logoDesc.normalText}{" "}
              {logoDesc.boldText && (
                <SpanTag
                  fontSize={device === "sm" ? "xxs" : "xs"}
                  fontWeight="bold"
                  color={theme.greyText}
                >
                  {logoDesc.boldText}
                </SpanTag>
              )}
            </ParaTag>
          )}
        </LogoContainer>
        <FlexComp padding="0px" gap="0px" flexDirection="column">
          <FlexComp
            handleClick={() => onClick(data, idx)}
            flex="0"
            gap="12px"
            justifyContent={device === "sm" ? "space-between" : "flex-start"}
            alignItems="center"
          >
            <ParaTag fontSize="large" fontWeight="bold">
              {label}
            </ParaTag>
            {!loadCurrency?.isLoaded && loadCurrency?.toAdd
              ? device === "lg" && arrowIcon
              : arrowIcon}
          </FlexComp>
          {!!subLabel && <ParaTag fontSize="sm">{subLabel}</ParaTag>}
          {featuresArr && (
            <PointsContainer>
              {featuresArr.map((item) => (
                <li key={item.id}>{item.label}</li>
              ))}
            </PointsContainer>
          )}
          {loadCurrency && (
            <>
              <FlexComp
                margin={device === "lg" ? "12px 0px 0px" : "4px 0px 0px"}
                flexDirection="column"
              >
                {!!loadCurrency.label && loadCurrency.isLoaded ? (
                  <FlexComp gap="4px" alignItems="center">
                    <ParaTag fontSize="xs" fontWeight="bold">
                      {loadCurrency.label}
                    </ParaTag>
                    {loadCurrency.isLoadedIcon ? (
                      <ImageTag src={loadCurrency.isLoadedIcon} />
                    ) : (
                      <RoundTickIcon size={12} />
                    )}
                  </FlexComp>
                ) : (
                  <ParaTag fontSize="xs" fontWeight="bold">
                    {loadCurrency.label}
                  </ParaTag>
                )}
                {!!loadCurrency.subLabel && (
                  <ParaTag
                    fontSize="xs"
                    color={theme.greyText}
                    margin="2px 0px 12px"
                  >
                    {loadCurrency.subLabel}{" "}
                    {!!loadCurrency.addText && (
                      <AnchorTag fontSize="xs" fontWeight="extraBold">
                        {loadCurrency.addText}
                      </AnchorTag>
                    )}
                  </ParaTag>
                )}
                {!!loadCurrency.linkBtnInMobile && (
                  <AnchorTag
                    fontSize="xs"
                    fontWeight="extraBold"
                    margin="auto 0px 0px"
                    handleClick={
                      loadCurrenyBtnHandler
                        ? () => loadCurrenyBtnHandler(data)
                        : () => {}
                    }
                    hrefProp={loadCurrency.linkBtnInMobile?.hrefProp}
                  >
                    {loadCurrency?.linkBtnInMobile?.label}
                  </AnchorTag>
                )}
              </FlexComp>
            </>
          )}
          {!!deliveryLabel && device === "sm" && (
            <FlexComp
              margin="4px 0px 0px"
              alignItems="center"
              padding="0px"
              gap="4px"
            >
              <LocationIcon />
              <SpanTag color={theme.greenText} fontSize="xs" fontWeight="bold">
                {deliveryLabel}
              </SpanTag>
            </FlexComp>
          )}
        </FlexComp>
      </FlexComp>
      {isleftOffBefore && (
        <PersuasionTile
          margin="24px 0px 0px"
          text={isleftOffBefore.text}
          bgColor={theme.yellowText2}
          linkBtn={{
            ...isleftOffBefore?.linkBtn,
            onClick: startFromWhereLeftHandler
              ? () => startFromWhereLeftHandler(data)
              : () => {},
          }}
          leftIcon={
            isleftOffBefore.iconSrc ? (
              <ImageTag src={isleftOffBefore.iconSrc} />
            ) : (
              <RoundEcllipseIcon />
            )
          }
        />
      )}
      {isRecommended && (
        <PersuasionTile
          margin="24px 0px 0px"
          text={isRecommended.text}
          bgColor={theme.greenText2}
          linkBtn={{
            ...isRecommended?.linkBtn,
            onClick: recommendedBtnHandler
              ? () => recommendedBtnHandler(data)
              : () => {},
          }}
        />
      )}
      {loadCurrency && loadCurrency.btnOptions && device === "sm" ? (
        <LandingButton
          {...loadCurrency.btnOptions}
          onClick={
            loadCurrenyBtnHandler ? () => loadCurrenyBtnHandler(data) : () => {}
          }
          fontSize="12px"
          margin="25px 0px 0px"
          customHeight="32px"
          customWidth="100%"
        />
      ) : (
        <AnchorTag
          fontSize="xs"
          fontWeight="extraBold"
          // margin="12px 0px 0px"
          handleClick={
            loadCurrenyBtnHandler ? () => loadCurrenyBtnHandler(data) : () => {}
          }
        >
          {loadCurrency?.btnOptions?.customTitle}
        </AnchorTag>
      )}
      {/* UNAVAILABLE AT LOCATION */}
      {isUnavailableAtLoc && (
        // <PersuasionTile
        //     margin="24px 0px 0px"
        //     bgColor={theme.yellowText2}
        //     normalTextColor={theme.brownText}
        //     text={isUnavailableAtLoc.text}
        // />
        <NotLocationText>
          {isUnavailableAtLoc.normalText && (
            <ParaTag fontSize="xs" color={theme.brownText}>
              {isUnavailableAtLoc.normalText}{" "}
              {isUnavailableAtLoc.linkText && (
                <AnchorTag
                  fontSize="xs"
                  fontWeight="bold"
                  hrefProp={isUnavailableAtLoc.linkText.hrefLink}
                >
                  {isUnavailableAtLoc.linkText?.text}
                </AnchorTag>
              )}
            </ParaTag>
          )}
        </NotLocationText>
      )}
      {/* DOCS PENDING */}
      {docsPending && (
        <FlexComp
          padding="12px"
          borderRadius="8px"
          margin="24px 0px 0px"
          flexDirection="column"
          bgColor="linear-gradient(159.48deg, #FFF9ED 4.69%, rgba(255, 239, 236, 0.736176) 27.76%, #F8F9E7 92.13%)"
        >
          {(docsPending.label || docsPending.iconSrc) && (
            <FlexComp
              alignItems="center"
              padding="0px"
              margin="0px 0px 16px"
              justifyContent="space-between"
            >
              {docsPending.label && (
                <ParaTag
                  fontWeight="bold"
                  color={theme.brownText}
                  margin="0px 20px 0px 0px"
                >
                  {docsPending.label}
                </ParaTag>
              )}
              {docsPending.iconSrc ? (
                <ImageTag src={docsPending.iconSrc} />
              ) : (
                <DocsUploadIcon />
              )}
            </FlexComp>
          )}
          {docsPending.btnProps && (
            <LandingButton
              {...docsPending.btnProps}
              onClick={
                docsPendingHandler ? () => docsPendingHandler(data) : () => {}
              }
              customWidth="100%"
            />
          )}
        </FlexComp>
      )}
    </LandingCardFeatureStyle>
  );
};

export default LandingCardFeature;
