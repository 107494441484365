import styled from "styled-components";
import { deviceType } from "../../utils";

export const LandingOfferingContainerStyle = styled.div`
  @media ${deviceType.mobile} {
    padding-top: 56px;
    width: 90%;
  }
`;
export const ForexOfferingWpr = styled.ul<any>`
  list-style: none;
  ${(props) => props.margin && `margin: ${props.margin ?? 0};`}
  width: 100%;
  padding-top: 4px;

  padding-bottom: 6px;
  display: flex;
  overflow-x: scroll;
  box-sizing: initial;
  @media ${deviceType.mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
