import styled from "styled-components";

import { theme } from "@tm/utility/global";

export const LandingCrdCompareStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
  position: relative;
  background-color: ${theme.whiteText2};
  padding: 100px 0px 120px 0px;
`;

export const LandingCrdCompareTitle = styled.h3`
  font-family: "Lato";
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 210px auto 0px;
  width: 1200px;
`;

export const LandingCrdCompareCrdList = styled.ul`
  display: flex;
  gap: 16px;

  li {
    position: relative;
  }
`;

export const CardName = styled.span`
  font-family: "Open Sans Light", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  padding: 16px 0;
`;

export const CardImg = styled.div`
  width: 288px;
  height: 180px;
  flex-shrink: 0;
  margin-bottom: 10px;
  top: -195px;
  position: absolute;
`;

export const CardFeature = styled.div<any>`
  background: #ffffff;
  border: 1px solid #d3d9df;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 288px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  position: relative;
  padding: 00px 16px 20px;

  ${(props) =>
    props.lastItem &&
    `
background: unset;
border: none;
box-shadow: unset;
border-radius: unset;
`}
`;

export const LabelList = styled.ul`
  margin-top: 66px;
  font-family: "Open Sans Light", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  li:nth-child(2n + 1):after {
    content: "";
    position: absolute;
    width: 1200px;
    height: 100%;
    background: rgba(0, 0, 0, 0.04);
    top: 0;
    left: 0;
    z-index: 1;
  }

  li {
    border-radius: 2px;
    min-height: 80px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    position: relative;
    color: ${theme.blackText2};
  }

  li a {
    font-family: "Open Sans Light", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #008cff;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 0;
`;

export const VideoPlay = styled.div`
  align-self: center;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: "Open Sans Light", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 250px;
  padding: 8px;
  cursor: pointer;

  .time {
    color: #979797;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 4px;
  }
`;

type LabelWrap = {
  flexColumn?: boolean;
  isBothIconAndText?: boolean | undefined;
  greyBg?: boolean;
};

export const LabelWrap = styled.div<LabelWrap>`
  padding: ${(props) => (props.isBothIconAndText ? "20px 0px;" : "20px 0px;")};
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 80px;
  align-items: center;

  &:nth-child(2n) {
    position: relative;
    z-index: 1;
  }

  .subInfo {
    color: #000;
    margin-top: 12px;
    opacity: 0.7;
    font-family: "Open Sans Light", sans-serif;
    font-size: 14px;
    line-height: 19px;
  }

  .infoTitle {
    color: #000;
    font-family: "Open Sans Light", sans-serif;
    font-size: 16px;
    line-height: 20px;
  }

  .infoTitle:first-child {
    margin-bottom: 8px;
  }

  ${(props) =>
    props.flexColumn &&
    `
            flex-direction: column;
            align-items: center;
    `}
`;
