import { useState } from "react";
import {
  LandingFooterStyle,
  LandingFooterWrap,
  LinksContainer,
  InnerLinksContainer,
  FooterSection,
  ChevronContainerIcon,
} from "./LandingFooter.style";

import {
  FooterHeartIcon,
  FooterDownloadIcon,
  MMTIcon,
  ChevronUpIcon,
} from "@tm/static/icons";

import {
  ParaTag,
  SpanTag,
  FlexComp,
  ImageTag,
  AnchorTag,
  ImageComponent,
} from "@tm/static";

import { footerDataProps } from "@tm/static/data";
import { useBreakpointV2 } from "@tm/ui-widgets";

interface LandingFooterProps {
  footerData: footerDataProps[];
  footerTextOne?: string;
  footerTextTwo?: string;
  followUsText?: string;
  downloadText?: string;
  locationText?: string;
  countryText?: string;
  mmtText?: string;
  playStoreImgURI: string;
  appStoreImgURI: string;
  linkedInImgURI: string;
  facebookImgURI: string;
  twitterImgURI: string;
  mmtIcon?: string;
  downIcon?: string;
  footerHeartIcon?: string;
}

const LandingFooter = ({
  footerData,
  footerTextOne = "An initiative",
  footerTextTwo = "towards solving Travel Finance",
  followUsText = "FOLLOW US",
  downloadText = "DOWNLOAD",
  countryText = "Country",
  locationText = "INDIA",
  mmtText = "MAKEMYTRIP PVT. LTD.",
  playStoreImgURI,
  appStoreImgURI,
  linkedInImgURI,
  facebookImgURI,
  twitterImgURI,
  mmtIcon,
  downIcon,
  footerHeartIcon,
}: LandingFooterProps) => {
  const device = useBreakpointV2();

  const [activeIdx, setActiveIdx] = useState(-1);

  const optionsHandler = (index: number) => {
    if (activeIdx === index) {
      setActiveIdx(-1);
      return;
    }

    setActiveIdx(index);
  };

  return (
    <LandingFooterStyle>
      <LandingFooterWrap>
        <FooterSection>
          <FlexComp
            alignItems="center"
            justifyContent={device === "sm" ? "center" : undefined}
            margin={"0px 0px 50px 0px"}
          >
            <SpanTag
              fontSize={device == "lg" ? "sm" : "xxs"}
              margin={"0px 8px 4px 0px"}
              color="white"
            >
              {footerTextOne}
            </SpanTag>
            {mmtIcon ? (
              <ImageComponent width="85px" height="27px" src={mmtIcon} />
            ) : (
              <MMTIcon />
            )}
            <SpanTag
              fontSize={device == "lg" ? "sm" : "xxs"}
              margin={"0px 0px 4px 8px"}
              color="white"
            >
              {footerTextTwo}
            </SpanTag>
          </FlexComp>
          <LinksContainer>
            {footerData?.map((item, idx) => {
              return (
                item?.label && (
                  <li
                    key={item.id}
                    onClick={
                      device === "sm" ? () => optionsHandler(idx) : undefined
                    }
                  >
                    <FlexComp
                      justifyContent={
                        device === "sm" ? "space-between" : undefined
                      }
                      gap={"8px"}
                      alignItems="center"
                    >
                      <FlexComp gap="10px">
                        {item?.icon && (
                          <ImageComponent
                            width="18px"
                            height="18px"
                            src={item.icon}
                          />
                        )}
                        <ParaTag color="white" fontWeight="black">
                          {item.label}
                        </ParaTag>
                      </FlexComp>
                      {device === "sm" && (
                        <ChevronContainerIcon isInvert={activeIdx !== idx}>
                          {downIcon ? (
                            <ImageTag src={downIcon} />
                          ) : (
                            <ChevronUpIcon />
                          )}
                        </ChevronContainerIcon>
                      )}
                    </FlexComp>
                    {device === "lg" ? (
                      <InnerLinksContainer>
                        {item.listPoints.map((point) => {
                          return (
                            <li key={point.id}>
                              <AnchorTag
                                color="#fff"
                                fontSize="xs"
                                hrefProp={point.link || "#"}
                                targetProp="_blank"
                              >
                                {point.label}
                              </AnchorTag>
                            </li>
                          );
                        })}
                      </InnerLinksContainer>
                    ) : (
                      activeIdx === idx && (
                        <InnerLinksContainer>
                          {item.listPoints.map((point) => {
                            return (
                              <li key={point.id}>
                                <AnchorTag
                                  color="#fff"
                                  fontSize="xs"
                                  hrefProp={point.link || "#"}
                                  targetProp="_blank"
                                >
                                  {point.label}
                                </AnchorTag>
                              </li>
                            );
                          })}
                        </InnerLinksContainer>
                      )
                    )}
                  </li>
                )
              );
            })}
          </LinksContainer>
        </FooterSection>
        <FlexComp
          flexDirection={device === "sm" ? "column" : undefined}
          justifyContent="space-between"
        >
          {/* <FlexComp
                        flexDirection={device === 'sm' ? 'column' : undefined}
                        alignItems={device === 'sm' ? 'center' : undefined}
                    >
                        <FlexComp
                            margin={device === 'sm' ? '0px 0px 12px' : '0px'}
                            flexDirection="column"
                            gap="12px"
                        >
                            {device === 'lg' && (
                                <FlexComp gap={'8px'}>
                                    {footerHeartIcon ? (
                                        <ImageTag src={footerHeartIcon} />
                                    ) : (
                                        <FooterHeartIcon />
                                    )}

                                    <ParaTag color="white" fontWeight="black">
                                        {followUsText}
                                    </ParaTag>
                                </FlexComp>
                            )}
                            <FlexComp gap="16px">
                                <AnchorTag hrefProp="#">
                                    <ImageTag
                                        width={'27px'}
                                        src={twitterImgURI}
                                    />
                                </AnchorTag>
                                <AnchorTag hrefProp="#">
                                    <ImageTag
                                        width={'27px'}
                                        src={facebookImgURI}
                                    />
                                </AnchorTag>
                                <AnchorTag hrefProp="#">
                                    <ImageTag
                                        width={'27px'}
                                        src={linkedInImgURI}
                                    />
                                </AnchorTag>
                            </FlexComp>
                        </FlexComp>
                        <FlexComp
                            gap="12px"
                            flexDirection="column"
                            margin={
                                device === 'lg'
                                    ? '0px 0px 0px 86px'
                                    : '0px 0px 12px'
                            }
                        >
                            {device === 'lg' && (
                                <FlexComp gap={'8px'}>
                                    <FooterDownloadIcon />
                                    <ParaTag color="white" fontWeight="black">
                                        {downloadText}
                                    </ParaTag>
                                </FlexComp>
                            )}
                            <FlexComp gap="16px">
                                <AnchorTag hrefProp="#">
                                    <ImageTag
                                        width={'128px'}
                                        src={playStoreImgURI}
                                    />
                                </AnchorTag>
                                <AnchorTag hrefProp="#">
                                    <ImageTag
                                        width={'128px'}
                                        src={appStoreImgURI}
                                    />
                                </AnchorTag>
                            </FlexComp>
                        </FlexComp>
                    </FlexComp> */}
          <div>
            <ParaTag
              textAlign={device === "lg" ? "left" : "center"}
              color="white"
            >
              © {new Date().getFullYear()} {mmtText}
            </ParaTag>
            <ParaTag textAlign={device === "lg" ? "right" : "center"}>
              <SpanTag color="white">{countryText}</SpanTag>{" "}
              <SpanTag color="white" fontWeight="bold">
                {locationText}
              </SpanTag>
            </ParaTag>
          </div>
        </FlexComp>
      </LandingFooterWrap>
    </LandingFooterStyle>
  );
};

export default LandingFooter;
