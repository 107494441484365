import styled from "styled-components";
import { WHITE_COLOR } from "@tm/utility/theme";

export const LandingHeader = styled.header`
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  color: ${WHITE_COLOR[60]};
  z-index: 40;

  & .displayNone {
    display: none;
  }
`;

export const LandingHeaderWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  position: relative;
`;

export const TMLogo = styled.a`
  width: 140px;
  height: 40px;
  flex-shrink: 0;
`;
