import { useState } from "react";

import { SpanTag, ParaTag } from "@tm/static";

import { theme } from "@tm/utility/global";

import {
  TMInputStyle,
  TMInputWrap,
  VerifiedContainer,
  InputWpr,
  IconContainer,
} from "./TMFloatingInput.style";

import { TMFloatingInputProps } from "./types";
import { DownArrow } from "../../icons/DownArrow";
import { EditIcon } from "../../icons/EditIcon";

const TMFloatingInput = ({
  placeholderTxt,
  labelTxt,
  disableState,
  inputWidth,
  margin,
  errorState,
  inputValue,
  handleChange,
  verifiedTag,
  maxLength,
  onFocus,
  onBlur,
  type = "text",
  inputMode,
  flex,
  fieldType = "text",
  showEdit = false,
  handleEditClick,
  handleDropdownPress,
}: TMFloatingInputProps) => {
  const [isFocus, setIsFocus] = useState(false);

  const inputFocusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    if (fieldType === "select") {
      event.preventDefault();
      event.currentTarget.blur();
    }
    !(fieldType === "select") && setIsFocus(true);
    onFocus && onFocus();
  };

  const inputBlurHandler = () => {
    !(fieldType === "select") && setIsFocus(false);
    onBlur && onBlur();
  };

  const handleClick = () => {
    if (fieldType === "select" && handleDropdownPress) {
      handleDropdownPress();
    }
  };

  return (
    <TMInputWrap
      disableState={disableState}
      margin={margin}
      inputWidth={inputWidth}
      flex={flex}
      placeholder={placeholderTxt}
      clickable={fieldType === "select"}
      onClick={handleClick}
    >
      <InputWpr>
        <TMInputStyle
          id={labelTxt}
          isActive={isFocus}
          inputMode={inputMode}
          onChange={fieldType === "select" ? () => {} : handleChange}
          value={inputValue}
          errorState={errorState}
          onBlur={inputBlurHandler}
          verifiedTag={verifiedTag}
          disableState={disableState}
          onFocus={inputFocusHandler}
          placeholder={placeholderTxt}
          spellCheck={"false"}
          maxLength={maxLength}
          type={type}
          required
          clickable={fieldType === "select"}
        />
        {labelTxt && (
          <label
            htmlFor={fieldType === "select" ? "" : labelTxt}
            className="labelText"
          >
            {labelTxt}
          </label>
        )}
        {fieldType === "text" && verifiedTag && (
          <VerifiedContainer>
            <ParaTag fontSize="xs" fontWeight="bold" color={theme.darkGreen}>
              {verifiedTag}
            </ParaTag>
          </VerifiedContainer>
        )}
        {fieldType === "text" && showEdit && (
          <IconContainer onClick={handleEditClick}>
            <EditIcon />
          </IconContainer>
        )}

        {fieldType === "select" && (
          <IconContainer>
            <DownArrow />
          </IconContainer>
        )}
      </InputWpr>
      {errorState && (
        <ParaTag
          margin="4px 0 0"
          color={theme.redText2}
          fontSize="xs"
          dangerousText={errorState}
        />
      )}
    </TMInputWrap>
  );
};

export default TMFloatingInput;
