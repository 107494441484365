import { FlexComp, ParaTag, SpanTag, TMFloatingInput } from "@tm/static";
import { TMFloatingInputProps } from "../../TMFloatingInput/types";
import { theme } from "@tm/utility/global";

import { AddCurrencyCardStyle } from "./AddCurrencyCard.style";
import { TimerLabel } from "../../ConversionRates/ConversionRates.style";
import { useBreakpoint } from "@tm/ui/hooks";

interface AddCurrencyCardProps {
  label?: string;
  inputFieldData?: TMFloatingInputProps;
  minimumQtyText?: string;
  exchangeRate: {
    label?: string;
    rateLabel?: string;
    persuasionLabel?: string;
  };
  amountLeftLabel?: string;
  amountRightLabel?: string;
}

const AddCurrencyCard = ({
  label,
  inputFieldData,
  minimumQtyText,
  exchangeRate,
  amountLeftLabel,
  amountRightLabel,
}: AddCurrencyCardProps) => {
  const device = useBreakpoint();

  return (
    <AddCurrencyCardStyle isError={!!inputFieldData?.errorState}>
      {label && (
        <ParaTag fontWeight="bold" fontSize="md">
          {label}
        </ParaTag>
      )}
      {inputFieldData && (
        <TMFloatingInput margin="16px 0px 0px" {...inputFieldData} />
      )}
      {minimumQtyText && !!!inputFieldData?.errorState && (
        <ParaTag
          color={inputFieldData?.errorState ? theme.redText : theme.greyText6}
          fontSize="xs"
          margin="4px 0px 0px"
          dangerousText={minimumQtyText}
        />
      )}
      {exchangeRate && (
        <FlexComp margin="16px 0px 0px" gap="4px">
          {exchangeRate.label && (
            <SpanTag color={theme.greyText6}>{exchangeRate.label}</SpanTag>
          )}
          {exchangeRate.rateLabel && (
            <SpanTag fontWeight="bold" color={theme.greenText}>
              {exchangeRate.rateLabel}
            </SpanTag>
          )}
          {exchangeRate.persuasionLabel && (
            <TimerLabel
              marginLeft="0px"
              marginTop={device === "sm" ? "0px" : undefined}
              bg="linear-gradient(87.29deg, #C86DD7 0%, #3023AE 100%)"
            >
              {exchangeRate.persuasionLabel}
            </TimerLabel>
          )}
        </FlexComp>
      )}
      {(!!amountLeftLabel || !!amountRightLabel) && (
        <FlexComp
          alignItems="center"
          margin="16px 0px 0px"
          justifyContent="space-between"
        >
          {amountLeftLabel && <SpanTag>{amountLeftLabel}</SpanTag>}
          {amountRightLabel && (
            <SpanTag color={theme.greyText}>{amountRightLabel}</SpanTag>
          )}
        </FlexComp>
      )}
    </AddCurrencyCardStyle>
  );
};

export default AddCurrencyCard;
