import styled from "styled-components";
import { ToastStyle } from "../Toast/Toast.style";
import { theme } from "@tm/utility/global";

type DatePickerProp = {
  activeTab?: boolean;
};

export const DatePickerWrap = styled.div`
  width: 592px;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 30px;

  ${ToastStyle} {
    position: static;
    background: #fcdadb;
    width: fit-content;
    margin-bottom: 16px;
    margin-left: 16px;
  }

  ${ToastStyle} span {
    font-size: 14px;
    color: ${theme.redText};
  }

  .rdp-cell {
    width: 38px;
    height: 38px;
  }

  .rdp-cell:hover {
    border-radius: 0;
  }

  .rdp-weeknumber,
  .rdp-day {
    font-size: 12px;
    width: 38px;
    height: 38px;
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible {
    background: #eaf5ff;
    color: #000;
    border-radius: 0;
  }

  .rdp-day_range_start,
  .rdp-day_range_end {
    background: #008cff;
    border-radius: 0px 2px 2px 0px;
    color: #fff;
  }
  .rdp-head_cell {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #9b9b9b;
  }
  .rdp-caption_label {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #000;
  }
  .rdp-caption_label .boldText {
    font-weight: 700;
  }
  /* .rdp-button[disabled]:not(.rdp-day_selected) {
        opacity: 1;
    } */
  .rdp-day_today {
    font-weight: 400;
  }
  .rdp-button svg {
    display: none;
  }
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background: #eaf5ff;
    border-radius: 0;
  }
  .rdp-nav_button_previous:before,
  .rdp-nav_button_next:before {
    position: absolute;
    top: 20px;
    content: "";
    width: 20px;
    height: 2px;
    background: #008cff;
    border-radius: 2px;
    background-image: none;
  }
  .rdp-nav_button_previous:after {
    left: 7px;
    transform: rotate(-45deg);
  }
  .rdp-nav_button_next:after {
    right: 7px;
    transform: rotate(135deg);
  }
  .rdp-nav_button_previous:after,
  .rdp-nav_button_next:after {
    position: absolute;
    top: 17px;
    content: "";
    width: 8px;
    height: 8px;
    background-image: none;
    border-top: solid 2px #008cff;
    border-left: solid 2px #008cff;
  }
`;

export const DateLabel = styled.div<DatePickerProp>`
  background: #ffffff;
  border-radius: 2px;
  padding: 0 8px;
  ${(p) =>
    p.activeTab &&
    `
    background: #eaf5ff;
    border: 1px solid #008cff;
    border-radius: 2px;
    `}
`;

export const DateTab = styled.ul`
  display: flex;
  padding: 10px 15px;
  border-radius: 4px 4px 0px 0px;
  background-color: #f2f2f2;
`;

export const DateTabList = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 10px;
  &:last-child {
    margin-right: unset;
  }
`;

export const ErrorToast = styled.div`
  border-radius: 6px;
  background: #fcdadb;
  padding: 12px 16px;
  margin: 0 16px 16px;
  width: auto;
`;

export const DobPickerStyle = styled.div`
  border-radius: 4px;
  border: 0px solid #979797;
  background: #fff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2);
  width: 300px;
  .rdp-dropdown_year,
  .rdp-dropdown_month {
    border-radius: 4px;
    border: 1px solid #4a4a4a;
    padding: 0 8px;
  }
  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    border-radius: 2px 0px 0px 2px;
    background: #008cff;
    color: #fff;
  }
  .rdp-head {
    color: #9b9b9b;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
  }
  .rdp-weeknumber,
  .rdp-day {
    font-size: 12px;
  }
`;
