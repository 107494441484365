import { FlexComp, H3Tag, ImageTag, ParaTag } from "@tm/static";

import { useBreakpoint } from "@tm/ui/hooks";

import { PoweredByContainer } from "./HappyClientsBanner.style";
import { LandingFaqTitle } from "../LandingFaq/LandingFaq.style";

interface HappyClientBannerProps {
  leftText?: string;
  rightText?: string;
  rightSubText?: string;
  poweredByText?: string;
  poweredByLogosArr?: any[];
}

const HappyClientsBanner = ({
  leftText,
  rightText,
  rightSubText,
  poweredByLogosArr,
  poweredByText,
}: HappyClientBannerProps) => {
  const device = useBreakpoint();

  return (
    <FlexComp
      justifyContent="space-between"
      flexDirection={device === "lg" ? "row" : "column"}
      alignItems={device === "lg" ? "center" : "flex-start"}
      padding={device === "lg" ? "56px 0px" : "86px 16px 0px"}
    >
      {leftText && (
        <LandingFaqTitle marginBottom="0px">{leftText}</LandingFaqTitle>
      )}
      <FlexComp margin={device === "lg" ? "0px" : "16px 0px 0px"} padding="0px">
        <FlexComp
          padding="0"
          justifyContent="center"
          flexDirection="column"
          margin={device === "lg" ? "0px 130px 0px 0px" : "0px 20px 0px 0px"}
        >
          {device === "lg"
            ? rightText && <H3Tag>{rightText}</H3Tag>
            : rightText && (
                <ParaTag fontSize="md" fontWeight="bold">
                  {rightText}
                </ParaTag>
              )}
          {rightSubText && (
            <ParaTag fontSize={device === "lg" ? "md" : "xs"}>
              {rightSubText}
            </ParaTag>
          )}
        </FlexComp>
        <PoweredByContainer>
          {poweredByText && (
            <ParaTag fontSize={device === "lg" ? "md" : "xs"}>
              {poweredByText}
            </ParaTag>
          )}
          {poweredByLogosArr && (
            <ul>
              {poweredByLogosArr.map((item, idx) => {
                return (
                  <li key={idx}>
                    <ImageTag
                      src={item.logoSrc}
                      width={`${
                        device === "lg" ? item.width : item.width * 0.7
                      }px`}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </PoweredByContainer>
      </FlexComp>
    </FlexComp>
  );
};

export default HappyClientsBanner;
