/* eslint-disable react/jsx-no-useless-fragment */
import { BANNER_TYPE } from "../../constants";
import useFetchBannerData from "../../hooks/useFetchBannerData";
import ForexMarketingBanner from "../ForexMarketingBanner";

const ForexBanners = () => {
  const { bannerOrdering, campaignData, marketingData } = useFetchBannerData();
  return (
    <>
      {bannerOrdering?.map((orderType) => {
        const data =
          orderType === BANNER_TYPE.CAMPAIGN ? campaignData : marketingData;
        return <ForexMarketingBanner bannerData={data} />;
      })}
    </>
  );
};

export default ForexBanners;
