import React, { useEffect, useState } from "react";

import { theme } from "@tm/utility/global";
import {
  AnchorTag,
  EditTraveller,
  FlexComp,
  ImageComponent,
  ParaTag,
  SpanTag,
  FormattedText,
} from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { formatDate } from "@tm/insurance/utils";
import { DATE_FORMATS } from "@tm/insurance/constants";
import { getAnalytics } from "@tm/insurance/analytics";

import {
  OrderDetailCompStyle,
  OrderDetailTravellerStyle,
  TravellerItem,
  OrderMobCtaWrap,
} from "./OrderDetailComp.style";
import { ITravellerData, OrderDetailProp } from "./type";

const analytics = getAnalytics();

const OrderDetailTraveller = ({
  title,
  orderDetailIcon,
  travellerDetails,
  editEnabled,
  editTraveller,
  updateOrderDetails,
  handleClickEditDetailSheet,
}: OrderDetailProp) => {
  const device = useBreakpointV2();

  const [editTravellerIndex, setEditTravellerIndex] = useState(-1);
  const [travellerData, setTravellerData] = useState<ITravellerData[]>([]);

  useEffect(() => {
    if (travellerDetails) {
      setTravellerData(travellerDetails);
    }
  }, [travellerDetails]);

  const handleClickEditDetails = (index: number) => {
    setEditTravellerIndex(index);
  };

  const handleUpdateDetails = async (
    updatedData: ITravellerData,
    index: number
  ) => {
    const travellers = [...travellerData];
    travellers[index] = {
      ...travellers[index],
      ...updatedData,
    };
    setTravellerData(travellers);
    await updateOrderDetails?.();
    setEditTravellerIndex(-1);
  };

  return (
    <div>
      <OrderDetailCompStyle>
        <FlexComp
          alignItems="center"
          margin={device == "lg" ? "0 0 24px" : "0 0 16px"}
          gap="8px"
        >
          {orderDetailIcon && (
            <ImageComponent width="24px" height="24px" src={orderDetailIcon} />
          )}

          <SpanTag
            fontSize={device == "lg" ? "large" : "sm"}
            fontWeight="bold"
            color={theme.greyText}
          >
            <FormattedText value={title} />
          </SpanTag>
        </FlexComp>
        <OrderDetailTravellerStyle>
          {travellerData?.map((item, index) => {
            return (
              <TravellerItem
                alignItems="baseline"
                justifyContent="space-between"
                key={index}
              >
                <FlexComp gap="4px" flexDirection="column" key={index}>
                  <ParaTag
                    fontSize={device == "lg" ? "md" : "sm"}
                    fontWeight="bold"
                  >
                    <FormattedText value={item.name} />
                  </ParaTag>
                  <div>
                    {item?.dob && (
                      <SpanTag
                        fontSize={device == "lg" ? "sm" : "xs"}
                        color={theme.greyText}
                      >
                        &nbsp;
                        <FormattedText
                          value={formatDate(
                            item.dob,
                            DATE_FORMATS["DD/MM/YYYY"]
                          )}
                        />{" "}
                        |
                      </SpanTag>
                    )}
                    <SpanTag
                      fontSize={device == "lg" ? "sm" : "xs"}
                      color={theme.greyText}
                    >
                      &nbsp;
                      <FormattedText value={item.gender} />
                    </SpanTag>
                  </div>
                </FlexComp>
                {device == "lg" && editEnabled && (
                  <AnchorTag
                    handleClick={() => {
                      analytics?.trackOrderDetailsEvents({
                        actionType: "edit_details_clicked",
                      });
                      handleClickEditDetails(index);
                    }}
                  >
                    <ImageComponent
                      width="16px"
                      height="16px"
                      src={
                        "https://tripmoneycmsimgak.mmtcdn.com/img/Edit_Icon_9bd6098b75.svg"
                      }
                    />
                  </AnchorTag>
                )}
                {editTravellerIndex === index && (
                  <EditTraveller
                    index={index}
                    title={editTraveller?.title}
                    travellerDetails={travellerData}
                    travellerAttributes={editTraveller?.travellerAttributes}
                    fullName={item.name}
                    dobValue={item.dob}
                    passportValue={item.passport}
                    genderValue={item.gender}
                    refId={item.refId}
                    goBackTxt={editTraveller?.goBackBtnTxt}
                    updateContactTxt={editTraveller?.updateNowBtnTxt}
                    handleUpdateDetails={(updatedData) =>
                      handleUpdateDetails(updatedData, index)
                    }
                    handleClose={() => setEditTravellerIndex(-1)}
                  />
                )}
              </TravellerItem>
            );
          })}
        </OrderDetailTravellerStyle>
      </OrderDetailCompStyle>
      {device == "sm" && editEnabled && (
        <OrderMobCtaWrap onClick={handleClickEditDetailSheet}>
          <AnchorTag fontWeight="bold">
            <FormattedText value="Edit Traveller Details" />
          </AnchorTag>
          <ImageComponent
            width="16px"
            height="16px"
            src="https://tripmoneycmsimgak.mmtcdn.com/img/blue_Chev_2800e4f975.svg"
          />
        </OrderMobCtaWrap>
      )}
    </div>
  );
};

export default OrderDetailTraveller;
