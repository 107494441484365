import React from "react";

import { ErrorScreen } from "@tm/ui/ui-container";

interface IErrorBoundaryState {
  hasError: boolean;
  error?: any;
}

interface IErrorBoundaryProps {
  onError?: (err: string) => JSX.Element;
  children?: JSX.Element;
}

export class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  override componentDidCatch(error: any) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    //   @TODO: Replace this with Sentry
    //   logErrorToMyService(error, info.componentStack);
    console.error("componentDidCatch", error);
  }

  override render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      const error = this.state.error.getErrorMessage
        ? this.state.error.getErrorMessage()
        : this.state.error;
      return this.props.onError?.(error) || <ErrorScreen error={error} />;
    }

    return this.props.children;
  }
}
