import React, { useEffect, useState } from "react";

import { theme } from "@tm/utility/global";
import {
  FlexComp,
  FormattedText,
  ImageTag,
  SpanTag,
  AnchorTag,
} from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { DATE_FORMATS, convertUnixToDate, formatDate } from "@tm/static/utils";
import { getAnalytics } from "@tm/insurance/analytics";

import { IUpcomingTrip } from "./types";
import { UpcomingTripStyle, UpcomingTripTag } from "./TravelDetails.style";

const analytics = getAnalytics();

const UpcomingTrips = ({
  country,
  travelDuration,
  travellerNum,
  travellerTitle,
  handleUpcomingTripClick,
}: IUpcomingTrip) => {
  const device = useBreakpointV2();

  const [isUpcomingTripSelected, setIsUpcomingTripSelected] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isUpcomingTrip = queryParams.get("isUpcomingTrip");
    if (isUpcomingTrip) {
      setIsUpcomingTripSelected(true);
    }
    analytics?.trackLandingEvents({
      actionType: "upcoming_trips_shown",
    });
  }, []);

  const showFormattedDate = (date?: number) => {
    if (date) {
      const dateValue = convertUnixToDate(date);
      return formatDate(dateValue, DATE_FORMATS["DD MMM YY"]);
    }
    return "";
  };

  return (
    <UpcomingTripStyle
      selected={isUpcomingTripSelected}
      onClick={handleUpcomingTripClick}
    >
      {" "}
      <UpcomingTripTag>
        <SpanTag fontSize="xxs" color={theme.whiteText}>
          UPCOMING TRIP
        </SpanTag>
      </UpcomingTripTag>
      <FlexComp flexDirection="column">
        <SpanTag fontWeight="bold">
          <FormattedText value={country} />
          &nbsp;
        </SpanTag>
        {/* {device == 'sm' && <br />} */}

        <SpanTag fontSize="xs" color={theme.greyText}>
          {` ${showFormattedDate(
            travelDuration?.startDate
          )} - ${showFormattedDate(travelDuration?.endDate)}`}{" "}
          | {travellerNum} <FormattedText value={travellerTitle} />
        </SpanTag>
      </FlexComp>
      <AnchorTag>
        <ImageTag
          src={
            "https://tripmoneycmsimgak.mmtcdn.com/img/Arrow_Right_Blue_448003ff39.svg"
          }
          width="16px"
          height="16px"
        />
      </AnchorTag>
    </UpcomingTripStyle>
  );
};

export default UpcomingTrips;
