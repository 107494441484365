import React from "react";
import { theme } from "@tm/utility/global";
import { H4TagStyle } from "./TextStyles.style";
import { usePartnerContext } from "@tm/static/partnerContext";
type Colors = typeof theme;
type ColorKey = keyof Colors;
interface H4TagProps {
  color?: Colors[ColorKey];
  children?: React.ReactNode;
  margin?: string;
}

const H4Tag: React.FC<H4TagProps> = ({ children, margin, color }) => {
  const context = usePartnerContext();
  return (
    <H4TagStyle
      context={context ? context : undefined}
      txtcolor={color}
      margin={margin}
    >
      {children}
    </H4TagStyle>
  );
};

export default H4Tag;
