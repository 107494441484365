import {
  H5Tag,
  LandingInput,
  ParaTag,
  SpanTag,
  FlexComp,
  LandingButton,
  LandingButtonWhite,
  AnchorTag,
  ShimmerVersion2,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import Shimmer from "../Shimmer/Shimmer";
import {
  PassportNumCompStyle,
  PassportHd,
  PassportItem,
  ButtonWrap,
} from "./PassportNumComp.style";

interface PassportNumCompProps {
  title?: string;
  info?: string;
  travellerData?: {
    name?: string;
    passportNum?: string;
  }[];
  handleAddPassportModal?: () => void;
}

const PassportNumCompShimmer = ({
  title,
  info,
  travellerData,
  handleAddPassportModal,
}: PassportNumCompProps) => {
  return (
    <PassportNumCompStyle>
      <PassportHd>
        <ShimmerVersion2 width="100%" height="28px" margin="0 0 8px" />
        <ShimmerVersion2 width="100%" height="18px" margin="0 0 4px" />
        <ShimmerVersion2 width="315px" height="18px" margin="0 0 4px" />
      </PassportHd>
      {Array.from(
        {
          length: 5,
        },
        (_, index) => (
          <PassportItem key={index}>
            <ShimmerVersion2 width="158px" height="22px" />
            <div>
              <ShimmerVersion2 width="170px" height="18px" margin="0 0 8px" />
              <ShimmerVersion2 width="258px" height="40px" />
            </div>
          </PassportItem>
        )
      )}
    </PassportNumCompStyle>
  );
};

export default PassportNumCompShimmer;
