import { useContext } from "react";
import ReactMarkdown, { Options } from "react-markdown";
import { t } from "@tm/utility";
import { CurrencySEOContext } from "@tm/ui/ui-container";
import { getRequestIdHeaders } from "@tm/ui-widgets";
import { API_ENDPOINT, PROGRAMS } from "../../static/constants/SeoConstants";

export const CurrencySEOMarkdown = ({
  children,
  className,
  ...props
}: Options) => {
  if (!children) return null;
  const { refreshBtn, currencyCode, exchangeRateApi, inrRate, setInrRate } =
    useContext(CurrencySEOContext);
  const handleClick = async () => {
    const prevInrRate = inrRate;
    setInrRate("...");
    setTimeout(async () => {
      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 3000);
        const apiUrl = `${API_ENDPOINT}?currency1={currency_code}&program=${PROGRAMS.FOREX_BMF_MAKEMYTRIP}`;
        const response = await fetch(
          t(apiUrl, { "{currency_code}": currencyCode }),
          {
            signal: controller.signal,
            headers: {
              ...getRequestIdHeaders(),
            },
          }
        );
        clearTimeout(timeoutId);
        const exchangeRateData = await response.json();
        if (exchangeRateData?.success && exchangeRateData.data?.[0]) {
          setInrRate(
            exchangeRateData.data[0].card || exchangeRateData.data[0].currency
          );
        } else {
          setInrRate(prevInrRate);
        }
      } catch (error: any) {
        setInrRate(prevInrRate);
        console.error("Exchange Rate Not Found", error.message);
      }
    }, 1000);
  };
  const RefeshBtn = ({ children, ...props }: any) => (
    <>
      {inrRate === "⚠️" ? (
        <a title="Failed to get Exchange Rate" style={{ cursor: "default" }}>
          {inrRate}
        </a>
      ) : (
        inrRate
      )}{" "}
      <button
        onClick={handleClick}
        className="font16 refreshBtn"
        title="Get latest exchange rate"
        {...props}
      >
        {refreshBtn}
      </button>
    </>
  );
  return children ? (
    <ReactMarkdown
      components={{ code: RefeshBtn }}
      className={className}
      {...props}
    >
      {children}
    </ReactMarkdown>
  ) : null;
};
