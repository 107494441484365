import { useState } from "react";
import styled from "styled-components";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import Modal from "../Modal/Modal";
import LandingButton from "../ui-atoms/LandingButton/LandingButton";
import { CloseIcon } from "../../icons/CloseIcon";
import { useDevice } from "../../../hooks/useDevice";
import useListingPdtLoggerV2 from "../../../hooks/useListingPdtLoggerV2";
import { getEventDetails } from "../../../utils";
import { PDT_EVENTS } from "../../../constants";
import { deviceType } from "../../utils";

interface ForexRatesWrapperProps {
  device: string | null;
}

const ForexRatesWrapper = styled.div<ForexRatesWrapperProps>`
  padding: 8px;
  display: flex;
  align-items: center;
  background: linear-gradient(
    90deg,
    #e0f0ff 33.5%,
    rgba(255, 255, 255, 0) 100%
  );
  color: #1a73e8;
  border-radius: 12px;
  @media (${deviceType.mobile}) {
    border-radius: 0px;
  }
`;

interface PriceHikeWarningProps {
  text: string;
  info: string;
}

const PriceHikeWarning = ({ text, info }: PriceHikeWarningProps) => {
  const [showInfo, setShowInfo] = useState(false);
  const device = useDevice();
  const { sendPdtDataV2 } = useListingPdtLoggerV2();

  const handleInfoClick = () => {
    setShowInfo(true);
    sendPdtDataV2({
      event_detail: getEventDetails({
        event_name: PDT_EVENTS.RATE_INCREASE_NUDGE_INFO_CLICKED,
      }),
    });
  };

  const handleModalClose = () => {
    setShowInfo(false);
  };

  if (!text || !info) {
    return null;
  }

  return (
    <>
      <ForexRatesWrapper device={device}>
        <ImageTag
          src="https://tripmoneycmsimgak.mmtcdn.com/img/price_hike_6cb035f6a3.svg"
          margin="0px 8px 0px 0px"
        />
        <SpanTag fontSize="xs" color="#005499">
          {text}
        </SpanTag>
        <ImageTag
          src="https://tripmoneycmsimgak.mmtcdn.com/img/Info_0a25f3f0f9.svg"
          showCursorPointer
          margin="0px 0px 0px 8px"
          onClick={handleInfoClick}
        />
      </ForexRatesWrapper>
      {showInfo && (
        <Modal
          modalWidth={480}
          closeButton={<CloseIcon />}
          onModalClose={handleModalClose}
          modalHeading={`Forex Rates`}
          modalSubHeading={``}
          modalHeadingCtrMargin="0px 0px 20px"
          modalType={device === "lg" ? "centered" : "bottomSheet"}
        >
          {info}
          <LandingButton
            onClick={handleModalClose}
            customTitle="OK"
            customWidth="100%"
            margin="24px 0 0 0"
          />
        </Modal>
      )}
    </>
  );
};

export default PriceHikeWarning;
