import styled from "styled-components";
import { theme } from "@tm/utility/global";

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Input = styled.input<{ hasError?: boolean }>`
  width: 35px;
  padding: 5px 0px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  border: 1px solid
    ${({ hasError }) => (hasError ? theme.redText2 : theme.greyText4)};
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: ${theme.blueText};
  }
`;
