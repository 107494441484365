import { FlexComp, ImageComponent, SpanTag, AnchorTag } from "@tm/static";
import { OrderMobCtaWrap, OrderMobAmtWrap } from "./OrderDetailComp.style";
import { theme } from "@tm/utility/global";
import BlueChev from "../../icons/blueChev.svg";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import PeopleGroupIcon from "../../icons/peopleGroupIcon.svg";

interface OrderDetailMobPriceProp {
  handlePayment?: () => void;
  handleDnldInvoice?: () => void;
}

const OrderDetailMobPriceComp = ({
  handlePayment,
  handleDnldInvoice,
}: OrderDetailMobPriceProp) => {
  return (
    <div>
      <OrderMobAmtWrap>
        <FlexComp alignItems="center" gap="8px">
          <ImageComponent
            width="24px"
            height="24px"
            src="https://tripmoneycmsimgak.mmtcdn.com/img/people_Group_Icon_d97975a5cb.svg"
          />
          <SpanTag fontSize="sm" fontWeight="bold" color={theme.greyText}>
            <FormattedText value={"Total Price"} />
          </SpanTag>
        </FlexComp>
        <SpanTag fontSize="sm" fontWeight="bold" color={theme.greyText}>
          <FormattedText value={"₹ 600"} />
        </SpanTag>
      </OrderMobAmtWrap>
      <OrderMobCtaWrap>
        <AnchorTag handleClick={handlePayment} fontWeight="bold">
          <FormattedText value="View Payment Breakup" />
        </AnchorTag>
        <ImageComponent
          width="16px"
          height="16px"
          src="https://tripmoneycmsimgak.mmtcdn.com/img/blue_Chev_2800e4f975.svg"
        />
      </OrderMobCtaWrap>
      <OrderMobCtaWrap>
        <AnchorTag handleClick={handleDnldInvoice} fontWeight="bold">
          <FormattedText value="Download Invoice" />
        </AnchorTag>
        <ImageComponent
          width="16px"
          height="16px"
          src="https://tripmoneycmsimgak.mmtcdn.com/img/blue_Chev_2800e4f975.svg"
        />
      </OrderMobCtaWrap>
    </div>
  );
};

export default OrderDetailMobPriceComp;
