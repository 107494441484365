import { deviceType } from "@tm/static/utils";
import styled from "styled-components";

interface ModalWrapperProps {
  modalWidth?: number;
  padding?: string;
  modalType?: "fullPage" | "centered" | "bottomSheet";
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
  overflow: hidden;
  width: ${(props) => (props.modalWidth ? `${props.modalWidth}px` : "420px")};
  padding: ${(props) =>
    props.padding ? `${props.padding}` : "20px 30px 30px 30px"};
  background-color: #fff;
  border-radius: 12px;
  position: fixed;
  max-height: 550px;
  overflow-y: auto;
  z-index: 11;
  ${(props) =>
    props.modalType === "centered" &&
    `
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    `}
  ${(props) =>
    props.modalType === "bottomSheet" &&
    `
        left: 0;
        bottom: 0;
        width: 100%;
        border-radius: 20px 20px 0 0;
        animation: modalAnim 0.6s ;
    @keyframes modalAnim {
        from{bottom:-600px;}
        to{bottom:0;}
    }
    `}
    ${(props) =>
    props.modalType === "fullPage" &&
    `
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        
        animation: fullModalAnim 0.8s;
        @keyframes fullModalAnim {
            from{top:${window.innerHeight}px;}
            to{top:0;}
        }
    `}
`;
export const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
`;
