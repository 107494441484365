import { BaseStyles, FontStyles } from "../CurrencySEOCommonUI";
import CurrencyDocument from "./CurrencyDocument";
import { CurrencyConverterPageStyle } from "./CurrencyConverterPage.styles";
import Header from "./Header";
import Hero from "./Hero";
import Introduction from "./Introduction";
import HowToConvert from "./HowToConvert";
import Benefits from "./Benefits";
import OtherServices from "./OtherServices";
import PopularDenomitations from "./PopularDenominations";
import CurrencyInformation from "./CurrencyInformation";
import { CurrencyFooter } from "../CurrencyPageTemplate/CurrencyFooter";
import OtherConversions from "./OtherConversions";
import Faqs from "./Faqs";
import ConvertOnline from "./ConvertOnline";
import ForexProduct from "./ForexProducts";
import { useEffect } from "react";
import { PAGE_NAMES, trackSeoPageLoad } from "@tm/utility/analytics";
import Banner from "../CurrencyRatePageTemplate/Banner";

export const CurrencyConverterPageTemplate = ({
  id,
  currencyData,
  inrExchangeRate,
  currCodeToCurrRate,
  switchInrExchnageRate,
  templateType = "",
}: any) => {
  useEffect(() => {
    trackSeoPageLoad({
      pageName: PAGE_NAMES["SEO_CURRENCY_CONVERTER"]?.replace(
        "NAME",
        `${currencyData?.curr_code}_to_INR`
      ),
      currency: currencyData?.curr_code,
      denomination: currencyData?.heroCurrencyDenominationVal,
    });
  }, []);
  return (
    <>
      <FontStyles />
      <BaseStyles />
      <CurrencyConverterPageStyle />

      <CurrencyDocument currencyData={currencyData} />
      <>
        <Header currencyData={currencyData} />
        <Hero
          currencyData={currencyData}
          inrExchangeRate={inrExchangeRate}
          currCodeToCurrRate={currCodeToCurrRate}
          switchInrExchnageRate={switchInrExchnageRate}
          breadcrumb={currencyData?.breadcrumb}
          templateType={templateType}
        />

        <div className="pageContainer">
          <ForexProduct
            currencyData={currencyData}
            pageName={PAGE_NAMES["SEO_CURRENCY_CONVERTER"]?.replace(
              "NAME",
              `${currencyData?.curr_code}_to_INR`
            )}
            templateType={templateType}
          />
          <div className="pageContent">
            <Banner currencyData={currencyData} />
            <Introduction currencyData={currencyData} />
            <ConvertOnline currencyData={currencyData} />
            <HowToConvert currencyData={currencyData} />
            <Benefits currencyData={currencyData} />
            <OtherServices currencyData={currencyData} />
            <PopularDenomitations
              currencyData={currencyData}
              inrExchageRate={inrExchangeRate}
            />
            <CurrencyInformation currencyData={currencyData} />
            <OtherConversions currencyData={currencyData} />
            <Faqs currencyData={currencyData} />
          </div>
        </div>

        {currencyData?.footer ? (
          <CurrencyFooter footer={currencyData?.footer} />
        ) : null}
      </>
    </>
  );
};
