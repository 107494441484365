import React from "react";
import { theme } from "@tm/utility/global";

import { ParaTagStyle } from "./TextStyles.style";
import { usePartnerContext } from "@tm/static/partnerContext";
type Colors = typeof theme;
type ColorKey = keyof Colors;
interface ParaTagProps {
  color?: Colors[ColorKey];
  children?: React.ReactNode;
  margin?: string;
  fontSize?: "xxs" | "xs" | "sm" | "md" | "large" | "xlarge" | "xxlarge";
  fontWeight?: "regular" | "bold" | "black";
  textAlign?: "center" | "left" | "right";
  lineHeight?: string;
  dangerousText?: string;
  textTransform?: "uppercase" | "lowercase";
  style?: React.CSSProperties;
}

const ParaTag: React.FC<ParaTagProps> = ({
  children,
  fontSize = "sm",
  fontWeight = "regular",
  color = "#000000",
  margin,
  textAlign,
  lineHeight,
  dangerousText,
  textTransform,
  style,
}) => {
  const context = usePartnerContext();
  return dangerousText ? (
    <ParaTagStyle
      txtfontSize={fontSize}
      txtfontWeight={fontWeight}
      txtcolor={color}
      margin={margin}
      textAlign={textAlign}
      lineHeight={lineHeight}
      dangerouslySetInnerHTML={{ __html: dangerousText }}
      textTransform={textTransform}
      context={context ? context : undefined}
      style={style}
    />
  ) : (
    <ParaTagStyle
      txtfontSize={fontSize}
      txtfontWeight={fontWeight}
      txtcolor={color}
      margin={margin}
      textAlign={textAlign}
      lineHeight={lineHeight}
      textTransform={textTransform}
      context={context ? context : undefined}
      style={style}
    >
      {children}
    </ParaTagStyle>
  );
};

export default ParaTag;
