import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { DateRange } from "react-day-picker";

import { useBreakpointV2 } from "@tm/ui-widgets";
import {
  SpanTag,
  DatePickerComp,
  RangeDatePicker,
  ReactPortalComp,
} from "@tm/static";
import { getAnalytics } from "@tm/insurance/analytics";
import { addDaysToDate, replaceHistory } from "@tm/insurance/utils";
import { TRAVELLER_NUMBER_CONSTANTS } from "@tm/insurance/constants";

import TravelDateWiget from "./TravelDateWiget";
import TravellerCounterWiget from "./TravellerCounterWiget";
import TravellerCountModal from "./TravellerCountModal";
import { TextNodeType } from "../FormattedText/FormattedText";
import { convertDateToUnix, convertUnixToDate } from "../../utils";

import {
  TravellingToWidgetStyle,
  WidgetList,
  ListItem,
} from "./TravellingToWidget.style";

const analytics = getAnalytics();
interface TravellingToWidgetProps {
  travelPurposeTxt?: string;
  travCountTitle?: TextNodeType | string;
  travPurpTitle?: string;
  travDateTitle?: TextNodeType;
  btnDisabledState?: boolean;
  calendarDatesLimit: {
    endDateErrorMessage: string;
    insPurchaseDateLimitInDays: number;
    insPurchaseEndDateLimitInDays: number;
    insPurchaseStartDateLimitInDays: number;
    insPurchaseStartDateMinLimitInDays: number;
    startDateErrorMessage: string;
    endLesserThanStartErrorMessage: string;
  };
  setCountValue: Dispatch<SetStateAction<number>>;
  setCalenderValue: Dispatch<SetStateAction<undefined | DateRange>>;
  calenderRef?: HTMLDivElement;
  upcomingTravellerNum?: number;
  upcomingFromDate?: number;
  upcomingToDate?: number;
}

const TravellingToWidget = ({
  travelPurposeTxt,
  travCountTitle,
  travPurpTitle,
  travDateTitle,
  btnDisabledState,
  setCountValue,
  calendarDatesLimit,
  setCalenderValue,
  calenderRef,
  upcomingTravellerNum,
  upcomingFromDate,
  upcomingToDate,
}: TravellingToWidgetProps) => {
  const defaultSelected: DateRange = {
    from: new Date(),
    to: new Date(Date.now() + 3600 * 1000 * 24),
  };

  const {
    endDateErrorMessage,
    insPurchaseDateLimitInDays, //total no. of allowed days from start date (for end date)
    insPurchaseEndDateLimitInDays, //max no. of allowed days from start date (for end date)
    insPurchaseStartDateLimitInDays, //max no. of allowed days from current date (for start date)
    insPurchaseStartDateMinLimitInDays, //min no. of days from current date (for start date)
    startDateErrorMessage,
  } = calendarDatesLimit;

  const maxEndDate = addDaysToDate(new Date(), insPurchaseDateLimitInDays);

  const device = useBreakpointV2();

  const ref = useRef<HTMLDivElement | null>(null);
  const divRefs = useRef<((instance: HTMLDivElement | null) => void)[]>([]);

  const [captureCount, setCaptureCount] = useState(1);
  const [dateTab, setDateTab] = useState(0);
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<Date>();
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const prevFocusedIndexRef = useRef(focusedIndex);

  const handleClickOutside = (event: Event) => {
    if (ref?.current && !ref?.current?.contains(event.target as Node)) {
      setFocusedIndex(-1);
    }
  };

  const addTravellerNumber = (travNumber: number) => {
    if (travNumber > TRAVELLER_NUMBER_CONSTANTS.MAX_TRAVELLERS) {
      setCaptureCount(TRAVELLER_NUMBER_CONSTANTS.MIN_TRAVELLERS);
      replaceHistory(
        "travellers",
        TRAVELLER_NUMBER_CONSTANTS.MIN_TRAVELLERS.toString()
      );
    } else {
      setCaptureCount(travNumber);
      replaceHistory("travellers", travNumber.toString());
    }
  };

  const addTravellerDates = (travStartDate: number, travEndDate: number) => {
    const tempFromDate = convertUnixToDate(travStartDate);
    const tempToDate = convertUnixToDate(travEndDate);
    replaceHistory("startDate", travStartDate.toString());
    replaceHistory("endDate", travEndDate.toString());

    setRange({ from: tempFromDate, to: tempToDate });
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const trav = queryParameters.get("travellers");
    const fromDate = queryParameters.get("startDate");
    const toDate = queryParameters.get("endDate");
    if (trav && !Number.isNaN(parseInt(trav))) {
      addTravellerNumber(parseInt(trav));
    } else if (upcomingTravellerNum) {
      addTravellerNumber(upcomingTravellerNum);
    }

    if (fromDate && toDate) {
      addTravellerDates(parseInt(fromDate), parseInt(toDate));
    } else if (upcomingFromDate && upcomingToDate) {
      addTravellerDates(upcomingFromDate, upcomingToDate);
    }
  }, []);

  useEffect(() => {
    setEndDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  }, []);

  useEffect(() => {
    if (captureCount >= TRAVELLER_NUMBER_CONSTANTS.MIN_TRAVELLERS) {
      setCountValue(captureCount);
    }
  }, [captureCount]);

  useEffect(() => {
    if (range) {
      setCalenderValue(range);
    }
  }, [range]);

  useEffect(() => {
    const datePickerElement = document.getElementById("datePickerContainer");

    if (datePickerElement) {
      const rect = datePickerElement.getBoundingClientRect();
      const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (prevFocusedIndexRef.current === 0 && focusedIndex !== 0) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (focusedIndex === 0 && !isInView) {
        const offset = rect.top + rect.height / 2 - window.innerHeight / 2;
        window.scrollTo({ top: offset, behavior: "smooth" });
      }
    }

    prevFocusedIndexRef.current = focusedIndex;
  }, [focusedIndex]);

  useEffect(() => {
    if (range?.to === undefined && range?.from) {
      let tempToDate = new Date(range?.from);
      tempToDate.setDate(tempToDate.getDate() + 1);

      replaceHistory("endDate", convertDateToUnix(tempToDate).toString());
    }
  }, [range?.from]);

  const handleClick = (index: number) => {
    index == focusedIndex ? setFocusedIndex(-1) : setFocusedIndex(index);
  };

  const handleDayClick = (selectedDate: Date) => {
    if (dateTab === 0) {
      const maxStartDate = addDaysToDate(
        new Date(),
        insPurchaseStartDateLimitInDays
      );

      if (maxStartDate && maxStartDate < selectedDate) {
        // alert(startDateErrorMessage);
        setShowToast(true);
        setToastMessage(startDateErrorMessage);
      } else if (range?.from && range?.to) {
        setRange({ from: selectedDate, to: undefined });
        replaceHistory("startDate", convertDateToUnix(selectedDate).toString());
        setDateTab(1);
        setShowToast(false);
      }
    } else if (dateTab === 1) {
      if (range?.from) {
        const fromDate = new Date(range?.from);
        const maxEndDate = addDaysToDate(
          fromDate,
          insPurchaseEndDateLimitInDays
        );
        if (range?.from > selectedDate) {
          setRange({ from: selectedDate, to: undefined });
          setShowToast(false);
        } else if (maxEndDate && maxEndDate <= selectedDate) {
          setShowToast(true);
          setToastMessage(endDateErrorMessage);
        } else {
          setRange({ from: range?.from, to: selectedDate });
          setShowToast(false);
          setDateTab(0);
          if (device == "lg") {
            setFocusedIndex(1);
          }
          replaceHistory("endDate", convertDateToUnix(selectedDate).toString());
          analytics?.trackLandingEvents({
            actionType: "travel_date_selected",
            payload: {
              departure: range.from
                ?.toLocaleDateString("en-GB")
                ?.replace(/\//gi, ":"),
              arrival:
                range.to?.toLocaleDateString("en-GB")?.replace(/\//gi, ":") ||
                "",
            },
          });
        }
      }
    }
  };

  const handleCaptureCountIncr = () => {
    captureCount < TRAVELLER_NUMBER_CONSTANTS.MAX_TRAVELLERS
      ? setCaptureCount(captureCount + 1)
      : setCaptureCount(TRAVELLER_NUMBER_CONSTANTS.MAX_TRAVELLERS);

    replaceHistory("travellers", (captureCount + 1).toString());
  };
  const handleCaptureCountDecr = () => {
    captureCount > TRAVELLER_NUMBER_CONSTANTS.MIN_TRAVELLERS
      ? setCaptureCount(captureCount - 1)
      : setCaptureCount(TRAVELLER_NUMBER_CONSTANTS.MIN_TRAVELLERS);

    // if (device == 'lg') {
    //     setFocusedIndex(2);
    // }
    replaceHistory("travellers", (captureCount - 1).toString());
  };

  const handleSubmit = (chkIn: Date | undefined, chkOut: Date | undefined) => {
    chkIn && setStartDate(chkIn);
    chkOut && setEndDate(chkOut);

    analytics?.trackLandingEvents({
      actionType: "travel_date_selected",
      payload: {
        departure:
          chkIn?.toLocaleDateString("en-GB")?.replace(/\//gi, ":") || "",
        arrival:
          chkOut?.toLocaleDateString("en-GB")?.replace(/\//gi, ":") || "",
      },
    });
    setFocusedIndex(-1);
  };

  const handleDayClick2 = (dateSelected: Date) => {
    if (range) {
      if (!startDate) {
        setStartDate(dateSelected);
      } else if (!endDate) {
        if (dateSelected && dateSelected >= startDate) {
          setEndDate(dateSelected);
        } else {
          setStartDate(dateSelected);
          setEndDate(undefined);
        }
      } else {
        setStartDate(dateSelected);
        setEndDate(undefined);
      }
    } else {
      setStartDate(dateSelected);
    }
  };

  return (
    <TravellingToWidgetStyle ref={device == "lg" ? ref : null}>
      <TravelDateWiget
        ref={divRefs.current[0]}
        showTravDate={focusedIndex == 0}
        handleTravDate={() => handleClick(0)}
        travDateTitle={travDateTitle}
        startDate={range?.from}
        endDate={range?.to}
      />
      <TravellerCounterWiget
        // ref={divRefs.current[1]}
        // showTravCount={focusedIndex == 1}
        captureCount={captureCount}
        travCountTitle={travCountTitle}
        handleCaptureCountIncr={handleCaptureCountIncr}
        handleCaptureCountDecr={handleCaptureCountDecr}
      />

      {/* TODO: NOT IN CURRENT SCOPE */}
      {/* <TravelPurposeWiget
                ref={(el: HTMLDivElement | null) => (divRefs.current[2] = el)}
                showTravPurpose={focusedIndex == 2}
                handleTravPurpose={() => handleClick(2)}
                capturePurpose={capturePurpose}
                travPurpTitle={travPurpTitle}
            /> */}

      {focusedIndex == 0 && device == "lg" && (
        <DatePickerComp
          startDateLabel="Start Date"
          endDateLabel="End Date"
          handleDayClick={handleDayClick}
          dateTab={dateTab}
          selected={range}
          from={range?.from}
          to={range?.to}
          maxEndDate={maxEndDate}
          toastMessage={toastMessage}
          showToast={showToast}
        />
      )}
      {/* {console.log(range?.from)} */}
      {focusedIndex == 0 && device == "sm" && (
        <ReactPortalComp>
          <RangeDatePicker
            calendarFooter={true}
            calendarHeader={true}
            onBackPress={() => handleClick(0)}
            onSubmitButtonPress={handleSubmit}
            range={true}
            calenderRef={calenderRef}
            from={range?.from}
            to={range?.to}
            toastMessage={toastMessage}
            showToast={showToast}
            selected={range}
            handleDayClick={handleDayClick}
          />
        </ReactPortalComp>
      )}
      {/* {focusedIndex == 1 && device == 'lg' && (
                <WidgetList travelCount>
                    {Array.from({ length: 20 }, (__, index) => (
                        <ListItem
                            key={index}
                            onClick={() => handleCaptureCount(index)}
                        >
                            <SpanTag fontSize="xs">
                                {index < 9 ? '0' + (index + 1) : index + 1}
                            </SpanTag>
                        </ListItem>
                    ))}
                </WidgetList>
            )}
            {focusedIndex == 1 && device == 'sm' && (
                <TravellerCountModal
                    captureCount={captureCount}
                    handleCaptureCount={handleCaptureCount}
                    handleClick={() => handleClick(1)}
                />
            )} */}
      {/* {focusedIndex == 2 && (
                <WidgetList width="310px">
                    <ListItem>
                        <ParaTag>{travelPurposeTxt}</ParaTag>
                    </ListItem>
                    {purposeList &&
                        purposeList.map((item, index) => {
                            return (
                                <ListItem
                                    key={index}
                                    onClick={() =>
                                        handleCapturePurpose(item.info)
                                    }
                                >
                                    {item.icon && (
                                        <ImageComponent src={item.icon} />
                                    )}
                                    <SpanTag>{item.info}</SpanTag>
                                </ListItem>
                            );
                        })}
                </WidgetList>
            )} */}
      {/* TODO */}
    </TravellingToWidgetStyle>
  );
};

export default TravellingToWidget;
