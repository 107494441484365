import { useBreakpointV2 } from "@tm/ui-widgets";
//import { Header as MMTHeader } from 'pwa-mmt-ui-header/ssr/header';
import { LandingHeaderMobile } from "..";
import "pwa-mmt-ui-header/ssr/header.css";
import dynamic from "next/dynamic";
import Router from "next/router";
import { PARTNER } from "@tm/insurance/constants";
import { navigationActions } from "@tm/insurance/navigation";

const DynamicMMTHeader = dynamic<any>(
  () => import("pwa-mmt-ui-header/ssr/header").then((module) => module.Header),
  { ssr: true }
);
export interface MMTHeaderProps {
  type?: "singleHeader" | "dualHeader";
  forceLogin?: boolean;
  activeModal?: "LOGIN";
  showMyBizFormSwitcher?: boolean;
  profile?: "PERSONAL" | "BUSINESS";
  displayLanguageDropdown?: boolean;
  selectedTabName?:
    | "Flights"
    | "Hotels"
    | "Holidays"
    | "Buses"
    | "Cabs"
    | "Trains"
    | "Giftcards"
    | "Travel Insurance";
  displayCurrencyDropdown?: boolean;
  showMoreButton?: boolean;
  showLoginPersuasionSlider?: boolean;
  multiLingualData?: {
    "header.login.signup.for.best.prices"?: string;
  };
  onLogin?: () => void;
  onLoginFlowComplete?: () => void;
  onModalChange?: (modal: "LOGIN" | undefined) => void;
}

interface MMTAuthProps {
  forceLogin?: boolean;
  headerProps?: MMTHeaderProps;
  mobileTitle?: string;
  myOrders?: boolean;
  goToMyOrders?: () => void;
  displayWhiteHeader?: boolean;
}

export function MMTInsuranceHeader({
  headerProps = {},
  mobileTitle = "",
  myOrders,
  goToMyOrders,
  displayWhiteHeader,
}: MMTAuthProps) {
  const device = useBreakpointV2();
  let headerPropsWithDefaults: MMTHeaderProps = {
    type: "singleHeader",
    forceLogin: false,
    activeModal: undefined,
    showMyBizFormSwitcher: true,
    profile: "PERSONAL",
    displayLanguageDropdown: false,
    selectedTabName: "Travel Insurance",
    displayCurrencyDropdown: false,
    onLogin: () => Router.reload(),
    onLoginFlowComplete: () => void 0,
    onModalChange: () => void 0,
    showMoreButton: true,
    showLoginPersuasionSlider: false,
    multiLingualData: {
      "header.login.signup.for.best.prices":
        "Please login to purchase your travel insurance",
    },
    ...headerProps,
  };
  if (headerPropsWithDefaults.type === "dualHeader") {
    headerPropsWithDefaults.showMoreButton = false;
  }
  if (headerPropsWithDefaults?.forceLogin) {
    headerPropsWithDefaults = {
      ...headerPropsWithDefaults,
      activeModal: "LOGIN",
    };
    return <DynamicMMTHeader {...headerPropsWithDefaults} />;
  }
  return device === "lg" ? (
    <DynamicMMTHeader {...headerPropsWithDefaults} />
  ) : (
    <LandingHeaderMobile
      headerTitle={mobileTitle}
      myOrders={myOrders}
      goToMyOrders={goToMyOrders}
      displayWhiteHeader={displayWhiteHeader}
      partnerType={PARTNER.MMT}
      insuranceHeader
    ></LandingHeaderMobile>
  );
}
