import { FlexComp, ParaTag, AnchorTag } from "@tm/static";

import { CityListText } from "./PincodeCities.style";

import { useState } from "react";
import { useBreakpoint } from "@tm/ui/hooks";

interface PincodeCitiesComp {
  citiesList?: string[];
  title?: string;
  showMoreBtnTxt?: string;
}
const PincodeCities = ({
  citiesList,
  title,
  showMoreBtnTxt,
}: PincodeCitiesComp) => {
  const device = useBreakpoint();
  const [showAllCities, setShowAllCities] = useState(false);

  const handleShowMore = () => {
    setShowAllCities(true);
  };
  const cityList = citiesList ? citiesList.join(" | ") : [];
  const characters = device === "lg" ? 100 : 85;
  const displayText = showAllCities
    ? cityList
    : `${cityList?.slice(0, characters)}... `;

  return (
    <FlexComp margin="12px 0px 24px 0px" flexDirection="column">
      {title && (
        <ParaTag fontSize="xs" fontWeight="bold" margin={"0px 0px 4px 0px"}>
          {title}
        </ParaTag>
      )}

      <div>
        <CityListText>{displayText}</CityListText>
        {!showAllCities && (
          <AnchorTag
            fontWeight="bold"
            fontSize="xs"
            handleClick={handleShowMore}
          >
            {showMoreBtnTxt}
          </AnchorTag>
        )}
      </div>
    </FlexComp>
  );
};

export default PincodeCities;
