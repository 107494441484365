import React from "react";

import { CliptextStyleComp } from "./TextStyles.style";

interface CliptextStyle {
  children?: React.ReactNode;
  margin?: string;
}

const CliptextStyle: React.FC<CliptextStyle> = ({ children, margin }) => {
  return <CliptextStyleComp margin={margin}>{children}</CliptextStyleComp>;
};

export default CliptextStyle;
