import React from "react";

import {
  AnchorTag,
  FlexComp,
  ImageComponent,
  SpanTag,
  FormattedText,
} from "@tm/static";
import {
  OrderRightStyle,
  OrderRightHd,
  OrderRightCntnt,
} from "./OrderDetailRightComp.style";
import { TextNodeType } from "../FormattedText/FormattedText";

interface OrderInsuranceProp {
  title?: TextNodeType;
  info?: string | TextNodeType;
  forInsurance?: {
    title?: string | TextNodeType;
    sectionType?: string;
    icon?: string;
  }[];
  handleClickInsurance?: any;
}

const OrderInsurance = ({
  title,
  info,
  forInsurance,
  handleClickInsurance,
}: OrderInsuranceProp) => {
  return (
    <OrderRightStyle>
      <OrderRightHd>
        {title && (
          <SpanTag fontWeight="bold" fontSize="md">
            <FormattedText value={title} />
          </SpanTag>
        )}
        {info && (
          <SpanTag fontWeight="bold" fontSize="md">
            <FormattedText value={info} />
          </SpanTag>
        )}
      </OrderRightHd>
      <OrderRightCntnt>
        <FlexComp flexDirection="column" gap="14px">
          {forInsurance?.map((item, index) => {
            return (
              <FlexComp
                alignItems="center"
                key={index}
                gap="16px"
                handleClick={() => handleClickInsurance(item)}
              >
                {item.icon && (
                  <ImageComponent width="20px" height="20px" src={item.icon} />
                )}
                {item.title && (
                  <AnchorTag fontWeight="bold">
                    <FormattedText value={item.title} />
                  </AnchorTag>
                )}
              </FlexComp>
            );
          })}
        </FlexComp>
      </OrderRightCntnt>
    </OrderRightStyle>
  );
};

export default OrderInsurance;
