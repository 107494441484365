export const cityCSSPosition = {
  "new delhi": [733, 632],
  gurugram: [733, 632],
  noida: [733, 632],
  ghaziabad: [733, 632],
  lucknow: [816, 677],
  mumbai: [636, 865],
  bengaluru: [741, 1006],
  hyderabad: [761, 904],
  chennai: [800, 1003],
  kolkata: [981, 781],
  ahmedabad: [629, 771],
  pune: [656, 877],
  cochin: [713, 1077],
  goa: [660, 951],
  jaipur: [701, 675],
  guwahati: [1055, 694],
  chandigarh: [723, 580],
  patna: [912, 708],
  trivandrum: [726, 1106],
  varanasi: [859, 714],
  leh: [742, 490],
  allahabad: [837, 709],
  kochi: [714, 1078],
  dubai: [234, 717],
  sharjah: [234, 717],
  sydney: [2396, 2105],
  "abu dhabi": [234, 717],
  bhopal: [734, 768],
  vijaywada: [805, 929],
  ranchi: [912, 768],
  nagpur: [773, 819],
  haldwani: [781, 623],
  kozhikode: [698, 1050],
  "north 24 parganas": [992, 784],
  salem: [750, 1039],
  raipur: [829, 815],
  coimbatore: [726, 1058],
  surat: [634, 823],
  indore: [702, 784],
  meerut: [742, 631],
  jhajjar: [718, 635],
  jetpur: [583, 807],
  bhubaneswar: [928, 843],
  faridabad: [734, 643],
  udaipur: [650, 737],
  "dakshina kannada": [686, 1015],
  "gautam buddha nagar": [738, 643],
  "kamrup metropolitan": [1063, 698],
  kelambakkam: [793, 1015],
  visakhapatnam: [865, 901],
  madurai: [746, 1078],
  quilon: [718, 1102],
  vadodara: [635, 792],
  thane: [635, 867],
  guntur: [802, 933],
  thiruvananthapuram: [722, 1110],
  "nong prue sub": [1266, 1016],
  jabalpur: [790, 769],
  kannur: [690, 1035],
  darjeeling: [976, 675],
  "pimpri chinchwad": [655, 878],
  ramachandrapuram: [837, 922],
  bijnor: [754, 620],
  munger: [940, 718],
  dhampur: [762, 620],
  thiruvallur: [790, 1008],
  bangkok: [1254, 992],
  "ho chi minh city": [1397, 1059],
  phuket: [1210, 1125],
  badung: [1583, 1494],
  amsterdam: [-901, -75],
  denpasar: [1587, 1498],
  "da nang": [1429, 941],
  chonburi: [1270, 1000],
  krabi: [1222, 1118],
  gianyar: [1587, 1494],
  singapore: [1329, 1275],
  sepang: [1282, 1243],
  "ha noi": [1381, 820],
  "nong prue": [1262, 996],
  "patong kathu": [1206, 1125],
  "ao nang": [1218, 1122],
  "borough of spelthorne": [-1028, -43],
  doha: [151, 722],
  frankfurt: [-817, 4],
  badgam: [675, 494],
  firozpur: [675, 573],
  tirupati: [786, 996],
  avadi: [794, 1004],
  chittoor: [798, 1008],
  amritsar: [679, 557],
  vijayawada: [810, 929],
  gorakhpur: [869, 682],
  jalandhar: [694, 565],
  dibrugarh: [1131, 663],
  gajuwaka: [865, 898],
  "port blair": [1079, 1043],
  rudrapur: [782, 627],
  warangal: [782, 894],
  barddhaman: [972, 769],
  panchkula: [722, 584],
  tiruchirappalli: [766, 1043],
  dimapur: [1107, 702],
  "hoan kiem": [1377, 824],
  jakarta: [1401, 1439],
  bonn: [-853, -16],
  darbhanga: [925, 698],
  rajkot: [587, 792],
  agartala: [1044, 753],
  renon: [1587, 1498],
  "lombok utara": [1611, 1490],
  dammam: [119, 690],
  kancheepuram: [790, 1020],
  guduvancheri: [790, 1012],
  imphal: [1107, 733],
  alwar: [718, 663],
  "madhubani district": [929, 694],
  himatnagar: [635, 761],
  chengalpattu: [794, 1020],
  dehradun: [750, 592],
  chakkarpur: [782, 682],
  bathinda: [679, 596],
  jammu: [679, 529],
  malappuram: [702, 1055],
  kolhapur: [663, 925],
  "sri ganganagar": [655, 604],
  jagtial: [770, 878],
  mukandpurct: [702, 573],
  kota: [702, 722],
  jodhpur: [635, 698],
  "samut prakan province": [1262, 996],
  berlin: [-710, -82],
  cairo: [-290, 565],
  muscat: [306, 761],
  "kiên giang": [1361, 1086],
  gampaha: [794, 1145],
  muharraq: [131, 698],
  bali: [1591, 1490],
  jeddah: [-123, 812],
  farwaniya: [71, 620],
  malé: [655, 1216],
  turkey: [-206, 361],
  islands: [1556, 788],
  ahmednagar: [675, 871],
  "hội an": [1437, 627],
};
