import * as React from "react";
interface starProp {
  width?: number;
  height?: number;
}
const StarFull = ({ width = 24, height = 23, ...props }: starProp) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9415 18.9704L4.92475 22.7389L6.26482 14.7571L0.588172 9.10436L8.43311 7.93983L11.9415 0.677734L15.4498 7.93983L23.2948 9.10436L17.6181 14.7571L18.9582 22.7389L11.9415 18.9704Z"
      fill="#E2B634"
    />
  </svg>
);
export { StarFull };
