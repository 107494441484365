import { ImageTag, SpanTag } from "@tm/static";
import {
  DeliveryPersuasionComp,
  DeliveryPersuasionStyle,
  Cta,
  ImgWpr,
  DeliveryLocationStyle,
} from "./DeliveryPersuasion.style";

import { ArrowIcon, ChevronDownIcon } from "@tm/static/icons";
import { theme } from "@tm/utility/global";

import { useBreakpoint } from "@tm/ui/hooks";

interface deliveryPersuasionProps {
  text?: string;
  fontSize?: "sm" | "large" | "xs" | "xxs" | "md" | "xlarge" | undefined;
  editLocationHandler?: () => void;
  margin?: string;
  padding?: string;
  bgColor?: string;
  upArrow?: string;
  checkPincode?: boolean;
  checkPincodeHandler?: () => void;
  checkPincodeLabel?: string;
  arrowIcon?: string;
  ctaText?: string;
  showIcon?: boolean;
  ctaMargin?: string;
  defaultIconComp?: React.ReactNode;
}

const DeliveryPersuasion = ({
  margin,
  fontSize = "sm",
  padding = "8px 12px",
  bgColor = `linear-gradient\(90.01deg, #deedff 0%, rgba(230, 241, 255, 0) 108.89%\)`,
  text,
  editLocationHandler,
  upArrow,
  showIcon = true,
  checkPincode = false,
  checkPincodeLabel,
  checkPincodeHandler,
  arrowIcon,
  ctaText,
  ctaMargin,
  defaultIconComp,
}: deliveryPersuasionProps) => {
  const device = useBreakpoint();

  return (
    <>
      {checkPincode ? (
        <>
          {text && (
            <DeliveryPersuasionComp
              padding={"Opx"}
              margin={margin}
              checkPincode={checkPincode}
            >
              <DeliveryPersuasionStyle
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </DeliveryPersuasionComp>
          )}

          <Cta
            margin={
              ctaMargin
                ? ctaMargin
                : checkPincode
                ? device === "lg"
                  ? "16px 0px 0px 0px"
                  : "8px 0px 0px 0px"
                : "0px"
            }
            onClick={checkPincodeHandler}
          >
            <SpanTag
              fontWeight="bold"
              fontSize={fontSize}
              color={theme.blueText}
            >
              {checkPincodeLabel}
            </SpanTag>{" "}
            {arrowIcon ? (
              <ImgWpr>
                <ImageTag src={arrowIcon} />
              </ImgWpr>
            ) : (
              <ImgWpr>
                <ArrowIcon />
              </ImgWpr>
            )}
          </Cta>
        </>
      ) : (
        <DeliveryPersuasionComp
          margin={margin}
          bgColor={bgColor}
          padding={padding}
          checkPincode={checkPincode}
        >
          {text && (
            <DeliveryPersuasionStyle
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          <Cta onClick={editLocationHandler}>
            <DeliveryLocationStyle fontSize={fontSize}>
              {ctaText}
            </DeliveryLocationStyle>
            {showIcon &&
              (upArrow ? (
                <ImageTag src={upArrow} />
              ) : defaultIconComp ? (
                defaultIconComp
              ) : (
                <ChevronDownIcon />
              ))}
          </Cta>
        </DeliveryPersuasionComp>
      )}
    </>
  );
};

export default DeliveryPersuasion;
