import styled from "styled-components";

export interface LandingCurrProp {
  currThankYou?: boolean;
}

export const LandingForeignCurrStyle = styled.section<LandingCurrProp>`
  background: linear-gradient(
    147.2deg,
    #fff6d8 4.79%,
    #fffaf3 43.98%,
    #f3f0e6 120.5%
  );
  border-radius: 25px;
  padding: 40px 60px;
  position: relative;
  overflow: hidden;
  margin-bottom: 163px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  ${(props) =>
    props.currThankYou &&
    `
background: linear-gradient(95.21deg, #E8F0FF 15.84%, #6536C9 100%);
box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.179518);
border-radius: 4px;
padding:26px 30px;
`}
`;

export const BgImgWrap = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
`;

export const LandingForeignCurrTitle = styled.h3<LandingCurrProp>`
  font-family: "Lato";
  font-weight: 600;
  font-size: 40px;
  line-height: 72px;
  color: #000000;
  margin-bottom: 24px;
  ${(props) =>
    props.currThankYou &&
    `
width:443px;
font-weight: 800;
font-size: 22px;
line-height: 28px;
color: #000000;
margin-bottom:16px;
`}
`;

export const LandingForeignCurrList = styled.ul<LandingCurrProp>`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  padding-left: 18px;
  gap: 10px;
  margin-bottom: 40px;

  li {
    position: relative;
  }

  li:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 14px;
    left: -18px;
    top: 8px;
    background: #41454e;
    border-radius: 14px;
  }

  ${(props) =>
    props.currThankYou &&
    `
  display:flex;
  gap:32px;
  margin-bottom: 24px;

  li:after {
    background: #B48B28;
  }
 `}
`;

export const ThankCurrInfo = styled.span`
  display: inline-block;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #232222;
  margin-bottom: 33px;
`;
