import React from "react";

import { theme } from "@tm/utility/global";

import {
  FlexComp,
  ParaTag,
  SpanTag,
  AnchorTag,
  BulletListItem,
  ImageTag,
} from "@tm/static";

import { landingForexOfferingCardDataProps } from "@tm/static/data";

import {
  ForexOfferingsStyle,
  ForexOfferingCntnt,
} from "./ForexOfferingCard.style";

interface OptionsList {
  data: landingForexOfferingCardDataProps;
  margin?: string;
}

const ForexOfferings: React.FC<OptionsList> = ({ data, margin }) => {
  return (
    <ForexOfferingsStyle margin={margin}>
      <ImageTag src={data.cardImg} />
      <ForexOfferingCntnt>
        <ParaTag fontSize="large" fontWeight="bold">
          {data.cardTitle}
        </ParaTag>
        <SpanTag margin="0 0 48px" fontSize="md">
          {data.cardInfo}
        </SpanTag>
        <ul>
          {data.cardFeatList.map((item) => {
            return (
              <BulletListItem key={item.id} bulletColor={theme.blueText}>
                {item.boldText && (
                  <SpanTag fontWeight="bold">{item.boldText}</SpanTag>
                )}{" "}
                {item.normalText}
              </BulletListItem>
            );
          })}
        </ul>
        <FlexComp margin="22px 0px 0px" justifyContent="center">
          <AnchorTag
            fontSize="sm"
            fontWeight="bold"
            textAlign="center"
            hrefProp={data.ctaLink}
          >
            {data.ctaTxt}
          </AnchorTag>
        </FlexComp>
      </ForexOfferingCntnt>
    </ForexOfferingsStyle>
  );
};

export default ForexOfferings;
