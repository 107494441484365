import React from "react";
import ReactMarkdown from "react-markdown";

export interface MarkdownTextProps {
  value: string;
}

const MarkdownText = ({ value }: MarkdownTextProps) => {
  return <ReactMarkdown components={{ p: "span" }}>{value}</ReactMarkdown>;
};

export default MarkdownText;
