import styled from "styled-components";

export const CurrentTripOrderStyle = styled.div`
  background: #fff;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LoadInstantly = styled.div`
  border-radius: 8px;
  background: linear-gradient(
      93deg,
      #fff8e1 11.79%,
      rgba(255, 255, 255, 0.99) 66.72%,
      rgba(255, 255, 255, 0) 98.34%
    ),
    #fff;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
