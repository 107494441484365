interface ChevronUpIconProps {
  cursor?: string;
  color?: string;
}

export const ChevronUpIcon = ({
  color = "#008cff",
  cursor = "pointer",
  ...props
}: ChevronUpIconProps) => (
  <svg
    style={{ cursor: cursor }}
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8583 10.6059C11.9364 10.5278 12.063 10.5278 12.1411 10.6059L16.2423 14.7071C16.6329 15.0976 17.266 15.0976 17.6566 14.7071V14.7071C18.0471 14.3166 18.0471 13.6834 17.6566 13.2929L12.7068 8.34315C12.3163 7.95262 11.6831 7.95262 11.2926 8.34315L6.34285 13.2929C5.95232 13.6834 5.95232 14.3166 6.34285 14.7071V14.7071C6.73337 15.0976 7.36654 15.0976 7.75706 14.7071L11.8583 10.6059Z"
      fill={color}
    />
  </svg>
);
