import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { theme } from "@tm/utility/global";
import { ImageComponent } from "@tm/static";
import { FlexComp, SpanTag } from "@tm/static";
import {
  formatDate,
  getFormFieldErrorMsg,
  isDobValid,
} from "@tm/insurance/utils";
import { DATE_FORMATS } from "@tm/insurance/constants";

import {
  TravellerDescpWrap,
  TravellerDescpStyle,
  TravellerDescpError,
  ArrowButtonWrap,
} from "./AddTravellerDetail.style";
import { ITravellerDescp, ITravellerDetailsFormV2 } from "./type";

const TravellerDescp = ({
  travellerIndex,
  genderTypeSelected,
  secondaryContactData,
  isSelected,
  handleEditState,
  isDomesticBooking,
}: ITravellerDescp) => {
  const { name, dob, gender } = secondaryContactData || {};

  const {
    control,
    formState: { errors },
  } = useFormContext<ITravellerDetailsFormV2>();

  const error = errors?.travellerData?.[travellerIndex];
  let errorMessage =
    error?.name?.message || error?.dob?.message || error?.gender?.message || "";

  return (
    <TravellerDescpWrap>
      <TravellerDescpStyle>
        <FlexComp
          flex="auto"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <SpanTag>
            {name?.isEnabled && (
              <Controller
                control={control}
                name={`travellerData.${travellerIndex}.name`}
                rules={{
                  required: {
                    value: isSelected ? name?.isRequired ?? true : false,
                    message: getFormFieldErrorMsg("name"),
                  },
                  pattern: {
                    value: new RegExp(name?.regex || ""),
                    message: getFormFieldErrorMsg("name", name?.errorMessage),
                  },
                }}
                render={({ field: { value } }) => (
                  <SpanTag fontSize="sm" fontWeight="black" margin="0 0 2px">
                    {value || "Traveller " + (travellerIndex + 1).toString()}
                  </SpanTag>
                )}
              />
            )}
            <div>
              {gender?.isEnabled && (
                <>
                  <Controller
                    control={control}
                    name={`travellerData.${travellerIndex}.gender`}
                    rules={{
                      required: {
                        value: isSelected ? gender?.isRequired ?? true : false,
                        message: getFormFieldErrorMsg("gender"),
                      },
                    }}
                    render={({ field: { value } }) => (
                      <SpanTag fontSize="xs" color={theme.greyText}>
                        {isDomesticBooking && "GENDER: "}
                        {value ? value : "---"}
                      </SpanTag>
                    )}
                  />
                </>
              )}
              {dob?.isEnabled && (
                <Controller
                  control={control}
                  name={`travellerData.${travellerIndex}.dob`}
                  rules={{
                    required: {
                      value: isSelected ? dob?.isRequired ?? true : false,
                      message: getFormFieldErrorMsg("dob"),
                    },
                    validate: isSelected
                      ? (value) =>
                          isDobValid(value, dob?.regex) ||
                          getFormFieldErrorMsg("dob", dob?.errorMessage)
                      : {},
                  }}
                  render={({ field: { value } }) => (
                    <SpanTag fontSize="xs" color={theme.greyText}>
                      ,DOB:{" "}
                      {formatDate(value, DATE_FORMATS["DD/MM/YYYY"]) || "---"}
                    </SpanTag>
                  )}
                />
              )}
            </div>
          </SpanTag>
          <ArrowButtonWrap onClick={handleEditState}>
            <ImageComponent
              width="9px"
              height="9px"
              margin="0px 0px 0px 0px"
              src="https://tripmoneycmsimgak.mmtcdn.com/img/Arrow_Down_Blue_5d58cf6783.svg"
            />
          </ArrowButtonWrap>
        </FlexComp>
      </TravellerDescpStyle>
      {errorMessage && (
        <TravellerDescpError>
          <SpanTag fontSize="xs" color={theme.redText2}>
            {errorMessage}
          </SpanTag>
        </TravellerDescpError>
      )}
    </TravellerDescpWrap>
  );
};

export default TravellerDescp;
