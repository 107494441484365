export const BLUE_COLOR = {
  "50": "#CCE8FF",
  "75": "#99D1FF",
  "100": "#66BAFF",
  "200": "#33A3FF",
  "300": "#008CFF",
  "400": "#0070CC",
  "500": "#005499",
  lightSkyBlue: "#6abcff",
};

export const WHITE_COLOR = {
  "60": "rgba(255, 255, 255, 0.6)",
};

export const BLACK_COLOR = {
  "16": "rgba(0, 0, 0, 0.16)",
  "21": "rgba(0, 0, 0, 0.21)",
  "60": "rgba(0, 0, 0, 0.6)",
};

export const TEXT_COLOR = {
  high: "#000000",
  medium: "#4A4A4A",
  low: "#757575",
  disabled: "rgba(0, 0, 0, 0.3)",
  error: "#960D11",
  warning: "#6B4300",
  success: "#1E5C43",
  white: "#fff",
  aquamarine: "#85d8ba",
  illuminatingEmerald: "#22936a",
};

export const BG_COLOR = {
  primary: "#FFFFFF",
  secondary: "#F6F6F6",
  inputfield: "#F6F6F6",
  disabled: "#CBCBCB",
  error: "#FCDADB",
  warning: "#FFEDD1",
  success: "#B8F5E0",
  blue: "#EAF5FF",
  black: "#000",
  vividBlack: "#010101",
  white: "#fff",
  bistreBrown: "#a07419",
  lightSilver: "#d9d9d9",
};

export const ICON_COLOR = {
  high: "#000000",
  medium: "#4A4A4A",
  low: "#757575",
  neutral: "#808080",
  disabled: "rgba(0, 0, 0, 0.3)",
};

export const SUCCESS_COLOR = {
  "50": "#E4FBF4",
  "75": "#B8F5E0",
  "100": "#8BEECD",
  "200": "#5EE8BA",
  "300": "#4FC594",
  "400": "#38A97A",
  "500": "#2B825E",
  "600": "#1E5C43",
};

export const WARNING_COLOR = {
  "50": "#FFEDD1",
  "75": "#FFDA9E",
  "100": "#FFC76B",
  "200": "#FFB438",
  "300": "#FFA105",
  "400": "#CF8100",
  "500": "#9E6300",
  "600": "#6B4300",
};

export const ERROR_COLOR = {
  "50": "#FCDADB",
  "75": "#F7ABAD",
  "100": "#F37C7F",
  "200": "#EF4D52",
  "300": "#EB2026",
  "400": "#C51217",
  "500": "#960D11",
};

export const NEUTRAL_COLOR = {
  "0": "#FFFFFF",
  "50": "#F6F6F6",
  "100": "#EEEEEE",
  "200": "#E2E2E2",
  "300": "#CBCBCB",
  "400": "#AFAFAF",
  "500": "#6B6B6B",
  "600": "#545454",
  "700": "#333333",
  "800": "#1F1F1F",
  "900": "#141414",
  "1000": "#000000",
};

export const BORDER_COLOR = {
  container: "#E2E2E2",
  divider: "#F2F2F2",
  dimGrey: "#717171",
  lightGrey: "#d2d2d2",
};

export const GRADIENT_COLOR = {
  white: "#ffffff",
  black: "#000000",
  lightPink: "#ffe2e8",
  snow: "#fffcfc",
  skyBlue: "#8cc8ea",
  lightCyan: "#e3fffd",
  lightSalmon: "rgba(230, 237, 255, 0.736176)",
  lightBlue0: "#d6e8f4",
  metallicSunBurst: "#9d8523",
  mustardYellow: "#dab90b",
  celladonGreen: "#249083",
  seaGreen: "#249054",
  zinnwalditeBrown: "#330e0e",
};
