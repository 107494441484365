import {
  PageNameMapping,
  ElementNameMapping,
  VERSIONS,
} from "libs/ui-widgets/src/lib/constants";
import { LocationUnavailable } from "libs/ui-widgets/src/lib/types";
import {
  getElementUniqueIds,
  getCamelCaseStr,
} from "libs/ui-widgets/src/lib/utils";
import { theme } from "../../../css/Global";
import { landingCardShowcaseDataProps } from "../../../mockData";
import { NotLocationText } from "../LandingCardFeature.style";
import AnchorTag from "../../ui-atoms/TextStyles/AnchorTag";
import ParaTag from "../../ui-atoms/TextStyles/ParaTag";
import { useDevice } from "libs/ui-widgets/src/lib/hooks/useDevice";
import { PersuasionHandler } from "../Persuasion";

export default function UnavailableLocationPersuasion(props: {
  cardData: landingCardShowcaseDataProps;
  persuasionProps: LocationUnavailable;
  handlers: PersuasionHandler;
}) {
  const device = useDevice();
  if (device === "lg") return <></>;
  return (
    <NotLocationText version={VERSIONS.V3}>
      {props.persuasionProps.normalText && (
        <span onClick={props.handlers.deliveryUnavailbleClickHandler}>
          <ParaTag fontSize="xs" color={theme.brownText}>
            {props.persuasionProps.normalText}{" "}
            {props.persuasionProps.linkText && (
              <AnchorTag
                id={getElementUniqueIds(
                  PageNameMapping.LISTING_PAGE,
                  getCamelCaseStr(props.cardData.label),
                  ElementNameMapping.ANOTHER_LOC_BTN
                )}
                width="fit-content"
                fontSize="xs"
                fontWeight="bold"
                onClick={() =>
                  props.handlers.onClickAnotherLocation(props.cardData)
                }
              >
                {props.persuasionProps.linkText?.text}
              </AnchorTag>
            )}
          </ParaTag>
        </span>
      )}
    </NotLocationText>
  );
}
