import styled from "styled-components";
import { deviceType } from "../../utils";
import { theme } from "../../css/Global";
import { VERSIONS } from "../../../constants";

type LandingCardFeatureStyleProps = {
  padding?: string;
  margin?: string;
  border?: string;
  borderWidth?: string;
  version?: number;
  isZeroPayment?: boolean;
};

export const NotLocationText = styled.div<{ version?: number }>`
  border-radius: 8px;
  background-color: ${(props: any) =>
    props.version === VERSIONS.V3 ? "#F6F6F6" : theme.yellowText2};
  padding: 8px;
  margin-top: 24px;
`;
export const ZeroPayment = styled.div`
  position: absolute;
  left: 16px;
  top: 0;
  z-index: 1;
`;

export const LandingCardFeatureStyle = styled.div<LandingCardFeatureStyleProps>`
  display: flex;
  position: relative;
  flex: 1;
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.padding && `padding: ${props.padding};`}
    flex-direction: column;
  ${(props) => props.border && `border: ${props.border};`}
  ${(props) => props.borderWidth && `border-width: ${props.borderWidth};`}
    border-radius:${(props: { version?: number }) =>
    props.version === VERSIONS.V3 ? "16px" : "4px"};
  cursor: pointer;
  align-self: ${(props: { version?: number }) =>
    props.version === VERSIONS.V3 ? "flex-start" : "unset"};

  @media ${deviceType.desktop} {
    ${(props) => props.isZeroPayment && `padding: 38px 16px 16px 16px;`}
  }
`;

export const LogoContainer = styled.div`
  width: 140px;

  @media screen and ${deviceType.mobile} {
    width: 60px;
  }
`;

export const AbsTag = styled.p`
  margin: 0;
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: ${theme.greenText2};
  padding: 4px 12px;
  border-radius: 20px;
`;

export const LandingCardFeatureInnerStyle = styled.div`
  display: flex;
`;

export const PointsContainer = styled.div`
  padding: 0px;
  list-style: none;
  margin: 12px 0 0 0;
  /* margin-bottom: 24px; */
  /* display: flex; */
  /* flex-wrap: wrap; */

  & > span:not(:last-child):after {
    content: "|";
    margin-left: 2px;
    margin-right: 2px;
  }

  @media screen and ${deviceType.mobile} {
    /* flex-direction: row; */
    margin-bottom: 0px;
  }
`;

export const RightButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const DeliveryLabelContainer = styled.div`
  margin: 18px 0px 0px;
  @media ${deviceType.mobile} {
    border-top: 1px solid #f2f2f2;
    margin: 12px 0px 0px;
  }
`;

export const FreeCard = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  padding: 4px 8px;
  gap: 4px;
  background: linear-gradient(
    160deg,
    rgba(210, 242, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  align-self: flex-start;
`;
