import { isOnServer } from "../../utils";
import { DEVICE_TYPE } from "../types";

// RESPONSIVE BREAKPOINTS
export const breakpoint = {
  mobile: 768,
};

export const deviceType = {
  mobile: `(max-width: ${breakpoint.mobile}px)`,
  desktop: `(min-width: ${breakpoint.mobile}px)`,
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const SHORT_WEEK_DAYS = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

const getSuffix = (dayNo: number) => {
  if (dayNo > 3 && dayNo < 21) return "th";
  switch (dayNo % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const getFutureDate = (count = 10) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // Get today's date
  const today = new Date();

  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + count);

  // Get the month name from the monthNames array
  const monthName = monthNames[tomorrow.getMonth()];

  // Output the date in the desired format
  const date = tomorrow.getDate();
  const year = tomorrow.getFullYear();
  const suffix = getSuffix(date);
  return {
    date,
    year,
    monthName,
    suffix,
    day: SHORT_WEEK_DAYS[tomorrow.getDay()],
  };
};

export function getDeviceType(userAgent: string): DEVICE_TYPE {
  const mobileRegex = new RegExp(/Mobile|Android|BlackBerry/);
  const tabletRegex = new RegExp(
    /iPad|(Android(?!.*Mobile))|Tablet|PlayBook|Kindle|GT-P1000|SCH-I800|sholes|Xoom|Libero|\b4G\b/
  );
  if (userAgent.match(mobileRegex)) {
    return DEVICE_TYPE.MOBILE;
  } else if (userAgent.match(tabletRegex)) {
    return DEVICE_TYPE.TABLET;
  }
  return DEVICE_TYPE.DESKTOP;
}

export const getMobileOperatingSystem = () => {
  //@ts-ignore
  const userAgent = !isOnServer()
    ? navigator?.userAgent || navigator?.vendor || window?.opera
    : null;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  //@ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

export * from "../utils/redirectionUtils";
