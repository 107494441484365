import React from "react";

import { ImageTag, H2Tag, H5Tag } from "@tm/static";

import {
  FeaturesListCompStyle,
  FeaturesList,
  YellowBox,
  ListLabel,
  Ul,
  Li,
  FeatureCardsWpr,
  FeatureCards,
  FeatureCard,
  Image,
  InfoIconWpr,
} from "./FeaturesListComp.style";

import BMFFlashImg from "../../icons/flash_bg_img2.png";
import { InfoIcon } from "../../icons/InfoIcon";

import { theme } from "@tm/utility/global";

import { useBreakpoint } from "@tm/ui/hooks";
import { FeaturesListCompProps } from "./types";

const FeaturesListComp: React.FC<FeaturesListCompProps> = ({
  data,
  margin,
  handleInfoClick = (id: string) => {},
}) => {
  const { featuresList, featureCards } = data;

  const device = useBreakpoint();

  return (
    <FeaturesListCompStyle margin={margin}>
      {featuresList && (
        <FeaturesList>
          {device === "lg" && featuresList.label && (
            <H2Tag>{featuresList.label}</H2Tag>
          )}
          {featuresList.listItems && (
            <Ul>
              {featuresList.listItems.map((item, idx) => (
                <Li key={`${idx}`}>
                  <YellowBox />
                  <ListLabel
                    small={true}
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                </Li>
              ))}
            </Ul>
          )}
        </FeaturesList>
      )}
      {featureCards && (
        <FeatureCardsWpr>
          {device === "lg" && <Image src={BMFFlashImg.src} />}
          {device !== "lg" && featuresList?.label && (
            <H5Tag>{featuresList.label}</H5Tag>
          )}
          <FeatureCards>
            {featureCards.map((item) => (
              <FeatureCard key={item.id}>
                {item.imgSrc && (
                  <ImageTag margin="0px 8px 0px 0px" src={item.imgSrc} />
                )}
                <ListLabel dangerouslySetInnerHTML={{ __html: item.label }} />
                {item.showIcon && (
                  <InfoIconWpr onClick={() => handleInfoClick(item.id)}>
                    {item.icon ? (
                      <ImageTag src={item.icon} showCursorPointer />
                    ) : (
                      <InfoIcon
                        height={device === "lg" ? 20 : 12}
                        width={device === "lg" ? 20 : 12}
                        color={theme.blueText}
                      />
                    )}
                  </InfoIconWpr>
                )}
              </FeatureCard>
            ))}
          </FeatureCards>
        </FeatureCardsWpr>
      )}
    </FeaturesListCompStyle>
  );
};

export default FeaturesListComp;
