import React, { useState } from "react";

import {
  H5Tag,
  FlexComp,
  SpanTag,
  ImageComponent,
  H6Tag,
  FormattedText,
} from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { theme } from "@tm/utility/global";

import InsuranceBookedTravel from "./InsuranceBookedTravel";
import InsuranceBookedPack from "./InsuranceBookedPack";

import { InsuranceBookedStyle, GreenArrowWrap } from "./InsuranceBooked.style";
import { InsuranceBookedProps } from "./type";

const InsuranceBooked = ({
  title,
  selectedPlanData,
  viewBenefitsCta,
  handleViewBenefits,
}: InsuranceBookedProps) => {
  const { payAmtTxt, payAmt } = selectedPlanData || {};
  const device = useBreakpointV2();

  const [insDetails, setInsDetails] = useState(false);

  const handleInsuranceExpand = () => {
    setInsDetails(!insDetails);
  };

  return (
    <InsuranceBookedStyle>
      <FlexComp
        justifyContent="space-between"
        alignItems={device == "lg" ? "center" : "flex-start"}
        borderBottom={
          insDetails && device == "sm"
            ? "1px solid #d8d8d8"
            : device == "lg"
            ? "1px solid #d8d8d8"
            : ""
        }
        padding={
          insDetails && device == "sm"
            ? "0 0 12px"
            : device == "lg"
            ? "0 0 24px"
            : ""
        }
        margin={
          insDetails && device == "sm"
            ? "0 0 12px"
            : device == "lg"
            ? "0 0 8px"
            : ""
        }
        handleClick={handleInsuranceExpand}
      >
        <FlexComp
          justifyContent="space-between"
          width="100%"
          alignItems={device === "lg" ? "center" : ""}
          flexDirection={device === "lg" ? "row" : "column"}
        >
          <FlexComp
            alignItems="center"
            gap="8px"
            padding="0"
            margin="0 10px 0 0"
            borderBottom="none"
          >
            <ImageComponent
              width="24px"
              height="24px"
              src="https://tripmoneycmsimgak.mmtcdn.com/img/Frame_48_Copy_4b90426dd9.svg"
            />
            {device == "lg" ? (
              <H5Tag color={theme.blackText}>
                <FormattedText value={title} />
              </H5Tag>
            ) : (
              <div>
                <H6Tag color={theme.blackText}>
                  <FormattedText value={title} />
                </H6Tag>
                <FlexComp flexShrink="0" flexDirection="row" gap="2px">
                  <SpanTag color={theme.greenText5} fontSize="xs">
                    <FormattedText value={payAmtTxt} />
                  </SpanTag>

                  <SpanTag
                    color={theme.greenText5}
                    fontSize="xs"
                    fontWeight="black"
                  >
                    <FormattedText value={payAmt} />
                  </SpanTag>
                </FlexComp>
              </div>
            )}
          </FlexComp>
          {device === "lg" && (
            <FlexComp
              flexDirection="row"
              gap="4px"
              margin={device === "lg" ? "0 0 0 auto" : ""}
            >
              <SpanTag
                color={theme.greenText5}
                fontSize={device == "lg" ? "md" : "xs"}
              >
                <FormattedText value={payAmtTxt} />
              </SpanTag>

              <SpanTag
                color={theme.greenText5}
                fontSize={device == "lg" ? "md" : "xs"}
                fontWeight="black"
              >
                <FormattedText value={payAmt} />
              </SpanTag>
            </FlexComp>
          )}
        </FlexComp>
        {device == "sm" && (
          <GreenArrowWrap
            showinsDetails={insDetails}
            onClick={handleInsuranceExpand}
          >
            <ImageComponent
              width="40px"
              height="40px"
              src="https://tripmoneycmsimgak.mmtcdn.com/img/Frame_1244832110_61908827b9.svg"
            />
          </GreenArrowWrap>
        )}
      </FlexComp>
      {((insDetails && device == "sm") || device == "lg") && (
        <React.Fragment>
          <InsuranceBookedPack
            selectedPlanData={selectedPlanData}
            viewBenefitsCta={viewBenefitsCta}
            handleViewBenefits={handleViewBenefits}
          />
          <InsuranceBookedTravel selectedPlanData={selectedPlanData} />
        </React.Fragment>
      )}
    </InsuranceBookedStyle>
  );
};

export default InsuranceBooked;
