import React from "react";

import { TagsStyle, TagItem } from "./Tags.style";
import { theme } from "@tm/utility/global";
import { SpanTag } from "@tm/static";
interface TagsProps {
  handleClickTab?: (index: number) => void;
  data?: {
    id?: number;
    name?: string;
    isSelected?: boolean;
  }[];
}

const Tags = ({ data, handleClickTab }: TagsProps) => {
  return (
    <TagsStyle>
      {data &&
        data.map((item, index) => {
          return (
            <TagItem
              isSelected={item.isSelected}
              onClick={handleClickTab ? () => handleClickTab(index) : () => {}}
              key={index}
            >
              <SpanTag
                color={item.isSelected ? theme.blueText : theme.greyText}
                fontSize="xs"
                fontWeight="black"
              >
                {item.name}
              </SpanTag>
            </TagItem>
          );
        })}
    </TagsStyle>
  );
};

export default Tags;
