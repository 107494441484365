import FlexComp from "../FlexComp/FlexComp";
import FormattedText from "../FormattedText/FormattedText";
import { WebToastStyle } from "./WebToast.style";
import { ImageComponent } from "@tm/static";
import { TextNodeType } from "../FormattedText/FormattedText";
interface WebToastProps {
  type?: "error" | "success" | "default";
  iconUrl?: string;
  toastTxt?: TextNodeType | string;
  toastTxtMargin?: string;
  handleErrorToast?: any;
  position?: string;
  fontSize?: string;
  fontWeight?: string;
  margin?: string;
  top?: string;
  left?: string;
  width?: string;
  height?: string;
  bottom?: string;
  right?: string;
  color?: string;
}

const TOAST_BG_COLOR = {
  error: "#FCDADB",
  success: "#B8F5E0",
  default: "#4A4A4A",
};

const WebToast = ({
  iconUrl,
  position,
  toastTxt,
  fontSize,
  fontWeight,
  toastTxtMargin,
  top,
  left,
  margin,
  width,
  bottom,
  right,
  height,
  type = "default",
  color,
}: WebToastProps) => {
  return (
    <WebToastStyle
      top={top}
      bottom={bottom}
      right={right}
      left={left}
      width={width}
      height={height}
      margin={margin}
      position={position}
      justifyContent={type === "success" ? "center" : "space-between"}
      background={TOAST_BG_COLOR[type]}
    >
      {iconUrl && (
        <ImageComponent
          height="15px"
          width="15px"
          src={iconUrl}
        ></ImageComponent>
      )}
      {toastTxt && (
        <FlexComp gap="10px" alignItems="center">
          <FormattedText
            value={toastTxt}
            baseStyle={{
              fontSize: fontSize ? fontSize : "12px",
              fontWeight: fontWeight ? fontWeight : "normal",
              color: color ? color : "#FFFFFF",
              margin: toastTxtMargin ? toastTxtMargin : "10px",
            }}
          />
        </FlexComp>
      )}
    </WebToastStyle>
  );
};

export default WebToast;
