interface EditIconInterface {
  size?: number;
  color?: string;
  cursor?: string;
}

export const EditIcon = ({
  size = 24,
  color = "#008CFF",
  cursor = "pointer",
  ...props
}: EditIconInterface) => (
  <svg
    style={{ cursor: cursor }}
    width={size}
    height={size}
    viewBox={`0 0 24 24`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.378 5.45329C15.9045 4.9268 16.7581 4.9268 17.2846 5.45328L19.0567 7.2254C19.5832 7.75188 19.5832 8.60548 19.0567 9.13196L18.3203 9.86834C18.1097 10.0789 17.7683 10.0789 17.5577 9.86834L14.6416 6.95229C14.431 6.74169 14.431 6.40025 14.6416 6.18966L15.378 5.45329Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1781 7.64303C13.3887 7.43244 13.7302 7.43244 13.9408 7.64303L16.8568 10.5591C17.0674 10.7697 17.0674 11.1111 16.8568 11.3217L10.3652 17.8134C10.2926 17.8859 10.1997 17.9347 10.0988 17.9532L6.59734 18.5945C6.25606 18.657 5.95467 18.365 6.00634 18.0219L6.55028 14.4106C6.56638 14.3038 6.61629 14.2049 6.69271 14.1285L13.1781 7.64303Z"
      fill={color}
    />
  </svg>
);
