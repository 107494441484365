import { ActionTypeOptions } from "../types";
import {
  ERROR_CARD_CTA_SIGNAL_STATE,
  ERROR_LISTING_PAGE_LOCATION_CHANGE,
  FETCH_CARD_CTA_SIGNAL_STATE,
  FETCH_CROSS_SELL_DATA,
  FETCH_DELIVERY_DETAILS,
  FETCH_FOREX_CURRENCY_RATE,
  FETCH_LISTING_PAGE_LOCATION_CHANGE,
  IS_ERROR_FETCHING_DELIVERY_DETAILS,
  IS_LOADING_DELIVERY_DETAILS,
  LOADING_CARD_CTA_SIGNAL_STATE,
  LOADING_LISTING_PAGE_LOCATION_CHANGE,
  PERSONA_AND_VERSION_FETCHED_ON_LOAD,
  UPDATE_LOGIN_EMAIL_MODAL_STATE,
} from "./actionType";

export const fetchForexCurrencyRateAction = (payload: any) => {
  return {
    type: FETCH_FOREX_CURRENCY_RATE,
    payload,
  };
};

// Delivery details
export const loadingDeliveryDetailsAction = () => ({
  type: IS_LOADING_DELIVERY_DETAILS,
});
export const fetchDeliveryDetailsAction = (payload: any) => {
  return {
    type: FETCH_DELIVERY_DETAILS,
    payload,
  };
};
export const fetchedPersonaAndVersionOnLoad = (payload: {
  persona: Object;
  version: number;
}) => {
  return {
    type: PERSONA_AND_VERSION_FETCHED_ON_LOAD,
    payload,
  };
};
export const errorFetchingDeliveryDetails = (payload: any) => ({
  type: IS_ERROR_FETCHING_DELIVERY_DETAILS,
  payload,
});

// Fetch Listing page once pincode or location detected changes.

export const loadingListingPageOnLocationChange = () => ({
  type: LOADING_LISTING_PAGE_LOCATION_CHANGE,
});

export const fetchingListingPageOnLocationChange = (payload: any) => ({
  type: FETCH_LISTING_PAGE_LOCATION_CHANGE,
  payload,
});

export const errorFetchingListingPageOnLocationChange = (payload: any) => ({
  type: ERROR_LISTING_PAGE_LOCATION_CHANGE,
  payload,
});

// Fetching Card CTA signal state actions
export const loadingCardCtaSignalState = () => ({
  type: LOADING_CARD_CTA_SIGNAL_STATE,
});

export const fetchingCardCtaSignalState = (
  payload: any,
  options?: ActionTypeOptions
) => ({
  type: FETCH_CARD_CTA_SIGNAL_STATE,
  payload,
  options,
});

export const fetchingCrossSellDataState = (payload: any) => ({
  type: FETCH_CROSS_SELL_DATA,
  payload,
});

export const errorCardCtaSignalState = (
  payload: any,
  options?: ActionTypeOptions
) => ({
  type: ERROR_CARD_CTA_SIGNAL_STATE,
  payload,
  options,
});

export const updateForceLoginEmailModal = (payload: boolean) => ({
  type: UPDATE_LOGIN_EMAIL_MODAL_STATE,
  payload,
});
