import { useBreakpointV2 } from "@tm/ui-widgets";
import React from "react";

/* Every text node can be of either of the three formats - 
1. string - "Hello"
2. text obj with value and styles - 
   text: {
    value: "Travel Assistance",
    styles: {
      color: "#000000",
      fontFamily: "fontSansBold",
    },
  }
3. Array of text objs with value and styles - 
   text: [
    {
      value: "Travel Assistance",
      styles: {
        color: "#000000",
        fontFamily: "fontSansBold",
      },
    },
  ]
*/
interface Styles {
  color?: string;
  "font-size"?: string;
  fontWeight?: string;
  mobile?: {
    "font-size"?: string;
  };
}

interface TextWithStyles {
  icon?: string;
  value?: string;
  styles?: Styles;
}

export interface TextNodeType {
  text?: string | TextWithStyles | TextWithStyles[];
}

export interface FormattedTextProps {
  value?: string | TextNodeType;
  baseStyle?: React.CSSProperties;
}

export const getPlainText = (data: TextNodeType | undefined): string => {
  if (!data) {
    return "";
  } else if (typeof data === "string") {
    return data;
  } else if (data?.text) {
    const { text } = data;
    if (Array.isArray(text)) {
      return text.map((node) => node.value).join("");
    } else if (typeof text === "object") {
      return text?.value || "";
    } else {
      return "";
    }
  } else {
    return "";
  }
};

const FormattedText = ({ value, baseStyle }: FormattedTextProps) => {
  const device = useBreakpointV2();
  if (!value) {
    return null;
  }

  let returnValue = null;

  if (typeof value === "string") {
    returnValue = value;
  } else if (value?.text) {
    const { text } = value;
    if (Array.isArray(text)) {
      returnValue = text.map((node, index) => {
        const mobileStyle = node.styles?.mobile;
        const combinedStyles = {
          ...node?.styles,
          ...(device === "sm" && { ...mobileStyle }),
        };
        return node.value ? (
          <span key={index} style={combinedStyles}>
            {node.value}
          </span>
        ) : null;
      });
    } else if (typeof text === "object") {
      const mobileStyle = text?.styles?.mobile;

      const combinedStyles = {
        ...text?.styles,
        ...(device === "sm" && { ...mobileStyle }),
      };
      returnValue = text?.value ? (
        <span style={combinedStyles}>{text.value}</span>
      ) : null;
    } else {
      returnValue = text;
    }
  }
  return <span style={baseStyle ?? {}}>{returnValue}</span>;
};

export default FormattedText;
