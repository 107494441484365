import styled from "styled-components";
import { theme } from "@tm/utility/global";

export const ExpandableTnCCardStyle = styled.div<any>`
  padding: 24px 16px;
  border-radius: 12px;
  background: linear-gradient(
    159.48deg,
    #fff9ed 4.69%,
    rgba(255, 239, 236, 0.736176) 27.76%,
    #f8f9e7 92.13%
  );
  ${(props) =>
    props.isOpen
      ? `border: 1px ${theme.yellowText4} solid;`
      : `box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);`}/* @media only screen and (max-width: 768px) {
        box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
    } */
`;

export const TnCLabel = styled.div`
  padding: 16px 11px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 24px;
  border-radius: 8px;
  background-color: ${theme.whiteText};
`;

export const IconCtr = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  background-color: ${theme.whiteText};
`;
