import { useRef } from "react";
import React, { useState, useEffect } from "react";

import Calendar from "./components/Calendar/Calendar";
import CalendarFooter from "./components/CalendarFooter/CalendarFooter";
import CalendarHeader from "./components/CalendarHeader/CalendarHeader";
import WeekDays from "./components/WeekDays/WeekDays";
import { RangeDatePickerStyle, ErrorToast } from "./styles/styles.style";

import { FormattedText, SpanTag } from "@tm/static";
import { theme } from "@tm/utility/global";
import { formatDate } from "../../utils";
import { DateRange } from "react-day-picker";

interface RangeDatePickerType {
  calendarHeader?: boolean;
  calendarWeekdays?: boolean;
  monthNameType?:
    | "numeric"
    | "2-digit"
    | "long"
    | "short"
    | "narrow"
    | undefined;
  yearType?: "numeric" | "2-digit" | undefined;
  calendarFooter?: boolean;
  onBackPress?: () => void;
  onSubmitButtonPress?: (chkIn?: Date, chkOut?: Date) => void;
  range?: boolean;
  numberOfMonth?: number;
  from?: Date;
  to?: Date;
  calenderRef?: HTMLDivElement;
  showToast?: boolean;
  toastMessage?: string;
  selected?: DateRange | undefined;
  handleDayClick: (event: Date) => void;
}
// import './styles/styles.css';

const RangeDatePicker = ({
  calendarHeader,
  calendarWeekdays = true,
  monthNameType = "short",
  yearType = "numeric",
  calendarFooter,
  onBackPress,
  onSubmitButtonPress,
  range,
  from,
  to,
  showToast,
  toastMessage,
  handleDayClick,
  selected,
}: // handleDayClick
RangeDatePickerType) => {
  const [startDate, setStartDate] = useState<Date | undefined>(from);
  const [endDate, setEndDate] = useState<Date | undefined>(to);

  return (
    <RangeDatePickerStyle className="cal__container">
      {calendarHeader && (
        <CalendarHeader
          checkinDate={startDate}
          checkoutDate={endDate}
          onBackPress={onBackPress}
        />
      )}
      {calendarWeekdays && <WeekDays top={calendarHeader ? 49 : 0} />}
      <Calendar
        handleDayClick={handleDayClick}
        startDate={startDate}
        endDate={endDate}
        monthNameType={monthNameType}
        yearType={yearType}
        selected={selected}
      />
      {calendarFooter && (
        <CalendarFooter
          checkinDate={selected?.from}
          checkoutDate={selected?.to}
          onSubmitButtonPress={
            onSubmitButtonPress
              ? () => onSubmitButtonPress(selected?.from, selected?.to)
              : () => {}
          }
        />
      )}
      {showToast && (
        <ErrorToast>
          <SpanTag color={theme.redText}>
            <FormattedText value={toastMessage} />
          </SpanTag>
        </ErrorToast>
      )}
    </RangeDatePickerStyle>
  );
};

export default RangeDatePicker;
