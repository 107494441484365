import { ToolTipProps } from "./ToolTipProps";
import { ToolTipCont, ToolTipBackdrop } from "./ToolTip.styles";
import { useDevice } from "libs/ui-widgets/src/lib/hooks/useDevice";

const ToolTip = ({
  width,
  children,
  bgColor,
  arrowDirection = "down",
  arrowPosition,
  style,
  closeTooltip,
  id,
}: ToolTipProps) => {
  const device = useDevice();
  return (
    <>
      <ToolTipCont
        width={width}
        arrowDirection={arrowDirection}
        arrowPosition={arrowPosition}
        bgColor={bgColor}
        style={style}
        id={id}
      >
        {children}
      </ToolTipCont>
      {device === "sm" && (
        <ToolTipBackdrop onClick={(e) => closeTooltip?.(e)} />
      )}
    </>
  );
};

export default ToolTip;
