import {
  OMNITURE_MAP,
  PAGE_AND_CATEGORY_MAP,
  PAGE_NAMES,
  OMNI_EVENTS_MAP,
} from "./constants";
import { trackEventMweb } from "../common";
import { getDevice, getUserCityAndLocation, getLogInStatus } from "./utils";
import type { TrackListingClickType } from "./type";
import { getDeviceTypeForOmniture } from "@tm/ui-widgets";
import { getCommonOmniData } from "@tm/ui-widgets";

const { CTA_INFO } = OMNI_EVENTS_MAP;
//  listing click events

export function trackListingClickEvents(deps: TrackListingClickType) {
  try {
    const { actionType, partner } = deps;
    const { pageName, lob, siteSection } =
      PAGE_AND_CATEGORY_MAP[PAGE_NAMES["LISTING"]];
    const location: string = getUserCityAndLocation();
    const platform: string = getDeviceTypeForOmniture() || "";
    const loginStatus = getLogInStatus();
    const commonOmniData = getCommonOmniData({
      pageName,
      lob,
      siteSection,
      partner,
      location,
      platform,
      loginStatus,
    });
    const omniData = {
      ...commonOmniData,
      [OMNITURE_MAP[CTA_INFO]]: actionType,
    };
    trackEventMweb({
      ...omniData,
      pageName,
    });
  } catch (e) {
    console.error(e);
  }
}
