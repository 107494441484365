import { theme } from "../../css/Global";
import {
  DiscountLabel,
  DiscountSubLabel,
  LabelContainer,
} from "./DiscountBanner.style";
import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import { ChevRight } from "../../icons/ChevRight";
import { InfoIcon } from "../../icons/InfoIcon";
import { useDevice } from "../../../hooks/useDevice";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";

interface DiscountBannerProps {
  discountImg?: string;
  label?: string;
  bgColor?: string;
  width?: string;
  margin?: string;
  padding?: string;
  color?: string;
  fontWeight?: string;
  alignItems?: string;
  justifyContent?: string;
  showIcon?: boolean;
  onPressHandler?: () => void;
  iconColor?: string;
  height?: string;
  fontSize?: string;
  lineHeight?: string;
  subLabelColor?: string;
  subLabel?: string;
  showInfoIcon?: boolean;
  infoIconHandler?: () => void;
  infoIconColor?: string;
  borderRadius?: string;
}

const DiscountBanner = ({
  discountImg,
  label,
  bgColor,
  width,
  margin,
  padding,
  color,
  fontWeight,
  alignItems,
  justifyContent,
  showIcon = false,
  onPressHandler,
  iconColor,
  height,
  lineHeight,
  fontSize,
  subLabelColor,
  subLabel,
  showInfoIcon,
  infoIconHandler,
  infoIconColor,
  borderRadius,
}: DiscountBannerProps) => {
  const device = useDevice();
  return (
    <FlexComp
      flexDirection="row"
      alignItems={alignItems || "center"}
      bgColor={bgColor || theme.blueText2}
      padding={padding || "8px"}
      borderRadius={borderRadius || "4px"}
      margin={margin || "12px 0px 0px 0px"}
      width={width}
      justifyContent={justifyContent || "unset"}
      handleClick={onPressHandler}
      height={height}
    >
      {discountImg && (
        <ImageTag
          height="20px"
          width="20px"
          margin="0px 4px 0px 0px"
          src={discountImg}
        />
      )}
      <LabelContainer>
        {label && (
          <DiscountLabel
            fontWeight={fontWeight}
            color={color}
            dangerouslySetInnerHTML={{ __html: label }}
            lineHeight={lineHeight}
            fontSize={fontSize}
            paddingTop={device === "sm" ? "2px" : "0px"}
          />
        )}
        {subLabel && (
          <DiscountSubLabel
            color={subLabelColor}
            dangerouslySetInnerHTML={{ __html: subLabel }}
          />
        )}
      </LabelContainer>

      {showInfoIcon && (
        <AnchorTag
          onClick={infoIconHandler}
          margin={device === "sm" ? "0px" : "4px 0px 0px"}
        >
          <InfoIcon
            color={
              infoIconColor ||
              (device === "sm" ? theme.whiteText : theme.blueText)
            }
          />
        </AnchorTag>
      )}

      {showIcon && !showInfoIcon && (
        <ChevRight color={iconColor || theme.whiteText} />
      )}
    </FlexComp>
  );
};

export default DiscountBanner;
