import styled from "styled-components";

import Header from "../Header404";
import { H3Tag, ParaTag, LandingButtonWhite } from "@tm/static";
import { Image } from "../Image";
import { theme } from "@tm/utility/global";

export const ErrorImg = styled.div`
  width: 230px;
  height: 230px;
  margin-bottom: 16px;
  align-self: center;
`;

export const ErrorPageStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

interface ErrorPageProps {
  errorImg?: string;
  title: string;
  info: string;
  errorEvent?: any;
  headerComponent?: JSX.Element;
  button1?: string;
  button2?: string;
}

export const ErrorPage = ({
  errorImg,
  title,
  info,
  errorEvent,
  headerComponent,
  button1,
  button2,
}: ErrorPageProps) => {
  return (
    <>
      {headerComponent || <Header />}
      <ErrorPageStyle>
        <ErrorImg>
          <Image src={errorImg} width="230" height="230" alt="Error" />
        </ErrorImg>
        <H3Tag color={theme.blackText}>{title}</H3Tag>
        <ParaTag
          textAlign="center"
          color={theme.greyText}
          fontSize="md"
          lineHeight="32px"
        >
          {info}
        </ParaTag>
        {button1 && (
          <LandingButtonWhite
            customTitle={button1}
            customWidth="100%"
            margin="32px 0 0"
          />
        )}
        {button2 && (
          <LandingButtonWhite
            customTitle={button2}
            customWidth="100%"
            margin="32px 0 0"
          />
        )}
      </ErrorPageStyle>
    </>
  );
};
