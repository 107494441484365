import { isOnServer } from "@tm/ui-widgets";
import { isHostMakemytrip } from "@tm/static/utils";
import { LandingHeader, LandingHeaderWrap, TMLogo } from "./index.style";

const Header = () => {
  if (!isOnServer()) {
    const toHome = () => {
      window.location.href = "/";
    };

    return (
      <LandingHeader>
        <LandingHeaderWrap>
          <TMLogo>
            <img
              src={
                isHostMakemytrip()
                  ? "https://tripmoneycmsimgak.mmtcdn.com/img/mmt_logo_007ad76964.png"
                  : "https://tripmoneycmsimgak.mmtcdn.com/img/gi_logo_1_dd53c8c777.svg"
              }
              alt="Blue mmt logo"
              onClick={toHome}
            ></img>
          </TMLogo>
        </LandingHeaderWrap>
      </LandingHeader>
    );
  }
  return <></>;
};

export default Header;
