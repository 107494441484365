import * as React from "react";
interface iconprop {}
const LuggageIconShaded = (props: iconprop) => (
  <svg
    width={72}
    height={80}
    viewBox="0 0 72 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={72} height={80} rx={8} fill="url(#paint0_linear_841_30597)" />
    <path
      d="M14.0001 52.1772C15.3762 50.1433 58.2344 50.6329 58.8242 52.1772C58.8242 51.4616 58.8242 46.5395 58.8242 46.0875C58.0378 44.1665 16.1626 43.8652 14.0001 46.0875C14.0001 46.9538 13.9999 51.3863 14.0001 52.1772Z"
      fill="#E0E0E0"
    />
    <path
      d="M17.2446 35.7326C17.2446 35.5675 17.3739 35.4336 17.5334 35.4336H18.1111C18.2706 35.4336 18.3999 35.5675 18.3999 35.7326V37.0282C18.3999 37.1933 18.2706 37.3272 18.1111 37.3272H17.5334C17.3739 37.3272 17.2446 37.1933 17.2446 37.0282V35.7326Z"
      fill="#1E1E1E"
    />
    <rect
      x={23.9131}
      y={21.2798}
      width={0.577626}
      height={9.46792}
      fill="#ADADAD"
    />
    <rect
      x={31.6592}
      y={21.2798}
      width={0.577626}
      height={9.46792}
      fill="#ADADAD"
    />
    <path
      d="M23.657 31.5459C23.4975 31.5459 23.3682 31.412 23.3682 31.2469V30.6489C23.3682 30.4838 23.4975 30.35 23.657 30.35H24.8122C24.9717 30.35 25.101 30.4838 25.101 30.6489V31.2469C25.101 31.412 24.9717 31.5459 24.8122 31.5459H23.657Z"
      fill="#1E1E1E"
    />
    <path
      d="M31.2273 31.5459C31.0678 31.5459 30.9385 31.412 30.9385 31.2469V30.6489C30.9385 30.4838 31.0678 30.35 31.2273 30.35H32.3825C32.542 30.35 32.6714 30.4838 32.6714 30.6489V31.2469C32.6714 31.412 32.542 31.5459 32.3825 31.5459H31.2273Z"
      fill="#1E1E1E"
    />
    <path
      d="M37.2437 42.1113C37.2437 41.6159 37.6316 41.2144 38.1101 41.2144H38.4952C38.9737 41.2144 39.3616 41.6159 39.3616 42.1113V49.287C39.3616 49.7824 38.9737 50.184 38.4952 50.184H38.1101C37.6316 50.184 37.2437 49.7824 37.2437 49.287V42.1113Z"
      fill="#1E1E1E"
    />
    <path
      d="M17.2446 53.672C17.2446 53.5069 17.3739 53.373 17.5334 53.373H18.1111C18.2706 53.373 18.3999 53.5069 18.3999 53.672V54.9676C18.3999 55.1328 18.2706 55.2666 18.1111 55.2666H17.5334C17.3739 55.2666 17.2446 55.1328 17.2446 54.9676V53.672Z"
      fill="#1E1E1E"
    />
    <mask
      id="mask0_841_30597"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={17}
      y={30}
      width={22}
      height={31}
    >
      <path
        d="M17.6021 33.9387C17.6021 32.1774 18.9813 30.7495 20.6827 30.7495H35.701C37.4024 30.7495 38.7817 32.1774 38.7817 33.9387V57.459C38.7817 59.2203 37.4024 60.6482 35.701 60.6482H20.6827C18.9813 60.6482 17.6021 59.2203 17.6021 57.459V33.9387Z"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_841_30597)">
      <path
        d="M17.6021 33.9387C17.6021 32.1774 18.9813 30.7495 20.6827 30.7495H35.701C37.4024 30.7495 38.7817 32.1774 38.7817 33.9387V57.459C38.7817 59.2203 37.4024 60.6482 35.701 60.6482H20.6827C18.9813 60.6482 17.6021 59.2203 17.6021 57.459V33.9387Z"
        fill="#925454"
      />
      <path
        d="M17.6021 33.9387C17.6021 32.1774 18.9813 30.7495 20.6827 30.7495H35.701C37.4024 30.7495 38.7817 32.1774 38.7817 33.9387V57.459C38.7817 59.2203 37.4024 60.6482 35.701 60.6482H20.6827C18.9813 60.6482 17.6021 59.2203 17.6021 57.459V33.9387Z"
        fill="url(#paint1_linear_841_30597)"
        fillOpacity={0.8}
      />
      <path
        d="M17.6021 33.9387C17.6021 32.1774 18.9813 30.7495 20.6827 30.7495H35.701C37.4024 30.7495 38.7817 32.1774 38.7817 33.9387V57.459C38.7817 59.2203 37.4024 60.6482 35.701 60.6482H20.6827C18.9813 60.6482 17.6021 59.2203 17.6021 57.459V33.9387Z"
        fill="url(#paint2_linear_841_30597)"
        fillOpacity={0.2}
      />
      <path
        d="M17.6021 33.9387C17.6021 32.1774 18.9813 30.7495 20.6827 30.7495H35.701C37.4024 30.7495 38.7817 32.1774 38.7817 33.9387V57.459C38.7817 59.2203 37.4024 60.6482 35.701 60.6482H20.6827C18.9813 60.6482 17.6021 59.2203 17.6021 57.459V33.9387Z"
        fill="url(#paint3_linear_841_30597)"
        fillOpacity={0.4}
      />
      <path
        d="M17.6021 58.1582H19.5275C21.0694 58.1582 22.3193 59.4522 22.3193 61.0484V62.1447H17.6021V58.1582Z"
        fill="#1E1E1E"
      />
      <path
        d="M17.6021 58.1582H19.5275C21.0694 58.1582 22.3193 59.4522 22.3193 61.0484V62.1447H17.6021V58.1582Z"
        fill="url(#paint4_linear_841_30597)"
        fillOpacity={0.2}
      />
      <path
        d="M38.8608 58.1582H36.9354C35.3935 58.1582 34.1436 59.4522 34.1436 61.0484V62.1447H38.8608V58.1582Z"
        fill="#1E1E1E"
      />
      <path
        d="M38.8608 58.1582H36.9354C35.3935 58.1582 34.1436 59.4522 34.1436 61.0484V62.1447H38.8608V58.1582Z"
        fill="url(#paint5_linear_841_30597)"
        fillOpacity={0.2}
      />
    </g>
    <ellipse
      cx={20.9789}
      cy={61.9905}
      rx={1.39593}
      ry={1.4451}
      fill="#1E1E1E"
    />
    <ellipse
      cx={20.9404}
      cy={61.9902}
      rx={0.818303}
      ry={0.847129}
      fill="#D9D9D9"
    />
    <ellipse
      cx={35.0346}
      cy={61.9905}
      rx={1.39593}
      ry={1.4451}
      fill="#1E1E1E"
    />
    <ellipse
      cx={34.996}
      cy={61.9902}
      rx={0.818303}
      ry={0.847129}
      fill="#D9D9D9"
    />
    <g filter="url(#filter0_i_841_30597)">
      <path
        d="M20.3018 34.0392C20.3018 33.8741 20.4311 33.7402 20.5906 33.7402C20.7501 33.7402 20.8794 33.8741 20.8794 34.0392V57.0612C20.8794 57.2263 20.7501 57.3602 20.5906 57.3602C20.4311 57.3602 20.3018 57.2263 20.3018 57.0612V34.0392Z"
        fill="black"
        fillOpacity={0.05}
      />
    </g>
    <g filter="url(#filter1_i_841_30597)">
      <path
        d="M24.0869 34.0392C24.0869 33.8741 24.2162 33.7402 24.3757 33.7402C24.5352 33.7402 24.6645 33.8741 24.6645 34.0392V57.0612C24.6645 57.2263 24.5352 57.3602 24.3757 57.3602C24.2162 57.3602 24.0869 57.2263 24.0869 57.0612V34.0392Z"
        fill="black"
        fillOpacity={0.05}
      />
    </g>
    <g filter="url(#filter2_i_841_30597)">
      <path
        d="M28.0483 34.0392C28.0483 33.8741 28.1776 33.7402 28.3372 33.7402C28.4967 33.7402 28.626 33.8741 28.626 34.0392V57.0612C28.626 57.2263 28.4967 57.3602 28.3372 57.3602C28.1776 57.3602 28.0483 57.2263 28.0483 57.0612V34.0392Z"
        fill="black"
        fillOpacity={0.05}
      />
    </g>
    <g filter="url(#filter3_i_841_30597)">
      <path
        d="M35.7944 34.0392C35.7944 33.8741 35.9237 33.7402 36.0832 33.7402C36.2428 33.7402 36.3721 33.8741 36.3721 34.0392V57.0612C36.3721 57.2263 36.2428 57.3602 36.0832 57.3602C35.9237 57.3602 35.7944 57.2263 35.7944 57.0612V34.0392Z"
        fill="black"
        fillOpacity={0.05}
      />
    </g>
    <g filter="url(#filter4_i_841_30597)">
      <path
        d="M31.8335 34.0392C31.8335 33.8741 31.9628 33.7402 32.1223 33.7402C32.2818 33.7402 32.4111 33.8741 32.4111 34.0392V57.0612C32.4111 57.2263 32.2818 57.3602 32.1223 57.3602C31.9628 57.3602 31.8335 57.2263 31.8335 57.0612V34.0392Z"
        fill="black"
        fillOpacity={0.05}
      />
    </g>
    <path
      d="M23.0107 21.4798C23.0107 20.6542 23.68 19.9849 24.5057 19.9849H31.4317C32.2573 19.9849 32.9267 20.6542 32.9267 21.4798H31.0253C31.0253 21.4798 29.2202 21.2805 27.9687 21.2805C26.7172 21.2805 24.9121 21.4798 24.9121 21.4798H23.0107Z"
      fill="#D9D9D9"
    />
    <path
      d="M24.8096 21.479V21.18C24.8096 21.0149 24.9389 20.881 25.0984 20.881H30.5858C30.7453 20.881 30.8746 21.0149 30.8746 21.18V21.479C30.8746 21.479 29.3824 21.2797 27.8421 21.2797C26.3018 21.2797 24.8096 21.479 24.8096 21.479Z"
      fill="#1E1E1E"
    />
    <rect
      x={45.5244}
      y={17.2949}
      width={0.577626}
      height={6.27872}
      fill="#ADADAD"
    />
    <path
      d="M44.9854 16.897C44.9854 16.4016 45.3733 16 45.8518 16Lnan nanL45.8518 16C46.3303 16 46.7182 16.4016 46.7182 16.897V17.8855C46.7182 17.945 46.6716 17.9932 46.6142 17.9932H45.0894C45.0319 17.9932 44.9854 17.945 44.9854 17.8855V16.897Z"
      fill="#D9D9D9"
    />
    <path
      d="M45.2742 24.3706C45.1147 24.3706 44.9854 24.2367 44.9854 24.0716V23.4736C44.9854 23.3085 45.1147 23.1747 45.2742 23.1747H46.4294C46.5889 23.1747 46.7182 23.3085 46.7182 23.4736V24.0716C46.7182 24.2367 46.5889 24.3706 46.4294 24.3706H45.2742Z"
      fill="#1E1E1E"
    />
    <mask
      id="mask1_841_30597"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={41}
      y={23}
      width={14}
      height={38}
    >
      <path
        d="M41.5625 26.6623C41.5625 24.956 42.8987 23.5728 44.5469 23.5728H51.3821C53.0304 23.5728 54.3665 24.956 54.3665 26.6623V57.4579C54.3665 59.1642 53.0304 60.5475 51.3821 60.5475H44.5469C42.8987 60.5475 41.5625 59.1642 41.5625 57.4579V26.6623Z"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask1_841_30597)">
      <path
        d="M41.5625 23.5728H54.3665V60.5475H41.5625V23.5728Z"
        fill="#444444"
      />
      <path
        d="M41.5625 23.5728H54.3665V60.5475H41.5625V23.5728Z"
        fill="url(#paint6_linear_841_30597)"
        fillOpacity={0.6}
      />
      <path
        d="M41.5625 23.5728H54.3665V60.5475H41.5625V23.5728Z"
        fill="url(#paint7_linear_841_30597)"
        fillOpacity={0.4}
      />
      <g filter="url(#filter5_i_841_30597)">
        <path
          d="M43.1111 27.6597C42.9516 27.6597 42.8223 27.5258 42.8223 27.3607C42.8223 27.1956 42.9516 27.0617 43.1111 27.0617H52.6419C52.8014 27.0617 52.9307 27.1956 52.9307 27.3607C52.9307 27.5258 52.8014 27.6597 52.6419 27.6597H43.1111Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <g filter="url(#filter6_i_841_30597)">
        <path
          d="M43.1111 31.6445C42.9516 31.6445 42.8223 31.5107 42.8223 31.3455C42.8223 31.1804 42.9516 31.0466 43.1111 31.0466H52.6419C52.8014 31.0466 52.9307 31.1804 52.9307 31.3455C52.9307 31.5107 52.8014 31.6445 52.6419 31.6445H43.1111Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <g filter="url(#filter7_i_841_30597)">
        <path
          d="M43.1111 35.6318C42.9516 35.6318 42.8223 35.498 42.8223 35.3328C42.8223 35.1677 42.9516 35.0339 43.1111 35.0339H52.6419C52.8014 35.0339 52.9307 35.1677 52.9307 35.3328C52.9307 35.498 52.8014 35.6318 52.6419 35.6318H43.1111Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <g filter="url(#filter8_i_841_30597)">
        <path
          d="M43.1111 39.6196C42.9516 39.6196 42.8223 39.4858 42.8223 39.3206C42.8223 39.1555 42.9516 39.0217 43.1111 39.0217H52.6419C52.8014 39.0217 52.9307 39.1555 52.9307 39.3206C52.9307 39.4858 52.8014 39.6196 52.6419 39.6196H43.1111Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <g filter="url(#filter9_i_841_30597)">
        <path
          d="M43.1111 43.604C42.9516 43.604 42.8223 43.4701 42.8223 43.305C42.8223 43.1399 42.9516 43.006 43.1111 43.006H52.6419C52.8014 43.006 52.9307 43.1399 52.9307 43.305C52.9307 43.4701 52.8014 43.604 52.6419 43.604H43.1111Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <g filter="url(#filter10_i_841_30597)">
        <path
          d="M43.1111 47.5913C42.9516 47.5913 42.8223 47.4574 42.8223 47.2923C42.8223 47.1272 42.9516 46.9933 43.1111 46.9933H52.6419C52.8014 46.9933 52.9307 47.1272 52.9307 47.2923C52.9307 47.4574 52.8014 47.5913 52.6419 47.5913H43.1111Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <g filter="url(#filter11_i_841_30597)">
        <path
          d="M43.1111 51.5791C42.9516 51.5791 42.8223 51.4452 42.8223 51.2801C42.8223 51.115 42.9516 50.9811 43.1111 50.9811H52.6419C52.8014 50.9811 52.9307 51.115 52.9307 51.2801C52.9307 51.4452 52.8014 51.5791 52.6419 51.5791H43.1111Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <path
        d="M44.9844 38.222C44.9844 37.8917 45.243 37.624 45.562 37.624C45.881 37.624 46.1396 37.8917 46.1396 38.222V45.9957C46.1396 46.3259 45.881 46.5936 45.562 46.5936C45.243 46.5936 44.9844 46.3259 44.9844 45.9957V38.222Z"
        fill="#1E1E1E"
      />
      <path
        d="M44.9844 38.222C44.9844 37.8917 45.243 37.624 45.562 37.624C45.881 37.624 46.1396 37.8917 46.1396 38.222V45.9957C46.1396 46.3259 45.881 46.5936 45.562 46.5936C45.243 46.5936 44.9844 46.3259 44.9844 45.9957V38.222Z"
        fill="url(#paint8_linear_841_30597)"
        fillOpacity={0.2}
      />
      <g filter="url(#filter12_i_841_30597)">
        <path
          d="M43.1111 55.5635C42.9516 55.5635 42.8223 55.4296 42.8223 55.2645C42.8223 55.0994 42.9516 54.9655 43.1111 54.9655H52.6419C52.8014 54.9655 52.9307 55.0994 52.9307 55.2645C52.9307 55.4296 52.8014 55.5635 52.6419 55.5635H43.1111Z"
          fill="black"
          fillOpacity={0.05}
        />
      </g>
      <path
        d="M41.0234 58.0566H42.9489C44.4908 58.0566 45.7407 59.3506 45.7407 60.9468V62.0431H41.0234V58.0566Z"
        fill="#1E1E1E"
      />
      <path
        d="M41.0234 58.0566H42.9489C44.4908 58.0566 45.7407 59.3506 45.7407 60.9468V62.0431H41.0234V58.0566Z"
        fill="url(#paint9_linear_841_30597)"
        fillOpacity={0.2}
      />
      <path
        d="M55.0747 58.0566H53.1493C51.6074 58.0566 50.3574 59.3506 50.3574 60.9468V62.0431H55.0747V58.0566Z"
        fill="#1E1E1E"
      />
      <path
        d="M55.0747 58.0566H53.1493C51.6074 58.0566 50.3574 59.3506 50.3574 60.9468V62.0431H55.0747V58.0566Z"
        fill="url(#paint10_linear_841_30597)"
        fillOpacity={0.2}
      />
    </g>
    <path
      d="M43.543 60.8957C43.543 60.703 43.6938 60.5469 43.8799 60.5469C44.066 60.5469 44.2169 60.703 44.2169 60.8957V63.0883C44.2169 63.2809 44.066 63.4371 43.8799 63.4371C43.6938 63.4371 43.543 63.2809 43.543 63.0883V60.8957Z"
      fill="#1E1E1E"
    />
    <path
      d="M44.4453 60.8957C44.4453 60.703 44.5962 60.5469 44.7823 60.5469C44.9684 60.5469 45.1192 60.703 45.1192 60.8957V63.0883C45.1192 63.2809 44.9684 63.4371 44.7823 63.4371C44.5962 63.4371 44.4453 63.2809 44.4453 63.0883V60.8957Z"
      fill="#1E1E1E"
    />
    <path
      d="M44.2637 61.2442C44.2637 61.1892 44.3068 61.1445 44.3599 61.1445C44.4131 61.1445 44.4562 61.1892 44.4562 61.2442V62.7391C44.4562 62.7942 44.4131 62.8388 44.3599 62.8388C44.3068 62.8388 44.2637 62.7942 44.2637 62.7391V61.2442Z"
      fill="#D9D9D9"
    />
    <path
      d="M50.9268 60.8957C50.9268 60.703 51.0776 60.5469 51.2637 60.5469C51.4498 60.5469 51.6007 60.703 51.6007 60.8957V63.0883C51.6007 63.2809 51.4498 63.4371 51.2637 63.4371C51.0776 63.4371 50.9268 63.2809 50.9268 63.0883V60.8957Z"
      fill="#1E1E1E"
    />
    <path
      d="M51.8291 60.8957C51.8291 60.703 51.98 60.5469 52.166 60.5469C52.3521 60.5469 52.503 60.703 52.503 60.8957V63.0883C52.503 63.2809 52.3521 63.4371 52.166 63.4371C51.98 63.4371 51.8291 63.2809 51.8291 63.0883V60.8957Z"
      fill="#1E1E1E"
    />
    <path
      d="M51.6475 61.2442C51.6475 61.1892 51.6906 61.1445 51.7437 61.1445C51.7969 61.1445 51.84 61.1892 51.84 61.2442V62.7391C51.84 62.7942 51.7969 62.8388 51.7437 62.8388C51.6906 62.8388 51.6475 62.7942 51.6475 62.7391V61.2442Z"
      fill="#D9D9D9"
    />
    <rect
      x={47.6865}
      y={23.5737}
      width={0.78639}
      height={36.9074}
      fill="#313131"
    />
    <rect
      x={47.8682}
      y={23.5737}
      width={0.288813}
      height={36.9747}
      fill="#1E1E1E"
    />
    <line
      x1={48.0821}
      y1={23.606}
      x2={48.0821}
      y2={60.4489}
      stroke="#616161"
      strokeWidth={0.0645043}
      strokeLinecap="round"
      strokeDasharray="0.13 0.13"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.7854 25.7732C47.6839 25.735 47.5721 25.7834 47.5307 25.8835L47.0321 27.089C46.9719 27.2344 47.0453 27.4034 47.1927 27.459C47.3402 27.5145 47.5013 27.4339 47.5455 27.2825L47.9121 26.0272C47.9425 25.923 47.8869 25.8114 47.7854 25.7732ZM47.2058 27.3648C47.3057 27.4024 47.4162 27.3491 47.4526 27.2456C47.4889 27.1422 47.4374 27.0278 47.3375 26.9902C47.2376 26.9525 47.1271 27.0058 47.0907 27.1093C47.0544 27.2127 47.1059 27.3271 47.2058 27.3648Z"
      fill="#1E1E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.7854 26.6643C47.6839 26.6261 47.5721 26.6745 47.5307 26.7746L47.0321 27.9801C46.9719 28.1256 47.0453 28.2945 47.1927 28.3501C47.3402 28.4056 47.5013 28.325 47.5455 28.1736L47.9121 26.9183C47.9425 26.8141 47.8869 26.7026 47.7854 26.6643ZM47.2058 28.2559C47.3057 28.2935 47.4162 28.2402 47.4526 28.1368C47.4889 28.0333 47.4374 27.9189 47.3375 27.8813C47.2376 27.8436 47.1271 27.897 47.0907 28.0004C47.0544 28.1038 47.1059 28.2182 47.2058 28.2559Z"
      fill="#1E1E1E"
    />
    <g filter="url(#filter13_b_841_30597)">
      <path
        d="M14.0001 46.0513C15.3762 48.8676 58.2344 48.1896 58.8242 46.0513C58.8242 47.0422 58.8242 52.2975 58.8242 52.9233C58.0378 55.5832 16.1626 56.0004 14.0001 52.9233C14.0001 51.7238 13.9999 47.1465 14.0001 46.0513Z"
        fill="white"
        fillOpacity={0.9}
      />
    </g>
    <g filter="url(#filter14_b_841_30597)">
      <ellipse
        cx={36.3755}
        cy={50.2115}
        rx={13.3686}
        ry={13.7896}
        fill="white"
        fillOpacity={0.9}
      />
    </g>
    <path
      d="M31.1172 44.4743C31.1172 44.4743 34.691 45.2935 36.5441 43.1772C36.5441 45.7713 36.5441 55.6698 36.5441 57.3082C32.3746 56.2159 31.1171 52.4613 31.1172 51.0278C31.1173 49.5942 31.1172 44.4743 31.1172 44.4743Z"
      fill="url(#paint11_linear_841_30597)"
    />
    <path
      d="M41.9282 44.4743C41.9282 44.4743 38.3544 45.2935 36.5014 43.1772C36.5014 45.7713 36.5014 55.6698 36.5014 57.3082C40.6708 56.2159 41.9283 52.4613 41.9282 51.0278C41.9281 49.5942 41.9282 44.4743 41.9282 44.4743Z"
      fill="url(#paint12_linear_841_30597)"
    />
    <path
      d="M41.9282 44.4743C41.9282 44.4743 38.3544 45.2935 36.5014 43.1772C36.5014 45.7713 36.5014 55.6698 36.5014 57.3082C40.6708 56.2159 41.9283 52.4613 41.9282 51.0278C41.9281 49.5942 41.9282 44.4743 41.9282 44.4743Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M34.4122 48.9491L33.8574 49.5117L33.8574 49.5116C33.8324 49.5369 33.8184 49.5712 33.8184 49.6071C33.8184 49.6429 33.8324 49.6772 33.8574 49.7025L35.9324 51.8069C35.9844 51.8596 36.0687 51.8596 36.1207 51.8069L39.3386 48.5436C39.3906 48.4909 39.3906 48.4054 39.3386 48.3527L38.7839 47.7901C38.759 47.7647 38.7251 47.7505 38.6898 47.7505C38.6544 47.7505 38.6206 47.7647 38.5956 47.7901L36.0266 50.3954L34.6005 48.9491C34.5484 48.8964 34.4641 48.8964 34.4122 48.9491Z"
      fill="#95F47D"
    />
    <defs>
      <filter
        id="filter0_i_841_30597"
        x={20.3018}
        y={33.7402}
        width={0.577637}
        height={23.9426}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter1_i_841_30597"
        x={24.0869}
        y={33.7402}
        width={0.577637}
        height={23.9426}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter2_i_841_30597"
        x={28.0483}
        y={33.7402}
        width={0.577637}
        height={23.9426}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter3_i_841_30597"
        x={35.7944}
        y={33.7402}
        width={0.577637}
        height={23.9426}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter4_i_841_30597"
        x={31.8335}
        y={33.7402}
        width={0.577637}
        height={23.9426}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter5_i_841_30597"
        x={42.8223}
        y={27.0615}
        width={10.1084}
        height={0.920666}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter6_i_841_30597"
        x={42.8223}
        y={31.0464}
        width={10.1084}
        height={0.920666}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter7_i_841_30597"
        x={42.8223}
        y={35.0337}
        width={10.1084}
        height={0.920666}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter8_i_841_30597"
        x={42.8223}
        y={39.0215}
        width={10.1084}
        height={0.920666}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter9_i_841_30597"
        x={42.8223}
        y={43.0059}
        width={10.1084}
        height={0.920666}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter10_i_841_30597"
        x={42.8223}
        y={46.9932}
        width={10.1084}
        height={0.920666}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter11_i_841_30597"
        x={42.8223}
        y={50.981}
        width={10.1084}
        height={0.920666}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter12_i_841_30597"
        x={42.8223}
        y={54.9653}
        width={10.1084}
        height={0.920666}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.322522} />
        <feGaussianBlur stdDeviation={0.322522} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_841_30597"
        />
      </filter>
      <filter
        id="filter13_b_841_30597"
        x={11.4198}
        y={43.4711}
        width={49.9846}
        height={14.1867}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.29009} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_841_30597"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_841_30597"
          result="shape"
        />
      </filter>
      <filter
        id="filter14_b_841_30597"
        x={21.7167}
        y={35.1318}
        width={29.3175}
        height={30.1593}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.645043} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_841_30597"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_841_30597"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_841_30597"
        x1={0}
        y1={0}
        x2={64.7822}
        y2={40.9614}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#474747" />
        <stop offset={0.872554} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_30597"
        x1={17.7073}
        y1={36.2201}
        x2={38.5467}
        y2={36.4107}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CD4848" />
        <stop offset={0.0414636} stopColor="#A22626" />
        <stop offset={0.957069} stopColor="#992222" />
        <stop offset={1} stopColor="#CD4848" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_841_30597"
        x1={19.6732}
        y1={31.1504}
        x2={33.5336}
        y2={60.4316}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.131169} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint3_linear_841_30597"
        x1={38.1534}
        y1={31.9616}
        x2={18.8743}
        y2={59.7986}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.363327} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint4_linear_841_30597"
        x1={19.9607}
        y1={58.1582}
        x2={19.9607}
        y2={62.1447}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.184356} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint5_linear_841_30597"
        x1={36.5022}
        y1={58.1582}
        x2={36.5022}
        y2={62.1447}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.184356} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint6_linear_841_30597"
        x1={41.3912}
        y1={37.4358}
        x2={54.3667}
        y2={37.4358}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#888888" />
        <stop offset={0.090846} stopColor="#444444" />
        <stop offset={0.935773} stopColor="#444444" />
        <stop offset={1} stopColor="#888888" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_841_30597"
        x1={53.7769}
        y1={24.6601}
        x2={41.3157}
        y2={58.6876}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.218513} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint8_linear_841_30597"
        x1={45.562}
        y1={37.624}
        x2={45.562}
        y2={46.5936}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.184356} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint9_linear_841_30597"
        x1={43.3821}
        y1={58.0566}
        x2={43.3821}
        y2={62.0431}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.184356} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint10_linear_841_30597"
        x1={52.7161}
        y1={58.0566}
        x2={52.7161}
        y2={62.0431}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.184356} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint11_linear_841_30597"
        x1={31.219}
        y1={49.2754}
        x2={36.2321}
        y2={49.2766}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopColor="#181E1F" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_841_30597"
        x1={41.8264}
        y1={49.2754}
        x2={36.8133}
        y2={49.2766}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopColor="#253033" />
      </linearGradient>
    </defs>
  </svg>
);
export { LuggageIconShaded };
