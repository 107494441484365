import { Fragment, MouseEventHandler } from "react";

import {
  LandingCardFeatureStyle,
  PointsContainer,
  AbsTag,
  LogoContainer,
  ZeroPayment,
  FreeCard,
} from "./LandingCardFeature.style";

import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import { landingCardShowcaseDataProps } from "../../mockData";
import { theme } from "../../css/Global";
import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";
import { useDevice } from "../../../hooks/useDevice";
import {
  PageNameMapping,
  ElementNameMapping,
  VERSIONS,
  PERSUASION_TYPE,
  PROGRAM_DETAILS_ENUM,
  IMAGE_URLS,
} from "../../../constants";
import { getElementUniqueIds, getCamelCaseStr } from "../../../utils";
import PersuasionListing from "./Persuasion";
import PoweredBy from "../PoweredBy/PoweredBy";
import CardCtaPersuasion from "./Persuasions/CardCtaPersuasion";
import { FreeCardOffer } from "./Persuasions/ProductOffers";
import { getPersuasionsProps } from "../../../utils/componentPropsMapper";

interface LandingCardFeaturePropsV3 {
  data: landingCardShowcaseDataProps;
  margin?: string;
  padding?: string;
  border?: string;
  borderWidth?: string;
  idx?: number;
  onClick?: (data: landingCardShowcaseDataProps, idx?: number) => void;
  loadCurrenyBtnHandler: (data: landingCardShowcaseDataProps) => void;
  recommendedBtnHandler: (data: landingCardShowcaseDataProps) => void;
  startFromWhereLeftHandler: (data: landingCardShowcaseDataProps) => void;
  docsPendingHandler: (data: landingCardShowcaseDataProps) => void;
  onClickAnotherLocation: (data: landingCardShowcaseDataProps) => void;
  onClickLoadMoney?: () => void;
  viewDashBoardClickHandler?: (data: landingCardShowcaseDataProps) => void;
  viewOrdersClickHandler?: (data: landingCardShowcaseDataProps) => void;
  startOrderClicked: (data: landingCardShowcaseDataProps) => void;
  deliveryUnavailbleClickHandler: MouseEventHandler<HTMLSpanElement>;
  isInfoOnRightSide: boolean;
  sendCrossSellPdtEvent: ({
    eventName,
    eventValue,
  }: {
    eventName: string;
    eventValue: string;
  }) => void;
  reloadTripHandler: (data: landingCardShowcaseDataProps) => void;
  bestRateApplicableTxt?: string;
}

const LandingCardFeatureV3 = ({
  margin,
  padding,
  border,
  borderWidth,
  data,
  idx,
  loadCurrenyBtnHandler,
  recommendedBtnHandler,
  startFromWhereLeftHandler,
  docsPendingHandler,
  onClick,
  onClickAnotherLocation,
  onClickLoadMoney,
  viewDashBoardClickHandler,
  viewOrdersClickHandler,
  isInfoOnRightSide,
  startOrderClicked,
  deliveryUnavailbleClickHandler,
  sendCrossSellPdtEvent,
  reloadTripHandler,
  bestRateApplicableTxt,
}: LandingCardFeaturePropsV3) => {
  const device = useDevice();

  const {
    logoSrc,
    label,
    subLabel,
    featuresArr,
    featureArrDesktop,
    loadCurrency,
    startOrder,
    logoDesc,
    isRecommendNotification,
    viewOrdersPersuasion,
    version,
    persuasions,
  } = data;

  const loadCurrencyHandler = (e: any, data: landingCardShowcaseDataProps) => {
    loadCurrenyBtnHandler?.(data);

    e.stopPropagation();
  };

  const onClickViewDashboard = (
    e: React.MouseEvent<HTMLAnchorElement>,
    data: landingCardShowcaseDataProps
  ) => {
    viewDashBoardClickHandler?.(data);
    e.stopPropagation();
  };

  const onClickViewOrders = (
    e: React.MouseEvent<HTMLAnchorElement>,
    data: landingCardShowcaseDataProps
  ) => {
    viewOrdersClickHandler?.(data);
    e.stopPropagation();
  };

  const getFreeCardPersuation = () => {
    const persuarionProps = getPersuasionsProps(data);
    return persuarionProps[PERSUASION_TYPE.FREE_CARD];
  };

  return device ? (
    <LandingCardFeatureStyle
      margin={margin}
      border={border}
      padding={padding}
      borderWidth={borderWidth}
      version={version}
      onClick={() => onClick?.(data, idx)}
      isZeroPayment={
        data?.program_details?.type === PROGRAM_DETAILS_ENUM.FREE_CARD
      }
    >
      {device === "lg" &&
        data?.program_details?.type === PROGRAM_DETAILS_ENUM.FREE_CARD && (
          <ZeroPayment>
            <ImageTag
              src={IMAGE_URLS.ZERO_PAYMENT_FREE_CARD_IMAGE}
              width="108px"
              height="22px"
            />
          </ZeroPayment>
        )}

      {isRecommendNotification && device === "lg" && (
        <AbsTag>
          <SpanTag color={theme.greenText}>Recommended</SpanTag>
        </AbsTag>
      )}
      <FlexComp
        flexDirection={
          device === "lg" ? (isInfoOnRightSide ? "row" : "column") : "row"
        }
        flex="1"
        padding="0px"
        gap={device === "lg" ? "0px" : "16px"}
        isCursorPointer
      >
        <LogoContainer
          id={getElementUniqueIds(
            PageNameMapping.LISTING_PAGE,
            getCamelCaseStr(data.label),
            ElementNameMapping.LOGO_CONTAINER
          )}
        >
          <ImageTag src={logoSrc} width={device === "lg" ? "120px" : "100%"} />
          {logoDesc && (
            <ParaTag
              margin={device === "lg" ? "0px 0px 6px" : "0px"}
              fontSize={device === "sm" ? "xxs" : "xs"}
              color={theme.greyText}
            >
              {logoDesc.normalText}{" "}
              {logoDesc.boldText && (
                <SpanTag
                  fontWeight="bold"
                  color={theme.greyText}
                  fontSize={device === "sm" ? "xxs" : "xs"}
                >
                  {logoDesc.boldText}
                </SpanTag>
              )}
            </ParaTag>
          )}
        </LogoContainer>
        <FlexComp
          padding="0px"
          gap="0px"
          flexDirection="column"
          isCursorPointer
        >
          <FlexComp
            // handleClick={() => onClick(data, idx)}
            flex="0"
            gap="12px"
            justifyContent="space-between"
            alignItems="center"
            isCursorPointer
          >
            <ParaTag
              id={getElementUniqueIds(
                PageNameMapping.LISTING_PAGE,
                getCamelCaseStr(data.label),
                ElementNameMapping.HEADING
              )}
              fontSize="large"
              fontWeight="bold"
            >
              {label}
            </ParaTag>
            {!!startOrder?.linkBtnInMobile && device === "lg" && (
              <CardCtaPersuasion
                cardData={startOrder}
                rightBtnClickHandler={(e) => {
                  e.stopPropagation();
                  startOrderClicked(data);
                }}
                id={getElementUniqueIds(
                  PageNameMapping.LISTING_PAGE,
                  getCamelCaseStr(data.label),
                  ElementNameMapping.QUICK_LOAD // @TODO: UPDATE KEY
                )}
              />
            )}
            {!!loadCurrency?.linkBtnInMobile && device === "lg" && (
              <CardCtaPersuasion
                cardData={loadCurrency}
                rightBtnClickHandler={(e) => onClickViewDashboard(e, data)}
                leftBtnClickHandler={(e) => loadCurrencyHandler(e, data)}
                id={getElementUniqueIds(
                  PageNameMapping.LISTING_PAGE,
                  getCamelCaseStr(data.label),
                  ElementNameMapping.QUICK_LOAD
                )}
              />
            )}
          </FlexComp>
          {/* <PoweredBy
                        margin="0px"
                        fontSize={device === 'lg' ? 'xs' : 'xxs'}
                        fontWeight="regular"
                        color={theme.greyText}
                        label={'Powered By'}
                        imgSrc={
                            'https://tripmoneycmsimgak.mmtcdn.com/img/bmf_transaprent_d790c47e7e.png'
                        }
                    /> */}
          {!!subLabel && <ParaTag fontSize="sm">{subLabel}</ParaTag>}
          {featuresArr && featureArrDesktop && !loadCurrency && (
            <PointsContainer>
              {(device === "lg" ? featureArrDesktop : featuresArr).map(
                (item, idx) => (
                  <SpanTag
                    fontSize={device === "lg" ? "sm" : "xs"}
                    color={theme.greyText}
                    key={idx}
                    display="inline"
                  >
                    {item.label}
                  </SpanTag>
                )
              )}
            </PointsContainer>
          )}
          {loadCurrency && (
            <Fragment>
              <FlexComp
                margin={device === "lg" ? "8px 0px 0px" : "4px 0px 0px"}
                flexDirection="column"
              >
                <FlexComp flexDirection="row" gap="4px" margin="0px 0px 12px">
                  {!!loadCurrency.label && (
                    <ParaTag
                      fontSize={device === "lg" ? "sm" : "xs"}
                      fontWeight="regular"
                    >
                      {loadCurrency.label}
                    </ParaTag>
                  )}
                  {!!loadCurrency.subLabel && (
                    <ParaTag
                      fontSize={device === "lg" ? "sm" : "xs"}
                      color={theme.greyText}
                      fontWeight="bold"
                    >
                      {loadCurrency.subLabel}
                    </ParaTag>
                  )}
                </FlexComp>

                {device === "lg" && !!loadCurrency.addText && (
                  <AnchorTag
                    id={"ListingPage_load_currency_btn"}
                    width="fit-content"
                    fontSize={"sm"}
                    fontWeight="bold"
                    margin={"0px"}
                    handleClick={(e: any) => loadCurrencyHandler(e, data)}
                  >
                    {loadCurrency.addText}
                  </AnchorTag>
                )}
              </FlexComp>
            </Fragment>
          )}
          {viewOrdersPersuasion && version === VERSIONS.V2 && (
            <FlexComp
              margin={device === "lg" ? "12px 0px 0px" : "4px 0px 0px"}
              flexDirection="column"
            >
              {viewOrdersPersuasion?.linkBtnInMobile && (
                <AnchorTag
                  width="fit-content"
                  fontSize={device === "lg" ? "sm" : "xs"}
                  fontWeight="bold"
                  margin={device === "lg" ? "0px" : "12px 0 0 0"}
                  handleClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    onClickViewOrders(e, data)
                  }
                >
                  {viewOrdersPersuasion?.linkBtnInMobile?.label}
                </AnchorTag>
              )}
            </FlexComp>
          )}
          <FreeCardOffer persuasionProps={getFreeCardPersuation()} />
        </FlexComp>
      </FlexComp>
      {device === "sm" && !!startOrder?.linkBtnInMobile && (
        <CardCtaPersuasion
          id={getElementUniqueIds(
            PageNameMapping.LISTING_PAGE,
            getCamelCaseStr(data.label),
            ElementNameMapping.QUICK_LOAD
          )}
          cardData={startOrder}
          rightBtnClickHandler={(e) => {
            e.stopPropagation();
            startOrderClicked(data);
          }}
          leftBtnClickHandler={(e) => loadCurrencyHandler(e, data)}
          bestRateApplicableTxt={bestRateApplicableTxt}
        />
      )}
      {device === "sm" &&
        (!!loadCurrency?.addText || !!loadCurrency?.linkBtnInMobile) && (
          <CardCtaPersuasion
            id={getElementUniqueIds(
              PageNameMapping.LISTING_PAGE,
              getCamelCaseStr(data.label),
              ElementNameMapping.QUICK_LOAD
            )}
            cardData={loadCurrency}
            rightBtnClickHandler={(e) => onClickViewDashboard(e, data)}
            leftBtnClickHandler={(e) => loadCurrencyHandler(e, data)}
          />
        )}

      {device === "lg" && bestRateApplicableTxt && (
        <FlexComp alignSelf="flex-start" padding="0">
          <FlexComp
            bgColor="linear-gradient(90deg, #ECEAF8 0%, rgba(255, 255, 255, 0) 93.75%)"
            padding="4px"
            margin="2px 0 0 0px"
            borderRadius="4px"
          >
            <SpanTag color="#3A27B0" fontSize="xs">
              {bestRateApplicableTxt}
            </SpanTag>
          </FlexComp>
        </FlexComp>
      )}

      <PersuasionListing
        handlers={{
          onClickAnotherLocation,
          docsPendingHandler,
          loadCurrenyBtnHandler,
          recommendedBtnHandler,
          startFromWhereLeftHandler,
          deliveryUnavailbleClickHandler,
          sendCrossSellPdtEvent,
          reloadTripHandler,
        }}
        cardData={data}
        persuasions={persuasions}
      />
    </LandingCardFeatureStyle>
  ) : (
    <Fragment />
  );
};

export default LandingCardFeatureV3;
