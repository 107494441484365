import { useEffect } from "react";
import Head from "next/head";

import { isValidJSON, withDeviceProvider } from "@tm/ui-widgets";
import { akamaiGetDevice } from "@tm/utility";
import { getAnalytics, PageName } from "@tm/insurance/analytics";
import { withPartnerInsuranceHeader } from "@tm/ui/hoc";

import { ErrorPage } from "..";

export interface ErrorScreenProps {
  error?:
    | {
        message?: {
          title?: string;
          description?: string;
          icon?: string;
        };
      }
    | string;
  trackError?: boolean;
  pageName?: PageName;
}

const analytics = getAnalytics();

export const ErrorScreen = ({
  error,
  trackError = false,
  pageName,
}: ErrorScreenProps) => {
  useEffect(() => {
    if (trackError && pageName && error && typeof error !== "string") {
      analytics?.trackPageLoadErrorEvent({
        pageName,
        actionType: "page_load_error",
        error: `${error?.message?.title}|
                  ${error?.message?.description}`,
      });
    }
  }, [error, pageName, trackError]);

  const title =
    (typeof error !== "string" && error?.message?.title) || "Sorry!";
  const description =
    (typeof error !== "string" && error?.message?.description) ||
    "We are having trouble communicating with the servers. Please try again.";
  const icon =
    (typeof error !== "string" && error?.message?.icon) ||
    "https://tripmoneycmsimgak.mmtcdn.com/img/error_Img_1e80a42832.svg";

  return (
    <>
      <Head>
        <title>Tripmoney Error</title>
      </Head>
      {withDeviceProvider(
        withPartnerInsuranceHeader(
          <ErrorPage
            title={title}
            info={description}
            errorImg={icon}
            headerComponent={<></>}
          />
        ),
        akamaiGetDevice({})
      )}
    </>
  );
};
