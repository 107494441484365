import { createContext } from "react";

export interface IConfigContext {
  cmsClientUrl: string;
}
export const ConfigContext = createContext({
  cmsClientUrl: "",
} as IConfigContext);

export const ConfigProvider = ConfigContext.Provider;
