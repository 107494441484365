import { theme } from "@tm/utility/global";
import { SnackbarContainer, SnackbarButton } from "./SnackBar.style";
import { ImageTag, SpanTag } from "@tm/static";
import { CrossIcon } from "../../icons/CrossIcon";

interface SnackBarProps {
  message?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  txtColor?: string;
  bgColor?: string;
  containerStyle?: {};
  showButton?: boolean;
  bottomMargin?: any;
  closeIcon?: string;
  isPosFixed?: boolean;
}

const SnackBar = ({
  message,
  buttonText = "OKAY",
  onButtonClick,
  txtColor = theme.redText,
  bgColor,
  containerStyle,
  showButton = true,
  bottomMargin,
  closeIcon,
  isPosFixed = true,
}: SnackBarProps) => {
  return (
    <SnackbarContainer
      containerStyle={containerStyle}
      style={{
        "--bgColor": bgColor,
      }}
      bottom={bottomMargin}
      isPosFixed={isPosFixed}
    >
      <SpanTag fontSize="xs" color={txtColor}>
        {message}
      </SpanTag>
      {showButton ? (
        <SnackbarButton onClick={onButtonClick}>{buttonText}</SnackbarButton>
      ) : (
        <div onClick={onButtonClick}>
          {closeIcon ? (
            <ImageTag src={closeIcon} showCursorPointer />
          ) : (
            <CrossIcon />
          )}
        </div>
      )}
    </SnackbarContainer>
  );
};

export default SnackBar;
