import React from "react";

import { SpanTagStyle } from "./TextStyles.style";

interface SpanTagProps {
  color?: "black" | "gray" | "blue" | string;
  children?: any;
  fontSize?: "xxs" | "xs" | "sm" | "md" | "large" | "xlarge" | "xxlarge";
  fontWeight?: "regular" | "medium" | "bold" | "black" | "semiBold";
  customWidth?: any;
  handleClick?: any;
  textAlign?: any;
  margin?: any;
  bgColor?: string;
  dangerousText?: string;
  id?: string;
  display?: string;
}

const SpanTag: React.FC<SpanTagProps> = ({
  fontSize = "sm",
  fontWeight = "regular",
  color = "black",
  textAlign,
  margin,
  children,
  bgColor,
  dangerousText,
  id,
  display,
  customWidth,
}) => {
  return dangerousText ? (
    <SpanTagStyle
      id={id}
      margin={margin}
      textAlign={textAlign}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      bgColor={bgColor}
      dangerouslySetInnerHTML={{ __html: dangerousText }}
      display={display}
      customWidth={customWidth}
    ></SpanTagStyle>
  ) : (
    <SpanTagStyle
      id={id}
      margin={margin}
      textAlign={textAlign}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      bgColor={bgColor}
      display={display}
      customWidth={customWidth}
    >
      {children}
    </SpanTagStyle>
  );
};

export default SpanTag;
