import { ImageTagStyle } from "./ImageTag.style";

interface ImageTagProps {
  src: string;
  width?: string;
  margin?: string;
  height?: string;
  showCursorPointer?: boolean;
  alt?: string;
  onClick?: any;
}

const ImageTag = ({
  src,
  width,
  alt = "image-tag",
  margin,
  height,
  showCursorPointer = false,
  onClick = () => {},
}: ImageTagProps) => {
  return (
    <ImageTagStyle
      src={src}
      width={width}
      margin={margin}
      height={height}
      showCursorPointer={showCursorPointer}
      alt={alt}
      onClick={onClick}
    />
  );
};

export default ImageTag;
