interface RoundTickIconProps {
  tickColor?: string;
  bgColor?: string;
  props?: any;
  size?: number;
}

export const RoundTickIcon = ({
  tickColor = "#0EB31F",
  bgColor = "#F2FFF4",
  props,
  size = 32,
}: RoundTickIconProps) => (
  <svg
    width={size}
    height={size + 1}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={16.3652} r={16} fill={bgColor} />
    <path
      d="M8 16.5349L12.1586 20.2655C12.9783 21.0009 14.2383 20.9352 14.9772 20.1186L23.3846 10.8262"
      stroke={tickColor}
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
