import styled from "styled-components";

type IconTextTagStyleProps = {
  margin?: string;
};

export const IconTextTagStyle = styled.div<IconTextTagStyleProps>`
  cursor: pointer;
  display: flex;
  padding: 12px 16px;
  ${(props) => props.margin && `margin: ${props.margin};`}
  background: linear-gradient(
        147.2deg,
        #fff6d8 4.79%,
        #fffaf3 43.98%,
        #f3f0e6 120.5%
    );
  justify-content: space-between;
`;
