import React from "react";

import { H5TagStyle } from "./TextStyles.style";
interface H5TagProps {
  color?: "black" | "gray" | "blue" | string;
  children?: any;
  margin?: any;
}

const H5Tag: React.FC<H5TagProps> = ({ children, margin, color }) => {
  return (
    <H5TagStyle style={{ "--color": color, "--margin": margin }}>
      {children}
    </H5TagStyle>
  );
};

export default H5Tag;
