import styled from "styled-components";
import { deviceType } from "libs/ui-widgets/src/lib/static/utils";

type crdStatusProp = {
  crdStatus?: "SUCCESS" | "CANCELLED" | "INPROGRESS" | "UNAVAILABLE";
};

export const StatusTrackStyle: any = styled.div<crdStatusProp>`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 8px;
  border-radius: 16px;
  border: 1px solid #fff;
  img {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    display: inline-block;
  }
  background: ${(p) =>
    p.crdStatus === "SUCCESS"
      ? "#E4FBF4"
      : p.crdStatus === "CANCELLED"
      ? "#FCDADB"
      : p.crdStatus === "UNAVAILABLE"
      ? "#FFEDD1"
      : "#EAF5FF"};
  @media ${deviceType.desktop} {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16);
  }
`;
