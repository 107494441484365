import React, { useState, useEffect } from "react";

import { BackDropCompStyle } from "./BackDropComp.style";

interface BackDropProp {
  onClose?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const BackDropComp = ({ onClose }: BackDropProp) => {
  const [height, setHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    if (typeof window !== "undefined") {
      setHeight(window.innerHeight);
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, []);

  return (
    <BackDropCompStyle onClick={onClose} height={height}></BackDropCompStyle>
  );
};

export default BackDropComp;
