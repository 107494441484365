import { useState } from "react";
import { theme } from "@tm/utility/global";
import { ParaTag, SpanTag } from "@tm/static";

import {
  MarqueeBarStyle,
  MarqueeRibbon,
  Tag,
  MarqueeContainer,
} from "./Marquee.style";
import { landingMarqueeDataProps } from "@tm/static/data";
import { useBreakpoint } from "@tm/ui/hooks";

interface MarqueeBarProps {
  leftTagText?: string;
  marqueeData: landingMarqueeDataProps[];
  margin?: string;
  isOnBottom?: boolean;
  padding?: string;
  bGColor?: string;
  marqueeCtrBgColor?: string;
  marqueeBarBgColor?: string;
}

const MarqueeBar = ({
  leftTagText,
  marqueeData,
  margin,
  padding,
  isOnBottom = false,
  marqueeCtrBgColor,
  marqueeBarBgColor = "rgba(74, 74, 74, 0.3)",
}: MarqueeBarProps) => {
  const [isPaused, setIsPaused] = useState(false);
  const device = useBreakpoint();

  return (
    <MarqueeBarStyle
      isOnBottom={isOnBottom}
      padding={padding}
      marqueeBarBgColor={marqueeBarBgColor}
      margin={margin}
      onMouseEnter={() => {
        setIsPaused(true);
      }}
      onMouseLeave={() => {
        setIsPaused(false);
      }}
    >
      {leftTagText && (
        <Tag>
          <ParaTag color="white">{leftTagText}</ParaTag>
        </Tag>
      )}
      {marqueeData && (
        <MarqueeContainer
          isOnBottom={isOnBottom}
          marqueeCtrBgColor={marqueeCtrBgColor}
        >
          <MarqueeRibbon isOnBottom={isOnBottom} isPaused={isPaused}>
            {marqueeData.map((item) => {
              return (
                <li key={item.id}>
                  {item.boldText && (
                    <SpanTag
                      color={isOnBottom ? theme.blackText : theme.whiteText}
                      fontWeight="bold"
                      margin="0px 4px 0px 0px"
                      fontSize={device === "sm" ? "xs" : "md"}
                    >
                      {item.boldText}
                    </SpanTag>
                  )}
                  {item.text && (
                    <SpanTag
                      fontSize={device === "sm" ? "xs" : "md"}
                      color={isOnBottom ? theme.greyText6 : theme.whiteText}
                    >
                      {item.text}
                    </SpanTag>
                  )}
                  {item.blueText && (
                    <SpanTag
                      fontSize={device === "sm" ? "xs" : "md"}
                      color={theme.blueText}
                      fontWeight={"bold"}
                    >
                      {item.blueText}
                    </SpanTag>
                  )}
                </li>
              );
            })}
          </MarqueeRibbon>
          <MarqueeRibbon
            isPaused={isPaused}
            aria-hidden="true"
            isOnBottom={isOnBottom}
          >
            {marqueeData.map((item) => {
              return (
                <li key={item.id}>
                  {item.boldText && (
                    <SpanTag
                      color={isOnBottom ? theme.blackText : theme.whiteText}
                      margin="0px 4px 0px 0px"
                      fontWeight="bold"
                      fontSize={device === "sm" ? "xs" : "md"}
                    >
                      {item.boldText}
                    </SpanTag>
                  )}
                  {item.text && (
                    <SpanTag
                      fontSize={device === "sm" ? "xs" : "md"}
                      color={isOnBottom ? theme.greyText6 : theme.whiteText}
                    >
                      {item.text}
                    </SpanTag>
                  )}
                  {item.blueText && (
                    <SpanTag
                      fontSize={device === "sm" ? "xs" : "md"}
                      color={theme.blueText}
                      fontWeight={"bold"}
                    >
                      {item.blueText}
                    </SpanTag>
                  )}
                </li>
              );
            })}
          </MarqueeRibbon>
        </MarqueeContainer>
      )}
    </MarqueeBarStyle>
  );
};

export default MarqueeBar;
