import { Fragment } from "react";

import {
  LandingCardFeatureStyle,
  PointsContainer,
  NotLocationText,
  AbsTag,
  LogoContainer,
} from "./LandingCardFeature.style";

import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import { landingCardShowcaseDataProps } from "../../mockData";
import { theme } from "../../css/Global";
import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import { ArrowIcon } from "../../icons/ArrowIcon";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";
import { LocationIcon } from "../../icons/LocationIcon";
import PersuasionTile from "./PersuasionTile";
import { RoundEcllipseIcon } from "../../icons/RoundEcllipseIcon";
import LandingButton from "../ui-atoms/LandingButton/LandingButton";
import { DocsUploadIcon } from "../../icons/DocsUploadIcon";
import { useDevice } from "../../../hooks/useDevice";
import {
  PageNameMapping,
  ElementNameMapping,
  VERSIONS,
} from "../../../constants";
import { getElementUniqueIds, getCamelCaseStr } from "../../../utils";

interface LandingCardFeatureProps {
  data: landingCardShowcaseDataProps;
  margin?: string;
  padding?: string;
  border?: string;
  borderWidth?: string;
  idx?: number;
  onClick?: (data: landingCardShowcaseDataProps, idx?: number) => void;
  loadCurrenyBtnHandler?: (data: landingCardShowcaseDataProps) => void;
  recommendedBtnHandler?: (data: landingCardShowcaseDataProps) => void;
  startFromWhereLeftHandler?: (data: landingCardShowcaseDataProps) => void;
  docsPendingHandler?: (data: landingCardShowcaseDataProps) => void;
  onClickAnotherLocation?: (data: landingCardShowcaseDataProps) => void;
  onClickLoadMoney?: () => void;
  viewDashBoardClickHandler?: (data: landingCardShowcaseDataProps) => void;
  viewOrdersClickHandler?: (data: landingCardShowcaseDataProps) => void;
  isInfoOnRightSide: boolean;
}

const LandingCardFeature = ({
  margin,
  padding,
  border,
  borderWidth,
  data,
  idx,
  loadCurrenyBtnHandler,
  recommendedBtnHandler,
  startFromWhereLeftHandler,
  docsPendingHandler,
  onClick,
  onClickAnotherLocation,
  onClickLoadMoney,
  viewDashBoardClickHandler,
  viewOrdersClickHandler,
  isInfoOnRightSide,
}: LandingCardFeatureProps) => {
  const device = useDevice();

  const {
    logoSrc,
    arrowIconSrc,
    label,
    subLabel,
    featuresArr,
    featureArrDesktop,
    loadCurrency,
    isleftOffBefore,
    isRecommended,
    logoDesc,
    isRecommendNotification,
    deliveryLabel,
    docsPending,
    isUnavailableAtLoc,
    backendPersuasion,
    viewOrdersPersuasion,
    version,
  } = data;

  const loadCurrencyHandler = (e: any, data: landingCardShowcaseDataProps) => {
    loadCurrenyBtnHandler?.(data);

    e.stopPropagation();
  };

  const onClickViewDashboard = (
    e: React.MouseEvent<HTMLAnchorElement>,
    data: landingCardShowcaseDataProps
  ) => {
    viewDashBoardClickHandler?.(data);
    e.stopPropagation();
  };

  const onClickViewOrders = (
    e: React.MouseEvent<HTMLAnchorElement>,
    data: landingCardShowcaseDataProps
  ) => {
    viewOrdersClickHandler?.(data);
    e.stopPropagation();
  };

  const arrowIcon = arrowIconSrc ? (
    <ImageTag src={arrowIconSrc} />
  ) : (
    <ArrowIcon />
  );

  return device ? (
    <LandingCardFeatureStyle
      margin={margin}
      border={border}
      padding={padding}
      borderWidth={borderWidth}
      version={version}
    >
      {isRecommendNotification && device === "lg" && (
        <AbsTag>
          <SpanTag color={theme.greenText}>Recommended</SpanTag>
        </AbsTag>
      )}
      <FlexComp
        flexDirection={
          device === "lg" ? (isInfoOnRightSide ? "row" : "column") : "row"
        }
        flex="1"
        padding="0px"
        gap={device === "lg" ? "0px" : "16px"}
        handleClick={() => {
          onClick?.(data, idx);
        }}
        isCursorPointer
      >
        <LogoContainer
          id={getElementUniqueIds(
            PageNameMapping.LISTING_PAGE,
            getCamelCaseStr(data.label),
            ElementNameMapping.LOGO_CONTAINER
          )}
        >
          <ImageTag src={logoSrc} width={device === "lg" ? "120px" : "100%"} />
          {logoDesc && (
            <ParaTag
              margin={device === "lg" ? "0px 0px 6px" : "0px"}
              fontSize={device === "sm" ? "xxs" : "xs"}
              color={theme.greyText}
            >
              {logoDesc.normalText}{" "}
              {logoDesc.boldText && (
                <SpanTag
                  fontWeight="bold"
                  color={theme.greyText}
                  fontSize={device === "sm" ? "xxs" : "xs"}
                >
                  {logoDesc.boldText}
                </SpanTag>
              )}
            </ParaTag>
          )}
        </LogoContainer>
        <FlexComp
          padding="0px"
          gap="0px"
          flexDirection="column"
          isCursorPointer
        >
          <FlexComp
            // handleClick={() => onClick(data, idx)}
            flex="0"
            gap="12px"
            justifyContent={device === "sm" ? "space-between" : "flex-start"}
            alignItems="center"
            isCursorPointer
          >
            <ParaTag
              id={getElementUniqueIds(
                PageNameMapping.LISTING_PAGE,
                getCamelCaseStr(data.label),
                ElementNameMapping.HEADING
              )}
              fontSize="large"
              fontWeight="bold"
            >
              {label}
            </ParaTag>
            {!loadCurrency?.isLoaded && loadCurrency?.toAdd
              ? device === "lg" && arrowIcon
              : arrowIcon}
          </FlexComp>
          {!!subLabel && <ParaTag fontSize="sm">{subLabel}</ParaTag>}
          {featuresArr && featureArrDesktop && !loadCurrency && (
            <PointsContainer>
              {(device === "lg" ? featureArrDesktop : featuresArr).map(
                (item, idx) => (
                  <SpanTag
                    fontSize={device === "lg" ? "sm" : "xs"}
                    color={theme.greyText}
                    key={idx}
                    display="inline"
                  >
                    {item.label}
                  </SpanTag>
                )
              )}
            </PointsContainer>
          )}
          {loadCurrency && (
            <Fragment>
              <FlexComp
                margin={device === "lg" ? "12px 0px 0px" : "4px 0px 0px"}
                flexDirection="column"
              >
                {!!loadCurrency.label && (
                  <ParaTag fontSize="xs" fontWeight="bold">
                    {loadCurrency.label}
                  </ParaTag>
                )}
                {!!loadCurrency.subLabel && (
                  <ParaTag
                    fontSize="xs"
                    color={theme.greyText}
                    margin="2px 0px 12px"
                  >
                    {loadCurrency.subLabel}
                    {!!loadCurrency.addText && (
                      <AnchorTag
                        fontSize="xs"
                        fontWeight="extraBold"
                        margin="0 0 0 4px"
                        handleClick={(e: any) => loadCurrencyHandler(e, data)}
                      >
                        {loadCurrency.addText}
                      </AnchorTag>
                    )}
                  </ParaTag>
                )}
                {!!loadCurrency.linkBtnInMobile && (
                  <AnchorTag
                    id={getElementUniqueIds(
                      PageNameMapping.LISTING_PAGE,
                      getCamelCaseStr(data.label),
                      ElementNameMapping.QUICK_LOAD
                    )}
                    width="fit-content"
                    fontSize={device === "lg" ? "sm" : "xs"}
                    fontWeight="bold"
                    margin={device === "lg" ? "0px" : "12px 0 0 0"}
                    handleClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                      onClickViewDashboard(e, data)
                    }
                  >
                    {loadCurrency?.linkBtnInMobile?.label}
                  </AnchorTag>
                )}
              </FlexComp>
            </Fragment>
          )}
          {viewOrdersPersuasion && version === VERSIONS.V2 && (
            <FlexComp
              margin={device === "lg" ? "12px 0px 0px" : "4px 0px 0px"}
              flexDirection="column"
            >
              {viewOrdersPersuasion?.linkBtnInMobile && (
                <AnchorTag
                  width="fit-content"
                  fontSize={device === "lg" ? "sm" : "xs"}
                  fontWeight="bold"
                  margin={device === "lg" ? "0px" : "12px 0 0 0"}
                  handleClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    onClickViewOrders(e, data)
                  }
                >
                  {viewOrdersPersuasion?.linkBtnInMobile?.label}
                </AnchorTag>
              )}
            </FlexComp>
          )}
          {!!deliveryLabel && (
            <FlexComp
              margin="4px 0px 0px"
              alignItems="center"
              flex="0"
              padding="0px"
              gap="4px"
            >
              <LocationIcon />
              <SpanTag
                id={getElementUniqueIds(
                  PageNameMapping.LISTING_PAGE,
                  getCamelCaseStr(data.label),
                  ElementNameMapping.DELIVERY_ETA
                )}
                color={theme.greenText}
                fontSize="xs"
                fontWeight="bold"
                dangerousText={deliveryLabel}
              />
            </FlexComp>
          )}
        </FlexComp>
      </FlexComp>
      {backendPersuasion && backendPersuasion?.length > 0 ? (
        <>
          {backendPersuasion?.map(({ value, theme }, index: any) => {
            return (
              <PersuasionTile
                flexId={getElementUniqueIds(
                  PageNameMapping.LISTING_PAGE,
                  getCamelCaseStr(data.label),
                  ElementNameMapping.PERSUASION_TILE.concat(index)
                )}
                key={index}
                margin="24px 0px 0px"
                text={{
                  normalText: value,
                }}
                isHtml
                bgColor={theme}
              />
            );
          })}
        </>
      ) : null}
      {isleftOffBefore && (
        <PersuasionTile
          id={getElementUniqueIds(
            PageNameMapping.LISTING_PAGE,
            getCamelCaseStr(data.label),
            ElementNameMapping.COMPLETE_ORDER
          )}
          margin="24px 0px 0px"
          text={isleftOffBefore.text}
          bgColor={theme.yellowText2}
          linkBtn={{
            ...isleftOffBefore?.linkBtn,
            onClick: startFromWhereLeftHandler
              ? () => startFromWhereLeftHandler(data)
              : () => {
                  console.log("link button");
                },
          }}
          leftIcon={
            isleftOffBefore.iconSrc ? (
              <ImageTag src={isleftOffBefore.iconSrc} />
            ) : (
              <RoundEcllipseIcon width="50px" height="50px" />
            )
          }
        />
      )}
      {isRecommended && (
        <PersuasionTile
          id={getElementUniqueIds(
            PageNameMapping.LISTING_PAGE,
            getCamelCaseStr(data.label),
            ElementNameMapping.TRACK_BTN
          )}
          margin="24px 0px 0px"
          text={isRecommended.text}
          doNotShiftLink={true}
          bgColor={theme.greenText2}
          isHtml
          htmlStyle={{
            fontSize: "12px",
            lineHeight: 1,
            marginBottom: "4px",
          }}
          linkBtn={{
            ...isRecommended?.linkBtn,
            onClick: recommendedBtnHandler
              ? () => recommendedBtnHandler(data)
              : () => {
                  console.log("recommended");
                },
          }}
        />
      )}
      {loadCurrency && loadCurrency.btnOptions ? (
        <LandingButton
          {...loadCurrency.btnOptions}
          onClick={
            loadCurrenyBtnHandler
              ? () => loadCurrenyBtnHandler(data)
              : () => {
                  console.log("landing button: load currency");
                }
          }
          fontSize="12px"
          margin="25px 0px 0px"
          customHeight="32px"
          customWidth="100%"
        />
      ) : (
        <AnchorTag
          width="fit-content"
          fontSize="xs"
          fontWeight="extraBold"
          // margin="12px 0px 0px"
          handleClick={
            loadCurrenyBtnHandler
              ? () => loadCurrenyBtnHandler(data)
              : () => {
                  console.log("load currency");
                }
          }
        >
          {loadCurrency?.btnOptions?.customTitle}
        </AnchorTag>
      )}
      {/* UNAVAILABLE AT LOCATION */}
      {isUnavailableAtLoc && (
        // <PersuasionTile
        //     margin="24px 0px 0px"
        //     bgColor={theme.yellowText2}
        //     normalTextColor={theme.brownText}
        //     text={isUnavailableAtLoc.text}
        // />
        <NotLocationText>
          {isUnavailableAtLoc.normalText && (
            <ParaTag fontSize="xs" color={theme.brownText}>
              {isUnavailableAtLoc.normalText}{" "}
              {isUnavailableAtLoc.linkText && (
                <AnchorTag
                  id={getElementUniqueIds(
                    PageNameMapping.LISTING_PAGE,
                    getCamelCaseStr(data.label),
                    ElementNameMapping.ANOTHER_LOC_BTN
                  )}
                  width="fit-content"
                  fontSize="xs"
                  fontWeight="bold"
                  // hrefProp={
                  //     isUnavailableAtLoc.linkText.hrefLink
                  // }
                  onClick={
                    onClickAnotherLocation
                      ? () => onClickAnotherLocation(data)
                      : () => {
                          console.log("another location");
                        }
                  }
                >
                  {isUnavailableAtLoc.linkText?.text}
                </AnchorTag>
              )}
            </ParaTag>
          )}
        </NotLocationText>
      )}
      {/* DOCS PENDING */}
      {docsPending && (
        <FlexComp
          padding="12px"
          borderRadius="8px"
          margin="24px 0px 0px"
          justifyContent="space-between"
          flexDirection={device === "sm" ? "column" : "row"}
          bgColor="linear-gradient(159.48deg, #FFF9ED 4.69%, rgba(255, 239, 236, 0.736176) 27.76%, #F8F9E7 92.13%)"
        >
          {(docsPending.label || docsPending.iconSrc) && (
            <FlexComp
              padding="0px"
              alignItems="center"
              justifyContent="space-between"
              margin={device === "sm" ? "0px 0px 16px" : "0px"}
            >
              {docsPending.label && (
                <ParaTag
                  fontWeight="bold"
                  color={theme.brownText}
                  margin="0px 20px 0px 0px"
                >
                  {docsPending.label}
                </ParaTag>
              )}
              {device === "sm" &&
                (docsPending.iconSrc ? (
                  <ImageTag src={docsPending.iconSrc} />
                ) : (
                  <DocsUploadIcon />
                ))}
            </FlexComp>
          )}
          {docsPending.btnProps && (
            <LandingButton
              id={getElementUniqueIds(
                PageNameMapping.LISTING_PAGE,
                getCamelCaseStr(data.label),
                ElementNameMapping.UPLOAD_NOW
              )}
              {...docsPending.btnProps}
              onClick={
                docsPendingHandler
                  ? () => docsPendingHandler(data)
                  : () => {
                      console.log("doc pending");
                    }
              }
              flexShrink="0"
              customWidth={device === "sm" ? "100%" : undefined}
            />
          )}
        </FlexComp>
      )}
      {/* Needed for currency phase 1 */}
      {viewOrdersPersuasion && version === VERSIONS.V1 && (
        <FlexComp
          padding="12px"
          borderRadius="8px"
          margin="24px 0px 0px"
          justifyContent="space-between"
          flexDirection={device === "sm" ? "column" : "row"}
          bgColor="linear-gradient(159.48deg, #FFF9ED 4.69%, rgba(255, 239, 236, 0.736176) 27.76%, #F8F9E7 92.13%)"
        >
          {(viewOrdersPersuasion.label || viewOrdersPersuasion.iconSrc) && (
            <FlexComp
              padding="0px"
              alignItems="center"
              justifyContent="space-between"
              margin={device === "sm" ? "0px 0px 16px" : "0px"}
            >
              {viewOrdersPersuasion.label && (
                <ParaTag
                  fontWeight="bold"
                  color={theme.brownText}
                  margin="0px 20px 0px 0px"
                >
                  {viewOrdersPersuasion.label}
                </ParaTag>
              )}
              {device === "sm" &&
                (viewOrdersPersuasion.iconSrc ? (
                  <ImageTag src={viewOrdersPersuasion.iconSrc} />
                ) : (
                  <DocsUploadIcon />
                ))}
            </FlexComp>
          )}
          {viewOrdersPersuasion.btnProps && (
            <LandingButton
              id={getElementUniqueIds(
                PageNameMapping.LISTING_PAGE,
                getCamelCaseStr(data.label),
                ElementNameMapping.UPLOAD_NOW
              )}
              {...viewOrdersPersuasion.btnProps}
              onClick={
                docsPendingHandler
                  ? () => docsPendingHandler(data)
                  : () => {
                      console.log("view orders");
                    }
              }
              flexShrink="0"
              customWidth={device === "sm" ? "100%" : undefined}
            />
          )}
        </FlexComp>
      )}
    </LandingCardFeatureStyle>
  ) : (
    <Fragment />
  );
};

export default LandingCardFeature;
