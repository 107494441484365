import { forwardRef } from "react";
import { ImageTagStyle } from "./ImageTag.style";

interface ImageTagProps {
  src: string;
  width?: string;
  margin?: string;
  height?: string;
  showCursorPointer?: boolean;
  alt?: string;
  borderRadius?: string;
  position?: string;
  bottom?: string;
  right?: string;
  zIndex?: number;
  onClick?: () => void;
}

const ImageTag = forwardRef<HTMLImageElement, ImageTagProps>(
  (
    {
      src,
      width,
      alt = "image-tag",
      margin,
      height,
      showCursorPointer = false,
      borderRadius,
      position,
      bottom,
      right,
      zIndex,
      onClick,
    },
    ref
  ) => {
    return (
      <ImageTagStyle
        ref={ref}
        src={src}
        width={width}
        margin={margin}
        height={height}
        showCursorPointer={showCursorPointer}
        alt={alt}
        borderRadius={borderRadius}
        position={position}
        bottom={bottom}
        right={right}
        zIndex={zIndex}
        onClick={onClick}
      />
    );
  }
);

export default ImageTag;
