import styled from "styled-components";

import { deviceType } from "@tm/static/utils";

export const NoPincodeRecommendtnStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #454545 0%, rgba(0, 0, 0, 1) 30%);
  height: 500px;

  @media ${deviceType.mobile} {
    height: 100%;
  }
`;
