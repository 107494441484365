import React, { useState, useEffect } from "react";
import { theme } from "@tm/utility/global";
import {
  FlexComp,
  TMFloatingInput,
  SpanTag,
  CommonModalDweb,
  RadioButton,
  ImageComponent,
  LandingButton,
  ParaTag,
} from "@tm/static";
import {
  CountryItem,
  CountrySection,
  BtnWrap,
} from "./SelectCountryModal.style";
import { List } from "react-virtualized";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { ICountryData } from "../AddTravellerDetail/type";

interface SelectCountryCodeModalProps {
  handleSelectCountry: (data: ICountryData[]) => void;
  handleClose: () => void;
  countryData: ICountryData[];
  popularRegion?: {
    id: number;
    regionName?: string;
    regionalCountryTxt?: string;
    countryList?: string[];
    isSelected?: boolean;
    regionInfo?: string;
  }[];
}

const SelectCountryCodeModal = ({
  countryData,
  handleSelectCountry,
  handleClose,
}: SelectCountryCodeModalProps) => {
  const device = useBreakpointV2();
  const windowWidth = window.innerWidth - 60;
  const [countriesData, setCountriesData] = useState(countryData);
  const [searchTxt, setInputValue] = useState("");
  const rowHeight = 60;

  const calculateListHeight = () => {
    const rowCount = countriesData?.length ?? 0; // Replace with your actual data length
    const height = rowCount * rowHeight;
    return height;
  };

  const countryHandler = (id: number) => {
    if (id) {
      setCountriesData(
        countryData?.map((item) => {
          if (id === item.id) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
          return item;
        })
      );
    }
  };

  useEffect(() => {
    if (searchTxt?.length) {
      const filteredDataList = searchTxt
        ? countryData?.filter((items) =>
            items.countryName?.toLowerCase().includes(searchTxt.toLowerCase())
          )
        : countryData;
      setCountriesData(filteredDataList);
    }
  }, [searchTxt]);

  const rowRenderer = ({ index, key }: { index: number; key: string }) => {
    const item = countriesData && countriesData[index];

    if (!item) {
      return null; // Return null if item is undefined
    }
    return (
      <div key={key}>
        <CountryItem onClick={() => countryHandler(item.id)}>
          <RadioButton
            handleChange={() => countryHandler(item.id)}
            activeBtn={item.isSelected}
          >
            <FlexComp
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <FlexComp alignItems="center" gap="8px">
                {item.countryFlag && <ImageComponent src={item.countryFlag} />}
                <SpanTag fontSize="sm" color={theme.blackText}>
                  {item.countryName}
                </SpanTag>
              </FlexComp>
              <SpanTag fontWeight="bold" fontSize="xs" color={theme.blackText}>
                {item.countryCode}
              </SpanTag>
            </FlexComp>
          </RadioButton>
        </CountryItem>
      </div>
    );
  };

  return (
    <CommonModalDweb handleClick={handleClose} customTitle="Select Country">
      <TMFloatingInput
        handleChange={(e) => setInputValue(e.target.value)}
        inputValue={searchTxt}
        labelTxt="Search Country Name"
      />
      {countriesData?.length > 0 && (
        <CountrySection>
          <SpanTag fontSize="sm" fontWeight="bold">
            Country Name
          </SpanTag>
          <SpanTag fontSize="sm" fontWeight="bold">
            Code
          </SpanTag>
        </CountrySection>
      )}
      {countriesData && countriesData?.length > 0 ? (
        <List
          width={device === "lg" ? 360 : windowWidth}
          height={calculateListHeight()}
          style={{ minHeight: calculateListHeight() }}
          autoHeight
          rowHeight={rowHeight}
          rowCount={countriesData.length}
          rowRenderer={rowRenderer}
        />
      ) : (
        <ParaTag fontSize="sm" color={theme.greyText} margin="16px 0 16px">
          No Country Found! Please enter correct name
        </ParaTag>
      )}
      {/* {countriesData && (
                <List
                    width={device === 'lg' ? 360 : windowWidth}
                    height={calculateListHeight()}
                    style={{minHeight:calculateListHeight()}}
                    autoHeight
                    rowHeight={rowHeight}
                    rowCount={countriesData.length}
                    rowRenderer={rowRenderer}
                />
            )} */}
      {countriesData?.length != 0 && (
        <BtnWrap>
          <LandingButton
            customWidth="100%"
            customTitle={"Select"}
            onClick={() => handleSelectCountry(countriesData)}
          />
        </BtnWrap>
      )}
    </CommonModalDweb>
  );
};

export default SelectCountryCodeModal;
