import { CASHBACK_STATUS } from "@tm/libs/types";
import styled from "styled-components";

type CashBackProp = {
  cashbackStatus?: string;
  showComp?: boolean;
};

export const CashBackStyle = styled.div<CashBackProp>`
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: ${(p: any) =>
    p.cashbackStatus === CASHBACK_STATUS.CREDITED
      ? "#E4FBF4"
      : p.cashbackStatus === CASHBACK_STATUS.CANCELLED
      ? "#FCDADB"
      : p.cashbackStatus === CASHBACK_STATUS.COMMITTED
      ? "#E4FBF4"
      : "#E4FBF4"};
  & a {
    flex-shrink: 0;
  }
  ${(p) =>
    !p.showComp &&
    `
  display:none;
  `}
`;
