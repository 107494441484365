import { getCookieExpiryTime, persistSpecificParamInCookie } from "../common";
import { getCookie } from "../common/cookieUtils";
import { COOKIES, GI_SUB_DOMAIN, TM_AUTH_EXPIRY_DAYS } from "../constants";
import { decodeJSON, encodeJSON, getTmAuthJsonForIos } from "../methods";
import { AuthAbstract } from "./baseAuth";

export class GIIosAuth extends AuthAbstract {
  partner = "GI";
  accType: string = "GI";

  getSLTToken(): Promise<null | string> {
    return new Promise((resolve, reject) => {
      let tokenSent: boolean = false;

      if (
        !window ||
        !window?.webkit?.messageHandlers?.getShortLivToken?.postMessage
      ) {
        return reject(
          new Error("Failed to get getShortLiveToken bridge handler in GI IOS")
        );
      }

      window.shortLivToken = (token: string | undefined) => {
        if (!token) {
          return reject(new Error("Failed to get SLT from GI IOS"));
        }
        resolve(token);
        tokenSent = true;
      };

      window?.webkit?.messageHandlers?.getShortLivToken?.postMessage({
        client: "TM",
      });

      setTimeout(() => {
        if (!tokenSent) {
          console.error("SLT token not sent after 5s in GI IOS");
        }
      }, 5000);
    });
  }

  getPartnerAuthFromCookie() {
    const gi_auth = getCookie(COOKIES.GI_AUTH_KEY, true) || "";
    return gi_auth;
  }

  getTmAuthFromBridge(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      if (
        !window ||
        !window?.webkit?.messageHandlers?.getTMToken?.postMessage
      ) {
        console.error("Failed to find ios getTMToken bridge");
        return resolve(null);
      }

      window.tripMoneyToken = (tokenObject) => {
        let token: string | null = null;
        try {
          const decodedJson: { tmAuth: string } = decodeJSON(tokenObject);
          token = decodedJson.tmAuth;
        } catch (e) {
          console.error("Error while decoding tmToken", e);
        }
        resolve(token);
      };

      window.webkit.messageHandlers.getTMToken.postMessage(
        JSON.stringify(true)
      );
    });
  }

  async getTMAuth(): Promise<string | null> {
    let tmAuth: string | null = this.getTMAuthFromCookie();
    if (!tmAuth) {
      tmAuth = await this.getTmAuthFromBridge();
    }
    return tmAuth;
  }

  /**
   * Retrieves the TM_AUTH value from the cookie.
   * @returns The TM_AUTH value from the cookie.
   */
  getTMAuthFromCookie(): string {
    const tm_auth = getCookie(COOKIES.TM_AUTH_KEY, true) || "";
    return tm_auth;
  }

  saveTMAuth(tmAuth: string) {
    this.saveTMAuthInAppStorage(tmAuth);
    this.saveTMAuthInCookie(tmAuth);
  }

  saveTMAuthInCookie(tmAuth: string) {
    const partnerAuth = this.getPartnerAuthFromCookie();
    persistSpecificParamInCookie(COOKIES.TM_AUTH_KEY, tmAuth, {
      path: "/",
      domain: GI_SUB_DOMAIN,
      expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
    });
    //Need to keep mapping of mmt_auth and tm_auth to  validate  partner and tm_auth mismatch
    persistSpecificParamInCookie(
      COOKIES.TM_GI_AUTH_KEY,
      `${tmAuth}___${partnerAuth}`,
      {
        path: "/",
        domain: GI_SUB_DOMAIN,
        expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
      }
    );
  }

  saveTMAuthInAppStorage(tmAuth: string) {
    if (window?.webkit?.messageHandlers?.saveTMToken?.postMessage) {
      console.error("SaveTMToken handler not found");
      return;
    }

    const token = encodeJSON(
      getTmAuthJsonForIos(this.getPartnerAuthFromCookie(), tmAuth)
    );

    window.webkit.messageHandlers.saveTMToken?.postMessage(
      JSON.stringify(token)
    );
  }
}
