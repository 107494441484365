import { ImageTag, ParaTag, SpanTag, ToolTip } from "@tm/static";

import {
  AddressCompStyle,
  AddAddressContainer,
  EditAddressContainer,
  EditAddressLeftStyle,
  EditContainer,
  DeliveryDateContainer,
  InfoIconWpr,
} from "./AddressComp.style";
import { theme } from "@tm/utility/global";
import { useBreakpoint } from "@tm/ui/hooks";

import { EditIcon, EnergyIcon, InfoIcon } from "../../icons";

import { AddressCompProps } from "./types";

const AddressComp = ({
  addAddressHandler,
  editAddress = false,
  addressDetails,
  editAddressHandler,
  containerStyle,
  deliveryDate,
  infoIconClick,
  noAddressLabel,
  addAddressLabel,
  editIcon,
  infoIcon,
  deliveryInfoTxt,
  showTooltip,
  closeTooltip,
  energyIcon,
}: AddressCompProps) => {
  const device = useBreakpoint();

  return (
    <AddressCompStyle
      onClick={!editAddress ? addAddressHandler : () => {}}
      containerStyle={containerStyle}
    >
      {!editAddress ? (
        <AddAddressContainer>
          {noAddressLabel && (
            <ParaTag color={theme.greyText6} textAlign="center" fontSize="sm">
              {noAddressLabel}
            </ParaTag>
          )}
          {addAddressLabel && (
            <ParaTag
              color={theme.blueText}
              textAlign="center"
              fontSize="sm"
              fontWeight="bold"
            >
              + {addAddressLabel}
            </ParaTag>
          )}
        </AddAddressContainer>
      ) : (
        <EditContainer>
          <EditAddressContainer>
            <EditAddressLeftStyle>
              {addressDetails?.addressLine1 && (
                <ParaTag color={theme.greyText} fontSize="sm">
                  {addressDetails?.addressLine1}
                </ParaTag>
              )}

              <ParaTag color={theme.greyText} fontSize="sm">
                {addressDetails?.addressLine2}{" "}
                {addressDetails?.pincode && (
                  <SpanTag
                    color={theme.greyText}
                    fontSize="sm"
                    fontWeight="bold"
                  >
                    {addressDetails?.pinLabel} {addressDetails.pincode}
                  </SpanTag>
                )}
              </ParaTag>
              {addressDetails?.addressLine3 && (
                <ParaTag color={theme.greyText} fontSize="sm">
                  {addressDetails.addressLine3}
                </ParaTag>
              )}
            </EditAddressLeftStyle>
            {editIcon ? (
              <div onClick={editAddressHandler}>
                {<ImageTag src={editIcon} showCursorPointer />}
              </div>
            ) : (
              <div onClick={editAddressHandler}>{<EditIcon />}</div>
            )}
          </EditAddressContainer>

          {deliveryDate && (
            <DeliveryDateContainer>
              {energyIcon ? <ImageTag src={energyIcon} /> : <EnergyIcon />}
              <ParaTag
                color={theme.lightGreen}
                fontSize="sm"
                margin="0px 8px 0px 5px"
              >
                {deliveryDate}
              </ParaTag>
              <InfoIconWpr onClick={infoIconClick}>
                {infoIcon ? (
                  <ImageTag src={infoIcon} showCursorPointer />
                ) : (
                  <InfoIcon />
                )}
                {showTooltip && deliveryInfoTxt && (
                  <ToolTip
                    closeTooltip={closeTooltip}
                    arrowPosition="90%"
                    style={{
                      right: "-20px",
                      bottom: "22px",
                      width: device === "lg" ? "320px" : "280px",
                    }}
                  >
                    <ParaTag color={theme.whiteText} fontWeight="regular">
                      {deliveryInfoTxt}
                    </ParaTag>
                  </ToolTip>
                )}
              </InfoIconWpr>
            </DeliveryDateContainer>
          )}
        </EditContainer>
      )}
    </AddressCompStyle>
  );
};

export default AddressComp;
