import {
  landingForexOfferingCardDataProps,
  persuasionData,
} from "../../static/mockData";
import { BMFListingContainer } from "../../static/components/ListingPageComponents/ListingPageComponents.style";
import PersuasionBanner from "../../static/components/PersuasionBanner/PersuasionBanner";
import { useDevice } from "../../hooks/useDevice";
import useRedirectionUrls from "../../hooks/useRedirectionUrls";
import { WhiteBackgroundStyled } from "../../static/CommonStyle/CommonStyles.style";
import { getCardValue, shouldOpenMMTLogin } from "../../utils";
import useOpenLoginForm from "../../hooks/useOpenLoginForm";
import { useGlobalConfig } from "../../hooks/useGlobalConfig";
import { useGlobalSelector } from "../../hooks/useGlobalSelector";
import { ListingReducerType } from "../../store/forexListingReducer";
import { CARD_TYPE } from "../../constants";
import { ProductType } from "../../types";

const persuasionBanner = {
  data: persuasionData,
  bgImgURI:
    "https://tripmoneycmsimgak.mmtcdn.com/img/listing_coins_bg_2ec4b8ddab.png",
  btnTitle: "EXPLORE FOREX CURRENCY",
  title: "Order Genuine Foreign Currency Notes",
};
const ForexCurrencyCard = () => {
  const device = useDevice();
  return device === "lg" ? <ForexCurrencyCardContainer /> : null;
};

export default ForexCurrencyCard;

const ForexCurrencyCardContainer = () => {
  const { isLoggedInUser } = useGlobalConfig();
  const { exploreForexCurrencyRedirection } = useRedirectionUrls();
  const { products } = useGlobalSelector((state: ListingReducerType) => state);
  const { openLoginFormForNonLoggedInUser } = useOpenLoginForm();

  const onClickForexCurrency = async () => {
    if (!isLoggedInUser) {
      await openLoginFormForNonLoggedInUser();
      return;
    }
    const cardValue = getCardValue(products, CARD_TYPE.CURRENCY as ProductType);
    exploreForexCurrencyRedirection(
      cardValue as landingForexOfferingCardDataProps
    );
  };
  return (
    <WhiteBackgroundStyled>
      <BMFListingContainer>
        <PersuasionBanner
          heading={persuasionBanner.title}
          bgImage={persuasionBanner.bgImgURI}
          btnText={persuasionBanner.btnTitle}
          bulletPoints={persuasionBanner.data}
          btnHandler={onClickForexCurrency}
        />
      </BMFListingContainer>
    </WhiteBackgroundStyled>
  );
};
