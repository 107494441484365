import { useState, Fragment } from "react";

import {
  MarqueeBarStyle,
  MarqueeRibbon,
  MarqueeContainer,
  DotsAnimation,
} from "./Marquee.style";
import { landingMarqueeDataProps } from "../../mockData";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import { theme } from "../../css/Global";
import { useDevice } from "../../../hooks/useDevice";
import Shimmer from "../Shimmer/Shimmer";
import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import { VERSIONS } from "../../../constants";

interface MarqueeBarProps {
  leftTagText?: string;
  marqueeData: landingMarqueeDataProps[];
  margin?: string;
  isOnBottom?: boolean;
  padding?: string;
  isLoading?: boolean;
  loadingText?: string;
  marqueeCtrBgColor?: string;
  marqueeBarBgColor?: string;
  LIVETagImgURI?: string;
  version?: number;
}

const MarqueeBar = ({
  leftTagText,
  marqueeData,
  margin,
  padding,
  isOnBottom = false,
  isLoading = true,
  loadingText,
  marqueeCtrBgColor,
  marqueeBarBgColor = "rgba(74, 74, 74, 0.3)",
  LIVETagImgURI,
  version,
}: MarqueeBarProps) => {
  const device = useDevice();
  const [isPaused, setIsPaused] = useState(false);

  const loaderRender = () => {
    return (
      <Fragment>
        {loadingText && (
          <SpanTag
            fontSize="xs"
            margin="0px 4px 0px 10px"
            color={isOnBottom ? theme.yellowText : theme.yellowText4}
          >
            {loadingText}
          </SpanTag>
        )}
        <DotsAnimation isOnBottom={isOnBottom}>
          <span />
          <span />
          <span />
        </DotsAnimation>
        <FlexComp margin="0px 0px 0px 16px" flex="1" gap={"16px"}>
          {device === "lg" ? (
            <Fragment>
              <Shimmer flex={1} height="16px" />
              <Shimmer flex={1} height="16px" />
              <Shimmer flex={1} height="16px" />
              <Shimmer flex={1} height="16px" />
              <Shimmer flex={1} height="16px" />
              <Shimmer flex={1} height="16px" />
              <Shimmer flex={1} height="16px" />
              <Shimmer flex={1} height="16px" />
              <Shimmer flex={1} height="16px" />
              <Shimmer flex={1} height="16px" />
            </Fragment>
          ) : (
            <Fragment>
              <Shimmer
                flex={1}
                height="16px"
                background={
                  isOnBottom
                    ? "linear-gradient(to right, #CBCBCB 8%, rgba(203, 203, 203, 0) 18%, #CBCBCB 33%)"
                    : undefined
                }
              />

              <Shimmer
                flex={1}
                height="16px"
                background={
                  isOnBottom
                    ? "linear-gradient(to right, #CBCBCB 8%, rgba(203, 203, 203, 0) 18%, #CBCBCB 33%)"
                    : undefined
                }
              />
            </Fragment>
          )}
        </FlexComp>
      </Fragment>
    );
  };

  return device ? (
    <MarqueeBarStyle
      margin={margin}
      padding={padding}
      isLoading={isLoading}
      isOnBottom={isOnBottom}
      marqueeBarBgColor={marqueeBarBgColor}
      onMouseEnter={
        device === "lg"
          ? () => {
              setIsPaused(true);
            }
          : undefined
      }
      onMouseLeave={
        device === "lg"
          ? () => {
              setIsPaused(false);
            }
          : undefined
      }
      onTouchStart={
        device === "sm"
          ? () => {
              setIsPaused(true);
            }
          : undefined
      }
      onTouchEnd={
        device === "sm"
          ? () => {
              setIsPaused(false);
            }
          : undefined
      }
    >
      {isLoading ? (
        loaderRender()
      ) : (
        <Fragment>
          {/* {leftTagText && (
                        <Tag>
                            <ParaTag
                                color={theme.whiteText}
                                fontSize={device === 'lg' ? 'sm' : 'xs'}
                            >
                                {leftTagText}
                            </ParaTag>
                        </Tag>
                    )} */}
          {LIVETagImgURI && (
            <ImageTag
              src={LIVETagImgURI}
              width={device === "lg" ? "50px" : "35px"}
            />
          )}
          {marqueeData && (
            <MarqueeContainer
              isOnBottom={isOnBottom}
              onMouseEnter={() => {
                setIsPaused(true);
              }}
              onMouseLeave={() => {
                setIsPaused(false);
              }}
              marqueeCtrBgColor={marqueeCtrBgColor}
            >
              <MarqueeRibbon isOnBottom={isOnBottom} isPaused={isPaused}>
                {marqueeData.map((item) => {
                  return (
                    <li key={item.id}>
                      {item.boldText && (
                        <SpanTag
                          color={
                            isOnBottom
                              ? theme.blackText
                              : version === VERSIONS.V3 && device === "sm"
                              ? theme.blackText
                              : theme.whiteText
                          }
                          fontWeight="bold"
                          margin={
                            device === "lg"
                              ? "0px 4px 0px 0px"
                              : "0px 4px 4px 0px"
                          }
                          fontSize={device === "sm" ? "xs" : "sm"}
                        >
                          {item.boldText}
                        </SpanTag>
                      )}
                      {item.text && (
                        <SpanTag
                          fontSize={device === "sm" ? "xs" : "sm"}
                          color={
                            isOnBottom
                              ? theme.greyText6
                              : version === VERSIONS.V3 && device === "sm"
                              ? theme.greyText6
                              : theme.whiteText
                          }
                        >
                          {item.text}
                        </SpanTag>
                      )}
                      {item.blueText && (
                        <SpanTag
                          fontSize={device === "sm" ? "xs" : "md"}
                          color={theme.blueText}
                          fontWeight={"bold"}
                        >
                          {item.blueText}
                        </SpanTag>
                      )}
                    </li>
                  );
                })}
              </MarqueeRibbon>
              {device === "lg" && (
                <MarqueeRibbon
                  isPaused={isPaused}
                  aria-hidden="true"
                  isOnBottom={isOnBottom}
                >
                  {marqueeData.map((item) => {
                    return (
                      <li key={item.id}>
                        {item.boldText && (
                          <SpanTag
                            color={
                              isOnBottom ? theme.blackText : theme.whiteText
                            }
                            margin={
                              device === "lg"
                                ? "0px 4px 0px 0px"
                                : "0px 4px 4px 0px"
                            }
                            fontWeight="bold"
                            fontSize="sm"
                          >
                            {item.boldText}
                          </SpanTag>
                        )}
                        {item.text && (
                          <SpanTag
                            fontSize="sm"
                            color={
                              isOnBottom ? theme.greyText6 : theme.whiteText
                            }
                          >
                            {item.text}
                          </SpanTag>
                        )}
                        {item.blueText && (
                          <SpanTag
                            fontSize="md"
                            color={theme.blueText}
                            fontWeight={"bold"}
                          >
                            {item.blueText}
                          </SpanTag>
                        )}
                      </li>
                    );
                  })}
                </MarqueeRibbon>
              )}
            </MarqueeContainer>
          )}
        </Fragment>
      )}
    </MarqueeBarStyle>
  ) : (
    <Fragment />
  );
};

export default MarqueeBar;

export const MarqueeBanner = MarqueeBar;
