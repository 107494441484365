import { PoweredByContainer } from "./HappyClientsBanner.style";
import { LandingFaqTitle } from "../LandingFaq/LandingFaq.style";
import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import H3Tag from "../ui-atoms/TextStyles/H3Tag";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import { useDevice } from "../../../hooks/useDevice";
import {
  PageNameMapping,
  ElementNameMapping,
  SectionNameMapping,
} from "../../../constants";
import { getElementUniqueIds } from "../../../utils";

interface HappyClientBannerProps {
  leftText?: string;
  rightText?: string;
  rightSubText?: string;
  poweredByText?: string;
  poweredByLogosArr?: any[];
}

const HappyClientsBanner = ({
  leftText,
  rightText,
  rightSubText,
  poweredByLogosArr,
  poweredByText,
}: HappyClientBannerProps) => {
  const device = useDevice();

  return device ? (
    <FlexComp
      flexDirection={device === "lg" ? "row" : "column"}
      padding={device === "lg" ? "56px 0px" : "86px 16px 0px 16px"}
      justifyContent="space-between"
      alignItems={device === "lg" ? "center" : "flex-start"}
      width="auto"
      height="auto"
    >
      {leftText && (
        <LandingFaqTitle
          id={getElementUniqueIds(
            PageNameMapping.LISTING_PAGE,
            SectionNameMapping.TRUST_US,
            ElementNameMapping.COMP_HEADING
          )}
          marginBottom="0px"
        >
          {leftText}
        </LandingFaqTitle>
      )}
      <FlexComp margin={device === "lg" ? "0px" : "16px 0px 0px"} padding="0px">
        <FlexComp
          padding="0"
          justifyContent="center"
          flexDirection="column"
          margin={device === "lg" ? "0px 130px 0px 0px" : "0px 20px 0px 0px"}
          width="auto"
          height="auto"
        >
          {device === "lg"
            ? rightText && <H3Tag>{rightText}</H3Tag>
            : rightText && (
                <ParaTag fontSize="md" fontWeight="bold">
                  {rightText}
                </ParaTag>
              )}
          {rightSubText && (
            <ParaTag fontSize={device === "lg" ? "md" : "xs"}>
              {rightSubText}
            </ParaTag>
          )}
        </FlexComp>
        <PoweredByContainer>
          {poweredByText && (
            <ParaTag fontSize={device === "lg" ? "md" : "xs"}>
              {poweredByText}
            </ParaTag>
          )}
          {poweredByLogosArr && (
            <ul>
              {poweredByLogosArr.map((item, idx) => {
                return (
                  <li key={idx}>
                    <ImageTag
                      src={item.logoSrc}
                      width={`${
                        device === "lg" ? item.width : item.width * 0.9
                      }px`}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </PoweredByContainer>
      </FlexComp>
    </FlexComp>
  ) : null;
};

export default HappyClientsBanner;
