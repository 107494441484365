import styled from "styled-components";
import { FlexCompStyle } from "../FlexComp/FlexComp.style";
import { deviceType } from "@tm/static/utils";

export const OrderDetailCompStyle = styled.div`
  background: #ffffff;
  border-radius: 16px;
  padding: 20px;
  picture {
    display: inherit;
    flex-shrink: 0;
  }
  @media ${deviceType.mobile} {
    padding: 16px 16px 12px;
    border-radius: 0px;
  }
`;

export const OrderDetailTravellerStyle = styled.div`
  border: 1px solid #e2e2e2;
  @media ${deviceType.mobile} {
    border: none;
    padding-left: 24px;
  }
`;

export const TravellerItem = styled(FlexCompStyle)`
  border-bottom: 1px solid #e2e2e2;
  padding: 12px 16px;
  :last-child {
    border-bottom: none;
  }
  @media ${deviceType.mobile} {
    padding: 8px;
  }
`;

export const PlanDetailStyle = styled.div`
  background: #f6faff;
  border-radius: 8px;
  padding: 16px;
  @media ${deviceType.mobile} {
    background: transparent;
    padding: 0;
    min-height: 100%;
    padding-left: 32px;
  }
`;

export const PlanDetailsInfo = styled.div`
  background: #ffffff;
  margin-top: 16px;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 0.6fr;
  ${FlexCompStyle} {
    border-right: 1px solid #e8e8e8;
    padding: 12px 16px;
    :last-child {
      border: none;
    }
  }
  @media ${deviceType.mobile} {
    display: flex;
    flex-direction: column;
    ${FlexCompStyle} {
      border: none;
      border-bottom: 1px solid #e8e8e8;
      padding: 12px 0 12px 0px;
    }
    ${FlexCompStyle}:last-child {
      border: none;
    }
  }
`;

export const OrderDetailsTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid #e8e8e8;
  ${FlexCompStyle} {
    :nth-child(n + 3) {
      border-bottom: none;
    }
    :nth-child(3) {
      border-right: none;
      border-bottom: 1px solid #e8e8e8;
    }
  }
  @media ${deviceType.mobile} {
    display: flex;
    flex-direction: column;
    border: none;
    ${FlexCompStyle} {
      border-bottom: 1px solid #e8e8e8;
      padding: 12px 0;
      border-right: none;
      :nth-child(n + 3) {
        border-bottom: 1px solid #e8e8e8;
      }
      :last-child {
        border-bottom: none;
      }
    }
  }
`;

export const OrderDetailsItem = styled(FlexCompStyle)`
  border-right: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  padding: 12px 16px;
  :last-child {
    border-right: none;
  }
  @media ${deviceType.mobile} {
    display: flex;
    justify-content: space-between;
  }
`;

export const OrderMobCtaWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px 16px 48px;
  background: #fff;
  border: 1px solid rgba(232, 232, 232, 1);
  box-shadow: 0px 1px 4px 0px rgba(74, 74, 74, 0.2);
`;

export const OrderMobAmtWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #fff;
  border: 1px solid rgba(232, 232, 232, 1);
`;
