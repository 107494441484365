export type PageName =
  | "LISTING"
  | "BMF_LANDING"
  | "TRAVELLER_DETAILS"
  | "PAN_INFO"
  | "ADD_ADDRESS"
  | "ADD_CURRENCY_DEFAULT"
  | "ADD_CURRENCY_CART"
  | "ORDER_IN_PROGRESS"
  | "FOREX_THANKS"
  | "FOREX_DASHBOARD"
  | string;

export type FunnelVars = {
  pageName: string;
  lob: string;
  siteSection: string;
};

export interface TrackPageLoadType {
  pageName: PageName;
  partner?: any;
  tmUserId?: string | null | number;
  upcomingTrip?: Record<string, any>;
}

export interface TrackPageLoadErrorType {
  pageName: PageName;
  errText?: string;
  partner?: any;
}

export interface TrackListingClickType {
  actionType: string;
  partner?: any;
  tmUserId?: string | null | number;
}

export type OmniData = {
  evar15?: string;
  evar24?: string;
  channel?: string;
  event327?: string;
  evar108?: string;
  evar32?: string;
  evar7?: string;
  prop67?: string;
  prop23?: string;
  evar16?: string;
  evar39?: string;
  evar20?: string;
  evar9?: string;
  prop52?: string;
  evar14?: string;
  evar75?: string;
  event328?: string;
  evar22?: string;
  evar46?: string;
  pageName: string;
};
declare global {
  interface Window {
    omniscriptLoaded: boolean;
    s: any;
    omnidata: any;
  }
}

export type GenericOmniType = {
  pageName: string;
  lob: string;
  siteSection: string;
  partner?: string | string[];
  location: string;
  platform: string;
  loginStatus: string;
  code?: string | string[];
  tmUserId?: string | number | null;
  upcomingTrip?: Record<string, any>;
};

export enum DEVICE_MODEL_TYPE_OMNITURE {
  APP = "APP",
  WEB = "DESKTOP",
  PWA = "PWA_DIRECT",
}
