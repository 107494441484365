import React, { useEffect, useState, useRef } from "react";
import { SpanTag } from "@tm/static";
import { theme } from "@tm/utility/global";
import { emptyCellAfter, emptyCellBefore, getCalendarData } from "../../utils";
import {
  CalenderWrap,
  CalenderMonthName,
  CalenderDays,
  CalenderDate,
} from "./Calendar.style";
import { DateRange } from "react-day-picker";

interface CalenderInterface {
  handleDayClick?: (day: Date) => void;
  startDate?: Date;
  endDate?: Date;
  monthNameType?:
    | "numeric"
    | "2-digit"
    | "long"
    | "short"
    | "narrow"
    | undefined;
  yearType?: "numeric" | "2-digit" | undefined;
  numberOfMonth?: number;
  selected?: DateRange | undefined;
}

const Calendar = ({
  handleDayClick,
  startDate,
  endDate,
  monthNameType,
  yearType,
  selected,
  numberOfMonth = 13,
}: CalenderInterface) => {
  // const [sDate, setSDate] = useState(startDate);
  // const [eDate, setEDate] = useState(endDate);

  const calendarData = getCalendarData(
    new Date(),
    new Date(new Date().setDate(new Date().getDate() + 365 + 180))
  );

  return (
    <CalenderWrap>
      {calendarData.map(
        ({ month, year, daysInMonth, firstDayOfWeek }, index) => {
          return (
            <div key={`${index}`}>
              <CalenderMonthName>
                {new Date(year, month).toLocaleString("en-us", {
                  month: monthNameType,
                })}{" "}
                &nbsp;
                {new Date(year, month).toLocaleString("en-us", {
                  year: yearType,
                })}
              </CalenderMonthName>
              <CalenderDays>
                {emptyCellBefore(firstDayOfWeek, daysInMonth)}
                {daysInMonth.days.map((day) => {
                  const d = new Date();
                  const curDate = d.getDate();
                  const curMonth = d.getMonth();
                  const curYear = d.getFullYear();

                  const isSelected =
                    day.date.getTime() ===
                      (selected?.from && selected?.from.getTime()) ||
                    day.date.getTime() ===
                      (selected?.to && selected?.to.getTime());

                  const isWithinRange =
                    selected &&
                    day.date > selected.from! &&
                    day.date < selected.to!;
                  const isDisabled =
                    (month == curMonth &&
                      year == curYear &&
                      day.date.getDate() < curDate) ||
                    day.date >
                      new Date(Date.now() + (365 - 1) * 24 * 60 * 60 * 1000);

                  return (
                    <CalenderDate
                      isSelected={isSelected}
                      isWithinRange={isWithinRange}
                      isDisabled={isDisabled}
                      onClick={
                        handleDayClick
                          ? () => handleDayClick(day.date)
                          : () => {}
                      }
                      key={`${day}`}
                    >
                      <SpanTag
                        color={
                          isSelected
                            ? theme.whiteText
                            : isDisabled
                            ? theme.greyText7
                            : theme.blackText
                        }
                      >
                        {day.date.getDate()}
                      </SpanTag>
                    </CalenderDate>
                  );
                })}
                {emptyCellAfter(firstDayOfWeek, daysInMonth)}
              </CalenderDays>
            </div>
          );
        }
      )}
    </CalenderWrap>
  );
};

export default Calendar;
