import styled from "styled-components";
import { deviceType } from "../../utils";

export const ErrorScreenStyle = styled.div<any>`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const ErrorComp = styled.div<any>`
  display: flex;
  width: 330px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  margin-top: 88px;
  flex: 1;
  @media ${deviceType.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 0px 16px;
    margin-top: unset;
    justify-content: center;
  }
`;

export const FullPageWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: hidden;
`;
