import React from "react";

import {
  FlexComp,
  ImageComponent,
  SpanTag,
  ParaTag,
  FormattedText,
  AnchorTag,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";

import { OrderDetailProp } from "./type";
import {
  OrderDetailCompStyle,
  OrderDetailsTable,
  OrderDetailsItem,
} from "./OrderDetailComp.style";

const OrderDetailInsurance = ({
  insPlanName,
  orderDetailIcon,
  orderDetails,
  insuranceType,
  insuranceInfo,
  insuranceTypeColor,
  forInsuranceContainer,
  handleClickInsurance,
}: OrderDetailProp) => {
  const device = useBreakpointV2();

  return (
    <OrderDetailCompStyle>
      <FlexComp
        flexDirection={device == "lg" ? "row" : "row-reverse"}
        justifyContent={device == "lg" ? "" : "space-between"}
        alignItems={device == "lg" ? "" : "center"}
        margin={device == "lg" ? "0 0 24px" : "0 0 14px"}
        gap="8px"
      >
        {orderDetailIcon && (
          <ImageComponent width="56px" height="56px" src={orderDetailIcon} />
        )}
        <FlexComp flexDirection="column" gap="2px" margin="0">
          <SpanTag fontSize={device == "lg" ? "large" : "md"} fontWeight="bold">
            <FormattedText value={insPlanName} />
          </SpanTag>
          <SpanTag
            fontSize={device == "lg" ? "sm" : "xs"}
            color={theme.greyText}
          >
            <FormattedText value={insuranceInfo} />
          </SpanTag>
        </FlexComp>
        {device == "lg" && insuranceType && (
          <SpanTag
            fontSize="xs"
            fontWeight="bold"
            textTransform="uppercase"
            margin="0 0 0 auto"
            color={insuranceTypeColor}
          >
            <FormattedText value={insuranceType} />
          </SpanTag>
        )}
      </FlexComp>
      <OrderDetailsTable>
        {orderDetails?.map((item, index) => {
          return (
            <OrderDetailsItem gap="16px" key={index}>
              {item.label && (
                <ParaTag
                  textTransform="uppercase"
                  color={theme.greyText7}
                  fontSize="xs"
                >
                  <FormattedText value={item.label} />
                </ParaTag>
              )}
              <SpanTag fontSize="xs" fontWeight="bold">
                <FormattedText value={item.value} />
              </SpanTag>
            </OrderDetailsItem>
          );
        })}
      </OrderDetailsTable>
      {device == "sm" && (
        <FlexComp
          gap="35px"
          borderTop="1px solid #e2e2e2"
          margin="16px -16px 0"
          padding="16px 16px 0"
          justifyContent="space-between"
        >
          {forInsuranceContainer?.forInsurance?.map((item: any, index: any) => {
            return (
              <FlexComp
                flex="1"
                flexDirection="column"
                alignItems="center"
                key={index}
                gap="6px"
                handleClick={() => handleClickInsurance(item)}
              >
                {item.icon && (
                  <ImageComponent width="36px" height="36px" src={item.icon} />
                )}
                {item.title && (
                  <AnchorTag fontSize="xs">
                    <FormattedText value={item.title} />
                  </AnchorTag>
                )}
              </FlexComp>
            );
          })}
        </FlexComp>
      )}
    </OrderDetailCompStyle>
  );
};

export default OrderDetailInsurance;
