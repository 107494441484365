import {
  LOB_FUNNEL_TYPE,
  LSITING_PDT_EVENTS_V2,
  PDT_EVENTS,
  SESSION_STORAGE_KEYS,
  getSessionPiiDataOpts,
} from "../../constants";
import { ListingReducerType } from "../../store/forexListingReducer";
import {
  getDeliveryDetailsPdtSchema,
  getEventDetails,
  getEventTrackingContext,
  getPdtLoggerMethods,
  getVendorErrorDetails,
} from "../../utils";
import { getSessionStorageItem } from "../../utils/clientStorageUtils";
import {
  IEventTrackingContext,
  IExperimentDetails,
} from "../../utils/pdtutils/types";
import { useGlobalSelector } from "../useGlobalSelector";

const useListingPdtLoggerV2 = () => {
  const { tm_user_id: userId, puid } = useGlobalSelector(
    (state: ListingReducerType) => state
  );
  const { sendCTAData, sendPdtDataV2 } = getPdtLoggerMethods({
    lobFunnelType: LOB_FUNNEL_TYPE.LISTING,
    puid: puid ? `${puid}` : null,
    userId: userId ? `${userId}` : null,
    lobCategory: "Listing",
    pdtEvents: LSITING_PDT_EVENTS_V2,
    funnelStep: "0",
  });

  const sendListingPdtLocationDetectedViewEventV2 = ({
    eventTrackingContext,
  }: {
    eventTrackingContext: IEventTrackingContext;
  }) => {
    try {
      const userLocation = getSessionStorageItem(
        SESSION_STORAGE_KEYS.USER_LOCATION_SESSION_KEY,
        true,
        getSessionPiiDataOpts
      );

      const eventDetail = {
        event_name: PDT_EVENTS.LOC_DETECT_EVENT,
        delivery_details: getDeliveryDetailsPdtSchema({
          deliveryDetails: userLocation,
        }),
      };
      sendPdtDataV2({
        event_detail: eventDetail,
        event_tracking_context: eventTrackingContext,
      });
    } catch (e: any) {
      console.error(e?.message);
    }
  };

  const sendPdtLocationBottomSheetViewEventV2 = () => {
    try {
      const eventDetail = {
        event_name: PDT_EVENTS.LOC_BOTTOM_SHEET_VIEWED,
      };
      const pageContextData = {
        subpage_name: PDT_EVENTS.LOC_BOTTOM_SHEET_VIEWED,
      };
      sendPdtDataV2({
        event_detail: eventDetail,
        page_context: pageContextData,
      });
    } catch (e: any) {
      console.error(e?.message);
    }
  };

  const sendPdtLoginClickEventListingPageV2 = () => {
    try {
      const eventDetail = getEventDetails({
        event_name: PDT_EVENTS.LISTING_PAGE_LOGIN_CLICK,
      });
      sendCTAData({ eventDetail });
    } catch (e: any) {
      console.error(e?.message);
    }
  };

  const sendPdtRateTickerViewEventV2 = (
    forex_rates: {
      [currencyCode: string]: string;
    },
    reqId: string
  ) => {
    try {
      const eventDetail = {
        event_name: PDT_EVENTS.RATE_TICKER_LOAD,
        ticker: {
          available_currency: JSON.stringify(Object.keys(forex_rates || {})),
          ticker_shown: Object.keys(forex_rates || {}).length ? true : false,
        },
      };
      sendPdtDataV2({
        event_detail: eventDetail,
        event_tracking_context: getEventTrackingContext({
          request_id: reqId,
        } as IEventTrackingContext),
      });
    } catch (e: any) {
      console.error(e?.message);
    }
  };

  const sendPdtListingErrorV2 = (error: any, description: string = "") => {
    try {
      const eventDetail = {
        vendor_error_details: getVendorErrorDetails({
          error_code: error?.code,
          error_message_shown:
            error?.errorTitle ||
            error?.errorDescription ||
            error?.description ||
            description ||
            "Unknown error",
        }),
      };

      sendPdtDataV2({ event_detail: eventDetail });
    } catch (e: any) {
      console.error(e?.message);
    }
  };

  const sendPdtUserServiceApiCallEventV2 = (eventCta: string) => {
    try {
      const eventDetail = getEventDetails({
        event_name: eventCta,
      });
      sendCTAData({ eventDetail });
    } catch (e: any) {
      console.error(e?.message);
    }
  };

  const sendPdtLoginTokenErrorV2 = (error: any, description: string = "") => {
    try {
      const eventDetail = {
        vendor_error_details: getVendorErrorDetails({
          error_code: error?.code,
          error_message_shown:
            error?.errorTitle ||
            error?.errorDescription ||
            error?.description ||
            description ||
            "Unknown error",
        }),
      };

      sendPdtDataV2({ event_detail: eventDetail });
    } catch (e: any) {
      console.error(e?.message);
    }
  };

  const sendPdtAppSocialErrorV2 = (error: any, description: string = "") => {
    try {
      const eventDetail = {
        vendor_error_details: getVendorErrorDetails({
          error_code: error?.code,
          error_message_shown:
            error?.errorTitle ||
            error?.errorDescription ||
            error?.description ||
            description ||
            "Unknown error",
        }),
      };

      sendPdtDataV2({ event_detail: eventDetail });
    } catch (e: any) {
      console.error(e?.message);
    }
  };

  return {
    sendCTAData,
    sendPdtDataV2,
    sendListingPdtLocationDetectedViewEventV2,
    sendPdtLocationBottomSheetViewEventV2,
    sendPdtLoginClickEventListingPageV2,
    sendPdtRateTickerViewEventV2,
    sendPdtListingErrorV2,
    sendPdtUserServiceApiCallEventV2,
    sendPdtLoginTokenErrorV2,
    sendPdtAppSocialErrorV2,
  };
};

export default useListingPdtLoggerV2;
