import React from "react";
import { ImageTag, Modal, SpanTag } from "@tm/static";
import { CurrenciesListStyle, ListItem, LabelWpr } from "./Currencies.style";

import { useBreakpoint } from "../../../../hooks/useBreakpoint";

import { CloseIcon } from "../../../icons/CloseIcon";

import { CurrenciesListProps } from "./types";

const CurrenciesList: React.FC<CurrenciesListProps> = ({
  listItems,
  modalHeading,
  onModalClose,
  closeButton = <CloseIcon />,
}) => {
  const device = useBreakpoint();

  return (
    <Modal
      modalHeading={modalHeading}
      onModalClose={onModalClose}
      closeButton={closeButton}
      modalWidth={420}
      modalHeadingCtrMargin={
        device === "lg" ? "0px 0px 8px 0px" : "0px 0px 14px 0px"
      }
      modalType={device === "lg" ? "centered" : "bottomSheet"}
    >
      <CurrenciesListStyle>
        {listItems?.map((item, idx) => (
          <ListItem key={item.id}>
            <LabelWpr>
              {item.imgSrc && (
                <ImageTag margin="0px 8px 0px 0px" src={item.imgSrc} />
              )}
              <SpanTag fontWeight={idx === 0 ? "bold" : "regular"}>
                {item.label}
              </SpanTag>
            </LabelWpr>
            <SpanTag
              fontWeight={idx === 0 ? "bold" : "regular"}
              margin="0px 0px 0px 8px"
            >
              {item.subLabel}
            </SpanTag>
          </ListItem>
        ))}
      </CurrenciesListStyle>
    </Modal>
  );
};

export default CurrenciesList;
