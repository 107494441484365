import React, { useEffect, useState } from "react";

import {
  FlexComp,
  SpanTag,
  H5Tag,
  FilterPlans,
  ComparePlan,
  ToastCompare,
  Tags,
  InsurancePlansComp,
  MoreBenefits,
  TravelAssistanceModal,
  FormattedText,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { navigationActions } from "@tm/insurance/navigation";
import { getAnalytics } from "@tm/insurance/analytics";

import { InsurancePlansStyle } from "./InsurancePlans.style";
import { insurancePlanType, IPlanData } from "./type";

const analytics = getAnalytics();

const InsurancePlans = ({
  title,
  sumInsTxt,
  // sortnFilterTxt,
  data,
  tagData,
  filterData,
  compareToastData,
  comparePlan,
  setShowTagData,
  showTagData,
}: insurancePlanType) => {
  const device = useBreakpointV2();
  const [showFilter, setShowFilter] = useState(false);
  const [comparisonIds, setComparisonIds] = useState<Set<number>>(new Set([]));

  const [comparePlanText, setComparePlanText] = useState(
    "0/2 Products Selected"
  );
  const [isDisable, setIsDisable] = useState(true);

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };
  const [showBenefits, setShowBenefits] = useState(-1);

  const toggleBenefitsModal = (index: number) => {
    showBenefits === index ? setShowBenefits(-1) : setShowBenefits(index);
  };

  const [showTravAssist, setshowTravAssist] = useState(-1);
  const handleTravAssist = (index: number) => {
    showTravAssist == index ? setshowTravAssist(-1) : setshowTravAssist(index);
  };

  const handleTabIndex = (id: number) => {
    if (showTagData) {
      let tempTagData = [...showTagData];

      tempTagData?.map((item) => {
        if (item.id === id) {
          item.isSelected = true;
          analytics?.trackListingEvents({
            actionType: "sum_insured_selected",
            payload: item.name,
          });
        } else {
          item.isSelected = false;
        }
      });
      setShowTagData(tempTagData);
    }
  };

  const [planSelected, setPlanSelected] = useState(data);
  const [count, setCount] = useState(0);
  const [comparePlanData, setComparePlanData]: any = useState([]);

  const [quoteId1ForCompare, setQuoteId1ForCompare] = useState("");
  const [quoteId2ForCompare, setQuoteId2ForCompare] = useState("");
  const [productId1ForCompare, setProductId1ForCompare] = useState("");
  const [productId2ForCompare, setProductId2ForCompare] = useState("");

  const handlePlanSelected = (productId: string) => {
    setPlanSelected(
      planSelected?.map((item) => {
        if (productId === item.productId) {
          if (!item.selectedForCompare && count === 2) {
            return item;
          } else {
            item.selectedForCompare = !item.selectedForCompare;
          }

          if (item.selectedForCompare) {
            setCount(count + 1);
            if (quoteId1ForCompare == "") {
              setQuoteId1ForCompare(item?.quoteId);
              setProductId1ForCompare(item?.productId);
            } else {
              setQuoteId2ForCompare(item?.quoteId);
              setProductId2ForCompare(item?.productId);
            }
          } else {
            setCount(count - 1);
            if (item?.quoteId == quoteId1ForCompare) {
              setQuoteId1ForCompare(quoteId2ForCompare);
              setProductId1ForCompare(productId2ForCompare);
            }
            setQuoteId2ForCompare("");
            setProductId2ForCompare("");
          }
        }
        return item;
      })
    );
  };

  useEffect(() => {
    if (count === 1) {
      setComparePlanText("1/2 Products Selected");
    } else if (count > 1) {
      setComparePlanText("2/2 Products Selected");
    } else {
      setComparePlanText("0/2 Products Selected");
    }
  }, [count]);

  const [showCompareNow, setShowCompareNow] = useState(false);

  const handleSelectCompare = () => {
    if (!showCompareNow) {
      analytics?.trackListingEvents({
        actionType: "comparenow_opened",
      });
    }
    setShowCompareNow(!showCompareNow);
  };

  const buyProduct = (quoteId: string, productId: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(searchParams.entries());

    let params = {
      ...queryParams,
      countries: searchParams.get("countries"),
      startDate: searchParams.get("startDate"),
      endDate: searchParams.get("endDate"),
      travellers: searchParams.get("travellers"),
      isUpcomingTrip: searchParams.has("isUpcomingTrip")
        ? searchParams.get("isUpcomingTrip")
        : false,
      productId,
      quoteId,
    };

    navigationActions.navigateToTravellerDetails(params);
  };

  useEffect(() => {
    if (showTagData) {
      setPlanSelected(data);
      const tempTagData = showTagData
        ?.filter((tagData) => {
          return tagData?.isSelected === true;
        })
        .map((item) => {
          return item.plans;
        });

      const tempResult: any[] = [];
      tempTagData?.map((item) => {
        item.map((tempItem) => tempResult.push(tempItem));
      });

      const result = data?.filter((plan) => {
        return tempResult?.includes(Number(plan?.productId));
      });

      result?.length && setPlanSelected(result);
    }
  }, [showTagData]);

  // TODO: add logic to compare productId from comparisonIds

  const closeToast = () => {
    setComparePlanData([]);
    setPlanSelected(
      planSelected?.map((item: IPlanData) => {
        item.selectedForCompare = false;
        return item;
      })
    );

    data?.map((item: IPlanData) => {
      item.selectedForCompare = false;
    });

    return setCount(0);
  };

  useEffect(() => {
    if (count === 2) setIsDisable(false);
    else setIsDisable(true);
  }, [count]);

  return (
    <InsurancePlansStyle>
      <H5Tag margin="0 0 16px">
        {title && <FormattedText value={title} />}
      </H5Tag>
      {sumInsTxt && (
        <SpanTag
          color={theme.greyText}
          fontSize="sm"
          fontWeight="black"
          margin="0 0 8px"
        >
          <FormattedText value={sumInsTxt} />
        </SpanTag>
      )}
      {tagData && (
        <FlexComp
          alignItems={device == "lg" ? "center" : "flex-start"}
          justifyContent="space-between"
          margin={device == "lg" ? "0 0 40px" : "0 0 24px"}
          gap="10px"
          flexDirection={device == "lg" ? "row" : "column"}
        >
          <Tags handleClickTab={handleTabIndex} data={showTagData} />
          {/* TODO: required later */}
          {/* <AnchorTag
                        handleClick={handleShowFilter}
                        fontSize="sm"
                        fontWeight="bold"
                        textTransform="uppercase"
                    >
                        {sortnFilterTxt && <FormattedText value={sortnFilterTxt} />}
                    </AnchorTag> */}
        </FlexComp>
      )}
      {planSelected?.map((item, index) => {
        const productType: "insurance" | "assistance" | "combo" =
          // @ts-ignore
          item?.packName?.text?.value?.toLowerCase?.();
        return (
          <React.Fragment key={index}>
            <InsurancePlansComp
              key={index}
              data={item}
              handleCompareSelect={() => handlePlanSelected(item?.productId)}
              handleMoreBenefits={() => {
                analytics?.trackListingEvents({
                  actionType: `View_details_${productType}`,
                });
                toggleBenefitsModal(index);
              }}
              handleTravAssist={() => {
                handleTravAssist(index);
                analytics?.trackListingEvents({
                  actionType: `View_details_${productType}`,
                });
              }}
              handleBuyButton={(data) => {
                analytics?.trackListingEvents({
                  actionType: `buy_now_clicked_outside_${productType}`,
                });
                buyProduct(data?.quoteId, data?.productId);
              }}
            />
            {showBenefits === index && (
              <MoreBenefits
                handleClose={() => toggleBenefitsModal(index)}
                moreBenfitsTitle={`${item?.totalBenefits} Benefits`}
                data={item}
                handleBuyButton={(data) => {
                  analytics?.trackListingEvents({
                    actionType: `buy_now_clicked_details_${productType}`,
                  });
                  buyProduct(data?.quoteId, data?.productId);
                }}
              />
            )}
            {showTravAssist == index && (
              <TravelAssistanceModal
                data={item}
                handleTravAssist={() => handleTravAssist(index)}
                travelAssistTitle="Travel Assistance" // should come from CB
              />
            )}
          </React.Fragment>
        );
      })}
      {showFilter && (
        <FilterPlans
          tagData={tagData}
          btnText="Apply Filter"
          filterData={filterData}
          title={"Filter Plan"}
          filterPlanHandleClick={handleShowFilter}
        />
      )}
      {count > 0 && (
        <ToastCompare
          toastCloseIcon="https://tripmoneycmsimgak.mmtcdn.com/img/cross_grey_rounded_e24cbc15d7.svg"
          toastIcon="https://tripmoneycmsimgak.mmtcdn.com/img/toast_Icon_4389f5385c.svg"
          planSelectedTxt={comparePlanText}
          toastCtaTxt={compareToastData?.toastCtaTxt}
          handleClickCta={handleSelectCompare}
          handleClose={closeToast}
          isDisable={isDisable}
          // greyBgCross={} // add in CB
        />
      )}
      {showCompareNow && comparePlan && (
        <ComparePlan
          modalTitle={compareToastData?.modalTitle}
          data={comparePlan}
          handleClose={handleSelectCompare}
          handleBuyButton={(quoteId, productId) => {
            analytics?.trackListingEvents({
              actionType: "buy_from_compare",
            });
            buyProduct(quoteId, productId);
          }}
          productId1ForCompare={productId1ForCompare}
          quoteId1ForCompare={quoteId1ForCompare}
          productId2ForCompare={productId2ForCompare}
          quoteId2ForCompare={quoteId2ForCompare}
        />
      )}
    </InsurancePlansStyle>
  );
};

export default InsurancePlans;
