import { HeaderProps } from "./HeaderProps";
import { HeaderCont, HeaderInner, HeaderLft, HeaderRht } from "./Header.styles";
import UserInfo from "../UserInfo/UserInfo";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";

const Header = ({
  logo,
  userName,
  bgColor = "#000",
  titleText,
  backArrow,
  headerType,
  children,
  userInfoHandler,
  greetingText,
  showShadow = false,
  backBtnHandler,
  headerWprId,
  backBtnId,
  userInfoId,
  userTextColor = "#fff",
  userFontSize = "xs",
  userFontWeight = "regular",
  userImg,
  handleRightBtnClick,
  showRightCta,
  rightCtaTitle,
}: HeaderProps) => {
  return (
    <HeaderCont bgColor={bgColor} showShadow={showShadow} id={headerWprId}>
      <HeaderInner headerType={headerType}>
        <HeaderLft>
          {backArrow && (
            <span onClick={backBtnHandler} id={backBtnId}>
              {backArrow}
            </span>
          )}
          {logo && logo}
          {titleText && (
            <ParaTag
              fontSize="md"
              color="#4a4a4a"
              fontWeight="bold"
              margin="0 0 0 15px"
            >
              {titleText}
            </ParaTag>
          )}
          {userImg && (
            <ImageTag
              margin="0px 0px 0px auto"
              height="24px"
              width="24px"
              borderRadius={"12px"}
              showCursorPointer
              src={userImg}
            />
          )}
        </HeaderLft>
        {showRightCta && (
          <HeaderRht>
            <AnchorTag
              fontWeight="bold"
              fontSize="sm"
              handleClick={handleRightBtnClick}
              margin="0px 16px 0px"
            >
              {rightCtaTitle}
            </AnchorTag>
          </HeaderRht>
        )}
        {!showRightCta && userName && (
          <HeaderRht>
            <UserInfo
              id={userInfoId}
              userName={userName}
              onPress={userInfoHandler}
              greetingText={greetingText}
              txtColor={userTextColor}
              fontSize={userFontSize}
              fontWeight={userFontWeight}
            />
          </HeaderRht>
        )}
      </HeaderInner>
      {children}
    </HeaderCont>
  );
};

export default Header;
