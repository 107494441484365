import { SectionDividersStyle } from "./SectionDividers.style";
interface sectionDivideType {
  small?: boolean;
  large?: boolean;
  xs?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
  margin?: string;
  backgroundColor?: string;
}

const SectionDividers = ({
  small,
  large,
  xs,
  width,
  height,
  borderRadius,
  margin,
  backgroundColor,
}: sectionDivideType) => {
  return (
    <SectionDividersStyle
      backgroundColor={backgroundColor}
      small={small}
      large={large}
      xs={xs}
      width={width}
      height={height}
      borderRadius={borderRadius}
      margin={margin}
    />
  );
};

export default SectionDividers;
