import styled from "styled-components";

import { deviceType } from "../../utils";
import { theme } from "../../css/Global";

export const LandingFaqStyle: any = styled.section`
  ${(props: any) => props.margin && `margin: ${props.margin};`}
  ${(props: any) => props.padding && `padding: ${props.padding};`}
    display: flex;
  background: #fff;
  flex-direction: column;
  width: 100%;
  background: white;

  ${(props: any) => props.customFaq && ``}
`;

type LandingFaqTitleProps = {
  marginBottom?: string;
  marginLeft?: string;
};

export const LandingFaqTitle = styled.h3<LandingFaqTitleProps>`
  padding: 0;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "56px"};
  color: ${theme.blackText};
  background: white;

  @media screen and ${deviceType.mobile} {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 8px;
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
  }
`;

export const LandingFaqList: any = styled.ul`
  list-style: none;
  width: 1000px;
  gap: 16px;
  padding: 0;
  background: white;

  li {
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
  }

  li h5 {
    padding: 24px;

    @media screen and ${deviceType.mobile} {
      padding: 16px;
    }
  }

  li > .pointCtr {
    padding: 0 24px;
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.25s;

    @media screen and ${deviceType.mobile} {
      padding: 0 16px;
    }
  }

  li > .pointCtr.open_forex {
    max-height: 1000px;
    transition: max-height 1s ease-out;
  }

  li > .pointCtr .boldText {
    font-weight: 700;
  }

  li:after {
    content: "";
    position: absolute;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    right: 24px;
    top: 33px;
    transition: 0.3s ease-in;

    @media screen and ${deviceType.mobile} {
      top: 16px;
    }
  }

  li.close_forex:after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  li.open_forex:after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  li:hover {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
  }

  li:last-child {
    margin-bottom: unset;
  }

  ${(props: any) =>
    props.customFaq &&
    `
            width:100%;
            gap:8px;
            margin-bottom: 24px;
            li {
                padding: 12px 16px;
                background: #FFFFFF;
                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
                border-radius: 6px;
            }
            li h5 {
                padding: unset;
            }
            li p {
                padding:unset;
                font-family: 'Open Sans';
                font-size: 12px;
                line-height: 16px;
                color: #000000;
            }
            li:after {
                border: solid #008CFF;
                border-width: 0 2px 2px 0;
                padding: 4px;
                right: 24px;
                top: 13px;
            }
    `}

  @media screen and ${deviceType.mobile} {
    width: 100%;

    li:hover {
      box-shadow: unset;
    }
  }
`;

export const LandingInnerPointsCtr = styled.div`
  & > div {
    margin-bottom: 16px;
  }
`;

export const LandingFaqQues: any = styled.h5`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  width: 95%;
  color: #000;
  background: white;
  ${(props: any) =>
    props.customFaq &&
    `
        font-family: 'Open Sans';
        font-size: 12px;
        line-height: 16px;
        color: #000000;`}

  @media screen and ${deviceType.mobile} {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const TabItem = styled.div<any>`
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  padding: 12px 16px;
  align-items: center;
  background-color: ${(props) =>
    props.isSelected ? "#EAF5FF" : theme.whiteText};
  border: ${(props) => (props.isSelected ? "1px solid #008CFF" : "unset")};
  font-size: 14px;
  font-weight: ${(props) => (props.isSelected ? 700 : 400)};
  color: ${(props) => (props.isSelected ? theme.blueText : theme.greyText)};
  margin-bottom: 24px;
`;
