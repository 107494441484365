interface RoundPlayIconProps {
  color?: string;
  props?: any;
}

export const RoundPlayIcon = ({
  color = "#008CFF",
  props,
}: RoundPlayIconProps) => (
  <svg
    width={30}
    height={31}
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 0.365234C6.72114 0.365234 0 7.08638 0 15.3652C0 23.6441 6.72114 30.3652 15 30.3652C23.2789 30.3652 30 23.6441 30 15.3652C30 7.08638 23.2789 0.365234 15 0.365234ZM12.2931 9.02523C11.9859 8.82613 11.594 8.81229 11.2721 8.98729C10.95 9.16229 10.75 9.49912 10.75 9.86517V20.8652C10.75 21.2312 10.95 21.5681 11.2721 21.7431C11.594 21.9181 11.9859 21.9042 12.2931 21.7051L20.7931 16.2051C21.0779 16.0201 21.25 15.7042 21.25 15.3652C21.25 15.0261 21.0779 14.7103 20.7931 14.5252L12.2931 9.02523Z"
      fill={color}
    />
  </svg>
);
