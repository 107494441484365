import { createGlobalStyle } from "styled-components";

export const CurrencyRatePageStyle = createGlobalStyle`
.breadCrumb{
    display: flex;
    margin-bottom:10px;
}
.breadCrumb li{
    font-size: 14px;
    font-weight: 700;
}
.breadCrumb li::after{
    content: "";
    display: inline-block;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    padding: 2px;
    transform: rotate(-45deg);
    margin: 0 10px;
}
.breadCrumb li:last-child:after{
    display: none;
}
.breadCrumb li a{
    color: #fff;
}
.breadCrumb li:last-child a{
    pointer-events: none;
}`;
