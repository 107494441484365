import MarketingBanner from "../../static/components/MarketingBanner/MarketingBanner";
import { BMFListingContainer } from "../../static/components/ListingPageComponents/ListingPageComponents.style";
import { WhiteBackgroundStyled } from "../../static/CommonStyle/CommonStyles.style";
import { useEffect } from "react";
import { useListingPdtLogger } from "../../hooks/usePdtLogger";
import { PDT_EVENTS } from "../../constants";
import useListingPdtLoggerV2 from "../../hooks/useListingPdtLoggerV2";
import { getEventDetails } from "../../utils";

interface MarketingBannerProps {
  bannerData?: {
    link?: string;
    cta?: string;
    partner_to_link?: {
      mmt?: string;
      gi?: string;
    };
    partner_to_cta?: {
      mmt?: string;
      gi?: string;
    };
  }[];
  delay?: number;
}

const ForexMarketingBanner = ({ bannerData, delay }: MarketingBannerProps) => {
  const { sendPdtMarketingBannerLoadEvent } = useListingPdtLogger();
  const { sendCTAData } = useListingPdtLoggerV2();

  useEffect(() => {
    sendPdtMarketingBannerLoadEvent();
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.MARKETING_BANNER_LOAD,
    });
    sendCTAData({ eventDetail });
  }, []);

  return (
    <WhiteBackgroundStyled>
      <BMFListingContainer>
        <MarketingBanner carouselData={bannerData} delay={delay} />
      </BMFListingContainer>
    </WhiteBackgroundStyled>
  );
};

export default ForexMarketingBanner;
