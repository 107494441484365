export const API_BASE_URL_HTTP = process.env["API_BASE_URL_HTTP"];

export const API_ROUTE_PREFIX = "/ext/api/v1/partners";
export const API_ROUTE_PREFIX_HTTPS = "/insurance/ext/api/v1/partners";

export const API_TIMEOUT_IN_MS = 10000;

export enum API_ROUTES {
  GET_LANDING_PAGE_DETAILS = "insurance/cb/landing",
  GET_PRODUCT_LISTING = "insurance/cb/quotes",
  GET_TRAVEL_ATTRIBUTES = "insurance/cb/travelattributes",
  PAYMENT_CHECKOUT = "insurance/cb/checkout",
  GET_PASSPORT_DETAILS = "insurance/cb/passportDetails",
  EDIT_INSURANCE_DETAILS = "insurance/cb/endorsement",
  ORDER_CONFIRMED = "insurance/cb/thankyou",
  ORDER_LISTING = "insurance/cb/orders",
  ORDER_DETAIL = "insurance/cb/orderDetail",
  TRIGGER_EMAIL = "insurance/cb/email",
}
