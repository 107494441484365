import React from "react";
import { theme } from "@tm/utility/global";
import { SpanTagStyle } from "./TextStyles.style";
import { usePartnerContext } from "@tm/static/partnerContext";
type Colors = typeof theme;
type ColorKey = keyof Colors;

interface SpanTagProps {
  color?: Colors[ColorKey];
  children?: React.ReactNode;
  fontSize?: "xxs" | "xs" | "sm" | "md" | "large" | "xlarge";
  fontWeight?: "regular" | "bold" | "black";
  margin?: string;
  textTransform?: "uppercase" | "lowercase";
  dangerousText?: string;
  style?: React.CSSProperties;
}

const SpanTag: React.FC<SpanTagProps> = ({
  fontSize = "sm",
  fontWeight = "regular",
  color = "black",
  margin,
  children,
  textTransform,
  dangerousText,
  style,
}) => {
  if (dangerousText && typeof dangerousText !== "string") {
    dangerousText = String(dangerousText);
  }
  const context = usePartnerContext();

  return dangerousText ? (
    <SpanTagStyle
      margin={margin}
      txtfontSize={fontSize}
      txtfontWeight={fontWeight}
      txtcolor={color}
      dangerouslySetInnerHTML={{ __html: dangerousText }}
      context={context ? context : undefined}
    />
  ) : (
    <SpanTagStyle
      style={style}
      margin={margin}
      txtfontSize={fontSize}
      txtfontWeight={fontWeight}
      txtcolor={color}
      textTransform={textTransform}
      context={context ? context : undefined}
    >
      {children}
    </SpanTagStyle>
  );
};

export default SpanTag;
