import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useEffect } from "react";

export const useInsuranaceAnimation = () => {
  const ANIMATION_TYPE = "ease-in-out";
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    setTimeout(() => {
      const rootContainerElement = document.getElementById("root_container");
      const featRefElement = document.getElementById("feat_ref");
      const lug1Element = document.getElementById("lug1_ref");
      const lug2Element = document.getElementById("lug2_ref");
      const shieldElement = document.getElementById("shield_ref");
      const shieldRectElement = document.getElementById("shieldRect_ref");
      const app1Element = document.getElementById("app1_ref");
      const app2Element = document.getElementById("app2_ref");
      const coinsElement = document.getElementById("coins_ref");
      const moneyBagElement = document.getElementById("moneyBag_ref");

      gsap.to(moneyBagElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: featRefElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        x: -44,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(app1Element, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: featRefElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        opacity: 1,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(app2Element, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: featRefElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        opacity: 1,
        ease: ANIMATION_TYPE,
        duration: 0.9,
        delay: 0.5,
      });

      //-----
      gsap.to(coinsElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: featRefElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        opacity: 1,
        delay: 0.4,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(lug1Element, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: featRefElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        x: -43,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(lug2Element, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: featRefElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        x: 81,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(shieldElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: featRefElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        opacity: 1,
        scale: 1,
        delay: 0.3,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(shieldRectElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: featRefElement,
          start: "top+=100px center",
          toggleActions: "restart none none reverse",
        },
        y: -30,
        opacity: 1,
        ease: ANIMATION_TYPE,
        delay: 0.3,
        duration: 0.9,
      });
    }, 300);
  }, []);
};
