import styled from "styled-components";

import { theme } from "@tm/utility/global";
import { deviceType } from "@tm/static/utils";

export const PersuasionTileStyles = styled.div<any>`
  background: ${(props) => props.bgColor};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px 8px 8px;
  ${(props) => props.margin && `margin: ${props.margin};`}
`;

export const LabelTag = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin-left: 4px;
  color: ${theme.blackText};

  .blackBoldText {
    font-weight: 700;
  }

  .greenBoldText {
    font-weight: 700;
    color: ${theme.greenText};
  }

  @media screen and ${deviceType.mobile} {
    margin-left: 0px;
  }
`;
