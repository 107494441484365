import React from "react";

import { FlexComp, SectionDividers } from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";

import TravellingToWidgetShimmer from "../TravellingToWidget/TravellingToWidgetShimmer";
import ShimmerVersion2 from "../Shimmer/ShimmerVersion2";
import ShimmerVersion3 from "../Shimmer/ShimmerVersion3";

import {
  TravellingToStyle,
  TravelNote,
  TravellingToStyleWrap,
} from "./TravelDetails.style";

const TravellingToShimmer = () => {
  const device = useBreakpointV2();
  return (
    <TravellingToStyleWrap>
      <TravelNote>
        <ShimmerVersion3 height="22px" />
      </TravelNote>
      <TravellingToStyle>
        <ShimmerVersion2 margin="0 0 16px" width="100%" height="28px" />
        <ShimmerVersion2 margin="0 0 8px" width="80%" height="16px" />
        <FlexComp
          margin={device == "sm" ? "0 0 20px" : ""}
          flexWrap="wrap"
          gap="8px"
        >
          {Array.from({ length: 5 }, (_, index) => (
            <ShimmerVersion2 key={index} width="89px" height="79px" />
          ))}
        </FlexComp>
        {device == "lg" && <SectionDividers margin="30px -30px 30px" xs />}
        <TravellingToWidgetShimmer />
        <ShimmerVersion3 width="100%" height="44px" />
      </TravellingToStyle>
    </TravellingToStyleWrap>
  );
};

export default TravellingToShimmer;
