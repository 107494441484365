import React from "react";
import CommonModalDweb from "../CommonModalDweb/CommonModalDweb";

import PlanTable from "./PlanTable";
import PlanTableFooter from "./PlanTableFooter";

import ComparePlanProps from "./types";

const ComparePlan = ({
  handleClose,
  modalTitle,
  data,
  handleBuyButton,
  productId1ForCompare,
  quoteId1ForCompare,
  productId2ForCompare,
  quoteId2ForCompare,
}: ComparePlanProps) => {
  return (
    <CommonModalDweb
      customWidth={"524px"}
      customTitle={modalTitle}
      handleClick={handleClose}
    >
      <PlanTable
        data={data}
        quoteID1={quoteId1ForCompare}
        quoteID2={quoteId2ForCompare}
      />
      <PlanTableFooter
        btn1Handler={() =>
          handleBuyButton(quoteId1ForCompare, productId1ForCompare)
        }
        btn2Handler={() =>
          handleBuyButton(quoteId2ForCompare, productId2ForCompare)
        }
        footerData={data?.footer}
        quoteID1={quoteId1ForCompare}
        quoteID2={quoteId2ForCompare}
      />
    </CommonModalDweb>
  );
};

export default ComparePlan;
