import { IImage } from "@tm/interfaces";
import { useHeaderAnimation } from "@tm/ui/hooks";
import {
  LandingHeader,
  LandingHeaderList,
  LandingHeaderWrap,
  TMLogo,
} from "./index.style";
import { pushEvent } from "@tm/utility/analytics";
import {
  LINKS,
  PageNameMapping,
  SectionNameMapping,
  ElementNameMapping,
  getElementUniqueIds,
} from "@tm/utility/constants";
import Logo from "../TMLogo";
import { useState } from "react";
import { ActiveLink } from "@tm/ui/ui-container";
import Link from "next/link";

interface ITMLogo {
  name?: string;
  redirectUrl: string;
  svg?: string;
}
export interface IHeader {
  tmLogo?: ITMLogo;
  darkTmLogo?: IImage;
  blueTmLogo?: IImage;
  faq?: any;
  forexHeader?: boolean;
  headerRef?: any;
  darkImgRef?: any;
  lightImgRef?: any;
  faqCTAEvent?: any;
  aboutUs?: any;
  isLight?: boolean;
  onClickFaqLink?: Function;
  faqRedirectionUrl?: string;
}

const Header = ({
  tmLogo,
  forexHeader,
  faq,
  faqCTAEvent,
  aboutUs,
  onClickFaqLink,
  faqRedirectionUrl,
}: IHeader) => {
  const [isLight, setIsLight] = useState(true);
  useHeaderAnimation(setIsLight);

  const onClickFaq = () => {
    if (faqCTAEvent) pushEvent(faqCTAEvent);
    if (typeof onClickFaqLink === "function") {
      onClickFaqLink();
    }
  };

  return (
    <LandingHeader forexHeader={forexHeader} id="header_ref" isLight={isLight}>
      <LandingHeaderWrap>
        <TMLogo
          // href={tmLogo?.redirectUrl}
          aria-label={tmLogo?.name}
          id={getElementUniqueIds(
            PageNameMapping.LISTING_PAGE,
            SectionNameMapping.HEADER,
            ElementNameMapping.TM_LOGO
          )}
          className="darkRef"
        >
          <Logo isLight={isLight} />
        </TMLogo>
        <LandingHeaderList>
          {faq && (
            <li
              id={getElementUniqueIds(
                PageNameMapping.LISTING_PAGE,
                SectionNameMapping.HEADER,
                ElementNameMapping.FAQ
              )}
            >
              {faqRedirectionUrl ? (
                <Link href={faqRedirectionUrl}>{faq.title}</Link>
              ) : (
                <ActiveLink
                  href={LINKS.FAQ}
                  aria-label={faq.title}
                  onClick={onClickFaq}
                >
                  {faq.title}
                </ActiveLink>
              )}
            </li>
          )}
          {aboutUs && (
            <li
              id={getElementUniqueIds(
                PageNameMapping.LISTING_PAGE,
                SectionNameMapping.HEADER,
                ElementNameMapping.ABOUT_US
              )}
            >
              <ActiveLink href={aboutUs.redirectUrl} aria-label={aboutUs.title}>
                {aboutUs.title}
              </ActiveLink>
            </li>
          )}
        </LandingHeaderList>
      </LandingHeaderWrap>
    </LandingHeader>
  );
};

export default Header;
