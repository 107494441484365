import React, { Fragment } from "react";

import { Modal, LandingButton, LandingButtonWhite } from "@tm/static";

import { useBreakpoint } from "@tm/ui/hooks";
import { CloseIcon, CrossIcon } from "@tm/static/icons";

import {
  selectCountryModalDataProps,
  slctCtryMdlDataCcyListItemProps,
} from "@tm/static/data";

import {
  ChooseLocationStyle,
  ChooseLocationInrStyle,
  ButtonContainer,
  StepCounter,
} from "./ChooseLocation.style";

// Country Selection Modal
import SelectCountry from "../SelectCountry/SelectCountry";

import { BtnProp } from "../../LandingButton/ButtonType";

export interface screenComponentsProps {
  id: string;
  nextBtnProps: BtnProp;
  previousBtnProps: BtnProp;
  component: React.ReactNode;
}

interface ChooseLocationProps {
  onModalClose?: () => void;
  screensComponents?: screenComponentsProps[];
  activeScreen: number;
  isSelectCountryModalOpen?: boolean;
  selectCountryData?: {
    title: string;
    subTitle?: string;
    selectCountryModalData: selectCountryModalDataProps[];
    isFetchingRate?: boolean;
    setIsFetchingRate?: React.Dispatch<React.SetStateAction<boolean>>;
    currencyItemHandler: (
      item: slctCtryMdlDataCcyListItemProps,
      type: string,
      idx: number
    ) => void;
    inputLabelTxt?: string;
    inputPlaceholderTxt?: string;
    inputValue: string;
    inputDisableState?: boolean;
    inputHandleChange: (e: any) => void;
    selectCountryLabel?: string;
    currencyLabel?: string;
    popularCountryTxt?: {
      leftLabel: string;
      rightLabel: string;
    };
    otherCountryTxt?: {
      leftLabel: string;
      rightLabel: string;
    };
    btnProps?: BtnProp;
  };
}

const ChooseLocation = ({
  onModalClose = () => {},
  activeScreen = 0,
  screensComponents,
  selectCountryData,
  isSelectCountryModalOpen,
}: ChooseLocationProps) => {
  const device = useBreakpoint();

  const stepCounterRenderer = () => {
    return screensComponents ? (
      <StepCounter>
        {screensComponents.map((_, idx) => {
          return (
            <li key={idx} className={idx <= activeScreen ? "activeBar" : ""} />
          );
        })}
      </StepCounter>
    ) : (
      <Fragment />
    );
  };

  return !isSelectCountryModalOpen ? (
    <Modal
      padding="0px"
      modalWidth={480}
      closeButton={<CloseIcon />}
      onModalClose={onModalClose}
      modalHeadingComp={stepCounterRenderer()}
      modalHeadingCtrMargin="24px 24px 32px 24px"
      modalType={device === "lg" ? "centered" : "bottomSheet"}
    >
      <ChooseLocationStyle>
        {screensComponents && screensComponents[activeScreen] && (
          <ChooseLocationInrStyle>
            {screensComponents[activeScreen].component}
          </ChooseLocationInrStyle>
        )}
        {screensComponents &&
          (screensComponents[activeScreen].previousBtnProps ||
            screensComponents[activeScreen].nextBtnProps) && (
            <ButtonContainer>
              {activeScreen !== 0 ? (
                <Fragment>
                  <LandingButtonWhite
                    {...screensComponents[activeScreen].previousBtnProps}
                    padding="0px 24px"
                    customWidth={"100%"}
                    margin="0px 16px 0px 0px"
                  />
                  <LandingButton
                    {...screensComponents[activeScreen].nextBtnProps}
                    padding="0px 24px"
                    customWidth={"100%"}
                  />
                </Fragment>
              ) : (
                <LandingButton
                  {...screensComponents[activeScreen].nextBtnProps}
                  padding="0px 24px"
                  customWidth={"100%"}
                />
              )}
            </ButtonContainer>
          )}
      </ChooseLocationStyle>
    </Modal>
  ) : selectCountryData ? (
    <Modal
      // padding="0px"
      modalWidth={480}
      closeButton={<CloseIcon />}
      onModalClose={onModalClose}
      showBackBtn={true}
      backBtn={device === "sm" && <CrossIcon />}
      modalHeading={selectCountryData.title}
      modalSubHeading={selectCountryData.subTitle}
      modalHeadingCtrMargin="0px 0px 20px"
      modalType={device === "lg" ? "centered" : "fullPage"}
    >
      <SelectCountry
        countryData={selectCountryData.selectCountryModalData}
        currencyItemHandler={selectCountryData.currencyItemHandler}
        isFetchingRate={selectCountryData.isFetchingRate}
        setIsFetchingRate={selectCountryData.setIsFetchingRate}
        selectCountryInput={{
          labelTxt: selectCountryData?.inputLabelTxt,
          placeholderTxt: selectCountryData?.inputPlaceholderTxt,
          inputValue: selectCountryData?.inputValue,
          disableState: selectCountryData?.inputDisableState,
          handleChange: selectCountryData?.inputHandleChange,
        }}
        selectCountryLabel={selectCountryData?.selectCountryLabel}
        currencyLabel={selectCountryData?.currencyLabel}
        btnProps={selectCountryData?.btnProps}
      />
    </Modal>
  ) : (
    <Fragment />
  );
};

export default ChooseLocation;
