import { useEffect, useState } from "react";
import {
  PRODUCT_CODE,
  PRODUCT_TYPE,
  SEO_REDIRECTIONS,
} from "../../static/constants";
import {
  checkIfVaildCardHolder,
  getUtmParamsForCalculatorCta,
} from "../../static/utils";

export default function ForexCalculator({
  currencyData,
  inrExchangeRate,
  currCodeToCurrRate,
  switchInrExchnageRate,
  templateType = "",
}: any) {
  const [amountToBeConvert, setAmountToBeConvert] = useState(
    currencyData.currencyDenominationVal || 1
  );
  const [isForexCardSelected, setIsForexCardSelected] = useState(true);
  const [convertedAmonutInInr, setConvertedAmonutInInr] = useState<string>();
  const [selectedCurrency, setSelectedCurrency] = useState("USD");

  useEffect(() => {
    updateStatesBasedOnCurrency(currencyData?.curr_code);
  }, [currencyData]);

  const checkCurrencyExistInProductType = (
    currency: string,
    isForexCard: boolean
  ) => {
    const currentCurrencyObj =
      currencyData?.forex_calculator?.currencies_map?.find(
        (e: any) => e.currency_code === currency
      );
    if (
      isForexCard &&
      currentCurrencyObj?.product_type?.includes(PRODUCT_CODE.CARD)
    ) {
      return true;
    } else if (
      !isForexCard &&
      currentCurrencyObj?.product_type?.includes(PRODUCT_CODE.CURRENCY)
    ) {
      return true;
    }
    return false;
  };

  const updateStatesBasedOnCurrency = (currency: string) => {
    const currencyExistInCard = checkCurrencyExistInProductType(currency, true);
    const currencyExistInCurrency = checkCurrencyExistInProductType(
      currency,
      false
    );
    setIsForexCardSelected(currencyExistInCard);
    if (currencyExistInCard) {
      setSelectedCurrency(currency);
    } else if (currencyExistInCurrency) {
      setSelectedCurrency(currency);
    }
    const rate = currCodeToCurrRate?.[currency]?.[currencyExistInCard ? 0 : 1];
    setConvertedAmonutInInr(
      (rate * amountToBeConvert).toFixed(rate > 1 ? 2 : 4)
    );
  };

  const handleRedirection = async () => {
    if (isForexCardSelected) {
      const isVaildCardHolder = await checkIfVaildCardHolder();
      if (isVaildCardHolder) {
        window.open(
          `${SEO_REDIRECTIONS?.CARD_LOAD}${getUtmParamsForCalculatorCta(
            PRODUCT_TYPE.CARD,
            templateType
          )}`
        );
      } else {
        window.open(
          `${SEO_REDIRECTIONS?.CARD_BUY}${getUtmParamsForCalculatorCta(
            PRODUCT_TYPE.CARD,
            templateType
          )}`
        );
      }
    } else {
      window.open(
        `${SEO_REDIRECTIONS?.CURRENCY_BUY}${getUtmParamsForCalculatorCta(
          PRODUCT_TYPE.CURRENCY,
          templateType
        )}`
      );
    }
  };

  const handleRadioClick = ({ isForexCard }: { isForexCard: boolean }) => {
    setIsForexCardSelected(isForexCard);
    switchInrExchnageRate(isForexCard);
    const currencyExistInCard = checkCurrencyExistInProductType(
      selectedCurrency,
      true
    );
    if (isForexCard && !currencyExistInCard) {
      setSelectedCurrency("USD");
      setAmountToBeConvert(amountToBeConvert);
      const rate = currCodeToCurrRate?.["USD"]?.[isForexCard ? 0 : 1];
      setConvertedAmonutInInr(
        (amountToBeConvert * rate).toFixed(rate > 1 ? 2 : 4)
      );
    } else {
      setAmountToBeConvert(amountToBeConvert);
      const rate =
        currCodeToCurrRate?.[selectedCurrency]?.[isForexCard ? 0 : 1];
      setConvertedAmonutInInr(
        (amountToBeConvert * rate).toFixed(rate > 1 ? 2 : 4)
      );
    }
  };

  const handleOnChangeOfAmount = (e: any) => {
    setAmountToBeConvert(e.target.value);
    const rate =
      currCodeToCurrRate?.[selectedCurrency]?.[isForexCardSelected ? 0 : 1];
    setConvertedAmonutInInr((e.target.value * rate).toFixed(rate > 1 ? 2 : 4));
  };

  const handleSelectionOfCurrency = (curr_code: string) => {
    setSelectedCurrency(curr_code);
    const rate = currCodeToCurrRate?.[curr_code]?.[isForexCardSelected ? 0 : 1];
    setConvertedAmonutInInr(
      amountToBeConvert
        ? (amountToBeConvert * rate).toFixed(rate > 1 ? 2 : 4)
        : "0"
    );
  };

  return (
    <div className="heroContent__right">
      <form action="#" onSubmit={(e) => e.preventDefault()}>
        <h2 className="heroContent__title">
          Our Live <br />
          Conversion Rates
        </h2>
        <div className="heroContent__option">
          <span className="radioBox">
            <input
              type="radio"
              name="r1"
              id="r1"
              checked={isForexCardSelected}
              onClick={() => {
                handleRadioClick({ isForexCard: true });
              }}
            />
            <label className="optionText" htmlFor="r1">
              Forex card
            </label>
          </span>
          <span className="radioBox">
            <input
              type="radio"
              name="r1"
              id="r2"
              checked={!isForexCardSelected}
              onClick={() => {
                handleRadioClick({ isForexCard: false });
              }}
            />
            <label className="optionText" htmlFor="r2">
              Currency notes
            </label>
          </span>
        </div>

        <div className="floatingSelect">
          <select
            className="floatingSlctBox"
            onChange={(event) =>
              handleSelectionOfCurrency(event?.target?.value)
            }
            value={selectedCurrency}
            id="slect"
          >
            {/* <option value="">{selectedCurrency}</option>
            {currencyData?.forex_calculator?.currCode?.map(
              (item: any, index: number) => (
                <option key={index} value={index}>
                  {item} ({currencyData?.forex_calculator?.currName[index]})
                </option>
              )
            )} */}
            <option value="">Select Currency</option>
            {currencyData?.forex_calculator?.currencies_map?.map(
              (item: any, index: number) => {
                if (
                  isForexCardSelected &&
                  item?.product_type?.includes(PRODUCT_CODE.CARD)
                ) {
                  return (
                    <option key={index} value={item?.currency_code}>
                      {item?.currency_code} ({item?.currency_name})
                    </option>
                  );
                } else if (
                  !isForexCardSelected &&
                  item?.product_type?.includes(PRODUCT_CODE.CURRENCY)
                ) {
                  return (
                    <option key={index} value={item?.currency_code}>
                      {item?.currency_code} ({item?.currency_name})
                    </option>
                  );
                }
                return null;
              }
            )}
          </select>
          <label htmlFor="slect" className="floatingSelect__label">
            currency
          </label>
        </div>
        <div className="inputContainer">
          <div className="inputContainer__col">
            <div className="form-group">
              <input
                type="text"
                placeholder=""
                id="AmountInGEL"
                style={{ marginTop: "6px" }}
                value={amountToBeConvert || ""}
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (value === "" || /^\d+$/.test(value)) {
                    handleOnChangeOfAmount(e);
                  }
                }}
              />
              <label htmlFor="AmountInGEL">Amount in {selectedCurrency}</label>
            </div>
          </div>
          <span className="inputContainer__separator">
            <img
              src="https://tripmoneycmsimgak.mmtcdn.com/img/grey_arrow_98b3fc0a80.png"
              alt="arrow"
              width="14"
              height="11"
            />
          </span>
          <div className="inputContainer__col">
            <div className="form-group">
              <input
                type="text"
                placeholder=""
                id="AmountInINR"
                value={convertedAmonutInInr}
                style={{ marginTop: "6px" }}
              />
              <label htmlFor="AmountInINR">Amount in INR</label>
            </div>
          </div>
        </div>

        <button
          className="btnSubmit"
          type="submit"
          onClick={() => handleRedirection()}
        >
          {isForexCardSelected
            ? "BUY FOREX CARD NOW"
            : "BUY CURRENCY NOTES NOW"}
        </button>
      </form>
    </div>
  );
}
