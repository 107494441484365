import { LandingButtonStyle } from "../LandingButton/LandingButton.style";
import styled from "styled-components";
import { SpanTagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "libs/ui-widgets/src/lib/static/utils";

export interface BenefitProp {
  firstRow?: boolean;
  lastRow?: boolean;
}

export const BenefitRow = styled.div<BenefitProp>`
  display: flex;
  border-bottom: 1px solid #d3d9df;
  gap: 10px;
  ${(p) =>
    p.lastRow &&
    `
    border-bottom: none;
    `}
  ${LandingButtonStyle} {
    height: 32px;
  }
  ${LandingButtonStyle} span {
    font-size: 12px;
  }
  @media ${deviceType.mobile} {
    gap: 0;
  }
`;

export const BenefitCol1 = styled.div`
  display: flex;
  gap: 10px;
  flex: 1.5;
  padding: 12px;
  @media ${deviceType.mobile} {
    padding: 12px 4px;
    flex: 1.7;
  }
`;

export const BenefitCol2 = styled.div<BenefitProp>`
  flex: 1.2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-left: solid 1px #d3d9df;
  border-right: solid 1px #d3d9df;
  padding: 12px;
  ${(p) =>
    p.firstRow &&
    `
    border-top:solid 1px #d3d9df;
    border-radius:8px 8px 0 0;
    `}
  ${(p) =>
    p.lastRow &&
    `
    border-bottom:solid 1px #d3d9df;
    border-radius:0 0 8px 8px;
    `}
    @media ${deviceType.mobile} {
    border-right: none;
    padding: 12px 10px;
    ${(p) =>
      p.firstRow &&
      `
    border-radius:8px 0px 0 0;
    `}
  }
`;

export const BenefitCol3 = styled.div<BenefitProp>`
  flex: 1.2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-left: solid 1px #d3d9df;
  border-right: solid 1px #d3d9df;
  padding: 12px;
  ${(p) =>
    p.firstRow &&
    `
    border-top:solid 1px #d3d9df;
    border-radius:8px 8px 0 0;
    `}
  ${(p) =>
    p.lastRow &&
    `
    border-bottom:solid 1px #d3d9df;
    border-radius:0 0 8px 8px;
    `}
    @media ${deviceType.mobile} {
    padding: 12px 10px;
    ${(p) =>
      p.firstRow &&
      `
    border-top:solid 1px #d3d9df;
    border-radius:0px 8px 0 0;
    `}
  }
`;

export const BenefitDescHeader = styled.div`
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: flex;
  gap: 10px;
  ${SpanTagStyle} {
    flex-shrink: 0;
  }
  @media ${deviceType.mobile} {
    gap: 0;
  }
`;
