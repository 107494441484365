import React from "react";

import { UserInfoProps } from "./UserInfoProps";
import { UserCont, UserIcon } from "./UserInfo.styles";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";

const UserInfo: React.FC<UserInfoProps> = ({
  userName,
  greetingText = "Hey",
  txtColor = "#fff",
  fontSize = "xs",
  fontWeight = "regular",
  onPress,
  id,
}) => {
  return (
    <UserCont onClick={onPress} id={id}>
      <UserIcon>{userName.split("")[0]}</UserIcon>
      <ParaTag color={txtColor} fontWeight={fontWeight} fontSize={fontSize}>
        {greetingText} {userName}
      </ParaTag>
    </UserCont>
  );
};

export default UserInfo;
