import { useEffect, useState } from "react";
import { ParaTag, FlexComp, ImageTag, SpanTag } from "@tm/static";
import AnchorTag from "@forex/Forex/TextStylesForex/AnchorTag";
import { UploadWpr } from "./AddOrSelectTicket.style";
import { theme } from "@tm/utility/global";

import RightTick from "@tm/static/icons/RightTick.svg";
import PreviewEye from "@forex/Forex/Icon/PreviewEye";
import { DOCUMENTS_TYPE, getDocNameBasedOnType } from "./utils";
import { TicketProp, UploadTicketImageProps } from "./type";

export const UploadTicketImage = ({
  onUploadClick,
  disabledText,
  onError,
  documentLink,
  handlePreiewEyeClick,
  handleDisabledText,
  ticketsData,
  onEditClick,
  renderUploadImage,
}: UploadTicketImageProps) => {
  const [ticketListState, setTicketListState] = useState<TicketProp[]>(
    ticketsData || []
  );
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (ticketsData?.length) {
      const tickets: TicketProp[] = ticketsData
        .sort((a, b) => {
          if (a?.type === DOCUMENTS_TYPE.ticket) return -1;
          if (b?.type === DOCUMENTS_TYPE.ticket) return 1;
          return 0;
        })
        .map((item) => {
          return {
            ...item,
            document_name: item?.document_name || getDocNameBasedOnType(item),
          };
        });
      setTicketListState(tickets);
    }
  }, [ticketsData]);

  const getUnUploadedTicketData = () => {
    const unUploadedTickets = ticketListState?.filter((ticket) => !ticket?.url);
    return unUploadedTickets[0];
  };

  return (
    <UploadWpr>
      <FlexComp
        padding="12px"
        borderRadius="12px"
        alignItems="center"
        justifyContent="space-between"
      >
        <ParaTag color={theme.greyText} fontSize="xs" fontWeight="bold">
          Add Ticket from Device
        </ParaTag>
        <FlexComp alignItems="center" justifyContent="end" gap="20px">
          {!disabledText && renderUploadImage ? (
            renderUploadImage({
              isEdit: false,
              docData: getUnUploadedTicketData(),
              onError: (msg: string) => setError(msg),
              onUpload: (response: any) => onUploadClick(response),
              documentsList: ticketListState,
            })
          ) : (
            <AnchorTag
              fontWeight="bold"
              fontSize="xs"
              color={"rgba(0, 0, 0, 0.3)"}
              handleClick={handleDisabledText}
            >
              UPLOAD
            </AnchorTag>
          )}
        </FlexComp>
      </FlexComp>
      {ticketListState &&
        ticketListState.map(
          (ticketUploaded, index) =>
            ticketUploaded?.url &&
            ticketUploaded?.isUploaded && (
              <FlexComp
                key={index}
                padding="0 12px 12px"
                alignItems="center"
                justifyContent="space-between"
              >
                <FlexComp
                  flexDirection="row"
                  alignItems="center"
                  padding="0"
                  margin="0"
                >
                  <ImageTag
                    width="16px"
                    height="16px"
                    src={RightTick.src}
                    margin="0 8px 0 0"
                  />
                  <SpanTag fontSize="xs">
                    {ticketUploaded?.document_name}
                  </SpanTag>
                </FlexComp>
                <FlexComp alignItems="center" justifyContent="end" gap="10px">
                  <div>
                    <AnchorTag
                      hrefProp={documentLink(ticketUploaded)}
                      target="_blank"
                      handleClick={handlePreiewEyeClick(ticketUploaded)}
                    >
                      <PreviewEye />
                    </AnchorTag>
                  </div>
                  {renderUploadImage &&
                    renderUploadImage({
                      isEdit: true,
                      docData: ticketUploaded,
                      onError: (msg: string) => setError(msg),
                      onUpload: (response: any) => onEditClick(response),
                      documentsList: ticketListState,
                    })}
                </FlexComp>
              </FlexComp>
            )
        )}
      {error && (
        <FlexComp
          justifyContent="end"
          padding="12px"
          borderRadius="12px"
          alignItems="center"
        >
          <SpanTag fontSize="xxs" color={theme?.redText}>
            {error}
          </SpanTag>
        </FlexComp>
      )}
    </UploadWpr>
  );
};
