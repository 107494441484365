import React, { useState } from "react";

import { FlexComp, ParaTag, SpanTag } from "@tm/static";
import { AccordianCompStyle } from "./AccordianComp.style";
import { BlueChev } from "@tm/static/icons";
import { theme } from "@tm/utility/global";

interface accordionProp {
  title?: string;
  subInfo?: string;
  info?: string;
  handleClickAccordion?: () => void;
  accordionStatus?: boolean;
  children?: React.ReactNode;
}

const AccordianComp = ({
  title,
  subInfo,
  info,
  handleClickAccordion,
  accordionStatus,
  children,
}: accordionProp) => {
  return (
    <AccordianCompStyle>
      <FlexComp
        alignItems="center"
        justifyContent="space-between"
        handleClick={handleClickAccordion}
      >
        <SpanTag
          fontSize="large"
          fontWeight={accordionStatus ? "bold" : "regular"}
        >
          {title}
        </SpanTag>
        <BlueChev />
      </FlexComp>
      {subInfo && <SpanTag color={theme.greyText}>{subInfo}</SpanTag>}
      {accordionStatus && (
        <ParaTag fontSize="md" color={theme.greyText}>
          {info}
        </ParaTag>
      )}
      {children}
    </AccordianCompStyle>
  );
};

export default AccordianComp;
