import { useRef, useEffect, useState, ReactNode } from "react";
import { createPortal } from "react-dom";

interface OverlayProps {
  children: ReactNode;
}

const ReactPortalComp = (props: OverlayProps) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>("#portal");
    setMounted(true);
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return mounted && ref.current
    ? createPortal(<div>{props.children}</div>, ref.current)
    : null;
};

export default ReactPortalComp;
