import { AxiosResponse } from "axios";

import {
  API_ROUTES,
  API_ROUTE_PREFIX,
  API_ROUTE_PREFIX_HTTPS,
} from "./apiConstants";
import { getPartnerOnClientSide, setURLQueryParams } from "../utils";
import { CONTEXT, PLATFORM, LOB, PARTNER } from "../constants";
import { API_HTTP, API_HTTPS } from "./config";
import {
  IPageRequestParams,
  IListingRequestParams,
  IAddPassportRequestParams,
  IOrderConfirmedRequestParams,
  IOrderDetailsRequestParams,
  IEditTravellersRequestParams,
  ApiResponseType,
  IPassportDetailsResponseData,
  ITravellerDetailsResponseData,
} from "./apiTypes";

export const getLandingPageDetails = async (reqData: IPageRequestParams) => {
  try {
    const { partner, headers, cmp, query } = reqData;

    const params = {
      ...{},
      ...query,
    };

    const urlParams = setURLQueryParams(params);

    const response = await API_HTTP.get(
      `${API_ROUTE_PREFIX}/${partner}/${API_ROUTES.GET_LANDING_PAGE_DETAILS}${
        urlParams ? `?${urlParams}` : ""
      }`,
      {
        headers: { ...headers, cmp },
      }
    );
    console.log({ response });
    return response;
  } catch (error) {
    console.log({ error: error });
    throw error;
  }
};

export const getProductListing = async (reqData: IListingRequestParams) => {
  try {
    const { partner = PARTNER.MMT, headers, cmp, query, ...data } = reqData;

    const params = {
      lob: LOB.STANDALONE,
      platform: PLATFORM.WEB,
      context: CONTEXT.STANDALONE_V2,
      isCountryCodesSupported: true,
      partner,
      ...query,
    };

    const urlParams = setURLQueryParams(params);

    const response = await API_HTTP.post(
      `${API_ROUTE_PREFIX}/${partner}/${API_ROUTES.GET_PRODUCT_LISTING}${
        urlParams ? `?${urlParams}` : ""
      }`,
      data,
      {
        headers: { ...headers, cmp },
      }
    );
    return response;
  } catch (error) {
    console.log({ error: error });
    throw error;
  }
};

export const getTravelAttributes = async (
  reqData: IPageRequestParams
): Promise<AxiosResponse<ApiResponseType<ITravellerDetailsResponseData>>> => {
  try {
    const { partner = PARTNER.MMT, headers, cmp, query } = reqData || {};

    const {
      isUpcomingTrip,
      bookingId,
      countries,
      startDate,
      endDate,
      travellers,
      ...queryParams
    } = query || {};

    const params = {
      lob: LOB.STANDALONE,
      platform: PLATFORM.WEB,
      context: CONTEXT.STANDALONE_V2,
      travelPurpose: "Leisure Trip",
      ...(isUpcomingTrip && {
        upcomingTraveller: isUpcomingTrip === "true" ? true : false,
      }),
      ...(bookingId && { booking_id: bookingId.toString() }),
      ...(countries && { countryCodes: countries.toString() }),
      ...(startDate && { departureDateTime: Number(startDate) }),
      ...(endDate && { arrivalDateTime: Number(endDate) }),
      ...(travellers && { numberOfTravellers: Number(travellers) }),
      ...queryParams,
    };

    const urlParams = setURLQueryParams(params);

    const response = await API_HTTP.get(
      `${API_ROUTE_PREFIX}/${partner}/${API_ROUTES.GET_TRAVEL_ATTRIBUTES}${
        urlParams ? `?${urlParams}` : ""
      }`,
      {
        headers: { ...headers, cmp },
      }
    );
    return response;
  } catch (error) {
    console.error("Error", { error });
    throw error;
  }
};

export const paymentCheckout = async (reqData: any): Promise<any> => {
  try {
    const { partner = getPartnerOnClientSide(), data } = reqData;
    const params = {
      context: CONTEXT.STANDALONE_V2,
      ins_new_flow: true,
    };
    const urlParams = setURLQueryParams(params);

    const response = await API_HTTPS.post(
      `${API_ROUTE_PREFIX_HTTPS}/${partner}/${API_ROUTES.PAYMENT_CHECKOUT}${
        urlParams ? `?${urlParams}` : ""
      }`,
      data,
      {
        headers: { cmp: sessionStorage.getItem("cmp") || undefined },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error", { error });
    throw error;
  }
};

export const getPassportDetails = async (
  reqData: IAddPassportRequestParams
): Promise<AxiosResponse<ApiResponseType<IPassportDetailsResponseData>>> => {
  try {
    const {
      bookingId,
      pageId,
      partner = PARTNER.MMT,
      headers,
      cmp,
      query,
    } = reqData;
    const params = { bookingId, pageId, ...query };
    const urlParams = setURLQueryParams(params);

    const response = await API_HTTP.get(
      `${API_ROUTE_PREFIX}/${partner}/${API_ROUTES.GET_PASSPORT_DETAILS}${
        urlParams ? `?${urlParams}` : ""
      }`,
      {
        headers: { ...headers, cmp },
      }
    );
    return response;
  } catch (error) {
    console.log("Error", { error });
    throw error;
  }
};

export const getOrderConfirmedDetails = async (
  data: IOrderConfirmedRequestParams
): Promise<any> => {
  const { partner, bookingId, headers, cmp, query } = data;
  try {
    const params = {
      tmBookingId: bookingId,
      ...query,
    };

    const urlParams = setURLQueryParams(params);

    const response = await API_HTTP.get(
      `${API_ROUTE_PREFIX}/${partner}/${API_ROUTES.ORDER_CONFIRMED}${
        urlParams ? `?${urlParams}` : ""
      }`,
      {
        headers: { ...headers, cmp },
      }
    );

    return response;
  } catch (error) {
    console.error("Error", { error });
    throw error;
  }
};

export const getOrderListing = async (
  reqData: IPageRequestParams
): Promise<any> => {
  try {
    const { partner = PARTNER.MMT, headers, cmp, query } = reqData;
    const params = {
      context: CONTEXT.STANDALONE_V2,
      ins_new_flow: true,
      ...query,
    };
    const urlParams = setURLQueryParams(params);

    const response = await API_HTTP.get(
      `${API_ROUTE_PREFIX}/${partner}/${API_ROUTES.ORDER_LISTING}${
        urlParams ? `?${urlParams}` : ""
      }`,
      {
        headers: { ...headers, cmp },
      }
    );
    return response;
  } catch (error) {
    console.error("Error", { error });
    throw error;
  }
};

export const getOrderDetails = async (
  data: IOrderDetailsRequestParams
): Promise<any> => {
  try {
    const { partner, bookingId, headers, cmp, query } = data || {};
    const params = {
      bookingId,
      ...query,
    };

    const urlParams = setURLQueryParams(params);

    const response = await API_HTTP.get(
      `${API_ROUTE_PREFIX}/${partner}/${API_ROUTES.ORDER_DETAIL}${
        urlParams ? `?${urlParams}` : ""
      }`,
      {
        headers: { ...headers, cmp },
      }
    );

    return response;
  } catch (error) {
    console.error("Error", { error });
    throw error;
  }
};

export const sendPolicyEmail = async (data: any): Promise<any> => {
  try {
    const { bookingId, partner } = data;
    const params = { bookingID: bookingId };
    const urlParams = setURLQueryParams(params);
    const response = await API_HTTPS.post(
      `${API_ROUTE_PREFIX}/${partner}/${API_ROUTES.TRIGGER_EMAIL}${
        urlParams ? `?${urlParams}` : ""
      }`,
      {
        headers: { cmp: sessionStorage.getItem("cmp") },
      }
    );
    return response?.data;
  } catch (error: any) {
    console.log("Error", { error: error.message });
    throw error;
  }
};

export const editInsuranceDetails = async (
  reqData: IEditTravellersRequestParams
): Promise<any> => {
  try {
    const {
      bookingID,
      partner = getPartnerOnClientSide(),
      data = [],
    } = reqData;

    if (!bookingID)
      throw "editInsuranceDetails: Booking id not present or invalid";

    const urlParams = setURLQueryParams({ bookingID });

    const response = await API_HTTPS.put(
      `${API_ROUTE_PREFIX_HTTPS}/${partner}/${
        API_ROUTES.EDIT_INSURANCE_DETAILS
      }${urlParams ? `?${urlParams}` : ""}`,
      data,
      {
        headers: { cmp: sessionStorage.getItem("cmp") || undefined },
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error", { error });
    throw error;
  }
};
