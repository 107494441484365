import Head from "next/head";

const CurrencyDocument = ({ currencyData }: any) => {
  const currencyCode: string = currencyData?.curr_code?.toLowerCase() || "";
  const denomination: string = currencyData?.heroCurrencyDenominationVal
    ? `/${currencyData.heroCurrencyDenominationVal}-${currencyCode}`
    : "";
  return (
    <Head>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={currencyData?.meta?.desc} />
      <meta name="keywords" content={currencyData?.meta?.keywords} />
      <title>{currencyData?.meta?.title}</title>
      <link
        rel="preload"
        href="https://fonts.googleapis.com/css?family=Lato:wght@400;500;600;700;900&display=swap"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="canonical"
        href={`https://www.makemytrip.com/tripmoney/currency-converter/${currencyCode}-to-inr${denomination}`}
      />
    </Head>
  );
};

export default CurrencyDocument;
