import { theme } from "@tm/utility/global";
import React, { useState } from "react";
import {
  FlexComp,
  RadioButton,
  ShimmerVersion2,
  ShimmerVersion3,
} from "@tm/static";

import {
  ShowInsPlanStyle,
  ShowInsPlanCntnt,
  ShowInsSelect,
} from "./ShowInsPlan.style";
import { TextNodeType } from "../FormattedText/FormattedText";

interface ShowInsPlanProp {
  data?: {
    title?: TextNodeType | string;
    planInfo?: TextNodeType | string;
    radioBtnLabel?: TextNodeType | string;
    planIcon?: string;
  }[];
  margin?: string;
}

const ShowInsPlanShimmer: React.FC<ShowInsPlanProp> = ({ data, margin }) => {
  return (
    <FlexComp gap="12px" margin={margin}>
      {data &&
        data.map((item, index) => {
          return (
            <ShowInsPlanStyle key={index}>
              <ShowInsPlanCntnt>
                <ShimmerVersion2 margin="0 0 8px" width="100%" height="32px" />
                <ShimmerVersion2 width="100%" height="48px" />
              </ShowInsPlanCntnt>
              {item.radioBtnLabel && (
                <ShowInsSelect>
                  <RadioButton>
                    <ShimmerVersion3 width="58px" height="26px" />
                  </RadioButton>
                </ShowInsSelect>
              )}
            </ShowInsPlanStyle>
          );
        })}
    </FlexComp>
  );
};

export default ShowInsPlanShimmer;
