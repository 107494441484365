import { ToggleBtnWrapper, ToggleButton } from "./ToggleBtn.style";

interface ToggleBtnProps {
  locked?: boolean;
  onToggleClick?: () => void;
}

const ToggleBtn = ({ locked, onToggleClick }: ToggleBtnProps) => {
  return (
    <ToggleBtnWrapper locked={locked} onClick={onToggleClick}>
      <ToggleButton locked={locked}></ToggleButton>
    </ToggleBtnWrapper>
  );
};

export default ToggleBtn;
