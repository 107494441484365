import { getPartnerOnClientSide } from "@tm/insurance/utils";
import { PARTNER } from "@tm/insurance/constants";
import { MMTInsuranceHeader } from "../static/components/MMTInsuranceHeader";
import { GIInsuranceHeader } from "../static/components/GIInsuranceHeader";
import { useEffect, useState } from "react";

export function withPartnerInsuranceHeader(component: JSX.Element) {
  let headerComponent = null;
  const [partnerHeader, setPartnerHeader] = useState("");

  useEffect(() => {
    const partner = getPartnerOnClientSide();
    setPartnerHeader(partner);
  }, []);

  if (partnerHeader === PARTNER.MMT) {
    headerComponent = <MMTInsuranceHeader />;
  } else if (partnerHeader === PARTNER.GI) {
    headerComponent = <GIInsuranceHeader />;
  }

  if (headerComponent) {
    return (
      <>
        {headerComponent}
        {component}
      </>
    );
  }

  return <>{component}</>;
}
