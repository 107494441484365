import { LocationPermissionProps } from "./LocationPermissionProps";
import { Container } from "./LocationPermission.styles";

import { ParaTag, ToggleBtn, FlexComp } from "@tm/static";
import { theme } from "@tm/utility/global";

const LocationPermission = ({
  icon,
  title,
  subTitle,
  switchButton,
  onToggleClick,
  locked,
}: LocationPermissionProps) => {
  return (
    <Container>
      {icon && (
        <FlexComp flexShrink="0" margin="0 8px 0 0">
          {icon}
        </FlexComp>
      )}
      <FlexComp flexDirection="column" flex="1">
        {title && (
          <ParaTag
            margin="0 0 8px 0"
            fontSize="md"
            fontWeight="bold"
            color={theme.whiteText}
          >
            {title}
          </ParaTag>
        )}
        {subTitle && (
          <ParaTag fontSize="xs" color="rgba(255,255,255,0.6)">
            {subTitle}
          </ParaTag>
        )}
      </FlexComp>
      {switchButton && (
        <div>
          <ToggleBtn locked={locked} onToggleClick={onToggleClick} />
        </div>
      )}
    </Container>
  );
};

export default LocationPermission;
