import React, { useState } from "react";
import { LandingButton, CommonModalDweb, SectionDividers } from "@tm/static";

import { BtnWrap } from "./FilterPlans.style";
import PlanTypeComp from "./PlanTypeComp";
import { FilterPlansProp } from "./type";

const FilterPlans = ({
  title,
  btnText,
  filterData,
  tagData,
  filterPlanHandleClick,
}: FilterPlansProp) => {
  const [tabIndex, setTabIndex] = useState(tagData);

  const handleTabIndex = (id: number) => {
    setTabIndex(
      tagData?.map((item) => {
        if (id === item.id) {
          item.isSelected = true;
        } else item.isSelected = false;

        return item;
      })
    );
  };

  return (
    <CommonModalDweb customTitle={title} handleClick={filterPlanHandleClick}>
      <PlanTypeComp data={filterData?.popularData} />
      <SectionDividers small />
      <PlanTypeComp data={filterData?.planType} title="Plan Type" />
      <SectionDividers small />
      <PlanTypeComp
        tagData={tagData}
        title="Sum Insured Per Traveller"
        handleClickTab={handleTabIndex}
      />
      <SectionDividers small />
      <PlanTypeComp data={filterData?.benefits} title="Benefits Included" />
      <BtnWrap>
        <LandingButton customTitle={btnText} />
      </BtnWrap>
    </CommonModalDweb>
  );
};

export default FilterPlans;
