import React, { useEffect, useRef, useState } from "react";
import { deviceType } from "@tm/static/utils";

import {
  PersuasionCrawlerStyle,
  PersuasionItem,
  Label,
  SubLabel,
} from "./PersuasionCrawler.style";

import { PersuasionCrawlerProps } from "./types";

const PersuasionCrawler: React.FC<PersuasionCrawlerProps> = ({ data }) => {
  const scrollRef = useRef();
  const interval = useRef();
  const timeout = useRef();

  useEffect(() => {
    // prettier-ignore
    //@ts-ignore
    timeout.current = setTimeout(() => {
            //@ts-ignore
            interval.current = setInterval(() => {
                //@ts-ignore
                scrollRef.current?.scrollBy({
                    left: window.innerWidth,
                    behavior: "smooth",
                  });
                  //@ts-ignore
                if(scrollRef.current?.scrollLeft >= (scrollRef.current?.scrollWidth - scrollRef.current?.clientWidth - 20) ){
                    //@ts-ignore
                    scrollRef.current?.scrollTo({
                        left: 0,
                        behavior: "smooth",
                      });
                }
    
            },3000);
        },5000)

    return () => {
      clearInterval(interval.current);
      clearTimeout(timeout.current);
    };
  }, []);

  return (
    <PersuasionCrawlerStyle ref={scrollRef}>
      {data.map((item) => (
        <PersuasionItem key={item.id}>
          <Label>{item.label}</Label>
          {item.subLabel && <SubLabel>{item.subLabel}</SubLabel>}
        </PersuasionItem>
      ))}
    </PersuasionCrawlerStyle>
  );
};

export default PersuasionCrawler;
