import { createContext } from "react";

export interface envContext {
  proxyCmsPrefix: string;
  exchangeRateApi: string;
}

export const PublicEnv = createContext<envContext>({
  proxyCmsPrefix: "",
  exchangeRateApi: "",
});

export const PublicEnvProvider = PublicEnv.Provider;
