import React, { useRef, useEffect } from "react";

import { ShimmerVersion2Style } from "./Shimmer.style";
import { ShimmerType } from "./ShimmerType";

export default function ShimmerVersion2({
  width,
  height,
  margin,
  children,
  padding,
}: ShimmerType) {
  return (
    <ShimmerVersion2Style
      padding={padding}
      width={width}
      height={height}
      margin={margin}
    >
      {children}
    </ShimmerVersion2Style>
  );
}
