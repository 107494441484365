import { useState } from "react";
import { CurrencySEOMarkdown } from "../CurrencySEOMarkdown";
import { FaqsStyle } from "./Faqs.styles";

export default function Faqs({ currencyData }: any) {
  const [open, setOpen] = useState(-1);

  const handleFaq = (index: number) => {
    if (open === index) {
      setOpen(-1);
    } else {
      setOpen(index);
    }
  };

  return (
    <>
      <FaqsStyle />
      <div className="separator"></div>
      <h2 className="h2">{currencyData?.faqs?.title}</h2>
      <ul className="faqList">
        {currencyData?.faqs?.list?.map((faq: any, index: number) => (
          <li
            key={index}
            onClick={() => handleFaq(index)}
            className={`${open === index ? "toggleAnswer" : ""}`}
          >
            <h4 className="question">{faq?.ques}</h4>
            <div className="answer">
              <CurrencySEOMarkdown>{faq?.ans}</CurrencySEOMarkdown>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
