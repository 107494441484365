import React from "react";
import { ImageTag, FlexComp, Shimmer } from "@tm/static";
import { GreatChoiceStyle, ChoiceText } from "./GreatChoice.style";
import GreenThumb from "../../icons/GreenThumb.svg";
import { theme } from "@tm/utility/global";

interface GreatChoiceProp {
  title?: string;
  data?: string[];
  info?: string;
}

const GreatChoiceShimmer = ({ title, info }: GreatChoiceProp) => {
  return (
    <GreatChoiceStyle>
      <Shimmer width="283px" height="32px" margin="0 0 8px" />
      <Shimmer width="100%" height="22px" />
    </GreatChoiceStyle>
  );
};

export default GreatChoiceShimmer;
