import { CurrencySEOMarkdown } from "../CurrencySEOMarkdown";

export default function OtherServices({ currencyData }: any) {
  return (
    <>
      <div className="separator"></div>
      <h2 className="h2">{currencyData?.other_forex_services?.title}</h2>
      <p className="description mt10">
        {currencyData?.other_forex_services?.sub_title}
      </p>
      <ul className="forexServices">
        {currencyData?.other_forex_services?.cards?.map(
          (item: any, index: number) => (
            <li className="forexServices__item" key={index}>
              <div className="forexServices__itemHdr">
                <span className="forexServices__itemImg">
                  <img
                    src={item?.img}
                    alt={item?.title}
                    width="112"
                    height="70"
                  />
                </span>
                <h3 className="forexServices__itemTitle">{item?.title}</h3>
              </div>
              <CurrencySEOMarkdown className="description">
                {item?.desc}
              </CurrencySEOMarkdown>
            </li>
          )
        )}
      </ul>
    </>
  );
}
