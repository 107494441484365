import { ShimmerComp } from "./Shimmer.style";
import { ShimmerType } from "./ShimmerType";

export default function Shimmer({
  width,
  height,
  borderRadius,
  margin,
  padding,
  flex,
  background,
}: ShimmerType) {
  return (
    <ShimmerComp
      flex={flex}
      width={width}
      margin={margin}
      height={height}
      padding={padding}
      background={background}
      borderRadius={borderRadius}
    />
  );
}
