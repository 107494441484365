import {
  GIHeaderProps,
  GIInsuranceHeader,
} from "../static/components/GIInsuranceHeader";

interface GIInsuranceHeaderPropsWithWrapper extends GIHeaderProps {
  Wrapper?: (props: { children: JSX.Element[] }) => JSX.Element;
  forceLogin?: boolean;
}

export function withGIInsuranceHeader(
  component: JSX.Element,
  headerOptions: GIInsuranceHeaderPropsWithWrapper = {},
  mobileTitle = "",
  myOrders = false,
  goToMyOrders = () => void 0,
  displayWhiteHeader = false
) {
  const { Wrapper, forceLogin = false, ...headerProps } = headerOptions;
  const giHeaderWithAuth = (
    <GIInsuranceHeader
      headerProps={headerProps}
      mobileTitle={mobileTitle}
      forceLogin={forceLogin}
      myOrders={myOrders}
      goToMyOrders={goToMyOrders}
      displayWhiteHeader={displayWhiteHeader}
    />
  );

  if (forceLogin) {
    return giHeaderWithAuth;
  }
  if (Wrapper) {
    return (
      <Wrapper>
        {giHeaderWithAuth}
        {component}
      </Wrapper>
    );
  }
  return (
    <>
      {giHeaderWithAuth}
      {component}
    </>
  );
}
