import { useState, useEffect, useContext } from "react";
import { t } from "@tm/utility";
import {
  TripMoneyStyles,
  BaseStyles,
  FontStyles,
  MMTLogo,
} from "../CurrencySEOCommonUI";
import Logo from "../TMLogo";
import BmfLogo from "../BMFLogo";
import { CurrencySEOMarkdown } from "../CurrencySEOMarkdown";
import { CurrencyFooter } from "./CurrencyFooter";
import CurrencyDocumet from "./CurrencyDocumet";
import { Hero } from "./Hero";
import CurrencyConverter from "../CurrencyRootPageTemplate/CurrencyConverter";
import { HeroV2 } from "./HeroV2";
import Faqs from "../CurrencyConverterPageTemplate/Faqs";
import ForexProduct from "../CurrencyConverterPageTemplate/ForexProducts";
import { PAGE_NAMES, trackSeoPageLoad } from "@tm/utility/analytics";
import { CurrencySEOContext } from "../CurrencySEOContext";

export const CurrencyPageTemplate = ({ currency, isMmtHost, id }: any) => {
  const [scrolled, setScrolled] = useState(false);
  const [showViewMore, setShowViewMore] = useState<boolean>(true);
  const [cardsToBeShown, setCardsToBeShown] = useState<number>(4);
  const hasHeroImage = currency?.forex_solution?.forex_sol?.src;

  const { refreshBtn, currencyCode, exchangeRateApi, inrRate, setInrRate } =
    useContext(CurrencySEOContext);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    trackSeoPageLoad({
      pageName: PAGE_NAMES["SEO_CURRENCY_NAME"]?.replace(
        "NAME",
        currency?.name
      ),
      currency: currency?.name,
    });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const {
    btn_link,
    btn_text,
    img_link,
    desc,
    title,
    list,
    forex_sol,
    forex_sol_mobile,
  }: React.ComponentProps<typeof Hero> = currency?.forex_solution || {};

  const heroProps: React.ComponentProps<typeof Hero> = {
    btn_link,
    btn_text,
    img_link,
    desc,
    title,
    list,
    forex_sol,
    forex_sol_mobile,
    green_check: currency?.common_images?.green_check,
  };

  const handleOnClick = () => {
    setShowViewMore(false);
    setCardsToBeShown(14);
  };

  const renderIfExchangeRateIsNotZero = (fact: string) => {
    if (fact === "INR_Exchange_Rate" && parseFloat(`${inrRate}`) <= 0) {
      return false;
    }
    return true;
  };

  return (
    <>
      <FontStyles />
      <BaseStyles />
      <TripMoneyStyles />

      <CurrencyDocumet
        currency={currency}
        hasHeroImage={hasHeroImage}
        id={id}
      />
      <>
        <header>
          <div className="header__inner">
            <a href={currency?.header?.image_link}>
              {isMmtHost ? (
                <MMTLogo isLight={scrolled ? false : true} />
              ) : (
                <BmfLogo isLight={scrolled ? false : true} />
              )}
            </a>
          </div>
        </header>
        <div className="topBlueSection" />
        <div className="greySection">
          <div className="container negativeMargin">
            <HeroV2 {...heroProps} breadcrumb={currency?.breadcrumb} />
            <div className="pageContainer pageContainer_margin sectionGapping">
              <ForexProduct
                currencyData={currency}
                pageName={PAGE_NAMES["SEO_CURRENCY_NAME"]?.replace(
                  "NAME",
                  currency?.name
                )}
              />
            </div>
            <section className="tripMoneySection makeFlex hrtlCenter">
              <img
                width={104}
                height={48}
                className="currencyLogoImg"
                src={currency?.flag?.[currency?.country_code]?.src}
                alt="country flag"
                loading="eager"
              />
              <h1>{currency?.name}</h1>
            </section>
            {currency?.about?.desc ||
            Object(currency?.quick_facts?.facts)?.length !== 0 ? (
              <section className="tripMoneySection">
                <h2 className="appendBottom30">
                  {currency?.about?.title}
                  {currency?.about?.title_text}
                </h2>

                {currency?.quick_facts?.facts &&
                Object(currency?.quick_facts?.facts)?.length !== 0 ? (
                  <div className="container__card container__card--yellow">
                    <div className="currencyFacts__heaading">
                      <img
                        className="quickfactsImg"
                        {...currency?.quick_facts?.quick_facts_logo}
                        loading="eager"
                      />
                      <h2>{currency?.quick_facts?.title}</h2>
                    </div>
                    <table className="currencyFacts__list">
                      <tbody>
                        {currency?.quick_facts?.list?.map((fact: any) =>
                          currency?.quick_facts?.facts?.[fact] &&
                          renderIfExchangeRateIsNotZero(fact) ? (
                            <tr key={fact}>
                              <td>
                                <span className="latoBold">
                                  {t(fact, { _: " " })}
                                </span>
                              </td>
                              <td>
                                <span>
                                  <CurrencySEOMarkdown>
                                    {currency?.quick_facts?.facts?.[fact]}
                                  </CurrencySEOMarkdown>
                                </span>
                              </td>
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : null}
                <CurrencySEOMarkdown>
                  {currency?.about?.desc}
                </CurrencySEOMarkdown>
              </section>
            ) : null}
            {currency?.history?.desc ? (
              <section className="tripMoneySection">
                <h2 className="appendBottom20">{currency?.history?.title}</h2>
                <CurrencySEOMarkdown>
                  {currency?.history?.desc}
                </CurrencySEOMarkdown>
              </section>
            ) : null}
            {currency?.countries_accepting?.desc_1 ||
            currency?.countries_accepting?.desc_2 ||
            (currency?.countries_accepting?.list &&
              currency?.countries_accepting?.list?.length !== 0) ? (
              <section className="tripMoneySection">
                <h2 className="appendBottom20">
                  {currency?.countries_accepting?.title}
                </h2>
                <CurrencySEOMarkdown className="appendBottom20">
                  {currency?.countries_accepting?.desc_1}
                </CurrencySEOMarkdown>
                {currency?.countries_accepting?.list &&
                currency?.countries_accepting?.list?.length !== 0 &&
                currency?.flag &&
                Object(currency?.flag)?.length !== 0 ? (
                  <div>
                    <h3 className="appendBottom20">
                      {currency?.countries_accepting?.list_title}
                    </h3>
                    <ul className="countryList">
                      {currency?.countries_accepting?.list?.map(
                        (country_code: any) =>
                          currency?.flag?.[country_code] ? (
                            <li key={country_code}>
                              <img
                                className="countryImg"
                                {...currency?.flag?.common}
                                src={currency?.flag?.[country_code]?.src}
                                alt={t(currency?.flag?.default?.alt, {
                                  "{country_name}": currency?.flag?.[
                                    country_code
                                  ]?.name
                                    ? currency?.flag?.[country_code]?.name
                                    : "country",
                                })}
                                loading="lazy"
                              />
                              {currency?.flag?.[country_code]?.url === "/#" ||
                              currency?.flag?.[country_code]?.url === "/" ? (
                                <span className="font16 latosemiBold">
                                  {currency?.flag?.[country_code]?.name}
                                </span>
                              ) : (
                                <a
                                  className="font16 latosemiBold"
                                  href={currency?.flag?.[country_code]?.url}
                                >
                                  {currency?.flag?.[country_code]?.name}
                                </a>
                              )}
                            </li>
                          ) : null
                      )}
                    </ul>
                  </div>
                ) : null}
                <CurrencySEOMarkdown>
                  {currency?.countries_accepting?.desc_2}
                </CurrencySEOMarkdown>
              </section>
            ) : null}
            {currency?.amount_for_trip?.desc ? (
              <section className="tripMoneySection">
                <h2 className="appendBottom20">
                  {currency?.amount_for_trip?.title}
                </h2>
                <CurrencySEOMarkdown>
                  {currency?.amount_for_trip?.desc}
                </CurrencySEOMarkdown>
              </section>
            ) : null}
          </div>
        </div>
        {currency?.identification?.list &&
        currency?.identification?.list?.length !== 0 ? (
          <div className="whiteSection">
            <div className="container">
              <section className="tripMoneySection makeFlex">
                <div>
                  <h2 className="appendBottom30">
                    {currency?.identification?.title}
                  </h2>
                  <div className="counterFietImgWrapMweb">
                    <img
                      {...currency?.identification?.counterfiet_mobile_logo}
                      loading="lazy"
                    />
                  </div>
                  <CurrencySEOMarkdown className="appendBottom20">
                    {currency?.identification?.list_title}
                  </CurrencySEOMarkdown>
                  <ul>
                    {currency?.identification?.list?.map(
                      (item: any, index: any) => (
                        <li
                          key={item}
                          className={`makeFlex ${
                            index < currency.identification.list.length - 1
                              ? "dashedBorder"
                              : ""
                          }`}
                        >
                          <img
                            className="greenCheckImg"
                            {...currency?.common_images?.green_check}
                            loading="lazy"
                          />
                          <span>
                            <CurrencySEOMarkdown>{item}</CurrencySEOMarkdown>
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div className="counterFietImgWrap">
                  <img
                    {...currency?.identification?.counterfiet_logo}
                    loading="lazy"
                  />
                </div>
              </section>
            </div>
          </div>
        ) : null}
        <div className="greySection">
          <div className="container">
            <section className="tripMoneySection">
              <h2 className="appendBottom20">
                {currency?.process_of_exchanging?.title}
              </h2>
              <CurrencySEOMarkdown className="appendBottom35">
                {currency?.process_of_exchanging?.desc}
              </CurrencySEOMarkdown>
              <div className="container__card">
                {currency?.process_of_exchanging?.list?.map(
                  (item: any, index: any) => (
                    <div key={index}>
                      <h3 className="appendBottom18">{item?.title}</h3>
                      <CurrencySEOMarkdown className="appendBottom10">
                        {item?.desc}
                      </CurrencySEOMarkdown>
                      {item?.list?.length !== 0 ? (
                        <ul>
                          {item?.list?.map((ele: any) => (
                            <li key={ele} className="makeFlex appendBottom15">
                              <img
                                className="greenCheckImg"
                                {...currency?.common_images?.green_check}
                                loading="lazy"
                              />
                              <span>
                                <CurrencySEOMarkdown>{ele}</CurrencySEOMarkdown>
                              </span>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                      {index <
                      currency?.process_of_exchanging?.list?.length - 1 ? (
                        <div className="dashedBorder" />
                      ) : null}
                    </div>
                  )
                )}
              </div>
            </section>
            <section className="tripMoneySection">
              <h2 className="appendBottom20">{currency?.exchange?.title}</h2>
              <CurrencySEOMarkdown className="appendBottom60">
                {currency?.exchange?.desc}
              </CurrencySEOMarkdown>
              <div className="container__card appendBottom80">
                <img
                  className="containerImg"
                  {...currency?.primary_value_proposition?.img}
                  loading="lazy"
                />
                <h3 className="appendBottom30">
                  {currency?.primary_value_proposition?.title}
                </h3>
                <ul className="productRangeList">
                  {currency?.primary_value_proposition?.list?.map(
                    (product: any) => (
                      <li key={product}>
                        <CurrencySEOMarkdown>{product}</CurrencySEOMarkdown>
                      </li>
                    )
                  )}
                </ul>
              </div>
              <div className="exchangeRateWrap">
                {currency?.secondary_value_proposition?.map((value: any) => (
                  <div className="container__card" key={value?.title}>
                    <img
                      className="containerImg"
                      {...value?.img}
                      loading="lazy"
                    />
                    <h3 className="appendBottom8">{value?.title}</h3>
                    <CurrencySEOMarkdown>{value?.desc}</CurrencySEOMarkdown>
                  </div>
                ))}
              </div>
            </section>
            <section className="tripMoneySection">
              <h2 className="appendBottom20">
                {currency?.best_rates_of_exchange?.title}
              </h2>
              <CurrencySEOMarkdown>
                {currency?.best_rates_of_exchange?.desc}
              </CurrencySEOMarkdown>
            </section>
            <section className="tripMoneySection">
              <h2 className="appendBottom20">
                {currency?.best_ways_to_carry?.title}
              </h2>
              <CurrencySEOMarkdown className="appendBottom24">
                {currency?.best_ways_to_carry?.desc}
              </CurrencySEOMarkdown>
              <div className="container__card container__cardAlign">
                <div className="tripmoneyImgWrap">
                  <img
                    className="tripMoneyImg"
                    {...currency?.best_ways_to_carry?.tripmoney_logo}
                    loading="lazy"
                  />
                </div>
                <div>
                  {currency?.best_ways_to_carry?.list?.map(
                    (way: any, index: any) => (
                      <div key={way?.title}>
                        <h4 className="blackText appendBottom10">
                          {way?.title}
                        </h4>
                        <CurrencySEOMarkdown>{way?.desc}</CurrencySEOMarkdown>
                        {index <
                        currency?.best_ways_to_carry?.list?.length - 1 ? (
                          <div className="dashedBorder" />
                        ) : null}
                      </div>
                    )
                  )}
                </div>
              </div>
            </section>
            <section className="tripMoneySection">
              <h2 className="appendBottom20">{currency?.how_to_buy?.title}</h2>
              <CurrencySEOMarkdown className="appendBottom24">
                {currency?.how_to_buy?.desc}
              </CurrencySEOMarkdown>
              <ul className="tripMoneySection__bestRateBuy">
                {currency?.how_to_buy?.list?.map((item: any, index: any) => (
                  <li key={index}>
                    <span className="stepCount">
                      <span className="font16">
                        {currency?.how_to_buy?.common}
                      </span>{" "}
                      {index + 1 < 9 ? "0" + (index + 1) : index + 1}
                    </span>
                    <CurrencySEOMarkdown>{item}</CurrencySEOMarkdown>
                  </li>
                ))}
              </ul>
              <div className="container__card">
                <h3 className="appendBottom10">
                  {currency?.documents_required?.title}
                </h3>
                <CurrencySEOMarkdown className="appendBottom20">
                  {currency?.documents_required?.desc}
                </CurrencySEOMarkdown>
                <ul className="documentList">
                  {currency?.documents_required?.list?.map((doc: any) => (
                    <li key={doc}>
                      <img
                        className="greenCheckImg"
                        {...currency?.common_images?.green_check}
                        loading="lazy"
                      />
                      <CurrencySEOMarkdown>{doc}</CurrencySEOMarkdown>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
            <section className="tripMoneySection">
              <div className="exchangeRateWrap appendBottom35">
                <div className="container__card flexOne">
                  <h2 className="appendBottom22">
                    {currency?.purposes_for_exchanging?.title}
                  </h2>

                  <ul className="productRangeList">
                    {currency?.purposes_for_exchanging?.list?.map(
                      (purpose: any, index: any) => (
                        <li
                          key={purpose}
                          className={
                            index <
                            currency.purposes_for_exchanging.list.length - 1
                              ? "dashedBorder"
                              : ""
                          }
                        >
                          <CurrencySEOMarkdown>{purpose}</CurrencySEOMarkdown>
                        </li>
                      )
                    )}
                  </ul>
                </div>

                <div className="flexOne makeFlex column">
                  <div className="container__card appendBottom30">
                    <h2 className="appendBottom22">
                      {currency?.buy_sell_online?.title}
                    </h2>
                    <CurrencySEOMarkdown>
                      {currency?.buy_sell_online?.desc}
                    </CurrencySEOMarkdown>
                  </div>
                  <div className="container__card">
                    <h2 className="appendBottom22">
                      {currency?.home_delivery?.title}
                    </h2>
                    <CurrencySEOMarkdown>
                      {currency?.home_delivery?.desc}
                    </CurrencySEOMarkdown>
                  </div>
                </div>
              </div>
            </section>

            <CurrencyConverter
              currConversion={currency?.currency_conversions}
            />
            <Faqs currencyData={currency} />
          </div>
        </div>
        {currency?.footer ? <CurrencyFooter footer={currency.footer} /> : null}
      </>
    </>
  );
};
