export const serverRuntimeConfig = () => {
  let adobeDtmScriptURL = process.env["SERVER_ADOBE_DTM_SCRIPT_URL"];
  let cmsBaseUrl = process.env["SERVER_CMS_BASE_URL"];
  if (process.env["ENV"] != "prod") {
    adobeDtmScriptURL =
      "https://assets.adobedtm.com/79483c2c7c00/1f00845047ae/launch-f02ff00ace82-development.min.js";
    cmsBaseUrl = "http://len-tm-cms-service.ecs.mmt";
    if (process.env["DOCKER_ADOBE_DTM_SCRIPT_URL"]) {
      adobeDtmScriptURL = process.env["DOCKER_ADOBE_DTM_SCRIPT_URL"];
    }
    if (process.env["DOCKER_CMS_BASE_URL"]) {
      cmsBaseUrl = process.env["DOCKER_CMS_BASE_URL"];
    }
  }

  return { cmsBaseUrl, adobeDtmScriptURL };
};
