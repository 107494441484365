import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

export const LocationFetchingStyles = styled.div<any>`
  background-color: black;
  background-image: ${(props) => props.bgImg && `url(${props.bgImg});`};
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LocationLoading = styled.div`
  margin-top: 70px;
  position: relative;
  width: 208px;
  height: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} linear 0.2s;
  opacity: 0;
  animation-fill-mode: forwards;
`;

export const AbsIconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
`;

export const ConditionsText = styled.div`
  margin: auto 0px 50px;
  width: 285px;
  text-align: center;
`;
