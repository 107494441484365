const PreviewEyeSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_625_7721)">
        <path
          d="M10.0667 5C6.4 5 3.26867 7.28067 2 10.5C3.26867 13.7193 6.4 16 10.0667 16C13.7333 16 16.8647 13.7193 18.1333 10.5C16.8647 7.28067 13.7333 5 10.0667 5ZM10.0667 14.1667C8.04267 14.1667 6.4 12.524 6.4 10.5C6.4 8.476 8.04267 6.83333 10.0667 6.83333C12.0907 6.83333 13.7333 8.476 13.7333 10.5C13.7333 12.524 12.0907 14.1667 10.0667 14.1667ZM10.0667 8.3C8.84933 8.3 7.86667 9.28267 7.86667 10.5C7.86667 11.7173 8.84933 12.7 10.0667 12.7C11.284 12.7 12.2667 11.7173 12.2667 10.5C12.2667 9.28267 11.284 8.3 10.0667 8.3Z"
          fill="#757575"
        />
      </g>
      <defs>
        <clipPath id="clip0_625_7721">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PreviewEyeSvg;
