import styled from "styled-components";

type PopularDescriptionProps = {
  customWidth?: string;
  nonClipHeading?: boolean;
};

export const PopularDescriptionWrap = styled.div<PopularDescriptionProps>`
  border-radius: 4px;
  padding: 12px;
  background-color: #f6f6f6;
`;
