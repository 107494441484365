import React from "react";
import { SpanTag, ImageComponent, FormattedText } from "@tm/static";
import { theme } from "@tm/utility/global";

import {
  BenefitRow,
  BenefitCol1,
  BenefitCol2,
  BenefitCol3,
} from "./ComparePlan.style";
import { TextNodeType } from "../FormattedText/FormattedText";
import { useBreakpoint } from "../../../hooks/useBreakpoint";

interface IPlanBenefit {
  benefitData?: {
    type?: string;
    label?: {
      text?: TextNodeType | string;
      icon?: string;
    };
    data?: Record<string, TextNodeType>;
  };
  quoteID1: string;
  quoteID2: string;
}

const PlanBenefit = ({ benefitData, quoteID1, quoteID2 }: IPlanBenefit) => {
  const device = useBreakpoint();

  return (
    <BenefitRow>
      <BenefitCol1>
        {benefitData?.label?.icon && (
          <ImageComponent
            src={benefitData?.label?.icon}
            height="20"
            width="20"
          />
        )}
        <SpanTag fontSize="xs">
          {benefitData?.label?.text && (
            <FormattedText value={benefitData?.label?.text} />
          )}
        </SpanTag>
      </BenefitCol1>
      {}
      <BenefitCol2>
        <SpanTag
          fontSize="xs"
          fontWeight={device == "lg" ? "black" : "bold"}
          color={
            benefitData?.data?.[quoteID1]?.text
              ? theme.blackText
              : theme.redText2
          }
        >
          <FormattedText value={benefitData?.data?.[quoteID1] || "NA"} />
        </SpanTag>
      </BenefitCol2>
      <BenefitCol3>
        <SpanTag
          fontSize="xs"
          fontWeight={device == "lg" ? "black" : "bold"}
          color={
            benefitData?.data?.[quoteID2] ? theme.blackText : theme.redText2
          }
        >
          <FormattedText value={benefitData?.data?.[quoteID2] || "NA"} />
        </SpanTag>
      </BenefitCol3>
    </BenefitRow>
  );
};

export default PlanBenefit;
