import React from "react";

import { LoginItem, AgreeTnCTxt, NotYourAccount } from "./LoginComp.style";
import {
  AnchorTag,
  CommonModalDweb,
  FlexComp,
  ImageTag,
  ParaTag,
  SpanTag,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { useBreakpoint } from "@tm/ui/hooks";
import OneWayArrBlue from "../../icons/oneWayArrBlue.svg";
import OneWayArrGrey from "../../icons/oneWayArrGrey.svg";
import CrossIcon from "../../icons/crossIconGreyBg.svg";
interface LoginProp {
  loginWidgetData?: {
    title?: string;
    info?: string;
    loginOptions?: {
      logoImg?: string;
      accountDetails?: string;
      loginWith?: string;
    }[];
    notYourAccount?: string;
    loginCta?: string;
    tnCTxt?: {
      txt1?: string;
      txt2?: string;
      txt3?: string;
      termsCta?: string;
      privacyPolicyCta?: string;
    };
  };
  handleTnC?: () => {};
  handlePrivacyPolicy?: () => {};
}

const LoginComp = ({
  loginWidgetData,
  handleTnC,
  handlePrivacyPolicy,
}: LoginProp) => {
  const device = useBreakpoint();
  return (
    <CommonModalDweb
      crossIcon={CrossIcon.src}
      customTitle={loginWidgetData?.title}
    >
      <ParaTag color={theme.greyText}>{loginWidgetData?.info}</ParaTag>
      {loginWidgetData?.loginOptions?.map((item, index) => {
        return (
          <LoginItem key={index}>
            {item.logoImg && (
              <ImageTag
                width={device === "lg" ? "32px" : "24px"}
                height={device === "lg" ? "32px" : "24px"}
                src={item.logoImg}
              />
            )}
            <FlexComp flexDirection="column" gap="4px">
              <ParaTag fontSize={device === "lg" ? "md" : "sm"}>
                {item.loginWith}
              </ParaTag>
              <ParaTag fontSize="xs" color={theme.greyText}>
                {item.accountDetails}
              </ParaTag>
            </FlexComp>
            <AnchorTag margin="0 0 0 auto" handleClick={() => {}}>
              <ImageTag src={OneWayArrBlue.src} />
            </AnchorTag>
          </LoginItem>
        );
      })}
      <NotYourAccount>
        <div>
          <ParaTag fontSize="sm" color={theme.greyText}>
            {loginWidgetData?.notYourAccount}
          </ParaTag>
          <AnchorTag fontSize="sm" fontWeight="bold">
            {loginWidgetData?.loginCta}
          </AnchorTag>
        </div>
        <AnchorTag margin="0 0 0 auto" handleClick={() => {}}>
          <ImageTag src={OneWayArrGrey.src} />
        </AnchorTag>
      </NotYourAccount>
      <AgreeTnCTxt>
        <SpanTag fontSize="xs" color={theme.greyText7}>
          {loginWidgetData?.tnCTxt?.txt1} &nbsp;
          <AnchorTag fontSize="xs" handleClick={handleTnC}>
            {loginWidgetData?.tnCTxt?.termsCta}
          </AnchorTag>
          &nbsp;
          {loginWidgetData?.tnCTxt?.txt2} &nbsp;
          <AnchorTag fontSize="xs" handleClick={handlePrivacyPolicy}>
            {loginWidgetData?.tnCTxt?.privacyPolicyCta}
          </AnchorTag>
          &nbsp;
          {loginWidgetData?.tnCTxt?.txt3}
        </SpanTag>
      </AgreeTnCTxt>
    </CommonModalDweb>
  );
};

export default LoginComp;
