import React, { Fragment, ChangeEvent } from "react";

import {
  LandingButton,
  TMFloatingInput,
  ParaTag,
  FlexComp,
  SpanTag,
  CheckButton,
  Spinner,
  RadioButton,
} from "@tm/static";

import {
  SelectCountryStyle,
  SelectCountryHeader,
  FixedBtn,
  CountryList,
  Scroller,
} from "./SelectCountry.style";

import { theme } from "@tm/utility/global";

import {
  selectCountryModalDataProps,
  slctCtryMdlDataCcyListItemProps,
} from "@tm/static/data";

import { BtnProp } from "../../LandingButton/ButtonType";

interface SelectCountryProps {
  countryData?: selectCountryModalDataProps[];
  selectCountryInput?: {
    labelTxt?: string;
    placeholderTxt?: string;
    inputValue: string;
    disableState?: boolean;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  selectCountryLabel?: string;
  isFetchingRate?: boolean;
  setIsFetchingRate?: React.Dispatch<React.SetStateAction<boolean>>;
  currencyLabel?: string;
  btnProps?: BtnProp;
  currencyItemHandler?: (
    item: slctCtryMdlDataCcyListItemProps,
    type: string,
    idx: number
  ) => void;
  showRadioButton?: boolean;
}

const SelectCountry = ({
  countryData,
  selectCountryInput,
  isFetchingRate,
  btnProps,
  currencyItemHandler = () => {},
  showRadioButton = false,
}: SelectCountryProps) => {
  const searchQueryHighlighter = (
    ele: slctCtryMdlDataCcyListItemProps,
    nameToSearch: "leftLabel" | "rightLabel"
  ) => {
    const withSpecialCharsSupport = selectCountryInput?.inputValue.replace(
      /[.*+?^${}()|[\]\\]/g,
      "\\$&"
    );

    const searchRegex = new RegExp(`(${withSpecialCharsSupport})`, "ig");
    const highlighted = ele[nameToSearch].replace(
      searchRegex,
      (match) => `<span class="highlight">${match}</span>`
    );

    return (
      <ParaTag
        dangerousText={highlighted}
        fontWeight={ele.isChecked ? "bold" : "regular"}
        margin={nameToSearch === "leftLabel" ? "0px 8px 0px 0px" : "0px"}
      />
    );
  };

  const spinnerLoader = isFetchingRate ? (
    <Spinner spinnerWidth={20} />
  ) : (
    <Fragment />
  );

  return (
    <SelectCountryStyle>
      <SelectCountryHeader>
        <TMFloatingInput
          // margin="7px 8px 0px"
          labelTxt={selectCountryInput?.labelTxt}
          inputValue={selectCountryInput?.inputValue}
          disableState={selectCountryInput?.disableState}
          handleChange={selectCountryInput?.handleChange}
        />
      </SelectCountryHeader>
      {countryData && (
        <Scroller>
          {countryData.map((item) => {
            return (
              item.currencyList.length > 0 && (
                <Fragment key={item.id}>
                  <FlexComp
                    padding="12px 0px"
                    bgColor="#fff"
                    borderBottom="1px solid #e8e8e8"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SpanTag
                      // color={theme.greyText6}
                      fontSize="xs"
                      fontWeight="bold"
                    >
                      {item.leftLabel}
                    </SpanTag>
                    <SpanTag
                      // color={theme.greyText6}
                      fontSize="xs"
                      fontWeight="bold"
                    >
                      {item.rightLabel}
                    </SpanTag>
                  </FlexComp>
                  <CountryList>
                    {item.currencyList.map((ele, index) => {
                      return (
                        <li
                          key={ele.id}
                          onClick={() =>
                            currencyItemHandler(ele, item.id, index)
                          }
                        >
                          {showRadioButton ? (
                            <RadioButton
                              inputId={`${index}`}
                              activeBtn={ele.isChecked}
                              inputIndex={index}
                              showLabel={false}
                            />
                          ) : (
                            <CheckButton
                              margin="2px 0px 0px"
                              inputId={index}
                              isChecked={ele.isChecked}
                            />
                          )}

                          <FlexComp
                            flex={"1"}
                            isCursorPointer={true}
                            justifyContent="space-between"
                          >
                            {ele.leftSubLabel ? (
                              <div>
                                <FlexComp
                                  alignItems="center"
                                  isCursorPointer={true}
                                >
                                  {searchQueryHighlighter(ele, "leftLabel")}
                                  {ele.isChecked && spinnerLoader}
                                </FlexComp>
                                <ParaTag
                                  fontSize="xs"
                                  color={
                                    ele.leftSubLabel.type === "SUCCESS"
                                      ? theme.greenText4
                                      : theme.redText
                                  }
                                >
                                  {ele.leftSubLabel.value}
                                </ParaTag>
                              </div>
                            ) : (
                              <FlexComp
                                isCursorPointer={true}
                                alignItems="center"
                              >
                                {searchQueryHighlighter(ele, "leftLabel")}
                                {ele.isChecked && spinnerLoader}
                              </FlexComp>
                            )}
                            <SpanTag
                              color={theme.greyText}
                              fontSize="xs"
                              fontWeight={ele.isChecked ? "bold" : "regular"}
                            >
                              {searchQueryHighlighter(ele, "rightLabel")}
                            </SpanTag>
                          </FlexComp>
                        </li>
                      );
                    })}
                  </CountryList>
                </Fragment>
              )
            );
          })}
        </Scroller>
      )}
      {btnProps && btnProps.customTitle && (
        <FixedBtn>
          <FlexComp padding="0px 16px 16px">
            <LandingButton
              customWidth="100%"
              margin="auto 0px 0px"
              {...btnProps}
            />
          </FlexComp>
        </FixedBtn>
      )}
    </SelectCountryStyle>
  );
};

export default SelectCountry;
