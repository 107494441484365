import { forwardRef } from "react";

interface CloseIconProps {
  color?: string;
  onPress?: () => void;
  cursor?: string;
  height?: number;
  width?: number;
}

export const CloseIcon = forwardRef<SVGSVGElement, CloseIconProps>(
  (
    {
      color = "#AFAFAF",
      onPress,
      cursor = "pointer",
      height = 24,
      width = 24,
      ...props
    },
    ref
  ) => {
    return (
      <svg
        ref={ref}
        style={{ cursor: cursor }}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onPress}
        {...props}
      >
        <path
          d="M7.79967 17.8333L11.9997 13.6333L16.1997 17.8333L17.833 16.1999L13.633 11.9999L17.833 7.79992L16.1997 6.16659L11.9997 10.3666L7.79967 6.16659L6.16634 7.79992L10.3663 11.9999L6.16634 16.1999L7.79967 17.8333ZM11.9997 23.6666C10.3858 23.6666 8.86912 23.3601 7.44968 22.7473C6.03023 22.1351 4.79551 21.3041 3.74551 20.2541C2.69551 19.2041 1.86445 17.9694 1.25234 16.5499C0.639452 15.1305 0.333008 13.6138 0.333008 11.9999C0.333008 10.386 0.639452 8.86936 1.25234 7.44992C1.86445 6.03048 2.69551 4.79575 3.74551 3.74575C4.79551 2.69575 6.03023 1.86431 7.44968 1.25142C8.86912 0.639307 10.3858 0.333252 11.9997 0.333252C13.6136 0.333252 15.1302 0.639307 16.5497 1.25142C17.9691 1.86431 19.2038 2.69575 20.2538 3.74575C21.3038 4.79575 22.1349 6.03048 22.747 7.44992C23.3599 8.86936 23.6663 10.386 23.6663 11.9999C23.6663 13.6138 23.3599 15.1305 22.747 16.5499C22.1349 17.9694 21.3038 19.2041 20.2538 20.2541C19.2038 21.3041 17.9691 22.1351 16.5497 22.7473C15.1302 23.3601 13.6136 23.6666 11.9997 23.6666Z"
          fill={color}
        />
      </svg>
    );
  }
);
