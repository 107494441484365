import { CurrencySEOMarkdown } from "../CurrencySEOMarkdown";

export default function BestRate({ currencyData }: any) {
  if (!currencyData?.best_currency_rate_today) {
    return null;
  }
  return (
    <>
      <h2 className="h2">{currencyData?.best_currency_rate_today?.title}</h2>

      <ul className="bestRate mt20">
        {currencyData?.best_currency_rate_today?.paraList?.map(
          (item: any, index: number) => (
            <li key={index}>
              <CurrencySEOMarkdown>{item}</CurrencySEOMarkdown>
            </li>
          )
        )}
      </ul>
    </>
  );
}
