import { landingCardShowcaseDataProps } from "../../../../mockData";
import ParaTag from "../../../ui-atoms/TextStyles/ParaTag";
import { FreeCard } from "../../LandingCardFeature.style";
import { PersuasionHandler } from "../../Persuasion";

export type ProductOffersType = {
  type: string;
  value: {
    details: Array<{
      coupon_cashback: number | string;
    }>;
  };
};

export type FreeCardOfferProps = {
  cashback: number | string;
  text?: string;
};

export const FreeCardOffer = (props: {
  persuasionProps?: FreeCardOfferProps | null;
  cardData?: landingCardShowcaseDataProps;
  handlers?: PersuasionHandler;
}) => {
  const { cashback, text } = props.persuasionProps || {};
  return !!cashback ? (
    <FreeCard>
      <ParaTag
        fontSize="xs"
        fontWeight="bold"
        color="#007E7D"
      >{`₹ ${cashback} Cashback`}</ParaTag>
      {text && (
        <>
          <ParaTag fontSize="xs" fontWeight="bold" color="#000">
            |
          </ParaTag>
          <ParaTag fontSize="xs" fontWeight="bold" color="#007E7D">
            {text}
          </ParaTag>
        </>
      )}
    </FreeCard>
  ) : null;
};
