import styled from "styled-components";

import { theme } from "@tm/utility/global";
import { H3TagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "@tm/static/utils";
import { BulletListItemStyle } from "../BulletListItem/BulletListItem.style";
import { LandingButtonStyle } from "../LandingButton/LandingButton.style";

interface PersuasionBannerStyle {
  bgImage?: string;
  margin?: string | number;
}

export const PersuasionBannerStyle = styled.div<PersuasionBannerStyle>`
  ${(props) => props.margin && `margin: ${props.margin};`}
  padding: 30px 34px;
  border-radius: 25px;
  background-image: ${(props) => `url(${props.bgImage})`};
  background-size: contain;
  /* min-height: 400px; */
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 400px;
  gap: 20px;
  em {
    color: ${theme.greenText};
    font-weight: 700;
    font-style: normal;
  }
  ${H3TagStyle} {
    width: 100%;
  }
  & ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  & li {
    position: relative;
    padding-left: 16px;
  }
  & li:before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0px;
    width: 4px;
    border-radius: 4px;
    height: 16px;
    background-color: ${theme.darkYellowText};
  }
  @media ${deviceType.mobile} {
    padding: 32px 16px;
    background-size: cover;
    border: 1px solid #fff;
    margin-bottom: 16px;
  }
  ${BulletListItemStyle} {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    @media ${deviceType.mobile} {
      margin-bottom: 8px;
    }
  }
  ${LandingButtonStyle} {
    padding: 10px 40px;
  }
`;

export const BulletListStyle = styled.ul`
  display: flex;
  flex-direction: column;
  /* margin-bottom: 40px;
    @media ${deviceType.mobile} {
        margin-bottom:20px;
  } */
`;
