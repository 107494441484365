import { isApp } from ".";
import { SESSION_STORAGE_KEYS } from "../constants";
import { redirectInterceptHref } from "../static/utils";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "./clientStorageUtils";

declare global {
  interface Window {
    webkit: any;
    mmt_android_bridge: any;
  }
}

export function setRNVariablesFromURL() {
  if (typeof window === "undefined") return;

  const urlParams = new URLSearchParams(window.location.search);
  const rn = urlParams.get(SESSION_STORAGE_KEYS.SOURCE_REACT_NATIVE);

  if (rn && isApp()) {
    setSessionStorageItem(SESSION_STORAGE_KEYS.SOURCE_REACT_NATIVE, rn, true);
  }
}

export function customBackHandlingForRN() {
  if (getSessionStorageItem(SESSION_STORAGE_KEYS.SOURCE_REACT_NATIVE, true)) {
    window.addEventListener("popstate", closeTmWebview);
    return window.removeEventListener("popstate", closeTmWebview);
  }
}

export function closeTmWebview() {
  if (
    typeof window.webkit?.messageHandlers?.closeWebView?.postMessage !==
    "undefined"
  ) {
    window.webkit.messageHandlers.closeWebView.postMessage(true);
  } else if (typeof window.mmt_android_bridge?.closeWebView !== "undefined") {
    window.mmt_android_bridge.closeWebView();
  }
}

export function redirectToListingPage(partner: string) {
  if (getSessionStorageItem(SESSION_STORAGE_KEYS.SOURCE_REACT_NATIVE, true)) {
    closeTmWebview();
  } else {
    redirectInterceptHref(`/partners/${partner}/forex/listing`);
  }
}
