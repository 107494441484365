import styled from "styled-components";
import { RadioButtonStyle } from "../RadioButton/RadioButton.style";

export interface ShowInsStyleProps {
  planSelected?: boolean;
  margin?: string;
}

export const ShowInsPlanStyle = styled.div<ShowInsStyleProps>`
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  flex: 1;
  background: #fff;
  margin: ${(p) => (p.margin ? p.margin : "")};
  display: flex;
  flex-direction: column;
  /* ${(p) =>
    p.planSelected &&
    `
border-color: #008CFF;
`} */
  ${RadioButtonStyle} {
    text-align: end;
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      padding-right: 30px;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      right: 0;
      left: auto;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      right: 3px;
      left: auto;
    }
  }
`;

export const ShowInsPlanCntnt = styled.div`
  padding: 12px;
`;

export const ShowInsSelect = styled.div<ShowInsStyleProps>`
  background: #f6f6f6;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid transparent;
  padding: 8px 12px;
  display: flex;
  align-items: flex-end;
  margin-top: auto;
  /* ${(p) =>
    p.planSelected &&
    `
        background: #EAF5FF;
        border-top: 1px solid #008CFF;
`} */
`;
