import { deviceType } from "@tm/static/utils";
import styled from "styled-components";
import { ParaTagStyle } from "../TextStyles/TextStyles.style";

export interface LandingTestProps {
  cardType?: string;
  active?: boolean;
  starRateFull?: boolean;
}

export const LandingTestimonialsStyle = styled.section`
  display: flex;
  background: #fff;
  flex-direction: column;
  margin-bottom: 137px;
  width: 100%;

  @media ${deviceType.mobile} {
    margin-bottom: 40px;
  }
`;

export const LandingTestTitle = styled.h3`
  font-family: "Lato";
  font-weight: 500;
  font-size: 56px;
  line-height: 72px;
  color: #000000;
  margin-bottom: 34px;
  display: flex;
  align-items: center;

  @media ${deviceType.mobile} {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
    padding: 0px 16px;
  }
`;

export const TestimonialContent = styled.div`
  display: flex;
  margin-bottom: 8px;
  > img {
    margin-right: 17px;
    border-radius: 8px;
  }
`;

export const LandingTest = styled.div`
  display: inline-block;
  img {
    object-fit: contain;
  }

  @media ${deviceType.mobile} {
    font-weight: 400;
    color: #4a4a4a;
    align-items: flex-start;
    border-radius: 10px;
    position: relative;
    background: #fff;
    z-index: 1;
    width: 100% !important;
    flex-direction: column !important;
    padding: 4px 16px;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const LandingTestWrap = styled.div`
  display: flex;
  width: 575px;
  padding: 32px 30px;
  background: #fff;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  margin-right: 20px;

  ${ParaTagStyle} {
    white-space: initial;
  }

  @media ${deviceType.mobile} {
    width: unset;
    padding: 16px;

    ${ParaTagStyle} {
      font-size: 14px;
      line-height: 20px;
    }
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-right: unset;
  }
`;

export const LandingTestImg = styled.div`
  width: 150px;
  height: 250px;
  flex-shrink: 0;
  position: relative;
  margin-right: 20px;
  z-index: 1;

  > img {
    border-radius: 20px;
  }

  @media ${deviceType.mobile} {
    width: 84px;
    height: 87px;
    display: flex;
  }
`;

export const LandingTextCrd = styled.div<LandingTestProps>`
  padding: 4px 8px 4px 8px;
  border-radius: 19px;
  font-weight: 700;
  font-size: 12px;
  line-height: 11px;
  color: #fff;
  position: absolute;
  bottom: -7px;
  left: 25px;
  text-transform: uppercase;

  ${(p) =>
    p.cardType == "travelLoan" &&
    `
    background: linear-gradient(88.44deg, #43e1a8 0.02%, #28beb2 100.01%);
    `}
  ${(p) =>
    p.cardType == "forexCard" &&
    `
    background: linear-gradient(85.23deg, #ff7f3f 0%, #ff3e5e 100%);
    `}

    @media ${deviceType.mobile} {
    position: static;
    width: fit-content;
  }
`;

export const PersonName = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #012136;
  margin-bottom: 2px;
  display: block;

  @media ${deviceType.mobile} {
    font-size: 16px;
    line-height: 20px;
    font-weight: 900;
  }
`;

export const Date = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #9b9b9b;

  @media ${deviceType.mobile} {
    font-size: 12px;
  }
`;

export const LandingTestCntnt = styled.div`
  z-index: 1;

  @media ${deviceType.mobile} {
    white-space: initial;
  }
`;

export const Flex = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

export const SlideShowContainer = styled.div`
  margin: 0 auto;
  overflow: hidden;
  max-width: 1200px;

  @media ${deviceType.mobile} {
    max-width: 100%;
    cursor: grab;
  }
`;

export const SlideShowSLider = styled.div`
  white-space: nowrap;
  transition: ease 1000ms;
`;

export const SlideDots = styled.div`
  text-align: center;

  @media ${deviceType.mobile} {
    margin-top: 12px;
  }
`;

export const SlideShowDotItem = styled.div<LandingTestProps>`
  background: #3f3d56;
  opacity: 0.2;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 16px;
  cursor: pointer;
  ${(p) =>
    p.active &&
    `
        width: 32px;
        height: 12px;
        background: #000000 !important;
        opacity: 1;
        border-radius: 6px;
        `}
`;

export const StarRating = styled.div<LandingTestProps>`
  display: flex;
  gap: 8px;
  ${(p) =>
    p.starRateFull &&
    `
    position: absolute;
    top: 0;
    `}

  @media ${deviceType.mobile} {
    gap: 5px;
  }
`;

export const StarRatingWrap = styled.div`
  margin-bottom: 12px;
  position: relative;
`;

export const TestimonialTag = styled.div`
  background: rgba(120, 198, 255, 0.2);
  border: 1px solid rgba(120, 198, 255, 0.4);
  border-radius: 8px;
  padding: 2px 12px;
`;
