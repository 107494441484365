import { LandingButton } from "@tm/static";
import React, { useEffect, useState } from "react";
import { ParaTag } from "@tm/static";
import { formatDate } from "../../utils";
import {
  CalenderFooterWrap,
  CalenderFootRange,
  CalFootRangeCol,
} from "./CalendarFooter.style";
import { theme } from "@tm/utility/global";

interface CalenderFooterType {
  checkinDate?: Date;
  checkoutDate?: Date;
  onSubmitButtonPress?: (chkIn?: Date, chkOut?: Date) => void;
}

const CalendarFooter = ({
  checkinDate,
  checkoutDate,
  onSubmitButtonPress,
}: CalenderFooterType) => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    checkinDate && setSelected(1);
    checkinDate && checkoutDate && setSelected(0);
  }, [checkinDate, checkoutDate]);

  return (
    <CalenderFooterWrap>
      <CalenderFootRange>
        <CalFootRangeCol selected={selected === 0}>
          <ParaTag
            textTransform="uppercase"
            fontSize="xs"
            fontWeight="bold"
            color={selected === 0 ? theme.blueText : theme.greyText7}
          >
            start date
          </ParaTag>
          <ParaTag fontWeight="bold">
            {checkinDate ? formatDate(checkinDate) : "-"}
          </ParaTag>
        </CalFootRangeCol>
        <CalFootRangeCol selected={selected === 1}>
          <ParaTag
            textTransform="uppercase"
            fontSize="xs"
            fontWeight="bold"
            color={selected === 1 ? theme.blueText : theme.greyText7}
          >
            end date
          </ParaTag>
          <ParaTag fontWeight="bold">
            {checkoutDate ? formatDate(checkoutDate) : "-"}
          </ParaTag>
        </CalFootRangeCol>
      </CalenderFootRange>
      <LandingButton
        customWidth="100%"
        onClick={
          onSubmitButtonPress
            ? () => onSubmitButtonPress(checkinDate, checkoutDate)
            : () => {}
        }
        customTitle="Confirm"
        disableState={!checkinDate || !checkoutDate}
      />
    </CalenderFooterWrap>
  );
};

export default CalendarFooter;
