interface DocsUploadIconProps {
  size?: number;
  props?: any;
  bgColor?: string;
  documentColor?: string;
}

export const DocsUploadIcon = ({
  size = 48,
  documentColor = "#2B8DFF",
  bgColor = "#F8F8F8",
  props,
}: DocsUploadIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={48} height={48} rx={24} fill="white" />
    <rect
      x={13.2002}
      y={10.801}
      width={21.2677}
      height={26.5846}
      rx={2}
      fill={documentColor}
    />
    <path
      d="M17.7917 15.1197H29.8756"
      stroke={bgColor}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M17.7917 19.8839H29.8756"
      stroke={bgColor}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M17.8525 24.0942H23.1695"
      stroke={bgColor}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <circle
      cx={31.4773}
      cy={33.5074}
      r={7.2}
      fill="url(#paint0_linear_1755_53184)"
    />
    <path
      d="M31.9004 29.9145V37.1021"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M28.3076 33.5069H35.4951"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1755_53184"
        x1={26.3862}
        y1={38.5985}
        x2={36.5685}
        y2={28.4162}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDD287" />
        <stop offset={1} stopColor="#B8860B" />
      </linearGradient>
    </defs>
  </svg>
);
