import styled from "styled-components";

import { theme } from "@tm/utility/global";
import { deviceType } from "@tm/static/utils";

type LandingCardFeatureStyleProps = {
  padding?: string;
  margin?: string;
  border?: string;
  borderWidth?: string;
};

export const NotLocationText = styled.div`
  border-radius: 8px;
  background-color: ${theme.yellowText2};
  padding: 8px;
  margin-top: 24px;
`;

export const LandingCardFeatureStyle = styled.div<LandingCardFeatureStyleProps>`
  display: flex;
  position: relative;
  flex: 1;
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.padding && `padding: ${props.padding};`}
    flex-direction: column;
  ${(props) => props.border && `border: ${props.border};`}
  ${(props) => props.borderWidth && `border-width: ${props.borderWidth};`}
    border-radius: 4px;
`;

export const LogoContainer = styled.div`
  width: 140px;

  @media screen and ${deviceType.mobile} {
    width: 60px;
  }
`;

export const AbsTag = styled.p`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: ${theme.greenText2};
  padding: 4px 12px;
  border-radius: 20px;
`;

export const LandingCardFeatureInnerStyle = styled.div`
  display: flex;
`;

export const PointsContainer = styled.ul`
  margin-top: 12px;
  /* margin-bottom: 24px; */
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-wrap: wrap;
  color: ${theme.greyText};

  & > li:not(:last-child):after {
    content: "•";
    color: ${theme.greenText};
    margin-left: 2px;
    margin-right: 2px;
  }

  @media screen and ${deviceType.mobile} {
    flex-direction: row;
    margin-bottom: 0px;
  }
`;
