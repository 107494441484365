import { AppUpdateModal, EmailLoginModal, LoginErrorModal } from "@tm/auth";
import { useListingPdtLogger } from "../../hooks/usePdtLogger";
import { useGlobalSelector } from "../../hooks/useGlobalSelector";
import { ListingReducerType } from "../../store/forexListingReducer";
import { useGlobalDispatch } from "../../hooks/useGlobalDispatch";
import useListingPdtLoggerV2 from "../../hooks/useListingPdtLoggerV2";
import { getEventDetails } from "../../utils";
import { PDT_EVENTS } from "../../constants";

const AuthModalContainer = () => {
  const { sendCTAData } = useListingPdtLoggerV2();
  const {
    sendPdtOpenEmailPopUp,
    sendPdtEmailPopUpOkClick,
    sendPdtOpenAppUpdatePopUp,
    sendPdtOpenUpdateAppPopUpOkClick,
    sendPdtLoginErrorPopUpOpen,
    sendPdtLoginErrorPopUpOkClick,
  } = useListingPdtLogger();

  const { forceLoginEmailModal } = useGlobalSelector(
    (state: ListingReducerType) => state
  );

  const onOpenEmailLoginModal = () => {
    sendPdtOpenEmailPopUp();
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.EMAIL_LOGIN_POP_UP_OPEN,
    });
    sendCTAData({ eventDetail });
  };

  const onCloseEmailLoginModal = () => {
    sendPdtEmailPopUpOkClick();
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.EMAIL_LOGIN_POP_UP_OK_CLICK,
    });
    sendCTAData({ eventDetail });
  };

  const onOpenAppUpdateModal = () => {
    sendPdtOpenAppUpdatePopUp();

    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.UPDATE_APP_POP_UP_OPEN,
    });
    sendCTAData({ eventDetail });
  };

  const onCloseAppUpdateModal = () => {
    sendPdtOpenUpdateAppPopUpOkClick();

    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.UPDATE_APP_POP_UP_OK_CLICK,
    });
    sendCTAData({ eventDetail });
  };

  const onOpenErrorModal = () => {
    sendPdtLoginErrorPopUpOpen();

    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.LOGIN_ERROR_POP_UP_OPEN,
    });
    sendCTAData({ eventDetail });
  };

  const onCloseErrorModal = () => {
    sendPdtLoginErrorPopUpOkClick();

    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.LOGIN_ERROR_POP_UP_OK_CLICK,
    });
    sendCTAData({ eventDetail });
  };

  return (
    <>
      <EmailLoginModal
        onOpen={onOpenEmailLoginModal}
        onClose={onCloseEmailLoginModal}
      />
      <AppUpdateModal
        onOpen={onOpenAppUpdateModal}
        onClose={onCloseAppUpdateModal}
      />
      <LoginErrorModal onOpen={onOpenErrorModal} onClose={onCloseErrorModal} />
    </>
  );
};

export default AuthModalContainer;
