import * as React from "react";
interface iconProps {}
const FlightCancel = (props: iconProps) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5484 13.4537L12.8911 11.7964L14.5484 10.1391C15.006 9.68148 15.748 9.68148 16.2056 10.1391C16.6633 10.5968 16.6633 11.3388 16.2056 11.7964L14.5484 13.4537Z"
      fill="#0095FF"
    />
    <path
      d="M4.60457 5.16739L2.94727 3.51009L5.4332 1.02415C5.89086 0.566494 6.63285 0.566494 7.09047 1.02415C7.54813 1.48181 7.54813 2.2238 7.09047 2.68142L4.60457 5.16739Z"
      fill="#4BB9EC"
    />
    <path
      d="M11.293 5.10817L2.43008 0.147072C1.9757 -0.106639 1.40074 -0.0261311 1.03215 0.342502L0 1.37465L7.50504 8.89617L11.293 5.10817Z"
      fill="#C9C9C9"
    />
    <path
      d="M17.0193 14.7528L12.1215 5.93677L8.3335 9.72474L15.7913 17.1825L16.8234 16.1504C17.1921 15.7817 17.2726 15.2068 17.0193 14.7528Z"
      fill="#C9C9C9"
    />
    <path
      d="M6.83422 15.3643L5.17695 17.0216L3.51969 13.707L0.205078 12.0498L1.86234 10.3925L4.62172 10.9477L13.5709 0.725744C14.3332 -0.152615 15.6673 -0.293435 16.5954 0.634651C17.5151 1.55442 17.3826 2.89684 16.5043 3.65914L6.27902 12.605L6.83422 15.3643Z"
      fill="#9FACBA"
    />
    <path
      d="M19.9609 15.8594C19.9609 18.1211 18.1211 19.9609 15.8594 19.9609C13.5977 19.9609 11.7578 18.1211 11.7578 15.8594C11.7578 13.5977 13.5977 11.7578 15.8594 11.7578C18.1211 11.7578 19.9609 13.5977 19.9609 15.8594Z"
      fill="#FF3838"
    />
    <path
      d="M16.7305 15.8985L17.4804 16.6602L16.6602 17.4805L15.8984 16.7305L15.1366 17.4805L14.3164 16.6602L15.0663 15.8985L14.3164 15.1367L15.1366 14.3164L15.8984 15.0664L16.6602 14.3164L17.4804 15.1367L16.7305 15.8985Z"
      fill="white"
    />
  </svg>
);
export { FlightCancel };
