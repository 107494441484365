import * as React from "react";

interface LocationGreenIconProps {
  color?: string;
}

export const LocationGreenIcon: React.FC<LocationGreenIconProps> = ({
  color = "#2B825E",
  ...props
}) => (
  <svg
    width={36}
    height={44}
    viewBox="0 0 36 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36 17.2174C36 26.7263 25 37.3043 18 44C10 36.3478 0 26.7263 0 17.2174C0 7.70849 8.05887 0 18 0C27.9411 0 36 7.70849 36 17.2174Z"
      fill={color}
    />
    <ellipse cx={18} cy={17.6004} rx={13.5} ry={13.2} fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.734 11.9977C25.0547 11.4664 24.0636 11.574 23.5202 12.2382L15.7396 21.7478L12.4717 17.821C11.9227 17.1613 10.9307 17.0617 10.256 17.5985C9.5813 18.1353 9.47941 19.1053 10.0284 19.765L13.6484 24.1147C14.7346 25.42 16.7758 25.4117 17.851 24.0977L25.9799 14.1622C26.5233 13.4981 26.4132 12.529 25.734 11.9977Z"
      fill={color}
    />
  </svg>
);
