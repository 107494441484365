import { ArrowIcon } from "@tm/static/icons";
import { FlexComp, ImageTag, ParaTag } from "@tm/static";
import { useBreakpoint } from "@tm/ui/hooks";

import { IconTextTagStyle } from "./IconTextTag.style";
import { theme } from "@tm/utility/global";

interface IconTextTagProps {
  label: string;
  subLabel?: string;
  icon?: string;
  margin?: string;
  onClick?: () => void;
}

const IconTextTag = ({
  label,
  subLabel,
  icon,
  margin,
  onClick,
}: IconTextTagProps) => {
  const device = useBreakpoint();

  return (
    <IconTextTagStyle onClick={onClick} margin={margin}>
      <FlexComp
        flexDirection={device === "lg" ? "row" : "column"}
        gap="4px"
        isCursorPointer={true}
        padding="0px"
      >
        <ParaTag fontSize="sm" fontWeight="bold" color={theme.blackText}>
          {label}
        </ParaTag>
        {subLabel && (
          <ParaTag fontSize="sm" color={theme.greyText}>
            {subLabel}
          </ParaTag>
        )}
      </FlexComp>
      {icon ? <ImageTag src={icon} showCursorPointer /> : <ArrowIcon />}
    </IconTextTagStyle>
  );
};

export default IconTextTag;
