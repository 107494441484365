import { useEffect, useState } from "react";
import { CUSTOM_EVENTS, MESSAGES } from "../../constants";
import { useDevice } from "../../hooks/useDevice";
import Modal from "../ui-atoms/Modal/Modal";
import LandingButton from "../ui-atoms/LandingButton/LandingButton";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";

interface AppUpdateModalTypes {
  onClose?: () => void;
  onOpen?: () => void;
  forceHide?: boolean;
}

export const AppUpdateModal = ({
  onClose,
  onOpen,
  forceHide,
}: AppUpdateModalTypes) => {
  const device = useDevice();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    document.addEventListener(
      CUSTOM_EVENTS.APP_UPDATE_MODAL_EVENT,
      onAppUpdateEventDispatched
    );
    return () => {
      document.removeEventListener(
        CUSTOM_EVENTS.EMAIL_LOGIN_ERROR_EVENT,
        onAppUpdateEventDispatched
      );
    };
  }, []);

  const onAppUpdateEventDispatched = (event: any) => {
    setShowModal(event?.detail?.showModal || "");
    if (event?.detail?.showModal && onOpen) {
      onOpen();
    }
  };

  return showModal && !forceHide ? (
    <Modal
      modalHeading={"Login Error"}
      onModalClose={() => {
        setShowModal(false);
        onClose && onClose();
      }}
      modalType={device === "lg" ? "centered" : "bottomSheet"}
    >
      <ParaTag
        fontSize={device === "lg" ? "md" : "sm"}
        lineHeight={device === "lg" ? "18px" : "20px"}
        margin="32px 0 16px 0"
      >
        {MESSAGES.APP_UPDATE}
      </ParaTag>
      <LandingButton
        customTitle="OK"
        onClick={() => {
          setShowModal(false);
          onClose && onClose();
        }}
        customWidth="100%"
      />
    </Modal>
  ) : null;
};

export default AppUpdateModal;
