import {
  loginWithMobile,
  editNumberReLogin,
  generateNewAuth,
} from "./loginWithMobile";
import {
  isApp,
  deleteSpecificCookie,
  persistSpecificParamInCookie,
  getCookieExpiryTime,
  getUserDeviceOS,
} from "../common";
import { getTmToken, getUserLoginStatus } from "./bridgeMethods";
import {
  ANDROID,
  COOKIES,
  GI_SUB_DOMAIN,
  GoIbibo,
  IOS,
  MakeMyTrip,
  MMT_SUB_DOMAIN,
  TM_AUTH_EXPIRY_DAYS,
} from "../constants";
import { getCookie } from "../common/cookieUtils";
import { setSessionStorageItem } from "../common/clientStorageUtils";

declare global {
  interface Window {
    webkit: any;
    tripMoneyToken: (token: string) => void;
    loginStatus: (isLogged: boolean) => void;
    profileType: (profileType: string) => void;
  }
}

// To edit phone number.
export function editNumberLogin(props: any) {
  const onApp = isApp();
  if (onApp) {
    const promise = new Promise((resolve, reject) => {
      const isUserLogged = (response: boolean | number) => {
        if (response === true) {
          resolve(true);
        } else {
          reject(response);
        }
      };
      editNumberReLogin({ props, isUserLogged });
    });
    return promise;
  } else {
    return;
  }
}

export function login(props: any) {
  const onApp = isApp();
  if (onApp) {
    const promise = new Promise((resolve, reject) => {
      const isUserLogged = (response: boolean | number) => {
        if (response === true) {
          resolve(true);
        } else {
          reject(response);
        }
      };
      loginWithMobile({ props, isUserLogged });
    });
    return promise;
  } else {
    return;
  }
}

export const getTmAuth = () => {
  const onApp = isApp();
  if (onApp) {
    return getTmToken();
  } else {
    return getCookie("tm-auth", true);
  }
};

export const refreshTmAuth = async () => {
  const onApp = isApp();
  if (onApp) {
    await generateNewAuth();
    const tmToken = await getTmToken();
    return tmToken;
  } else {
    //TODO
  }
};

export const isUserLogin = async () => {
  const onApp = isApp();
  if (onApp) {
    const isLoggedIn = await getUserLoginStatus();
    return isLoggedIn;
  }
  return;
};

export const storeInSession = () => {
  const tmAuth = getCookie("tm-auth", true);
  deleteSpecificCookie("tm-auth");
  setSessionStorageItem("tm-auth", tmAuth || "", true);
};

export const encodeJSON = (json: any) => {
  const jsonStr = JSON.stringify(json);
  const encodedString = btoa(jsonStr);
  return encodedString;
};

export const decodeJSON = (str: any) => {
  str = str.replace(/"/g, "");
  const decodedStr = atob(str);
  return JSON.parse(decodedStr);
};

export const getTmAuthJsonForIos = (partnerAuth: any, tmAuth: any) => {
  return {
    tmAuth,
    tmPartnerAuth: `${tmAuth}___${partnerAuth}`,
  };
};

export const getTmAuthFromIosBridge = async (partner: string) => {
  const tokenString = await getTmToken();
  if (!tokenString) {
    return;
  }
  const decodedJson = decodeJSON(tokenString);
  if (partner === MakeMyTrip) {
    persistSpecificParamInCookie(COOKIES.TM_AUTH_KEY, decodedJson.tmAuth, {
      path: "/",
      domain: MMT_SUB_DOMAIN,
      expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
    });
    //Need to keep mapping of mmt_auth and tm_auth to  validate  partner and tm_auth mismatch
    persistSpecificParamInCookie(
      COOKIES.TM_MMT_AUTH_KEY,
      `${decodedJson.tmPartnerAuth}`,
      {
        path: "/",
        domain: MMT_SUB_DOMAIN,
        expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
      }
    );
  } else if (partner === GoIbibo) {
    persistSpecificParamInCookie(COOKIES.TM_AUTH_KEY, decodedJson.tmAuth, {
      path: "/",
      domain: GI_SUB_DOMAIN,
      expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
    });
    persistSpecificParamInCookie(
      COOKIES.TM_GI_AUTH_KEY,
      `${decodedJson.tmPartnerAuth}`,
      {
        path: "/",
        domain: GI_SUB_DOMAIN,
        expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
      }
    );
  }
};

export const closeWebview = () => {
  //@ts-ignore
  if (window?.app_bridge) {
    if (getUserDeviceOS() === ANDROID) {
      //@ts-ignore
      window?.app_bridge?.closeAndRefreshWebView();
    } else if (getUserDeviceOS() === IOS) {
      // @ts-ignore
      window?.webkit.messageHandlers?.closeAndRefreshWebView?.postMessage(
        JSON.stringify(true)
      );
    }
    return;
    //@ts-ignore
  } else if (window?.mmt_android_bridge?.closeWebView) {
    //@ts-ignore
    window?.mmt_android_bridge?.closeWebView(false);
    return;
  }
  window.history?.back();
};
