import * as React from "react";
interface BlogProp {}
const BlueChev = (props: BlogProp) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00005 9.5L12.25 4.25L13.5 5.5L7.00005 12.0028L0.5 5.5L1.75 4.25L7.00005 9.5Z"
      fill="#008CFF"
    />
  </svg>
);
export { BlueChev };
