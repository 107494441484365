import styled from "styled-components";
import { deviceType } from "@tm/static/utils";
import { PARTNER, PartnerType } from "@tm/insurance/constants";
import { LandingButtonStyle } from "../LandingButton/LandingButton.style";
type DesktopLayoutProp = {
  height?: string;
  landingMargin?: boolean;
  alignCenter?: boolean;
  leftSectionHeight?: boolean | undefined;
  flexDirection?: string;
  leftSectionGap?: string;
  leftSectionMargin?: string;
  rightSectionHeight?: string;
  pageTheme?: PartnerType;
  landingPageProp?: boolean;
};

export const DesktopLayoutStyleSectionWrap = styled.div<DesktopLayoutProp>`
  position: relative;
  padding-top: 6px;

  img,
  picture {
    flex-shrink: 0;
  }

  &:after {
    position: fixed;
    width: 100%;
    background-image: url("https://tripmoneycmsimgak.mmtcdn.com/img/blue_Grad_Bg_563cf7d36a.png");
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    top: 0;
    left: 0;
    height: 87%;
    z-index: 1;
  }
  ${(p) =>
    p.pageTheme === PARTNER.MMT && p.landingPageProp
      ? `
        &:after {
        background:transparent;
        }
        ${LandingButtonStyle}{
            border-radius:38px;
        }
`
      : p.pageTheme === PARTNER.GI && p.landingPageProp
      ? `
    &:after {
    background:transparent;
    }
    ${LandingButtonStyle}{
        border-radius:30px;
    }
`
      : ""}
  @media ${deviceType.mobile} {
    padding-top: 0px;
    &:after {
      height: 0;
      background: transparent;
      background-image: none;
    }
  }
`;

export const DesktopLayoutStyleSection = styled.div<DesktopLayoutProp>`
  width: ${(p) => (p.landingMargin ? "1200px" : "1230px")};
  margin: 0 auto;
  display: flex;
  gap: ${(p) => (p.landingMargin ? "88px" : "58px")};
  height: 100%;
  z-index: 3;
  position: relative;
  justify-content: space-between;
  align-items: ${(p) => (p.alignCenter ? "flex-start" : "baseline")};
  flex-direction: ${(p) => (p.flexDirection ? "column" : "row")};
  margin-bottom: ${(p) => (p.landingMargin ? "70px" : "")};
  background: ${(p) => (p.landingMargin ? "#fff" : "transparent")};
  ${(p) =>
    p.pageTheme === PARTNER.MMT && p.landingMargin
      ? `
            padding:68px 40px 40px;
            border-radius:10px;
            margin-top:26px;
`
      : p.pageTheme === PARTNER.GI && p.landingMargin
      ? `
            padding:38px 40px 40px;
            border-radius:10px;
            margin-top:26px;
`
      : ""}
  @media ${deviceType.mobile} {
    background: #f2f2f2;
    width: 100%;
    gap: 24px;
    padding: 56px 16px 0;
    margin-top: 0;
    border-radius: 0px;
    background-image: url("https://tripmoneycmsimgak.mmtcdn.com/img/mob_Bg_76aceb3f35.png");
    background-repeat: no-repeat;
    background-size: contain;
    flex-direction: column;
    ${(p) =>
      p.landingMargin &&
      `
            padding-bottom: 238px;
            margin-bottom: -236px ;
        `}
  }
`;

export const DesktopLayoutBotttomSection = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 80px;
  height: 100%;
  z-index: 2;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  @media ${deviceType.mobile} {
    gap: 56px;
    z-index: 3;
  }
`;

export const DesktopLayoutLeftSection = styled.div<DesktopLayoutProp>`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding-top: ${(p) => (p.leftSectionHeight ? "" : "0px")};
  /* gap: ${(p) => (p.leftSectionGap ? p.leftSectionGap : "")}; */
  gap: 32px;
  margin: ${(p) => (p.leftSectionMargin ? p.leftSectionMargin : "")};
  height: ${(p) => (p.leftSectionHeight ? "100%" : "calc(708px - 130px)")};
  flex: 1;
  margin-left: ${(p) => (p.leftSectionHeight ? "" : "15px")};
  position: sticky;
  top: 155px;
  width: 498px;
  ${(p) =>
    p.pageTheme === PARTNER.MMT && p.leftSectionHeight
      ? `
            margin-top:40px;
`
      : p.pageTheme === PARTNER.GI
      ? `
            margin-top:40px;
`
      : ""}
  @media ${deviceType.mobile} {
    margin-left: 0;
    margin-top: 11px;
    padding-top: 0;
    height: unset;
    gap: 10px;
    position: static;
    width: 100%;
  }
`;

export const DesktopLayoutRightSection = styled.section<DesktopLayoutProp>`
  width: ${(p) => (p.rightSectionHeight ? "592px" : "622px")};
  flex-shrink: 0;
  /* overflow-y: ${(p) => (p.rightSectionHeight ? "" : "scroll")}; */
  padding-bottom: ${(p) => (p.rightSectionHeight ? "" : "40px")};
  /* height: ${(p) =>
    p.rightSectionHeight ? p.rightSectionHeight : "calc(100vh - 86px)"}; */
  display: flex;
  flex-direction: column;
  z-index: 1;
  gap: 16px;
  /* padding: ${(p) => (p.rightSectionHeight ? "" : "30px 30px 0")}; */
  padding: ${(p) => (p.rightSectionHeight ? "" : "56px 30px 0")};
  margin-right: ${(p) => (p.rightSectionHeight ? "" : "-15px")};
  min-height: 490px;

  &::-webkit-scrollbar {
    display: none;
  }
  @media ${deviceType.mobile} {
    width: 100%;
    overflow-y: unset;
    padding: 0;
    margin-right: 0;
    height: unset;
    ${(p) =>
      !p.rightSectionHeight &&
      `
        margin-right: -16px;
        margin-left: -16px;
        width: -webkit-fill-available;
        overflow-y: scroll;
        flex-shrink:unset;
        `}
  }
`;

export const DesktopLayoutHeading = styled.div`
  display: flex;
  flex-direction: column;
  /* min-height: 373px; */
`;
