import {
  PageNameMapping,
  ElementNameMapping,
} from "libs/ui-widgets/src/lib/constants";
import { UploadDocsPersuasionProps } from "libs/ui-widgets/src/lib/types";
import {
  getElementUniqueIds,
  getCamelCaseStr,
} from "libs/ui-widgets/src/lib/utils";
import { theme } from "../../../css/Global";
import { landingCardShowcaseDataProps } from "../../../mockData";
import FlexComp from "../../ui-atoms/FlexComp/FlexComp";
import LandingButton from "../../ui-atoms/LandingButton/LandingButton";
import ParaTag from "../../ui-atoms/TextStyles/ParaTag";
import { useDevice } from "libs/ui-widgets/src/lib/hooks/useDevice";
import { PersuasionHandler } from "../Persuasion";

export default function UploadDocsPersuasion(props: {
  cardData: landingCardShowcaseDataProps;
  persuasionProps: UploadDocsPersuasionProps;
  handlers: PersuasionHandler;
}) {
  const device = useDevice();
  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
        props.handlers.docsPendingHandler(props.cardData);
      }}
    >
      <FlexComp
        padding={device === "lg" ? "8px 12px" : "16px"}
        borderRadius="8px"
        margin={device === "lg" ? "24px 0px 0px" : "24px -16px -16px"}
        justifyContent="space-between"
        flexDirection={"row"}
        bgColor="linear-gradient(147deg, #FFF6D8 4.79%, #FFFAF3 43.98%, #F3F0E6 120.5%)"
        alignItems="center"
      >
        {(props.persuasionProps.label || props.persuasionProps.iconSrc) && (
          <FlexComp
            padding="0px"
            alignItems="center"
            justifyContent="space-between"
            margin={device === "sm" ? "0px 0px 0" : "0px"}
            bgColor="transparent"
          >
            {props.persuasionProps.label && (
              <ParaTag
                fontWeight="bold"
                color={theme.blackText}
                margin="0px 20px 0px 0px"
              >
                {props.persuasionProps.label}
              </ParaTag>
            )}
          </FlexComp>
        )}
        {props.persuasionProps.btnProps && (
          <LandingButton
            id={getElementUniqueIds(
              PageNameMapping.LISTING_PAGE,
              getCamelCaseStr(props.cardData.label),
              ElementNameMapping.UPLOAD_NOW
            )}
            {...props.persuasionProps.btnProps}
            flexShrink="0"
            fontSize={"12px"}
            customHeight="32px"
            padding="12px 16px"
          />
        )}
      </FlexComp>
    </span>
  );
}
