import React, { useState, useEffect, useRef } from "react";
import { PriceTickerData } from "./mockData";
import { useDevice } from "../../../hooks/useDevice";
import { PriceTickerContainer, TickerList } from "./PriceTicker.style";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import { theme } from "../../css/Global";
import {
  PageNameMapping,
  SectionNameMapping,
  ElementNameMapping,
} from "../../../constants";
import { getElementUniqueIds } from "../../../utils";

export interface PriceTickerDataProps {
  id?: string;
  currencyCode?: string;
  amount?: string;
  conditional?: boolean;
  upcomingTripInfo?: string;
}

export interface PriceTickerProps {
  height: number;
  priceTickerData?: PriceTickerDataProps[];
}

const PriceTicker = ({
  height = 56,
  priceTickerData = PriceTickerData,
}: PriceTickerProps) => {
  const [index, setIndex] = useState(0);
  const items = priceTickerData;
  const doubledItems = [...items, ...items];
  const tickerRef = useRef<HTMLUListElement>(null);
  const UPCOMING_TRIP_INTERVAL = 5000;
  const NORMAL_INTERVAL = 3000;
  const device = useDevice();

  useEffect(() => {
    const isItemUpcomingTrip = items[index]?.upcomingTripInfo ? true : false;
    const interval = setInterval(
      () => {
        setIndex((prevIndex) => prevIndex + 1);
      },
      isItemUpcomingTrip ? UPCOMING_TRIP_INTERVAL : NORMAL_INTERVAL
    );

    return () => clearInterval(interval);
  }, [index]);

  useEffect(() => {
    if (index === items.length) {
      setTimeout(() => {
        if (tickerRef.current) {
          tickerRef.current.style.transition = "none";
          tickerRef.current.style.transform = `translateY(0px)`;
          tickerRef.current.offsetHeight; // force reflow
        }
        setIndex(0);

        setTimeout(() => {
          if (tickerRef.current) {
            tickerRef.current.style.transition = "transform 0.8s ease-in-out";
          }
        }, 50); // Small delay to ensure the transition is applied correctly
      }, 50); // Small delay to avoid the glitch
    }
  }, [index, items.length]);

  return (
    <PriceTickerContainer
      height={height}
      id={getElementUniqueIds(
        PageNameMapping.LISTING_PAGE,
        SectionNameMapping.HEADER,
        ElementNameMapping.PRICE_TICKER
      )}
    >
      <TickerList
        ref={tickerRef}
        height={height}
        style={{ transform: `translateY(${-index * height}px)` }}
      >
        {doubledItems?.map((item, i) => (
          <li key={i}>
            {device === "lg" && item?.upcomingTripInfo && (
              <ParaTag
                color={theme.greyText}
                fontSize="xs"
                margin={"0px 0px 2px"}
                dangerousText={item.upcomingTripInfo}
              />
            )}
            <ParaTag
              color={theme.blackText}
              fontWeight={device === "lg" ? "bold" : "medium"}
              fontSize="xxlarge"
            >
              {item.currencyCode}
              <SpanTag
                color={theme.greyText6}
                fontWeight="regular"
                margin={"0px 4px 0px 4px"}
                fontSize="xxlarge"
              >
                @
              </SpanTag>
              {item?.amount && (
                <SpanTag
                  color={theme.greenText5}
                  fontWeight={device === "lg" ? "bold" : "semiBold"}
                  fontSize="xxlarge"
                >
                  {item.amount}
                </SpanTag>
              )}
              {item.conditional && (
                <SpanTag color={theme.redText2} fontSize="xxlarge">
                  {"*"}
                </SpanTag>
              )}
              {item?.amount ? (
                <SpanTag
                  color={theme.greenText5}
                  margin={"0px 0px 0px 4px"}
                  fontWeight={device === "lg" ? "bold" : "semiBold"}
                  fontSize="xxlarge"
                >
                  INR
                </SpanTag>
              ) : (
                <></>
              )}
            </ParaTag>
            {device === "sm" && item?.upcomingTripInfo && (
              <ParaTag
                color={theme.greyText}
                fontSize="xs"
                margin={"2px 0px 0px"}
                dangerousText={item.upcomingTripInfo}
              />
            )}
          </li>
        ))}
      </TickerList>
    </PriceTickerContainer>
  );
};

export default PriceTicker;
