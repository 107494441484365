import React, { useCallback } from "react";

import { debounce } from "@tm/insurance/utils";
import { theme } from "@tm/utility/global";
import {
  ImageComponent,
  CheckButton,
  SpanTag,
  FormattedText,
} from "@tm/static";
import { IPopularCountry } from "./types";
import {
  PopularCountry,
  CountryImg,
  PopularCountryItem,
  BgText,
  TripUpcoming,
  CountryNameTxt,
  OtherCountyBtn,
} from "./TravelDetails.style";
import { useBreakpointV2 } from "libs/auth/src/lib/hooks/useDeviceContext";

const PopularCountryComp = ({
  popCountry,
  handleShowOtherCountry,
  otherCountryTxt,
  handlePopularCountrySelect,
  setIsSelectedError,
}: IPopularCountry) => {
  const device = useBreakpointV2();
  const isCheckedHandler = useCallback(
    (data: any) => {
      return data?.isSelected;
    },
    [popCountry]
  );

  const handleErrorToast = debounce(() => {
    setIsSelectedError(false);
  }, 3000);

  const handleCountrySelect = (item: any) => {
    if (item.isDisabled) {
      setIsSelectedError(true);
      handleErrorToast();
    } else {
      handlePopularCountrySelect(item.code);
    }
  };

  return (
    <PopularCountry>
      {popCountry &&
        popCountry.map((item: any, index: number) => {
          return (
            <PopularCountryItem
              listSelected={isCheckedHandler(item)}
              onClick={() => handleCountrySelect(item)}
              key={index}
            >
              {index !== popCountry.length && (
                <CheckButton
                  isDisabled={item.isDisabled}
                  isChecked={item.isDisabled ? true : isCheckedHandler(item)}
                >
                  {!item.img && item.name && <BgText>{item?.code}</BgText>}
                  {item.upcomingTrip && (
                    <TripUpcoming>TRIP UPCOMING</TripUpcoming>
                  )}
                  {item?.name && !item.subtitle && (
                    <CountryNameTxt title={item.name}>
                      {item?.name}
                    </CountryNameTxt>
                  )}
                  {item?.name && item.subtitle && (
                    <CountryNameTxt title={item.name + item.subtitle}>
                      {item?.name + " " + item.subtitle}
                    </CountryNameTxt>
                  )}
                </CheckButton>
              )}
              {item?.img && (
                <CountryImg>
                  <ImageComponent
                    width={device == "sm" ? "100%" : "80px"}
                    height={device == "lg" ? "80px" : "82px"}
                    src={item.img}
                    loading="eager"
                  />
                </CountryImg>
              )}
            </PopularCountryItem>
          );
        })}
      <OtherCountyBtn onClick={handleShowOtherCountry}>
        <ImageComponent
          width="24px"
          height="24px"
          src="https://tripmoneycmsimgak.mmtcdn.com/img/Search_Icon_ffe4fbfcc2.svg"
          loading="eager"
        />
        <SpanTag fontSize="xs" fontWeight="bold" color={theme.blueText}>
          <FormattedText value={otherCountryTxt} />
        </SpanTag>
      </OtherCountyBtn>
    </PopularCountry>
  );
};

export default PopularCountryComp;
