import React from "react";

import { FlexComp, ImageTag, LandingButtonWhite } from "@tm/static";
import { ArrowIcon } from "@tm/static/icons";
import { theme } from "@tm/utility/global";
import { ArrowIconContainer } from "./ListingPageComponents.style";
import { BtnProp } from "../LandingButton/ButtonType";

export interface ListingPageHeaderProps {
  arrowIcon?: string;
  isLoggedIn?: boolean;
  userAvatarImgURI?: string;
  loginBtnProps?: BtnProp;
}

const ListingPageHeader = ({
  arrowIcon,
  isLoggedIn = false,
  userAvatarImgURI,
  loginBtnProps,
}: ListingPageHeaderProps) => {
  return (
    <FlexComp
      padding="16px"
      alignItems={"center"}
      justifyContent="space-between"
    >
      <ArrowIconContainer>
        {arrowIcon ? (
          <ImageTag src={arrowIcon} />
        ) : (
          <ArrowIcon color={theme.greyText} />
        )}
      </ArrowIconContainer>
      {isLoggedIn ? (
        <LandingButtonWhite {...loginBtnProps} />
      ) : (
        userAvatarImgURI && <ImageTag width="24px" src={userAvatarImgURI} />
      )}
    </FlexComp>
  );
};

export default ListingPageHeader;
