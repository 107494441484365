interface FooterHeartIconProps {
  props?: any;
}

export const FooterHeartIcon = ({ props }: FooterHeartIconProps) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1734_41302)">
      <rect
        x={1}
        y={1.36914}
        width={18}
        height={18}
        rx={4.5}
        stroke="#979797"
        strokeWidth={0.9}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.041 5.3291C11.9294 5.3292 10.9042 5.9446 10.3596 6.93889C9.81505 5.9446 8.78985 5.3292 7.67823 5.3291C5.97803 5.3291 4.59961 6.94705 4.59961 8.69137C4.59961 13.0725 10.3596 16.1291 10.3596 16.1291C10.3596 16.1291 16.1196 13.0725 16.1196 8.69137C16.1196 6.94705 14.7412 5.3291 13.041 5.3291Z"
        fill="url(#paint0_linear_1734_41302)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1734_41302"
        x1={9.28137}
        y1={22.3257}
        x2={21.759}
        y2={10.7275}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0772C" />
        <stop offset={1} stopColor="#F95776" />
      </linearGradient>
      <clipPath id="clip0_1734_41302">
        <rect
          width={20}
          height={20}
          fill="white"
          transform="translate(0 0.369141)"
        />
      </clipPath>
    </defs>
  </svg>
);
