import { useState, useEffect, useRef } from "react";

export type CounterType = "increment" | "decrement";

export interface TimerParams {
  startHours: number;
  startMinutes: number;
  startSeconds: number;
  counterType: CounterType;
  stopHours: number;
  stopMinutes: number;
  stopSeconds: number;
  padLength?: number;
}

const useTimer = ({
  startHours,
  startMinutes,
  startSeconds,
  counterType,
  stopHours,
  stopMinutes,
  stopSeconds,
  padLength = 1,
}: TimerParams) => {
  const [hours, setHours] = useState(startHours);
  const [minutes, setMinutes] = useState(startMinutes);
  const [seconds, setSeconds] = useState(startSeconds);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const hoursRef = useRef(startHours);
  const minutesRef = useRef(startMinutes);
  const secondsRef = useRef(startSeconds);

  const tick = () => {
    const totalSeconds =
      hoursRef.current * 3600 +
      minutesRef.current * 60 +
      secondsRef.current +
      (counterType === "increment" ? 1 : -1);

    if (totalSeconds < 0) {
      return;
    }

    const newHours = Math.floor(totalSeconds / 3600);
    const newMinutes = Math.floor((totalSeconds % 3600) / 60);
    const newSeconds = totalSeconds % 60;

    if (
      newHours === stopHours &&
      newMinutes === stopMinutes &&
      newSeconds === stopSeconds
    ) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    }

    // Update refs
    hoursRef.current = newHours;
    minutesRef.current = newMinutes;
    secondsRef.current = newSeconds;

    // Update state
    setHours(newHours);
    setMinutes(newMinutes);
    setSeconds(newSeconds);
  };

  useEffect(() => {
    if (!timerRef.current) {
      timerRef.current = setInterval(tick, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [counterType, stopHours, stopMinutes, stopSeconds]);

  const formatTime = (time: number) => String(time).padStart(padLength, "0");

  return {
    hours: formatTime(hours),
    minutes: formatTime(minutes),
    seconds: formatTime(seconds),
  };
};

export default useTimer;
