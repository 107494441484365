import styled from "styled-components";

export interface FilterStyleProps {}

export const FilterBottomCompStyle = styled.div<FilterStyleProps>`
  border-radius: 6px;
  background: rgba(9, 18, 32, 0.95);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.43);
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  z-index: 3;
  overflow: hidden;
  max-height: 68px;
`;

export const FilterLeftSection = styled.div`
  display: flex;
  gap: 6px;
  overflow-x: scroll;
  padding: 8px;
  div {
    border-radius: 4px;
    background: #202e3c;
    padding: 6px;
    text-align: center;
    flex: 1;
  }
`;

export const FilterRightSection = styled.div`
  width: 72px;
  height: 68px;
  flex-shrink: 0;
  background: #008cff;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  position: relative;
`;

export const FilterNumber = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: #fff;
  color: #008cff;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  position: absolute;
  top: 8px;
  left: 43px;
`;
