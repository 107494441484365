import styled from "styled-components";
import { deviceType } from "@tm/static/utils";

export const LoginCompStyle = styled.div``;

export const LoginItem = styled.div`
  background: #f6faff;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 12px;
  @media ${deviceType.mobile} {
    padding: 12px 16px;
  }
`;

export const AgreeTnCTxt = styled.div`
  border-top: 1px solid #e2e2e2;
  padding-top: 8px;
  margin-top: 32px;
`;

export const NotYourAccount = styled.div`
  padding: 12px 20px;
  background: #f6f6f6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
