import {
  USER_LOCATION_LOCAL_STORAGE_KEY,
  COOKIES,
  LOGGED_IN,
  NOT_LOGGED_IN,
} from "./constants";
import { getDeviceType, isOnServer } from "../../../utils";
import { getCookie } from "../../../utils/cookieUtils";
import { getSessionStorageItem } from "../../../utils/clientStorageUtils";

export function getLogInStatus() {
  const isLoggedInCookie = getCookie(COOKIES.TM_AUTH_KEY, true);
  const status = isLoggedInCookie ? LOGGED_IN : NOT_LOGGED_IN;
  return status;
}

export function getUserCityAndLocation() {
  if (isOnServer()) return "";
  let userLocation: string = "";
  const { city = "", pincode = "" } =
    getSessionStorageItem(USER_LOCATION_LOCAL_STORAGE_KEY, true, {
      isPiiData: true,
      shouldParseJson: true,
    }) ?? {};
  userLocation = city && pincode ? `${city},${pincode}` : "";
  return userLocation;
}

export function getDevice() {
  if (isOnServer() || !navigator) return "";
  const ua = navigator?.userAgent || "";
  const device = getDeviceType(ua) || "";
  return device;
}
