import styled from "styled-components";

export const InsFaqCompStyle = styled.div`
  background: linear-gradient(45deg, #f7f5ff 14.64%, #e1ecff 85.36%);
  margin-top: 82px;
`;

export const InsCompWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding: 32px 0;
`;

export const SearchField = styled.input`
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 14px 20px;
  width: 530px;
  flex-shrink: 0;
`;

export const IfHelpFullStyle = styled.div`
  background: linear-gradient(45deg, #f7f5ff 14.64%, #e1ecff 85.36%);
  border-radius: 8px;
  padding: 24px 16px;
`;

export const SubmitRequestStyle = styled.div`
  background: #f6f6f6;
  border-radius: 8px;
  padding: 14px;
  display: flex;
  gap: 12px;
`;

export const FeedBackCompStyle = styled.div`
  background: linear-gradient(
    45deg,
    rgba(67, 225, 168, 0.2) 14.64%,
    rgba(33, 147, 147, 0.2) 85.36%
  );
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
