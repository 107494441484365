import * as React from "react";
interface ArrowLargeProps {
  color?: string;
  margin?: string;
  cursor?: string;
}

export const ArrowLarge: React.FC<ArrowLargeProps> = ({
  color = "#008CFF",
  margin,
  cursor = "pointer",
  ...props
}) => (
  <svg
    width={28}
    height={9}
    style={{ margin: margin, cursor: cursor }}
    viewBox="0 0 28 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.86 4.13812L24.06 1.16299C23.8703 0.992485 23.5021 0.9221 23.29 1.11924C23.0811 1.3134 23.0919 1.68997 23.3 1.8849L24.952 3.63686C25.0723 3.76444 24.9819 3.97407 24.8065 3.97407H1.525C1.23506 3.97407 1 4.20912 1 4.49908C1 4.78904 1.23506 5.0241 1.525 5.0241H24.8065C24.9819 5.0241 25.0723 5.23372 24.952 5.36131L23.3 7.11327C23.1209 7.29273 23.0832 7.68254 23.29 7.87893C23.4968 8.07529 23.8781 8.01256 24.06 7.83516L26.86 4.86003C26.9558 4.75822 27.0003 4.63876 27 4.49908C26.9951 4.37395 26.9458 4.22933 26.86 4.13812Z"
      fill={color}
    />
  </svg>
);
