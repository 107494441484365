import { SpanTagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "libs/ui-widgets/src/lib/static/utils";
import styled from "styled-components";

type WebToastStyleProps = {
  top?: string;
  left?: string;
  height?: string;
  width?: string;
  bottom?: string;
  right?: string;
  background?: string;
  txtColor?: string;
  margin?: string;
  position?: string;
  alignItems?: string;
  justifyContent?: string;
};

export const WebToastStyle = styled.div<WebToastStyleProps>`
  position: ${(p) => (p.position ? `${p.position}` : `absolute`)};
  top: ${(p) => `${p.top}`};
  left: ${(p) => `${p.left}`};
  width: ${(p) => `${p.width}`};
  height: ${(p) => `${p.height}`};
  background: ${(p) => (p.background ? `${p.background}` : `#4a4a4a`)};
  display: flex;
  align-items: ${(p) => (p.alignItems ? `${p.alignItems}` : `center`)};
  z-index: 200;
  justify-content: ${(p) =>
    p.justifyContent ? `${p.justifyContent}` : `space-between`};
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.16);
  ${(p) => p.margin && `margin: ${p.margin};`}
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  bottom: ${(p) => `${p.bottom}`};
  right: ${(p) => `${p.right}`};
  picture {
    height: 19px;
    width: 19px;
    padding: 2px;
  }
  ${SpanTagStyle} {
    flex-shrink: 0;
  }
  @media ${deviceType.mobile} {
    width: calc(100% - 32px);
    padding: 8px 16px;
    right: 16px;
    left: 16px;
  }
`;
