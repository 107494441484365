import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children, id }: { children: any; id?: string }) => {
  const portalId = id || "portal";
  const portalEle: HTMLElement | null = document.getElementById(portalId);
  return portalEle ? (
    createPortal(children, portalEle)
  ) : (
    <div id={id}>{children}</div>
  );
};

export default Portal;
