import { theme } from "@tm/utility/global";
import React, { useState } from "react";
import {
  FlexComp,
  RadioButton,
  SpanTag,
  ParaTag,
  ImageComponent,
  FormattedText,
} from "@tm/static";

import {
  ShowInsPlanStyle,
  ShowInsPlanCntnt,
  ShowInsSelect,
} from "./ShowInsPlan.style";
import { TextNodeType } from "../FormattedText/FormattedText";

interface ShowInsPlanProp {
  data?: {
    title?: TextNodeType;
    planInfo?: TextNodeType;
    radioBtnLabel?: TextNodeType;
    planIcon?: string;
  }[];
  margin?: string;
}

const ShowInsPlan: React.FC<ShowInsPlanProp> = ({ data, margin }) => {
  const [selectPlan, setSelectPlan] = useState(-1);
  const handleSelectPlan = (index: number) => {
    setSelectPlan(index);
  };
  return (
    <FlexComp gap="12px" margin={margin}>
      {data &&
        data.map((item, index) => {
          return (
            <ShowInsPlanStyle
              onClick={() => handleSelectPlan(index)}
              planSelected={selectPlan == index}
              key={index}
            >
              <ShowInsPlanCntnt>
                <FlexComp gap="8px" alignItems="center" margin="0 0 8px">
                  {item.planIcon && (
                    <ImageComponent
                      width="24px"
                      height="24px"
                      src={item.planIcon}
                    />
                  )}
                  {item.title && (
                    <SpanTag
                      fontSize="sm"
                      fontWeight="black"
                      color={theme.blackText}
                      margin="0 0 0 8px"
                    >
                      <FormattedText value={item.title} />
                    </SpanTag>
                  )}
                </FlexComp>
                {item.planInfo && (
                  <ParaTag fontSize="xs" color={theme.greyText}>
                    <FormattedText value={item.planInfo} />
                  </ParaTag>
                )}
              </ShowInsPlanCntnt>
              {/* {item.radioBtnLabel && (
                                <ShowInsSelect
                                    planSelected={selectPlan == index}
                                >
                                    <RadioButton
                                        activeBtn={selectPlan == index}
                                        inputIndex={index}
                                        inputId={`${index}`}
                                        handleChange={() =>
                                            handleSelectPlan(index)
                                        }
                                    >
                                        <SpanTag
                                            fontSize="xs"
                                            color={theme.greyText}
                                        >
                                            <FormattedText
                                                value={item.radioBtnLabel}
                                            />
                                        </SpanTag>
                                    </RadioButton>
                                </ShowInsSelect>
                            )} */}
            </ShowInsPlanStyle>
          );
        })}
    </FlexComp>
  );
};

export default ShowInsPlan;
