import { deviceType } from "@tm/static/utils";
import styled from "styled-components";

interface HeaderContProps {
  bgColor?: string;
  showShadow?: boolean;
}
interface HeaderInnerProps {
  headerType?: "mobile" | "desktop";
}

export const HeaderCont = styled.header<HeaderContProps>`
  background-color: ${(props) => props.bgColor};
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: ${(props) =>
    props.showShadow ? "0px 1px 7px rgba(0, 0, 0, 0.21)" : "unset"};
`;
export const HeaderInner = styled.div<HeaderInnerProps>`
  width: 1200px;
  padding: ${(props) => (props.headerType === "mobile" ? "16px" : "20px 0")};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${deviceType.mobile} {
    width: 100%;
  }
`;
export const HeaderLft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
export const HeaderRht = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;
