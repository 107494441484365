import * as React from "react";
interface cityProp {}
const CitiesIcon = (props: cityProp) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1.50391}
      width={18}
      height={18}
      rx={4.5}
      stroke="#979797"
      strokeWidth={0.9}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1802 14.2845L6.37135 16.2869L7.09877 12.0457L4.01735 9.04202L8.27577 8.42324L10.1802 4.56445L12.0846 8.42324L16.343 9.04202L13.2616 12.0457L13.989 16.2869L10.1802 14.2845Z"
      fill="url(#paint0_linear_841_15469)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_841_15469"
        x1={8.96718}
        y1={24.9604}
        x2={23.8392}
        y2={12.0005}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0772C" />
        <stop offset={1} stopColor="#F95776" />
      </linearGradient>
    </defs>
  </svg>
);
export { CitiesIcon };
