import React, { Fragment } from "react";
import ListingPageHeader, { ListingPageHeaderProps } from "./ListingPageHeader";

import { theme } from "../../css/Global";

import {
  FadeImg,
  GifContainer,
  MoveUpAndScale,
  LocationLoading,
  ConditionsText,
  FadeInAnimation,
  MoveUpAnimation,
  MarkerContainer,
  SpecialAttention,
  AbsIconContainer,
  LocationLoadingCtr,
  ListingTopContainer,
  BMFListingContainer,
  LandingCardFeatureCtr,
  FadeInAndMoveUpAnimation,
} from "./ListingPageComponents.style";

import {
  landingCardShowcaseDataProps,
  landingMarqueeDataProps,
} from "../../mockData";
import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import LandingCardFeature from "../LandingCardFeature/LandingCardFeature";
import IconTextTag from "../IconTextTag/IconTextTag";
import CliptextStyle from "../ui-atoms/TextStyles/CliptextStyle";
import DeliveryPersuasion from "../DeliveryPersuasion/DeliveryPersuasion";
import H4Tag from "../ui-atoms/TextStyles/H4Tag";
import { MarqueeBanner } from "../MarqueeBar/MarqueeBar";
import { useDevice } from "../../../hooks/useDevice";
import { LocationFetchingIcon } from "../../icons/LocationFetchingIcon";
import { LocationMarkerWithCheck } from "../../icons/LocationMarkerWithCheck";
import ImageTag from "../ui-atoms/ImageTag/ImageTag";
import { LocationFetchingProps } from "../../../types";
import {
  PageNameMapping,
  SectionNameMapping,
  ElementNameMapping,
  HOST,
  VERSIONS,
} from "../../../constants";
import { getElementUniqueIds, getHostName } from "../../../utils";
import DiscountBanner from "../DiscountBanner/DiscountBanner";
import H5Tag from "../ui-atoms/TextStyles/H5Tag";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";
import { ArrowIcon } from "../../icons/ArrowIcon";

interface TopShowcaseProps {
  isPageLoading?: boolean;
  isPageLoadingGif?: string;
  isPageLoadingText?: string;
  isMarqueeOnBottom?: boolean;
  sectionBgImgURI?: string;
  otherProductsText?: string;
  iconTextTag?: {
    label: string;
    subLabel?: string;
    btnHandler: () => void;
  };
  deliveryPersuasion?: {
    text?: string;
    editLocationHandler: () => void;
    upArrow?: string;
    checkPincode: boolean;
    checkPincodeLabel: string;
    checkPincodeHandler: () => void;
    arrowIcon?: string;
    ctaText?: string;
  } | null;
  landingTitle?: {
    textOne?: string;
    textTwo?: string;
    textThree?: string;
    textFour?: string;
  };
  arrowIcon?: string;
  pinnedShowcaseCardData?: {
    data: landingCardShowcaseDataProps[];
    onClick?: (item: landingCardShowcaseDataProps) => void;
  };
  loadCurrenyBtnHandler: (data: landingCardShowcaseDataProps) => void;
  docsPendingHandler: (data: landingCardShowcaseDataProps) => void;
  recommendedBtnHandler: (data: landingCardShowcaseDataProps) => void;
  startFromWhereLeftHandler: (data: landingCardShowcaseDataProps) => void;
  onClickAnotherLocation: (data: landingCardShowcaseDataProps) => void;
  viewDashBoardClickHandler: (data: landingCardShowcaseDataProps) => void;
  viewOrdersClickHandler: (
    data: landingCardShowcaseDataProps | { label: string; version: number }
  ) => void;
  otherShowcaseCardsData?: {
    isLoading?: boolean;
    loadingText: string;
    loadingImgSrc: string;
    data: landingCardShowcaseDataProps[];
    itemHandler: (item: landingCardShowcaseDataProps, idx?: number) => void;
    showInfoOnRightSideInCards: boolean;
  };
  marqueeData?: {
    isLoading?: boolean;
    loadingText?: string;
    data: landingMarqueeDataProps[];
    LIVETagImgURI?: string;
  };
  mobileStickyHeadingData?: ListingPageHeaderProps;
  mobileTitle?: string;
  locationFetchingData?: {
    locFetchingMode?: LocationFetchingProps;
    isLocationFetching?: boolean;
    locFetchingModeHandler?: React.Dispatch<
      React.SetStateAction<LocationFetchingProps>
    >;
    isLocationFetchingHandler?: React.Dispatch<React.SetStateAction<boolean>>;
    data?: {
      fetchingData: {
        label?: string;
        iconSrc?: string;
        loadingGifSrc?: string;
      };
      fetchedData: {
        iconSrc?: string;
        label?: string | null;
        ctaText?: string;
        ctaHandler?: () => void;
        showDeliveryIcon?: boolean;
      };
      conditionsText?: {
        greenText: string;
        normalText: string;
      };
    };
  };
  discountBanner?:
    | {
        imgSrc?: any;
        label?: string;
        subLabel?: string;
      }
    | any;
  isShowDiscountBanner?: boolean;
  discountOfferHandler?: () => void;
  discountInfoIconHandler?: () => void;
  discountBannerBgColor?: string;
  showInfoIcon?: boolean;
  version: number;
  orderDetailsHandler?: () => void;
  viewOrderLabel: string | boolean;
  locationFetchingDataV3: any;
}

const TopShowcase = ({
  isPageLoading,
  isPageLoadingGif,
  isPageLoadingText,
  sectionBgImgURI,
  otherProductsText,
  iconTextTag,
  deliveryPersuasion,
  landingTitle,
  marqueeData,
  otherShowcaseCardsData,
  pinnedShowcaseCardData,
  isMarqueeOnBottom = false,
  mobileStickyHeadingData,
  mobileTitle,
  locationFetchingData,
  discountBanner,
  isShowDiscountBanner,
  discountOfferHandler,
  discountInfoIconHandler,
  discountBannerBgColor,
  showInfoIcon,
  version,
  viewOrderLabel,
  loadCurrenyBtnHandler,
  docsPendingHandler,
  recommendedBtnHandler,
  startFromWhereLeftHandler,
  onClickAnotherLocation,
  viewDashBoardClickHandler,
  viewOrdersClickHandler,
}: TopShowcaseProps) => {
  const device = useDevice();
  const host = getHostName();

  // For Location Fetching
  const { data: locRenderingData } = locationFetchingData!;
  const LandingCardComponent = LandingCardFeature;
  const locationFetchingRenderer = () => {
    return (
      <Fragment>
        {mobileStickyHeadingData?.isLoggedIn && (
          <ListingPageHeader {...mobileStickyHeadingData} />
        )}
        <FlexComp
          flexDirection="column"
          flex="1"
          alignItems="center"
          width="auto"
          height="auto"
        >
          <LocationLoadingCtr>
            <LocationLoading>
              <Fragment>
                <AbsIconContainer>
                  {locationFetchingData?.locFetchingMode === "ERROR" ||
                  locationFetchingData?.locFetchingMode === "SUCCESS" ? (
                    locRenderingData?.fetchedData.iconSrc ? (
                      <ImageTag src={locRenderingData?.fetchedData.iconSrc} />
                    ) : (
                      <LocationMarkerWithCheck />
                    )
                  ) : locRenderingData?.fetchingData.iconSrc ? (
                    <ImageTag src={locRenderingData.fetchingData.iconSrc} />
                  ) : (
                    <LocationFetchingIcon />
                  )}
                </AbsIconContainer>
                {locRenderingData?.fetchingData.loadingGifSrc && (
                  <FadeImg
                    showAnim={
                      locationFetchingData?.locFetchingMode !== "FETCHING"
                    }
                  >
                    <ImageTag
                      src={locRenderingData.fetchingData.loadingGifSrc}
                    />
                  </FadeImg>
                )}
              </Fragment>
            </LocationLoading>
            {locationFetchingData?.locFetchingMode !== "FETCHING" &&
              locRenderingData?.fetchedData.label && (
                <MoveUpAnimation>
                  <DeliveryPersuasion
                    bgColor=""
                    fontSize="md"
                    padding="0px"
                    text={locRenderingData?.fetchedData.label}
                    styleWithFlex={false}
                    isTextCenter={true}
                    ctaText={locRenderingData?.fetchedData.ctaText}
                    editLocationHandler={
                      locRenderingData?.fetchedData.ctaHandler
                    }
                    showIcon={locRenderingData?.fetchedData.showDeliveryIcon}
                  />
                </MoveUpAnimation>
              )}
            {!!locRenderingData?.fetchingData.label && (
              <MoveUpAndScale
                showAnim={locationFetchingData?.locFetchingMode !== "FETCHING"}
              >
                <ParaTag
                  fontSize="md"
                  // margin="16px 0px 0px"
                  color={theme.greyText9}
                >
                  {locRenderingData.fetchingData.label}
                </ParaTag>
              </MoveUpAndScale>
            )}
          </LocationLoadingCtr>
          {locationFetchingData?.locFetchingMode !== "FETCHING" &&
            locRenderingData?.conditionsText && (
              <ConditionsText isFadeIn={true}>
                <ParaTag
                  fontSize="xs"
                  color={theme.greyText}
                  margin="0 0 20px 0"
                >
                  {!!locRenderingData.conditionsText.greenText && (
                    <SpanTag fontSize="xs" color={theme.greenText}>
                      {locRenderingData.conditionsText.greenText}
                    </SpanTag>
                  )}{" "}
                  {!!locRenderingData.conditionsText.normalText &&
                    locRenderingData.conditionsText.normalText}
                </ParaTag>
              </ConditionsText>
            )}
        </FlexComp>
      </Fragment>
    );
  };

  const mobileViewRenderer = () => {
    return (
      <Fragment>
        {locationFetchingData?.isLocationFetching ? (
          locationFetchingRenderer()
        ) : (
          <>
            {mobileStickyHeadingData && (
              <ListingPageHeader {...mobileStickyHeadingData} />
            )}
            <FadeInAndMoveUpAnimation
              moveFromDistance="50px"
              delay="0s"
              moveDelay="0s"
            >
              <FlexComp
                flexDirection="column"
                padding="16px"
                width="auto"
                height="auto"
              >
                {mobileTitle &&
                  (version === VERSIONS.V3 ? (
                    <H4Tag
                      id={getElementUniqueIds(
                        PageNameMapping.LISTING_PAGE,
                        SectionNameMapping.HEADER,
                        ElementNameMapping.HEADING
                      )}
                    >
                      {mobileTitle}
                    </H4Tag>
                  ) : (
                    <CliptextStyle
                      id={getElementUniqueIds(
                        PageNameMapping.LISTING_PAGE,
                        SectionNameMapping.HEADER,
                        ElementNameMapping.HEADING
                      )}
                    >
                      <H4Tag>{mobileTitle}</H4Tag>
                    </CliptextStyle>
                  ))}
                {!locationFetchingData?.isLocationFetching &&
                  deliveryPersuasion && (
                    <FadeInAnimation delay="0s">
                      <DeliveryPersuasion
                        fontSize="sm"
                        padding="0px"
                        bgColor=""
                        margin={"16px 0px 12px 0px"}
                        {...deliveryPersuasion}
                      />
                    </FadeInAnimation>
                  )}
              </FlexComp>
            </FadeInAndMoveUpAnimation>
            {marqueeData && !isMarqueeOnBottom && (
              <FadeInAndMoveUpAnimation
                delay="0.25s"
                moveDelay="0s"
                moveFromDistance="100px"
              >
                <MarqueeBanner
                  LIVETagImgURI={marqueeData.LIVETagImgURI}
                  isLoading={marqueeData.isLoading}
                  padding={marqueeData.isLoading ? "8px 0px" : undefined}
                  loadingText="Fetching LIVE Rates"
                  margin={device === "lg" ? "0px 0px 16px" : "0px 16px 16px"}
                  marqueeData={marqueeData.data}
                  version={version}
                />
              </FadeInAndMoveUpAnimation>
            )}
            {isShowDiscountBanner && (
              <FadeInAndMoveUpAnimation
                delay="0s"
                moveDelay="0s"
                moveFromDistance="100px"
              >
                <DiscountBanner
                  alignItems="flex-start"
                  bgColor={discountBannerBgColor || theme.darkGreen2}
                  discountImg={discountBanner.imgSrc}
                  label={discountBanner?.label}
                  subLabel={discountBanner?.subLabel}
                  // onPressHandler={discountOfferHandler}
                  // showIcon
                  margin="0px 16px 18px"
                  color={theme.whiteText}
                  fontWeight="800"
                  width="calc(100% - 32px)"
                  height="fit-content"
                  infoIconHandler={discountInfoIconHandler}
                  showInfoIcon={showInfoIcon}
                />
              </FadeInAndMoveUpAnimation>
            )}

            <MarkerContainer
              moveFromDistance="150px"
              delay="0.5s"
              moveDelay="0s"
            >
              {pinnedShowcaseCardData &&
                pinnedShowcaseCardData?.data?.length > 0 && (
                  <>
                    {pinnedShowcaseCardData &&
                      pinnedShowcaseCardData.data.map((item) => (
                        <SpecialAttention key={item.id} withShadow={true}>
                          <FlexComp
                            padding={device === "lg" ? "24px" : "16px"}
                            width="auto"
                            height="auto"
                          >
                            <LandingCardComponent
                              data={item}
                              onClick={pinnedShowcaseCardData.onClick}
                              loadCurrenyBtnHandler={loadCurrenyBtnHandler}
                              recommendedBtnHandler={recommendedBtnHandler}
                              startFromWhereLeftHandler={
                                startFromWhereLeftHandler
                              }
                              docsPendingHandler={docsPendingHandler}
                              onClickAnotherLocation={onClickAnotherLocation}
                              viewDashBoardClickHandler={
                                viewDashBoardClickHandler
                              }
                              viewOrdersClickHandler={viewOrdersClickHandler}
                              isInfoOnRightSide={false}
                            />
                          </FlexComp>
                        </SpecialAttention>
                      ))}
                  </>
                )}
              {otherShowcaseCardsData &&
                otherShowcaseCardsData?.data?.length > 0 && (
                  <SpecialAttention withShadow={true}>
                    <FlexComp
                      flexDirection="column"
                      padding={device === "lg" ? "24px" : "16px"}
                      width="auto"
                      height="auto"
                    >
                      {otherProductsText && (
                        <ParaTag
                          fontSize="sm"
                          fontWeight="bold"
                          margin="0px 0px 16px"
                          color={theme.greyText}
                        >
                          {otherProductsText}
                        </ParaTag>
                      )}
                      {otherShowcaseCardsData &&
                        otherShowcaseCardsData.data.map((item, idx) => {
                          return (
                            <LandingCardComponent
                              isInfoOnRightSide={false}
                              key={item?.id || idx}
                              data={item}
                              border={
                                idx + 1 < otherShowcaseCardsData.data.length
                                  ? "1px #e2e2e2 solid"
                                  : ""
                              }
                              padding={
                                idx + 1 < otherShowcaseCardsData.data.length
                                  ? "0px 0px 16px 0px"
                                  : "0px"
                              }
                              margin={
                                idx + 1 < otherShowcaseCardsData.data.length
                                  ? "0px 0px 16px 0px"
                                  : "0px"
                              }
                              borderWidth={
                                idx + 1 < otherShowcaseCardsData.data.length
                                  ? "0px 0px 1px 0px"
                                  : "0px"
                              }
                              onClick={otherShowcaseCardsData.itemHandler}
                              loadCurrenyBtnHandler={loadCurrenyBtnHandler}
                              recommendedBtnHandler={recommendedBtnHandler}
                              startFromWhereLeftHandler={
                                startFromWhereLeftHandler
                              }
                              docsPendingHandler={docsPendingHandler}
                              onClickAnotherLocation={onClickAnotherLocation}
                              viewDashBoardClickHandler={
                                viewDashBoardClickHandler
                              }
                              viewOrdersClickHandler={viewOrdersClickHandler}
                            />
                          );
                        })}
                    </FlexComp>
                    {iconTextTag && (
                      <IconTextTag
                        label={iconTextTag.label}
                        subLabel={iconTextTag.subLabel}
                        onClick={iconTextTag.btnHandler}
                      />
                    )}
                  </SpecialAttention>
                )}
            </MarkerContainer>
          </>
        )}
      </Fragment>
    );
  };

  const desktopViewHandler = () => {
    return (
      <Fragment>
        <BMFListingContainer>
          <FlexComp
            padding="24px 24px 48px 24px"
            minHeight={"200px"}
            flexDirection="column"
            justifyContent="center"
            width="auto"
            height="auto"
          >
            {otherShowcaseCardsData?.isLoading ? (
              <FlexComp
                padding="0px"
                alignItems="center"
                // justifyContent="center"
                flexDirection="column"
                width="auto"
                height="auto"
              >
                {otherShowcaseCardsData.loadingImgSrc && (
                  <ImageTag
                    width="56px"
                    src={otherShowcaseCardsData.loadingImgSrc}
                  />
                )}
                {!!otherShowcaseCardsData.loadingText && (
                  <ParaTag fontWeight="bold">
                    {otherShowcaseCardsData.loadingText}
                  </ParaTag>
                )}
              </FlexComp>
            ) : (
              <Fragment>
                <FlexComp
                  gap={version === VERSIONS.V3 ? "0px" : "6px"}
                  padding="0px"
                  alignItems="center"
                  justifyContent={
                    version === VERSIONS.V3 ? "space-between" : "center"
                  }
                  margin={`${
                    host === HOST.MAKEMYTRIP ? "40px" : "0px"
                  } 0px 24px 0px`}
                  width="auto"
                  height="auto"
                >
                  <FlexComp
                    gap="6px"
                    flexDirection="row"
                    alignItems="unset"
                    margin="0px"
                    padding="unset"
                    width="unset"
                    height="unset"
                  >
                    {landingTitle?.textOne && (
                      <H5Tag>{landingTitle.textOne}</H5Tag>
                    )}
                    {landingTitle?.textTwo &&
                      landingTitle?.textThree &&
                      landingTitle?.textFour && (
                        <ParaTag
                          // margin="4px 0px 0px"
                          color={theme.greyText}
                          fontSize="large"
                        >
                          {landingTitle.textTwo}{" "}
                          <SpanTag
                            color={theme.greenText}
                            fontSize="large"
                            fontWeight="bold"
                          >
                            {landingTitle.textThree}
                          </SpanTag>{" "}
                          {landingTitle.textFour}{" "}
                        </ParaTag>
                      )}
                  </FlexComp>
                  {/* VIEW ORDER CTA */}
                  {viewOrderLabel && version === VERSIONS.V3 && (
                    <FlexComp
                      gap="4px"
                      flexDirection="row"
                      margin="0px"
                      padding="unset"
                      alignItems="center"
                      width="unset"
                      height="unset"
                    >
                      <AnchorTag
                        fontSize="sm"
                        fontWeight="bold"
                        onClick={viewOrdersClickHandler.bind(null, {
                          label: "View Orders",
                          version: VERSIONS.V3,
                        })}
                      >
                        {viewOrderLabel}
                      </AnchorTag>
                      <ArrowIcon />
                    </FlexComp>
                  )}
                </FlexComp>

                {isShowDiscountBanner && (
                  <DiscountBanner
                    alignItems="center"
                    fontSize="sm"
                    bgColor={theme.greenText2}
                    discountImg={discountBanner.imgSrc}
                    label={discountBanner?.label}
                    subLabel={discountBanner?.subLabel}
                    // onPressHandler={discountOfferHandler}
                    // showIcon
                    margin="0px 0px 18px"
                    color={theme.darkGreen}
                    fontWeight="800"
                    iconColor={theme.blueText}
                    width={"fit-content"}
                    infoIconHandler={discountInfoIconHandler}
                    showInfoIcon={showInfoIcon}
                  />
                )}

                {otherShowcaseCardsData && (
                  <LandingCardFeatureCtr version={version}>
                    {otherShowcaseCardsData.data.map((item, index) => {
                      return (
                        <LandingCardComponent
                          isInfoOnRightSide={
                            otherShowcaseCardsData.showInfoOnRightSideInCards
                          }
                          data={item}
                          key={index}
                          idx={index}
                          padding="16px"
                          border="1px #e2e2e2 solid"
                          onClick={otherShowcaseCardsData.itemHandler}
                          loadCurrenyBtnHandler={loadCurrenyBtnHandler}
                          recommendedBtnHandler={recommendedBtnHandler}
                          startFromWhereLeftHandler={startFromWhereLeftHandler}
                          docsPendingHandler={docsPendingHandler}
                          onClickAnotherLocation={onClickAnotherLocation}
                          viewDashBoardClickHandler={viewDashBoardClickHandler}
                          viewOrdersClickHandler={viewOrdersClickHandler}
                        />
                      );
                    })}
                  </LandingCardFeatureCtr>
                )}
                {iconTextTag && (
                  <IconTextTag
                    margin="8px 0px 0px"
                    label={iconTextTag.label}
                    subLabel={iconTextTag.subLabel}
                    onClick={iconTextTag.btnHandler}
                  />
                )}
              </Fragment>
            )}
          </FlexComp>
        </BMFListingContainer>
        {marqueeData && !isMarqueeOnBottom && (
          <MarqueeBanner
            margin="24px 0px 0px"
            isLoading={marqueeData.isLoading}
            padding={
              marqueeData.isLoading
                ? device === "lg"
                  ? "11px 24px"
                  : device === "sm"
                  ? "8px 16px"
                  : undefined
                : device === "lg"
                ? "4px 0px"
                : undefined
            }
            marqueeData={marqueeData.data}
            LIVETagImgURI={marqueeData.LIVETagImgURI}
            loadingText={marqueeData.loadingText}
            version={version}
          />
        )}
      </Fragment>
    );
  };

  return device ? (
    <ListingTopContainer
      fullPageHeight={true}
      bgImage={sectionBgImgURI}
      isPageLoading={isPageLoading}
      isLocationFetching={locationFetchingData?.isLocationFetching}
      version={version}
    >
      {isPageLoading &&
      device === "sm" &&
      !locationFetchingData?.isLocationFetching ? (
        <FlexComp
          alignItems="center"
          flexDirection="column"
          width="auto"
          height="auto"
        >
          {isPageLoadingGif && (
            <GifContainer>
              <ImageTag src={isPageLoadingGif} width="48px" />
            </GifContainer>
          )}
          {isPageLoadingText && (
            <ParaTag
              margin="90px 0px 0px"
              fontSize={"md"}
              color={theme.whiteText}
            >
              {isPageLoadingText}
            </ParaTag>
          )}
        </FlexComp>
      ) : device === "lg" ? (
        desktopViewHandler()
      ) : (
        mobileViewRenderer()
      )}
    </ListingTopContainer>
  ) : (
    <Fragment />
  );
};

export default TopShowcase;
