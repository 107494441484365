import React, { Fragment } from "react";
import styled from "styled-components";

import { PersuasionTileStyles } from "./PersuasionTile.style";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import FlexComp from "../ui-atoms/FlexComp/FlexComp";
import AnchorTag from "../ui-atoms/TextStyles/AnchorTag";
import { theme } from "../../css/Global";
import { useDevice } from "../../../hooks/useDevice";

interface PersuasionTileProps {
  bgColor?: string;
  margin?: string;
  leftIcon?: React.ReactNode;
  isHtml?: boolean;
  linkBtn?: {
    label?: string;
    onClick?: (e: any) => void;
  };
  text?: {
    blackBoldText?: string;
    greenBoldText?: string;
    normalText?: string;
    linkText?: {
      text: string;
      hrefLink: string;
    };
  };
  normalTextColor?: string;
  id?: string;
  flexId?: string;
  htmlStyle?: object;
  doNotShiftLink?: boolean;
}

const Container = styled.div`
  font-size: 14px;
  line-height: 20px;
`;
const PersuasionTile = ({
  bgColor,
  leftIcon,
  text,
  margin,
  normalTextColor = "black",
  linkBtn,
  isHtml,
  id,
  flexId,
  htmlStyle,
  doNotShiftLink = false,
}: PersuasionTileProps) => {
  const device = useDevice();

  const paraTagRenderer = () => {
    return (
      <Container>
        {text && text.blackBoldText && (
          <SpanTag fontSize="xs" fontWeight="bold" color={theme.blackText}>
            {text.blackBoldText}
          </SpanTag>
        )}{" "}
        {text && text.greenBoldText && (
          <SpanTag color={theme.greenText} fontSize="xs" fontWeight="bold">
            {text.greenBoldText}
          </SpanTag>
        )}{" "}
        {text?.normalText && (
          <>
            {isHtml
              ? text.normalText && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: text.normalText || "",
                    }}
                    style={htmlStyle}
                    suppressHydrationWarning={true}
                  ></div>
                )
              : text.normalText && (
                  <SpanTag fontSize="xs" color={normalTextColor}>
                    {text.normalText}
                  </SpanTag>
                )}
          </>
        )}{" "}
        {/* {text && text.linkText && (
                    <AnchorTag
                        fontSize="xs"
                        fontWeight="bold"
                        hrefProp={text.linkText.hrefLink}
                    >
                        {text.linkText.text}
                    </AnchorTag>
                )} */}
      </Container>
    );
  };

  return device ? (
    <PersuasionTileStyles margin={margin} bgColor={bgColor}>
      {device === "lg" ? (
        <Fragment>
          <FlexComp
            id={flexId}
            gap="4px"
            alignItems="center"
            flex="1"
            padding="0"
          >
            {leftIcon && leftIcon}
            {paraTagRenderer()}
          </FlexComp>
          {linkBtn && (
            <AnchorTag
              id={id}
              margin="0px 0px 0px 8px"
              fontSize="xs"
              fontWeight="bold"
              handleClick={linkBtn.onClick}
            >
              {linkBtn.label}
            </AnchorTag>
          )}
        </Fragment>
      ) : (
        <FlexComp
          id={flexId}
          gap="12px"
          flex="1"
          alignItems="center"
          padding="0"
        >
          {leftIcon && leftIcon}
          <FlexComp
            gap="0px"
            flexDirection={doNotShiftLink ? "row" : "column"}
            alignItems={doNotShiftLink ? "center" : undefined}
            justifyContent={doNotShiftLink ? "space-between" : undefined}
            flex="1"
            padding="0"
          >
            {paraTagRenderer()}
            {linkBtn && (
              <AnchorTag
                id={id}
                handleClick={linkBtn.onClick}
                fontSize="xs"
                fontWeight="bold"
              >
                {linkBtn.label}
              </AnchorTag>
            )}
          </FlexComp>
        </FlexComp>
      )}
    </PersuasionTileStyles>
  ) : null;
};

export default PersuasionTile;
