interface ChevRightProps {
  color?: string;
  cursor?: string;
}

const ChevRight = ({
  color = "#008CFF",
  cursor = "pointer",
  ...props
}: ChevRightProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.3941 12.1414C13.4722 12.0633 13.4722 11.9367 13.3941 11.8586L9.29289 7.75735C8.90237 7.36683 8.90237 6.73366 9.29289 6.34314C9.68342 5.95261 10.3166 5.95261 10.7071 6.34314L15.6569 11.2929C16.0474 11.6834 16.0474 12.3166 15.6569 12.7071L10.7071 17.6568C10.3166 18.0474 9.68342 18.0474 9.29289 17.6568C8.90237 17.2663 8.90237 16.6332 9.29289 16.2426L13.3941 12.1414Z"
      fill={color}
    />
  </svg>
);
export { ChevRight };
