import LandingOfferingContainer from "../../static/components/LandingOfferingContainer/LandingOfferingContainer";
import {
  landingForexOfferingCardData,
  landingForexOfferingCardDataProps,
} from "../../static/mockData";
import { useDevice } from "../../hooks/useDevice";
import useRedirectionUrls from "../../hooks/useRedirectionUrls";
import { useListingPdtLogger } from "../../hooks/usePdtLogger";
import { getCardValue, getEventDetails, shouldOpenMMTLogin } from "../../utils";
import { useGlobalConfig } from "../../hooks/useGlobalConfig";
import useOpenLoginForm from "../../hooks/useOpenLoginForm";
import { trackListingClickEvents } from "../../analytics";
import { useGlobalSelector } from "../../hooks/useGlobalSelector";
import { ListingReducerType } from "../../store/forexListingReducer";
import { CARD_TYPE, DEFAULT_FLOW, PDT_EVENTS } from "../../constants";
import { ProductType } from "../../types";
import useListingPdtLoggerV2 from "../../hooks/useListingPdtLoggerV2";
import { WhiteBackgroundStyled } from "../../static/CommonStyle/CommonStyles.style";
import { BMFListingContainer } from "../../static/components/ListingPageComponents/ListingPageComponents.style";

const forexOffering = {
  data: landingForexOfferingCardData,
  title: "Know More about our Products",
};

const CARD_TO_ACTION_MAPPING: Record<string, string> = {
  "Multicurrency Card": "multicurrency_details_clicked",
  "Foreign Currency Notes": "currency_notes_details_clicked",
};

const ForexLandingOfferings = () => {
  const device = useDevice();
  return <ForexLandingOfferingsContainer />;
};

export default ForexLandingOfferings;

const ForexLandingOfferingsContainer = () => {
  const { partner, isLoggedInUser } = useGlobalConfig();
  const { redirectKnowMoreCardLinks } = useRedirectionUrls();
  const { sendPdtKnowMoreCardCtaClickEvent } = useListingPdtLogger();
  const { sendCTAData } = useListingPdtLoggerV2();
  const { openLoginFormForNonLoggedInUser } = useOpenLoginForm();
  const { products } = useGlobalSelector((state: ListingReducerType) => state);
  const proceedHandler = async (item: landingForexOfferingCardDataProps) => {
    const cardValue = getCardValue(products, item?.id as ProductType);
    if (
      !isLoggedInUser &&
      !(
        cardValue?.deep_login_allowed == true && item.id === CARD_TYPE.CURRENCY
      ) &&
      item.id !== CARD_TYPE.MULTI_CURRENCY_CARD
    ) {
      await openLoginFormForNonLoggedInUser();
      return;
    }
    await sendPdtKnowMoreCardCtaClickEvent(item.cardTitle);

    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.KNOW_MORE_CTA,
      event_value: item.cardTitle,
    });

    sendCTAData({
      eventDetail,
    });
    trackListingClickEvents({
      actionType: CARD_TO_ACTION_MAPPING[item.cardTitle],
      partner,
    });
    item.cta_redirect = cardValue?.cta_redirect;
    item.version = cardValue?.version || DEFAULT_FLOW;
    item.cta = cardValue?.cta;
    redirectKnowMoreCardLinks(item);
  };
  return (
    <WhiteBackgroundStyled>
      <BMFListingContainer>
        <LandingOfferingContainer
          heading={forexOffering.title}
          data={forexOffering.data}
          onClickProceed={proceedHandler}
        />
      </BMFListingContainer>
    </WhiteBackgroundStyled>
  );
};
