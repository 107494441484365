import styled from "styled-components";

export const AddressCompStyle: any = styled.div`
  display: flex;
  background: #f6faff;
  gap: 4px;
  padding: 20px 10px;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #cce8ff;
  ${(props: any) => props.containerStyle}
`;

export const AddAddressContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
`;
export const EditContainer: any = styled.div`
  display: flex;
  flex-direction: column;
`;
export const EditAddressContainer: any = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const EditAddressLeftStyle: any = styled.div`
  flex-direction: column;
`;

export const DeliveryDateContainer: any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
`;

export const InfoIconWpr: any = styled.div`
  position: relative;
`;
