import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

interface IActiveLink {
  children: ReactNode;
  href: string;
  onClick?: () => void;
}

export function ActiveLink({ children, href, ...rest }: IActiveLink) {
  const [fontWeight, setFontWeight] = useState<string | number>("normal");

  useEffect(() => {
    const fontWeight =
      window.location.href.indexOf(href.replace("/", "")) !== -1
        ? 800
        : "normal";
    setFontWeight(fontWeight);
  }, []);

  return (
    <a href={href} {...rest} style={{ fontWeight }}>
      {children}
    </a>
  );
}
