import { createGlobalStyle } from "styled-components";
export const ForexProductsStyle = createGlobalStyle`

.getForex{
    padding: 56px 24px 44px;
    border-radius: 10px;
    background-color: #fff;
}
.getForex__title{
    margin-bottom: 40px;
}
.getForex__title .big{
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin-right: 10px;
}
.getForex__title .small{
    font-size: 18px;
    color: #4a4a4a;
    font-weight: 400;
}
.getForex__title .medium{
    font-size: 18px;
    color: #2b825e;
    font-weight: 900;
}
.getForex__list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}
.getForex__listItem{
    border: solid 1px #e2e2e2;
    padding: 10px 13px;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
}
.getForex__listItemImg{
    width: 140px;
    flex-shrink: 0;
    margin-right: 10px;
}
.getForex__listItemDesc{
    flex: 1;
}
.getForex__listItemDesc h3.title{
    font-size: 18px;
    font-weight: 900;
    color: #000;
    display: flex;
    align-items: center;
}
.getForex__listItemDesc p.title{
    font-size: 18px;
    font-weight: 900;
    color: #000;
    display: flex;
    align-items: center;
}
.getForex__listItemDesc h3.title .arrow{
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 10px;
    position: relative;
}
.getForex__listItemDesc h3.title .arrow::after{
    content: "";
    display: inline-block;
    border: solid #008cff;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(-45deg);
    position: absolute;
    right: 2px;
    top: 4px;
}
.getForex__listItemDesc h3.title .arrow::before{
    content: "";
    display: inline-block;
    width: 10px;
    height: 2px;
    background-color: #008cff;
    position: absolute;
    right: 3px;
    top: 7px;
}
.getForex__listItemDesc p.desc{
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
}
.getForex__listItemDesc p.desc span{
    position: relative;
    padding: 0 5px 0 10px;
}
.getForex__listItemDesc p.desc span::after{
    width: 4px;
    height: 4px;
    background-color: #2b825e;
    content: "";
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 8px;
}
.getForex__listItemDesc p.desc span:first-child::after{
    display: none;
}
.getForex__listItemDesc p.desc span:first-child{
    padding: 0 5px 0 0;
}
@media (min-width: 320px) and (max-width: 767px) {
    .getForex{ box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.16); border: solid 1px #e8e8e8; padding: 25px 12px; border-radius: 12px;}
    .getForex__title{ display: none;}
    .getForex__list{ grid-template-columns: 1fr;}
    .getForex__listItem{ border: 0; padding: 0; border-radius: 0;}
    .getForex__listItem:first-child{ border-bottom: 1px #f2f2f2 solid; padding-bottom: 15px;}
    .getForex__listItemImg{ width: 64px;}
    .getForex__listItemImg img{ width: 64px; height: unset;}
    .getForex__listItemDesc p.desc{ margin-top: 5px; font-size: 12px; line-height: 18px;}
    .getForex__listItemDesc p.desc span::after{ top: 6px;}
    .getForex__listItemDesc p.title .arrow{ margin-left: auto;}
}
`;
