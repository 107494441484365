import React, { useRef, useEffect } from "react";

import { ShimmerComp } from "./Shimmer.style";
import { ShimmerType } from "./ShimmerType";

export default function Shimmer({
  width,
  height,
  margin,
  padding,
  children,
  background,
}: ShimmerType) {
  return (
    <ShimmerComp
      padding={padding}
      width={width}
      height={height}
      margin={margin}
      background={background}
    >
      {" "}
      {children}
    </ShimmerComp>
  );
}
