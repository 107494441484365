import React, { Dispatch, SetStateAction } from "react";

interface SEOContext {
  refreshBtn: string;
  currencyCode: string | number;
  exchangeRateApi: any;
  inrRate: string | number;
  setInrRate: Dispatch<SetStateAction<string | number>>;
}

export const CurrencySEOContext = React.createContext<SEOContext>({
  refreshBtn: "",
  currencyCode: "",
  exchangeRateApi: null,
  inrRate: "",
  setInrRate: () => {
    return;
  },
});
