import * as React from "react";
interface iconprop {}
const GreySpiral = (props: iconprop) => (
  <svg
    width={60}
    height={58}
    viewBox="0 0 60 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M58.0764 22.5587C58.6531 22.4945 59.0686 21.975 59.0044 21.3984L57.959 12.0008C57.8949 11.4241 57.3754 11.0086 56.7987 11.0727C56.222 11.1369 55.8065 11.6564 55.8707 12.2331L56.7999 20.5865L48.4465 21.5157C47.8698 21.5799 47.4543 22.0994 47.5185 22.676C47.5826 23.2527 48.1021 23.6682 48.6788 23.6041L58.0764 22.5587ZM2.07621 37.2577C8.7142 25.7603 16.3501 20.6118 22.9282 19.6045C29.4773 18.6016 35.1915 21.6601 38.2476 27.0683L40.077 26.0345C36.6112 19.9013 30.0615 16.3865 22.6102 17.5275C15.1879 18.664 7.08677 24.3767 0.256481 36.2071L2.07621 37.2577ZM38.2476 27.0683C39.7792 29.7786 39.9904 32.0064 39.5103 33.6481C39.0325 35.2817 37.8259 36.4953 36.1816 37.1246C32.8862 38.3857 27.7459 37.2658 24.056 31.9463L22.3294 33.144C26.4632 39.1032 32.5687 40.7571 36.9326 39.087C39.1179 38.2507 40.8444 36.5724 41.527 34.2379C42.2074 31.9115 41.8063 29.0948 40.077 26.0345L38.2476 27.0683ZM24.056 31.9463C22.3233 29.4486 22.1974 26.6711 23.299 24.0815C24.4135 21.4615 26.8052 19.0079 30.1299 17.3598C36.7382 14.0838 46.9515 14.0551 57.304 22.335L58.6165 20.694C47.6907 11.9557 36.6084 11.803 29.1966 15.4772C25.5112 17.3042 22.7117 20.0942 21.3654 23.259C20.0063 26.454 20.1502 30.0024 22.3294 33.144L24.056 31.9463Z"
      fill="#CBCBCB"
    />
  </svg>
);
export { GreySpiral };
