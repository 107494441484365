import styled from "styled-components";
import { deviceType } from "@tm/static/utils";

import { theme } from "@tm/utility/global";

export const LandingFooterStyle = styled.section`
  background: ${theme.blackText};
  color: ${theme.whiteText};
  padding-top: 32px;
  padding-bottom: 64px;
  width: 100%;

  @media screen and ${deviceType.mobile} {
    padding-bottom: 22px;
  }
`;

type ChevronContainerIconProps = {
  isInvert?: boolean;
};

export const ChevronContainerIcon = styled.span<ChevronContainerIconProps>`
  transform: ${(props) => (props.isInvert ? "rotate(180deg)" : "rotate(0deg)")};
`;

export const LinksContainer = styled.ul`
  margin-bottom: 100px;

  & > li:not(:last-child) {
    margin-bottom: 24px;
  }

  @media screen and ${deviceType.mobile} {
    margin-bottom: 40px;

    & > li {
      padding: 16px;
      border-bottom: 1px ${theme.greyText} solid;
    }

    & > li:not(:last-child) {
      margin-bottom: unset;
    }
  }
`;

export const FooterSection = styled.div`
  width: 964px;

  @media screen and ${deviceType.mobile} {
    width: 100%;
  }
`;

export const InnerLinksContainer = styled.ul`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;

  & > li {
    position: relative;
    color: white;
    font-size: 12px;
    line-height: 16px;
  }

  & > li::after {
    content: "•";
    margin-left: 4px;
    margin-right: 4px;
  }

  & > li:last-child:after {
    content: "";
  }
`;

export const LandingFooterWrap = styled.div`
  margin: 0 auto;
  width: 1200px;

  @media screen and ${deviceType.mobile} {
    width: 100%;
  }
`;
