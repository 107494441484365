import React from "react";
import { CommonModalDweb, FlexComp, ParaTag, SpanTag } from "@tm/static";
import { theme } from "@tm/utility/global";

import { BreakupList, SubBreakupList } from "./AmountBreakup.style";

interface AmtBreakupInterFace {
  amtBreakupTitle: string;
  data?: {
    title?: string;
    info?: string;
    totalAmt?: string;
    amountBrkup?: {
      id?: number;
      label?: string;
      amnt?: string;
      addBrkup?: {
        id?: number;
        subLabel?: string;
        amnt?: string;
      }[];
    }[];
  };
  handleClose?: () => void;
}

const AmountBreakup = ({
  amtBreakupTitle,
  data,
  handleClose,
}: AmtBreakupInterFace) => {
  return (
    <CommonModalDweb customTitle={amtBreakupTitle} handleClick={handleClose}>
      <BreakupList>
        {data &&
          data.amountBrkup?.map((item, index) => {
            return (
              <li key={item.id}>
                <FlexComp justifyContent="space-between">
                  <SpanTag
                    fontSize={index == 0 ? "md" : "sm"}
                    color={theme.blackText}
                  >
                    {item.label}
                  </SpanTag>
                  <SpanTag
                    fontSize={index == 0 ? "md" : "sm"}
                    color={theme.blackText}
                  >
                    {item.amnt}
                  </SpanTag>
                </FlexComp>
                {item.addBrkup &&
                  item.addBrkup.map((item2, index2) => {
                    return (
                      <SubBreakupList key={item2.id}>
                        <SpanTag fontSize="sm" color={theme.greyText}>
                          {item2.subLabel}
                        </SpanTag>
                        <SpanTag fontSize="sm" color={theme.greyText}>
                          {item2.amnt}
                        </SpanTag>
                      </SubBreakupList>
                    );
                  })}
              </li>
            );
          })}
      </BreakupList>
      <FlexComp justifyContent="space-between">
        <div>
          <ParaTag fontSize="md" fontWeight="bold" color={theme.blackText}>
            {data?.title}
          </ParaTag>
          <SpanTag fontSize="sm" color={theme.greyText}>
            {data?.info}
          </SpanTag>
        </div>
        <ParaTag fontSize="md" fontWeight="bold" color={theme.blackText}>
          {data?.totalAmt}
        </ParaTag>
      </FlexComp>
    </CommonModalDweb>
  );
};

export default AmountBreakup;
