import { Fragment } from "react";
import { theme } from "@tm/utility/global";
import { useBreakpoint } from "@tm/ui/hooks";

import {
  AnchorTag,
  BulletListItem,
  LandingButtonWhite,
  Modal,
  ParaTag,
  SpanTag,
} from "@tm/static";

import {
  BulletListContainer,
  CardCongratsStyle,
  CardInfoContainer,
  CardInnerStyle,
  Confetti,
  EnvelopeCtr,
  MoveUpContainer,
  ButtonContainer,
  FeaturesCtr,
  PointTag,
} from "./CardCongrats.style";

import {
  cardCongratsImgDataProps,
  newCardFeaturesModalDataProps,
} from "@tm/static/data";

import { BtnProp } from "../../LandingButton/ButtonType";

interface CardCongratsProps {
  isLoading?: boolean;
  loadingText?: string;
  preferenceText?: string;
  globalCardText?: string;
  onModalClose?: () => void;
  featuresTextData?: string[];
  linkBtn?: {
    title: string;
    handleClick: () => void;
    hrefValue?: string;
  };
  solidBtn?: BtnProp;
  confettiImgURI?: string;
  envelopeImgData?: cardCongratsImgDataProps[];
  newCardFeaturesModalData?: newCardFeaturesModalDataProps[];
}

const CardCongrats = ({
  isLoading = false,
  onModalClose = () => {},
  loadingText,
  preferenceText,
  globalCardText,
  linkBtn,
  solidBtn,
  featuresTextData,
  confettiImgURI,
  envelopeImgData,
  newCardFeaturesModalData,
}: CardCongratsProps) => {
  const device = useBreakpoint();

  return (
    <Modal
      padding="0px"
      modalWidth={480}
      onModalClose={onModalClose}
      modalType={device === "lg" ? "centered" : "fullPage"}
    >
      <CardCongratsStyle>
        {isLoading ? (
          <ParaTag color="white">{loadingText}</ParaTag>
        ) : (
          <CardInnerStyle>
            {confettiImgURI && (
              <Confetti>
                <img src={confettiImgURI} />
              </Confetti>
            )}
            <MoveUpContainer>
              {preferenceText && (
                <ParaTag margin="0px 0px 6px 0px" fontSize="xs" color="gray">
                  {preferenceText}
                </ParaTag>
              )}
              {globalCardText && (
                <ParaTag
                  fontSize="md"
                  fontWeight="bold"
                  color="white"
                  margin="0px 0px 5px 0px"
                >
                  {globalCardText}
                </ParaTag>
              )}
              {featuresTextData && (
                <FeaturesCtr>
                  <ParaTag color="white">
                    {featuresTextData.slice(0, -1).map((item, idx) => {
                      return (
                        <Fragment key={idx}>
                          <SpanTag color="white">{item}</SpanTag>
                          {idx + 1 < featuresTextData.length - 1 && (
                            <PointTag>·</PointTag>
                          )}
                        </Fragment>
                      );
                    })}
                  </ParaTag>
                  <ParaTag color="white">
                    <PointTag>·</PointTag>
                    {featuresTextData.slice(-1)[0]}
                    <PointTag>·</PointTag>
                  </ParaTag>
                </FeaturesCtr>
              )}
              {envelopeImgData && (
                <EnvelopeCtr>
                  {envelopeImgData.map((item, idx) => {
                    return (
                      <img
                        key={idx}
                        src={item.src}
                        alt={item.altTag}
                        className={item.className}
                      />
                    );
                  })}
                </EnvelopeCtr>
              )}
              {newCardFeaturesModalData && (
                <CardInfoContainer>
                  <BulletListContainer>
                    {newCardFeaturesModalData.map((item) => {
                      return (
                        <BulletListItem
                          key={item.id}
                          bulletColor={theme.blueText}
                        >
                          {item.boldText && (
                            <SpanTag color="white" fontWeight="bold">
                              {item.boldText}
                            </SpanTag>
                          )}{" "}
                          {item.normalText}
                        </BulletListItem>
                      );
                    })}
                  </BulletListContainer>
                </CardInfoContainer>
              )}
            </MoveUpContainer>
            {(solidBtn || linkBtn) && (
              <ButtonContainer withAnimation={true}>
                {linkBtn && (
                  <AnchorTag
                    fontWeight="bold"
                    margin="0px 0px 18px 0px"
                    handleClick={linkBtn.handleClick}
                    hrefProp={linkBtn.hrefValue}
                  >
                    {linkBtn.title}
                  </AnchorTag>
                )}
                <LandingButtonWhite customWidth="100%" {...solidBtn} />
              </ButtonContainer>
            )}
          </CardInnerStyle>
        )}
      </CardCongratsStyle>
    </Modal>
  );
};

export default CardCongrats;
