import * as React from "react";
interface iconprop {}
const LightGreenShield = (props: iconprop) => (
  <svg
    width={287}
    height={321}
    viewBox="0 0 287 321"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.24}>
      <g opacity={0.2}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150.226 1.51056C189.214 21.9489 231.324 35.0405 275.532 39.8239V39.8468C282.037 40.6021 286.971 45.889 286.971 52.4577V155.473C286.971 235.968 227.964 305.271 146.318 320.193C145.531 320.422 144.769 320.422 143.982 320.422C143.196 320.422 142.433 320.422 141.647 320.171C60.0006 305.294 0.994141 235.945 0.994141 155.496V52.4348C0.994141 45.8891 5.92723 40.6021 12.4332 39.8468C56.6404 35.0405 98.7504 21.9489 137.739 1.51056C141.647 -0.503521 146.318 -0.503521 150.226 1.51056Z"
          fill="url(#paint0_linear_841_15041)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.405 320.418C143.606 320.422 143.808 320.422 144.012 320.422C144.798 320.422 145.561 320.422 146.347 320.193C227.994 305.271 287 235.968 287 155.473V52.4577C287 45.889 282.067 40.6021 275.561 39.8468V39.8239C231.354 35.0405 189.244 21.9489 150.256 1.51056C148.117 0.408498 145.75 -0.0905396 143.405 0.0134493V320.418Z"
          fill="url(#paint1_linear_841_15041)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.405 320.418C143.606 320.422 143.808 320.422 144.012 320.422C144.798 320.422 145.561 320.422 146.347 320.193C227.994 305.271 287 235.968 287 155.473V52.4577C287 45.889 282.067 40.6021 275.561 39.8468V39.8239C231.354 35.0405 189.244 21.9489 150.256 1.51056C148.117 0.408498 145.75 -0.0905396 143.405 0.0134493V320.418Z"
          fill="white"
          fillOpacity={0.2}
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_841_15041"
        x1={143.982}
        y1={0}
        x2={143.982}
        y2={320.422}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28624A" />
        <stop offset={1} stopColor="#3DC78E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_15041"
        x1={215.202}
        y1={0}
        x2={215.202}
        y2={320.422}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28624A" />
        <stop offset={1} stopColor="#3DC78E" />
      </linearGradient>
    </defs>
  </svg>
);
export { LightGreenShield };
