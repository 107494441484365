import React from "react";

import { FlexCompStyle } from "./FlexComp.style";

interface flexInterface {
  children?: any;
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  gap?: string;
  margin?: string;
  borderBottom?: string;
  borderTop?: string;
  padding?: string;
  flex?: string;
  bgColor?: string;
  borderRadius?: string;
  height?: string;
  alignSelf?: string;
  flexShrink?: string;
  handleClick?: any;
  width?: any;
  boxShadow?: string;
  isCursorPointer?: boolean;
  minHeight?: string;
  id?: string;
}

const FlexComp: React.FC<flexInterface> = ({
  children,
  flexDirection,
  alignItems,
  justifyContent,
  gap,
  margin,
  borderBottom,
  borderTop,
  padding,
  flex,
  bgColor,
  borderRadius,
  height,
  alignSelf,
  flexShrink,
  handleClick,
  boxShadow,
  width,
  minHeight,
  isCursorPointer = false,
  id,
}) => {
  return (
    <FlexCompStyle
      id={id}
      alignItems={alignItems}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      minHeight={minHeight}
      isCursorPointer={isCursorPointer ? true : handleClick ? true : false}
      style={{
        "--gap": gap,
        "--flexMargin": margin,
        "--borderBottom": borderBottom,
        "--borderTop": borderTop,
        "--padding": padding,
        "--flex": flex,
        "--bgColor": bgColor,
        "--borderRadius": borderRadius,
        "--height": height,
        "--alignSelf": alignSelf,
        "--flexShrink": flexShrink,
        "--width": width,
        "--boxShadow": boxShadow,
      }}
      onClick={handleClick}
    >
      {children}
    </FlexCompStyle>
  );
};

export default FlexComp;
