import {
  MMTHeaderProps,
  MMTInsuranceHeader,
} from "../static/components/MMTInsuranceHeader";

interface MMTInsuranceHeaderPropsWithWrapper extends MMTHeaderProps {
  Wrapper?: (props: { children: JSX.Element[] }) => JSX.Element;
}

export function withMMTInsuranceHeader(
  component: JSX.Element,
  headerOptions: MMTInsuranceHeaderPropsWithWrapper = {},
  mobileTitle = "",
  myOrders = false,
  goToMyOrders = () => void 0,
  displayWhiteHeader = false
) {
  const { Wrapper, ...headerProps } = headerOptions;

  const authComponent = (
    <MMTInsuranceHeader
      headerProps={headerProps}
      mobileTitle={mobileTitle}
      myOrders={myOrders}
      goToMyOrders={goToMyOrders}
      displayWhiteHeader={displayWhiteHeader}
    />
  );

  if (headerProps?.forceLogin) {
    return authComponent;
  }

  if (Wrapper) {
    return (
      <Wrapper>
        {authComponent}
        {component}
      </Wrapper>
    );
  }
  return (
    <>
      {authComponent}
      {component}
    </>
  );
}
