import React from "react";
import { FormattedText, LandingButton, SpanTag } from "@tm/static";

import {
  BenefitRow,
  BenefitCol1,
  BenefitCol2,
  BenefitCol3,
} from "./ComparePlan.style";

import { PlanTableFooterProps } from "./types";
import { useBreakpointV2 } from "@tm/ui-widgets";

const PlanTableFooter = ({
  btn1Handler,
  btn2Handler,
  footerData,
  quoteID1,
  quoteID2,
}: PlanTableFooterProps) => {
  const device = useBreakpointV2();
  return (
    <BenefitRow lastRow>
      {footerData && (
        <BenefitCol1>
          <SpanTag fontSize="xs" fontWeight="bold">
            {footerData?.label && <FormattedText value={footerData?.label} />}
          </SpanTag>
        </BenefitCol1>
      )}
      {footerData && (
        <BenefitCol2 lastRow>
          <SpanTag fontSize="xs" fontWeight="black">
            <SpanTag fontSize="xs" fontWeight="bold">
              {footerData?.data?.[quoteID1]?.label && (
                <FormattedText value={footerData?.data?.[quoteID1]?.label} />
              )}
            </SpanTag>
          </SpanTag>
          <LandingButton
            onClick={btn1Handler}
            customTitle={device == "lg" ? "BUY PLAN" : "BUY"}
            customWidth="100%"
          />
        </BenefitCol2>
      )}
      {footerData && (
        <BenefitCol3 lastRow>
          <SpanTag fontSize="xs" fontWeight="bold">
            <SpanTag fontSize="xs" fontWeight="bold">
              {footerData?.data?.[quoteID2]?.label && (
                <FormattedText value={footerData?.data?.[quoteID2]?.label} />
              )}
            </SpanTag>
          </SpanTag>
          <LandingButton
            onClick={btn2Handler}
            customTitle={device == "lg" ? "BUY PLAN" : "BUY"}
            customWidth="100%"
          />
        </BenefitCol3>
      )}
    </BenefitRow>
  );
};

export default PlanTableFooter;
