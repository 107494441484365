import { SectionDividersStyle } from "./SectionDividers.style";
interface sectionDivideType {
  small?: boolean;
  large?: boolean;
  xs?: boolean;
  width?: any;
  height?: any;
  borderRadius?: any;
  margin?: any;
}

const SectionDividers = ({
  small,
  large,
  xs,
  width,
  height,
  borderRadius,
  margin,
}: sectionDivideType) => {
  return (
    <SectionDividersStyle
      small={small}
      large={large}
      xs={xs}
      style={{
        "--tm-width": width,
        "--tm-height": height,
        "--borderRadius": borderRadius,
        "--margin": margin,
      }}
    />
  );
};

export default SectionDividers;
