import { createGlobalStyle } from "styled-components";
export const FaqsStyle = createGlobalStyle`.faqList{
    margin-top: 50px;
}
.answer{
    display:none;
}
.toggleAnswer .answer{
    display:block;
}
.faqList li h4.question{
    font-size: 24px;
    line-height: 26px;
    font-weight: 900;
    color: #000;
    padding: 30px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
}
.faqList li h4.question::after{
    content: "";
    border: solid #2f2f2f;
    display: inline-block;
    border-width: 0 3px 3px 0;
    padding: 6px;
    transform: rotate(45deg);
    position: absolute;
    right: 40px;
    top: 32px;
}
.faqList li div.answer{
    font-size: 18px;
    line-height: 24px;
    color: #000;
    padding: 0 0 0 30px;
    margin-bottom: 30px;
}
.faqList li div.answer span{
    font-weight: 900;
}
.faqList li:hover h4.question{
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
    background-color: #fff;
}
.faqList li.active h4.question::after{
    transform: rotate(-135deg);
    top: 42px;
}

/* Mobile devices */
@media (min-width: 320px) and (max-width: 767px) {
    .faqList li{ border-radius: 6px; box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22); background-color: #fff; padding: 20px 10px; margin-bottom: 40px;}
    .faqList li:last-child{ margin-bottom: 0;}
    .faqList li:hover h4.question{ border-radius: unset; box-shadow: unset; background-color: unset;}
    .faqList li h4.question{ padding: 0 60px 0 0; font-size: 18px; line-height: 22px;}
    .faqList li h4.question::after{ padding: 4px; right: 10px; top: 5px;}
    .faqList li.active h4.question::after{ top: 10px;}
    .faqList li div.answer{ margin-bottom: unset; padding: 10px 0 0 0; font-size: 16px;}
    .faqList li.active{ box-shadow: unset;}
}
`;
