import * as React from "react";
interface CouponIconType {}
const CouponIcon = (props: CouponIconType) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.93155 3.55772C9.60944 2.80629 10.8554 3.3227 10.8043 4.33313C10.7712 4.96945 11.2962 5.49492 11.933 5.46241C12.9435 5.41072 13.4599 6.65671 12.7084 7.33513C12.2352 7.76201 12.2352 8.50438 12.7084 8.93179C13.4599 9.60968 12.9435 10.8557 11.933 10.8045C11.2962 10.7715 10.7712 11.2964 10.8043 11.9333C10.8554 12.9437 9.60944 13.4601 8.93155 12.7092C8.50414 12.2354 7.76177 12.2354 7.33489 12.7092C6.65593 13.4601 5.41047 12.9437 5.46217 11.9333C5.49468 11.2964 4.96921 10.7715 4.33289 10.8045C3.32245 10.8557 2.80604 9.60968 3.55747 8.93179C4.03072 8.50438 4.03072 7.76201 3.55747 7.33513C2.80604 6.65671 3.32245 5.41072 4.33289 5.46241C4.96921 5.49492 5.49468 4.96945 5.46217 4.33313C5.41047 3.3227 6.65593 2.80629 7.33489 3.55772C7.76177 4.03096 8.50414 4.03096 8.93155 3.55772Z"
      fill="url(#paint0_linear_743_3250)"
    />
    <path
      d="M8.93155 3.55772V3.55772C9.60944 2.80629 10.8554 3.3227 10.8043 4.33313C10.7712 4.96945 11.2962 5.49492 11.933 5.46241C12.9435 5.41072 13.4599 6.65671 12.7084 7.33513C12.2352 7.76201 12.2352 8.50438 12.7084 8.93179C13.4599 9.60968 12.9435 10.8557 11.933 10.8045C11.2962 10.7715 10.7712 11.2964 10.8043 11.9333C10.8554 12.9437 9.60944 13.4601 8.93155 12.7092C8.50414 12.2354 7.76177 12.2354 7.33489 12.7092C6.65593 13.4601 5.41047 12.9437 5.46217 11.9333C5.49468 11.2964 4.96921 10.7715 4.33289 10.8045C3.32245 10.8557 2.80604 9.60968 3.55747 8.93179C4.03072 8.50438 4.03072 7.76201 3.55747 7.33513C2.80604 6.65671 3.32245 5.41072 4.33289 5.46241C4.96921 5.49492 5.49468 4.96945 5.46217 4.33313C5.41047 3.3227 6.65593 2.80629 7.33489 3.55772C7.76177 4.03096 8.50414 4.03096 8.93155 3.55772"
      stroke="url(#paint1_linear_743_3250)"
      stroke-width="0.8"
    />
    <path
      d="M9.71191 6.04314C9.86187 5.89317 10.105 5.89317 10.255 6.04314C10.3799 6.1681 10.4008 6.35777 10.3175 6.50431L10.255 6.58619L6.60279 10.2384C6.45283 10.3883 6.2097 10.3883 6.05974 10.2384C5.93477 10.1134 5.91394 9.92373 5.99725 9.77719L6.05974 9.69531L9.71191 6.04314Z"
      fill="white"
      stroke="white"
      stroke-width="0.24"
    />
    <path
      d="M7.1895 5.93066C6.8627 5.93066 6.59802 6.19501 6.59802 6.52182C6.59802 6.84838 6.86278 7.11297 7.1895 7.11297C7.51598 7.11297 7.78066 6.84829 7.78066 6.52182C7.78066 6.19509 7.51606 5.93066 7.1895 5.93066Z"
      fill="white"
      stroke="white"
      stroke-width="0.24"
    />
    <path
      d="M9.07659 9.16797C8.74979 9.16797 8.48511 9.43231 8.48511 9.75912C8.48511 10.0857 8.74987 10.3503 9.07659 10.3503C9.40307 10.3503 9.66774 10.0856 9.66774 9.75912C9.66774 9.43239 9.40315 9.16797 9.07659 9.16797Z"
      fill="white"
      stroke="white"
      stroke-width="0.24"
    />
    <defs>
      <linearGradient
        id="paint0_linear_743_3250"
        x1="4.64479"
        y1="11.6215"
        x2="11.6212"
        y2="4.64517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#43E1A8" />
        <stop offset="1" stop-color="#219393" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_743_3250"
        x1="4.64479"
        y1="11.6215"
        x2="11.6212"
        y2="4.64517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#43E1A8" />
        <stop offset="1" stop-color="#219393" />
      </linearGradient>
    </defs>
  </svg>
);
export { CouponIcon };
