import React from "react";
import {
  FlexComp,
  SpanTag,
  ParaTag,
  AnchorTag,
  ImageComponent,
  FormattedText,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";

import {
  InsurancePlanTypeSection,
  ActivityStatus,
  PlanSectionItem,
} from "./InsurancePlans.style";
import { insurancePlanCompType } from "./type";

const InsurancePlanTypeSectionComp = ({
  data,
  handleMoreBenefits,
  handleTravAssist,
}: insurancePlanCompType) => {
  const device = useBreakpointV2();
  const {
    expertAssistanceInfo,
    sportsActivity,
    expertAssistanceIcon,
    assistanceCovers,
    insBenefits,
  } = data;

  return (
    <InsurancePlanTypeSection>
      {expertAssistanceInfo && (
        <FlexComp
          justifyContent="space-between"
          margin="0 0 12px"
          alignItems="center"
        >
          <SpanTag fontSize="xs" fontWeight="bold" color={theme.greenText3}>
            <FormattedText value={expertAssistanceInfo} />
          </SpanTag>
          {expertAssistanceIcon && (
            <ImageComponent
              width={device == "lg" ? "30px" : "24px"}
              height={device == "lg" ? "30px" : "24px"}
              src={expertAssistanceIcon}
            />
          )}
        </FlexComp>
      )}
      <PlanSectionItem>
        {insBenefits &&
          insBenefits.map((item, index) => {
            return (
              <FlexComp
                gap="0"
                width={device == "lg" ? "max-content" : "100%"}
                flexDirection={device == "lg" ? "column" : "row"}
                justifyContent={device == "sm" ? "space-between" : "unset"}
                key={index}
              >
                <ParaTag
                  fontWeight={index == 0 ? "bold" : "regular"}
                  fontSize="xs"
                >
                  {item.policyTitle}
                </ParaTag>
                <SpanTag
                  fontWeight={index == 0 ? "bold" : "regular"}
                  fontSize="xs"
                >
                  {item.policyAmt}
                </SpanTag>
              </FlexComp>
            );
          })}
        {assistanceCovers &&
          assistanceCovers.map((item, index) => {
            return (
              <ParaTag key={index} fontWeight="bold" fontSize="xs">
                {item}
              </ParaTag>
            );
          })}
        <AnchorTag
          handleClick={
            insBenefits && handleMoreBenefits
              ? () => handleMoreBenefits(parseInt(data.productId))
              : assistanceCovers && handleTravAssist
              ? () => handleTravAssist(parseInt(data.productId))
              : () => {}
          }
          fontSize="xs"
          fontWeight="bold"
          textAlign={device == "lg" ? "right" : "left"}
        >
          {insBenefits && `${data?.totalBenefits} More Benefits`}
          {assistanceCovers && "View Details"}
        </AnchorTag>
      </PlanSectionItem>
      {sportsActivity && (
        <ActivityStatus>
          {sportsActivity?.icon && (
            <ImageComponent
              width={device == "lg" ? "24px" : "16px"}
              height={device == "lg" ? "24px" : "16px"}
              src={sportsActivity?.icon}
            />
          )}
          <SpanTag fontSize="xs" color={theme.blackText}>
            {sportsActivity?.title && (
              <FormattedText value={sportsActivity?.title} />
            )}
          </SpanTag>
        </ActivityStatus>
      )}
    </InsurancePlanTypeSection>
  );
};

export default InsurancePlanTypeSectionComp;
