import React, { useState } from "react";

import {
  CommonModalDweb,
  LandingButton,
  LandingInput,
  FlexComp,
  AnchorTag,
} from "@tm/static";
import {} from "./AddAlternateContact.style";
import { BlueChev } from "@tm/static/icons";
import { theme } from "@tm/utility/global";

interface accordionProp {
  title?: string;
  emailAddress?: string;
  goBackTxt?: string;
  handleEmailChange?: () => void;
  addContactTxt?: string;
  handleAlternateContact?: () => void;
  handleAddContact?: () => void;
  handleGoBack?: () => void;
}

const AddAlternateContact = ({
  title,
  emailAddress,
  goBackTxt,
  handleEmailChange,
  addContactTxt,
  handleAlternateContact,
  handleAddContact,
  handleGoBack,
}: accordionProp) => {
  return (
    <CommonModalDweb handleClick={handleAlternateContact} customTitle={title}>
      <LandingInput
        flex="1"
        placeholderTxt="Enter 10 digit mobile number"
        requiredField
        labelTxt="10 Digit Mobile No."
        inputType="number"
        margin="0 0 16px"
      />
      <LandingInput
        inputValue={emailAddress}
        handleChange={handleEmailChange}
        flex="1"
        placeholderTxt="Enter Email Id"
        requiredField
        labelTxt="Email ID"
      />
      <FlexComp
        justifyContent="space-between"
        alignItems="center"
        margin="32px 0 0"
        gap="10px"
      >
        <LandingButton
          onClick={handleAddContact}
          customWidth="210px"
          customTitle={addContactTxt}
        />
        <AnchorTag
          handleClick={handleGoBack}
          fontSize="md"
          fontWeight="bold"
          textTransform="uppercase"
        >
          {goBackTxt}
        </AnchorTag>
      </FlexComp>
    </CommonModalDweb>
  );
};

export default AddAlternateContact;
