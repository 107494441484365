import React from "react";

import { H3TagStyle } from "./TextStyles.style";

interface H3TagProps {
  color?: "black" | "gray" | "blue" | any;
  children?: any;
  margin?: any;
}

const H3Tag: React.FC<H3TagProps> = ({ children, margin, color }) => {
  return (
    <H3TagStyle style={{ "--color": color, "--margin": margin }}>
      {children}
    </H3TagStyle>
  );
};

export default H3Tag;
