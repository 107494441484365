import styled from "styled-components";
import { ImageTagStyle } from "../ImageTag/ImageTag.style";
import { ParaTagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "libs/ui-widgets/src/lib/static/utils";

export const ManageInsuranceStyle = styled.div`
  background: linear-gradient(46.21deg, #f7f4ff 0.78%, #dceaff 97.21%), #ffffff;
  border: 1px solid #ffffff;
  border-radius: 16px;
  padding: 10px 16px;
  position: relative;
  overflow: hidden;
  > img {
    position: absolute;
    top: -7px;
    right: -7px;
  }
  svg {
    fill: #fff;
  }
`;

export const ManageInsuranceInfo = styled.p`
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
  color: #4a4a4a;

  span {
    font-weight: 800;
  }
`;

export const ManageInsuranceStyleShimmer = styled.div`
  background: #e2e2e2;
  border-radius: 4px;
  height: 176px;
  padding: 16px;
  display: flex;
`;

export const TitleText = styled(ParaTagStyle)`
  max-width: 200px;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 8px;
`;

export const InfoText = styled(ParaTagStyle)`
  width: 95%;
  font-size: 12px;
  margin-bottom: 8px;
  color: #4a4a4a;
  @media ${deviceType.mobile} {
    width: 80%;
  }
`;
