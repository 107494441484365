import { createGlobalStyle } from "styled-components";

export const BaseStyles = createGlobalStyle`  
html,
body {
  background: #ffffff;
  padding: 0;
  margin: 0;
  color: #2f2f2f;
  font-size: 18px;
  line-height: 26px;
  font-family: lato;
  font-weight: 400;
}
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  list-style: none;
  padding: 0;
}
a {
  color: #008cff;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
.hide {
  display: none;
}
.show {
  display: block;
}
img {
  max-width: 100%;
}
svg {
  flex-shrink: 0;
}
.lineThrough {
  text-decoration: line-through;
}
.capText {
  text-transform: uppercase;
}
.makeRelative {
  position: relative;
}
.truncate {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.blackText {
  color: #000000;
}
.whiteText {
  color: #fff;
}
.darkBlueText {
  color: #0e3f76;
}
.greyText {
  color: #696969;
}
.greyText2 {
  color: #4b4a4a;
}
.dashedBorder {
  border-bottom: dashed 1px #cfcfcf;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.font10 {
  font-size: 10px;
  line-height: 10px;
}
.font11 {
  font-size: 11px;
  line-height: 11px;
}
.font12 {
  font-size: 12px;
  line-height: 12px;
}
.font14 {
  font-size: 14px;
  line-height: 14px;
}
.font16 {
  font-size: 16px;
  line-height: 16px;
}
.font18 {
  font-size: 18px;
  line-height: 18px;
}
.font20 {
  font-size: 20px;
  line-height: 20px;
}
.font22 {
  font-size: 22px;
  line-height: 22px;
}
.font28 {
  font-size: 28px;
  line-height: 28px;
}
.latoRegular {
  font-weight: 400;
}
.latosemiBold {
  font-weight: 600;
}
.latoBold {
  font-weight: 700;
}
.latoBlack {
  font-weight: 900;
}
.latoItalic {
  font-style: italic;
}
.lineHeight14 {
  line-height: 14px;
}
.lineHeight16 {
  line-height: 16px;
}
.lineHeight18 {
  line-height: 18px;
}
.lineHeight20 {
  line-height: 20px;
}
.lineHeight21 {
  line-height: 21px;
}
.lineHeight22 {
  line-height: 22px;
}
.lineHeight25 {
  line-height: 25px;
}
.lineHeight30 {
  line-height: 30px;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.textCenter {
  text-align: center;
}
.relative {
  position: relative;
}
.makeFlex {
  display: flex;
}
.makeFlex.flexWrap {
  flex-wrap: wrap;
}
.makeFlex .flexOne {
  flex: 1;
}
.makeFlex.column {
  flex-direction: column;
}
.makeFlex.perfectCenter {
  align-items: center;
  justify-content: center;
}
.makeFlex.hrtlCenter {
  align-items: center;
}
.makeFlex.spaceBetween {
  justify-content: space-between;
}
.makeFlex.top {
  align-items: flex-start;
}
.noShrink {
  flex-shrink: 0;
}
.flexOne {
  flex: 1;
}
.inlineFlex {
  display: inline-flex;
}
.inlineFlex.hrtlCenter {
  align-items: center;
}
.appendTop2 {
  margin-top: 2px;
}
.appendTop3 {
  margin-top: 3px;
}
.appendTop5 {
  margin-top: 5px;
}
.appendTop6 {
  margin-top: 6px;
}
.appendTop8 {
  margin-top: 8px;
}
.appendTop10 {
  margin-top: 10px;
}
.appendTop15 {
  margin-top: 15px;
}
.appendTop18 {
  margin-top: 18px;
}
.appendTop20 {
  margin-top: 20px;
}
.appendTop25 {
  margin-top: 25px;
}
.appendTop30 {
  margin-top: 30px;
}
.appendTop40 {
  margin-top: 40px;
}
.appendTop45 {
  margin-top: 45px;
}
.appendTop52 {
  margin-top: 52px;
}
.appendRight3 {
  margin-right: 3px;
}
.appendRight5 {
  margin-right: 5px;
}
.appendRight6 {
  margin-right: 6px;
}
.appendRight8 {
  margin-right: 8px;
}
.appendRight10 {
  margin-right: 10px;
}
.appendRight12 {
  margin-right: 12px;
}
.appendRight15 {
  margin-right: 15px;
}
.appendRight20 {
  margin-right: 20px;
}
.appendRight35 {
  margin-right: 35px;
}
.appendBottom2 {
  margin-bottom: 2px;
}
.appendBottom3 {
  margin-bottom: 3px;
}
.appendBottom4 {
  margin-bottom: 4px;
}
.appendBottom5 {
  margin-bottom: 5px;
}
.appendBottom7 {
  margin-bottom: 7px;
}
.appendBottom8 {
  margin-bottom: 8px;
}
.appendBottom10 {
  margin-bottom: 10px;
}
.appendBottom12 {
  margin-bottom: 12px;
}
.appendBottom15 {
  margin-bottom: 15px;
}
.appendBottom18 {
  margin-bottom: 18px;
}
.appendBottom20 {
  margin-bottom: 20px;
}
.appendBottom22 {
  margin-bottom: 22px;
}
.appendBottom24 {
  margin-bottom: 24px;
}
.appendBottom30 {
  margin-bottom: 30px;
}
.appendBottom35 {
  margin-bottom: 35px;
}
.appendBottom40 {
  margin-bottom: 40px;
}
.appendBottom60 {
  margin-bottom: 60px;
}
.appendBottom80 {
  margin-bottom: 80px;
}
.paddingT10 {
  padding-top: 10px;
}
.prependTop5 {
  padding-top: 5px;
}
.prependRight5 {
  padding-right: 5px;
}
.appendLeft3 {
  margin-left: 3px;
}
.appendLeft4 {
  margin-left: 4px;
}
.appendLeft5 {
  margin-left: 5px;
}
.appendLeft10 {
  margin-left: 10px;
}
.appendLeft15 {
  margin-left: 15px;
}

.checkmarkOuter {
  display: inline-flex;
  position: relative;
  min-height: 18px;
  flex-shrink: 0;
}
.checkmarkOuter [type='checkbox']:checked,
.checkmarkOuter [type='checkbox']:not(:checked) {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.checkmarkOuter [type='checkbox']:checked + label,
.checkmarkOuter [type='checkbox']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-flex;
}
.checkmarkOuter [type='checkbox']:checked + label:before,
.checkmarkOuter [type='checkbox']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #9b9b9b;
  border-radius: 2px;
  background: #fff;
}
.checkmarkOuter [type='checkbox']:checked + label:before {
  border: 1px solid #008cff;
  content: '';
  width: 18px;
  height: 18px;
  background: #008cff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.checkmarkOuter [type='checkbox']:checked + label:after {
  position: absolute;
  left: 6px;
  top: 3px;
  content: '';
  display: block;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.primaryBtn {
  border-radius: 6px;
  height: 60px;
  width: 100%;
  background: var(
    --btnBg,
    linear-gradient(91.91deg, #000000 0.3%, #330e0e 100%)
  );
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.21);
  display: inline-block;
  flex-shrink: 0;
  color: #fff;
  text-transform: uppercase;
  font-family: lato;
  font-size: 20px;
  font-weight: 900;
  padding: 9px 20px;
  cursor: pointer;
  outline: 0;
  border: 0;
  text-align: center;
}
.flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* Left Navigation Start*/

.leftNav {
  width: 150px;
  background-color: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 50%;
  z-index: 11;
  padding: 16px;
  border-radius: 0 10px 10px 0;
}
.leftNav > li {
  display: flex;
  align-items: center;
  border-bottom: 1px #efefef solid;
}
.leftNav > li:last-child {
  border-bottom: 0;
}
.leftNav > li a {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 700;
  color: #4a4a4a;
}
.leftNav > li a.activePage {
  color: #008cff;
}
.leftNav > li a::after {
  content: '';
  display: inline-block;
  border: solid #008cff;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(-45deg);
  margin-right: 5px;
}

/* Left Navigation End*/

.secondaryBtn {
  width: 90px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-weight: 900;
  color: #008cff;
  font-size: 16px;
  line-height: 20px;
  border-radius: 96px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(93.66deg, #249083 0%, #249054 100%), #ffffff;
  box-shadow: 0px 1px 7px rgb(0 0 0 / 20%);
  -webkit-box-shadow: 0px 1px 7px rgb(0 0 0 / 20%);
  -webkit-appearance: none;
  cursor: pointer;
}
`;
