import React from "react";
import { useFormContext, Controller } from "react-hook-form";

import {
  H6Tag,
  FlexComp,
  SpanTag,
  RadioButton,
  LandingInput,
  LandingButtonWhite,
  FormattedText,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";

import {
  getFormFieldErrorMsg,
  isDobValid,
  subtractYrsFromDate,
} from "@tm/insurance/utils";

import TravellerDescp from "./TravellerDescp";
import {
  TravellerDetailsStyle,
  GenderTypeItem,
} from "./AddTravellerDetail.style";
import { ITravellerDetailsForm, ITravellerDetailsProps } from "./type";

const minDate = subtractYrsFromDate(new Date(), 70).toISOString().split("T")[0];
const maxDate = new Date().toISOString().split("T")[0];

const TravellerDetails = ({
  travellerIndex,
  travellerRef,
  isLoading,
  firstErrorIndex,
  travellerNumber,
  genderTypes,
  handleAddBtn,
  travellerSelected,
  editTravellerState,
  handleGenderChange,
  selectedGender,
  saveFormValuesOnBlur,
  secondaryContactData,
}: ITravellerDetailsProps) => {
  const device = useBreakpointV2();

  const {
    control,
    formState: { errors },
  } = useFormContext<ITravellerDetailsForm>();

  const error = errors?.travellerData?.[travellerIndex];

  const { name, dob, gender } = secondaryContactData || {};

  return (
    <TravellerDetailsStyle
      ref={travellerIndex === firstErrorIndex ? travellerRef : null}
    >
      {!editTravellerState && (
        <>
          <FlexComp alignItems="center" justifyContent="space-between">
            <H6Tag>{travellerNumber}</H6Tag>
            {!travellerSelected && (
              <LandingButtonWhite
                onClick={handleAddBtn}
                customHeight="32px"
                customTitle="ADD"
              />
            )}
          </FlexComp>
          {travellerSelected && (
            <React.Fragment>
              <FlexComp
                flexDirection={device == "lg" ? "row" : "column"}
                margin="16px 0 16px"
                gap="16px"
              >
                {name?.isEnabled && (
                  <Controller
                    control={control}
                    name={`travellerData.${travellerIndex}.name`}
                    rules={{
                      required: {
                        value: name?.isRequired ?? true,
                        message: getFormFieldErrorMsg("name"),
                      },
                      pattern: {
                        value: new RegExp(name?.regex || ""),
                        message: getFormFieldErrorMsg(
                          "name",
                          name?.errorMessage
                        ),
                      },
                    }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <LandingInput
                        flex="1"
                        requiredField={name?.isRequired}
                        placeholderTxt={name?.placeholder}
                        labelTxt={name?.title}
                        inputValue={value}
                        errorState={error?.name?.message}
                        disableState={isLoading}
                        handleChange={onChange}
                        handleBlur={() => {
                          saveFormValuesOnBlur();
                          onBlur();
                        }}
                        autoComplete="off"
                        inputWidth="100%"
                      />
                    )}
                  />
                )}
                {dob?.isEnabled && (
                  <Controller
                    control={control}
                    name={`travellerData.${travellerIndex}.dob`}
                    rules={{
                      required: {
                        value: dob?.isRequired ?? true,
                        message: getFormFieldErrorMsg("dob"),
                      },
                      validate: (value) =>
                        isDobValid(value, dob?.regex) ||
                        getFormFieldErrorMsg("dob", dob?.errorMessage),
                    }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <LandingInput
                        flex="1"
                        placeholderTxt={dob?.placeholder}
                        requiredField={dob?.isRequired}
                        labelTxt={dob?.title}
                        inputType="date"
                        inputValue={value}
                        minValue={minDate}
                        maxValue={maxDate}
                        errorState={error?.dob?.message}
                        disableState={isLoading}
                        handleChange={(event) => {
                          onChange(event.target.value);
                          saveFormValuesOnBlur();
                        }}
                        handleBlur={onBlur}
                        inputWidth="100%"
                      />
                    )}
                  />
                )}
              </FlexComp>
              {gender?.isEnabled && (
                <>
                  <SpanTag
                    margin="0 0 8px"
                    fontSize="md"
                    fontWeight="bold"
                    color={theme.greyText}
                  >
                    <FormattedText value={gender?.title} />
                  </SpanTag>
                  <FlexComp gap="8px">
                    <Controller
                      control={control}
                      name={`travellerData.${travellerIndex}.gender`}
                      rules={{
                        required: {
                          value: gender?.isRequired ?? true,
                          message: getFormFieldErrorMsg("gender"),
                        },
                      }}
                      render={({ field: { value } }) => (
                        <>
                          {genderTypes?.map((item) => (
                            <GenderTypeItem
                              selected={item.label === value}
                              key={item.id}
                              onClick={() => {
                                handleGenderChange(item, travellerIndex);
                                saveFormValuesOnBlur();
                              }}
                            >
                              <RadioButton activeBtn={item.label === value}>
                                <SpanTag
                                  fontSize="xs"
                                  fontWeight="bold"
                                  color={
                                    item.label === value
                                      ? theme.blueText
                                      : theme.greyText
                                  }
                                >
                                  {item.label}
                                </SpanTag>
                              </RadioButton>
                            </GenderTypeItem>
                          ))}
                        </>
                      )}
                    />
                  </FlexComp>
                </>
              )}
            </React.Fragment>
          )}
        </>
      )}

      {editTravellerState && (
        <TravellerDescp
          travellerIndex={travellerIndex}
          travellerNumber={travellerIndex + 1}
          genderTypeSelected={selectedGender}
          secondaryContactData={secondaryContactData}
          handleEditState={handleAddBtn}
        />
      )}
    </TravellerDetailsStyle>
  );
};

export default TravellerDetails;
