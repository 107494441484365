import styled from "styled-components";
import { deviceType } from "libs/ui-widgets/src/lib/static/utils";
import { LandingButtonWhiteStyle } from "libs/ui-widgets/src/lib/static/components/ui-atoms/LandingButton/LandingButton.style";
export interface EditStyleProps {
  selected?: boolean;
}

export const AccordianCompStyle = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const GenderTypeItem = styled.div<EditStyleProps>`
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  padding: 5px 12px;
  cursor: pointer;
  ${(p) =>
    p.selected &&
    `
    background: #EAF5FF;
    border: 1px solid #008CFF;
    border-radius: 6px;
`}
`;

export const EditTravelSheetStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0px;
  z-index: 10;
  background-color: #f2f2f2;
  /* padding: 16px; */
  overflow-y: scroll;
`;

export const BtnWrap = styled.div`
  background: #fff;
  padding: 8px 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const TravllerNum = styled.div`
  border-radius: 18px;
  background: #f6f6f6;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  flex-shrink: 0;
`;

export const EditTravllerItem = styled.div`
  /* display: flex;
    justify-content: space-between; */
  border-bottom: 1px solid #e2e2e2;
  padding: 12px 16px;
  background: #fff;
`;

export const TravellerDetailsStyle = styled.div`
  padding: 16px 30px;
  border-top: 1px solid #e8e8e8;
  ${LandingButtonWhiteStyle} {
    span {
      font-size: 12px;
      line-height: 12px;
    }
  }
  @media ${deviceType.mobile} {
    padding: 24px 16px;
  }
`;

export const TravellerAddShimmer = styled.div`
  padding: 24px 30px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
`;
