import {
  USER_LOCATION_LOCAL_STORAGE_KEY,
  LOGGED_IN,
  NOT_LOGGED_IN,
  OMNI_EVENTS_MAP,
  OMNITURE_MAP,
} from "./constants";
import { COOKIES, HOST } from "../../constants";
import { getHostName, isMobile } from "../../utils";
import { getSessionStorageItem } from "../../utils/clientStorageUtils";
import { getCookie } from "libs/ui-widgets/src/lib/utils/cookieUtils";
import { DEVICE_MODEL_TYPE_OMNITURE, GenericOmniType, OmniData } from "./type";

export function trackEventMweb(omniData: OmniData) {
  if (
    (window?.omniscriptLoaded || getHostName() === HOST.MAKEMYTRIP) &&
    typeof window.s !== "undefined"
  ) {
    for (const [key, value] of Object.entries(omniData)) {
      window.s[key] = value;
    }
    window.s.t();
  } else {
    if (!window.omnidata) window.omnidata = [];
    window.omnidata.push(omniData);
  }
}
export const isOnServer = () => {
  if (typeof window === "undefined") {
    return true;
  }
  return false;
};

export function getLogInStatus() {
  const isLoggedInCookie = getCookie(COOKIES.TM_AUTH_KEY, true);
  const status = isLoggedInCookie ? LOGGED_IN : NOT_LOGGED_IN;
  return status;
}

export function getUserCityAndLocation() {
  if (isOnServer()) return "";
  let userLocation: string = "";
  const options = { isPiiData: true, shouldParseJson: true };
  const { city = "", pincode = "" } =
    getSessionStorageItem(USER_LOCATION_LOCAL_STORAGE_KEY, true, options) ?? {};
  userLocation = city && pincode ? `${city},${pincode}` : "";
  return userLocation;
}

export function getDeviceTypeForOmniture() {
  if (isOnServer() || !navigator) return "";
  // @ts-ignore
  if (window?.mmt_android_bridge || window?.app_bridge) {
    return DEVICE_MODEL_TYPE_OMNITURE.APP;
  } else if (isMobile()) {
    return DEVICE_MODEL_TYPE_OMNITURE.PWA;
  }
  return DEVICE_MODEL_TYPE_OMNITURE.WEB;
}

export function getCommonOmniData({
  pageName,
  lob,
  siteSection,
  partner,
  location,
  platform,
  loginStatus,
  code,
  tmUserId,
  upcomingTrip,
}: GenericOmniType) {
  const {
    PAGE_NAME,
    LOB,
    SITE_SECTION,
    FUNNEL_TYPE,
    USER_LOC,
    PLATFORM,
    LOGIN_STATUS,
    TM_USER_ID,
    UPCOMING_TRIP_DETECTED,
    TRAVEL_END_DATE,
    TRAVEL_START_DATE,
    DESTINATION,
  } = OMNI_EVENTS_MAP;
  const productType = code === "CN" ? "Currency" : "Card";
  const { intl_travel, country, start_date, end_date } = upcomingTrip ?? {};
  return {
    [OMNITURE_MAP[PAGE_NAME]]: pageName,
    [OMNITURE_MAP[LOB]]: lob,
    [OMNITURE_MAP[SITE_SECTION]]: siteSection,
    [OMNITURE_MAP[FUNNEL_TYPE]]: `Tripmoney|BMF_${productType}|${partner}`,
    [OMNITURE_MAP[USER_LOC]]: location,
    [OMNITURE_MAP[PLATFORM]]: platform,
    [OMNITURE_MAP[LOGIN_STATUS]]: loginStatus,
    [OMNITURE_MAP[TM_USER_ID]]: tmUserId,
    ...(intl_travel && {
      [OMNITURE_MAP[UPCOMING_TRIP_DETECTED]]: intl_travel ? "intl_travel" : "",
    }),
    ...(start_date && {
      [OMNITURE_MAP[TRAVEL_START_DATE]]: new Date(
        start_date * 1000
      ).toLocaleDateString(),
    }),
    ...(end_date && {
      [OMNITURE_MAP[TRAVEL_END_DATE]]: new Date(
        end_date * 1000
      ).toLocaleDateString(),
    }),
    ...(country && {
      [OMNITURE_MAP[DESTINATION]]: country,
    }),
  };
}

export function getCommonSeoOmniData({ pageName }: { pageName: string }) {
  const { PAGE_NAME, LOB, SITE_SECTION } = OMNI_EVENTS_MAP;
  return {
    [OMNITURE_MAP[PAGE_NAME]]: pageName,
    [OMNITURE_MAP[LOB]]: "tripmoney forex currency",
    [OMNITURE_MAP[SITE_SECTION]]: "landing",
  };
}
