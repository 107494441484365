import styled from "styled-components";
import {
  ParaTagStyle,
  SpanTagStyle,
} from "../ui-atoms/TextStyles/TextStyles.style";
import { theme } from "../../css/Global";

type DeliveryPersuasionCompProps = {
  margin?: string;
  padding?: string;
  bgColor?: string;
  styleWithFlex?: boolean;
  isTextCenter?: boolean;
};

type ImgWprCompProps = {
  isFlex?: boolean;
};

export const DeliveryPersuasionStyle = styled(ParaTagStyle)<any>`
  border-radius: 4px;
  width: fit-content;
  color: ${theme.greyText};

  .boldText {
    font-weight: 700;
  }

  .blueText {
    color: ${theme.blueText};
  }

  .greenText {
    color: ${theme.greenText5};
  }

  .blackText {
    color: ${theme.blackText};
  }
`;

export const DeliveryPersuasionComp = styled.div<DeliveryPersuasionCompProps>`
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  background: ${(props) => props.bgColor};
  ${(props) =>
    props.isTextCenter &&
    `
        text-align: center;
    `};
  ${(props) =>
    props.styleWithFlex &&
    `
        display: flex;
        align-items: baseline;
    `};
`;

export const Cta = styled.a<any>`
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  font-family: "Open Sans";
  color: ${theme.blueText};
  display: flex;
  align-items: center;
  margin: ${(props: any) => (props?.margin ? props.margin : "0px 0px 0px")};

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }
`;
export const ImgWpr: any = styled.div<ImgWprCompProps>`
  margin: 0 0 0 6px;
  display: ${(props) => (props.isFlex ? "flex" : "block")};
`;

export const DeliveryLocationText = styled(SpanTagStyle)`
  .regular {
    font-weight: 400;
  }

  .greyText9 {
    color: ${theme.whiteText};
  }
`;
