import React from "react";

import { CheckButtonStyle } from "./CheckButton.style";

interface CheckBtnInterFace {
  children?: React.ReactNode;
  inputId?: any;
  travellingTo?: boolean;
  labelBefore?: string | undefined;
  handleChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  inputIndex?: any;
  isChecked?: boolean;
  margin?: string;
  isDisabled?: boolean;
}

const CheckButton = ({
  children,
  inputId,
  travellingTo,
  handleChange,
  inputIndex,
  isDisabled,
  isChecked = false,
  margin,
}: CheckBtnInterFace) => {
  return (
    <CheckButtonStyle
      margin={margin}
      travellingTo={travellingTo}
      isDisabled={isDisabled}
    >
      <input
        onChange={handleChange}
        type="checkbox"
        id={inputId}
        checked={isChecked}
      />
      <label htmlFor={inputId} id={inputId}>
        {children}
      </label>
    </CheckButtonStyle>
  );
};

export default CheckButton;
