import { generateSourceSetImageUrl } from "../../utils/index";
import { ReactImage } from "@tm/interfaces";
export const ImageComponent = ({
  src,
  width,
  height,
  alt = "",
  loading = "lazy",
  className = "",
  id = "",
  cdnUrlWidths = [],
  sizes = "",
  margin,
}: ReactImage) => {
  const imgStyle = margin ? { margin } : {};
  if (!src || !width || !height) {
    return null;
  }
  if (src.endsWith && src.endsWith(".svg")) {
    return (
      <img
        src={src}
        loading={loading}
        alt={alt}
        style={imgStyle}
        className={className}
        id={id}
        width={width}
        height={height}
      />
    );
  }
  const srcsetUrl = generateSourceSetImageUrl(
    cdnUrlWidths,
    src,
    parseInt(width)
  );
  return (
    <picture>
      <source
        data-testid="testID"
        srcSet={srcsetUrl}
        sizes={sizes ? sizes : width}
      />
      <img
        src={src}
        loading={loading}
        alt={alt}
        className={className}
        id={id}
        width={width}
        height={height}
        style={imgStyle}
      />
    </picture>
  );
};
