export default function PopularDenomitations({
  currencyData,
  inrExchageRate,
}: any) {
  return (
    <>
      <div className="separator"></div>
      <h2 className="h2">{currencyData?.popular_denominations?.title}</h2>
      <div className="conversionRate">
        <div className="conversionRate__col">
          <table className="conversionTbl">
            <thead>
              <tr>
                <th>
                  <h3>
                    {currencyData?.popular_denominations?.table?.col_name?.[0]}
                  </h3>
                </th>
                <th>
                  <h3>
                    {currencyData?.popular_denominations?.table?.col_name?.[1]}
                  </h3>
                </th>
              </tr>
            </thead>
            <tbody>
              {currencyData?.popular_denominations?.table?.row_value_left_side?.map(
                (item: any, index: number) => {
                  const href = `/tripmoney/currency-converter/${currencyData?.curr_code?.toLowerCase?.()}-to-inr/${item}-${currencyData?.curr_code?.toLowerCase?.()}`;
                  return (
                    <tr key={index}>
                      <td>
                        <a href={href} target="_self">
                          {item} {currencyData?.curr_code}
                        </a>
                      </td>
                      <td>{(item * inrExchageRate).toFixed(2)} INR</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
        <div className="conversionRate__col">
          <table className="conversionTbl">
            <thead>
              <tr>
                <th>
                  <h3>
                    {currencyData?.popular_denominations?.table?.col_name?.[0]}
                  </h3>
                </th>
                <th>
                  <h3>
                    {currencyData?.popular_denominations?.table?.col_name?.[1]}
                  </h3>
                </th>
              </tr>
            </thead>
            <tbody>
              {currencyData?.popular_denominations?.table?.row_value_right_side?.map(
                (item: any, index: number) => {
                  const href = `/tripmoney/currency-converter/${currencyData?.curr_code?.toLowerCase()}-to-inr/${item}-${currencyData?.curr_code?.toLowerCase()}`;
                  return (
                    <tr key={index}>
                      <td>
                        <a href={href} target="_self">
                          {item} {currencyData?.curr_code}
                        </a>
                      </td>
                      <td>{(item * inrExchageRate).toFixed(2)} INR</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
