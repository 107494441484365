import styled from "styled-components";

type orderDetailProp = {
  pending?: boolean;
  failed?: boolean;
};

export const OrderStatusCompStyle = styled.div<orderDetailProp>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 2px;
  padding: 4px 8px;
  background: ${(p) => (p.pending ? "#FFEDD1" : p.failed ? "#FCDADB" : "#fff")};
`;
