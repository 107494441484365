import React from "react";

import { ShimmerVersion3Style } from "./Shimmer.style";
import { ShimmerType } from "./ShimmerType";

export default function ShimmerVersion3({
  width,
  height,
  margin,
  children,
  padding,
}: ShimmerType) {
  return (
    <ShimmerVersion3Style
      padding={padding}
      width={width}
      height={height}
      margin={margin}
    >
      {children}
    </ShimmerVersion3Style>
  );
}
