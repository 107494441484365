import { trackListingClickEvents } from "../../analytics";
import { ListingReducerType } from "../../store/forexListingReducer";
import { useGlobalConfig } from "../useGlobalConfig";
import { useGlobalSelector } from "../useGlobalSelector";

export default function useTrackListingClickEvent() {
  const { tm_user_id } = useGlobalSelector(
    (state: ListingReducerType) => state
  );
  const { partner } = useGlobalConfig();

  function trackListingClickEventsInOmniture(data: { actionType: string }) {
    trackListingClickEvents({
      actionType: data.actionType,
      tmUserId: tm_user_id,
      partner,
    });
  }

  return {
    trackListingClickEvents: trackListingClickEventsInOmniture,
  };
}
