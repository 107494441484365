import { deviceType } from "@tm/static/utils";
import styled from "styled-components";

export const Container = styled.div`
  background: linear-gradient(98.48deg, #000000 2.53%, #260505 100%);
  border-radius: 8px;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
`;
