import { useEffect, useState } from "react";
import {
  CUSTOM_EVENTS,
  GoIbibo,
  MakeMyTrip,
  MESSAGES,
  PROFILE_URL,
} from "../../constants";
import { useDevice } from "../../hooks/useDevice";
import Modal from "../ui-atoms/Modal/Modal";
import LandingButton from "../ui-atoms/LandingButton/LandingButton";
import ParaTag from "../ui-atoms/TextStyles/ParaTag";
import { getPartner, isApp, isMobile } from "../../common";
import { closeWebview } from "../../methods";
import { delay, loginToPartner, logout } from "../../api/tmAuth";

interface EmailLoginModalTypes {
  onClose?: () => void;
  onOpen?: () => void;
  forceHide?: boolean;
}

export const EmailLoginModal = ({
  onClose,
  onOpen,
  forceHide,
}: EmailLoginModalTypes) => {
  const device = useDevice();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    document.addEventListener(
      CUSTOM_EVENTS.EMAIL_LOGIN_ERROR_EVENT,
      onEmailLoginEventDispatched
    );
    return () => {
      document.removeEventListener(
        CUSTOM_EVENTS.EMAIL_LOGIN_ERROR_EVENT,
        onEmailLoginEventDispatched
      );
    };
  }, []);

  const onEmailLoginEventDispatched = (event: any) => {
    setShowModal(event?.detail?.showModal || "");
    if (event?.detail?.showModal && onOpen) {
      onOpen();
    }
  };

  const onCloseEmailLogin = async () => {
    setShowModal(false);
    if (onClose) {
      onClose();
    }

    // await logout()
    // Need some delay while getting logout callback from  app  bridges
    // await delay(100)
    // await loginToPartner()

    // Need for future to redirect to profile page

    if (isApp()) {
      closeWebview();
      return;
    }
    if (getPartner() === MakeMyTrip) {
      if (isMobile()) {
        window.location.href = PROFILE_URL.MMT_PWA;
      } else {
        window.location.href = PROFILE_URL.MMT_DT;
      }
      return;
    } else if (getPartner() === GoIbibo) {
      window.location.href = PROFILE_URL.GI;
      return;
    }
  };

  return showModal && !forceHide ? (
    <Modal
      modalHeading={`Mobile Number not linked to ${
        getPartner() === MakeMyTrip ? "MakeMyTrip" : "Goibibo"
      } Account`}
      onModalClose={() => {
        setShowModal(false);
        onClose && onClose();
      }}
      modalType={device === "lg" ? "centered" : "bottomSheet"}
    >
      <ParaTag
        fontSize={device === "lg" ? "md" : "sm"}
        lineHeight={device === "lg" ? "18px" : "20px"}
        margin="32px 0 16px 0"
      >
        {MESSAGES.EMAIL_LOGIN_ERROR(
          getPartner() === MakeMyTrip ? "MakeMyTrip" : "Goibibo"
        )}
      </ParaTag>
      <LandingButton
        customTitle="LINK MOBILE NUMBER"
        onClick={onCloseEmailLogin}
        customWidth="100%"
      />
    </Modal>
  ) : null;
};

export default EmailLoginModal;
