import { ReactNode } from "react";
import { FlexComp } from "@tm/static";
import { useBreakpoint } from "@tm/ui/hooks";
import { breakpoint } from "@tm/static/utils";
import { LeftContainer } from "./LeftStickyRightScrollLayout.style";
interface LeftStickyRightScrollLayoutInterface {
  leftSection: ReactNode;
  rightSection: ReactNode;
}

const LeftStickyRightScrollLayout = ({
  leftSection,
  rightSection,
}: LeftStickyRightScrollLayoutInterface) => {
  return (
    <LeftContainer>
      {leftSection}
      {rightSection}
    </LeftContainer>
  );
};

export default LeftStickyRightScrollLayout;
