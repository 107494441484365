import { CurrencySEOMarkdown } from "../CurrencySEOMarkdown";

export default function WhyToBuy({ currencyData }: any) {
  if (!currencyData?.whyToBuy) {
    return null;
  }
  return (
    <>
      <div className="separator"></div>
      <h2 className="h2">{currencyData?.whyToBuy?.title}</h2>
      <CurrencySEOMarkdown className="description mt10">
        {currencyData?.whyToBuy?.sub_title}
      </CurrencySEOMarkdown>
      <ul className="benifitsList">
        {currencyData?.whyToBuy?.list?.map((item: any, index: number) => (
          <li key={index}>
            <CurrencySEOMarkdown>{item}</CurrencySEOMarkdown>
          </li>
        ))}
      </ul>
      <CurrencySEOMarkdown className="description">
        {currencyData?.whyToBuy?.end_para}
      </CurrencySEOMarkdown>
    </>
  );
}
