import React, { useState } from "react";

import { ShimmerVersion2 } from "@tm/static";
import {
  OrderRightStyle,
  OrderRightHd,
  OrderRightCntnt,
} from "./OrderDetailRightComp.style";

interface accordionProp {
  title?: string;
  info?: string;
  handleInvoice?: () => void;
  amountPaid?: {
    breakupTxt?: string;
    details?: {
      bank?: string;
      amt?: string;
    };
  };
}

const OrderDetailRightCompShimmer = ({}: accordionProp) => {
  return (
    <OrderRightStyle>
      <OrderRightHd>
        <ShimmerVersion2 width="100%" height="22px" margin="0 0 auto" />
      </OrderRightHd>
      <OrderRightCntnt></OrderRightCntnt>
    </OrderRightStyle>
  );
};

export default OrderDetailRightCompShimmer;
