import { ViewOrdersPersuasaionProps } from "libs/ui-widgets/src/lib/types";
import { landingCardShowcaseDataProps } from "../../../mockData";
import { useDevice } from "libs/ui-widgets/src/lib/hooks/useDevice";
import {
  VERSIONS,
  PageNameMapping,
  ElementNameMapping,
} from "libs/ui-widgets/src/lib/constants";
import {
  getElementUniqueIds,
  getCamelCaseStr,
} from "libs/ui-widgets/src/lib/utils";
import { theme } from "../../../css/Global";
import { DocsUploadIcon } from "../../../icons/DocsUploadIcon";
import FlexComp from "../../ui-atoms/FlexComp/FlexComp";
import ImageTag from "../../ui-atoms/ImageTag/ImageTag";
import LandingButton from "../../ui-atoms/LandingButton/LandingButton";
import ParaTag from "../../ui-atoms/TextStyles/ParaTag";
import { PersuasionHandler } from "../Persuasion";

export default function ViewOrdersPersuasion(props: {
  cardData: landingCardShowcaseDataProps;
  persuasionProps: ViewOrdersPersuasaionProps;
  handlers: PersuasionHandler;
}) {
  const device = useDevice();

  if (props.cardData.version !== VERSIONS.V1) {
    return <></>;
  }

  return (
    <FlexComp
      padding="12px"
      borderRadius="8px"
      margin="24px 0px 0px"
      justifyContent="space-between"
      flexDirection={device === "sm" ? "column" : "row"}
      bgColor="linear-gradient(159.48deg, #FFF9ED 4.69%, rgba(255, 239, 236, 0.736176) 27.76%, #F8F9E7 92.13%)"
    >
      {(props.persuasionProps.label || props.persuasionProps.iconSrc) && (
        <FlexComp
          padding="0px"
          alignItems="center"
          justifyContent="space-between"
          margin={device === "sm" ? "0px 0px 16px" : "0px"}
        >
          {props.persuasionProps.label && (
            <ParaTag
              fontWeight="bold"
              color={theme.brownText}
              margin="0px 20px 0px 0px"
            >
              {props.persuasionProps.label}
            </ParaTag>
          )}
          {device === "sm" &&
            (props.persuasionProps.iconSrc ? (
              <ImageTag src={props.persuasionProps.iconSrc} />
            ) : (
              <DocsUploadIcon />
            ))}
        </FlexComp>
      )}
      {props.persuasionProps.btnProps && (
        <LandingButton
          id={getElementUniqueIds(
            PageNameMapping.LISTING_PAGE,
            getCamelCaseStr(props.cardData.label),
            ElementNameMapping.UPLOAD_NOW
          )}
          {...props.persuasionProps.btnProps}
          onClick={
            props.handlers.docsPendingHandler
              ? () => props.handlers.docsPendingHandler(props.cardData)
              : () => {
                  console.log("view orders");
                }
          }
          flexShrink="0"
          customWidth={device === "sm" ? "100%" : undefined}
        />
      )}
    </FlexComp>
  );
}
