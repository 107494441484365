export interface ITravellerDetailsForm {
  primaryContactDetails?: {
    email: string;
    mobile: string;
  };
  travellerDetails?: {
    name: string;
    dob: string;
    gender: string;
    isSelected: boolean;
    isEdited: boolean;
    isSavedTraveller: boolean;
    passport: string;
  }[];
  countryCode?: string;
}

let travellerDetails: ITravellerDetailsForm = {};

export const setTravDetailsInMemory = (value: any) => {
  travellerDetails = value;
};

export const getTravDetailsFromMemory = () => {
  return travellerDetails;
};
