import styled from "styled-components";
import { deviceType } from "../../utils";

interface HeaderContProps {
  bgColor?: string;
  showShadow?: boolean;
}
interface HeaderInnerProps {
  headerType?: "mobile" | "desktop";
}

export const HeaderCont = styled.header<HeaderContProps>`
  background-color: ${(props) => props.bgColor};
  position: fixed;
  top: 0;
  z-index: 11;
  box-shadow: ${(props) =>
    props.showShadow ? "0px 1px 7px rgba(0, 0, 0, 0.21)" : "unset"};
  width: 100%;
`;
export const HeaderInner = styled.div<HeaderInnerProps>`
  width: 1200px;
  padding: ${(props) => (props.headerType === "mobile" ? "16px" : "20px 0")};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${deviceType.mobile} {
    width: 100%;
  }
`;
export const HeaderLft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: 10px;
  }
`;
export const HeaderRht = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;
