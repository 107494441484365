export const ROUTE_CONSTANTS = {
  LANDING_PAGE: "/insurance",
  LISTING_SCREEN: "/insurance/listing",
  TRAVELLER_DETAILS: "/insurance/traveller-details",
  PAYMENT_SUCCESS: "/insurance/paymentSuccess",
  PAYMENT_FAILURE: "/insurance/payment-failure",
  ADD_PASSPORT: "/insurance/add-passport",
  ORDER_CONFIRMED: "/insurance/order-confirmed",
  ORDER_LISTING: "/insurance/order-listing",
  ORDER_DETAILS: "/insurance/order-details",
  EDIT_TRAVELLERS: "/insurance/edit-travellers",
  DOWNLOAD_POLICY: "/insurance/download-policy",
  WEBVIEW_SCREEN: "/insurance/webview-screen",
};
