import { useState, Fragment } from "react";
import {
  CheckButton,
  ParaTag,
  H5Tag,
  ImageTag,
  FlexComp,
  AnchorTag,
} from "@tm/static";

import { theme } from "@tm/utility/global";
import { ChevronUpIcon } from "@tm/static/icons";

import {
  TnCLabel,
  IconCtr,
  ExpandableTnCCardStyle,
} from "./ExpandableTnCCard.style";

interface ExpandableTnCCardProps {
  label?: string;
  subLabel?: string;
  noteLabel?: string;
  dangerousTnCLabel?: string;
  dangerousNoteSubLabel?: string;
  tncCheckbox?: {
    label?: string;
    linkLabel: {
      label: string;
      hrefProp: string;
      onClick: () => void;
    };
    onClick?: () => void;
    isChecked?: boolean;
  };
  conditionLabel?: string;
  iconWhenCollapsed?: string;
}

const ExpandableTnCCard = ({
  label,
  subLabel,
  noteLabel,
  tncCheckbox,
  conditionLabel,
  iconWhenCollapsed,
  dangerousNoteSubLabel,
}: ExpandableTnCCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ExpandableTnCCardStyle isOpen={isOpen}>
      {!!label && <H5Tag>{label}</H5Tag>}
      {!!subLabel && (
        <ParaTag fontSize="sm" margin="8px 0px 0px">
          {subLabel}
        </ParaTag>
      )}
      {isOpen ? (
        <Fragment>
          {!!noteLabel && (
            <ParaTag
              fontSize="sm"
              fontWeight="bold"
              margin="24px 0px 0px"
              color={theme.brownText}
            >
              {noteLabel}
            </ParaTag>
          )}
          {!!dangerousNoteSubLabel && (
            <ParaTag
              fontSize="xs"
              margin="4px 0px 0px"
              color={theme.greyText}
              dangerousText={dangerousNoteSubLabel}
            />
          )}
          {!!tncCheckbox && (
            <TnCLabel onClick={tncCheckbox.onClick}>
              <CheckButton isChecked={tncCheckbox.isChecked} />
              {tncCheckbox.label && (
                <ParaTag fontSize="xs" dangerousText={tncCheckbox.label} />
              )}
              {tncCheckbox.linkLabel && (
                <AnchorTag
                  fontSize="xs"
                  margin="0px 0px 0px 4px"
                  hrefProp={tncCheckbox.linkLabel.hrefProp}
                  handleClick={tncCheckbox.linkLabel.onClick}
                >
                  {tncCheckbox.linkLabel.label}
                </AnchorTag>
              )}
            </TnCLabel>
          )}
        </Fragment>
      ) : (
        <FlexComp
          alignItems="center"
          margin="16px 0px 0px"
          justifyContent="space-between"
        >
          {conditionLabel && (
            <ParaTag fontSize="xs" color={theme.greyText}>
              {conditionLabel}
            </ParaTag>
          )}
          {iconWhenCollapsed ? (
            <ImageTag src={iconWhenCollapsed} />
          ) : (
            <IconCtr onClick={() => setIsOpen(true)}>
              <span>
                <ChevronUpIcon />
              </span>
            </IconCtr>
          )}
        </FlexComp>
      )}
    </ExpandableTnCCardStyle>
  );
};

export default ExpandableTnCCard;
