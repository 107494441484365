import styled from "styled-components";
import { theme } from "@tm/utility/global";
import { deviceType } from "@tm/static/utils";

type PropTypes = {
  showinsDetails?: boolean;
};

export const InsuranceBookedStyle = styled.div`
  background-color: ${theme.neonGreen};
  border-radius: 8px;
  padding: 28px 26px;
  min-height: 295px;
  @media ${deviceType.mobile} {
    min-height: 0;
    padding: 12px 16px;
  }
`;

export const ListingSpan = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${theme.greyText};
  padding-right: 12px;
  margin-right: 12px;
  position: relative;
  flex-shrink: 0;
  &:last-child {
    border: none;
    flex-shrink: unset;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 4px;
    width: 1px;
    height: 14px;
    background: #4a4a4a;
  }
  &:last-child:after {
    width: 0;
    border: none;
    flex-shrink: unset;
  }
  @media ${deviceType.mobile} {
    font-size: 12px;
  }
`;

export const GreenArrowWrap = styled.div<PropTypes>`
  transition: 0.4s ease-in;
  transform-origin: center;
  flex-shrink: 0;
  // transform:rotate(180deg);
  width: 40px;
  height: 40px;
  display: flex;
  ${(p) =>
    p.showinsDetails &&
    `
transform:rotate(180deg);
`}
`;

export const InsuranceBookedPackStyle = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.lightSilver};
  padding-bottom: 8px;
  margin-bottom: 24px;
  @media ${deviceType.mobile} {
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
`;

export const ViewPlanBenefitStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: none;
  margin-bottom: 20px;
  gap: 8px;
  @media ${deviceType.mobile} {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    margin-bottom: 12px;
  }
`;
