import { SpanTag } from "@tm/static";
import React, { useEffect, useState } from "react";
import { CalenderHeaderWrap, CalHeaderBackBtn } from "./CalendarHeader.style";

interface CalenderHdType {
  checkoutDate?: Date;
  checkinDate?: Date;
  onBackPress?: () => void;
}

const CalendarHeader = ({
  checkinDate,
  checkoutDate,
  onBackPress,
}: CalenderHdType) => {
  const [text, setText] = useState("Select Travel Dates");

  useEffect(() => {
    checkinDate && setText("Select Travel Dates");
    checkinDate && checkoutDate && setText("Select Travel Dates");
  }, [checkinDate, checkoutDate]);

  return (
    <CalenderHeaderWrap>
      <CalHeaderBackBtn onClick={onBackPress}></CalHeaderBackBtn>
      <SpanTag fontWeight="bold" fontSize="md">
        {text}
      </SpanTag>
    </CalenderHeaderWrap>
  );
};

export default CalendarHeader;
