import React from "react";

import {
  FlexComp,
  ImageComponent,
  AnchorTag,
  SpanTag,
  FormattedText,
} from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";

import {
  OrderRightStyle,
  OrderRightHd,
  OrderRightCntnt,
} from "./OrderDetailRightComp.style";
import { TextNodeType } from "../FormattedText/FormattedText";

interface OrderGetHelpProp {
  title?: string | TextNodeType;
  info?: string | TextNodeType;
  getHelp?: {
    title?: string | TextNodeType;
    icon?: string;
  }[];
}

const OrderGetHelp = ({ title, info, getHelp }: OrderGetHelpProp) => {
  const device = useBreakpointV2();

  return (
    <OrderRightStyle>
      <OrderRightHd>
        <SpanTag fontWeight="bold" fontSize="md">
          <FormattedText value={title} />
        </SpanTag>
        <SpanTag fontWeight="bold" fontSize="md">
          <FormattedText value={info} />
        </SpanTag>
      </OrderRightHd>
      <OrderRightCntnt>
        <FlexComp
          justifyContent={device == "lg" ? "space-between" : "center"}
          gap={device == "lg" ? "20px" : "42px"}
        >
          {getHelp?.map((item, index) => {
            return (
              <FlexComp
                gap="8px"
                alignItems="center"
                key={index}
                flexDirection="column"
              >
                {item.icon && (
                  <ImageComponent width="27px" height="27px" src={item.icon} />
                )}
                {item.title && (
                  <AnchorTag fontSize="xs">
                    <FormattedText value={item.title} />
                  </AnchorTag>
                )}
              </FlexComp>
            );
          })}
        </FlexComp>
      </OrderRightCntnt>
    </OrderRightStyle>
  );
};

export default OrderGetHelp;
