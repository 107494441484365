import { theme } from "@tm/utility/global";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
export const Cta = styled.a`
  font-size: 14px;
  line-height: 19px;
  color: ${theme.blackText};
  font-weight: 800;
  display: inline-flex;
  align-items: center;
  margin-left: 22px;
  cursor: pointer;
`;

export const GpsIconWpr = styled.div`
  margin: 0 5px 0 0;
`;
