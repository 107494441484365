import styled from "styled-components";

export const AddPassportDetailStyle = styled.div`
  background: #ffedd1;
  border: 1px solid #cf8100;
  border-radius: 16px;
  padding: 24px 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;
