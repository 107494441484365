interface RoundCrossIconProps {
  crossColor?: string;
  bgColor?: string;
  props?: any;
  cursor?: string;
}

export const RoundCrossIcon = ({
  crossColor = "#CF3720",
  bgColor = "#FFEAE7",
  cursor = "pointer",
  props,
}: RoundCrossIconProps) => (
  <svg
    style={{ cursor: cursor }}
    width={32}
    height={33}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={16.3652} r={16} fill={bgColor} />
    <path
      d="M9.84375 10.2109L22.1514 22.5186"
      stroke={crossColor}
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M22.1562 10.2109L9.84856 22.5186"
      stroke={crossColor}
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
