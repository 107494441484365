import { pushEvent } from "@tm/utility/analytics";
import { Image } from "../Image";
import * as S from "./LandingFooter.style";
import {
  LINKS,
  PageNameMapping,
  SectionNameMapping,
  ElementNameMapping,
  getElementUniqueIds,
  getCamelCaseStr,
} from "@tm/utility/constants";

interface ILandingFooter {
  mmtInitiativeLink?: any;
  cityIcon?: any;
  blogIcon?: any;
  quickLinkIcon?: any;
  otherIcon?: any;
  followUsIcon?: any;
  downloadIcon?: any;
  cityTitle?: string;
  blogTitle?: string;
  quickLinkTitle?: string;
  otherTitle?: string;
  cities?: Array<string>;
  blog?: Array<string>;
  quickLinks?: any[];
  others?: Array<string>;
  followUsTitle?: string;
  downloadTitle?: string;
  copyRightTitle?: string;
  contryName?: string;
  twitterIcon?: any;
  fbIcon?: any;
  linkedInIcon?: any;
  playStoreIcon?: any;
  appStoreIcon?: any;
  faqRedirectionUrl?: string;
}
const LandingFooter = ({
  mmtInitiativeLink,
  cityIcon,
  blogIcon,
  quickLinkIcon,
  otherIcon,
  followUsIcon,
  downloadIcon,
  cityTitle,
  blogTitle,
  quickLinkTitle,
  otherTitle,
  cities,
  blog,
  quickLinks,
  others,
  followUsTitle,
  downloadTitle,
  copyRightTitle,
  contryName,
  twitterIcon,
  fbIcon,
  linkedInIcon,
  playStoreIcon,
  appStoreIcon,
  faqRedirectionUrl,
}: ILandingFooter) => {
  const onOtherLinkHandler = ({ linkEvent }: any) => {
    if (linkEvent) pushEvent(linkEvent);
  };
  return (
    <S.LandingFooter>
      <S.LandingFooterWrap>
        <S.LandingFooterImg>
          <Image {...mmtInitiativeLink} />
        </S.LandingFooterImg>
        {cityIcon && cityTitle && (
          <S.LandingFooterSub>
            <S.IconMargin>
              <Image {...cityIcon} />
            </S.IconMargin>
            {cityTitle}
          </S.LandingFooterSub>
        )}

        {cities && (
          <S.LandingFooterList>
            {cities?.map(({ text, linkEvent }: any, index: number) => {
              return (
                <li key={index}>
                  <a
                    aria-label="others"
                    href="#"
                    onClick={() => onOtherLinkHandler({ linkEvent })}
                  >
                    {text}
                  </a>
                </li>
              );
            })}
          </S.LandingFooterList>
        )}

        {blogIcon && (
          <S.LandingFooterSub>
            <S.IconMargin>
              <Image {...blogIcon} />
            </S.IconMargin>
            {blogTitle}
          </S.LandingFooterSub>
        )}

        {blog && (
          <S.LandingFooterList>
            {blog?.map(({ text, linkEvent }: any, index: number) => {
              return (
                <li key={index}>
                  <a
                    aria-label="others"
                    href="#"
                    onClick={() => onOtherLinkHandler({ linkEvent })}
                  >
                    {text}
                  </a>
                </li>
              );
            })}
          </S.LandingFooterList>
        )}
        {quickLinkTitle && (
          <S.LandingFooterSub>
            <S.IconMargin>
              <Image {...quickLinkIcon} />
            </S.IconMargin>
            {quickLinkTitle}
          </S.LandingFooterSub>
        )}

        {quickLinks && (
          <S.LandingFooterList>
            {quickLinks.map(
              (
                { text, linkEvent, href, target = "_self" }: any,
                index: number
              ) => {
                href = text === "FAQ" ? faqRedirectionUrl : href;
                return (
                  <li key={index}>
                    <a
                      id={getElementUniqueIds(
                        PageNameMapping.LISTING_PAGE,
                        SectionNameMapping.FOOTER,
                        getCamelCaseStr(text)
                      )}
                      aria-label="others"
                      target={target}
                      href={href}
                      onClick={() => onOtherLinkHandler({ linkEvent })}
                    >
                      {text}
                    </a>
                  </li>
                );
              }
            )}
          </S.LandingFooterList>
        )}

        {otherIcon && (
          <S.LandingFooterSub>
            <S.IconMargin>
              <Image {...otherIcon} />
            </S.IconMargin>
            {otherTitle}
          </S.LandingFooterSub>
        )}
        {others && (
          <S.LandingFooterList>
            {others.map(({ text, linkEvent }: any, index: number) => {
              return (
                <li key={index}>
                  <a
                    aria-label="others"
                    href="#"
                    onClick={() => onOtherLinkHandler({ linkEvent })}
                  >
                    {text}
                  </a>
                </li>
              );
            })}
          </S.LandingFooterList>
        )}

        <S.LandingFooterFlex>
          <div>
            <S.LandingFooterSub>
              <S.IconMargin>
                <Image {...followUsIcon} />
              </S.IconMargin>
              {followUsTitle}
            </S.LandingFooterSub>
            <S.Flex>
              <a
                id={getElementUniqueIds(
                  PageNameMapping.LISTING_PAGE,
                  SectionNameMapping.FOOTER,
                  ElementNameMapping.TWITTER_Link
                )}
                aria-label="twitter"
                href={twitterIcon?.redirectionUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  onOtherLinkHandler({
                    linkEvent: twitterIcon?.linkEvent,
                  })
                }
              >
                <S.IconMargin>
                  <Image {...twitterIcon?.iconProps} />
                </S.IconMargin>
              </a>
              <a
                id={getElementUniqueIds(
                  PageNameMapping.LISTING_PAGE,
                  SectionNameMapping.FOOTER,
                  ElementNameMapping.FB_Link
                )}
                aria-label="facebook"
                href={fbIcon?.redirectionUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  onOtherLinkHandler({
                    linkEvent: twitterIcon?.linkEvent,
                  })
                }
              >
                <S.IconMargin>
                  <Image {...fbIcon?.iconProps} />
                </S.IconMargin>
              </a>
              <a
                id={getElementUniqueIds(
                  PageNameMapping.LISTING_PAGE,
                  SectionNameMapping.FOOTER,
                  ElementNameMapping.LINKEDIN_Link
                )}
                aria-label="linkedin"
                href={linkedInIcon?.redirectionUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  onOtherLinkHandler({
                    linkEvent: twitterIcon?.linkEvent,
                  })
                }
              >
                <S.IconMargin>
                  <Image {...linkedInIcon?.iconProps} />
                </S.IconMargin>
              </a>
            </S.Flex>
          </div>
          <div>
            <S.LandingFooterSub>
              <S.IconMargin>
                <Image {...downloadIcon} />
              </S.IconMargin>
              {downloadTitle}
            </S.LandingFooterSub>

            <S.Flex>
              <a aria-label="googlePlay" href={playStoreIcon?.redirectionUrl}>
                <S.IconMargin>
                  <Image {...playStoreIcon} />
                </S.IconMargin>
              </a>
              <a aria-label="appleStore" href={appStoreIcon?.redirectionUrl}>
                <Image {...appStoreIcon} />
              </a>
            </S.Flex>
          </div>
          {copyRightTitle && (
            <S.LandingFooterCopyRight>
              <span> {copyRightTitle}</span>
              <span>{contryName}</span>
            </S.LandingFooterCopyRight>
          )}
        </S.LandingFooterFlex>
      </S.LandingFooterWrap>
    </S.LandingFooter>
  );
};

export default LandingFooter;
