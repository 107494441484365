import {
  ParaTag,
  FlexComp,
  AnchorTag,
  ImageTag,
  SpanTag,
  Spinner,
  ToolTip,
  Toast,
} from "@tm/static";
import { FlightInfoWpr } from "./AddOrSelectTicket.style";
import Swap from "libs/ui/src/lib/static/icons/Swap.svg";
import ArrowRight from "libs/ui/src/lib/static/icons/ArrowRight.svg";
import { useBreakpoint } from "@tm/ui/hooks";
import { theme } from "@tm/utility/global";
import { FlightInfoProps } from "./type";
import { useState } from "react";
import Warning from "@tm/static/icons/Warning.svg";
import { AddOrSelectDocData } from "./utils";

const FlightInfo = ({
  ticket,
  disabledText,
  onSelectClick = () => {},
  selectedTicketCount,
}: FlightInfoProps) => {
  const device = useBreakpoint();
  const [showToast, setShowToast] = useState(false);

  return (
    <FlightInfoWpr>
      <FlexComp
        alignItems="center"
        justifyContent="space-between"
        margin="0 0 4px 0"
      >
        <FlexComp alignItems="center">
          <SpanTag fontSize="sm" fontWeight="bold">
            {ticket?.from}
          </SpanTag>
          <ImageTag
            width="14px"
            height="11px"
            src={ticket?.isRoundTrip ? Swap.src : ArrowRight.src}
            margin="0 4px 0"
          />
          <SpanTag fontSize="sm" fontWeight="bold">
            {ticket?.to}
          </SpanTag>
          {ticket?.viaCities && (
            <SpanTag color={theme.greyText}>
              {"\u00A0via\u00A0"}
              {ticket?.viaCities}
            </SpanTag>
          )}
        </FlexComp>
        {ticket?.isLoading ? (
          <Spinner customStyle={{ margin: 0 }} />
        ) : (
          <AnchorTag
            fontWeight="bold"
            fontSize="xs"
            handleClick={() => onSelectClick(ticket?.id)}
            color={disabledText ? "rgba(0, 0, 0, 0.3)" : ""}
          >
            SELECT
          </AnchorTag>
        )}
      </FlexComp>
      <ParaTag color={theme.greyText} fontSize="xs" margin="0 0 4px 0">
        {ticket?.time}
      </ParaTag>
      <AnchorTag
        color={theme.greyText}
        fontSize="xs"
        handleClick={() => setShowToast(true)}
        style={{ textDecoration: "underline" }}
      >
        {ticket?.fileName}
      </AnchorTag>
      {ticket?.showTooltip && !disabledText && selectedTicketCount > 0 && (
        <ToolTip
          closeTooltip={() => {}}
          arrowPosition="90%"
          style={{
            width: "307px",
            borderRadius: "12px",
            padding: "4px 8px",
            right: 0,
            zIndex: 1,
            marginTop: "3px",
          }}
          arrowDirection="up"
          bgColor={theme.greenText3}
        >
          <ParaTag color={theme.whiteText} fontWeight="regular">
            {ticket?.tooltipMsg}
          </ParaTag>
        </ToolTip>
      )}
      {showToast && (
        <Toast
          planSelectedTxt={AddOrSelectDocData.toastMsg2}
          toastIcon={Warning.src}
          onTimerEnd={() => setShowToast(false)}
        />
      )}
    </FlightInfoWpr>
  );
};

export default FlightInfo;
