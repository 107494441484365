import { theme } from "../../css/Global";
import { BulletListItemStyle } from "./BulletListItem.style";

interface BulletListItemProps {
  bulletColor?: string;
  children: string | React.ReactNode;
}

const BulletListItem = ({
  children,
  bulletColor = theme.darkYellowText,
}: BulletListItemProps) => {
  return (
    <BulletListItemStyle bulletColor={bulletColor}>
      {children}
    </BulletListItemStyle>
  );
};

export default BulletListItem;
