import { theme } from "@tm/utility/global";
import styled from "styled-components";

interface ToggleBtnProps {
  locked?: boolean;
}

export const ToggleBtnWrapper = styled.div<ToggleBtnProps>`
  background: ${theme.whiteText};
  border: 2px solid rgba(0, 0, 0, 0.12);
  border-radius: 35px;
  padding: 2px;
  width: 52px;
  display: flex;
  ${(p) =>
    p.locked &&
    `
    background: ${theme.blueText};
    border: 2px solid ${theme.blueText};
`}
  cursor: pointer;
`;

export const ToggleButton = styled.div<ToggleBtnProps>`
  background: ${theme.lightBlack};
  border-radius: 23px;
  width: 24px;
  height: 24px;
  transition: all 0.3s linear;
  ${(p) =>
    p.locked &&
    `
  margin-left:20px;
  background:${theme.whiteText};
`}
  ${(p) =>
    !p.locked &&
    `
  margin-right:auto;
`}
`;
