import * as React from "react";
interface iconProps {
  color?: string;
}
const WhiteChev = ({ color = "#fff" }, props: iconProps) => (
  <svg
    width={9}
    height={6}
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 1.0666L4.96642 5L9 1" stroke={color} />
  </svg>
);
export { WhiteChev };
