import React from "react";
import { TravAssistWraning } from "./InsurancePlans.style";
import { insurancePlanCompType } from "./type";
import { theme } from "@tm/utility/global";
import {
  FlexComp,
  CommonModalDweb,
  ParaTag,
  InsPlanModalCommonInfo,
  ImageComponent,
  FormattedText,
} from "@tm/static";
import { TextNodeType } from "../FormattedText/FormattedText";

interface BenefitsInterface {
  id: string;
  icon: string;
  title: TextNodeType;
  info: TextNodeType;
  price: TextNodeType;
}

const TravelAssistanceModal = ({
  travelAssistTitle,
  data,
  handleTravAssist,
}: insurancePlanCompType) => {
  const travelAssistanceCover = () => {
    for (let i in data?.travelAssistanceCovers) {
      return data?.travelAssistanceCovers?.[i]?.map((item: any, index: any) => {
        return (
          <FlexComp
            alignItems="flex-start"
            key={index}
            margin="0 0 20px"
            gap="20px"
          >
            {item.icon && <ImageComponent src={item.icon} />}
            <div>
              <ParaTag margin="0 0 8px" fontSize="sm" fontWeight="bold">
                {item.title && <FormattedText value={item.title} />}
              </ParaTag>
              <ParaTag fontSize="xs" color={theme.greyText7}>
                {item.info && <FormattedText value={item.info} />}
              </ParaTag>
            </div>
          </FlexComp>
        );
      });
    }
    return;
  };

  return (
    <CommonModalDweb
      handleClick={
        handleTravAssist
          ? () => handleTravAssist(parseInt(data?.productId) ?? 0)
          : () => {}
      }
      customTitle={travelAssistTitle}
    >
      <InsPlanModalCommonInfo data={data} />
      {travelAssistanceCover()}
      {data?.travelAssistanceWarning && (
        <TravAssistWraning>
          {data.travelAssistanceWarning.icon && (
            <ImageComponent src={data.travelAssistanceWarning.icon} />
          )}
          <div>
            {data.travelAssistanceWarning.title && (
              <ParaTag
                margin="0 0 8px"
                fontSize="large"
                fontWeight="bold"
                color={theme.yellowText}
              >
                <FormattedText value={data.travelAssistanceWarning.title} />
              </ParaTag>
            )}
            {
              <ParaTag color={theme.greyText} fontSize="md">
                {data?.travelAssistanceWarning.info && (
                  <FormattedText value={data?.travelAssistanceWarning.info} />
                )}
              </ParaTag>
            }
          </div>
        </TravAssistWraning>
      )}
    </CommonModalDweb>
  );
};

export default TravelAssistanceModal;
