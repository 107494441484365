import styled from "styled-components";

type FlexStyleProps = {
  alignItems?: string;
  isCursorPointer?: boolean;
  flexDirection?: string;
  justifyContent?: string;
  gap?: string;
  flex?: string;
  flexShrink?: string;
  boxShadow?: string;
  width?: string;
  height?: string;
  borderBottom?: string;
  borderTop?: string;
  bgColor?: string;
  margin?: string;
  padding?: string;
  borderRadius?: string;
  flexWrap?: string;
  alignSelf?: string;
};

const renderAlign = (alignItems: string) => {
  switch (alignItems) {
    case "center":
      return `align-items: center;`;
    case "baseline":
      return `align-items: baseline;`;
    case "flex-start":
      return `align-items:flex-start;`;
    case "flex-end":
      return `align-items:flex-end;`;
    default:
      return `align-items:center;`;
  }
};
const renderAlignSelf = (alignSelf: string) => {
  switch (alignSelf) {
    case "center":
      return `align-self: center;`;
    case "baseline":
      return `align-self: baseline;`;
    case "flex-start":
      return `align-self:flex-start;`;
    case "flex-end":
      return `align-self:flex-end;`;
    default:
      return `align-self:unset;`;
  }
};

const renderJustify = (justifyContent: string) => {
  switch (justifyContent) {
    case "center":
      return `justify-content:center;`;
    case "flex-start":
      return `justify-content:flex-start;`;
    case "end":
      return `justify-content:end;`;
    case "space-between":
      return `justify-content:space-between;`;
    case "inherit":
      return `justify-content:inherit;`;
    case "right":
      return `justify-content:right;`;
    default:
      return `justify-content:center;`;
  }
};

const renderDir = (flexDirection: string) => {
  switch (flexDirection) {
    case "row":
      return `flex-direction:row;`;
    case "column":
      return `flex-direction:column;`;
    case "row-reverse":
      return `flex-direction:row-reverse;`;
    case "column-reverse":
      return `flex-direction:column-reverse;`;
    default:
      return `flex-direction:row;`;
  }
};

export const FlexCompStyle = styled.div<FlexStyleProps>`
  display: flex;
  position: relative;
  cursor: ${(props) => (props.isCursorPointer ? "pointer" : "default")};
  ${(p) =>
    p.alignItems &&
    `
    ${renderAlign(p.alignItems)}
  `}
  ${(p) =>
    p.alignSelf &&
    `
    ${renderAlignSelf(p.alignSelf)}
  `}
    ${(p) =>
    p.justifyContent &&
    `
    ${renderJustify(p.justifyContent)}
  `}
  ${(p) =>
    p.flexDirection &&
    `
    ${renderDir(p.flexDirection)}
  `}
    gap: ${(p) => (p.gap ? p.gap : "0")};
  flex: ${(p) => (p.flex ? p.flex : "unset")};
  flex-shrink: ${(p) => (p.flexShrink ? p.flexShrink : "unset")};
  margin: ${(p) => (p.margin ? p.margin : "unset")};
  padding: ${(p) => (p.padding ? p.padding : "0")};
  border-bottom: ${(p) => (p.borderBottom ? p.borderBottom : "none")};
  border-top: ${(p) => (p.borderTop ? p.borderTop : "none")};
  background: ${(p) => (p.bgColor ? p.bgColor : "transparent")};
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : "0")};
  height: ${(p) => (p.height ? p.height : "unset")};
  width: ${(p) => (p.width ? p.width : "unset")};
  box-shadow: ${(p) => (p.boxShadow ? p.boxShadow : "none")};
  pointer-events: auto;
  flex-wrap: ${(p) => (p.flexWrap ? p.flexWrap : "")};
`;
