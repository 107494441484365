import React, { useState } from "react";
import { FlexComp, FormattedText, ImageComponent, SpanTag } from "@tm/static";
import {
  EmptyBorder,
  GreyChevWrap,
  BenefitName,
  BenefitPrice,
} from "./InsurancePlans.style";
import { Benefits } from "./type";
import { theme } from "@tm/utility/global";
import GreyBgChev from "../../icons/GreyBgChev.svg";
import { useBreakpointV2 } from "@tm/ui-widgets";

const BenefitItem = ({ data, benefitName, isAddOnBenefits }: Benefits) => {
  const device = useBreakpointV2();
  const [benefitOpen, setBenefit] = useState(true);

  const handleBenefit = () => {
    setBenefit(!benefitOpen);
  };
  return (
    <React.Fragment>
      <FlexComp
        alignItems="center"
        justifyContent="space-between"
        gap={device == "lg" ? "24px" : "16px"}
        handleClick={handleBenefit}
      >
        {benefitName && isAddOnBenefits ? (
          <BenefitName
            color={device == "lg" ? theme.greyText : theme.greyText7}
          >
            {benefitName}
          </BenefitName>
        ) : (
          <BenefitName
            color={device == "lg" ? theme.greyText : theme.greyText7}
          >
            {data && data.length} &nbsp;
            {benefitName}
          </BenefitName>
        )}
        <EmptyBorder></EmptyBorder>
        {!isAddOnBenefits && (
          <GreyChevWrap chevOpen={benefitOpen}>
            <ImageComponent
              width="32px"
              height="32px"
              src="https://tripmoneycmsimgak.mmtcdn.com/img/Grey_Bg_Chev_81a870a4f8.svg"
            />
          </GreyChevWrap>
        )}
      </FlexComp>
      {benefitOpen &&
        data &&
        data?.map((item2, index2) => {
          return (
            <FlexComp alignItems="flex-start" key={index2} gap="20px">
              {item2.icon && (
                <ImageComponent
                  src={item2.icon}
                  height={device == "lg" ? "30" : "20px"}
                  width={device == "lg" ? "30" : "20px"}
                />
              )}
              <FlexComp width="100%" flexDirection="column">
                <FlexComp justifyContent="space-between" gap="8px">
                  {item2.title && (
                    <SpanTag
                      fontSize="sm"
                      fontWeight="black"
                      color={theme.blackText}
                    >
                      <FormattedText value={item2.title} />
                    </SpanTag>
                  )}
                  {item2.price && (
                    <BenefitPrice
                      txtfontSize="xs"
                      txtcolor={theme.greenText}
                      txtfontWeight="bold"
                    >
                      <FormattedText value={item2.price} />
                    </BenefitPrice>
                  )}
                </FlexComp>
                {item2.info && (
                  <SpanTag fontSize="xs" color={theme.greyText}>
                    <FormattedText value={item2.info} />
                  </SpanTag>
                )}
              </FlexComp>
            </FlexComp>
          );
        })}
    </React.Fragment>
  );
};

export default BenefitItem;
