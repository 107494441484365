import React, { forwardRef } from "react";
import {
  ShimmerWrap,
  BtnMainIcon,
  LandingButtonStyle,
} from "./LandingButton.style";
import { BtnProp } from "./ButtonType";

const LandingButton = forwardRef<HTMLButtonElement, BtnProp>(
  (
    {
      customTitle,
      margin,
      customWidth,
      disableState,
      onClick,
      flexShrink,
      btnIcon,
      background,
      color,
      customHeight,
      shimmerBtn,
      flex,
      border,
      btnMainIcon,
      fontSize,
      padding,
      id,
    },
    ref
  ) => {
    return (
      <LandingButtonStyle
        ref={ref}
        id={id}
        btnIcon={btnIcon}
        onClick={onClick}
        style={{
          "--btnBg": background,
          "--btnColor": color,
          "--customWidth": customWidth,
          "--btnMargin": margin,
          "--customHeight": customHeight,
          "--btnFlexShrink": flexShrink,
          "--btnFlex": flex,
          "--btnBorder": border,
          "--btnFont": fontSize,
          "--btnPadding": padding,
        }}
        disableState={disableState}
      >
        <BtnMainIcon>
          {btnMainIcon}
          {customTitle}
        </BtnMainIcon>
        {btnIcon && btnIcon}
        {shimmerBtn && (
          <ShimmerWrap>
            <img src="../../../images/shimmerBtn.svg" />
          </ShimmerWrap>
        )}
      </LandingButtonStyle>
    );
  }
);

export default LandingButton;
