import styled from "styled-components";
import { FlexCompStyle } from "../FlexComp/FlexComp.style";
import { LandingButtonStyle } from "../LandingButton/LandingButton.style";
import { AnchorTagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "@tm/static/utils";

type PassportNumType = {
  itemIndex?: boolean;
};

export const PassportNumCompStyle = styled.div`
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.16);
  flex: 1;
  display: flex;
  flex-direction: column;
  @media ${deviceType.mobile} {
    border-radius: 16px 16px 0 0;
  }
`;

export const PassportHd = styled.div`
  padding: 30px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media ${deviceType.mobile} {
    padding: 24px 16px;
  }
`;

export const PassportItem = styled.div<PassportNumType>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: ${(p) => (p.itemIndex ? "none" : "1px solid #e8e8e8")};
  padding: 16px 30px;
  gap: 8px;
  @media ${deviceType.mobile} {
    grid-template-columns: repeat(1, 1fr);
    padding: 16px;
  }
`;

export const ButtonWrap = styled(FlexCompStyle)`
  display: flex;
  align-items: center;
  padding: 30px;
  margin-top: auto;
  ${LandingButtonStyle} {
    flex: 1;
  }
  ${FlexCompStyle} {
    flex: 1;
    text-align: center;
  }
  @media ${deviceType.mobile} {
    padding: 24px 16px;
  }
`;
