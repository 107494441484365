export default function Banner({ currencyData }: any) {
  if (!currencyData.offer_banner_dt && !currencyData.offer_banner_mob)
    return null;
  return (
    <div
      className="offer_banner"
      onClick={() => {
        if (currencyData?.offer_link)
          window.open(currencyData?.offer_link, "_blank");
      }}
    >
      <img
        className="offer_bannerDt"
        src={currencyData.offer_banner_dt}
        alt="banner"
        width={"100%"}
        height={253}
      />
      <img
        className="offer_bannerMobile"
        src={currencyData.offer_banner_mob}
        alt="banner"
        width={"100%"}
        height={340}
      />
    </div>
  );
}
