import React from "react";
import { FlexComp, H5Tag, ImageComponent, ParaTag, SpanTag } from "@tm/static";
import { SafenSecureTravCrdStyle, FeatList } from "./SafenSecureTrav.style";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import { useBreakpointV2 } from "@tm/ui-widgets";

interface SafenSecureTravCrdProps {
  title?: TextNodeType;
  featList?: TextNodeType[];
  poweredBy?: string[];
  cardImg?: string;
}

const SafenSecureTravCrd = ({
  title,
  featList,
  cardImg,
  poweredBy,
}: SafenSecureTravCrdProps) => {
  const device = useBreakpointV2();
  return (
    <SafenSecureTravCrdStyle>
      {device == "lg" ? (
        <H5Tag>{title && <FormattedText value={title} />}</H5Tag>
      ) : (
        <ParaTag
          fontWeight="black"
          fontSize="large"
          margin={device == "sm" ? "" : "0 0 12px"}
        >
          {title && <FormattedText value={title} />}
        </ParaTag>
      )}
      <FeatList>
        {featList &&
          featList?.map((item: TextNodeType, index: number) => {
            return (
              <li key={index}>
                <FormattedText value={item} />
              </li>
            );
          })}
      </FeatList>
      {poweredBy && (
        <FlexComp margin="auto 0 0" gap="20px" alignItems="Center">
          <SpanTag fontSize="xs">Powered By</SpanTag>
          {poweredBy &&
            poweredBy.map((item, index) => {
              return (
                <ImageComponent
                  key={index}
                  src={item}
                  width={"32px"}
                  height={"22px"}
                />
              );
            })}
        </FlexComp>
      )}
      {cardImg && (
        <ImageComponent
          width={device == "lg" ? "92px" : "54px"}
          height={device == "lg" ? "102px" : "60px"}
          src={cardImg}
        />
      )}
    </SafenSecureTravCrdStyle>
  );
};

export default SafenSecureTravCrd;
