import React from "react";

import { H6Tag, FlexComp, SpanTag, FormattedText } from "@tm/static";
import { theme } from "@tm/utility/global";

import { DATE_FORMATS } from "@tm/insurance/constants";
import { formatDate, convertUnixToDate } from "@tm/insurance/utils";

import { ListingSpan } from "./InsuranceBooked.style";
import { InsuranceBookedProps } from "./type";
import { useBreakpoint } from "../../../hooks/useBreakpoint";

const InsuranceBookedTravel = ({ selectedPlanData }: InsuranceBookedProps) => {
  const device = useBreakpoint();
  const { countryTitle, travelDate, travellerCount, travelPurpose } =
    selectedPlanData || {};
  const { startDate, endDate } = travelDate || {};

  console.log({ travelPurpose, travellerCount });

  return (
    <React.Fragment>
      <H6Tag color={theme.greyText}>
        <FormattedText value={countryTitle} />
      </H6Tag>
      <FlexComp margin={device == "lg" ? "8px 0 0" : ""}>
        {startDate && endDate && (
          <ListingSpan color={theme.greyText}>
            <SpanTag color={theme.greyText} fontSize="md" fontWeight="bold">
              {formatDate(startDate, DATE_FORMATS["D MMM"])}'
            </SpanTag>
            {formatDate(startDate, DATE_FORMATS.YY)} -To-{" "}
            <SpanTag color={theme.greyText} fontSize="md" fontWeight="bold">
              {formatDate(endDate, DATE_FORMATS["D MMM"])}'
            </SpanTag>
            {formatDate(endDate, DATE_FORMATS.YY)}
          </ListingSpan>
        )}
        {travellerCount && (
          <ListingSpan color={theme.greyText}>
            <FormattedText value={travellerCount} />
          </ListingSpan>
        )}
        {/* {travelPurpose && (
                    <ListingSpan color={theme.greyText}>
                        <FormattedText value={travelPurpose} />
                    </ListingSpan>
                )} */}
      </FlexComp>
    </React.Fragment>
  );
};

export default InsuranceBookedTravel;
