import * as React from "react";
interface iconProps {}
const GreyBgChev = (props: iconProps) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={16}
      cy={16}
      r={16}
      transform="matrix(1 0 0 -1 0 32)"
      fill="#F4F4F4"
    />
    <path
      d="M15.8585 17.3941C15.9366 17.4722 16.0633 17.4722 16.1414 17.3941L20.2426 13.2929C20.6331 12.9024 21.2663 12.9024 21.6568 13.2929C22.0473 13.6834 22.0473 14.3166 21.6568 14.7071L16.7071 19.6569C16.3165 20.0474 15.6834 20.0474 15.2928 19.6569L10.3431 14.7071C9.95257 14.3166 9.95257 13.6834 10.3431 13.2929C10.7336 12.9024 11.3668 12.9024 11.7573 13.2929L15.8585 17.3941Z"
      fill="#008CFF"
    />
  </svg>
);
export { GreyBgChev };
