interface TMLogoProps {}
const TMBlueLogo = (props: TMLogoProps) => (
  <svg
    width={115}
    height={32}
    viewBox="0 0 115 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.33961 8.46034V14.6648C5.33961 15.2289 5.52762 15.4169 5.64043 15.4921C5.86605 15.6049 6.12927 15.6801 6.46769 15.6801C6.73091 15.6801 7.52057 15.4545 7.97181 15.2665L8.46064 15.116V17.485L8.27263 17.5978C7.59578 17.9363 6.88132 18.1243 6.12927 18.1243C3.19624 18.1243 2.55699 16.3569 2.55699 14.8528V8.46034H0L5.33961 3.08313V6.01615H8.49825V8.46034H5.33961Z"
      fill="#124680"
    />
    <path
      d="M17.2979 5.79099V8.42319H16.7338C15.9442 8.42319 15.1921 8.5736 14.4776 8.87442C13.8384 9.17524 13.3496 9.55127 12.9735 10.0401V17.8239H10.2285V6.0166H12.2215L12.7103 7.44551C13.7632 6.35503 15.1169 5.82859 16.6962 5.82859H17.2979V5.79099Z"
      fill="#124680"
    />
    <path
      d="M21.6215 2.93303C21.6215 3.72269 20.9822 4.36194 20.1926 4.36194C19.4029 4.36194 18.7637 3.72269 18.7637 2.93303C18.7637 2.14337 19.4029 1.50412 20.1926 1.50412C20.9446 1.50412 21.6215 2.14337 21.6215 2.93303Z"
      fill="#124680"
    />
    <path d="M21.5087 6.01645H18.7637V17.899H21.5087V6.01645Z" fill="#124680" />
    <path
      d="M34.3666 7.44564C33.2385 6.31755 31.9224 5.75351 30.4183 5.75351C28.9142 5.75351 27.4853 6.65598 26.8461 7.14482L26.282 6.01673H24.2891V22.9004H27.0341V16.9216C28.0117 17.7112 29.1398 18.1249 30.3807 18.1249C31.8472 18.1249 33.1257 17.5984 34.2162 16.5455L34.329 16.4327C35.4571 15.3046 36.0212 13.8005 36.0212 11.958C36.0588 10.0778 35.4947 8.57372 34.3666 7.44564ZM30.1175 15.4927C28.9518 15.4927 27.9365 15.0414 27.0341 14.1013V9.77702C27.9365 8.83694 28.9142 8.38571 30.1175 8.38571C31.0952 8.38571 31.8472 8.68653 32.4489 9.28818C33.0505 9.88982 33.3138 10.7923 33.3138 11.9204C33.3138 13.0485 33.0129 13.9509 32.4489 14.5526C31.8472 15.1918 31.0952 15.4927 30.1175 15.4927Z"
      fill="#124680"
    />
    <path
      d="M74.1152 7.48311C73.0247 6.35503 71.5582 5.79099 69.7909 5.79099C68.0235 5.79099 66.557 6.35503 65.4665 7.48311C64.3761 8.6112 63.8496 10.1153 63.8496 11.9203C63.8496 13.7628 64.4137 15.2293 65.4665 16.3574C66.557 17.4855 68.0235 18.0495 69.7909 18.0495C71.5582 18.0495 73.0247 17.4855 74.1152 16.3574C75.2057 15.2293 75.7697 13.7252 75.7697 11.9203C75.7321 10.1153 75.1681 8.6112 74.1152 7.48311ZM69.7909 15.5301C68.926 15.5301 68.1739 15.2293 67.5347 14.6277C66.8954 14.0636 66.557 13.1235 66.557 11.9203C66.557 10.717 66.8954 9.81449 67.4971 9.21285C68.1363 8.6112 68.8884 8.31038 69.7533 8.31038C70.6181 8.31038 71.3702 8.6112 72.0094 9.21285C72.6487 9.77689 72.9495 10.6794 72.9495 11.9203C72.9495 13.1235 72.6111 14.0636 72.0094 14.6277C71.4078 15.2293 70.6557 15.5301 69.7909 15.5301Z"
      fill="#124680"
    />
    <path
      d="M88.4434 10.4161V17.8615H85.6984V10.6418C85.6984 9.06243 85.0591 8.34798 83.6678 8.34798C82.5397 8.34798 81.4869 8.98723 80.4716 10.1905V17.8615H77.7266V6.0166H79.7195L80.246 7.44551C81.4116 6.35503 82.6901 5.79099 84.1566 5.79099C85.4727 5.79099 86.5256 6.24222 87.3153 7.14469C88.0674 8.00955 88.4434 9.13764 88.4434 10.4161Z"
      fill="#124680"
    />
    <path
      d="M100.211 14.4398C99.158 15.1166 98.0299 15.455 96.8266 15.455C95.6233 15.455 94.7208 15.1918 94.1568 14.703C93.6679 14.2894 93.3671 13.7253 93.2167 12.9733H101.565V11.5067C101.489 9.96503 101.038 8.64893 100.173 7.55845C99.2332 6.35516 97.8042 5.75351 95.9993 5.75351C94.1944 5.75351 92.8031 6.35516 91.8254 7.55845C90.8853 8.76174 90.3965 10.2659 90.3965 12.0708C90.3965 13.8757 90.9229 15.3422 92.0134 16.4327C93.0663 17.5232 94.4952 18.0496 96.2625 18.0496C97.9547 18.0496 99.4964 17.6736 100.775 16.9592L100.925 16.9216L100.775 14.1013L100.211 14.4398ZM93.1791 10.6419C93.2919 9.88982 93.5927 9.32578 94.044 8.94975C94.608 8.46092 95.2473 8.2353 95.9617 8.2353C96.6762 8.2353 97.3154 8.46092 97.8795 8.94975C98.3683 9.36338 98.6691 9.92743 98.7443 10.6419H93.1791Z"
      fill="#124680"
    />
    <path
      d="M114.199 6.01648L114.011 6.50532L107.205 22.9002H104.385L106.416 17.6734L101.678 6.01648H104.611L107.882 14.2515L111.266 6.01648H114.199Z"
      fill="#124680"
    />
    <path
      d="M38 6.09835C38 2.40238 40.4024 0 44.0983 0H55.9716C59.6676 0 62.07 2.40238 62.07 6.09835V17.9717C62.07 21.6676 59.6676 24.07 55.9716 24.07H44.0983C40.4024 24.07 38 21.6676 38 17.9717V6.09835Z"
      fill="#ED1C24"
    />
    <mask
      id="mask0_41_41366"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={38}
      y={0}
      width={25}
      height={25}
    >
      <path
        d="M38 6.09835C38 2.40238 40.4024 0 44.0983 0H55.9716C59.6676 0 62.07 2.40238 62.07 6.09835V17.9717C62.07 21.6676 59.6676 24.07 55.9716 24.07H44.0983C40.4024 24.07 38 21.6676 38 17.9717V6.09835Z"
        fill="#ED1C24"
      />
    </mask>
    <g mask="url(#mask0_41_41366)">
      <path
        d="M54.8275 11.8541C54.595 12.9214 54.3921 15.7951 54.3648 16.4839C54.3375 17.1727 54.5614 17.5955 55.112 17.8681C55.583 18.1013 56.0273 18.0952 56.4557 17.7906C56.6163 17.6764 56.3713 15.8541 56.4557 14.6179C56.631 12.0489 57.8308 8.16922 57.8308 8.16922C57.8533 8.03564 57.8769 7.90226 57.8769 7.79098C57.8769 7.12387 57.5506 6.70142 56.8521 6.63473C56.1306 6.56784 55.7572 6.9017 55.1058 7.65759C53.6611 9.36966 51.9373 11.9272 51.1225 13.1061C51.0299 13.2387 50.8664 13.1947 50.8664 13.0168C50.8664 12.4832 52.8078 6.7918 52.8078 6.7918C52.8078 6.7918 53.0364 6.01202 52.3702 5.74207C52.3702 5.74207 51.37 5.14165 50.5216 6.33194L46.5828 11.4741C46.2736 11.8677 46.1202 11.722 46.1202 11.722C46.024 11.6752 46.086 11.3819 46.086 11.3819L46.7071 8.02987C47.2042 5.45281 46.2572 5.31724 45.6652 5.24119C45.0735 5.16514 44.3631 5.59057 44.3631 5.59057C43.1318 6.18064 42.2027 8.52836 42.2027 8.52836C42.2027 8.52836 41.9244 9.36369 42.5109 9.6858C42.5109 9.6858 42.7338 9.86258 43.1664 9.84745C44.0263 9.81719 44.2052 8.25204 44.8341 8.25204C44.9511 8.25204 44.9511 8.38542 44.9511 8.40772L42.9171 16.5798C42.9171 16.5798 42.684 17.3891 43.4256 17.8221C44.3739 18.3755 44.988 17.4556 44.988 17.4556L45.6992 16.3579C46.7853 14.5415 48.7536 11.9378 48.963 11.9378C49.033 11.9378 49.056 11.9607 49.056 12.0263L48.3606 16.8154C48.3606 16.8154 48.2105 17.6081 48.9448 17.8681C48.9448 17.8681 49.6615 18.108 50.0739 17.7906C50.0739 17.7906 50.5872 17.4586 51.0989 16.6573C51.4934 16.0394 53.4772 13.8103 54.6878 11.765C54.7814 11.6314 54.851 11.7425 54.8275 11.8541Z"
        fill="white"
      />
    </g>
    <path
      d="M26.5859 30.6083L26.0594 29.3298H24.4425L23.9537 30.6083H23.4648L25.0442 26.5847H25.4578L27.0371 30.6083H26.5859ZM25.9466 28.9161L25.4578 27.6376C25.4578 27.6 25.4202 27.5624 25.4202 27.4872C25.3826 27.412 25.3826 27.3368 25.345 27.2616C25.3074 27.1864 25.3074 27.1112 25.2698 27.0736C25.2698 27.1488 25.2322 27.224 25.1946 27.2992C25.157 27.3744 25.157 27.4496 25.1194 27.4872C25.0818 27.5624 25.0818 27.6 25.0818 27.6376L24.5929 28.9161H25.9466Z"
      fill="#4A4A4A"
    />
    <path
      d="M30.9104 30.6083L29.5567 27.036H29.5191C29.5191 27.1112 29.5191 27.1864 29.5567 27.2992C29.5567 27.412 29.5567 27.5248 29.5567 27.6376C29.5567 27.7504 29.5567 27.9008 29.5567 28.0137V30.5707H29.1055V26.5847H29.8199L31.0984 29.9314H31.136L32.4521 26.5847H33.129V30.6083H32.6777V28.0137C32.6777 27.9008 32.6777 27.788 32.6777 27.6376C32.6777 27.5248 32.6777 27.412 32.6777 27.2992C32.6777 27.1864 32.6777 27.1112 32.6777 27.036H32.6401L31.2488 30.6083H30.9104Z"
      fill="#4A4A4A"
    />
    <path
      d="M35.3088 27.5248C35.6848 27.5248 35.948 27.6 36.136 27.7504C36.3241 27.9008 36.3993 28.1641 36.3993 28.5401V30.6082H36.0608L35.9856 30.157H35.948C35.8728 30.2698 35.76 30.345 35.6848 30.4202C35.6096 30.4954 35.4968 30.533 35.3464 30.5706C35.2336 30.6082 35.0832 30.6082 34.8951 30.6082C34.7071 30.6082 34.5567 30.5706 34.4063 30.4954C34.2559 30.4202 34.1431 30.345 34.0679 30.1946C33.9927 30.0818 33.9551 29.8938 33.9551 29.7058C33.9551 29.405 34.0679 29.1793 34.3311 29.0289C34.5567 28.8785 34.9328 28.7657 35.4216 28.7657L35.948 28.7281V28.5401C35.948 28.2769 35.8728 28.0889 35.76 27.976C35.6472 27.8632 35.4968 27.8256 35.2712 27.8256C35.1208 27.8256 34.9704 27.8632 34.8199 27.9008C34.6695 27.9384 34.5567 28.0136 34.4063 28.0513L34.2559 27.7128C34.4063 27.6376 34.5567 27.6 34.7447 27.5248C34.9328 27.5624 35.1208 27.5248 35.3088 27.5248ZM35.9104 29.1041L35.4592 29.1417C35.0832 29.1417 34.8199 29.2169 34.6695 29.3297C34.5191 29.4426 34.4439 29.593 34.4439 29.781C34.4439 29.969 34.4815 30.0818 34.5943 30.157C34.7071 30.2322 34.8575 30.2698 35.008 30.2698C35.2712 30.2698 35.4968 30.1946 35.6848 30.0442C35.8728 29.8938 35.948 29.6682 35.948 29.3674V29.1041H35.9104Z"
      fill="#4A4A4A"
    />
    <path
      d="M37.7911 26.3218V28.5404C37.7911 28.6156 37.7911 28.6908 37.7911 28.8036C37.7911 28.9164 37.7911 28.9916 37.7911 29.0668H37.8287C37.8663 29.0292 37.9415 28.954 38.0167 28.8412C38.0919 28.766 38.1671 28.6908 38.2047 28.6156L39.22 27.5627H39.7464L38.5055 28.8412L39.8216 30.5709H39.2576L38.1671 29.142L37.7911 29.4804V30.5709H37.3398V26.3218H37.7911Z"
      fill="#4A4A4A"
    />
    <path
      d="M41.5134 27.5248C41.7766 27.5248 42.0022 27.6 42.1902 27.7128C42.3782 27.8256 42.5286 27.976 42.6038 28.2017C42.7167 28.3897 42.7543 28.6529 42.7543 28.9161V29.2169H40.6485C40.6485 29.5554 40.7613 29.8562 40.9117 30.0442C41.0997 30.2322 41.3254 30.3074 41.6262 30.3074C41.8142 30.3074 42.0022 30.3074 42.1526 30.2698C42.303 30.2322 42.4534 30.1946 42.6038 30.1194V30.4578C42.4534 30.533 42.303 30.5706 42.1526 30.6082C42.0022 30.6459 41.8142 30.6458 41.6262 30.6458C41.3254 30.6458 41.0621 30.5706 40.8741 30.4578C40.6485 30.345 40.4981 30.157 40.3853 29.9314C40.2725 29.7058 40.1973 29.405 40.1973 29.1041C40.1973 28.7657 40.2349 28.5025 40.3477 28.2769C40.4605 28.0513 40.6109 27.8632 40.7989 27.7128C40.9869 27.6 41.2125 27.5248 41.5134 27.5248ZM41.4758 27.9008C41.2125 27.9008 41.0245 27.976 40.8741 28.1265C40.7237 28.2769 40.6485 28.5025 40.6109 28.8033H42.2278C42.2278 28.6153 42.1902 28.4649 42.1526 28.3145C42.115 28.1641 42.0022 28.0889 41.8894 28.0136C41.8142 27.9384 41.6638 27.9008 41.4758 27.9008Z"
      fill="#4A4A4A"
    />
    <path
      d="M45.387 30.6083L44.0333 27.036H43.9957C43.9957 27.1112 43.9957 27.1864 44.0333 27.2992C44.0333 27.412 44.0333 27.5248 44.0333 27.6376C44.0333 27.7504 44.0333 27.9008 44.0333 28.0137V30.5707H43.582V26.5847H44.2965L45.575 29.9314H45.6126L46.9287 26.5847H47.6055V30.6083H47.1543V28.0137C47.1543 27.9008 47.1543 27.788 47.1543 27.6376C47.1543 27.5248 47.1543 27.412 47.1543 27.2992C47.1543 27.1864 47.1543 27.1112 47.1543 27.036L45.763 30.6083H45.387Z"
      fill="#4A4A4A"
    />
    <path
      d="M48.168 27.6005H48.6568L49.3337 29.3678C49.3713 29.4806 49.4089 29.5558 49.4465 29.6686C49.4841 29.7438 49.5217 29.8566 49.5217 29.9318C49.5217 30.007 49.5593 30.1199 49.5969 30.1951H49.6345C49.6721 30.1199 49.7097 29.9694 49.7473 29.819C49.7849 29.6686 49.8601 29.5182 49.8977 29.3678L50.537 27.6381H51.0258L49.7097 31.0975C49.6345 31.2855 49.5593 31.436 49.4465 31.5864C49.3713 31.7368 49.2585 31.812 49.108 31.8872C48.9576 31.9624 48.8072 32 48.6192 32C48.544 32 48.4688 32 48.3936 32C48.3184 32 48.2808 31.9624 48.2056 31.9624V31.5864C48.2432 31.5864 48.2808 31.5864 48.356 31.624C48.4312 31.624 48.4688 31.624 48.544 31.624C48.6568 31.624 48.7696 31.5864 48.8448 31.5488C48.92 31.5112 48.9952 31.436 49.0704 31.3608C49.1456 31.2855 49.1832 31.1727 49.2209 31.0599L49.3713 30.6463L48.168 27.6005Z"
      fill="#4A4A4A"
    />
    <path
      d="M52.8318 30.6083H52.343V26.9984H51.0645V26.5847H54.1103V26.9984H52.8318V30.6083Z"
      fill="#4A4A4A"
    />
    <path
      d="M56.028 27.5248C56.1032 27.5248 56.1408 27.5248 56.216 27.5248C56.2912 27.5248 56.3289 27.5248 56.4041 27.5624L56.3288 27.976C56.2912 27.976 56.216 27.9384 56.1784 27.9384C56.1032 27.9384 56.0656 27.9384 55.9904 27.9384C55.8776 27.9384 55.7648 27.976 55.652 28.0136C55.5392 28.0513 55.464 28.1265 55.3512 28.2393C55.276 28.3145 55.2008 28.4273 55.1632 28.5777C55.1256 28.6905 55.088 28.8409 55.088 28.9913V30.6082H54.6367V27.6H55.0127L55.0504 28.1641H55.088C55.1632 28.0513 55.2384 27.9384 55.3136 27.8632C55.3888 27.788 55.5016 27.7128 55.6144 27.6376C55.7648 27.5624 55.8776 27.5248 56.028 27.5248Z"
      fill="#4A4A4A"
    />
    <path
      d="M57.4205 27.6005V30.6087H56.9692V27.6005H57.4205ZM57.1949 26.4724C57.2701 26.4724 57.3453 26.51 57.3829 26.5476C57.4205 26.5852 57.4581 26.6604 57.4581 26.7732C57.4581 26.886 57.4205 26.9612 57.3829 26.9988C57.3453 27.0364 57.2701 27.074 57.1949 27.074C57.1197 27.074 57.0444 27.0364 57.0068 26.9988C56.9692 26.9612 56.9316 26.886 56.9316 26.7732C56.9316 26.6604 56.9692 26.5852 57.0068 26.5476C57.0821 26.4724 57.1197 26.4724 57.1949 26.4724Z"
      fill="#4A4A4A"
    />
    <path
      d="M59.8645 27.5248C60.2405 27.5248 60.5413 27.6376 60.7669 27.9009C60.9925 28.1641 61.1054 28.5401 61.1054 29.0666C61.1054 29.405 61.0677 29.7058 60.9549 29.9314C60.8421 30.157 60.6917 30.3451 60.5037 30.4579C60.3157 30.5707 60.0901 30.6459 59.8269 30.6459C59.6764 30.6459 59.526 30.6083 59.4132 30.5707C59.3004 30.5331 59.1876 30.4579 59.1124 30.3827C59.0372 30.3075 58.962 30.2322 58.8868 30.157H58.8492C58.8492 30.2322 58.8492 30.3075 58.8868 30.4203C58.8868 30.5331 58.8868 30.6083 58.8868 30.6835V31.962H58.4355V27.6H58.8116L58.8868 28.0513H58.9244C58.9996 27.9761 59.0372 27.8633 59.15 27.7881C59.2252 27.7129 59.338 27.6377 59.4508 27.6C59.526 27.5624 59.6764 27.5248 59.8645 27.5248ZM59.7516 27.9009C59.526 27.9009 59.3756 27.9385 59.2252 28.0137C59.1124 28.0889 58.9996 28.2017 58.962 28.3897C58.8868 28.5401 58.8868 28.7657 58.8492 28.9914V29.0666C58.8492 29.3298 58.8868 29.5554 58.9244 29.7058C58.9996 29.8938 59.0748 30.0066 59.1876 30.1194C59.338 30.1946 59.4884 30.2699 59.714 30.2699C59.9021 30.2699 60.0525 30.2322 60.2029 30.1194C60.3157 30.0066 60.4285 29.8938 60.4661 29.7058C60.5413 29.5178 60.5789 29.3298 60.5789 29.0666C60.5789 28.7281 60.5037 28.4273 60.3533 28.2017C60.2781 28.0137 60.0525 27.9009 59.7516 27.9009Z"
      fill="#4A4A4A"
    />
    <path
      d="M65.2053 28.5028H66.6343V30.4581C66.4086 30.5333 66.183 30.5709 65.9574 30.6085C65.7318 30.6461 65.4686 30.6461 65.2053 30.6461C64.7917 30.6461 64.4157 30.5709 64.1525 30.3829C63.8516 30.2325 63.626 29.9693 63.4756 29.6685C63.3252 29.3676 63.25 28.9916 63.25 28.578C63.25 28.1644 63.3252 27.7883 63.5132 27.4875C63.6636 27.1867 63.9268 26.9235 64.2277 26.773C64.5285 26.585 64.9045 26.5098 65.3558 26.5098C65.5814 26.5098 65.807 26.5474 65.995 26.585C66.183 26.6226 66.371 26.6978 66.5591 26.773L66.371 27.1867C66.2206 27.1115 66.0702 27.0739 65.8822 27.0363C65.6942 26.9987 65.5062 26.9611 65.3182 26.9611C64.9797 26.9611 64.7165 27.0363 64.4533 27.1491C64.2277 27.2995 64.0397 27.4875 63.9269 27.7131C63.814 27.9763 63.7388 28.2396 63.7388 28.578C63.7388 28.9164 63.7764 29.2172 63.8892 29.4429C64.0021 29.7061 64.1525 29.8941 64.4157 30.0069C64.6413 30.1573 64.9421 30.1949 65.3182 30.1949C65.5062 30.1949 65.6566 30.1949 65.807 30.1573C65.9574 30.1197 66.0702 30.1197 66.183 30.0821V28.8412H65.2053V28.5028Z"
      fill="#4A4A4A"
    />
    <path
      d="M68.9245 27.5248C68.9997 27.5248 69.0373 27.5248 69.1125 27.5248C69.1877 27.5248 69.2253 27.5248 69.3005 27.5624L69.2253 27.9761C69.1877 27.9761 69.1125 27.9385 69.0749 27.9385C68.9997 27.9385 68.9621 27.9385 68.8869 27.9385C68.7741 27.9385 68.6613 27.9761 68.5485 28.0137C68.4357 28.0513 68.3605 28.1265 68.2477 28.2393C68.1725 28.3145 68.0972 28.4273 68.0596 28.5777C68.022 28.6905 67.9844 28.8409 67.9844 28.9914V30.6083H67.5332V27.6H67.9092L67.9468 28.1641H67.9844C68.0596 28.0513 68.1349 27.9385 68.2101 27.8633C68.2853 27.7881 68.3981 27.7129 68.5109 27.6377C68.6237 27.5624 68.7741 27.5248 68.9245 27.5248Z"
      fill="#4A4A4A"
    />
    <path
      d="M72.5004 29.1047C72.5004 29.368 72.4628 29.556 72.3876 29.7816C72.3124 29.9696 72.2372 30.1576 72.1244 30.2704C72.0116 30.4208 71.8611 30.496 71.6731 30.5712C71.4851 30.6465 71.2971 30.6841 71.0715 30.6841C70.8835 30.6841 70.6955 30.6465 70.5074 30.5712C70.357 30.496 70.2066 30.3832 70.0562 30.2704C69.9434 30.12 69.8306 29.9696 69.7554 29.7816C69.6802 29.5936 69.6426 29.368 69.6426 29.1047C69.6426 28.7663 69.7178 28.5031 69.8306 28.2775C69.9434 28.0519 70.0938 27.8638 70.3194 27.751C70.545 27.6382 70.7707 27.563 71.0715 27.563C71.3347 27.563 71.5979 27.6382 71.7859 27.751C72.0116 27.8638 72.162 28.0519 72.2748 28.2775C72.4252 28.4655 72.5004 28.7663 72.5004 29.1047ZM70.169 29.1047C70.169 29.3304 70.2066 29.556 70.2818 29.744C70.357 29.932 70.4322 30.0448 70.5826 30.1576C70.7331 30.2704 70.8835 30.308 71.1091 30.308C71.3347 30.308 71.4851 30.2704 71.6355 30.1576C71.7859 30.0448 71.8611 29.932 71.9363 29.744C72.0116 29.556 72.0492 29.368 72.0492 29.1047C72.0492 28.8791 72.0116 28.6535 71.9363 28.4655C71.8611 28.2775 71.7859 28.1647 71.6355 28.0519C71.4851 27.939 71.3347 27.9014 71.1091 27.9014C70.8083 27.9014 70.545 28.0143 70.3946 28.2023C70.2442 28.4279 70.169 28.7287 70.169 29.1047Z"
      fill="#4A4A4A"
    />
    <path
      d="M75.807 27.6V30.6083H75.431L75.3558 30.1946C75.2806 30.3075 75.2054 30.3827 75.0925 30.4579C74.9797 30.5331 74.8669 30.5707 74.7541 30.6083C74.6413 30.6459 74.4909 30.6459 74.3405 30.6459C74.1149 30.6459 73.8892 30.6083 73.7388 30.5331C73.5884 30.4579 73.438 30.3451 73.3628 30.157C73.2876 30.0066 73.25 29.781 73.25 29.5178V27.5248H73.7012V29.4802C73.7012 29.7434 73.7764 29.9314 73.8893 30.0442C74.0021 30.157 74.1901 30.2322 74.4157 30.2322C74.6413 30.2322 74.8293 30.1946 74.9421 30.1194C75.0925 30.0442 75.1677 29.8938 75.243 29.7434C75.3182 29.593 75.3182 29.3674 75.3182 29.1418V27.5624H75.807V27.6Z"
      fill="#4A4A4A"
    />
    <path
      d="M78.216 27.5248C78.592 27.5248 78.8929 27.6376 79.1185 27.9009C79.3441 28.1641 79.4569 28.5401 79.4569 29.0666C79.4569 29.405 79.4193 29.7058 79.3065 29.9314C79.1937 30.157 79.0433 30.3451 78.8553 30.4579C78.6673 30.5707 78.4416 30.6459 78.1784 30.6459C78.028 30.6459 77.8776 30.6083 77.7648 30.5707C77.652 30.5331 77.5392 30.4579 77.464 30.3827C77.3888 30.3075 77.3135 30.2322 77.2383 30.157H77.2007C77.2007 30.2322 77.2007 30.3075 77.2383 30.4203C77.2383 30.5331 77.2383 30.6083 77.2383 30.6835V31.962H76.7871V27.6H77.1631L77.2383 28.0513H77.2759C77.3512 27.9761 77.3888 27.8633 77.5016 27.7881C77.5768 27.7129 77.6896 27.6377 77.8024 27.6C77.9152 27.5624 78.0656 27.5248 78.216 27.5248ZM78.1408 27.9009C77.9152 27.9009 77.7648 27.9385 77.6144 28.0137C77.5016 28.0889 77.3888 28.2017 77.3512 28.3897C77.2759 28.5401 77.2759 28.7657 77.2383 28.9914V29.0666C77.2383 29.3298 77.2759 29.5554 77.3135 29.7058C77.3888 29.8938 77.464 30.0066 77.5768 30.1194C77.7272 30.1946 77.8776 30.2699 78.1032 30.2699C78.2912 30.2699 78.4416 30.2322 78.592 30.1194C78.7049 30.0066 78.8177 29.8938 78.8553 29.7058C78.9305 29.5178 78.9681 29.3298 78.9681 29.0666C78.9681 28.7281 78.8929 28.4273 78.7425 28.2017C78.6296 28.0137 78.4416 27.9009 78.1408 27.9009Z"
      fill="#4A4A4A"
    />
    <path
      d="M83.5525 26.9235C83.3269 26.9235 83.1389 26.9611 82.9509 27.0363C82.7629 27.1115 82.6124 27.2243 82.4996 27.3747C82.3868 27.5251 82.274 27.6755 82.1988 27.9011C82.1236 28.0891 82.086 28.3524 82.086 28.578C82.086 28.9164 82.1236 29.2172 82.2364 29.4429C82.3492 29.7061 82.4996 29.8941 82.7253 30.0069C82.9509 30.1573 83.2141 30.1949 83.5149 30.1949C83.7029 30.1949 83.8533 30.1949 84.0414 30.1573C84.1918 30.1197 84.3422 30.0821 84.5302 30.0445V30.4581C84.3798 30.4957 84.2294 30.5709 84.079 30.5709C83.9285 30.6085 83.7405 30.6085 83.5149 30.6085C83.1013 30.6085 82.7629 30.5333 82.462 30.3453C82.1988 30.1573 81.9732 29.9317 81.8228 29.6309C81.6724 29.33 81.6348 28.954 81.6348 28.5404C81.6348 28.2396 81.6724 27.9763 81.7476 27.7131C81.8228 27.4499 81.9732 27.2243 82.1236 27.0739C82.274 26.8859 82.4996 26.7354 82.7253 26.6602C82.9509 26.5474 83.2517 26.5098 83.5525 26.5098C83.7405 26.5098 83.9661 26.5474 84.1542 26.585C84.3422 26.6226 84.5302 26.6978 84.6806 26.773L84.4926 27.1867C84.3798 27.1115 84.2294 27.0739 84.079 27.0363C83.8909 26.9611 83.7405 26.9235 83.5525 26.9235Z"
      fill="#4A4A4A"
    />
    <path
      d="M87.9906 29.1047C87.9906 29.368 87.953 29.556 87.8778 29.7816C87.8026 29.9696 87.7274 30.1576 87.6146 30.2704C87.5018 30.4208 87.3514 30.496 87.1634 30.5712C86.9754 30.6465 86.7873 30.6841 86.5617 30.6841C86.3737 30.6841 86.1857 30.6465 85.9977 30.5712C85.8473 30.496 85.6969 30.3832 85.5464 30.2704C85.4336 30.12 85.3208 29.9696 85.2456 29.7816C85.1704 29.5936 85.1328 29.368 85.1328 29.1047C85.1328 28.7663 85.208 28.5031 85.3208 28.2775C85.4336 28.0519 85.584 27.8638 85.8097 27.751C86.0353 27.6382 86.2609 27.563 86.5617 27.563C86.8249 27.563 87.0882 27.6382 87.2762 27.751C87.5018 27.8638 87.6522 28.0519 87.765 28.2775C87.9154 28.4655 87.9906 28.7663 87.9906 29.1047ZM85.6593 29.1047C85.6593 29.3304 85.6969 29.556 85.7721 29.744C85.8473 29.932 85.9225 30.0448 86.0729 30.1576C86.2233 30.2704 86.3737 30.308 86.5993 30.308C86.8249 30.308 86.9754 30.2704 87.1258 30.1576C87.2762 30.0448 87.3514 29.932 87.4266 29.744C87.5018 29.556 87.5394 29.368 87.5394 29.1047C87.5394 28.8791 87.5018 28.6535 87.4266 28.4655C87.3514 28.2775 87.2762 28.1647 87.1258 28.0519C86.9754 27.939 86.8249 27.9014 86.5993 27.9014C86.2985 27.9014 86.0353 28.0143 85.8849 28.2023C85.7345 28.4279 85.6593 28.7287 85.6593 29.1047Z"
      fill="#4A4A4A"
    />
    <path
      d="M92.0918 27.5248C92.4302 27.5248 92.6934 27.6 92.8438 27.7881C93.0318 27.9761 93.107 28.2393 93.107 28.6153V30.6083H92.6558V28.6529C92.6558 28.4273 92.6182 28.2393 92.5054 28.0889C92.3926 27.9761 92.2422 27.9009 92.0166 27.9009C91.7157 27.9009 91.4901 27.9761 91.3773 28.1641C91.2269 28.3145 91.1893 28.5777 91.1893 28.9161V30.6083H90.7381V28.6529C90.7381 28.5025 90.7005 28.3521 90.6629 28.2393C90.6252 28.1265 90.55 28.0513 90.4372 28.0137C90.362 27.9761 90.2116 27.9385 90.0988 27.9385C89.9108 27.9385 89.7228 27.9761 89.61 28.0513C89.4972 28.1265 89.3844 28.2393 89.3468 28.4273C89.3091 28.5777 89.2715 28.8033 89.2715 29.029V30.6083H88.8203V27.6H89.1963L89.2715 28.0137H89.3092C89.3844 27.9009 89.4596 27.8257 89.5348 27.7505C89.61 27.6753 89.7228 27.6377 89.8356 27.6C89.9484 27.5624 90.0612 27.5624 90.2116 27.5624C90.4372 27.5624 90.6252 27.6 90.7757 27.6753C90.9261 27.7505 91.0389 27.9009 91.1141 28.0513H91.1517C91.2645 27.8633 91.3773 27.7505 91.5653 27.6753C91.6781 27.5624 91.8661 27.5248 92.0918 27.5248Z"
      fill="#4A4A4A"
    />
    <path
      d="M95.4719 27.5248C95.8479 27.5248 96.1487 27.6376 96.3743 27.9008C96.6 28.1641 96.7128 28.5401 96.7128 29.0665C96.7128 29.405 96.6752 29.7058 96.5624 29.9314C96.4496 30.157 96.2991 30.345 96.1111 30.4578C95.9231 30.5706 95.6975 30.6458 95.4343 30.6458C95.2839 30.6458 95.1335 30.6082 95.0206 30.5706C94.9078 30.533 94.795 30.4578 94.7198 30.3826C94.6446 30.3074 94.5694 30.2322 94.4942 30.157H94.4566C94.4566 30.2322 94.4566 30.3074 94.4942 30.4202C94.4942 30.533 94.4942 30.6082 94.4942 30.6835V31.962H94.043V27.6H94.419L94.4942 28.0513H94.5318C94.607 27.976 94.6822 27.8632 94.7574 27.788C94.8326 27.7128 94.9454 27.6376 95.0582 27.6C95.1711 27.5624 95.3215 27.5248 95.4719 27.5248ZM95.3967 27.9008C95.1711 27.9008 95.0206 27.9384 94.8702 28.0136C94.7574 28.0889 94.6446 28.2017 94.607 28.3897C94.5318 28.5401 94.5318 28.7657 94.4942 28.9913V29.0665C94.4942 29.3298 94.5318 29.5554 94.5694 29.7058C94.6446 29.8938 94.7198 30.0066 94.8326 30.1194C94.983 30.1946 95.1335 30.2698 95.3591 30.2698C95.5471 30.2698 95.6975 30.2322 95.8479 30.1194C95.9607 30.0066 96.0735 29.8938 96.1111 29.7058C96.1863 29.5178 96.2239 29.3298 96.2239 29.0665C96.2239 28.7281 96.1487 28.4273 95.9983 28.2017C95.8855 28.0136 95.6975 27.9008 95.3967 27.9008Z"
      fill="#4A4A4A"
    />
    <path
      d="M98.6701 27.5248C99.0461 27.5248 99.3094 27.6 99.4974 27.7505C99.6854 27.9009 99.7606 28.1641 99.7606 28.5401V30.6083H99.4222L99.347 30.157H99.3094C99.2342 30.2699 99.1213 30.3451 99.0461 30.4203C98.9709 30.4955 98.8581 30.5331 98.7077 30.5707C98.5949 30.6083 98.4445 30.6083 98.2565 30.6083C98.0685 30.6083 97.9181 30.5707 97.7676 30.4955C97.6172 30.4203 97.5044 30.3451 97.4292 30.1946C97.354 30.0818 97.3164 29.8938 97.3164 29.7058C97.3164 29.405 97.4292 29.1794 97.6924 29.029C97.9181 28.8785 98.2941 28.7657 98.7829 28.7657L99.3094 28.7281V28.5401C99.3094 28.2769 99.2718 28.0889 99.1213 27.9761C99.0085 27.8633 98.8581 27.8257 98.6325 27.8257C98.4821 27.8257 98.3317 27.8633 98.1813 27.9009C98.0309 27.9385 97.8805 28.0137 97.7676 28.0513L97.6172 27.7129C97.7676 27.6376 97.9181 27.6 98.1061 27.5248C98.2941 27.5624 98.4821 27.5248 98.6701 27.5248ZM99.3094 29.1042L98.8581 29.1418C98.4821 29.1418 98.2189 29.217 98.0685 29.3298C97.9181 29.4426 97.8428 29.593 97.8428 29.781C97.8428 29.969 97.8805 30.0818 97.9933 30.157C98.1061 30.2322 98.2565 30.2699 98.4069 30.2699C98.6701 30.2699 98.8957 30.1946 99.0837 30.0442C99.2718 29.8938 99.347 29.6682 99.347 29.3674V29.1042H99.3094Z"
      fill="#4A4A4A"
    />
    <path
      d="M102.13 27.5248C102.506 27.5248 102.769 27.6 102.957 27.7881C103.145 27.9761 103.22 28.2393 103.22 28.6153V30.5707H102.806V28.6529C102.806 28.3897 102.731 28.2017 102.618 28.0889C102.506 27.9761 102.318 27.9009 102.092 27.9009C101.754 27.9009 101.528 28.0137 101.378 28.2017C101.227 28.3897 101.19 28.6529 101.19 29.029V30.6083H100.738V27.6H101.114L101.19 28.0513H101.227C101.302 27.9385 101.378 27.8633 101.49 27.7881C101.603 27.7129 101.716 27.6753 101.829 27.6377C101.866 27.5624 102.017 27.5248 102.13 27.5248Z"
      fill="#4A4A4A"
    />
    <path
      d="M103.709 27.6005H104.198L104.875 29.3678C104.912 29.4806 104.95 29.5558 104.987 29.6686C105.025 29.7438 105.063 29.8566 105.063 29.9318C105.063 30.007 105.1 30.1199 105.138 30.1951H105.175C105.213 30.1199 105.251 29.9694 105.288 29.819C105.326 29.6686 105.401 29.5182 105.439 29.3678L106.078 27.6381H106.567L105.251 31.0975C105.175 31.2855 105.1 31.436 104.987 31.5864C104.912 31.7368 104.799 31.812 104.649 31.8872C104.499 31.9624 104.348 32 104.16 32C104.085 32 104.01 32 103.935 32C103.859 32 103.822 31.9624 103.747 31.9624V31.5864C103.784 31.5864 103.822 31.5864 103.897 31.624C103.972 31.624 104.01 31.624 104.085 31.624C104.198 31.624 104.311 31.5864 104.386 31.5488C104.461 31.5112 104.536 31.436 104.611 31.3608C104.687 31.2855 104.724 31.1727 104.762 31.0599L104.912 30.6463L103.709 27.6005Z"
      fill="#4A4A4A"
    />
  </svg>
);
export { TMBlueLogo };
