import * as React from "react";
interface iconprop {}
const MedicalBenefitIcon = (props: iconprop) => (
  <svg
    width={164}
    height={164}
    viewBox="0 0 164 164"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={82} cy={82} r={82} fill="#FAFAFA" />
    <path
      d="M131.057 61.6454V82.8209H96.3694V120.466H72.8521V82.8209H38.1641V61.6454H72.8521V24H96.3694V61.6454H131.057Z"
      fill="#F4EDED"
    />
    <path
      d="M119.718 102.307C127.804 94.2018 127.798 81.0774 119.7 72.9798C111.911 65.1904 99.4702 64.8887 91.3199 72.0732C90.7771 72.5517 89.9678 72.5517 89.425 72.0732C81.2747 64.8887 69.291 64.0095 61.5017 71.7988C53.4042 79.8963 52.9413 94.2018 61.0263 102.307L89.365 130.647C89.9214 131.203 90.8235 131.203 91.3799 130.647L119.718 102.307Z"
      fill="url(#paint0_linear_841_15247)"
    />
    <path
      d="M120.718 102.307C128.803 94.202 128.798 81.0776 120.7 72.98C112.911 65.1906 100.47 64.8889 92.3197 72.0734C91.7769 72.5519 90.9677 72.5519 90.4248 72.0734C82.2745 64.8889 69.8337 65.1906 62.0444 72.98C53.9469 81.0775 53.9411 94.202 62.0261 102.307L90.3648 130.647C90.9212 131.203 91.8233 131.203 92.3797 130.647L120.718 102.307Z"
      fill="url(#paint1_linear_841_15247)"
      fillOpacity={0.8}
    />
    <path
      d="M120.72 102.305L92.3819 130.645C91.8262 131.201 90.9229 131.201 90.3672 130.645L87.5234 127.802L115.185 100.14C123.269 92.0343 123.264 78.9093 115.167 70.8124C113.819 69.4646 112.331 68.3405 110.75 67.4414C114.397 68.2892 117.858 70.1342 120.702 72.9781C128.8 81.0749 128.806 94.1998 120.72 102.305Z"
      fill="url(#paint2_linear_841_15247)"
    />
    <path
      d="M92.0124 109.917C92.0124 109.475 91.6542 109.117 91.2124 109.117H81.6374V99.5422C81.6374 99.1004 81.2792 98.7422 80.8374 98.7422H69.9602C69.5183 98.7422 69.1602 99.1004 69.1602 99.5422V109.117H59.5852C59.1433 109.117 58.7852 109.475 58.7852 109.917V120.794C58.7852 121.236 59.1433 121.594 59.5852 121.594H69.1602V131.169C69.1602 131.611 69.5183 131.969 69.9602 131.969H80.8374C81.2792 131.969 81.6374 131.611 81.6374 131.169V121.594H91.2124C91.6543 121.594 92.0124 121.236 92.0124 120.794V109.917Z"
      fill="url(#paint3_linear_841_15247)"
    />
    <path
      d="M91.0124 108.917C91.0124 108.475 90.6542 108.117 90.2124 108.117H80.6374V98.5422C80.6374 98.1004 80.2792 97.7422 79.8374 97.7422H68.9602C68.5183 97.7422 68.1602 98.1004 68.1602 98.5422V108.117H58.5852C58.1433 108.117 57.7852 108.475 57.7852 108.917V119.794C57.7852 120.236 58.1433 120.594 58.5852 120.594H68.1602V130.169C68.1602 130.611 68.5183 130.969 68.9602 130.969H79.8374C80.2792 130.969 80.6374 130.611 80.6374 130.169V120.594H90.2124C90.6543 120.594 91.0124 120.236 91.0124 119.794V108.917Z"
      fill="url(#paint4_linear_841_15247)"
    />
    <path
      d="M89.9985 110.05C89.9985 109.635 89.6622 109.299 89.2473 109.299H79.7874V99.5052C79.7874 99.0903 79.451 98.7539 79.0361 98.7539H69.7895C69.3746 98.7539 69.0383 99.0903 69.0383 99.5052V109.299H59.5584C59.1435 109.299 58.8071 109.635 58.8071 110.05V118.881C58.8071 119.296 59.1435 119.633 59.5584 119.633H69.0383V129.205C69.0383 129.62 69.3746 129.956 69.7895 129.956H79.0361C79.451 129.956 79.7874 129.62 79.7874 129.205V119.633H89.2473C89.6622 119.633 89.9985 119.296 89.9985 118.881V110.05Z"
      fill="url(#paint5_linear_841_15247)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_841_15247"
        x1={90.4841}
        y1={66.3125}
        x2={90.4841}
        y2={131.064}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9C07" />
        <stop offset={1} stopColor="#FFD6D6" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_15247"
        x1={56.3186}
        y1={78.6422}
        x2={90.5745}
        y2={131.573}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE7370" />
        <stop offset={0.570958} stopColor="#EC4545" />
        <stop offset={1} stopColor="#992222" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_841_15247"
        x1={123.496}
        y1={78.2988}
        x2={90.4961}
        y2={131.299}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C73C3C" />
        <stop offset={1} stopColor="#CA5A5A" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint3_linear_841_15247"
        x1={75.3988}
        y1={98.7422}
        x2={75.3988}
        y2={131.969}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3773B" />
        <stop offset={1} stopColor="#D2A76D" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_841_15247"
        x1={74.3988}
        y1={97.7422}
        x2={74.3988}
        y2={130.969}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF9DC" />
        <stop offset={1} stopColor="#FFE34E" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_841_15247"
        x1={74.4028}
        y1={98.7539}
        x2={74.4028}
        y2={129.956}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2CC98" />
        <stop offset={1} stopColor="#F2CC98" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
export { MedicalBenefitIcon };
