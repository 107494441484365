import styled from "styled-components";

type CalenderFooterProp = {
  selected?: boolean;
};

export const CalenderFooterWrap = styled.div`
  background-color: #fff;
  padding: 16px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  position: relative;
`;

export const CalenderFootRange = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export const CalFootRangeCol = styled.div<CalenderFooterProp>`
  flex: 1;
  background-color: #f7f7f7;
  border: 1px solid #e7e7e7;
  border-radius: 2px;
  padding: 8px 10px;
  height: 54px;
  position: relative;
  ${(p) =>
    p.selected &&
    `
        border: 1px solid #008CFF;
        background: #F9FCFF;
    `}
  &:last-child {
    padding: 8px 10px 8px 16px;
  }
`;
