import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
`;

export interface ShimmerStyleProps {
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  background?: string;
}

export const ShimmerComp = styled.div<ShimmerStyleProps>`
  animation: ${shimmer} 1s linear infinite;
  background: ${(p: any) =>
    p.background
      ? p.background
      : "linear-gradient(to right, #e2e2e2 8%, #f6f6f6 18%, #e2e2e2 33%)"};
  background-size: 800px 104px;
  position: relative;
  border-radius: 4px;
  width: ${(p) => (p.width ? p.width : "100%")};
  height: ${(p) => (p.height ? p.height : "14px")};
  margin: ${(p) => (p.margin ? p.margin : "")};
  position: relative;
  flex-shrink: 0;
  padding: ${(p) => (p.padding ? p.padding : "")};
  display: flex;
  align-items: flex-end;
`;

export const ShimmerVersion2Style = styled(ShimmerComp)`
  background: linear-gradient(to right, #f6f6f6 8%, #f9fafb 18%, #f6f6f6 33%);
  background-size: 800px 104px;
`;

export const ShimmerVersion3Style = styled(ShimmerComp)`
  background: linear-gradient(to right, #cbcbcb 8%, #f9fafb 18%, #cbcbcb 33%);
  background-size: 800px 104px;
`;
