import {
  PageNameMapping,
  ElementNameMapping,
} from "libs/ui-widgets/src/lib/constants";
import {
  getElementUniqueIds,
  getCamelCaseStr,
} from "libs/ui-widgets/src/lib/utils";
import { DeliveryLabelContainer } from "../LandingCardFeature.style";
import { useDevice } from "libs/ui-widgets/src/lib/hooks/useDevice";
import ParaTag from "../../ui-atoms/TextStyles/ParaTag";
import { landingCardShowcaseDataProps } from "../../../mockData";
import {
  ActivationInProgressPersuasionProps,
  DeliveryTrackingPersuasionProps,
  PersuasionType,
  UpcomingTripsPersuasionProps,
} from "libs/ui-widgets/src/lib/types";

export default function DeliveryStatusPersuasion({
  persuasionProps,
  cardData,
  type,
}: {
  cardData: landingCardShowcaseDataProps;
  persuasionProps:
    | ActivationInProgressPersuasionProps
    | DeliveryTrackingPersuasionProps
    | UpcomingTripsPersuasionProps;
  type: PersuasionType;
}) {
  const device = useDevice();
  return (
    <DeliveryLabelContainer
      id={getElementUniqueIds(
        PageNameMapping.LISTING_PAGE,
        getCamelCaseStr(persuasionProps.type),
        ElementNameMapping.DELIVERY_ETA
      )}
      style={
        type === "upcoming_trips"
          ? {
              background: "#E6FFF9",
              padding: "8px 12px 8px 8px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
            }
          : {}
      }
    >
      <ParaTag
        dangerousText={persuasionProps.label}
        fontSize={device === "lg" ? "sm" : "xs"}
        color={"#000"}
        margin={
          device === "lg"
            ? "0"
            : type === "upcoming_trips"
            ? "0px"
            : "12px 0px 0px"
        }
      />
    </DeliveryLabelContainer>
  );
}
