import React, { useEffect, useState } from "react";
import {
  CommonModalDwebStyle,
  CommonModalHeader,
  CrossIconWrap,
} from "./RegionCountryModal.style";

import { theme } from "@tm/utility/global";
import {
  AnchorTag,
  ImageComponent,
  BackDropComp,
  H5Tag,
  FormattedText,
  ReactPortalComp,
} from "@tm/static";
import CrossIcon from "../../icons/cancelGreyBg.svg";
import { TextNodeType } from "../FormattedText/FormattedText";

interface ModalInterface {
  children?: React.ReactNode;
  customTitle?: string | TextNodeType;
  customSubTitle?: string;
  requiredField?: boolean;
  handleClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  crossIcon?: string;
  customWidth?: string;
  customIcon?: string;
  errorModal?: boolean;
  nonClipHeading?: boolean;
  customHeight?: string;
}

const RegionCountryModal = ({
  children,
  customTitle,
  customSubTitle,
  handleClick,
  requiredField,
  crossIcon,
  customWidth,
  customIcon,
  errorModal,
  customHeight,
  nonClipHeading,
}: ModalInterface) => {
  useEffect(() => {
    const parentElement = document.getElementById("parent-section");
    parentElement ? (parentElement.style.overflow = "unset") : "";
    return () => {
      parentElement ? (parentElement.style.overflowY = "scroll") : "";
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
    return () => {};
  }, []);

  return (
    <ReactPortalComp>
      <CommonModalDwebStyle
        customHeight={customHeight}
        customWidth={customWidth}
      >
        <CommonModalHeader nonClipHeading={nonClipHeading}>
          <div>
            <H5Tag color={errorModal ? theme.redText : theme.blackText}>
              {customIcon && (
                <ImageComponent width="40px" height="40px" src={customIcon} />
              )}
              {customTitle && <FormattedText value={customTitle} />}
            </H5Tag>
            {customSubTitle && <FormattedText value={customSubTitle} />}
          </div>

          <CrossIconWrap onClick={handleClick}>
            <ImageComponent
              width="28px"
              height="28px"
              src="https://tripmoneycmsimgak.mmtcdn.com/img/cross_grey_rounded_e24cbc15d7.svg"
            />
          </CrossIconWrap>
          {/* {customSubTitle && <FormattedText value={customSubTitle} />}x */}
        </CommonModalHeader>
        {children}
      </CommonModalDwebStyle>
      <BackDropComp onClose={handleClick} />
    </ReactPortalComp>
  );
};

export default RegionCountryModal;
