import React from "react";

import { ImageTag } from "@tm/static";
import {
  FeaturesDemoListStyle,
  Label,
  CardsWpr,
  Card,
  CardLabel,
} from "./FeaturesDemoList.style";

import { FeaturesDemoListProps } from "./types";

const FeaturesDemoList: React.FC<FeaturesDemoListProps> = ({
  data,
  margin,
}) => {
  const { label, demoList } = data;

  return (
    <FeaturesDemoListStyle margin={margin}>
      {label && <Label>{label}</Label>}
      {demoList && (
        <CardsWpr>
          {demoList.map((item) => (
            <Card key={item.id}>
              <ImageTag src={item.imgSrc} />
              {item.label && (
                <CardLabel dangerouslySetInnerHTML={{ __html: item.label }} />
              )}
            </Card>
          ))}
        </CardsWpr>
      )}
    </FeaturesDemoListStyle>
  );
};

export default FeaturesDemoList;
