import styled from "styled-components";

const DeliveryStatusTextStyled = styled.div`
  padding: 6px;
  font-size: 12px;
  font-weight: 400;
  background: linear-gradient(
    90deg,
    #e0f0ff 33.5%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 6px;
`;

interface DeliveryStatusProps {
  text: string | undefined;
}
const DeliveryStatusText = ({ text }: DeliveryStatusProps) => {
  if (!text) return null;
  return (
    <>
      <DeliveryStatusTextStyled>{text}</DeliveryStatusTextStyled>
    </>
  );
};

export default DeliveryStatusText;
