import React, { useState } from "react";

import {
  ReactPortalComp,
  LandingButton,
  LandingHeaderMobile,
  TravellerDetails,
  FlexComp,
  FormattedText,
  ParaTag,
  SpanTag,
  AnchorTag,
  LandingInput,
  RadioButton,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import {
  formatDate,
  getPartnerOnClientSide,
  isFieldValid,
} from "@tm/insurance/utils";
import {
  DATE_FORMATS,
  ERROR_CODES,
  RESPONSE_STATUS,
} from "@tm/insurance/constants";

import {
  EditTravelSheetStyle,
  BtnWrap,
  TravllerNum,
  EditTravllerItem,
  GenderTypeItem,
} from "./EditTraveller.style";
import { IEditTravellerData, ITravellerData } from "./type";
import { editInsuranceDetails } from "@tm/insurance/api";

const minDate = "1970-01-01";
const maxDate = new Date().toISOString().split("T")[0];

interface EditTravellerProp {
  handleClose?: () => void;
  editTraveller: IEditTravellerData;
  travellerData: ITravellerData[];
  updateOrderDetails: () => void;
}

const EditTravellerSheet = ({
  handleClose,
  travellerData,
  editTraveller,
  updateOrderDetails,
}: EditTravellerProp) => {
  const [editTravller, setEditTraveller] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const travellers = travellerData.map((item, i) => {
    const { refId, name, dob, passport, gender } = item;
    return {
      refId,
      FullName: name,
      Dob: dob,
      Gender: gender,
      PassportNo: passport,
    };
  });
  const [travellerDataState, setTravellerDataState] = useState([...travellers]);

  const emptyErrorList = travellerData.map(() => {
    return {
      nameError: "",
      dobError: "",
      passportError: "",
    };
  });
  const [errorMessages, setErrorMessages] = useState([...emptyErrorList]);

  const handleEditTravller = (index: number) => {
    setEditTraveller(index);
  };

  const handleServerErrors = (error: any) => {
    setIsError(true);
    const { displaySecondaryError = [] } = error?.validationError || {};
    displaySecondaryError.map((item: any, index: number) => {
      const { name, dob, passport } = item?.displayError;
      if (name) {
        let updatedErrorMessages = [...errorMessages];
        updatedErrorMessages[index].nameError = name;
        setErrorMessages(updatedErrorMessages);
        setEditTraveller(index);
      }
      if (dob) {
        let updatedErrorMessages = [...errorMessages];
        updatedErrorMessages[index].dobError = dob;
        setErrorMessages(updatedErrorMessages);
        setEditTraveller(index);
      }
      if (passport) {
        let updatedErrorMessages = [...errorMessages];
        updatedErrorMessages[index].passportError = passport;
        setErrorMessages(updatedErrorMessages);
        setEditTraveller(index);
      }
    });
  };

  const onSubmit = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const bookingId = queryParams.get("bookingId");
      const partner = getPartnerOnClientSide();

      setIsLoading(true);

      const response = await editInsuranceDetails({
        partner,
        bookingID: bookingId,
        data: travellerDataState,
      });
      const { responseStatus } = response;

      if (responseStatus === RESPONSE_STATUS.SUCCESS) {
        await updateOrderDetails?.();
      } else {
        const { Error: error } = response;
        if (!error) {
          throw new Error("Something went wrong");
        }
        handleServerErrors(error);
      }
      setEditTraveller(-1);
    } catch (err: any) {
      console.log("ERROR IN UPDATING TRAVELLER DETAILS----", { err });
      const { Error } = err?.response?.data;
      if (Error && Error?.code === ERROR_CODES.DISPLAY_ERROR) {
        handleServerErrors(Error);
      }
    }
    setIsLoading(false);
  };

  const onNameChange = (updatedName: string, index: number) => {
    const updatedTravellerDataState = [...travellerDataState];
    updatedTravellerDataState[index].FullName = updatedName;
    setTravellerDataState(updatedTravellerDataState);
    validateName(updatedName, index);
  };

  const validateName = (updatedName: string, index: number) => {
    const isNameValid = isFieldValid(
      editTraveller?.travellerAttributes?.name?.regex ?? "",
      updatedName
    );
    if (!isNameValid) {
      setIsError(true);
      let updatedErrorMessages = [...errorMessages];
      updatedErrorMessages[index].nameError =
        editTraveller?.travellerAttributes?.name?.errorMessage ?? "";
      setErrorMessages(updatedErrorMessages);
    } else {
      setIsError(false);
      setErrorMessages([...emptyErrorList]);
    }
  };

  const onPassportChange = (updatedPassport: string, index: number) => {
    const updatedTravellerDataState = [...travellerDataState];
    updatedTravellerDataState[index].PassportNo = updatedPassport?.trim?.();
    setTravellerDataState(updatedTravellerDataState);
    validatePassport(updatedPassport, index);
  };

  const validatePassport = (updatedPassport: string, index: number) => {
    const isPassportValid = isFieldValid(
      editTraveller?.travellerAttributes?.passport?.regex ?? "",
      updatedPassport
    );
    if (!isPassportValid) {
      setIsError(true);
      let updatedErrorMessages = [...errorMessages];
      updatedErrorMessages[index].passportError =
        editTraveller?.travellerAttributes?.passport?.errorMessage ?? "";
      setErrorMessages(updatedErrorMessages);
    } else {
      setIsError(false);
      setErrorMessages([...emptyErrorList]);
    }
  };

  const onDobChange = (updatedDob: string, index: number) => {
    const updatedTravellerDataState = [...travellerDataState];
    updatedTravellerDataState[index].Dob = updatedDob?.trim?.();
    setTravellerDataState(updatedTravellerDataState);
    validateDob(updatedDob, index);
  };

  const validateDob = (updatedDob: string, index: number) => {
    const isDobValid = isFieldValid(
      editTraveller?.travellerAttributes?.dob?.regex ?? "",
      formatDate(updatedDob, DATE_FORMATS["DD/MM/YYYY"])
    );
    if (!isDobValid) {
      setIsError(true);
      let updatedErrorMessages = [...errorMessages];
      updatedErrorMessages[index].dobError =
        editTraveller?.travellerAttributes?.dob?.errorMessage ?? "";
      setErrorMessages(updatedErrorMessages);
    } else {
      setIsError(false);
      setErrorMessages([...emptyErrorList]);
    }
  };

  const onGenderChange = (updatedGender: string, index: number) => {
    const updatedTravellerDataState = [...travellerDataState];
    updatedTravellerDataState[index].Gender = updatedGender?.trim?.();
    setTravellerDataState(updatedTravellerDataState);
  };

  return (
    <ReactPortalComp>
      <EditTravelSheetStyle>
        <LandingHeaderMobile
          displayWhiteHeader
          headerTitle="Edit Traveller Details"
          handleBackBtn={handleClose}
        />

        {travellerDataState?.map((item, index) => {
          return (
            <>
              <EditTravllerItem key={index}>
                {editTravller != index && (
                  <FlexComp justifyContent="space-between">
                    <FlexComp gap="10px">
                      <TravllerNum>
                        <FormattedText value={`${index + 1}`} />
                      </TravllerNum>
                      <div>
                        <ParaTag fontWeight="bold">
                          <FormattedText value={item.FullName} />
                        </ParaTag>
                        <div>
                          <SpanTag fontSize="xs" color={theme.greyText}>
                            {item?.Dob && (
                              <FormattedText
                                value={formatDate(
                                  item.Dob,
                                  DATE_FORMATS["DD/MM/YYYY"]
                                )}
                              />
                            )}
                          </SpanTag>
                          &nbsp;
                          <SpanTag fontSize="xs" color={theme.greyText}>
                            <FormattedText value={item.Gender} />
                          </SpanTag>
                        </div>
                      </div>
                    </FlexComp>
                    <AnchorTag
                      textTransform="uppercase"
                      fontSize="xs"
                      fontWeight="bold"
                      handleClick={() => handleEditTravller(index)}
                    >
                      Edit
                    </AnchorTag>
                  </FlexComp>
                )}
                {editTravller == index && (
                  <div>
                    <ParaTag
                      margin="0 0 18px"
                      fontWeight="bold"
                      color={theme.greyText}
                    >
                      Traveller {index + 1}
                    </ParaTag>
                    <LandingInput
                      flex="1"
                      requiredField
                      placeholderTxt="FULL NAME"
                      labelTxt="FULL NAME"
                      inputValue={item.FullName}
                      errorState={errorMessages?.[index]?.nameError}
                      // disableState={isLoading}
                      handleChange={(event) =>
                        onNameChange(event.target.value, index)
                      }
                      handleBlur={(event) => {
                        validateName(event.target.value, index);
                      }}
                      autoComplete="off"
                      margin="0 0 8px"
                    />
                    <LandingInput
                      flex="1"
                      placeholderTxt="Date of birth"
                      requiredField
                      labelTxt="Date of birth"
                      inputType="date"
                      inputValue={item.Dob}
                      minValue={minDate}
                      maxValue={maxDate}
                      errorState={errorMessages?.[index]?.dobError}
                      // disableState={isLoading}
                      handleChange={(event) => {
                        onDobChange(event.target.value, index);
                        // saveFormValuesOnBlur();
                      }}
                      handleBlur={(event) => {
                        validateDob(event.target.value, index);
                      }}
                      margin="0 0 8px"
                    />
                    <SpanTag
                      margin="0 0 8px"
                      fontSize="md"
                      fontWeight="bold"
                      color={theme.greyText}
                    >
                      <FormattedText value="Gender" />
                    </SpanTag>
                    <FlexComp gap="8px" margin="0 0 10px">
                      {editTraveller?.travellerAttributes?.gender?.values?.map(
                        (gender: any) => (
                          <GenderTypeItem
                            selected={
                              gender.label?.toLowerCase() ==
                              item.Gender?.toLowerCase()
                            }
                            key={gender.id}
                            onClick={() => {
                              onGenderChange(gender.label, index);
                              // saveFormValuesOnBlur();
                            }}
                          >
                            <RadioButton
                              activeBtn={
                                gender.label?.toLowerCase() ==
                                item.Gender?.toLowerCase()
                              }
                            >
                              <SpanTag
                                fontSize="xs"
                                fontWeight="bold"
                                textTransform="uppercase"
                                color={
                                  gender.label?.toLowerCase() ==
                                  item.Gender?.toLowerCase()
                                    ? theme.blueText
                                    : theme.greyText
                                }
                              >
                                {gender.label}
                              </SpanTag>
                            </RadioButton>
                          </GenderTypeItem>
                        )
                      )}
                    </FlexComp>
                    <LandingInput
                      flex="1"
                      placeholderTxt="PASSPORT NO."
                      labelTxt="PASSPORT NO."
                      inputValue={item.PassportNo}
                      errorState={errorMessages?.[index]?.passportError}
                      // disableState={isLoading}
                      handleChange={(event) => {
                        onPassportChange(event.target.value, index);
                        // saveFormValuesOnBlur();
                      }}
                      handleBlur={(event) => {
                        validatePassport(event.target.value, index);
                      }}
                    />
                  </div>
                )}
              </EditTravllerItem>
            </>
          );
        })}
        <BtnWrap>
          {editTravller >= 0 && (
            <LandingButton
              customTitle={editTraveller?.updateNowBtnTxt}
              customWidth="100%"
              onClick={onSubmit}
              isLoading={isLoading}
              disableState={isError}
            />
          )}
        </BtnWrap>
      </EditTravelSheetStyle>
    </ReactPortalComp>
  );
};

export default EditTravellerSheet;
