import styled from "styled-components";
import previewEye from "@tm/images/PreviewEyeSvg";

const PreviewEye = styled(
  previewEye as React.FC<React.SVGProps<SVGSVGElement>>
)`
  width: ${(props) => props.width || ""};
  height: ${(props) => props.height || ""};
  margin: ${(props: any) => props.margin};
  flex-shrink: 0;
  cursor: pointer;
`;

export default PreviewEye;
