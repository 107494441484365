import styled from "styled-components";

export interface RadioBtnProp {
  showLabel?: boolean;
  isPadding?: boolean;
}

export const RadioButtonStyle = styled.div<RadioBtnProp>`
  ${(p) =>
    p.isPadding &&
    `
    padding: 16px 10px;
`}
  display: flex;
  ${(p) =>
    p.showLabel &&
    `
        width: 100%;
`}

  label {
    user-select: none;
  }
  input {
    display: none;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    display: inline-flex;
    color: #666;
    width: 100%;
    ${(p) =>
      !p.showLabel &&
      `
        margin-top:-12px
`}
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    width: 15px;
    height: 15px;
    border: 1px solid #008cff;
    border-radius: 100%;
    background: #fff;
    ${(p) =>
      !p.showLabel &&
      `
        margin-top:-12px
`}
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 9px;
    height: 9px;
    background: #008cff;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    ${(p) =>
      !p.showLabel &&
      `
        margin-top:-12px
`}
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;
