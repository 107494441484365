import React from "react";

import { ImageTag, LandingButton } from "@tm/static";

import {
  ForexCardCompDwebStyle,
  CardImgStyle,
  BottomContainer,
} from "./ForexComp.style";

import { theme } from "@tm/utility/global";

import BMFCardImg from "../../icons/bmf_card_img.png";

import { ForexCardCompDwebProps } from "./types";

const ForexCardCompDweb: React.FC<ForexCardCompDwebProps> = ({
  label,
  btn,
  imgSrc,
}) => {
  return (
    <ForexCardCompDwebStyle>
      <CardImgStyle>
        <ImageTag src={imgSrc ? imgSrc : BMFCardImg.src} width={"328px"} />
      </CardImgStyle>
      <BottomContainer>
        {label && <p dangerouslySetInnerHTML={{ __html: label }}></p>}
        <LandingButton
          customTitle={btn?.label}
          margin="16px 0 0 0"
          customWidth="100%"
          disableState={btn?.disabled}
          onClick={btn?.onClick}
        />
      </BottomContainer>
    </ForexCardCompDwebStyle>
  );
};

export default ForexCardCompDweb;
