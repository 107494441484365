import {
  H3Tag,
  SpanTag,
  LandingButton,
  BulletListItem,
  FlexComp,
  ParaTag,
  FormattedText,
  AnchorTag,
} from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { TextNodeType } from "../FormattedText/FormattedText";
import {
  PersuasionBannerStyle,
  BulletListStyle,
} from "./PersuasionBanner.style";
import MarkdownText from "../MarkdownText/MarkdownText";

interface PersuasionBannerProps {
  crossSell?: {
    title?: string | TextNodeType;
    description?: string;
    background?: { url?: string };
    info?: string | TextNodeType;
    cta?: {
      title?: string;
    };
  };
  btnHandler?: () => void;
}

const PersuasionBannerInsurance = ({
  crossSell,
  btnHandler,
}: PersuasionBannerProps) => {
  const device = useBreakpointV2();
  if (!crossSell) {
    return null;
  }
  const { title, background, description, cta, info } = crossSell;
  return (
    <PersuasionBannerStyle bgImage={background?.url}>
      {title && (
        <H3Tag>
          <FormattedText value={title} />
        </H3Tag>
      )}
      {device === "sm" && (
        <FlexComp gap="6px">
          <ParaTag>
            <FormattedText value={info} />
          </ParaTag>
        </FlexComp>
      )}
      {description && <MarkdownText value={description} />}
      <LandingButton
        customTitle={cta?.title}
        onClick={btnHandler}
        padding="0px 40px"
      />
    </PersuasionBannerStyle>
  );
};

export default PersuasionBannerInsurance;
