import React, { useState } from "react";
import {
  InputWrap,
  LandingInputStyle,
  ErrorTxt,
  CounryCodeWrap,
} from "./CountryCodeInput.style";
import { SpanTag, ImageComponent } from "@tm/static";
import { theme } from "@tm/utility/global";

interface InputFiledProp {
  placeholderTxt?: string;
  labelTxt?: string;
  disableState?: boolean;
  inputWidth?: string;
  margin?: string;
  inputValue?: string | number;
  handleChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  handleBlur?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  Verify?: boolean;
  flex?: string;
  requiredField?: boolean;
  handleKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  maxInputLength?: number | undefined;
  inputType?: React.HTMLInputTypeAttribute | undefined;
  errorState?: string | undefined;
  handleCountryCodeModal?: () => void;
  countryFlag?: string;
  countryCode?: string;
  autoComplete?: string;
}

const CountryCodeInput: React.FC<InputFiledProp> = ({
  placeholderTxt,
  labelTxt,
  disableState,
  inputWidth,
  margin,
  errorState,
  inputValue,
  handleChange,
  handleBlur,
  Verify,
  flex,
  requiredField,
  handleKeyDown,
  maxInputLength,
  inputType,
  handleCountryCodeModal,
  countryCode,
  countryFlag,
  autoComplete = "on",
}) => {
  return (
    <InputWrap
      margin={margin}
      flex={flex}
      inputWidth={inputWidth}
      Verify={Verify}
    >
      <CounryCodeWrap onClick={handleCountryCodeModal}>
        {countryFlag && <ImageComponent src={countryFlag} />}
        <SpanTag fontWeight="black">{countryCode}</SpanTag>
      </CounryCodeWrap>
      {labelTxt && (
        <label htmlFor={labelTxt}>
          {labelTxt}
          {requiredField && (
            <SpanTag fontWeight="bold" color={theme.redText2}>
              *
            </SpanTag>
          )}
        </label>
      )}
      <LandingInputStyle
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        value={inputValue}
        errorState={errorState}
        id={labelTxt}
        disableState={disableState}
        placeholder={placeholderTxt}
        maxLength={maxInputLength}
        type={inputType}
        autoComplete={autoComplete}
      />
      {errorState && (
        <ErrorTxt
          txtfontSize="xs"
          margin="4px 0 0"
          color={theme.redText}
          txtfontWeight="bold"
        >
          {errorState}
        </ErrorTxt>
      )}
    </InputWrap>
  );
};

export default CountryCodeInput;
