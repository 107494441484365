import React, { MutableRefObject } from "react";
import {
  TravellerCountWigetStyle,
  ChevStateWrap,
  CounterWrap,
  CounterBtn,
} from "./TravellingToWidget.style";

import { AnchorTag, FlexComp, SpanTag } from "@tm/static";
import { BlueChev } from "../../icons";
import { theme } from "@tm/utility/global";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";

interface TravellerCountWigetProps {
  showTravCount?: boolean;
  handleCaptureCountIncr?: () => void;
  captureCount?: number;
  ref?: (instance: HTMLDivElement | null) => void;
  travCountTitle?: TextNodeType | string;
  travCountInfo?: TextNodeType | string;
  handleCaptureCountDecr?: () => void;
}

const TravellerCounterWiget = ({
  showTravCount,
  handleCaptureCountIncr,
  handleCaptureCountDecr,
  captureCount,
  ref,
  travCountTitle,
  travCountInfo,
}: TravellerCountWigetProps) => {
  return (
    <TravellerCountWigetStyle>
      <FlexComp flexDirection="column">
        <SpanTag
          textTransform="uppercase"
          color={showTravCount ? theme.blueText : theme.greyText}
          fontWeight={"bold"}
          fontSize="xs"
        >
          {travCountTitle && <FormattedText value={travCountTitle} />}
        </SpanTag>
        <SpanTag color={theme.greyText6} fontSize="xs">
          {
            <FormattedText
              value={"Senior citizens above 70yrs are not covered"}
            />
          }
        </SpanTag>
      </FlexComp>
      <CounterWrap>
        <CounterBtn
          isDiabled={captureCount == 1}
          onClick={handleCaptureCountDecr}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" fill="white" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 11.0938H17.5V12.9064H6V11.0938Z"
              fill="#008CFF"
            />
          </svg>
        </CounterBtn>
        <SpanTag fontWeight="bold" fontSize="md">
          {captureCount && captureCount <= 9
            ? "0" + captureCount
            : captureCount}
        </SpanTag>
        <CounterBtn
          isDiabled={captureCount == 20}
          onClick={handleCaptureCountIncr}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect width="24" height="24" fill="white" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.612 6.04785V11.1719H17.496V12.7919H12.612V17.9519H10.86V12.7919H6V11.1719H10.86V6.04785H12.612Z"
              fill="#008CFF"
            />
          </svg>
        </CounterBtn>
      </CounterWrap>
    </TravellerCountWigetStyle>
  );
};

export default TravellerCounterWiget;
