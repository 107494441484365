import { theme } from "@tm/utility/global";
import React from "react";
import {
  SecureTravOfferStyle,
  SecureTravItem,
  SecureTravImgWrap,
  SecureTravContent,
} from "./SecureTravOffer.style";
import { H5Tag, ImageComponent, ParaTag, H6Tag } from "@tm/static";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { usePartnerContext } from "@tm/static/partnerContext";
import { PARTNER } from "@tm/insurance/constants";

interface SecureTravOfferProps {
  data?: {
    travImg?: string;
    title?: TextNodeType;
    info?: TextNodeType;
  }[];
}

const SecureTravOffer = ({ data }: SecureTravOfferProps) => {
  const context = usePartnerContext();
  const device = useBreakpointV2();
  return (
    <SecureTravOfferStyle>
      {data &&
        data.map((item, index) => {
          return (
            <SecureTravItem key={index}>
              <SecureTravImgWrap pageTheme={context ? context : undefined}>
                {item.travImg && (
                  <ImageComponent
                    width="48px"
                    height="48px"
                    src={item.travImg}
                    loading="eager"
                  />
                )}
              </SecureTravImgWrap>
              <SecureTravContent>
                {device == "lg" ? (
                  <H5Tag color={theme.greyText}>
                    {item.title && <FormattedText value={item.title} />}
                  </H5Tag>
                ) : (
                  <ParaTag fontSize="md" color={theme.greyText}>
                    {item.title && <FormattedText value={item.title} />}
                  </ParaTag>
                )}
                {item.info && (
                  <ParaTag
                    margin={device == "lg" ? "16px 0 0" : "8px 0 0"}
                    fontSize={device == "lg" ? "sm" : "xs"}
                    color={theme.greyText}
                  >
                    <FormattedText value={item.info} />
                  </ParaTag>
                )}
              </SecureTravContent>
            </SecureTravItem>
          );
        })}
    </SecureTravOfferStyle>
  );
};

export default SecureTravOffer;
