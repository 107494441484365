import { Fragment } from "react";
import { FlexComp, Modal } from "@tm/static";

interface NoPincodeRecommendationProps {
  label?: string;
  subLabel?: string;
  isLoading?: boolean;
  loadingText?: string;
  inputFieldOptions?: TMFloatingInputProps;
  linkBtn?: {
    title?: string;
    hrefValue?: string;
    onClick?: () => void;
  };
  solidBtnProps?: BtnProp;
  onModalClose?: () => void;
}

import {
  AnchorTag,
  LandingButtonWhite,
  TMFloatingInput,
  ParaTag,
} from "@tm/static";
import { BtnProp } from "../../LandingButton/ButtonType";
import { TMFloatingInputProps } from "../../TMFloatingInput/types";
import { NoPincodeRecommendtnStyle } from "./NoPincodeRecommendation.style";
import { ButtonContainer } from "../CardCongrats/CardCongrats.style";
import { useBreakpoint } from "@tm/ui/hooks";
import { theme } from "@tm/utility/global";

const NoPincodeRecommendation = ({
  label,
  onModalClose = () => {},
  subLabel,
  loadingText,
  inputFieldOptions,
  isLoading,
  linkBtn,
  solidBtnProps,
}: NoPincodeRecommendationProps) => {
  const device = useBreakpoint();

  return (
    <Modal
      padding="0px"
      modalWidth={480}
      onModalClose={onModalClose}
      modalType={device === "lg" ? "centered" : "fullPage"}
    >
      <NoPincodeRecommendtnStyle>
        {isLoading ? (
          loadingText && (
            <ParaTag color={theme.greyText} textAlign="center">
              {loadingText}
            </ParaTag>
          )
        ) : (
          <Fragment>
            <FlexComp
              width="312px"
              alignItems="center"
              flexDirection="column"
              margin={device === "lg" ? "-50px 0px 0px" : "0px"}
            >
              {label && (
                <ParaTag
                  textAlign="center"
                  fontSize="md"
                  color={theme.whiteText}
                  fontWeight="bold"
                >
                  {label}
                </ParaTag>
              )}
              {subLabel && (
                <ParaTag
                  margin="5px 0px 0px"
                  fontSize="xs"
                  color={theme.whiteText}
                >
                  {subLabel}
                </ParaTag>
              )}
              {inputFieldOptions && (
                <TMFloatingInput
                  margin={device === "sm" ? "50px 0px 0px" : "24px 0px 0px"}
                  inputWidth="100%"
                  {...inputFieldOptions}
                />
              )}
            </FlexComp>
            {(solidBtnProps || linkBtn) && (
              <ButtonContainer>
                {linkBtn && (
                  <AnchorTag
                    fontWeight="bold"
                    margin="0px 0px 18px 0px"
                    hrefProp={linkBtn.hrefValue}
                    handleClick={linkBtn.onClick}
                  >
                    {linkBtn.title}
                  </AnchorTag>
                )}
                {solidBtnProps && (
                  <LandingButtonWhite customWidth="100%" {...solidBtnProps} />
                )}
              </ButtonContainer>
            )}
          </Fragment>
        )}
      </NoPincodeRecommendtnStyle>
    </Modal>
  );
};

export default NoPincodeRecommendation;
