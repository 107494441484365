import { theme } from "@tm/utility/global";
import styled from "styled-components";
import { deviceType } from "@tm/static/utils";

export const ListingTravelDetailsStyle = styled.div`
  background: #fff;
  border: 1px solid rgba(242, 242, 242, 0.16);
  /* box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.16); */
  border-radius: 8px;
  padding: 24px;
  @media ${deviceType.mobile} {
    padding: 12px 16px;
  }
`;
