import styled from "styled-components";

export interface BackDropStyleProps {
  height?: number;
}

export const BackDropCompStyle = styled.div<BackDropStyleProps>`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 46;
  width: 100%;
  /* height: 100%; */
  height: ${(p) => (p.height ? `${p.height}px` : "100vh")};
`;
