import {
  FlexComp,
  LandingButton,
  Modal,
  TMFloatingInput,
  SnackBar,
  ImageTag,
} from "@tm/static";

import { Container, Cta, GpsIconWpr } from "./TravAddressForm.style";
import { theme } from "@tm/utility/global";

import { useBreakpoint } from "@tm/ui/hooks";

import { CloseIcon, GpsIcon } from "../../../icons";

import { TravAddressFormProps } from "./types";
import { BackIcon } from "../../../icons/BackIcon";

const TravAddressForm = ({
  onModalClose,
  modalHeading,
  closeButton,
  backIcon,
  locationEnabled = true,
  detectLocationLabel,
  detectLocationHandler,
  pincodeInput,
  address1Input,
  address2Input,
  stateInput,
  cityInput,
  btnLabel,
  btnDisabledState,
  btnClickHandler,
  snackbarDetails,
  showSnackbar,
  gpsIcon,
}: TravAddressFormProps) => {
  const device = useBreakpoint();

  return (
    <Modal
      showBackBtn
      modalHeading={modalHeading}
      onModalClose={onModalClose}
      backBtn={
        device === "sm" &&
        (backIcon ? (
          <ImageTag src={backIcon} showCursorPointer />
        ) : (
          <BackIcon />
        ))
      }
      closeButton={
        device === "lg" &&
        (closeButton ? (
          <ImageTag src={closeButton} showCursorPointer />
        ) : (
          <CloseIcon />
        ))
      }
      modalType={device === "lg" ? "centered" : "fullPage"}
      padding={device === "sm" ? "16px" : "20px 30px 30px 30px"}
    >
      <Container>
        <FlexComp
          flexDirection="row"
          flex="1"
          justifyContent="center"
          alignItems="center"
        >
          <TMFloatingInput
            flex={1}
            labelTxt={pincodeInput?.labelTxt}
            inputValue={pincodeInput?.inputValue}
            handleChange={pincodeInput?.handleChange}
            errorState={pincodeInput?.errorState}
            verifiedTag={pincodeInput?.verifiedTag}
            maxLength={pincodeInput?.maxLength}
          />
          {locationEnabled && (
            <Cta onClick={detectLocationHandler}>
              <GpsIconWpr>
                {gpsIcon ? (
                  <ImageTag src={gpsIcon} />
                ) : (
                  <GpsIcon color={theme.blackText} />
                )}
              </GpsIconWpr>

              {detectLocationLabel}
            </Cta>
          )}
        </FlexComp>

        <TMFloatingInput
          labelTxt={address1Input?.labelTxt}
          inputValue={address1Input?.inputValue}
          handleChange={address1Input?.handleChange}
          errorState={address1Input?.errorState}
          verifiedTag={address1Input?.verifiedTag}
          maxLength={address1Input?.maxLength}
        />
        <TMFloatingInput
          labelTxt={address2Input?.labelTxt}
          inputValue={address2Input?.inputValue}
          handleChange={address2Input?.handleChange}
          errorState={address2Input?.errorState}
          verifiedTag={address2Input?.verifiedTag}
          maxLength={address2Input?.maxLength}
        />
        <FlexComp flexDirection="row" gap="12px">
          <TMFloatingInput
            labelTxt={stateInput?.labelTxt}
            inputValue={stateInput?.inputValue}
            handleChange={stateInput?.handleChange}
            errorState={stateInput?.errorState}
            verifiedTag={stateInput?.verifiedTag}
            maxLength={stateInput?.maxLength}
            flex={0.5}
          />
          <TMFloatingInput
            labelTxt={cityInput?.labelTxt}
            inputValue={cityInput?.inputValue}
            handleChange={cityInput?.handleChange}
            errorState={cityInput?.errorState}
            verifiedTag={cityInput?.verifiedTag}
            maxLength={cityInput?.maxLength}
            flex={0.5}
          />
        </FlexComp>
        <LandingButton
          margin="4px 0px"
          customTitle={btnLabel}
          customWidth="100%"
          disableState={btnDisabledState}
          onClick={btnClickHandler}
        />
        {showSnackbar && (
          <SnackBar
            containerStyle={{ marginTop: 16 }}
            buttonText={snackbarDetails?.btnText}
            message={snackbarDetails?.snackbarMsg}
            onButtonClick={snackbarDetails?.snackbarCloseHandler}
            bgColor={snackbarDetails?.bgColor}
            txtColor={snackbarDetails?.txtColor}
          />
        )}
      </Container>
    </Modal>
  );
};

export default TravAddressForm;
