import { createGlobalStyle } from "styled-components";

export const TripMoneyStyles = createGlobalStyle`.container {
    width: 1100px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 22px;
  }
  
  .container.negativeMargin {
    margin-top: -358px;
  }
  
  .greySection {
    background-color: #f6f6f6;
    padding: 60px 0;
  }
  .whiteSection {
    background-color: #fff;
    padding: 60px 0;
  }
  
  .headerLogo {
    flex-shrink: 0;
    width: 150px;
    height: 59px;
  }
  
  header {
    background-color: #fff;
    height: 80px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
  .topBlueSection {
    background-color: #010101;
    height: 450px;
  }
  
  .tripmoney__breadCrumbs {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 32px;
  }
  .container .bannerText {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 10px;
    font-weight: 900;
    color: #000;
  }
  .container .bannerTextInfo {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 35px;
    color: #4b4a4a;
  }
  .container h1 {
    font-size: 36px;
    line-height: 44px;
    font-weight: 900;
    color: #000;
  }
  .container h2 {
    font-size: 30px;
    line-height: 44px;
    font-weight: 900;
    color: #000;
  }
  .container h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 900;
    color: #000;
  }
  .container h4 {
    font-size: 22px;
    line-height: 26px;
    font-weight: 900;
  }
  .container__card {
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
    background-color: #fff;
    padding: 46px 40px 40px;
    flex: 1;
    position: relative;
  }
  .containerImg {
    position: absolute;
    top: -48px;
    left: 33px;
    width: 81px;
    height: 81px;
  }
  .forexSolImgWrap {
    width: 392px;
    height: 279px;
    flex-shrink: 0;
  }
  .container__card--yellow {
    background-color: #fffbec;
    margin-bottom: 42px;
  }
  .container__card--blue {
    background-color: #f0fdff;
    display: flex;
    margin-bottom: 34px;
  }
  .header__inner,.header__inner2 {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header__links {
    display: flex;
    align-items: center;
    gap: 32px;
  }
  .page__section {
    width: 100%;
  }
  .baseHeaderContiner {
    width: 1200px;
    margin: 25px auto;
    display: flex;
  }
  .baseLeft {
    flex: 1;
    flex-shrink: 0;
  }
  .baseLeft__cont {
    max-width: 770px;
  }
  .baseRight {
    width: 340px;
    flex-shrink: 0;
    margin-left: 10px;
  }
  .baseRight__item {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
    gap: 10px;
  }
  
  .tripMoneySection {
    border-bottom: solid 1px #979797;
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
  
  .tripMoneySection:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .forexSolList {
    margin-bottom: 35px;
  }
  
  .forexSolList li {
    margin-bottom: 10px;
    color: #696969;
    display: flex;
  }
  
  .countryList {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    margin-bottom: 35px;
  }
  
  .countryList li {
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
    background-color: #fff;
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .currencyFacts__heaading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
  }
  .currencyFacts__list {
    position: relative;
  }
  
  .currencyFacts__list {
    border-spacing: 12px;
    position: relative;
  }
  
  .currencyFacts__list::after {
    content: '';
    position: absolute;
    background: #e9e2c6;
    width: 1px;
    height: 606px;
    top: -10px;
    left: 50%;
  }
  
  .currencyFacts__list tr {
    margin-bottom: 12px;
  }
  
  .currencyFacts__list td {
    border-bottom: solid 1px #e9e2c6;
    padding-bottom: 12px;
    font-size: 18px;
    color: #2f2f2f;
    align-items: center;
    width: 50%;
  }
  
  .currencyFacts__list tr:last-child td {
    border-bottom: 0;
    padding-bottom: 0;
  }
  
  .currencyFacts__list span {
    padding-left: 120px;
    display: inline-block;
  }
  .currencyFacts__list li:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .tripMoneySection__bestRateBuy {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 45px;
  }
  
  .tripMoneySection__bestRateBuy li {
    background-image: url(https://tripmoneycmsimgak.mmtcdn.com/img/img_Bg_Path_85ccab3b71.png);
    background-size: 353px 94px;
    background-position: top left;
    border-radius: 8px;
    border: solid 1px #cad3dd;
    background-color: #fff;
    font-size: 16px;
    color: #2f2f2f;
    width: 353px;
    height: 94px;
    padding: 10px;
    padding-left: 110px;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .greenCheckImg {
    width: 18px;
    height: 18px;
    margin-right: 12px;
    margin-top: 3px;
  }
  
  .tripmoneyImgWrap {
    width: 228px;
    flex-shrink: 0;
    height: 48px;
    margin-right: 75px;
    align-self: center;
    position: relative;
  }
  
  .tripmoneyImgWrap::after {
    content: '';
    position: absolute;
    top: -146px;
    right: -33px;
    width: 1px;
    background: #d5d5d5;
    height: 345px;
  }
  .countryImg {
    border-radius: 50%;
    margin-right: 8px;
    object-fit: cover;
  }
  
  .counterFietImgWrap {
    width: 427px;
    height: 579px;
    flex-shrink: 0;
    margin-left: 22px;
  }
  
  .productRangeList {
    padding-left: 32px;
  }
  
  .productRangeList li {
    position: relative;
    margin-bottom: 20px;
  }
  
  .productRangeList li::after {
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: #000;
    position: absolute;
    top: 10px;
    left: -32px;
  }

  .reviewedCurrencies {
    background-color: #e0e0e0;
    padding: 46px 0;
  }

  .reviewedCurrencies .container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }

  .footerLink{
    padding:6px 0;
  }

  .footerLinkCol{
        margin-left: 26px;
  }

  .footerLinkCol:first-child{
    margin-left: 0;
  }

  .reviewedCurrencies h3{
    font-size:28px;
    margin-bottom: 16px;
  }
  
  .quickfactsImg {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 18px;
  }
  
  .stepCount {
    color: #fff;
    position: absolute;
    top: 14px;
    left: 16px;
    font-size: 42px;
    line-height: 50px;
    display: flex;
    flex-direction: column;
  }
  
  .currencyLogoImg {
    width: 104px;
    height: 58px;
    flex-shrink: 0;
    margin-right: 16px;
  }
  .documentList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    grid-column-gap: 28px;
  }
  
  .documentList li {
    display: flex;
  }
  
  footer {
    background-color: #16223e;
    padding: 46px 0 46px;
  }
  
  footer .container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    color: #fff;
    font-size: 16px;
  }
  
  footer a {
    font-size: 16px;
    color: #fff;
  }
  
  footer .linkTitle {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 20px;
  }
  .counterFietImgWrapMweb {
    display: none;
  }
  .exchangeRateWrap {
    display: flex;
    gap: 20px;
  }
  .container__cardAlign {
    display: flex;
  }
  .container__card .primaryBtn {
    width: 250px;
    color: "#fff";
  }
  .refreshBtn{
    cursor: pointer;
  }
  .forexSolImgWrapMweb {
    display: none;
  }

  .capSulesList{
    display: grid;
    margin-top: 30px;
    gap: 15px;
  }
  .capSulesList.grid5{
      grid-template-columns: repeat(5, 1fr);
  }
  .capSulesList.grid4{
      grid-template-columns: repeat(4, 1fr);
  }
  .capSulesList li{
      padding: 19px;
      border-radius: 6px;
      cursor: pointer;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
      background-color: #fff;
      font-size: 16px;
      font-weight: 700;
      color: #005cbc;
      text-align: center;
  }
  .viewMore{
      margin-top: 30px;
  }
  .viewMore__cta{
      font-size: 16px;
      font-weight: 700;
      color: #005cbc;
  }
  .capSulesList li:nth-child(n+5){
      display: none;
  }
  .capSulesList.showAll li:nth-child(n+5){
      display: block;
  }
  
  @media only screen and (max-width: 768px) {
    header{
      padding: 20px;
    }
    .tripmoney__breadCrumbs {
      margin-bottom: 24px;
    }
    .container {
      width: 100%;
      margin: unset;
      padding: 16px;
      font-size: 16px;
      line-height: 20px;
    }
    .container .bannerText {
      font-size: 25px;
      line-height: 30px;
      text-align: center;
    }
    .container__card {
      padding: 60px 22px 22px;
    }
    .container__card--yellow {
      padding-top: 24px;
    }
    .container__card--blue {
      padding: 22px;
    }
    .whiteSection,
    .greySection {
      padding: 30px 0;
    }
    .forexSolList {
      font-size: 14px;
      line-height: 20px;
    }
    .countryList {
      grid-template-columns: repeat(2, 1fr);
    }
    .tripMoneySection__bestRateBuy {
      display: flex;
      flex-direction: column;
    }
    .documentList {
      display: flex;
      flex-direction: column;
    }
    .container h1 {
      font-size: 30px;
      line-height: 36px;
    }
    .container h2 {
      font-size: 22px;
      line-height: 27px;
    }
    .container h3 {
      font-size: 18px;
      line-height: 22px;
    }
    .currencyFacts__heaading {
      margin-bottom: 30px;
    }
    .currencyFacts__list span {
      padding-left: 0;
    }
    .currencyFacts__list li {
      font-size: 16px;
      line-height: 20px;
    }
    .currencyFacts__list::before,
    .currencyFacts__list::after {
      position: static;
    }
    .counterFietImgWrap {
      display: none;
    }
    .counterFietImgWrapMweb {
      width: 330px;
      height: 448px;
      flex-shrink: 0;
      display: inline-block;
      margin-bottom: 20px;
    }
    .forexSolImgWrap {
      display: none;
    }
    .header__links {
      display: none;
    }
    .exchangeRateWrap {
      flex-direction: column;
      gap: 60px;
    }
    .container__cardAlign {
      flex-direction: column;
    }
    .container .bannerTextInfo {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }
    .container__card .primaryBtn {
      width: 100%;
    }
    .tripMoneySection {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
    .currencyLogoImg {
      width: 70px;
      height: 40px;
    }
    .forexSolImgWrapMweb {
      display: flex;
      align-items: center;
      width: 224px;
      height: 160px;
      flex-shrink: 0;
      margin: 0 auto;
      margin-bottom: 44px;
    }
    .containerImg {
      top: -40px;
    }
    .tripmoneyImgWrap {
      position: static;
      margin-bottom: 38px;
    }
    .tripmoneyImgWrap::after {
      content: none;
    }
    .container h4 {
      font-size: 18px;
      line-height: 22px;
    }
    .tripMoneySection__bestRateBuy li {
      width: 100%;
      background-repeat: no-repeat;
    }
    .capSulesList.grid5, .capSulesList.grid4{ grid-template-columns: repeat(2, 1fr); gap: 10px;}
    .currencyList{ grid-template-columns: repeat(2, 1fr); gap: 10px;}
    .capSulesList{ margin-top: 20px;}
    .viewMore{ text-align: center; margin-top: 20px;}
  }
  @media only screen and (max-width: 550px){
    footer .container{
      flex-direction: column;
    }
    footer .item{
      margin-bottom: 50px;
    }

    .reviewedCurrencies .container{
      flex-direction: column;
    }

    .reviewedCurrencies h3{
      margin-bottom: 0;
      line-height:1.3;
    }

    .footerLinkCol{
      margin-left:0;
    }
  }
  
  .heroSection{
      background: #000;
  }
  .heroSection__inner{
      max-width: 1200px;
      margin: 0 auto;
      background: url('https://tripmoneycmsimgak.mmtcdn.com/img/hero_bg_4567e802ae.png') top right no-repeat;
      background-size: 557px 155px;
      padding: 20px 0 80px 0;
  }
  .heroSection__inner.heroPadding {
    padding: 0 16px 200px 16px;
  }

  .heroSection .heroSection_info {
    padding: 38px 0 0;
  }

  .heroPadding h2{
    font-size: 45px;
    line-height: 52px;
    font-weight: 900;
    color: #fff;
  }

  .heroPadding p{
    font-size: 22px;
    line-height: 52px;
    font-weight: 600;
    line-height: 30px;
    color: #fff;
    margin-top: 10px;
  }

  .pageContainer.pageContainer_margin {
    margin: 0 auto;
    margin-top: -170px;
  }

  .separator {
    height: 1px;
    background-color: #979797;
    margin: 50px 0;
  }

  .faqList{
      margin-top: 50px;
  }
  .faqList li h4.question{
      font-size: 24px;
      line-height: 26px;
      font-weight: 900;
      color: #000;
      padding: 30px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      position: relative;
  }
  .faqList li h4.question::after{
      content: "";
      border: solid #2f2f2f;
      display: inline-block;
      border-width: 0 3px 3px 0;
      padding: 6px;
      transform: rotate(45deg);
      position: absolute;
      right: 40px;
      top: 32px;
  }
  .faqList li p.answer{
      font-size: 18px;
      line-height: 24px;
      color: #000;
      padding: 0 0 0 30px;
      margin-bottom: 30px;
  }
  .faqList li p.answer span{
      font-weight: 900;
  }
  .faqList li:hover h4.question{
      border-radius: 6px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
      background-color: #fff;
  }
  .faqList li.active h4.question::after{
      transform: rotate(-135deg);
      top: 42px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .heroPadding h2{
        font-size: 24px;
        line-height: 28px;
    }
    .heroPadding p{
        font-size: 16px;
        line-height: 20px;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
      body{ background-color: #ffffff;}
      .header__right{ display: none;}
      .headerInner{ justify-content: center; align-items: center;}
      .heroSection__inner{ padding: 0 12px 30px 12px; background: url('https://tripmoneycmsimgak.mmtcdn.com/img/hero_bg_mob_d9f0191293.png') top center no-repeat; background-size: 320px 167px;}
      .heroContent{ flex-direction: column; margin-top: 40px;}
      .heroContent__left{ width: 100%; display: flex; flex-direction: column;}
      .heroContent__right{ width: 100%; padding: 20px 16px 30px 16px;}
      .heroContent__left h1{ font-size: 28px; line-height: 32px; text-align: center;}
      .heroContent__left .h1{ font-size: 28px; line-height: 32px; text-align: center;}
      .heroContent__left p{ font-size: 14px; line-height: 18px; text-align: center;}
      .exchange{ font-size: 15px; margin-left: auto; margin-right: auto; margin-bottom: 25px; padding: 15px 25px;}

      .h2{ font-size: 30px; line-height: 34px;}
      .h3{ font-size: 20px; line-height: 24px;}
      .pageContainer{ padding: 0 12px; margin: 30px auto;}
      .getForex{ box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.16); border: solid 1px #e8e8e8; padding: 25px 12px; border-radius: 12px;}
      .getForex__title{ display: none;}
      .getForex__list{ grid-template-columns: 1fr;}
      .getForex__listItem{ border: 0; padding: 0; border-radius: 0;}
      .getForex__listItem:first-child{ border-bottom: 1px #f2f2f2 solid; padding-bottom: 15px;}
      .getForex__listItemImg{ width: 64px;}
      .getForex__listItemImg img{ width: 64px; height: unset;}
      .getForex__listItemDesc p.desc{ margin-top: 5px; font-size: 12px; line-height: 18px;}
      .getForex__listItemDesc p.desc span::after{ top: 6px;}
      .getForex__listItemDesc p.title .arrow{ margin-left: auto;}
      .deliveryBy{ display: flex;}
      .planTrip p{ font-size: 16px; line-height: 24px;}
      .description{ font-size: 16px; line-height: 24px;}
      .separator{ margin: 30px 0;}
      .convertCurrency{ padding: 30px 20px;}
      .ccList__item p.heading{ font-size: 18px; line-height: 22px;}
      .ccList__item p.desc{ font-size: 16px; line-height: 24px;}
      .ccList__item{ padding: 16px 20px;}
      .benifitsList li{ font-size: 16px;}
      .forexServices{ display: flex; gap: unset; flex-wrap: nowrap; overflow-x: auto; padding: 6px 12px; margin: 20px -12px 0 -12px;}
      .forexServices::-webkit-scrollbar{ display: none;}
      .forexServices__item{ width: 258px; flex-shrink: 0; margin-right: 12px; padding: 20px 20px 20px 29px;}
      .forexServices__itemHdr{ flex-direction: column; margin-bottom: 10px;}
      .forexServices__itemImg{ margin-right: 0;}
      .forexServices__itemImg img{ width: 102px; height: auto;}
      .forexServices__itemTitle{ max-width: unset; font-size: 18px; line-height: 22px; margin-top: 10px;}
      .conversionRate{ grid-template-columns: 1fr; gap: 26px; margin-top: 30px;}
      .conversionTbl th{ padding: 14px 25px; }
      .conversionTbl td{ padding: 14px 25px; font-size: 16px;}
      .states{ margin-top: 30px;}
      .capSulesList.grid5, .capSulesList.grid4{ grid-template-columns: repeat(2, 1fr); gap: 10px;}
      .currencyList{ grid-template-columns: repeat(2, 1fr); gap: 10px;}
      .capSulesList{ margin-top: 20px;}
      .capSulesList li:nth-child(n+5), .currencyList li:nth-child(n+5){display: none;}
      .viewMore{ text-align: center; margin-top: 20px;}
      .faqList li{ border-radius: 6px; box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22); background-color: #fff; padding: 20px 10px; margin-bottom: 40px;}
      .faqList li:last-child{ margin-bottom: 0;}
      .faqList li:hover h4.question{ border-radius: unset; box-shadow: unset; background-color: unset;}
      .faqList li h4.question{ padding: 0 60px 0 0; font-size: 18px; line-height: 22px;}
      .faqList li h4.question::after{ padding: 4px; right: 10px; top: 5px;}
      .faqList li.active h4.question::after{ top: 10px;}
      .faqList li p.answer{ margin-bottom: unset; padding: 10px 0 0 0; font-size: 16px;}
      .faqList li.active{ box-shadow: unset;}
      .faqList li{ border-radius: 6px; box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22); background-color: #fff; padding: 20px 10px; margin-bottom: 40px;}
      .faqList li:last-child{ margin-bottom: 0;}
      .faqList li:hover h4.question{ border-radius: unset; box-shadow: unset; background-color: unset;}
      .faqList li h4.question{ padding: 0 60px 0 0; font-size: 18px; line-height: 22px;}
      .faqList li h4.question::after{ padding: 4px; right: 10px; top: 5px;}
      .faqList li.active h4.question::after{ top: 10px;}
      .faqList li p.answer{ margin-bottom: unset; padding: 10px 0 0 0; font-size: 16px;}
      .pageContent{ margin-bottom: 0;}
      footer{ padding: 30px 12px;}
      .footernavCont{ grid-template-columns: repeat(2, 1fr);}
      .footerBottom{ text-align: left;}
  }


  @media (min-width: 768px) and (max-width: 1280px) {
      header{ padding: 10px 16px;}
      .heroSection{ padding: 0 16px;}
      .heroContent__left{ width: 48%;}
      .heroContent__right{ width: 48%;}
      .pageContainer{ margin: 30px auto; padding: 0 16px;}
      .convertCurrency{ padding: 20px 30px;}
      .forexServices__itemHdr{ flex-direction: column;}
      .conversionRate{ gap: 30px;}
      .capSulesList.grid5, .capSulesList.grid4, .currencyList{ grid-template-columns: repeat(3, 1fr);}
      footer{ padding: 30px 16px;}
      .faqList li h4.question{ padding-right: 50px;}
  }

  .sectionGapping{
    margin-bottom:30px !important;
  }`;
