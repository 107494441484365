import React, { useState, useEffect } from "react";
import {
  SpanTag,
  ParaTag,
  FlexComp,
  AnchorTag,
  ImageComponent,
} from "@tm/static";
import {
  DnldPolicyComponentStyle,
  TravNumber,
  PolicyItem,
} from "./DnldPolicyComponent.style";
import PeopleGroupIcon from "../../icons/peopleGroupIcon.svg";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import { theme } from "libs/ui-widgets/src/lib/static/css/Global";

interface BackDropProp {
  data?: {
    Title?: TextNodeType | string;
    Description?: TextNodeType | string;
    Link?: {
      DownloadUrl: string;
    };
  }[];
}

const DnldPolicyComponent = ({ data }: BackDropProp) => {
  return (
    <DnldPolicyComponentStyle>
      <FlexComp gap="8px" alignItems="center" margin="0 0 24px">
        <ImageComponent
          width="24px"
          height="24px"
          src="https://tripmoneycmsimgak.mmtcdn.com/img/people_Group_Icon_d97975a5cb.svg"
        />
        <SpanTag fontWeight="black" fontSize="large" color={theme.greyText}>
          Travellers Insured ({data?.length})
        </SpanTag>
      </FlexComp>
      {data?.map((item, index: number) => {
        return (
          <PolicyItem firstChild={index == 0} key={index}>
            <TravNumber>{index + 1}</TravNumber>
            <div>
              {item.Title && (
                <ParaTag fontSize="md" fontWeight="black">
                  <FormattedText value={item.Title} />
                </ParaTag>
              )}
              <div>
                {item.Description && (
                  <SpanTag fontSize="xs" color={theme.greyText7}>
                    <FormattedText value={item.Description} />
                  </SpanTag>
                )}
                {/* &nbsp; */}
                {/* {item.dob && (
                                    <SpanTag
                                        fontSize="xs"
                                        color={theme.greyText7}
                                    >
                                        DOB: <FormattedText value={item.dob} />
                                    </SpanTag>
                                )} */}
              </div>
            </div>
            <AnchorTag
              margin="0 0 0 auto"
              textTransform="uppercase"
              fontWeight="black"
              hrefProp={item?.Link?.DownloadUrl}
              download={true}
              targetProp={"_blank"}
            >
              Download
            </AnchorTag>
          </PolicyItem>
        );
      })}
    </DnldPolicyComponentStyle>
  );
};

export default DnldPolicyComponent;
