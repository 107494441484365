import styled from "styled-components";

export interface sectionDividerProp {
  xs?: boolean;
  small?: boolean;
  large?: boolean;
  width?: string;
  margin?: string;
  borderRadius?: string;
  height?: string;
  backgroundColor?: string;
}

export const SectionDividersStyle = styled.div<sectionDividerProp>`
  background: ${(p) =>
    p.backgroundColor ? `${p.backgroundColor}` : `#f2f2f2`};
  width: ${(p) => (p.width ? p.width : "unset")};
  height: ${(p) => (p.height ? p.height : "4px")};
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : "0px")};
  margin: ${(p) => (p.margin ? p.margin : "0px")};
  ${(p) => p.xs && `height: 1px;`};
  ${(p) => p.small && `height: 10px;`};
  ${(p) => p.large && `height: 25px;`};
  flex-shrink: 0;
`;
