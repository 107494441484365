import { AUTHTOKEN, AppSocialLogin } from "../constants";

export async function getAuth(data: any) {
  const response = await fetch(AppSocialLogin, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AUTHTOKEN, //TODO
      "User-Identifier":
        '{"type" :"auth","value":"","deviceId":"device1","os":"android","osVersion":"6.0.0","appVersion":"5.4.1","imie":"132342354","ipAddress":"127.0.0.1","timeZone":"+5.30 GMT"}',
    },
    body: data,
  });
  const responseData = await response.json();
  if (!response.ok) {
    console.log(
      `Code: ${responseData?.code}, Message: ${responseData?.message}`
    );
  }
  return responseData;
}
