import React from "react";
import { SpinnerProps } from "./SpinnerProps";
import { SpinnerCont } from "./Spinner.styles";

const Spinner: React.FC<SpinnerProps> = ({
  spinnerWidth = 22,
  spinnerColor = "#0189d2",
  thickness = 2,
  customStyle,
}) => {
  return (
    <SpinnerCont
      style={{
        width: spinnerWidth,
        height: spinnerWidth,
        border: `${thickness}px solid ${spinnerColor}`,
        borderRightColor: "transparent",
        ...customStyle,
      }}
    ></SpinnerCont>
  );
};

export default Spinner;
