import React from "react";

import {
  WhyBuyTravInsStyle,
  WhyBuyItem,
  WhyFeatListWrap,
  ShieldWrap,
} from "./WhyBuyTravIns.style";
import { InnerContentWrap } from "../../CommonStyle/CommonStyles.style";
import LightGreenShield from "../../icons/LightGreenShield.svg";
import {
  FlexComp,
  H2Tag,
  H5Tag,
  ImageComponent,
  ParaTag,
  SpanTag,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { usePartnerContext } from "@tm/static/partnerContext";
import { PARTNER } from "@tm/insurance/constants";

interface WhyBuyTravInsInterFace {
  title?: TextNodeType;
  info?: TextNodeType;
  data: {
    icon: string;
    info: TextNodeType;
  }[];
}

const WhyBuyTravIns: React.FC<WhyBuyTravInsInterFace> = ({
  title,
  info,
  data,
}) => {
  const device = useBreakpointV2();
  const context = usePartnerContext();
  return (
    <WhyBuyTravInsStyle pageTheme={context ? context : undefined}>
      <InnerContentWrap>
        <FlexComp
          // flexWrap="wrap"
          flexDirection={device == "lg" ? "row" : "column"}
          alignItems="center"
          gap={device == "lg" ? "74px" : "24px"}
        >
          <div>
            {device == "lg" ? (
              <H2Tag margin="0 0 60px">
                {title && <FormattedText value={title} />}
              </H2Tag>
            ) : (
              <H5Tag margin="0 0 20px">
                {" "}
                {title && <FormattedText value={title} />}
              </H5Tag>
            )}
            <ParaTag
              fontSize={device == "lg" ? "large" : "sm"}
              color={theme.greyText}
            >
              {info && <FormattedText value={info} />}
            </ParaTag>
          </div>
          <WhyFeatListWrap>
            {data &&
              data.map((item, index) => {
                return (
                  <WhyBuyItem key={index}>
                    {item.icon && (
                      <ImageComponent src={item.icon} height="24" width="24" />
                    )}
                    <SpanTag>
                      <FormattedText value={item?.info} />
                    </SpanTag>
                  </WhyBuyItem>
                );
              })}
          </WhyFeatListWrap>
          {device == "lg" && (
            <ShieldWrap>
              <ImageComponent
                width="286px"
                height="320px"
                src="https://tripmoneycmsimgak.mmtcdn.com/img/Light_Green_Shield_5cb1aff4ce.svg"
              />
            </ShieldWrap>
          )}
        </FlexComp>
      </InnerContentWrap>
    </WhyBuyTravInsStyle>
  );
};

export default WhyBuyTravIns;
