import styled from "styled-components";
import { deviceType } from "@tm/static/utils";
import { InnerContentWrap } from "../../CommonStyle/CommonStyles.style";
import { FlexCompStyle } from "../FlexComp/FlexComp.style";
import { H4TagStyle, H2TagStyle } from "../TextStyles/TextStyles.style";
import { PARTNER, PartnerType } from "@tm/insurance/constants";

type Props = {
  pageTheme?: PartnerType;
};

export const WhatsCoveredStyle = styled.section<Props>`
  background: #fff;
  width: 100%;
  ${H2TagStyle} {
    max-width: 660px;
    font-weight: 900;
  }
  ${(p) =>
    p.pageTheme === PARTNER.MMT &&
    `
    ${H4TagStyle}{
        font-size:24px;
        font-weight:900;
    }
    `}
  @media ${deviceType.mobile} {
    padding: 0 16px;
    ${InnerContentWrap} {
      > ${FlexCompStyle} {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        margin-right: -16px;
        margin-left: -16px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
`;
export const BenefitItem = styled.div`
  flex: 1;
  @media ${deviceType.mobile} {
    width: 230px;
    flex-shrink: 0;
    flex: none;
  }
`;
