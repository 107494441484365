import {
  FlexComp,
  ParaTag,
  SpanTag,
  AnchorTag,
  ImageComponent,
  FormattedText,
} from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { theme } from "@tm/utility/global";

import { InsuranceBookedProps } from "./type";
import {
  InsuranceBookedPackStyle,
  ViewPlanBenefitStyle,
} from "./InsuranceBooked.style";

const InsuranceBookedPack = ({
  selectedPlanData,
  viewBenefitsCta,
  handleViewBenefits,
}: InsuranceBookedProps) => {
  const device = useBreakpointV2();
  const {
    planType,
    planName,
    vendorName,
    vendorIcon,
    settleMentRatio,
    planTypeColor,
  } = selectedPlanData || {};

  return (
    <InsuranceBookedPackStyle>
      <ParaTag fontSize="xs" fontWeight="black">
        <FormattedText value={planType} />
      </ParaTag>
      <ViewPlanBenefitStyle>
        <SpanTag fontWeight="black" fontSize="md" color={theme.blackText}>
          <FormattedText value={planName} />
        </SpanTag>
        {viewBenefitsCta && (
          <AnchorTag
            fontSize="sm"
            fontWeight="black"
            handleClick={handleViewBenefits}
          >
            <FormattedText value={viewBenefitsCta} />
          </AnchorTag>
        )}
      </ViewPlanBenefitStyle>
      <FlexComp
        alignItems="center"
        borderBottom="none"
        padding="0"
        gap="14px"
        margin="0"
      >
        {vendorIcon && (
          <ImageComponent width="48px" height="48px" src={vendorIcon} />
        )}
        <div>
          <ParaTag fontSize="sm" fontWeight="black" color={theme.blackText}>
            <FormattedText value={vendorName} />
          </ParaTag>
          <SpanTag fontSize="xs" color={theme.blackText}>
            <FormattedText value={settleMentRatio} />
          </SpanTag>
        </div>
      </FlexComp>
    </InsuranceBookedPackStyle>
  );
};

export default InsuranceBookedPack;
