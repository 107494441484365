import React, { useState } from "react";
import {
  FlexComp,
  ImageTag,
  ParaTag,
  ShimmerVersion2,
  SpanTag,
} from "@tm/static";
import {
  OrderDetailsStyle,
  OrderDetailsSectionOne,
  OrderDetailsSectionTwo,
  OrderDetailsSectionThree,
  OrderDetailsList,
} from "./OrderDetails.style";
import { theme } from "@tm/utility/global";

interface orderDetailsProp {
  margin?: string;
  orderDetails?: {
    planName?: string;
    packName?: string;
    insPackName?: string;
    packColor?: string;
    packImg?: string;
    orderDetailAmt?: {
      orderDetail?: string;
      info?: string;
    }[];
  };
}

const OrderDetailsShimmer = ({ orderDetails, margin }: orderDetailsProp) => {
  return (
    <OrderDetailsStyle margin={margin}>
      <OrderDetailsSectionOne>
        <ShimmerVersion2 width="300px" height="20px" />
        <ShimmerVersion2 width="136px" height="20px" />
      </OrderDetailsSectionOne>
      <OrderDetailsSectionTwo></OrderDetailsSectionTwo>
      <OrderDetailsList>
        {orderDetails?.orderDetailAmt &&
          orderDetails?.orderDetailAmt?.map((item, index) => {
            return (
              <FlexComp
                justifyContent="space-between"
                borderBottom={"1px dashed #dbdbdb"}
                padding={
                  index == (orderDetails?.orderDetailAmt?.length ?? 0) - 1
                    ? "0 30px 16px"
                    : "0 30px 8px"
                }
                margin="0 0 8px"
                key={index}
              >
                <ShimmerVersion2 width="144px" height="18px" />
                <ShimmerVersion2 width="144px" height="18px" />
              </FlexComp>
            );
          })}
      </OrderDetailsList>
    </OrderDetailsStyle>
  );
};

export default OrderDetailsShimmer;
