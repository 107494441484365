import { Modal, ParaTag, LandingButton, FlexComp } from "@tm/static";
import { CloseIcon } from "@tm/static/icons";
import { useBreakpoint } from "@tm/ui/hooks";
import { theme } from "@tm/utility/global";
import { BtnProp } from "../../LandingButton/ButtonType";

import {
  ProductDescriptionStyle,
  CloseIconCtr,
} from "./ProductDescription.style";

interface ProductDescriptionProps {
  onModalClose?: () => void;
  bgImg: string;
  label?: string;
  subLabel?: string;
  solidBtn?: BtnProp;
}

const ProductDescription = ({
  onModalClose = () => {},
  label,
  subLabel,
  bgImg,
  solidBtn,
}: ProductDescriptionProps) => {
  const device = useBreakpoint();

  return (
    <Modal
      padding="0px"
      onModalClose={onModalClose}
      modalWidth={480}
      // closeButton={<CloseIcon />}
      modalType={device === "lg" ? "centered" : "bottomSheet"}
    >
      <ProductDescriptionStyle bgImg={bgImg}>
        {device === "lg" && (
          <CloseIconCtr>
            <CloseIcon onPress={onModalClose} />
          </CloseIconCtr>
        )}
        {label && (
          <ParaTag margin={"0px 0px 30px"} fontSize="xxlarge" fontWeight="bold">
            {label}
          </ParaTag>
        )}
        {subLabel && (
          <ParaTag margin={"0px 0px 70px"} color={theme.greyText}>
            {subLabel}
          </ParaTag>
        )}
        {solidBtn && (
          <LandingButton
            {...solidBtn}
            padding="0px 24px"
            customWidth={"100%"}
          />
        )}
      </ProductDescriptionStyle>
    </Modal>
  );
};

export default ProductDescription;
