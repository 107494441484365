import { theme } from "@tm/utility/global";
import styled from "styled-components";
import { SpanTagStyle } from "../TextStyles/TextStyles.style";
import { deviceType } from "libs/ui-widgets/src/lib/static/utils";
import { FlexCompStyle } from "../FlexComp/FlexComp.style";

export const ListingEditDetailsStyle = styled.div`
  background: #fff;
  border: 1px solid rgba(242, 242, 242, 0.16);
  /* box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.16); */
  border-radius: 8px;
  padding: 24px;
  & > ${FlexCompStyle}:first-child {
    margin-bottom: 8px;
  }
  @media ${deviceType.mobile} {
    padding: 12px 16px;
    & > ${FlexCompStyle}:first-child {
      margin-bottom: 0px;
    }
  }
`;

export const ListingWidgetDetails = styled.div`
  background: #f6f6f6;
  border-radius: 4px;
  padding: 12px;
`;

export const ListingSpan = styled(SpanTagStyle)`
  font-size: 14px;
  line-height: 20px;
  color: ${theme.blackText};
  padding-right: 12px;
  margin-right: 12px;
  position: relative;
  flex-shrink: 0;
  &:last-child {
    border: none;
    flex-shrink: unset;
    /* font-size:12px;
        font-weight:700; */
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 4px;
    width: 1px;
    height: 14px;
    background: #000;
  }
  &:last-child:after {
    width: 0;
  }
  @media ${deviceType.mobile} {
    font-size: 12px;
  }
`;
