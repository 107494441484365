import { theme } from "@tm/utility/global";
import styled from "styled-components";

export const PANCardWpr: any = styled.div`
  display: flex;
  background: linear-gradient(59.34deg, #dac6db 1.63%, #8ec5f2 102.73%);
  gap: 16px;
  padding: 24px 16px;
  flex-direction: column;
  border-radius: 8px;
  align-self: stretch;
  ${(props: any) => props.containerStyle}
`;

export const PANInputContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FetchedNameContainer: any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 4px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
`;

export const IconContainer: any = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: ${theme.persianGreen};
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const PANReqdContainer: any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const InfoLeftContainer: any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
