import styled from "styled-components";
import { deviceType } from "../../utils";

export const CarouselCntWrp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 16px;
  @media ${deviceType.mobile} {
    margin: 32px 16px -32px 16px;
  }
`;

export const CarouselCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  border-radius: 16px;
`;

export const CarouselSlider = styled.div<any>`
  white-space: nowrap;
  width: 100%;
  transform: ${(props) =>
    props.translate ? `translate(${props.translate}%)` : `#fff`};
  transition: ease 1000ms;
`;

interface CarouselElmntProps {
  bgColor?: string;
}

export const CarouselElmnt = styled.div<CarouselElmntProps>`
  display: inline-block;
  height: 280px;
  width: 100%;
  border-radius: 16px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : `#fff`)};
  cursor: pointer;
  @media ${deviceType.mobile} {
    height: 180px;
  }
`;

export const SlideShowDotsCnt = styled.div`
  display: flex;
  align-self: center;
`;

interface SlideShowDotProps {
  isActive: boolean;
}

export const SlideShowDot = styled.div<SlideShowDotProps>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
  margin: 12px 4px 0px;
  background-color: ${(props) => (props.isActive ? `#2276E3` : `#c4c4c4`)};
`;
