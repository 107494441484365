import React from "react";
import { ImageTag, Modal, ParaTag, SpanTag } from "@tm/static";
import {
  ConversionRatesStyle,
  BlueBox,
  LabelsWpr,
  ListItems,
  ListItem,
  LeftCont,
  SubLabelWpr,
  SubLabel,
  Banner,
} from "./ConversionRates.style";

import { useBreakpoint } from "../../../../hooks/useBreakpoint";

import { CloseIcon } from "../../../icons/CloseIcon";

import { ConversionRatesProps } from "./types";

const ConversionRates: React.FC<ConversionRatesProps> = ({
  modalHeading,
  onModalClose,
  closeButton = <CloseIcon />,
  data,
}) => {
  const { listLabels, labelsList, listItems } = data;

  const device = useBreakpoint();

  return (
    <Modal
      modalHeading={modalHeading}
      onModalClose={onModalClose}
      closeButton={closeButton}
      modalWidth={420}
      modalHeadingCtrMargin={
        device === "lg" ? "0px 0px 8px 0px" : "0px 0px 14px 0px"
      }
      modalType={device === "lg" ? "centered" : "bottomSheet"}
    >
      <ConversionRatesStyle>
        {labelsList && (
          <BlueBox>
            {labelsList.map((item, idx) => (
              <ParaTag
                key={`${idx}`}
                margin={
                  idx < labelsList.length - 1 ? "0px 0px 16px 0px" : "0px"
                }
                fontSize="xs"
                fontWeight="bold"
              >
                {item}
              </ParaTag>
            ))}
          </BlueBox>
        )}
        {listLabels && (
          <LabelsWpr>
            {listLabels.label && (
              <SpanTag fontWeight="bold" fontSize="xs" color="#4a4a4a">
                {listLabels.label}
              </SpanTag>
            )}
            {listLabels.subLabel && (
              <SpanTag
                fontWeight="bold"
                fontSize="xs"
                margin="0px 0px 0px auto"
              >
                {listLabels.subLabel}
              </SpanTag>
            )}
          </LabelsWpr>
        )}
        {listItems && (
          <ListItems>
            {listItems.map((item) => (
              <ListItem>
                <LeftCont>
                  <ParaTag fontWeight="bold" fontSize="xs">
                    {item.label}
                  </ParaTag>
                  <SubLabelWpr>
                    {item.subLabel && (
                      <SubLabel
                        dangerouslySetInnerHTML={{ __html: item.subLabel }}
                      />
                    )}
                    {item.banner && <Banner>{item.banner}</Banner>}
                    {item.icon && <ImageTag src={item.icon} />}
                  </SubLabelWpr>
                </LeftCont>
                {item.price && (
                  <SpanTag fontWeight="bold" fontSize="xs">
                    {item.price}
                  </SpanTag>
                )}
              </ListItem>
            ))}
          </ListItems>
        )}
      </ConversionRatesStyle>
    </Modal>
  );
};

export default ConversionRates;
