import { CurrencySEOMarkdown } from "../CurrencySEOMarkdown";

export default function HowBestPrice({ currencyData }: any) {
  if (!currencyData?.bestRateComparison) {
    return null;
  }
  return (
    <>
      <div className="separator"></div>
      <h2 className="h2">{currencyData?.bestRateComparison?.title}</h2>

      <ul className="bestRate mt20">
        {currencyData?.bestRateComparison?.paraList?.map(
          (item: any, index: number) => (
            <li key={index}>
              <CurrencySEOMarkdown>{item}</CurrencySEOMarkdown>
            </li>
          )
        )}
      </ul>
    </>
  );
}
