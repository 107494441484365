import styled from "styled-components";

import { deviceType } from "@tm/static/utils";
import { theme } from "@tm/utility/global";

type LandingFaqTitleProps = {
  marginBottom?: string;
  marginLeft?: string;
  customFaq?: string;
  margin?: string;
};

export interface LandingFaqProps {
  customFaq?: string;
  margin?: string;
}

export const LandingFaqStyle = styled.section<LandingFaqProps>`
  ${(props) => props.margin && `margin: ${props.margin};`}
  display: flex;
  background: #fff;
  flex-direction: column;
  width: 100%;

  ${(props) => props.customFaq && ``}
`;

export const LandingFaqTitle = styled.h3<LandingFaqTitleProps>`
  font-family: "Lato";
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "56px"};
  color: ${theme.blackText};

  @media screen and ${deviceType.mobile} {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 8px;
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
  }
`;

export const LandingFaqList = styled.ul<LandingFaqProps>`
  width: 1000px;
  gap: 16px;

  li {
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
  }

  li h5 {
    padding: 24px;

    @media screen and ${deviceType.mobile} {
      padding: 16px;
    }
  }

  li p {
    padding: 0 24px 24px;
  }

  li:after {
    content: "";
    position: absolute;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    right: 24px;
    top: 33px;
    transition: 0.3s ease-in;

    @media screen and ${deviceType.mobile} {
      top: 16px;
    }
  }

  li.close:after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  li.open:after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  li:hover {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
  }

  li:last-child {
    margin-bottom: unset;
  }

  ${(props) =>
    props.customFaq &&
    `
            width:100%;
            gap:8px;
            margin-bottom: 24px;
            li {
                padding: 12px 16px;
                background: #FFFFFF;
                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
                border-radius: 6px;
            }
            li h5 {
                padding: unset;
            }
            li p {
                padding:unset;
                font-size: 12px;
                line-height: 16px;
                color: #000000;
            }
            li:after {
                border: solid #008CFF;
                border-width: 0 2px 2px 0;
                padding: 4px;
                right: 24px;
                top: 13px;
            }
    `}

  @media screen and ${deviceType.mobile} {
    width: 100%;
  }
`;

export const LandingFaqQues = styled.h5<LandingFaqProps>`
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  ${(props) =>
    props.customFaq &&
    `
        font-size: 12px;
        line-height: 16px;
        color: #000000;`}

  @media screen and ${deviceType.mobile} {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`;
