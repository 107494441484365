import styled from "styled-components";

export const CalenderHeaderWrap = styled.div`
  background-color: #fff;
  padding: 12px 16px;
  position: sticky;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 6;
`;

export const CalHeaderBackBtn = styled.button`
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: 0;
  border: 0;
  position: relative;
  flex-shrink: 0;
  margin-right: 15px;
  &:after {
    content: "";
    border: solid #000;
    border-width: 0 2px 2px 0;
    padding: 5px;
    transform: rotate(135deg);
    position: absolute;
    left: 2;
  }
  &:before {
    width: 16px;
    height: 2px;
    background-color: #000;
    content: "";
    flex-shrink: 0;
  }
`;
