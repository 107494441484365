import React, { useState } from "react";
import {
  LandingForeignCurrStyle,
  ThankCurrInfo,
  LandingForeignCurrTitle,
  LandingForeignCurrList,
  BgImgWrap,
} from "./LandingForeignCurr.style";
import { SpanTag, LandingButton } from "@tm/static";

interface landingForeignProps {
  currThankYou?: boolean;
}

const LandingForeignCurr = ({ currThankYou }: landingForeignProps) => {
  return (
    <LandingForeignCurrStyle currThankYou={currThankYou}>
      {currThankYou ? (
        <BgImgWrap src="../../images/forrCurrBg.png" alt="" />
      ) : (
        <img src="../../images/foreignCurrBg.png" alt="" />
      )}
      {currThankYou && (
        <ThankCurrInfo>FOREIGN CURRENCY AT YOUR DOOR STEP</ThankCurrInfo>
      )}
      <LandingForeignCurrTitle currThankYou={currThankYou}>
        {currThankYou
          ? "We can deliver some physical currency too if you need, Shall we ?"
          : "Looking for foreign currency"}
        ?
      </LandingForeignCurrTitle>
      {!currThankYou && (
        <LandingForeignCurrList>
          <li>
            <SpanTag fontWeight="bold">100% Digital</SpanTag> Control
          </li>
          <li>No Mark-ups</li>
          <li>
            <SpanTag fontWeight="bold">Easy money loading</SpanTag> in INR
          </li>
        </LandingForeignCurrList>
      )}
      {currThankYou && (
        <LandingForeignCurrList currThankYou={true}>
          <li>
            <SpanTag fontWeight="bold">100% Digital</SpanTag> Control
          </li>
          <li>No Mark-ups</li>
          <li>
            <SpanTag fontWeight="bold">Easy money loading</SpanTag> in INR
          </li>
        </LandingForeignCurrList>
      )}
      <LandingButton
        customTitle={currThankYou ? "GET CURRENCIES" : "Explore Forex currency"}
        customWidth={currThankYou ? "178px" : ""}
      />
    </LandingForeignCurrStyle>
  );
};

export default LandingForeignCurr;
