import styled from "styled-components";
import { deviceType } from "libs/ui-widgets/src/lib/static/utils";

export interface OrderStyleProps {
  margin?: string;
}

export const OrderDetailsStyle = styled.div<OrderStyleProps>`
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.179518);
  border-radius: 16px;
  overflow: hidden;
  margin: ${(props) => props.margin};
  picture,
  img {
    flex-shrink: 0;
  }
  @media ${deviceType.mobile} {
    padding-bottom: 16px;
  }
`;

export const OrderDetailsSectionOne = styled.div`
  padding: 16px 30px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #000000;
`;
export const OrderDetailsSectionTwo = styled.div`
  padding: 16px 30px;
  background: linear-gradient(93.36deg, #f2eae6 -17.08%, #ffffff 118.39%);
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
  min-height: 90px;
  .imgWrap {
    width: 145px;
    height: 88px;
  }
`;
export const OrderDetailsSectionThree = styled.div`
  padding: 16px 30px;
  background: #fff;
  display: flex;
  flex-direction: column;
  border: 1px dashed #fafafa;
  background: #fafafa;
  margin: 8px 0;
`;

export const OrderDetailsList = styled.div`
  border-top: 1px dashed #d8d8d8;
  padding-top: 16px;
  padding-bottom: 2px;
`;
