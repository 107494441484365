import * as React from "react";
interface TmLogoInterface {
  darkHeader?: boolean;
  lightHeader?: boolean;
}
const TripMoneyLogo = ({ darkHeader, lightHeader }: TmLogoInterface) => (
  <svg
    width={140}
    height={39}
    viewBox="0 0 140 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.50765 10.3113V17.873C6.50765 18.5604 6.73679 18.7896 6.87427 18.8812C7.14924 19.0187 7.47004 19.1104 7.8825 19.1104C8.2033 19.1104 9.1657 18.8354 9.71564 18.6062L10.3114 18.4229V21.3101L10.0823 21.4476C9.25735 21.8601 8.38661 22.0892 7.47004 22.0892C3.89542 22.0892 3.11634 19.9353 3.11634 18.1021V10.3113H0L6.50765 3.75781V7.33243H10.3572V10.3113H6.50765Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M21.0806 7.05664V10.2646H20.3932C19.4308 10.2646 18.5142 10.4479 17.6435 10.8146C16.8644 11.1812 16.2686 11.6395 15.8103 12.2353V21.7218H12.4648V7.33161H14.8938L15.4895 9.07309C16.7727 7.74407 18.4225 7.10247 20.3473 7.10247H21.0806V7.05664Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M26.3506 3.57547C26.3506 4.53787 25.5716 5.31695 24.6092 5.31695C23.6468 5.31695 22.8677 4.53787 22.8677 3.57547C22.8677 2.61307 23.6468 1.83398 24.6092 1.83398C25.5257 1.83398 26.3506 2.61307 26.3506 3.57547Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M26.2132 7.33203H22.8677V21.8138H26.2132V7.33203Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M41.886 9.074C40.5112 7.69915 38.9072 7.01172 37.074 7.01172C35.2409 7.01172 33.4994 8.1116 32.7203 8.70737L32.0329 7.33252H29.604V27.9095H32.9495V20.6228C34.141 21.5852 35.5159 22.0893 37.0282 22.0893C38.8155 22.0893 40.3737 21.4477 41.7027 20.1645L41.8402 20.027C43.2151 18.6522 43.9025 16.819 43.9025 14.5734C43.9483 12.282 43.2609 10.4489 41.886 9.074ZM36.7074 18.8813C35.2867 18.8813 34.0494 18.3314 32.9495 17.1856V11.9154C34.0494 10.7697 35.2409 10.2197 36.7074 10.2197C37.899 10.2197 38.8155 10.5863 39.5488 11.3196C40.282 12.0529 40.6028 13.1527 40.6028 14.5276C40.6028 15.9024 40.2362 17.0023 39.5488 17.7356C38.8155 18.5147 37.899 18.8813 36.7074 18.8813Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M90.3266 9.11892C88.9976 7.74407 87.2103 7.05664 85.0563 7.05664C82.9024 7.05664 81.1151 7.74407 79.7861 9.11892C78.457 10.4938 77.8154 12.3269 77.8154 14.5267C77.8154 16.7723 78.5029 18.5596 79.7861 19.9344C81.1151 21.3093 82.9024 21.9967 85.0563 21.9967C87.2103 21.9967 88.9976 21.3093 90.3266 19.9344C91.6556 18.5596 92.3431 16.7265 92.3431 14.5267C92.2972 12.3269 91.6098 10.4938 90.3266 9.11892ZM85.0563 18.9262C84.0023 18.9262 83.0857 18.5596 82.3066 17.8263C81.5275 17.1389 81.1151 15.9932 81.1151 14.5267C81.1151 13.0602 81.5275 11.9603 82.2608 11.227C83.0399 10.4938 83.9565 10.1271 85.0105 10.1271C86.0646 10.1271 86.9811 10.4938 87.7602 11.227C88.5393 11.9145 88.9059 13.0143 88.9059 14.5267C88.9059 15.9932 88.4935 17.1389 87.7602 17.8263C87.027 18.5596 86.1104 18.9262 85.0563 18.9262Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M107.788 12.6935V21.7676H104.443V12.9685C104.443 11.0437 103.664 10.173 101.968 10.173C100.593 10.173 99.3099 10.9521 98.0725 12.4186V21.7676H94.7271V7.33161H97.156L97.7976 9.07309C99.2182 7.74407 100.776 7.05664 102.564 7.05664C104.168 7.05664 105.451 7.60658 106.413 8.70647C107.33 9.76052 107.788 11.1354 107.788 12.6935Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M122.132 17.5981C120.849 18.423 119.474 18.8355 118.008 18.8355C116.541 18.8355 115.441 18.5147 114.754 17.9189C114.158 17.4148 113.791 16.7274 113.608 15.8108H123.782V14.0235C123.69 12.1445 123.14 10.5405 122.086 9.21149C120.941 7.74497 119.199 7.01172 116.999 7.01172C114.8 7.01172 113.104 7.74497 111.912 9.21149C110.767 10.678 110.171 12.5111 110.171 14.7109C110.171 16.9107 110.812 18.698 112.142 20.027C113.425 21.356 115.166 21.9976 117.32 21.9976C119.382 21.9976 121.261 21.5394 122.82 20.6686L123.003 20.6228L122.82 17.1856L122.132 17.5981ZM113.562 12.9694C113.7 12.0529 114.066 11.3654 114.616 10.9071C115.304 10.3114 116.083 10.0364 116.954 10.0364C117.824 10.0364 118.603 10.3114 119.291 10.9071C119.887 11.4113 120.253 12.0987 120.345 12.9694H113.562Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M139.18 7.33203L138.951 7.9278L130.656 27.909H127.219L129.694 21.5389L123.919 7.33203H127.494L131.481 17.3685L135.606 7.33203H139.18Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M46.2866 7.43108C46.2866 2.92739 49.214 0 53.7177 0H68.1858C72.6895 0 75.6169 2.92739 75.6169 7.43108V21.8992C75.6169 26.4028 72.6895 29.3302 68.1858 29.3302H53.7177C49.214 29.3302 46.2866 26.4028 46.2866 21.8992V7.43108Z"
      fill="#ED1C24"
    />
    <mask
      id="mask0_841_14902"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={46}
      y={0}
      width={30}
      height={30}
    >
      <path
        d="M46.2866 7.43108C46.2866 2.92739 49.214 0 53.7177 0H68.1858C72.6895 0 75.6169 2.92739 75.6169 7.43108V21.8992C75.6169 26.4028 72.6895 29.3302 68.1858 29.3302H53.7177C49.214 29.3302 46.2866 26.4028 46.2866 21.8992V7.43108Z"
        fill="#ED1C24"
      />
    </mask>
    <g mask="url(#mask0_841_14902)">
      <path
        d="M66.7918 14.4442C66.5085 15.7447 66.2613 19.2464 66.228 20.0858C66.1947 20.9251 66.4676 21.4404 67.1385 21.7724C67.7125 22.0566 68.2539 22.0493 68.7759 21.6781C68.9716 21.5389 68.6731 19.3183 68.7759 17.812C68.9895 14.6816 70.4515 9.95402 70.4515 9.95402L70.4557 9.92885C70.4818 9.77456 70.5076 9.62172 70.5076 9.49311C70.5076 8.68021 70.11 8.16544 69.2589 8.08418C68.3798 8.00267 67.9247 8.40948 67.131 9.33057C65.3705 11.4168 63.2701 14.5333 62.2771 15.9698C62.1643 16.1314 61.9651 16.0778 61.9651 15.8609C61.9651 15.2108 64.3308 8.27558 64.3308 8.27558C64.3308 8.27558 64.6093 7.32538 63.7975 6.99643C63.7975 6.99643 62.5787 6.2648 61.5449 7.71521L56.7454 13.9811C56.3686 14.4607 56.1816 14.2832 56.1816 14.2832C56.0645 14.2262 56.14 13.8688 56.14 13.8688L56.8968 9.78421C57.5026 6.64396 56.3486 6.47876 55.6272 6.38609C54.9062 6.29343 54.0405 6.81183 54.0405 6.81183C52.5402 7.53085 51.4081 10.3916 51.4081 10.3916C51.4081 10.3916 51.0689 11.4095 51.7836 11.802C51.7836 11.802 52.0552 12.0174 52.5824 11.999C53.6302 11.9621 53.8482 10.0549 54.6145 10.0549C54.757 10.0549 54.757 10.2175 54.757 10.2446L52.2785 20.2027C52.2785 20.2027 51.9945 21.1888 52.8982 21.7164C54.0538 22.3908 54.802 21.2698 54.802 21.2698L55.6687 19.9322C56.9921 17.7189 59.3906 14.5461 59.6457 14.5461C59.7311 14.5461 59.759 14.574 59.759 14.6541L58.9117 20.4897C58.9117 20.4897 58.7287 21.4556 59.6236 21.7724C59.6236 21.7724 60.4968 22.0648 60.9994 21.6781C60.9994 21.6781 61.6249 21.2735 62.2484 20.2971C62.7291 19.5441 65.1464 16.8279 66.6216 14.3356C66.7356 14.1728 66.8205 14.3082 66.7918 14.4442Z"
        fill="white"
      />
    </g>
    <path
      d="M32.4 37.304L31.7584 35.7459H29.7877L29.192 37.304H28.5962L30.521 32.4004H31.0251L32.9499 37.304H32.4ZM31.6209 35.2418L31.0251 33.6836C31.0251 33.6378 30.9793 33.5919 30.9793 33.5003C30.9334 33.4086 30.9334 33.317 30.8876 33.2253C30.8418 33.1336 30.8418 33.042 30.796 32.9962C30.796 33.0878 30.7501 33.1795 30.7043 33.2711C30.6585 33.3628 30.6585 33.4544 30.6126 33.5003C30.5668 33.5919 30.5668 33.6378 30.5668 33.6836L29.971 35.2418H31.6209Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M37.6705 37.304L36.0206 32.9503H35.9748C35.9748 33.042 35.9748 33.1336 36.0206 33.2711C36.0206 33.4086 36.0206 33.5461 36.0206 33.6836C36.0206 33.8211 36.0206 34.0044 36.0206 34.1419V37.2582H35.4707V32.4004H36.3414L37.8996 36.4791H37.9454L39.5494 32.4004H40.3743V37.304H39.8244V34.1419C39.8244 34.0044 39.8244 33.8669 39.8244 33.6836C39.8244 33.5461 39.8244 33.4086 39.8244 33.2711C39.8244 33.1336 39.8244 33.042 39.8244 32.9503H39.7786L38.0829 37.304H37.6705V37.304Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M43.0317 33.5469C43.4899 33.5469 43.8107 33.6385 44.0399 33.8218C44.269 34.0052 44.3607 34.326 44.3607 34.7842V37.3048H43.9482L43.8566 36.7549H43.8107C43.7191 36.8924 43.5816 36.984 43.4899 37.0757C43.3983 37.1673 43.2608 37.2132 43.0775 37.259C42.94 37.3048 42.7567 37.3048 42.5275 37.3048C42.2984 37.3048 42.1151 37.259 41.9318 37.1673C41.7485 37.0757 41.611 36.984 41.5193 36.8007C41.4277 36.6632 41.3818 36.4341 41.3818 36.2049C41.3818 35.8383 41.5193 35.5633 41.8401 35.38C42.1151 35.1967 42.5734 35.0592 43.1691 35.0592L43.8107 35.0134V34.7842C43.8107 34.4634 43.7191 34.2343 43.5816 34.0968C43.4441 33.9593 43.2608 33.9135 42.9858 33.9135C42.8025 33.9135 42.6192 33.9593 42.4359 34.0052C42.2526 34.051 42.1151 34.1426 41.9318 34.1885L41.7485 33.776C41.9318 33.6844 42.1151 33.6385 42.3442 33.5469C42.5734 33.5927 42.8025 33.5469 43.0317 33.5469ZM43.7649 35.4717L43.215 35.5175C42.7567 35.5175 42.4359 35.6092 42.2526 35.7466C42.0693 35.8841 41.9776 36.0674 41.9776 36.2966C41.9776 36.5257 42.0234 36.6632 42.1609 36.7549C42.2984 36.8465 42.4817 36.8924 42.665 36.8924C42.9858 36.8924 43.2608 36.8007 43.4899 36.6174C43.7191 36.4341 43.8107 36.1591 43.8107 35.7925V35.4717H43.7649Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M46.0563 32.0801V34.784C46.0563 34.8756 46.0563 34.9673 46.0563 35.1048C46.0563 35.2422 46.0563 35.3339 46.0563 35.4256H46.1021C46.1479 35.3797 46.2396 35.2881 46.3313 35.1506C46.4229 35.0589 46.5146 34.9673 46.5604 34.8756L47.7978 33.5924H48.4394L46.927 35.1506L48.531 37.2587H47.8436L46.5146 35.5172L46.0563 35.9297V37.2587H45.5063V32.0801H46.0563Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M50.5937 33.5469C50.9145 33.5469 51.1895 33.6385 51.4187 33.776C51.6478 33.9135 51.8311 34.0968 51.9228 34.3718C52.0603 34.6009 52.1061 34.9217 52.1061 35.2425V35.6092H49.5397C49.5397 36.0216 49.6772 36.3882 49.8605 36.6174C50.0896 36.8465 50.3646 36.9382 50.7312 36.9382C50.9604 36.9382 51.1895 36.9382 51.3728 36.8924C51.5561 36.8465 51.7395 36.8007 51.9228 36.709V37.1215C51.7395 37.2132 51.5561 37.259 51.3728 37.3048C51.1895 37.3506 50.9604 37.3506 50.7312 37.3506C50.3646 37.3506 50.0438 37.259 49.8147 37.1215C49.5397 36.984 49.3564 36.7549 49.2189 36.4799C49.0814 36.2049 48.9897 35.8383 48.9897 35.4717C48.9897 35.0592 49.0356 34.7384 49.1731 34.4634C49.3105 34.1885 49.4939 33.9593 49.723 33.776C49.9521 33.6385 50.2271 33.5469 50.5937 33.5469ZM50.5479 34.0052C50.2271 34.0052 49.998 34.0968 49.8147 34.2801C49.6313 34.4634 49.5397 34.7384 49.4939 35.105H51.4645C51.4645 34.8759 51.4187 34.6926 51.3728 34.5093C51.327 34.326 51.1895 34.2343 51.052 34.1426C50.9604 34.051 50.7771 34.0052 50.5479 34.0052Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M55.314 37.304L53.6642 32.9503H53.6184C53.6184 33.042 53.6184 33.1336 53.6642 33.2711C53.6642 33.4086 53.6642 33.5461 53.6642 33.6836C53.6642 33.8211 53.6642 34.0044 53.6642 34.1419V37.2582H53.1143V32.4004H53.985L55.5432 36.4791H55.589L57.193 32.4004H58.0179V37.304H57.468V34.1419C57.468 34.0044 57.468 33.8669 57.468 33.6836C57.468 33.5461 57.468 33.4086 57.468 33.2711C57.468 33.1336 57.468 33.042 57.468 32.9503L55.7723 37.304H55.314V37.304Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M58.7051 33.6387H59.3008L60.1258 35.7926C60.1716 35.9301 60.2174 36.0218 60.2632 36.1592C60.3091 36.2509 60.3549 36.3884 60.3549 36.48C60.3549 36.5717 60.4007 36.7092 60.4466 36.8008H60.4924C60.5382 36.7092 60.584 36.5259 60.6299 36.3426C60.6757 36.1592 60.7674 35.9759 60.8132 35.7926L61.5923 33.6845H62.188L60.584 37.9007C60.4924 38.1299 60.4007 38.3132 60.2632 38.4965C60.1716 38.6798 60.0341 38.7715 59.8508 38.8631C59.6675 38.9548 59.4842 39.0006 59.255 39.0006C59.1634 39.0006 59.0717 39.0006 58.9801 39.0006C58.8884 39.0006 58.8426 38.9548 58.7509 38.9548V38.4965C58.7967 38.4965 58.8426 38.4965 58.9342 38.5423C59.0259 38.5423 59.0717 38.5423 59.1634 38.5423C59.3008 38.5423 59.4383 38.4965 59.53 38.4507C59.6216 38.4048 59.7133 38.3132 59.805 38.2215C59.8966 38.1299 59.9424 37.9924 59.9883 37.8549L60.1716 37.3508L58.7051 33.6387Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M64.3873 37.304H63.7916V32.9045H62.2334V32.4004H65.9455V32.9045H64.3873V37.304V37.304Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M68.2831 33.5469C68.3747 33.5469 68.4205 33.5469 68.5122 33.5469C68.6039 33.5469 68.6497 33.5469 68.7413 33.5927L68.6497 34.0968C68.6039 34.0968 68.5122 34.051 68.4664 34.051C68.3747 34.051 68.3289 34.051 68.2372 34.051C68.0997 34.051 67.9623 34.0968 67.8248 34.1426C67.6873 34.1885 67.5956 34.2801 67.4581 34.4176C67.3665 34.5093 67.2748 34.6468 67.229 34.8301C67.1832 34.9676 67.1373 35.1509 67.1373 35.3342V37.3048H66.5874V33.6385H67.0457L67.0915 34.326H67.1373C67.229 34.1885 67.3207 34.051 67.4123 33.9593C67.504 33.8677 67.6415 33.776 67.7789 33.6844C67.9623 33.5927 68.0997 33.5469 68.2831 33.5469Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M69.9796 33.6385V37.3048H69.4296V33.6385H69.9796ZM69.7046 32.2637C69.7962 32.2637 69.8879 32.3095 69.9337 32.3553C69.9796 32.4012 70.0254 32.4928 70.0254 32.6303C70.0254 32.7678 69.9796 32.8594 69.9337 32.9053C69.8879 32.9511 69.7962 32.9969 69.7046 32.9969C69.6129 32.9969 69.5213 32.9511 69.4754 32.9053C69.4296 32.8594 69.3838 32.7678 69.3838 32.6303C69.3838 32.4928 69.4296 32.4012 69.4754 32.3553C69.5671 32.2637 69.6129 32.2637 69.7046 32.2637Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M72.9583 33.5469C73.4166 33.5469 73.7832 33.6844 74.0582 34.0052C74.3331 34.326 74.4706 34.7842 74.4706 35.4258C74.4706 35.8383 74.4248 36.2049 74.2873 36.4799C74.1498 36.7549 73.9665 36.984 73.7374 37.1215C73.5082 37.259 73.2332 37.3506 72.9124 37.3506C72.7291 37.3506 72.5458 37.3048 72.4083 37.259C72.2709 37.2132 72.1334 37.1215 72.0417 37.0298C71.9501 36.9382 71.8584 36.8465 71.7667 36.7549H71.7209C71.7209 36.8465 71.7209 36.9382 71.7667 37.0757C71.7667 37.2132 71.7667 37.3048 71.7667 37.3965V38.9546H71.2168V33.6385H71.6751L71.7667 34.1885H71.8126C71.9042 34.0968 71.95 33.9593 72.0875 33.8677C72.1792 33.776 72.3167 33.6844 72.4542 33.6385C72.5458 33.5927 72.7291 33.5469 72.9583 33.5469ZM72.8208 34.0052C72.5458 34.0052 72.3625 34.051 72.1792 34.1426C72.0417 34.2343 71.9042 34.3718 71.8584 34.6009C71.7667 34.7842 71.7667 35.0592 71.7209 35.3342V35.4258C71.7209 35.7466 71.7667 36.0216 71.8126 36.2049C71.9042 36.4341 71.9959 36.5716 72.1334 36.709C72.3167 36.8007 72.5 36.8924 72.775 36.8924C73.0041 36.8924 73.1874 36.8465 73.3707 36.709C73.5082 36.5716 73.6457 36.4341 73.6915 36.2049C73.7832 35.9758 73.829 35.7466 73.829 35.4258C73.829 35.0134 73.7374 34.6468 73.5541 34.3718C73.4624 34.1426 73.1874 34.0052 72.8208 34.0052Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M79.4661 34.7375H81.2076V37.1206C80.9326 37.2122 80.6576 37.2581 80.3827 37.3039C80.1077 37.3497 79.7869 37.3497 79.4661 37.3497C78.962 37.3497 78.5037 37.2581 78.1829 37.0289C77.8163 36.8456 77.5413 36.5248 77.358 36.1582C77.1747 35.7916 77.083 35.3333 77.083 34.8292C77.083 34.325 77.1747 33.8668 77.4038 33.5001C77.5871 33.1335 77.9079 32.8127 78.2745 32.6294C78.6412 32.4002 79.0995 32.3086 79.6494 32.3086C79.9244 32.3086 80.1993 32.3544 80.4285 32.4002C80.6576 32.4461 80.8868 32.5377 81.1159 32.6294L80.8868 33.1335C80.7035 33.0419 80.5201 32.996 80.291 32.9502C80.0619 32.9044 79.8327 32.8585 79.6036 32.8585C79.1911 32.8585 78.8703 32.9502 78.5495 33.0877C78.2745 33.271 78.0454 33.5001 77.9079 33.7751C77.7704 34.0959 77.6788 34.4167 77.6788 34.8292C77.6788 35.2416 77.7246 35.6082 77.8621 35.8832C77.9996 36.204 78.1829 36.4332 78.5037 36.5706C78.7787 36.754 79.1453 36.7998 79.6036 36.7998C79.8327 36.7998 80.016 36.7998 80.1993 36.754C80.3827 36.7081 80.5201 36.7081 80.6576 36.6623V35.15H79.4661V34.7375Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M84.0023 33.5469C84.094 33.5469 84.1398 33.5469 84.2314 33.5469C84.3231 33.5469 84.3689 33.5469 84.4606 33.5927L84.3689 34.0968C84.3231 34.0968 84.2314 34.051 84.1856 34.051C84.094 34.051 84.0481 34.051 83.9565 34.051C83.819 34.051 83.6815 34.0968 83.544 34.1426C83.4065 34.1885 83.3149 34.2801 83.1774 34.4176C83.0857 34.5093 82.9941 34.6468 82.9482 34.8301C82.9024 34.9676 82.8566 35.1509 82.8566 35.3342V37.3048H82.3066V33.6385H82.7649L82.8108 34.326H82.8566C82.9482 34.1885 83.0399 34.051 83.1316 33.9593C83.2232 33.8677 83.3607 33.776 83.4982 33.6844C83.6357 33.5927 83.819 33.5469 84.0023 33.5469Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M88.357 35.4708C88.357 35.7916 88.3112 36.0207 88.2195 36.2957C88.1278 36.5248 88.0362 36.754 87.8987 36.8914C87.7612 37.0748 87.5779 37.1664 87.3488 37.2581C87.1196 37.3497 86.8905 37.3956 86.6155 37.3956C86.3864 37.3956 86.1572 37.3497 85.9281 37.2581C85.7448 37.1664 85.5614 37.0289 85.3781 36.8914C85.2406 36.7081 85.1032 36.5248 85.0115 36.2957C84.9199 36.0665 84.874 35.7916 84.874 35.4708C84.874 35.0583 84.9657 34.7375 85.1032 34.4625C85.2407 34.1876 85.424 33.9584 85.6989 33.8209C85.9739 33.6835 86.2489 33.5918 86.6155 33.5918C86.9363 33.5918 87.2571 33.6835 87.4862 33.8209C87.7612 33.9584 87.9445 34.1876 88.082 34.4625C88.2653 34.6917 88.357 35.0583 88.357 35.4708ZM85.5156 35.4708C85.5156 35.7457 85.5614 36.0207 85.6531 36.2498C85.7448 36.479 85.8364 36.6165 86.0197 36.754C86.203 36.8914 86.3864 36.9373 86.6613 36.9373C86.9363 36.9373 87.1196 36.8914 87.3029 36.754C87.4862 36.6165 87.5779 36.479 87.6696 36.2498C87.7612 36.0207 87.807 35.7916 87.807 35.4708C87.807 35.1958 87.7612 34.9208 87.6696 34.6917C87.5779 34.4625 87.4862 34.3251 87.3029 34.1876C87.1196 34.0501 86.9363 34.0043 86.6613 34.0043C86.2947 34.0043 85.9739 34.1417 85.7906 34.3709C85.6073 34.6459 85.5156 35.0125 85.5156 35.4708Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M92.3888 33.6385V37.3048H91.9305L91.8389 36.8007C91.7472 36.9382 91.6555 37.0298 91.5181 37.1215C91.3806 37.2132 91.2431 37.259 91.1056 37.3048C90.9681 37.3506 90.7848 37.3506 90.6015 37.3506C90.3265 37.3506 90.0515 37.3048 89.8682 37.2132C89.6849 37.1215 89.5016 36.984 89.4099 36.7549C89.3183 36.5716 89.2725 36.2966 89.2725 35.9758V33.5469H89.8224V35.93C89.8224 36.2508 89.9141 36.4799 90.0516 36.6174C90.189 36.7549 90.4182 36.8465 90.6931 36.8465C90.9681 36.8465 91.1973 36.8007 91.3347 36.709C91.5181 36.6174 91.6097 36.4341 91.7014 36.2508C91.793 36.0674 91.793 35.7925 91.793 35.5175V33.5927H92.3888V33.6385Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M95.323 33.5469C95.7813 33.5469 96.1479 33.6844 96.4229 34.0052C96.6979 34.326 96.8354 34.7842 96.8354 35.4258C96.8354 35.8383 96.7895 36.2049 96.6521 36.4799C96.5146 36.7549 96.3313 36.984 96.1021 37.1215C95.873 37.259 95.598 37.3506 95.2772 37.3506C95.0939 37.3506 94.9106 37.3048 94.7731 37.259C94.6356 37.2132 94.4981 37.1215 94.4065 37.0298C94.3148 36.9382 94.2231 36.8465 94.1315 36.7549H94.0857C94.0857 36.8465 94.0857 36.9382 94.1315 37.0757C94.1315 37.2132 94.1315 37.3048 94.1315 37.3965V38.9546H93.5815V33.6385H94.0398L94.1315 34.1885H94.1773C94.269 34.0968 94.3148 33.9593 94.4523 33.8677C94.5439 33.776 94.6814 33.6844 94.8189 33.6385C94.9564 33.5927 95.1397 33.5469 95.323 33.5469ZM95.2314 34.0052C94.9564 34.0052 94.7731 34.051 94.5898 34.1426C94.4523 34.2343 94.3148 34.3718 94.269 34.6009C94.1773 34.7842 94.1773 35.0592 94.1315 35.3342V35.4258C94.1315 35.7466 94.1773 36.0216 94.2231 36.2049C94.3148 36.4341 94.4065 36.5716 94.5439 36.709C94.7273 36.8007 94.9106 36.8924 95.1855 36.8924C95.4147 36.8924 95.598 36.8465 95.7813 36.709C95.9188 36.5716 96.0563 36.4341 96.1021 36.2049C96.1938 35.9758 96.2396 35.7466 96.2396 35.4258C96.2396 35.0134 96.1479 34.6468 95.9646 34.3718C95.8271 34.1426 95.598 34.0052 95.2314 34.0052Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M101.83 32.8127C101.555 32.8127 101.326 32.8585 101.097 32.9502C100.868 33.0419 100.684 33.1793 100.547 33.3627C100.409 33.546 100.272 33.7293 100.18 34.0042C100.088 34.2334 100.043 34.5542 100.043 34.8292C100.043 35.2416 100.088 35.6082 100.226 35.8832C100.363 36.204 100.547 36.4332 100.822 36.5706C101.097 36.754 101.417 36.7998 101.784 36.7998C102.013 36.7998 102.197 36.7998 102.426 36.754C102.609 36.7081 102.792 36.6623 103.021 36.6165V37.1206C102.838 37.1664 102.655 37.2581 102.472 37.2581C102.288 37.3039 102.059 37.3039 101.784 37.3039C101.28 37.3039 100.868 37.2122 100.501 36.9831C100.18 36.754 99.9051 36.479 99.7218 36.1124C99.5385 35.7457 99.4927 35.2874 99.4927 34.7833C99.4927 34.4167 99.5385 34.0959 99.6302 33.7751C99.7218 33.4543 99.9051 33.1793 100.088 32.996C100.272 32.7669 100.547 32.5836 100.822 32.4919C101.097 32.3544 101.463 32.3086 101.83 32.3086C102.059 32.3086 102.334 32.3544 102.563 32.4002C102.792 32.4461 103.021 32.5377 103.205 32.6294L102.976 33.1335C102.838 33.0419 102.655 32.996 102.472 32.9502C102.242 32.8585 102.059 32.8127 101.83 32.8127Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M107.238 35.4708C107.238 35.7916 107.192 36.0207 107.1 36.2957C107.009 36.5248 106.917 36.754 106.78 36.8914C106.642 37.0748 106.459 37.1664 106.23 37.2581C106 37.3497 105.771 37.3956 105.496 37.3956C105.267 37.3956 105.038 37.3497 104.809 37.2581C104.626 37.1664 104.442 37.0289 104.259 36.8914C104.122 36.7081 103.984 36.5248 103.892 36.2957C103.801 36.0665 103.755 35.7916 103.755 35.4708C103.755 35.0583 103.847 34.7375 103.984 34.4625C104.122 34.1876 104.305 33.9584 104.58 33.8209C104.855 33.6835 105.13 33.5918 105.496 33.5918C105.817 33.5918 106.138 33.6835 106.367 33.8209C106.642 33.9584 106.825 34.1876 106.963 34.4625C107.146 34.6917 107.238 35.0583 107.238 35.4708ZM104.396 35.4708C104.396 35.7457 104.442 36.0207 104.534 36.2498C104.626 36.479 104.717 36.6165 104.901 36.754C105.084 36.8914 105.267 36.9373 105.542 36.9373C105.817 36.9373 106 36.8914 106.184 36.754C106.367 36.6165 106.459 36.479 106.55 36.2498C106.642 36.0207 106.688 35.7916 106.688 35.4708C106.688 35.1958 106.642 34.9208 106.55 34.6917C106.459 34.4625 106.367 34.3251 106.184 34.1876C106 34.0501 105.817 34.0043 105.542 34.0043C105.176 34.0043 104.855 34.1417 104.671 34.3709C104.488 34.6459 104.396 35.0125 104.396 35.4708Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M112.234 33.5469C112.646 33.5469 112.967 33.6385 113.15 33.8677C113.379 34.0968 113.471 34.4176 113.471 34.8759V37.3048H112.921V34.9217C112.921 34.6468 112.875 34.4176 112.738 34.2343C112.6 34.0968 112.417 34.0052 112.142 34.0052C111.775 34.0052 111.5 34.0968 111.363 34.326C111.18 34.5093 111.134 34.8301 111.134 35.2425V37.3048H110.584V34.9217C110.584 34.7384 110.538 34.5551 110.492 34.4176C110.446 34.2801 110.355 34.1885 110.217 34.1426C110.126 34.0968 109.942 34.051 109.805 34.051C109.576 34.051 109.346 34.0968 109.209 34.1885C109.071 34.2801 108.934 34.4176 108.888 34.6468C108.842 34.8301 108.797 35.105 108.797 35.38V37.3048H108.247V33.6385H108.705L108.797 34.1426H108.842C108.934 34.0052 109.026 33.9135 109.117 33.8218C109.209 33.7302 109.346 33.6844 109.484 33.6385C109.621 33.5927 109.759 33.5927 109.942 33.5927C110.217 33.5927 110.446 33.6385 110.63 33.7302C110.813 33.8218 110.95 34.0052 111.042 34.1885H111.088C111.225 33.9593 111.363 33.8218 111.592 33.7302C111.73 33.5927 111.959 33.5469 112.234 33.5469Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M116.357 33.5469C116.815 33.5469 117.182 33.6844 117.457 34.0052C117.732 34.326 117.87 34.7842 117.87 35.4258C117.87 35.8383 117.824 36.2049 117.686 36.4799C117.549 36.7549 117.365 36.984 117.136 37.1215C116.907 37.259 116.632 37.3506 116.311 37.3506C116.128 37.3506 115.945 37.3048 115.807 37.259C115.67 37.2132 115.532 37.1215 115.441 37.0298C115.349 36.9382 115.257 36.8465 115.166 36.7549H115.12C115.12 36.8465 115.12 36.9382 115.166 37.0757C115.166 37.2132 115.166 37.3048 115.166 37.3965V38.9546H114.616V33.6385H115.074L115.166 34.1885H115.211C115.303 34.0968 115.395 33.9593 115.486 33.8677C115.578 33.776 115.716 33.6844 115.853 33.6385C115.991 33.5927 116.174 33.5469 116.357 33.5469ZM116.266 34.0052C115.991 34.0052 115.807 34.051 115.624 34.1426C115.486 34.2343 115.349 34.3718 115.303 34.6009C115.211 34.7842 115.211 35.0592 115.166 35.3342V35.4258C115.166 35.7466 115.211 36.0216 115.257 36.2049C115.349 36.4341 115.441 36.5716 115.578 36.709C115.761 36.8007 115.945 36.8924 116.22 36.8924C116.449 36.8924 116.632 36.8465 116.815 36.709C116.953 36.5716 117.09 36.4341 117.136 36.2049C117.228 35.9758 117.274 35.7466 117.274 35.4258C117.274 35.0134 117.182 34.6468 116.999 34.3718C116.861 34.1426 116.632 34.0052 116.266 34.0052Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M120.252 33.5469C120.711 33.5469 121.031 33.6385 121.261 33.8218C121.49 34.0052 121.581 34.326 121.581 34.7842V37.3048H121.169L121.077 36.7549H121.031C120.94 36.8924 120.802 36.984 120.711 37.0757C120.619 37.1673 120.482 37.2132 120.298 37.259C120.161 37.3048 119.977 37.3048 119.748 37.3048C119.519 37.3048 119.336 37.259 119.152 37.1673C118.969 37.0757 118.832 36.984 118.74 36.8007C118.648 36.6632 118.603 36.4341 118.603 36.2049C118.603 35.8383 118.74 35.5633 119.061 35.38C119.336 35.1967 119.794 35.0592 120.39 35.0592L121.031 35.0134V34.7842C121.031 34.4634 120.986 34.2343 120.802 34.0968C120.665 33.9593 120.482 33.9135 120.207 33.9135C120.023 33.9135 119.84 33.9593 119.657 34.0052C119.473 34.051 119.29 34.1426 119.152 34.1885L118.969 33.776C119.152 33.6844 119.336 33.6385 119.565 33.5469C119.794 33.5927 120.023 33.5469 120.252 33.5469ZM121.031 35.4717L120.482 35.5175C120.023 35.5175 119.702 35.6092 119.519 35.7466C119.336 35.8841 119.244 36.0674 119.244 36.2966C119.244 36.5257 119.29 36.6632 119.427 36.7549C119.565 36.8465 119.748 36.8924 119.932 36.8924C120.252 36.8924 120.527 36.8007 120.756 36.6174C120.986 36.4341 121.077 36.1591 121.077 35.7925V35.4717H121.031Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M124.47 33.5469C124.928 33.5469 125.249 33.6385 125.478 33.8677C125.707 34.0968 125.799 34.4176 125.799 34.8759V37.259H125.294V34.9217C125.294 34.6009 125.203 34.3718 125.065 34.2343C124.928 34.0968 124.699 34.0052 124.424 34.0052C124.011 34.0052 123.736 34.1426 123.553 34.3718C123.37 34.6009 123.324 34.9217 123.324 35.38V37.3048H122.774V33.6385H123.232L123.324 34.1885H123.37C123.461 34.051 123.553 33.9593 123.69 33.8677C123.828 33.776 123.965 33.7302 124.103 33.6844C124.149 33.5927 124.332 33.5469 124.47 33.5469Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
    <path
      d="M126.394 33.6387H126.989L127.814 35.7926C127.86 35.9301 127.906 36.0218 127.952 36.1592C127.998 36.2509 128.043 36.3884 128.043 36.48C128.043 36.5717 128.089 36.7092 128.135 36.8008H128.181C128.227 36.7092 128.273 36.5259 128.318 36.3426C128.364 36.1592 128.456 35.9759 128.502 35.7926L129.281 33.6845H129.877L128.273 37.9007C128.181 38.1299 128.089 38.3132 127.952 38.4965C127.86 38.6798 127.723 38.7715 127.539 38.8631C127.356 38.9548 127.173 39.0006 126.944 39.0006C126.852 39.0006 126.76 39.0006 126.669 39.0006C126.577 39.0006 126.531 38.9548 126.439 38.9548V38.4965C126.485 38.4965 126.531 38.4965 126.623 38.5423C126.714 38.5423 126.76 38.5423 126.852 38.5423C126.989 38.5423 127.127 38.4965 127.218 38.4507C127.31 38.4048 127.402 38.3132 127.493 38.2215C127.585 38.1299 127.631 37.9924 127.677 37.8549L127.86 37.3508L126.394 33.6387Z"
      fill={lightHeader ? "white" : darkHeader ? "#124680" : "white"}
    />
  </svg>
);
export { TripMoneyLogo };
