import * as React from "react";
interface dnldProp {}
const DnldIcon = (props: dnldProp) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1.00391}
      width={18}
      height={18}
      rx={4.5}
      stroke="#979797"
      strokeWidth={0.9}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.82002 16.4829L5.5 11.4429H8.37999V4.96289H11.26V11.4429H14.14L9.82002 16.4829Z"
      fill="url(#paint0_linear_841_15524)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_841_15524"
        x1={9.01132}
        y1={23.0926}
        x2={21.2341}
        y2={15.1042}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0772C" />
        <stop offset={1} stopColor="#F95776" />
      </linearGradient>
    </defs>
  </svg>
);
export { DnldIcon };
