import { ParaTag, Spinner } from "@tm/static";
import { Overlay, SpinnerWrapper } from "./Loader.style";
import { theme } from "@tm/utility/global";

interface LoaderProps {
  loaderTxt?: string;
}

const Loader = ({ loaderTxt }: LoaderProps) => {
  return (
    <Overlay>
      <SpinnerWrapper>
        <Spinner spinnerWidth={36} thickness={4} />
        {loaderTxt && (
          <ParaTag fontSize="xs" fontWeight="bold" color={theme.whiteText}>
            {loaderTxt}
          </ParaTag>
        )}
      </SpinnerWrapper>
    </Overlay>
  );
};

export default Loader;
