import { getUserDeviceOS } from "../common";
import { ANDROID, MakeMyTrip } from "../constants";

declare global {
  interface Window {
    app_bridge: any;
    webkit: any;
  }
}

interface LoginOptionType {
  loginId?: string;
  countryCode?: string;
  allowedType?: string;
  editable?: string;
  showReferral?: boolean;
  socialActionEnabled?: string;
  bannerText?: string;
  bannerIcon?: string;
  icon?: string;
  header?: string;
  description?: string;
  bottomSheet?: string;
}

export const loginUser = (loginOptions?: LoginOptionType) => {
  const defaultloginOptions = {
    loginId: "",
    countryCode: "91",
    allowedType: "mobile",
    editable: "true",
    showReferral: false,
    socialActionEnabled: "false",
    bannerText: "",
    bannerIcon: "",
    icon: "",
    header: "login to view bookings",
    description: "",
    bottomSheet: "false",
  };
  loginOptions = { ...defaultloginOptions, ...loginOptions };
  if (getUserDeviceOS() === ANDROID) {
    window?.app_bridge?.login?.(JSON.stringify(loginOptions));
  } else {
    window?.webkit.messageHandlers?.login?.postMessage(loginOptions);
  }
};

export const reloginUser = async (loginOptions?: LoginOptionType) => {
  await logoutUser();
  loginUser(loginOptions);
};

export const reloginEmailUser = async (loginOptions?: LoginOptionType) => {
  await logoutUser();
  loginUser(loginOptions);
};

export const logoutUser = () => {
  const promise = new Promise((resolve, reject) => {
    window.logoutUser = () => {
      resolve(true);
    };
    if (getUserDeviceOS() === ANDROID) {
      window?.app_bridge?.logoutUser();
    } else {
      window?.webkit?.messageHandlers?.logoutUser?.postMessage(
        JSON.stringify(true)
      );
    }
  });
  return promise;
};

export function getTmToken() {
  const promise = new Promise((resolve, reject) => {
    if (getUserDeviceOS() === ANDROID) {
      resolve(window?.app_bridge?.getTMToken());
    } else {
      window.tripMoneyToken = (token) => {
        resolve(token);
      };
      window?.webkit?.messageHandlers?.getTMToken?.postMessage(
        JSON.stringify(true)
      );
    }
  });
  return promise;
}

export function getSLToken(partner: string | null) {
  if (getUserDeviceOS() === ANDROID) {
    window?.app_bridge?.getShortLivToken(
      JSON.stringify({ client: "TM" }),
      "shortLivToken"
    );
  } else {
    if (partner === MakeMyTrip) {
      window?.webkit?.messageHandlers?.getShortLivToken?.postMessage(
        JSON.stringify({ client: "TM" })
      );
    } else {
      window?.webkit?.messageHandlers?.getShortLivToken?.postMessage({
        client: "TM",
      });
    }
  }
}

export function saveTMToken(token: string) {
  if (getUserDeviceOS() === ANDROID) {
    window?.app_bridge?.saveTMToken(JSON.stringify(token));
  } else {
    window?.webkit?.messageHandlers?.saveTMToken?.postMessage(
      JSON.stringify(token)
    );
  }
}

export function getUserLoginStatus() {
  const promise = new Promise((resolve, reject) => {
    if (getUserDeviceOS() === ANDROID) {
      resolve(window?.app_bridge?.getUserLoginStatus());
    } else {
      window.loginStatus = (status: boolean) => {
        resolve(status);
      };
      window?.webkit?.messageHandlers?.getUserLoginStatus?.postMessage(
        JSON.stringify(true)
      );
    }
  });
  return promise;
}

export function getUserProfileType() {
  const promise = new Promise((resolve, reject) => {
    if (getUserDeviceOS() === ANDROID) {
      resolve(window?.app_bridge?.getUserProfileType());
    } else {
      window.profileType = (profileType: string) => {
        resolve({
          profileType,
        });
      };
      if (window?.webkit?.messageHandlers?.getUserProfileType) {
        window?.webkit?.messageHandlers?.getUserProfileType.postMessage(true);
      } else {
        resolve(null);
      }
    }
  });
  return promise;
}
