import React from "react";
import { ShimmerWrap, LandingButtonStyle } from "./LandingButton.style";
import { BtnProp } from "./ButtonType";
import { FormattedText, ImageComponent, SpanTag, Spinner } from "@tm/static";
import { theme } from "@tm/utility/global";
import { usePartnerContext } from "@tm/static/partnerContext";
import { useBreakpointV2 } from "libs/auth/src/lib/hooks/useDeviceContext";

const LandingButton = ({
  customTitle,
  margin,
  customWidth,
  disableState,
  onClick,
  flexShrink,
  shimmerBtn,
  flex,
  btnLeftIcon,
  btnRightIcon,
  isLoading,
  buttonref,
}: BtnProp) => {
  const context = usePartnerContext();

  return (
    <LandingButtonStyle
      ref={buttonref}
      onClick={onClick}
      customWidth={customWidth}
      margin={margin}
      flexShrink={flexShrink}
      flex={flex}
      disableState={disableState}
      btnTheme={context ? context : undefined}
      txtClipIcon={btnLeftIcon !== "" && btnRightIcon !== ""}
    >
      {isLoading ? (
        <Spinner spinnerColor={"#fff"} />
      ) : (
        <React.Fragment>
          {btnLeftIcon && (
            <ImageComponent width="24px" height="24px" src={btnLeftIcon} />
          )}
          {customTitle && (
            <SpanTag color={theme.blueText} fontSize="md" fontWeight="black">
              {customTitle}
            </SpanTag>
          )}
          {shimmerBtn && (
            <ShimmerWrap>
              <img src="../../../images/shimmerBtn.svg" />
            </ShimmerWrap>
          )}
          {btnRightIcon && (
            <ImageComponent
              width="16px"
              height="16px"
              margin="0 0 0 auto"
              src={btnRightIcon}
            />
          )}
        </React.Fragment>
      )}
    </LandingButtonStyle>
  );
};

export default LandingButton;
