import {
  H3Tag,
  SpanTag,
  LandingButton,
  BulletListItem,
  FlexComp,
  ParaTag,
  FormattedText,
  H5Tag,
} from "@tm/static";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { TextNodeType } from "../FormattedText/FormattedText";
import {
  PersuasionBannerStyle,
  BulletListStyle,
} from "./PersuasionBanner.style";

interface PersuasionBannerProps {
  heading?: string | TextNodeType;
  bgImage?: string;
  bulletPoints: any[];
  btnText: string;
  margin?: string | number;
  btnHandler?: () => void;
  info?: string;
}

const PersuasionBanner = ({
  heading,
  bgImage,
  bulletPoints,
  btnText,
  margin,
  btnHandler,
  info,
}: PersuasionBannerProps) => {
  const device = useBreakpointV2();
  return (
    <PersuasionBannerStyle margin={margin} bgImage={bgImage}>
      {device == "lg" ? (
        <H3Tag>
          <FormattedText value={heading} />
        </H3Tag>
      ) : (
        <H5Tag>
          {" "}
          <FormattedText value={heading} />
        </H5Tag>
      )}
      {device === "sm" && (
        <FlexComp gap="6px">
          <ParaTag dangerousText={info}></ParaTag>
        </FlexComp>
      )}
      {bulletPoints?.length > 0 && (
        <BulletListStyle>
          {bulletPoints.map((item) => {
            return (
              <BulletListItem key={item.id}>
                {item.boldText && (
                  <SpanTag fontWeight="bold">{item.boldText}</SpanTag>
                )}{" "}
                <SpanTag>{item.normalText}</SpanTag>
              </BulletListItem>
            );
          })}
        </BulletListStyle>
      )}
      <LandingButton
        customTitle={btnText}
        onClick={btnHandler}
        padding="0px 40px"
        margin="auto 0 0"
      />
    </PersuasionBannerStyle>
  );
};

export default PersuasionBanner;
