import React, { useState, useRef, useMemo, useEffect } from "react";
import {
  FormProvider,
  SubmitHandler,
  SubmitErrorHandler,
  useForm,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { MoreBenefits } from "@tm/static";
import WebToast from "../Toast/WebToast";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { FormattedText, H5Tag, ParaTag, SectionDividers } from "..";
import { theme } from "@tm/utility/global";
import { ClientError } from "@tm/utility";
import TravellerDetailsV2 from "./TravellerDetailsV2";
import { IAddOnPlans, IFilters, paymentCheckout } from "@tm/insurance/api";
import {
  formatDate,
  getFormFieldErrorMsg,
  getPartnerOnClientSide,
  getTravDetailsFromMemory,
  openCheckoutPage,
  setTravDetailsInMemory,
} from "@tm/insurance/utils";
import {
  ERROR_CODES,
  RESPONSE_STATUS,
  GENDER_VALUES,
  DATE_FORMATS,
  constants,
} from "@tm/insurance/constants";
import { getAnalytics } from "@tm/insurance/analytics";
import TravellerCommonComp from "./TravellerCommonComp";
import {
  AddTravellerDetailStyle,
  AddTravellerHd,
  AddButtonWrap,
  WebToastWrap,
} from "./AddTravellerDetail.style";
import { ImageComponent } from "@tm/static";
import {
  IAddTravellerDetailProps,
  IGender,
  ISecondaryContactV2,
  ITravellerDetailsFormV2,
} from "./type";
const analytics = getAnalytics();

const AddTravellerDetails = ({
  title,
  info,
  data,
  countryData,
  travellerCount,
  benefitsData,
  benefitsTitle,
  showBenefits,
  handleCountryCodeModal,
  handleViewBenefits,
  setAddOnProductDetails,
  addOnProductDetails,
}: IAddTravellerDetailProps) => {
  const {
    primaryContact,
    secondaryContact,
    upcomingTravellerDetails,
    savedTravellers,
    note,
    concent,
    totalAmt,
    widgets,
    isDomesticBooking,
    addOns,
    experiment,
    planInfo,
  } = data || {};
  const { addOnPlans, filters } = addOns || {};
  const { dob, name, gender } = secondaryContact?.fields || {};
  const { formErrorToast, addErrorToast } = constants;
  const device = useBreakpointV2();
  const travellerDataFromStore = getTravDetailsFromMemory();
  const { travellerDetails, primaryContactDetails } =
    travellerDataFromStore || {};

  const travellerRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [addTrav, setAddTrav] = useState(-1);
  const [quoteId, setQuoteId] = useState<string | null>(null);
  const [firstErrorIndex, setFirstErrorIndex] = useState(-1);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isSelectedError, setIsSelectedError] = useState<boolean>(false);
  const [isFieldError, setIsFieldError] = useState<boolean>(false);
  const [showOfferToast, setShowOfferToast] = useState<boolean>(true);
  const [addOnFilters, setAddOnFilters] = useState<any>(filters);
  const [addOnPlansData, setAddOnPlansData] = useState<any>(addOnPlans);
  const primaryContactRef = useRef<HTMLInputElement>(null);
  const tncRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (planInfo?.quoteId) {
      setQuoteId(planInfo.quoteId);
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      const quoteId = queryParams.get("quoteId");
      setQuoteId(quoteId);
    }
    setTimeout(() => {
      setShowOfferToast(false);
    }, 3000);
  }, []);

  const methods = useForm<ITravellerDetailsFormV2>({
    defaultValues: {
      travellerData: Array.from({
        length: (savedTravellers?.length ?? 0) + travellerCount,
      }).map(() => ({
        name: "",
        dob: "",
        gender: "",
        isSelected: false,
      })),
      primaryContactData: {
        email: "",
        mobile: "",
      },
      tncs: Array.from({ length: concent?.tnc?.length ?? 0 }).map(() => true),
    },
    mode: "onTouched",
  });

  const {
    control,
    getValues,
    setValue,
    getFieldState,
    setError,
    handleSubmit,
    trigger,
  } = methods;

  const formValues = getValues();

  const travellerData = useWatch({
    control,
    name: "travellerData", // unique name for field array
  });

  const { fields: travellers, append } = useFieldArray({
    control,
    name: "travellerData", // unique name for field array
  });

  const selectedCount = useMemo(
    () =>
      travellerData?.filter((traveller) => traveller.isSelected)?.length ?? 0,
    [travellerData]
  );

  useMemo(() => {
    if (selectedCount === travellerCount) {
      let isValid = true;
      travellerData?.map((data: ISecondaryContactV2) => {
        if (data.isSelected) {
          const validName = name?.isEnabled ? (data.name ? true : false) : true;
          const validDob = dob?.isEnabled ? (data.dob ? true : false) : true;
          const validGender = gender?.isEnabled
            ? data?.gender
              ? true
              : false
            : true;
          if (!(validDob && validGender && validName)) {
            isValid = false;
          }
        }
      });
      setIsValidForm(isValid);
    } else {
      setIsValidForm(false);
    }
  }, [travellerData]);

  // To add saved travellers and upcoming travellers in memory
  useEffect(() => {
    let travellerDetail: any = [];
    const queryParams = new URLSearchParams(window.location.search);
    if (
      !travellerDetails?.length ||
      queryParams.get("isUpcomingTrip") === "true"
    ) {
      if (
        queryParams.get("isUpcomingTrip") === "true" ||
        savedTravellers?.length === 0
      ) {
        upcomingTravellerDetails?.map((data: any) => {
          travellerDetail.push({
            ...data,
            dob: parseInt(data.dob),
            gender:
              data.gender.charAt(0).toUpperCase() +
              data.gender.slice(1).toLowerCase(),
            isSelected: queryParams.get("isUpcomingTrip") === "true",
          });
        });
      }
      savedTravellers?.map((data: any) => {
        travellerDetail.push({
          ...data,
          gender:
            data.gender.charAt(0).toUpperCase() +
            data.gender.slice(1).toLowerCase(),
          isSelected: false,
          isSavedTraveller: true,
          isEdited: false,
        });
      });
      setTravDetailsInMemory({
        ...travellerDataFromStore,
        travellerDetails: travellerDetail,
        primaryContactDetails: primaryContact,
      });
      if (travellerDetail.length === 0) {
        setAddTrav(0);
      }
    } else {
      const selectedTravellersInMemory = travellerDetails?.filter(
        (data: ISecondaryContactV2) => data.isSelected
      );
      const remainingTravellersInMemory = travellerDetails?.filter(
        (data: ISecondaryContactV2) => !data.isSelected
      );
      const queryParams = new URLSearchParams(window.location.search);
      const travellers: number = Number(queryParams.get("travellers"));
      travellerDetail.push(
        ...selectedTravellersInMemory.slice(0, travellers),
        ...selectedTravellersInMemory
          .slice(travellers)
          .map((data: ISecondaryContactV2) => {
            return { ...data, isSelected: false };
          }),
        ...remainingTravellersInMemory
      );
    }
    let length = travellerDetail?.length
      ? travellerDetail?.length
      : upcomingTravellerDetails?.length
      ? upcomingTravellerDetails?.length
      : 1;
    setValue(
      "travellerData",
      Array.from({
        length: length,
      }).map((_, index) => ({
        name: travellerDetail?.[index]?.name || "",
        dob: travellerDetail?.[index]?.isSavedTraveller
          ? travellerDetail?.[index]?.dob || ""
          : travellerDetail?.[index]?.dob || "2000-01-01",
        gender: travellerDetail?.[index]?.gender || "",
        passport: travellerDetail?.[index]?.passport || "",
        isSelected: travellerDetail?.[index]?.isSelected ?? true,
        isEdited: travellerDetail?.[index]?.isEdited ?? true,
        isSavedTraveller: travellerDetail?.[index]?.isSavedTraveller ?? false,
      }))
    );
  }, [secondaryContact, savedTravellers, upcomingTravellerDetails]);

  useEffect(() => {
    setValue("primaryContactData", {
      mobile:
        primaryContactDetails?.mobile ||
        primaryContact?.fields?.mobile?.value ||
        "",
      email:
        primaryContactDetails?.email ||
        primaryContact?.fields?.email?.value ||
        "",
    });
  }, [primaryContact]);

  const selectedCountry = useMemo(() => {
    return countryData?.find((item) => item.isSelected);
  }, [countryData]);

  const handleArrowButton = (index: number) => {
    if (addTrav === index) {
      setAddTrav(-1);
      if (travellerData?.[index].isSelected) {
        trigger(`travellerData.${index}`);
      }
    } else {
      setAddTrav(index);
    }
  };

  const handleGenderChange = (data: IGender, index: number) => {
    setValue(`travellerData.${index}.gender`, data.label);
  };

  const saveFormValuesOnBlur = (index: number = -1) => {
    if (index != -1 && !getValues(`travellerData.${index}.isEdited`)) {
      setValue(`travellerData.${index}.isEdited`, true);
    }
    const { travellerData, primaryContactData } = formValues;
    setTravDetailsInMemory({
      ...travellerDataFromStore,
      travellerDetails: travellerData,
      primaryContactDetails: primaryContactData,
    });
  };

  const getTravellerDetailList = (data: ITravellerDetailsFormV2) => {
    const {
      travellerData,
      primaryContactData: { email = "", mobile = "" } = {},
    } = data || {};

    const travellerList = travellerData
      ?.map((traveller, index) => {
        if (traveller.isSelected) {
          return {
            travellerId: index.toString(),
            name: traveller.name.trim(),
            dob: formatDate(traveller.dob, DATE_FORMATS["DD/MM/YYYY"]),
            gender: traveller.gender,
            mobile: `${selectedCountry?.countryCode || "+91"}${mobile.trim()}`,
            isEdited: traveller?.isEdited ?? false,
            passport: traveller?.passport ?? "",
            email: email,
            countryCode: "",
          };
        } else {
          return null;
        }
      })
      .filter((data) => data != null);
    return travellerList;
  };

  const handleTncCheckbox = (index: number) => {
    const value = formValues.tncs[index];
    setValue(`tncs.${index}`, !value);
    trigger(`tncs.${index}`);
  };

  const handleServerErrors = (error: any) => {
    const {
      displayPrimaryMobileError = "",
      displayPrimaryEmailError = "",
      displaySecondaryError = [],
    } = error?.validationError || {};

    if (displayPrimaryMobileError) {
      setError("primaryContactData.mobile", {
        message: displayPrimaryMobileError,
      });
      analytics?.trackTravDetailsEvents({
        actionType: "error_shown",
        payload: displayPrimaryMobileError,
      });
    }

    if (displayPrimaryEmailError) {
      setError("primaryContactData.email", {
        message: displayPrimaryEmailError,
      });
      analytics?.trackTravDetailsEvents({
        actionType: "error_shown",
        payload: displayPrimaryEmailError,
      });
    }

    displaySecondaryError.map((item: any, index: number) => {
      const { travellerId } = item;
      const { name, dob, gender } = item?.displayError || {};
      if (name) {
        setError(`travellerData.${travellerId || index}.name`, {
          message: name,
        });
        analytics?.trackTravDetailsEvents({
          actionType: "error_shown",
          payload: name,
        });
      }
      if (dob) {
        setError(`travellerData.${travellerId || index}.dob`, {
          message: dob,
        });
        analytics?.trackTravDetailsEvents({
          actionType: "error_shown",
          payload: dob,
        });
      }
      if (gender) {
        setError(`travellerData.${travellerId || index}.gender`, {
          message: gender,
        });
        analytics?.trackTravDetailsEvents({
          actionType: "error_shown",
          payload: gender,
        });
      }
    });
    if (displaySecondaryError.length) {
      const firstError = Number(displaySecondaryError[0]?.travellerId);
      if (!Number.isNaN(firstError)) {
        primaryContactRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "center",
        });
      }
    }
  };

  const handleErrorToast = () => {
    setTimeout(() => {
      setIsSelectedError(false);
      setIsFieldError(false);
    }, 3000);
  };

  const handleTravellerSelect = (value: boolean, index: number) => {
    if (getValues(`travellerData.${index}.isSelected`)) {
      setAddTrav(-1);
      setValue(`travellerData.${index}.isSelected`, false);
    } else {
      if (selectedCount === travellerCount) {
        if (!isSelectedError) {
          setIsSelectedError(true);
          handleErrorToast();
        }
      } else {
        setAddTrav(index);
        setValue(`travellerData.${index}.isSelected`, true);
      }
    }
  };

  const onSubmit: SubmitHandler<ITravellerDetailsFormV2> = async (formData) => {
    try {
      analytics?.trackTravDetailsEvents({
        actionType: "traveller_name_filled",
      });
      analytics?.trackTravDetailsEvents({
        actionType: "contact_details_filled",
      });
      analytics?.trackTravDetailsEvents({ actionType: "dob_selected" });
      analytics?.trackTravDetailsEvents({
        actionType: "gender_selected",
      });

      const partner = getPartnerOnClientSide();

      const travellerDetails = getTravellerDetailList(formData);

      const failureUrl = new URL(window.location.href);
      failureUrl.searchParams.append("paymentSuccess", "false");
      const requestData = {
        quoteId,
        insurancePurchaseRequest: travellerDetails,
        failureUrl,
      };
      setIsLoading(true);
      const response = await paymentCheckout({
        partner,
        data: requestData,
      });
      const { responseStatus, data } = response;
      if (responseStatus === RESPONSE_STATUS.SUCCESS && data?.checkoutUrl) {
        const { checkoutUrl } = data;
        setIsLoading(false);
        openCheckoutPage(checkoutUrl);
      } else {
        setIsLoading(false);
        const { Error, error } = response;
        if (Error && Error?.code === ERROR_CODES.DISPLAY_ERROR) {
          handleServerErrors(Error);
        } else {
          setCheckoutError(error);
        }
      }
    } catch (err: any) {
      console.error("CHECKOUT FAILURE ERROR ----", { err });
      const { error } = err?.response?.data || {};
      setIsLoading(false);
      setCheckoutError(error);
    }
  };

  const handleAddTraveller = () => {
    if (selectedCount != travellerCount) {
      setAddTrav(travellers.length);
      append({
        name: "",
        dob: "2000-01-01",
        gender: "",
        isSelected: true,
      });
    }
  };

  const onSubmitError: SubmitErrorHandler<ITravellerDetailsFormV2> = (
    errors
  ) => {
    if (!isFieldError) {
      setIsFieldError(true);
      handleErrorToast();
    }
    if (errors.travellerData?.length) {
      const errorIndex = errors.travellerData?.findIndex?.((error) => error);
      if (errorIndex != null && errorIndex > -1) {
        setFirstErrorIndex(errorIndex);
      }
    } else if (
      errors.primaryContactData?.email ||
      errors.primaryContactData?.mobile
    ) {
      primaryContactRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "center",
      });
    } else if (errors.tncs?.length) {
      tncRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  };

  useEffect(() => {
    if (firstErrorIndex > -1) {
      travellerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setAddTrav(-1);
    }
    setFirstErrorIndex(-1);
  }, [firstErrorIndex]);

  const handleBuy = (fromBenefitModal: boolean = false) => {
    if (showBenefits) {
      handleViewBenefits();
    }
    if (selectedCount !== travellerCount) {
      if (!isFieldError) {
        setIsFieldError(true);
        setFirstErrorIndex(0);
        handleErrorToast();
      }
    } else {
      if (isDomesticBooking) {
        if (fromBenefitModal) {
          analytics?.trackTravDetailsEvents({
            actionType: "buy_clicked_details_domestic",
          });
        } else {
          analytics?.trackTravDetailsEvents({
            actionType: "buy_clicked_outside_domestic",
          });
        }
      } else {
        analytics?.trackTravDetailsEvents({
          actionType: "buy_clicked",
        });
      }
      const formValues = getValues();
      formValues?.travellerData?.map((item, index) => {
        const {
          name: fullName,
          dob: dobValue,
          gender: genderType,
        } = item || {};
        const fieldState = getFieldState(`travellerData.${index}`);
        const { isTouched } = fieldState;

        if (!isTouched) {
          if (name?.isEnabled && !fullName) {
            setError(`travellerData.${index}.name`, {
              message: getFormFieldErrorMsg("name"),
            });
          } else if (dob?.isEnabled && !dobValue) {
            setError(`travellerData.${index}.dob`, {
              message: getFormFieldErrorMsg("dob"),
            });
          } else if (gender?.isEnabled && !genderType) {
            setError(`travellerData.${index}.gender`, {
              message: getFormFieldErrorMsg("gender"),
            });
          }
        }
      });
      handleSubmit(onSubmit, onSubmitError)();
    }
  };

  if (checkoutError && typeof window !== "undefined") {
    throw new ClientError(checkoutError);
  }

  const handleAddOnFilters = (id: number) => {
    analytics?.trackTravDetailsEvents({
      actionType: "Filter_selected",
    });
    const filterData = filters?.map((filter: IFilters, index: number) => {
      if (index === id) {
        filter.isSelected = true;
      } else {
        filter.isSelected = false;
      }
      return filter;
    });
    const selectedFilteredPlans = filterData?.filter(
      (filter: IFilters) => filter.isSelected
    )?.[0]?.plans;
    let currentAddOnPlans = addOnPlans
      ?.map((plan: IAddOnPlans) => {
        if (selectedFilteredPlans?.includes(plan?.planCode)) {
          return plan;
        } else {
          return null;
        }
      })
      ?.filter((plan: any) => plan !== null);
    setAddOnPlansData(currentAddOnPlans);
    setAddOnFilters(filterData);
  };

  const handleAddOnSelect = (planCode: string) => {
    const selectedFilteredPlans = addOnFilters?.filter(
      (filter: IFilters) => filter.isSelected
    )?.[0]?.plans;

    const addOnData = addOnPlans
      ?.map((plan: IAddOnPlans, index: number) => {
        if (plan?.planCode === planCode) {
          if (plan.isSelected) {
            const queryParams = new URLSearchParams(window.location.search);
            const quoteId = queryParams.get("quoteId");
            setQuoteId(quoteId);
            plan.isSelected = false;
          } else {
            setQuoteId(plan?.quoteId);
            plan.isSelected = true;
          }
        } else {
          plan.isSelected = false;
        }
        return plan;
      })
      ?.filter((plan: IAddOnPlans) =>
        selectedFilteredPlans?.includes(plan?.planCode)
      );

    const selectedAddOnProdDetails = addOnPlans?.filter(
      (plan: IAddOnPlans) => plan?.isSelected
    )?.[0]?.comboProductDetails;
    if (selectedAddOnProdDetails) {
      setAddOnProductDetails?.(selectedAddOnProdDetails);
    } else {
      setAddOnProductDetails?.(null);
    }
    setAddOnPlansData(addOnData);
  };

  return (
    <FormProvider {...methods}>
      <AddTravellerDetailStyle>
        <AddTravellerHd>
          <H5Tag>
            <FormattedText value={title} />
          </H5Tag>
          <ParaTag fontSize="xs" color={theme.greyText}>
            <FormattedText value={info} />
          </ParaTag>
        </AddTravellerHd>

        {travellers.map((traveller, index) => {
          const { gender, isSelected } =
            formValues?.travellerData?.[index] || {};

          return (
            <div key={traveller.id}>
              <TravellerDetailsV2
                handleTravellerSelect={handleTravellerSelect}
                travellerIndex={index}
                travellerRef={travellerRef}
                isLoading={isLoading}
                firstErrorIndex={firstErrorIndex}
                travellerSelected={addTrav == index}
                isSelected={isSelected}
                count={selectedCount}
                travellerNumber={`${secondaryContact?.title} ${index + 1}`}
                isSavedTravellers={savedTravellers?.length ? true : false}
                secondaryContactData={secondaryContact?.fields}
                genderTypes={
                  secondaryContact?.fields?.gender?.values || GENDER_VALUES
                }
                selectedGender={gender}
                handleGenderChange={(item) => handleGenderChange(item, index)}
                handleArrowButton={() => handleArrowButton(index)}
                travellerCount={travellerCount}
                saveFormValuesOnBlur={saveFormValuesOnBlur}
                isDomesticBooking={isDomesticBooking}
                isValidForm={isValidForm}
                isEditable={
                  secondaryContact?.isEditable === false ? false : true
                }
              />
            </div>
          );
        })}

        <SectionDividers height="1.2px" />
        {!isDomesticBooking && (
          <AddButtonWrap
            isDisabled={selectedCount === travellerCount}
            onClick={handleAddTraveller}
          >
            <ImageComponent
              width="16px"
              height="16px"
              margin="0px 10px 0px 0px"
              src={
                selectedCount === travellerCount
                  ? "https://tripmoneycmsimgak.mmtcdn.com/img/Vector_529ac663fb.svg"
                  : "https://tripmoneycmsimgak.mmtcdn.com/img/Vector_68e1489f3a.svg"
              }
            />

            <FormattedText
              value={"ADD ANOTHER TRAVELLER"}
              baseStyle={{
                fontWeight: "800",
                // margin: '10px 0px 10px 0px',
              }}
            />
          </AddButtonWrap>
        )}
        <SectionDividers small />

        {addOns && (
          <TravellerCommonComp
            title={addOns?.title}
            info={addOns?.subTitle}
            addOnPlans={addOnPlansData}
            addOnfilters={addOnFilters}
            handleBuy={handleBuy}
            handleAddOnFilters={handleAddOnFilters}
            handleAddOnSelect={handleAddOnSelect}
          ></TravellerCommonComp>
        )}

        {addOns && <SectionDividers small />}

        <TravellerCommonComp
          componentRef={primaryContactRef}
          title={primaryContact?.title}
          info={primaryContact?.info}
          note={isDomesticBooking ? undefined : note}
          primaryContactDetails
          primaryContactData={primaryContact?.fields}
          saveFormValuesOnBlur={saveFormValuesOnBlur}
          countryData={countryData}
          selectedCountry={selectedCountry}
          handleCountryCodeModal={handleCountryCodeModal}
        />
        <SectionDividers small />

        {/* NOT IN CURRENT SCOPE : Choose from multiple insurers*/}
        {/* <TravellerCommonComp
                title="Select Insurer"
                info="Select from the available Insurers to secure your trip."
                selectInsurer={selectInsurer}
            />
            <SectionDividers small /> */}

        <TravellerCommonComp
          componentRef={tncRef}
          confirmTnC={concent?.tnc}
          handleTncCheckbox={handleTncCheckbox}
          info={isDomesticBooking ? "" : concent?.info}
          totalAmt={
            addOnProductDetails?.totalAmountToPay
              ? addOnProductDetails?.totalAmountToPay
              : totalAmt
          }
          handleBuy={() => {
            handleBuy();
          }}
          isLoading={isLoading}
        />
      </AddTravellerDetailStyle>
      {isSelectedError && (
        <WebToast
          toastTxt={`Already selected ${travellerCount} ${
            travellerCount === 1 ? "traveller" : "travellers"
          }, Remove before adding one.`}
          position="fixed"
          right={"50px"}
          margin={device === "lg" ? "0px 0px 0px 28px" : ""}
          height="40px"
          bottom={device === "sm" ? "70px" : "50px"}
        />
      )}
      {isFieldError && (
        <WebToastWrap>
          <WebToast
            type="error"
            toastTxt={
              selectedCount === travellerCount
                ? widgets?.formErrorToast
                  ? widgets?.formErrorToast?.message
                  : formErrorToast
                : widgets?.addErrorToast
                ? widgets?.addErrorToast?.message
                : addErrorToast
            }
            color="#6B4300"
          />
        </WebToastWrap>
      )}

      {widgets?.offerToast && showOfferToast && (
        <WebToast
          type="success"
          iconUrl={widgets?.offerToast?.iconUrl}
          toastTxt={widgets?.offerToast?.message}
          position="fixed"
          toastTxtMargin="0px 0px 0px 5px"
          color="#1E5C43"
          fontSize="14px"
          fontWeight="700"
          right="50px"
          bottom={device === "sm" ? "70px" : "50px"}
        />
      )}

      {showBenefits && (
        <MoreBenefits
          data={benefitsData}
          moreBenfitsTitle={benefitsTitle}
          handleClose={handleViewBenefits}
          handleBuyButton={() => {
            handleBuy(true);
          }}
        />
      )}
    </FormProvider>
  );
};

export default AddTravellerDetails;
