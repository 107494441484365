import React from "react";

import {
  AnchorTag,
  FlexComp,
  SpanTag,
  FormattedText,
  ImageComponent,
} from "@tm/static";
import { theme } from "libs/ui-widgets/src/lib/static/css/Global";
import { navigationActions } from "@tm/insurance/navigation";
import { PASSPORT_DETAILS_PAGE_ID } from "@tm/insurance/constants";

import { TextNodeType } from "../FormattedText/FormattedText";
import { OrderStatusCompStyle } from "./OrderStatusComp.style";

interface noInsProp {
  orderStatus?: string | TextNodeType;
  orderFailed?: boolean;
  orderPending?: boolean;
  ctatText?: string | TextNodeType;
  bookingId: string | TextNodeType;
}

const OrderStatusComp: React.FC<noInsProp> = ({
  orderStatus,
  orderFailed,
  orderPending,
  ctatText,
  bookingId,
}) => {
  return (
    <OrderStatusCompStyle pending={orderPending} failed={orderFailed}>
      {orderStatus && (
        <SpanTag
          fontSize="xs"
          fontWeight="bold"
          color={
            orderFailed
              ? theme.redText
              : orderPending
              ? theme.brownText
              : theme.blackText
          }
        >
          {orderPending && <SpanTag fontWeight="regular">Documents:</SpanTag>}
          <FormattedText value={orderStatus} />
        </SpanTag>
      )}
        
      <FlexComp
        handleClick={() =>
          navigationActions.navigateToAddPassport({
            bookingId,
            pageId: PASSPORT_DETAILS_PAGE_ID.ORDER_CONFIRMED,
          })
        }
        alignItems="center"
        gap="4px"
      >
        {ctatText && (
          <AnchorTag fontWeight="bold" fontSize="xs">
            <FormattedText value={ctatText} />
          </AnchorTag>
        )}
        <ImageComponent
          width="16px"
          height="16px"
          src="https://tripmoneycmsimgak.mmtcdn.com/img/right_arrow_4a1f2bfef9.svg"
        />
      </FlexComp>
    </OrderStatusCompStyle>
  );
};

export default OrderStatusComp;
