import type { DeviceContextV2Type } from "@tm/ui-widgets";

export function akamaiGetDevice(
  headers: Record<string, string | string[]> = {}
) {
  const isTabletHeader = headers["tablet-device"];
  const isMobileHeader = headers["mobile-device"];

  let device: DeviceContextV2Type = "desktop";
  switch ("true") {
    case isTabletHeader:
      device = "tablet";
      break;
    case isMobileHeader:
      device = "mobile";
      break;
    default:
      device = "desktop";
  }
  return device;
}
