import React from "react";

import { ShimmerVersion2 } from "@tm/static";
import { OrderDetailCompStyle, PlanDetailStyle } from "./OrderDetailComp.style";

const OrderDetailPlanShimmer = () => {
  return (
    <OrderDetailCompStyle>
      <ShimmerVersion2 width="366px" height="25px" margin="0 0 24px" />
      <PlanDetailStyle></PlanDetailStyle>
    </OrderDetailCompStyle>
  );
};

export default OrderDetailPlanShimmer;
