import React from "react";
import {
  TravellingToWidgetStyle,
  TravelDateWigetShimmerStyle,
  TravellerCountWigetShimmerStyle,
} from "./TravellingToWidget.style";
import { ShimmerVersion2, FlexComp } from "@tm/static";
import { TextNodeType } from "../FormattedText/FormattedText";

interface TravellingToWidgetProps {
  travelPurposeTxt?: string;
  travCountTitle?: TextNodeType | string;
  travPurpTitle?: string;
  travDateTitle?: string;
  btnDisabledState?: boolean;
}

const TravellingToWidgetShimmer = ({}: TravellingToWidgetProps) => {
  return (
    <TravellingToWidgetStyle>
      <TravelDateWigetShimmerStyle>
        <FlexComp flexDirection="column" gap="4px">
          <ShimmerVersion2 width="76px" height="16px" />
          <ShimmerVersion2 width="76px" height="38px" />
        </FlexComp>
        <ShimmerVersion2 width="76px" height="38px" />
      </TravelDateWigetShimmerStyle>
      <TravellerCountWigetShimmerStyle>
        <FlexComp flexDirection="column" gap="4px">
          <ShimmerVersion2 width="76px" height="16px" />
          <ShimmerVersion2 width="76px" height="38px" />
        </FlexComp>
        <ShimmerVersion2 width="76px" height="38px" />
      </TravellerCountWigetShimmerStyle>
    </TravellingToWidgetStyle>
  );
};

export default TravellingToWidgetShimmer;
