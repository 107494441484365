import * as React from "react";

interface GpsIconProps {
  color?: string;
  margin?: string;
  cursor?: string;
}

export const GpsIcon: React.FC<GpsIconProps> = ({
  color = "#008CFF",
  margin,
  cursor = "pointer",
  ...props
}) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ margin: margin, cursor: cursor }}
    {...props}
  >
    <path
      d="M10.5005 16.3584C6.98402 16.3584 4.14209 13.5165 4.14209 10C4.14209 6.48353 6.98402 3.6416 10.5005 3.6416C14.017 3.6416 16.8589 6.45953 16.8589 9.97593C16.8589 13.4923 14.017 16.3584 10.5005 16.3584ZM10.5005 5.03853C7.75482 5.03853 5.53901 7.25434 5.53901 10C5.53901 12.7457 7.75482 14.9615 10.5005 14.9615C13.2462 14.9617 15.462 12.7217 15.462 9.97593C15.462 7.25429 13.2462 5.03853 10.5005 5.03853Z"
      fill={color}
    />
    <path
      d="M10.4996 5.0383C10.1142 5.0383 9.80127 4.72515 9.80127 4.33993V2.36488C9.80127 1.97948 10.1144 1.6665 10.4996 1.6665C10.885 1.6665 11.198 1.97966 11.198 2.36488V4.33993C11.1982 4.70113 10.885 5.0383 10.4996 5.0383Z"
      fill={color}
    />
    <path
      d="M10.4996 18.333C10.1142 18.333 9.80127 18.0198 9.80127 17.6346V15.6596C9.80127 15.2742 10.1144 14.9612 10.4996 14.9612C10.885 14.9612 11.198 15.2743 11.198 15.6596V17.6346C11.1982 18.0198 10.885 18.333 10.4996 18.333Z"
      fill={color}
    />
    <path
      d="M18.1363 10.6975H16.1613C15.7759 10.6975 15.4629 10.3844 15.4629 9.99916C15.4629 9.61376 15.776 9.30078 16.1613 9.30078H18.1363C18.5217 9.30078 18.8347 9.61394 18.8347 9.99916C18.8349 10.3844 18.5459 10.6975 18.1363 10.6975Z"
      fill={color}
    />
    <path
      d="M4.84042 10.6975H2.86537C2.47997 10.6975 2.16699 10.3844 2.16699 9.99916C2.16699 9.61376 2.48015 9.30078 2.86537 9.30078H4.84042C5.22581 9.30078 5.53879 9.61394 5.53879 9.99916C5.53879 10.3844 5.22564 10.6975 4.84042 10.6975Z"
      fill={color}
    />
    <path
      d="M10.5017 12.6742C9.00844 12.6742 7.8042 11.47 7.8042 9.97676C7.8042 8.48354 9.00844 7.2793 10.5017 7.2793C11.9951 7.2793 13.1993 8.50758 13.1993 9.97676C13.1993 11.47 11.9951 12.6742 10.5017 12.6742ZM10.5017 8.70031C9.80329 8.70031 9.22517 9.27839 9.22517 9.97681C9.22517 10.6752 9.80325 11.2533 10.5017 11.2533C11.2 11.2533 11.7782 10.6752 11.7782 9.97681C11.7782 9.27839 11.2002 8.70031 10.5017 8.70031Z"
      fill={color}
    />
  </svg>
);
