import React from "react";

import {
  FlexComp,
  ImageComponent,
  ParaTag,
  SpanTag,
  AnchorTag,
  FormattedText,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";

import { OrderDetailCompStyle, OrderMobCtaWrap } from "./OrderDetailComp.style";
import { OrderDetailProp } from "./type";
import MailIcon from "../../icons/mailIcon.svg";
import CallIcon from "../../icons/callIcon.svg";
import BlueChev from "../../icons/blueChev.svg";

const PrimaryContact = ({
  title,
  orderDetailIcon,
  contactDetails,
  handleAlternateContact,
}: OrderDetailProp) => {
  const device = useBreakpointV2();

  return (
    <div>
      <OrderDetailCompStyle>
        <FlexComp
          alignItems="center"
          margin={device == "lg" ? "0 0 24px" : "0 0 16px"}
          gap="8px"
        >
          {orderDetailIcon && (
            <ImageComponent width="24px" height="24px" src={orderDetailIcon} />
          )}
          {title && (
            <SpanTag
              fontSize={device == "lg" ? "large" : "sm"}
              fontWeight="bold"
              color={theme.greyText}
            >
              <FormattedText value={title} />
            </SpanTag>
          )}
        </FlexComp>
        {contactDetails?.name && (
          <ParaTag margin="0 0 10px 32px" fontSize="md" fontWeight="bold">
            <FormattedText value={contactDetails?.name} />
          </ParaTag>
        )}
        <FlexComp
          margin="0 0 10px"
          alignItems="center"
          gap="6px"
          padding="0 0 0 32px"
        >
          <ImageComponent
            width="16px"
            height="16px"
            src="https://tripmoneycmsimgak.mmtcdn.com/img/mail_Icon_cf3395e217.svg"
          />
          {contactDetails?.email && (
            <SpanTag
              fontSize={device == "lg" ? "sm" : "xs"}
              color={theme.greyText}
            >
              <FormattedText value={contactDetails?.email} />
            </SpanTag>
          )}
        </FlexComp>
        <FlexComp justifyContent="space-between" padding="0 0 0 32px">
          <FlexComp alignItems="center" gap="6px">
            <ImageComponent
              width="16px"
              height="16px"
              src="https://tripmoneycmsimgak.mmtcdn.com/img/call_Icon_94b21de941.svg"
            />
            {contactDetails?.contactNo && (
              <SpanTag
                fontSize={device == "lg" ? "sm" : "xs"}
                color={theme.greyText}
              >
                <FormattedText value={contactDetails?.contactNo} />
              </SpanTag>
            )}
          </FlexComp>
          {/* {device == 'lg' && (
                        <FlexComp alignItems="center" gap="6px">
                            <AnchorTag
                                fontWeight="bold"
                                handleClick={handleAlternateContact}
                            >
                                Add Alternate Contact Details
                            </AnchorTag>
                            <ImageComponent width="10px" height="14px" src="https://tripmoneycmsimgak.mmtcdn.com/img/blue_Chev_2800e4f975.svg"/>
                        </FlexComp>
                    )} */}
        </FlexComp>
      </OrderDetailCompStyle>
      {/* {device == 'sm' && (
                <OrderMobCtaWrap>
                    <AnchorTag
                        handleClick={handleAlternateContact}
                        fontWeight="bold"
                    >
                        <FormattedText value="Add Alternate Contact Details" />
                    </AnchorTag>
                    <ImageComponent
                        width="16px"
                        height="16px"
                        src="https://tripmoneycmsimgak.mmtcdn.com/img/blue_Chev_2800e4f975.svg"
                    />
                </OrderMobCtaWrap>
            )} */}
    </div>
  );
};

export default PrimaryContact;
