import { TicketProp } from "./type";
declare const window: any;

export const DOCUMENTS_TYPE = {
  passFP: "passFP",
  passLP: "passLP",
  ticket: "ticket",
  panCard: "panCard",
  visa: "visa",
  itrBankStatement: "itrBankStatement",
  opt_ticket: "opt_ticket",
};

export const AddOrSelectDocData = {
  heading: "Select or Upload Ticket",
  subHeading:
    "Displaying air tickets booked on MakeMyTrip with travel date within next 60 days. Select maximum of 2 air tickets.",
  toastMsg:
    "To add another ticket, please remove or edit any existing uploaded ticket",
  toastMsg2: "Select your ticket to view details",
};

export const GoIbibo = "gi";

export const openOutsideUriGi = (link: any) => {
  if (!link) {
    return;
  }
  // 744 for opening outside the webview
  // 710 for opening inside the webview (this needs to be tested)
  const obj = {
    tg: "744",
    gd: {
      url: link,
    },
  };
  window.webkit.messageHandlers.openNativePage.postMessage(obj);
};

export const getRedirectionUrl = (url: any) => {
  let suffix = "";
  const OPEN_OUTSIDE = "open=outside";
  if (url?.indexOf(`${OPEN_OUTSIDE}`) > -1) {
    suffix = "";
  } else if (url?.indexOf("?") > -1) {
    suffix = `&${OPEN_OUTSIDE}`;
  } else {
    suffix = `?${OPEN_OUTSIDE}`;
  }
  return `${url}${suffix}`;
};

export const getDocNameBasedOnType = (doc: TicketProp) => {
  if (doc?.type === DOCUMENTS_TYPE.ticket) {
    return "Air Ticket 1";
  } else if (doc?.type === DOCUMENTS_TYPE.opt_ticket) {
    return "Air Ticket 2";
  }
  return doc?.desc;
};
