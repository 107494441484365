import React, { Fragment } from "react";

import { theme } from "@tm/utility/global";
import { RoundSearchIcon } from "@tm/static/icons";
import { chooseModalCountryDataProps } from "@tm/static/data";
import {
  CountriesContainer,
  CountryLiPoint,
  ModalContentEqualizer,
} from "./ModalScreen.style";
import { insertBrOnNewLine } from "@tm/static/utils";
import {
  CheckButton,
  H5Tag,
  ImageComponent,
  ParaTag,
  SpanTag,
} from "@tm/static";

export interface CountriesScreenProps {
  countriesData?: chooseModalCountryDataProps[];
  title?: string;
  subTitle?: string;
  countriesDataHandler?: React.Dispatch<React.SetStateAction<boolean>>;
  countrySearchText?: string;
  searchIcon?: string;
  lastLiHandler?: () => void;
  countriesDataItemHandler: (
    item: chooseModalCountryDataProps,
    idx: number
  ) => void;
}

const CountriesScreen = ({
  title,
  subTitle,
  countriesData,
  countriesDataItemHandler,
  lastLiHandler,
  countrySearchText,
  searchIcon,
}: CountriesScreenProps) => {
  return (
    <Fragment>
      {title && <H5Tag margin="0px 0px 12px 0px">{title}</H5Tag>}
      {subTitle && (
        <ParaTag color="gray" margin="0px 0px 8px 0px" fontSize="xs">
          {subTitle}
        </ParaTag>
      )}
      {countriesData && (
        <ModalContentEqualizer>
          <CountriesContainer>
            {countriesData.map((item, idx: number) => {
              return (
                <CountryLiPoint
                  key={item.id}
                  bgImage={item.logoSrc}
                  onClick={() => countriesDataItemHandler(item, idx)}
                >
                  <CheckButton
                    margin="8px 0px 0px 8px"
                    isChecked={item.isSelected}
                  />
                  <SpanTag fontSize="xs" color="white" fontWeight="bold">
                    {item.label}
                  </SpanTag>
                </CountryLiPoint>
              );
            })}
            <CountryLiPoint onClick={lastLiHandler}>
              <div>
                {searchIcon ? (
                  <ImageComponent src={searchIcon} />
                ) : (
                  <RoundSearchIcon />
                )}
                {countrySearchText && (
                  <ParaTag
                    fontSize="xs"
                    color={theme.blueText}
                    fontWeight={"bold"}
                    textAlign="center"
                    margin="4px 0px 0px 0px"
                    dangerousText={insertBrOnNewLine(countrySearchText)}
                  />
                )}
              </div>
            </CountryLiPoint>
          </CountriesContainer>
        </ModalContentEqualizer>
      )}
    </Fragment>
  );
};

export default CountriesScreen;
