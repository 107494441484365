import * as React from "react";
interface calenderType {
  color?: string;
  width?: number;
  height?: number;
}
const CalenderIcon = (
  { color = "#9b9b9b", width = "16", height = "16" },
  props: calenderType
) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1745 3.49118V2.35529H9.90469V3.49118H6.09517V2.35529H4.82533C4.82533 1.94366 4.82533 3.49118 4.82533 3.49118L3.55549 3.49118C2.85072 3.49118 2.29199 4.00233 2.29199 4.62707L2.28564 12.5783C2.28564 13.2031 2.85072 13.7142 3.55549 13.7142H12.4444C13.1428 13.7142 13.7142 13.2031 13.7142 12.5783V4.62707C13.7142 4.00233 13.1428 3.49118 12.4444 3.49118H11.1745ZM12.4444 12.5783H3.55549V5.76296H12.4444V12.5783ZM5.0127 6.89886H7.29841V9.18457H5.0127V6.89886Z"
      fill={color}
    />
  </svg>
);
export { CalenderIcon };
