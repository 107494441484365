import BulletListItem from "../BulletListItem/BulletListItem";
import LandingButton from "../ui-atoms/LandingButton/LandingButton";
import H3Tag from "../ui-atoms/TextStyles/H3Tag";
import SpanTag from "../ui-atoms/TextStyles/SpanTag";
import {
  PersuasionBannerStyle,
  BulletListStyle,
} from "./PersuasionBanner.style";
import {
  PageNameMapping,
  SectionNameMapping,
  ElementNameMapping,
} from "../../../constants";
import { getElementUniqueIds } from "../../../utils";

interface PersuasionBannerProps {
  heading: string;
  bgImage?: string;
  bulletPoints: any[];
  btnText: string;
  margin?: string | number;
  btnHandler?: () => void;
}

const PersuasionBanner = ({
  heading,
  bgImage,
  bulletPoints,
  btnText,
  margin,
  btnHandler,
}: PersuasionBannerProps) => {
  return (
    <PersuasionBannerStyle margin={margin} bgImage={bgImage}>
      <H3Tag margin={"0px 0px 24px 0px"}>{heading}</H3Tag>
      {bulletPoints?.length > 0 && (
        <BulletListStyle>
          {bulletPoints.map((item) => {
            return (
              <BulletListItem key={item.id}>
                {item.boldText && (
                  <SpanTag fontWeight="bold">{item.boldText}</SpanTag>
                )}{" "}
                <SpanTag>{item.normalText}</SpanTag>
              </BulletListItem>
            );
          })}
        </BulletListStyle>
      )}
      <LandingButton
        id={getElementUniqueIds(
          PageNameMapping.LISTING_PAGE,
          SectionNameMapping.CURR_CARD,
          ElementNameMapping.EXPLORE_MORE_BTN
        )}
        customTitle={btnText}
        onClick={btnHandler}
        padding="0px 40px"
      />
    </PersuasionBannerStyle>
  );
};

export default PersuasionBanner;
