export function injectPlatform(eventObj: any) {
  const isPageViewEvent = ["pageView", "errorScreen"].includes(eventObj?.event);
  const missingPlatform =
    eventObj?.page?.pageInfo && !eventObj?.page?.pageInfo?.platform;
  if (isPageViewEvent && missingPlatform) {
    eventObj.page.pageInfo.platform = /Mobile|Android|BlackBerry/.test(
      navigator.userAgent
    )
      ? "mweb"
      : "desktop";
  }
}
