import styled from "styled-components";
import { deviceType } from "@tm/static/utils";
import { PARTNER, PartnerType } from "@tm/insurance/constants";

export interface InsuranceStatsWrapProps {
  pageTheme?: PartnerType;
}

export const InsuranceStatsWrap = styled.section`
  width: 100%;
  @media ${deviceType.mobile} {
    padding: 0 16px;
  }
`;

export const InsuranceStatsStyle = styled.div<InsuranceStatsWrapProps>`
  background: #fff;
  border-radius: 16px;
  padding: 40px 80px;
  display: flex;
  gap: 114px;
  width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  ${(p) =>
    p.pageTheme === PARTNER.GI &&
    `
    border-radius: 20px;
    background: #FFF;
    `}
  @media ${deviceType.mobile} {
    width: 100%;
    gap: 24px;
    padding: 16px;
    grid-template-columns: repeat(2, 1fr);
    box-shadow: none;
    ${(p) =>
      p.pageTheme === PARTNER.GI &&
      `
    background: #fff;
    box-shadow:none;
    `}
  }
`;
