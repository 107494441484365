import React, { useEffect, useRef, useState } from "react";
import { getMmtLoginCustomEvent } from "../../common";
import { CUSTOM_EVENTS } from "../../constants";

//@ts-ignore
const Header = React.lazy(() => import("./header"));

interface MMTLoginTypes {
  onClose?: () => void;
  onOpen?: () => void;
}

export const MMTLogin = ({ onClose, onOpen }: MMTLoginTypes) => {
  const [activeModal, setActiveModal] = useState("");
  const timeoutRef: any = useRef();

  useEffect(() => {
    document.addEventListener(
      CUSTOM_EVENTS.MMT_LOGIN_CUSTOM_EVENT,
      onMMTLoginEventDispatch
    );
    return () => {
      document.removeEventListener(
        CUSTOM_EVENTS.MMT_LOGIN_CUSTOM_EVENT,
        onMMTLoginEventDispatch
      );
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const onMMTLoginEventDispatch = (event: any) => {
    setActiveModal("");
    timeoutRef.current = setTimeout(() => {
      setActiveModal(event?.detail?.activeModal || "");
      if (onOpen) {
        onOpen();
      }
    }, 10);
  };

  const onLoginFlowComplete = () => {
    document.dispatchEvent(getMmtLoginCustomEvent());
    if (onClose) {
      onClose();
    }
    window.location.reload();
  };

  // if (typeof document !== "undefined" && !document?.getElementById(MMT_DESKTOP_DIV)) {
  //     const elem = document.createElement('div');
  //     elem.setAttribute('id', MMT_DESKTOP_DIV);
  //     document.body.appendChild(elem);
  // }
  // const root = ReactDOM.createRoot(
  //     document.getElementById(MMT_DESKTOP_DIV) as HTMLElement
  // );
  // root.render(
  //     <React.StrictMode>
  //         <Header
  //             showNav={false}
  //             onLoginFlowComplete={onLoginFlowComplete}
  //             activeModal={activeModal}
  //         />
  //     </React.StrictMode>
  // );
  return activeModal ? (
    <Header
      showNav={false}
      onLoginFlowComplete={onLoginFlowComplete}
      activeModal={activeModal}
      socialLogin={false}
    />
  ) : null;
};
