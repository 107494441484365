import React from "react";
import { theme } from "@tm/utility/global";
type Colors = typeof theme;
type ColorKey = keyof Colors;
import { AnchorTagStyle } from "./TextStyles.style";
import { usePartnerContext } from "@tm/static/partnerContext";

interface AnchorTagProps {
  fontSize?: string;
  fontWeight?: string;
  color?: Colors[ColorKey];
  children?: React.ReactNode;
  handleClick?: () => void;
  hrefProp?: string;
  textAlign?: "center" | "left" | "right";
  margin?: string;
  handleMouseLeave?: () => void;
  handleMouseEnter?: () => void;
  textTransform?: "uppercase" | "lowercase";
  anchorTitle?: string | undefined;
  targetProp?: string;
  download?: boolean;
  disableState?: boolean;
  style?: React.CSSProperties;
}

const AnchorTag: React.FC<AnchorTagProps> = ({
  children,
  fontSize = "sm",
  fontWeight = "regular",
  color,
  handleClick,
  hrefProp,
  textAlign,
  margin,
  handleMouseEnter,
  handleMouseLeave,
  textTransform,
  anchorTitle,
  targetProp,
  download = false,
  disableState,
  style,
}) => {
  const context = usePartnerContext();
  return (
    <AnchorTagStyle
      context={context ? context : undefined}
      textAlign={textAlign}
      txtfontSize={fontSize}
      txtfontWeight={fontWeight}
      txtcolor={color}
      onClick={handleClick}
      href={hrefProp}
      margin={margin}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      textTransform={textTransform}
      title={anchorTitle}
      target={targetProp}
      download={download}
      disableState={disableState}
      style={style}
    >
      {children}
    </AnchorTagStyle>
  );
};

export default AnchorTag;
