import React from "react";
import { WhatsCoveredStyle, BenefitItem } from "./WhatsCovered.style";
import { InnerContentWrap } from "../../CommonStyle/CommonStyles.style";
import {
  FlexComp,
  H4Tag,
  H2Tag,
  ParaTag,
  ImageComponent,
  H5Tag,
} from "@tm/static";
import { theme } from "@tm/utility/global";
import FormattedText, { TextNodeType } from "../FormattedText/FormattedText";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { usePartnerContext } from "@tm/static/partnerContext";

interface WhatsCoveredProps {
  data?: {
    title: TextNodeType;
    info: TextNodeType;
    cardImg: string;
  }[];
  title?: TextNodeType;
  info?: TextNodeType;
}

const WhatsCovered = ({ data, title, info }: WhatsCoveredProps) => {
  const device = useBreakpointV2();
  const context = usePartnerContext();
  return (
    <WhatsCoveredStyle pageTheme={context ? context : undefined}>
      <InnerContentWrap>
        {device == "lg" ? (
          <H2Tag>{title && <FormattedText value={title} />}</H2Tag>
        ) : (
          <H5Tag>{title && <FormattedText value={title} />}</H5Tag>
        )}
        <ParaTag
          fontSize={device == "lg" ? "large" : "sm"}
          color={theme.greyText}
          margin="0 0 66px"
        >
          {info && <FormattedText value={info} />}
        </ParaTag>
        <FlexComp gap={device == "lg" ? "82px" : "24px"}>
          {data &&
            data.map((item, index) => {
              return (
                <BenefitItem key={index}>
                  {item.cardImg && (
                    <ImageComponent
                      width={device == "lg" ? "164px" : "80px"}
                      height={device == "lg" ? "164px" : "80px"}
                      src={item.cardImg}
                    />
                  )}
                  {device == "lg" ? (
                    <H4Tag margin="0 0 8px">
                      {item.title && <FormattedText value={item.title} />}
                    </H4Tag>
                  ) : (
                    <ParaTag
                      fontSize="large"
                      fontWeight="black"
                      margin="0 0 8px"
                    >
                      {" "}
                      {item.title && <FormattedText value={item.title} />}
                    </ParaTag>
                  )}
                  <ParaTag
                    fontSize={device == "lg" ? "large" : "sm"}
                    color={device == "lg" ? theme.greyText7 : theme.blackText}
                  >
                    {item.info && <FormattedText value={item.info} />}
                  </ParaTag>
                </BenefitItem>
              );
            })}
        </FlexComp>
      </InnerContentWrap>
    </WhatsCoveredStyle>
  );
};

export default WhatsCovered;
