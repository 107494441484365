import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useEffect } from "react";

export const useForeignCurrencyAnimation = () => {
  const ANIMATION_TYPE = "ease-in-out";
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    let timerId = setTimeout(() => {
      const rootContainerElement = document.getElementById("root_container");
      const currRefElement = document.getElementById("curr_ref");
      const padRefElement = document.getElementById("pad_ref");
      const currDelLeftElement = document.getElementById("curr_del_left");
      const curr1 = document.getElementById("curr1");
      const curr2 = document.getElementById("curr2");
      const curr3 = document.getElementById("curr3");

      gsap.to(currRefElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currRefElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        width: "100%",
        borderRadius: 0,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(padRefElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currRefElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        width: "1200px",
        ease: ANIMATION_TYPE,
      });
      gsap.to(currDelLeftElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currRefElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        visibility: "visible",
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(curr1, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currRefElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        rotate: 0,
        left: 25,
        // top: 110,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(curr2, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currRefElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        left: 117,
        rotate: 0,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
      gsap.to(curr3, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currRefElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        rotate: 0,
        left: 209,
        ease: ANIMATION_TYPE,
        duration: 0.9,
      });
    }, 300);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
};
