import React from "react";

import { theme } from "@tm/utility/global";
import { useBreakpointV2 } from "@tm/ui-widgets";
import {
  AnchorTag,
  FlexComp,
  ImageComponent,
  FormattedText,
  ParaTag,
  SpanTag,
} from "@tm/static";

import {
  OrderDetailCompStyle,
  PlanDetailStyle,
  PlanDetailsInfo,
  OrderMobCtaWrap,
} from "./OrderDetailComp.style";
import { OrderDetailProp } from "./type";
import BlueChev from "../../icons/blueChev.svg";

const OrderDetailPlan = ({
  title,
  orderDetailIcon,
  planBenefits,
  handleMoreBenefits,
  ctaLink,
  subTitle,
  benefitsCTA,
}: OrderDetailProp) => {
  const device = useBreakpointV2();

  return (
    <div>
      <OrderDetailCompStyle>
        <FlexComp
          alignItems="center"
          margin={device == "lg" ? "0 0 24px" : "0 0 16px"}
          gap="8px"
        >
          {orderDetailIcon && (
            <ImageComponent width="24px" height="24px" src={orderDetailIcon} />
          )}
          {title && (
            <SpanTag
              fontSize={device == "lg" ? "large" : "sm"}
              fontWeight="bold"
              color={theme.greyText}
            >
              <FormattedText value={title} />
            </SpanTag>
          )}
        </FlexComp>
        <PlanDetailStyle>
          <ParaTag fontWeight="bold" fontSize="large">
            {subTitle}
          </ParaTag>
        </PlanDetailStyle>
      </OrderDetailCompStyle>
      {/* {device == 'sm' && (
                <OrderMobCtaWrap>
                    <AnchorTag
                        handleClick={handleMoreBenefits}
                        fontWeight="bold"
                    >
                        <FormattedText value="View all 15 benefits" />
                    </AnchorTag>
                    <ImageComponent
                        width="16px"
                        height="16px"
                        src="https://tripmoneycmsimgak.mmtcdn.com/img/blue_Chev_2800e4f975.svg"
                    />
                </OrderMobCtaWrap>
            )} */}
    </div>
  );
};

export default OrderDetailPlan;
