import { useDevice } from "libs/ui-widgets/src/lib/hooks/useDevice";
import FlexComp from "../../ui-atoms/FlexComp/FlexComp";
import { landingCardShowcaseDataProps } from "../../../mockData";
import {
  LocationUnavailable,
  ReloadTripPersuasionProps,
} from "libs/ui-widgets/src/lib/types";
import { PersuasionHandler } from "../Persuasion";
import ParaTag from "../../ui-atoms/TextStyles/ParaTag";
import { theme } from "../../../css/Global";
import LandingButton from "../../ui-atoms/LandingButton/LandingButton";
import {
  getCamelCaseStr,
  getElementUniqueIds,
} from "libs/ui-widgets/src/lib/utils";
import {
  ElementNameMapping,
  PageNameMapping,
} from "libs/ui-widgets/src/lib/constants";

export default function ReloadTripPersuasion(props: {
  cardData: landingCardShowcaseDataProps;
  persuasionProps: ReloadTripPersuasionProps;
  handlers: PersuasionHandler;
}) {
  const device = useDevice();
  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
        props.handlers.reloadTripHandler(props.cardData);
      }}
    >
      <FlexComp
        padding={device === "lg" ? "8px 12px" : "16px"}
        borderRadius="8px"
        margin={device === "lg" ? "24px 0px 0px" : "24px -16px -16px"}
        justifyContent="space-between"
        flexDirection={"row"}
        bgColor="linear-gradient(147deg, #FFF6D8 4.79%, #FFFAF3 43.98%, #F3F0E6 120.5%)"
        alignItems="center"
      >
        <FlexComp margin="0px" padding={"0px"}>
          {props.persuasionProps.showInstantLoadIcon ? (
            <img
              src="https://tripmoneycmsimgak.mmtcdn.com/img/load_Instantly_dc4ba861d7.svg"
              alt="IL"
              style={{ marginRight: "10px" }}
            />
          ) : (
            <></>
          )}
          {props.persuasionProps.label ? (
            <FlexComp
              padding="0px"
              alignItems="flex-start"
              justifyContent="space-between"
              margin={device === "sm" ? "0px 20px 0px 0px" : "0px"}
              bgColor="transparent"
              flexDirection="column"
            >
              {props.persuasionProps.label && (
                <ParaTag fontWeight="bold" color={theme.blackText}>
                  {props.persuasionProps.label}
                </ParaTag>
              )}
              {props.persuasionProps.subLabel && (
                <ParaTag color={theme.greyText}>
                  {props.persuasionProps.subLabel}
                </ParaTag>
              )}
            </FlexComp>
          ) : (
            <></>
          )}
        </FlexComp>
        {props.persuasionProps.btnProps && (
          <LandingButton
            id={getElementUniqueIds(
              PageNameMapping.LISTING_PAGE,
              getCamelCaseStr(props.cardData.label),
              ElementNameMapping.RELOAD_TRIP
            )}
            {...props.persuasionProps.btnProps}
            flexShrink="0"
            fontSize={"12px"}
            customHeight="32px"
            padding="12px 16px"
          />
        )}
      </FlexComp>
    </span>
  );
}
