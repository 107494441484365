import dynamic from "next/dynamic";
import { COMPONENT_TYPE } from "@tm/types";

const LandingMoneyTransfer = dynamic(
  () => import("../LandingMoneyTransfer/LandingMoneyTransfer")
);
const LandingMoneyTransferMobile = dynamic(
  () => import("../LandingMoneyTransferMobile/LandingMoneyTransfer.mobile")
);

const Header = dynamic(() => import("../header/HeaderLanding"));
const Hero = dynamic(() => import("../hero"));
const ForeignCurrencyForex = dynamic(() => import("../foreignCurrencyForex"));
const ForexCardSection = dynamic(
  () => import("../forexCardSection/ForexCardSection")
);
const InsuranceSection = dynamic(() => import("../insuranceSection"));
const PrivacySection = dynamic(() => import("../privacySection"));

const TestimonialSection = dynamic(() => import("../TestimonialSection"));
const LandingFaq = dynamic(() => import("../LandingFaq/LandingFaq"));
const LandingFooter = dynamic(() => import("../LandingFooter/LandingFooter"));
const LandingHeaderMobile = dynamic(
  () => import("../LandingHeaderMobile/TmLandingHeaderMobile")
);
const HeroMobile = dynamic(() => import("../HeroMobile/Hero.mobile"));
const LandingCurrDel = dynamic(
  () => import("../LandingCurrDel/LandingCurrDel")
);
const LandingTrans = dynamic(
  () => import("../LandingTransMobile/LandingTrans")
);
const InsuranceMobileSection = dynamic(
  () => import("../InsuranceMobileSection")
);
const TravelLoanSectionMobile = dynamic(
  () => import("../TravelLoanMobileSection/TravelLoanSection.mobile")
);
const LandingPrivacyMobile = dynamic(
  () => import("../LandingPrivacyMobile/LandingPrivacy.mobile")
);
const LandingTestimonialsMobile = dynamic(
  () => import("../LandingTestimonialsMobile/LandingTestimonials.mobile")
);
const LandingFaqMobile = dynamic(
  () => import("../LandingFaqMobile/LandingFaq.mobile")
);

const LandingFooterMobile = dynamic(
  () => import("../LandingFooterMobile/LandingFooter.mobile")
);

const AboutUs = dynamic(() => import("../AboutUs"));

export const componentMapper: { [key: string]: React.ComponentType<any> } = {
  [COMPONENT_TYPE.HEADER]: Header,
  [COMPONENT_TYPE.HEADER_MOBILE]: LandingHeaderMobile,

  [COMPONENT_TYPE.HERO]: Hero,
  [COMPONENT_TYPE.HERO_MOBILE]: HeroMobile,

  [COMPONENT_TYPE.FOREIGN_CURRENCY_FOREX]: ForeignCurrencyForex,
  [COMPONENT_TYPE.FOREIGN_CURRENCY_FOREX_MOBILE]: LandingCurrDel,

  [COMPONENT_TYPE.FOREX_CARD_SECTION]: ForexCardSection,
  [COMPONENT_TYPE.FOREX_CARD_SECTION_MOBILE]: LandingTrans,

  [COMPONENT_TYPE.INSURANCE_SECTION]: InsuranceSection,
  [COMPONENT_TYPE.INSURANCE_SECTION_MOBILE]: InsuranceMobileSection,

  [COMPONENT_TYPE.TRAVEL_LOAN_MOBILE_SECTION]: TravelLoanSectionMobile,

  [COMPONENT_TYPE.TRANSER_MONEY]: LandingMoneyTransfer,
  [COMPONENT_TYPE.TRANSFER_MONEY_MOBILE]: LandingMoneyTransferMobile,

  [COMPONENT_TYPE.PRIVACY_SECTION]: PrivacySection,
  [COMPONENT_TYPE.PRIVACY_SECTION_MOBILE]: LandingPrivacyMobile,

  [COMPONENT_TYPE.TESTIMONIALS_SECTION]: TestimonialSection,
  [COMPONENT_TYPE.TESTIMONIALS_MOBILE]: LandingTestimonialsMobile,

  [COMPONENT_TYPE.FAQS_SECTION]: LandingFaq,
  [COMPONENT_TYPE.FAQS_SECTION_MOBILE]: LandingFaqMobile,

  [COMPONENT_TYPE.FOOTER]: LandingFooter,
  [COMPONENT_TYPE.FOOTER_MOBILE]: LandingFooterMobile,

  [COMPONENT_TYPE.ABOUT_US]: AboutUs,
  [COMPONENT_TYPE.ABOUT_US_MOBILE]: AboutUs,
};
