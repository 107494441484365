import * as React from "react";
interface starEmptyInterface {
  width?: number;
  height?: number;
}
const StarEmpty = ({
  width = 26,
  height = 25,
  ...props
}: starEmptyInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0675 19.9704L6.05072 23.7389L7.3908 15.7571L1.71415 10.1044L9.55909 8.93983L13.0675 1.67773L16.5758 8.93983L24.4208 10.1044L18.7441 15.7571L20.0842 23.7389L13.0675 19.9704Z"
      fill="#E2B634"
      fillOpacity={0.1}
      stroke="#E2B634"
      strokeOpacity={0.4}
    />
  </svg>
);
export { StarEmpty };
