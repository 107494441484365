import styled from "styled-components";

type TagStyleProps = {
  startColor?: string;
  endColor?: string;
};

type ContainerStyleProps = {
  flexDirection?: string;
  gap?: string;
};

export const DomesticTripStyle = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
`;

export const ContainerStyle = styled.div<ContainerStyleProps>`
  display: flex;
  ${(props) => props.flexDirection && `flex-direction: ${props.flexDirection}`};
  ${(props) => props.gap && `gap: ${props.gap}`};
`;

export const TagStyle = styled.span<TagStyleProps>`
  color: #ffffff;
  padding: 2px 8px 2px 8px;
  font-weight: 600;
  font-size: 10px;
  align-items: center;
  border-radius: 8px 0px 8px 0px;
  ${(props) =>
    `background: linear-gradient(45deg, ${
      props?.startColor ?? "#00d2ff"
    } 14.64%, ${props?.endColor ?? "#3a7bd5"} 85.36%)`};
`;

export const ArrowButtonWrap = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
`;

export const BannerStyle = styled.div`
  display: flex;
  background: #eaf5ff;
  padding: 8px 15px 12px 16px;
  border-radius: 0px 0px 8px 8px;
  gap: 8px;
  font-size: 14px;
`;
