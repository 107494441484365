import React, { useState } from "react";
import {
  AnchorTag,
  CheckButton,
  FlexComp,
  ImageTag,
  ParaTag,
  SpanTag,
} from "@tm/static";
import { CurrentTripOrderStyle, LoadInstantly } from "./CurrentTripOrder.style";
import { theme } from "@tm/utility/global";

interface currentTripProps {
  pageTitle?: string;
  pageInfo?: string;
  loadInstantly?: {
    title?: string;
    icon?: string;
    desc?: string;
  };
}

const CurrentTripOrder = ({
  pageTitle,
  pageInfo,
  loadInstantly,
}: currentTripProps) => {
  const [concent, setConcent] = useState(false);
  const handleConcentChange = (e: any) => {
    setConcent(e.target.checked);
  };
  return (
    <CurrentTripOrderStyle>
      <LoadInstantly>
        <FlexComp alignItems="center" gap="8px">
          {loadInstantly?.icon && <ImageTag src={loadInstantly?.icon} />}
          <SpanTag fontSize="xs" dangerousText={loadInstantly?.title} />
        </FlexComp>
        <ParaTag
          color={theme.greyText}
          fontSize="xs"
          dangerousText={loadInstantly?.desc}
        />
      </LoadInstantly>
      <ParaTag fontSize="sm" fontWeight="bold">
        {pageTitle}
      </ParaTag>
      <ParaTag color={theme.greyText} fontSize="xs" dangerousText={pageInfo} />
      <CheckButton
        inputId="confirmTnC"
        handleChange={handleConcentChange}
        isChecked={concent}
      >
        <ParaTag color={theme.greyText} fontSize="xs">
          I agree to all <AnchorTag>T&C</AnchorTag> including that my travel
          purpose is <b>Leisure/Medical/Education</b>
        </ParaTag>
      </CheckButton>
    </CurrentTripOrderStyle>
  );
};

export default CurrentTripOrder;
