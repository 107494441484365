import React from "react";
import { FlexComp, ShimmerVersion2 } from "@tm/static";
import { TravellerDetailsStyle } from "./AddTravellerDetail.style";

const TravellerDetailsShimmer = ({}) => {
  return (
    <TravellerDetailsStyle>
      <ShimmerVersion2 width="266px" height="20px" margin="0 0 16px" />
      <FlexComp gap="16px" margin="0 0 16px">
        <div>
          <ShimmerVersion2 width="170px" height="18px" margin="0 0 8px" />
          <ShimmerVersion2 width="258px" height="40px" />
        </div>
        <div>
          <ShimmerVersion2 width="170px" height="18px" margin="0 0 8px" />
          <ShimmerVersion2 width="258px" height="40px" />
        </div>
      </FlexComp>
      <ShimmerVersion2 width="170px" height="24px" margin="0 0 8px" />
      <FlexComp gap="8px">
        <ShimmerVersion2 width="77px" height="36px" />
        <ShimmerVersion2 width="77px" height="36px" />
        <ShimmerVersion2 width="77px" height="36px" />
      </FlexComp>
    </TravellerDetailsStyle>
  );
};

export default TravellerDetailsShimmer;
