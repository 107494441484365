import * as React from "react";
interface iconProps {}
const HeartCare = (props: iconProps) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.2565 11.0258C19.2031 9.0743 19.2017 5.91439 17.2521 3.96476C15.3767 2.08934 12.3814 2.01669 10.4191 3.74648C10.2884 3.8617 10.0935 3.8617 9.96283 3.74648C8.00051 2.01669 5.00519 2.08934 3.12978 3.96476C1.18018 5.91436 1.17878 9.0743 3.12538 11.0258L9.94838 17.849C10.0823 17.983 10.2995 17.983 10.4335 17.849L17.2565 11.0258Z"
      fill="#FB4A59"
    />
    <path
      d="M17.2564 11.0256L10.4334 17.849C10.2996 17.9828 10.0822 17.9828 9.94836 17.849L9.26367 17.1643L15.9237 10.5043C17.8701 8.55272 17.8687 5.39268 15.9192 3.44321C15.5947 3.11872 15.2366 2.84807 14.8558 2.63159C15.734 2.83571 16.5672 3.27992 17.2519 3.96464C19.2018 5.91407 19.2031 9.07411 17.2564 11.0256Z"
      fill="#F82F40"
    />
    <path
      d="M10.3335 13.1312C10.3335 12.6894 9.97532 12.3312 9.5335 12.3312H7.83554V10.6333C7.83554 10.1914 7.47737 9.83325 7.03554 9.83325H5.63145C5.18962 9.83325 4.83145 10.1914 4.83145 10.6333V12.3312H3.1335C2.69167 12.3312 2.3335 12.6894 2.3335 13.1312V14.5353C2.3335 14.9771 2.69167 15.3353 3.1335 15.3353H4.83145V17.0333C4.83145 17.4751 5.18962 17.8333 5.63145 17.8333H7.03554C7.47737 17.8333 7.83554 17.4751 7.83554 17.0333V15.3353H9.5335C9.97532 15.3353 10.3335 14.9771 10.3335 14.5353V13.1312Z"
      fill="#F2CC98"
    />
    <rect
      x={5}
      y={13.1667}
      width={2.66667}
      height={1.33333}
      rx={0.4}
      fill="#FFEBCF"
    />
  </svg>
);
export { HeartCare };
