import styled from "styled-components";
type DescriptionStyleProps = {
  isCursorPointer?: boolean;
};

export const ArrowButtonWrap = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
`;

export const DescriptionWrap = styled.div<DescriptionStyleProps>`
  cursor: none;
  margin: 10px 0px 0px 32px;
  border-radius: 4px;
  padding: 10px 6px 10px 10px;
  background-color: #f6f6f6;
  cursor: ${(props) => (props.isCursorPointer ? "pointer" : "default")};
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > span {
    margin-bottom: -6px; /* Optional: Adds space between rows */
  }
`;
