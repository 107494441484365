import BreadCrumb from "@forex/Forex/Breadcrumb";
import ForexProduct from "../CurrencyConverterPageTemplate/ForexProducts";
import { ForexProductsStyle } from "../CurrencyConverterPageTemplate/ForexProducts.styles";

interface HeroV2Props {
  title?: string;
  desc?: string;
  breadcrumb?: Array<{ name: string; link: string }>;
}

export function HeroV2(props: HeroV2Props): JSX.Element {
  const { title = "", desc = "" } = props;
  return (
    <section>
      <div className="heroSection__inner heroPadding">
        <BreadCrumb breadCrumbData={props.breadcrumb || []} />
        <div className="heroSection_info">
          <h2>{title}</h2>
          <p>{desc}</p>
        </div>
      </div>
    </section>
  );
}
