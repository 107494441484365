import { useEffect } from "react";

import { getHostName, isApiRequestCompleted } from "./../../utils/index";
import { CURRENCY_RATE_REFRESH_TIME, HOST } from "../../constants";
import useCountTimer from "../useCountTimer";
import { useGlobalSelector } from "../useGlobalSelector";
import useFetchForexRatex from "../useFetchForexRates";
import { ListingReducerType } from "../../store/forexListingReducer";
import { useGlobalConfig } from "../useGlobalConfig";

const useRefreshCurrencyRates = () => {
  const host = getHostName();
  const {
    forex_rates,
    isErrorListingPageLocationChange,
    isSuccessListingDetailsOnLocChange,
  } = useGlobalSelector((state: ListingReducerType) => state || {});

  const { count } = useCountTimer(0, CURRENCY_RATE_REFRESH_TIME);
  const { fetchForexRates } = useFetchForexRatex();
  const { isBot } = useGlobalConfig();

  useEffect(() => {
    if (count <= 0 || host === HOST.MAKEMYTRIP || isBot) return;

    (async () => {
      await fetchForexRates();
    })();
  }, [count, host, isBot]);

  const isProdListingDetailsReqCompleted = isApiRequestCompleted(
    isSuccessListingDetailsOnLocChange,
    isErrorListingPageLocationChange
  );
  const forexRatesLength = forex_rates && Object.keys(forex_rates).length;

  useEffect(() => {
    if (host === HOST.MAKEMYTRIP || isBot) {
      return;
    }
    if (
      !isProdListingDetailsReqCompleted ||
      (isProdListingDetailsReqCompleted && forexRatesLength)
    )
      return;
    (async () => {
      await fetchForexRates();
    })();
  }, [isProdListingDetailsReqCompleted, forexRatesLength, host, isBot]);
};

export default useRefreshCurrencyRates;
