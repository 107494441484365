import dynamic from "next/dynamic";
import { useBreakpointV2 } from "@tm/ui-widgets";
import { LandingHeaderMobile } from "..";
import Router from "next/router";
import { PARTNER } from "@tm/insurance/constants";
import { navigationActions } from "@tm/insurance/navigation";
import { useState } from "react";
import { Header } from "@growth/dweb-header";

const GiDwebAuth = dynamic(() =>
  import("@growth/gi-dweb-auth").then((p) => p.default)
);
const GiMwebAuth = dynamic(
  () => import("@growth/gi-mweb-auth").then((p) => p.MwebAuth),
  {
    ssr: false,
  }
);
export interface GIHeaderProps {
  lobName?:
    | "Flights"
    | "Hotels"
    | "Holidays"
    | "Buses"
    | "Cabs"
    | "Trains"
    | "Giftcards"
    | "insurance";
  sticky?: boolean;
  shouldShowCloseButton?: boolean;
  navOrigin?: string;
}

interface GIAuthProps {
  // forceLogin?: boolean;
  headerProps?: GIHeaderProps;
  mobileTitle?: string;
  forceLogin?: boolean;
  myOrders?: boolean;
  goToMyOrders?: () => void;
  displayWhiteHeader?: boolean;
}

export function GIInsuranceHeader({
  headerProps = {},
  mobileTitle = "",
  forceLogin = false,
  myOrders,
  goToMyOrders,
  displayWhiteHeader,
}: GIAuthProps) {
  const device = useBreakpointV2();
  const headerPropsWithDefaults: GIHeaderProps = {
    lobName: "insurance",
    sticky: true,
    shouldShowCloseButton: true,
    navOrigin: "https://www.goibibo.com",
    ...headerProps,
  };
  let authComponent = null;
  const [isAuthComponentVisible, setIsAuthComponentVisible] =
    useState(forceLogin);
  if (isAuthComponentVisible) {
    authComponent =
      device === "lg" ? (
        <GiDwebAuth
          prefillMobileNumber={undefined}
          showCloseButton={false}
          onClose={() => console.log("Closed")}
          lobName={"insurance"}
          onLoginSuccess={() => Router.reload()}
          configData={undefined}
        />
      ) : (
        <GiMwebAuth
          prefillMobileNumber={undefined}
          showCloseButton={false}
          onClose={() => console.log("Closed")}
          lobName={""}
          onLoginSuccess={() => {
            setIsAuthComponentVisible(false);
            setTimeout(() => {
              Router.reload();
            }, 100);
          }}
          showAuth={true}
        />
      );
    authComponent = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          backgroundColor: "rgba(0,0,0,0.7)",
        }}
      >
        {authComponent}
      </div>
    );
  }
  return (
    <>
      {authComponent}
      {device === "lg" ? (
        <Header {...headerPropsWithDefaults} />
      ) : (
        <LandingHeaderMobile
          headerTitle={mobileTitle}
          myOrders={myOrders}
          goToMyOrders={goToMyOrders}
          displayWhiteHeader={displayWhiteHeader}
          partnerType={PARTNER.GI}
          insuranceHeader
        ></LandingHeaderMobile>
      )}
    </>
  );
}
