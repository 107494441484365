import { useEffect, useState } from "react";

const useScrolledMwebHeaderContent = () => {
  const [scrolledMWebHeaderContent, setScrolledMWebHeaderContent] =
    useState(false);

  const changeNavBg = () => {
    var scrollPos = window.scrollY;
    scrollPos >= 200
      ? setScrolledMWebHeaderContent(true)
      : setScrolledMWebHeaderContent(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);
    return () => window.removeEventListener("scroll", changeNavBg);
  }, []);

  return {
    scrolledMWebHeaderContent,
  };
};

export default useScrolledMwebHeaderContent;
