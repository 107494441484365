import styled from "styled-components";
import { SpanTagStyle } from "../TextStyles/TextStyles.style";

export interface countryCodeInput {
  margin?: string;
  inputWidth?: string;
  flex?: string;
  disableState?: boolean;
  errorState?: string;
  Verify?: boolean;
}

export const InputWrap = styled.div<countryCodeInput>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.margin};
  width: ${(props) => props.inputWidth};
  flex: ${(p) => (p.flex ? p.flex : "")};
  flex-shrink: 0;
  label {
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    color: #4a4a4a;
    display: block;
    margin-bottom: 8px;
    transition: all 0.3s ease;
    display: inline-block;
  }
`;

export const ErrorTxt = styled(SpanTagStyle)`
  color: #eb2026;
`;

export const LandingInputStyle = styled.input<countryCodeInput>`
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 10px 10px 10px 64px;
  font-weight: 900;
  font-size: 14px;
  line-height: 19px;
  display: block;

  &::placeholder {
    font-weight: 400;
  }

  &:focus + label {
    color: #008cff;
  }

  ${(props) =>
    props.disableState &&
    `
      opacity: 0.3;
      pointer-events: none;
	`}
  ${(props) =>
    props.errorState &&
    `
      border: 1px solid #EB2026;
	`}

${(props) =>
    props.Verify &&
    `
      color: #008CFF;
      background: #F9FCFF;
      border: 1px solid #008CFF;
      pointer-events: none;
	`}

  &:focus {
    outline: none;
    border: 1px solid #008cff;
    background: #f9fcff;
  }
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const CounryCodeWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  left: 16px;
  top: 39px;
  z-index: 1;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    top: 5px;
    right: -13px;
    border: solid #008cff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
`;
