import * as React from "react";
interface TMLogoProps {
  color?: string;
}
export const TMLogo: React.FC<TMLogoProps> = ({ color = "#fff", ...props }) => (
  <svg
    width={140}
    height={39}
    viewBox="0 0 140 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.50765 10.3115V17.8732C6.50765 18.5606 6.73679 18.7897 6.87427 18.8814C7.14924 19.0189 7.47004 19.1105 7.8825 19.1105C8.2033 19.1105 9.1657 18.8356 9.71564 18.6064L10.3114 18.4231V21.3103L10.0823 21.4478C9.25735 21.8602 8.38661 22.0894 7.47004 22.0894C3.89542 22.0894 3.11634 19.9355 3.11634 18.1023V10.3115H0L6.50765 3.758V7.33262H10.3572V10.3115H6.50765Z"
      fill="white"
    />
    <path
      d="M21.0806 7.05756V10.2656H20.3932C19.4308 10.2656 18.5142 10.4489 17.6435 10.8155C16.8644 11.1821 16.2686 11.6404 15.8103 12.2362V21.7227H12.4648V7.33253H14.8938L15.4895 9.07401C16.7727 7.74498 18.4225 7.10338 20.3473 7.10338H21.0806V7.05756Z"
      fill="white"
    />
    <path
      d="M26.3511 3.57461C26.3511 4.53701 25.572 5.3161 24.6096 5.3161C23.6472 5.3161 22.8682 4.53701 22.8682 3.57461C22.8682 2.61221 23.6472 1.83313 24.6096 1.83313C25.5262 1.83313 26.3511 2.61221 26.3511 3.57461Z"
      fill="white"
    />
    <path d="M26.2136 7.33258H22.8682V21.8144H26.2136V7.33258Z" fill="white" />
    <path
      d="M41.8865 9.07394C40.5117 7.69909 38.9077 7.01166 37.0745 7.01166C35.2414 7.01166 33.4999 8.11154 32.7208 8.70731L32.0334 7.33246H29.6045V27.9095H32.95V20.6227C34.1415 21.5851 35.5164 22.0892 37.0287 22.0892C38.816 22.0892 40.3742 21.4476 41.7032 20.1644L41.8407 20.0269C43.2156 18.6521 43.903 16.819 43.903 14.5734C43.9488 12.2819 43.2614 10.4488 41.8865 9.07394ZM36.7079 18.8812C35.2872 18.8812 34.0499 18.3313 32.95 17.1856V11.9153C34.0499 10.7696 35.2414 10.2197 36.7079 10.2197C37.8994 10.2197 38.816 10.5863 39.5493 11.3195C40.2825 12.0528 40.6033 13.1527 40.6033 14.5275C40.6033 15.9024 40.2367 17.0023 39.5493 17.7355C38.816 18.5146 37.8994 18.8812 36.7079 18.8812Z"
      fill="white"
    />
    <path
      d="M90.3266 9.11984C88.9976 7.74498 87.2103 7.05756 85.0563 7.05756C82.9024 7.05756 81.1151 7.74498 79.7861 9.11984C78.457 10.4947 77.8154 12.3278 77.8154 14.5276C77.8154 16.7732 78.5029 18.5605 79.7861 19.9354C81.1151 21.3102 82.9024 21.9976 85.0563 21.9976C87.2103 21.9976 88.9976 21.3102 90.3266 19.9354C91.6556 18.5605 92.3431 16.7274 92.3431 14.5276C92.2972 12.3278 91.6098 10.4947 90.3266 9.11984ZM85.0563 18.9271C84.0023 18.9271 83.0857 18.5605 82.3066 17.8273C81.5275 17.1398 81.1151 15.9941 81.1151 14.5276C81.1151 13.0611 81.5275 11.9612 82.2608 11.2279C83.0399 10.4947 83.9565 10.1281 85.0105 10.1281C86.0646 10.1281 86.9811 10.4947 87.7602 11.2279C88.5393 11.9154 88.9059 13.0153 88.9059 14.5276C88.9059 15.9941 88.4935 17.1398 87.7602 17.8273C87.027 18.5605 86.1104 18.9271 85.0563 18.9271Z"
      fill="white"
    />
    <path
      d="M107.789 12.6945V21.7685H104.443V12.9694C104.443 11.0446 103.664 10.1739 101.968 10.1739C100.594 10.1739 99.3104 10.953 98.073 12.4195V21.7685H94.7275V7.33253H97.1565L97.798 9.07401C99.2187 7.74498 100.777 7.05756 102.564 7.05756C104.168 7.05756 105.451 7.6075 106.414 8.70738C107.33 9.76144 107.789 11.1363 107.789 12.6945Z"
      fill="white"
    />
    <path
      d="M122.132 17.598C120.849 18.423 119.474 18.8354 118.008 18.8354C116.541 18.8354 115.441 18.5146 114.754 17.9188C114.158 17.4147 113.791 16.7273 113.608 15.8107H123.782V14.0234C123.69 12.1444 123.14 10.5405 122.086 9.21143C120.941 7.74491 119.199 7.01166 116.999 7.01166C114.8 7.01166 113.104 7.74491 111.912 9.21143C110.767 10.6779 110.171 12.5111 110.171 14.7108C110.171 16.9106 110.812 18.6979 112.142 20.0269C113.425 21.356 115.166 21.9976 117.32 21.9976C119.382 21.9976 121.261 21.5393 122.82 20.6685L123.003 20.6227L122.82 17.1856L122.132 17.598ZM113.562 12.9694C113.7 12.0528 114.066 11.3654 114.616 10.9071C115.304 10.3113 116.083 10.0363 116.954 10.0363C117.824 10.0363 118.603 10.3113 119.291 10.9071C119.887 11.4112 120.253 12.0986 120.345 12.9694H113.562Z"
      fill="white"
    />
    <path
      d="M139.181 7.33258L138.952 7.92835L130.657 27.9096H127.22L129.694 21.5394L123.92 7.33258H127.495L131.482 17.369L135.606 7.33258H139.181Z"
      fill="white"
    />
    <path
      d="M46.2871 7.43108C46.2871 2.92739 49.2145 0 53.7182 0H68.1863C72.6899 0 75.6173 2.92739 75.6173 7.43108V21.8992C75.6173 26.4028 72.6899 29.3302 68.1863 29.3302H53.7182C49.2145 29.3302 46.2871 26.4028 46.2871 21.8992V7.43108Z"
      fill="#ED1C24"
    />
    <mask
      id="mask0_705_6507"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={46}
      y={0}
      width={30}
      height={30}
    >
      <path
        d="M46.2871 7.43108C46.2871 2.92739 49.2145 0 53.7182 0H68.1863C72.6899 0 75.6173 2.92739 75.6173 7.43108V21.8992C75.6173 26.4028 72.6899 29.3302 68.1863 29.3302H53.7182C49.2145 29.3302 46.2871 26.4028 46.2871 21.8992V7.43108Z"
        fill="#ED1C24"
      />
    </mask>
    <g mask="url(#mask0_705_6507)">
      <path
        d="M66.7923 14.4447C66.509 15.7452 66.2618 19.2469 66.2285 20.0863C66.1952 20.9256 66.4681 21.4408 67.139 21.7729C67.713 22.0571 68.2544 22.0497 68.7764 21.6786C68.9721 21.5394 68.6736 19.3188 68.7764 17.8125C68.99 14.6821 70.452 9.9545 70.452 9.9545L70.4562 9.92934C70.4823 9.77505 70.5081 9.62221 70.5081 9.49359C70.5081 8.6807 70.1105 8.16593 69.2593 8.08467C68.3803 8.00316 67.9252 8.40997 67.1315 9.33106C65.371 11.4173 63.2706 14.5338 62.2776 15.9703C62.1648 16.1319 61.9656 16.0783 61.9656 15.8614C61.9656 15.2113 64.3313 8.27607 64.3313 8.27607C64.3313 8.27607 64.6098 7.32586 63.798 6.99692C63.798 6.99692 62.5792 6.26529 61.5454 7.7157L56.7459 13.9816C56.3691 14.4612 56.1821 14.2837 56.1821 14.2837C56.065 14.2266 56.1404 13.8693 56.1404 13.8693L56.8973 9.78469C57.5031 6.64445 56.349 6.47925 55.6277 6.38658C54.9067 6.29391 54.041 6.81232 54.041 6.81232C52.5407 7.53133 51.4086 10.3921 51.4086 10.3921C51.4086 10.3921 51.0694 11.41 51.7841 11.8025C51.7841 11.8025 52.0557 12.0179 52.5829 11.9995C53.6307 11.9626 53.8487 10.0554 54.615 10.0554C54.7575 10.0554 54.7575 10.2179 54.7575 10.2451L52.279 20.2032C52.279 20.2032 51.995 21.1893 52.8987 21.7169C54.0542 22.3913 54.8025 21.2703 54.8025 21.2703L55.6691 19.9327C56.9926 17.7194 59.3911 14.5466 59.6462 14.5466C59.7315 14.5466 59.7595 14.5745 59.7595 14.6546L58.9122 20.4902C58.9122 20.4902 58.7292 21.4561 59.6241 21.7729C59.6241 21.7729 60.4973 22.0653 60.9999 21.6786C60.9999 21.6786 61.6254 21.2739 62.2489 20.2975C62.7296 19.5446 65.1469 16.8284 66.6221 14.3361C66.7361 14.1733 66.821 14.3086 66.7923 14.4447Z"
        fill="white"
      />
    </g>
    <path
      d="M32.4004 37.3046L31.7588 35.7464H29.7882L29.1924 37.3046H28.5967L30.5215 32.4009H31.0256L32.9504 37.3046H32.4004ZM31.6214 35.2423L31.0256 33.6841C31.0256 33.6383 30.9798 33.5925 30.9798 33.5008C30.9339 33.4092 30.9339 33.3175 30.8881 33.2259C30.8423 33.1342 30.8423 33.0425 30.7964 32.9967C30.7964 33.0884 30.7506 33.18 30.7048 33.2717C30.659 33.3633 30.659 33.455 30.6131 33.5008C30.5673 33.5925 30.5673 33.6383 30.5673 33.6841L29.9715 35.2423H31.6214Z"
      fill="white"
    />
    <path
      d="M37.6705 37.3046L36.0206 32.9509H35.9748C35.9748 33.0425 35.9748 33.1342 36.0206 33.2717C36.0206 33.4092 36.0206 33.5467 36.0206 33.6841C36.0206 33.8216 36.0206 34.0049 36.0206 34.1424V37.2588H35.4707V32.4009H36.3414L37.8996 36.4797H37.9454L39.5494 32.4009H40.3744V37.3046H39.8244V34.1424C39.8244 34.0049 39.8244 33.8675 39.8244 33.6841C39.8244 33.5467 39.8244 33.4092 39.8244 33.2717C39.8244 33.1342 39.8244 33.0425 39.8244 32.9509H39.7786L38.0829 37.3046H37.6705Z"
      fill="white"
    />
    <path
      d="M43.0317 33.5467C43.4899 33.5467 43.8107 33.6384 44.0399 33.8217C44.269 34.005 44.3607 34.3258 44.3607 34.7841V37.3046H43.9482L43.8566 36.7547H43.8107C43.7191 36.8922 43.5816 36.9838 43.4899 37.0755C43.3983 37.1671 43.2608 37.213 43.0775 37.2588C42.94 37.3046 42.7567 37.3046 42.5275 37.3046C42.2984 37.3046 42.1151 37.2588 41.9318 37.1671C41.7485 37.0755 41.611 36.9838 41.5193 36.8005C41.4277 36.663 41.3818 36.4339 41.3818 36.2047C41.3818 35.8381 41.5193 35.5631 41.8401 35.3798C42.1151 35.1965 42.5734 35.059 43.1691 35.059L43.8107 35.0132V34.7841C43.8107 34.4633 43.7191 34.2341 43.5816 34.0966C43.4441 33.9592 43.2608 33.9133 42.9858 33.9133C42.8025 33.9133 42.6192 33.9591 42.4359 34.005C42.2526 34.0508 42.1151 34.1425 41.9318 34.1883L41.7485 33.7758C41.9318 33.6842 42.1151 33.6383 42.3442 33.5467C42.5734 33.5925 42.8025 33.5467 43.0317 33.5467ZM43.7649 35.4715L43.215 35.5173C42.7567 35.5173 42.4359 35.609 42.2526 35.7465C42.0693 35.8839 41.9776 36.0673 41.9776 36.2964C41.9776 36.5255 42.0234 36.663 42.1609 36.7547C42.2984 36.8463 42.4817 36.8922 42.665 36.8922C42.9858 36.8922 43.2608 36.8005 43.4899 36.6172C43.7191 36.4339 43.8107 36.1589 43.8107 35.7923V35.4715H43.7649Z"
      fill="white"
    />
    <path
      d="M46.0568 32.0801V34.784C46.0568 34.8756 46.0568 34.9673 46.0568 35.1048C46.0568 35.2422 46.0568 35.3339 46.0568 35.4256H46.1026C46.1484 35.3797 46.2401 35.2881 46.3317 35.1506C46.4234 35.0589 46.5151 34.9673 46.5609 34.8756L47.7983 33.5924H48.4399L46.9275 35.1506L48.5315 37.2587H47.8441L46.5151 35.5172L46.0568 35.9297V37.2587H45.5068V32.0801H46.0568Z"
      fill="white"
    />
    <path
      d="M50.5942 33.5467C50.915 33.5467 51.19 33.6383 51.4191 33.7758C51.6483 33.9133 51.8316 34.0966 51.9233 34.3716C52.0607 34.6007 52.1066 34.9215 52.1066 35.2423V35.609H49.5402C49.5402 36.0214 49.6777 36.3881 49.861 36.6172C50.0901 36.8463 50.3651 36.938 50.7317 36.938C50.9609 36.938 51.19 36.938 51.3733 36.8922C51.5566 36.8463 51.7399 36.8005 51.9233 36.7089V37.1213C51.7399 37.213 51.5566 37.2588 51.3733 37.3046C51.19 37.3505 50.9609 37.3505 50.7317 37.3505C50.3651 37.3505 50.0443 37.2588 49.8151 37.1213C49.5402 36.9838 49.3569 36.7547 49.2194 36.4797C49.0819 36.2047 48.9902 35.8381 48.9902 35.4715C48.9902 35.059 49.0361 34.7382 49.1735 34.4633C49.311 34.1883 49.4943 33.9591 49.7235 33.7758C49.9526 33.6383 50.2276 33.5467 50.5942 33.5467ZM50.5484 34.005C50.2276 34.005 49.9985 34.0966 49.8151 34.28C49.6318 34.4633 49.5402 34.7382 49.4943 35.1049H51.465C51.465 34.8757 51.4191 34.6924 51.3733 34.5091C51.3275 34.3258 51.19 34.2341 51.0525 34.1425C50.9609 34.0508 50.7775 34.005 50.5484 34.005Z"
      fill="white"
    />
    <path
      d="M55.315 37.3046L53.6652 32.9509H53.6193C53.6193 33.0425 53.6193 33.1342 53.6652 33.2717C53.6652 33.4092 53.6652 33.5467 53.6652 33.6841C53.6652 33.8216 53.6652 34.0049 53.6652 34.1424V37.2588H53.1152V32.4009H53.986L55.5441 36.4797H55.59L57.194 32.4009H58.0189V37.3046H57.4689V34.1424C57.4689 34.0049 57.4689 33.8675 57.4689 33.6841C57.4689 33.5467 57.4689 33.4092 57.4689 33.2717C57.4689 33.1342 57.4689 33.0425 57.4689 32.9509L55.7733 37.3046H55.315Z"
      fill="white"
    />
    <path
      d="M58.7061 33.6382H59.3018L60.1267 35.7921C60.1726 35.9296 60.2184 36.0213 60.2642 36.1588C60.3101 36.2504 60.3559 36.3879 60.3559 36.4795C60.3559 36.5712 60.4017 36.7087 60.4475 36.8004H60.4934C60.5392 36.7087 60.585 36.5254 60.6309 36.3421C60.6767 36.1588 60.7683 35.9754 60.8142 35.7921L61.5933 33.684H62.189L60.585 37.9002C60.4934 38.1294 60.4017 38.3127 60.2642 38.496C60.1726 38.6793 60.0351 38.771 59.8518 38.8626C59.6685 38.9543 59.4851 39.0001 59.256 39.0001C59.1643 39.0001 59.0727 39.0001 58.981 39.0001C58.8894 39.0001 58.8435 38.9543 58.7519 38.9543V38.496C58.7977 38.496 58.8435 38.496 58.9352 38.5418C59.0269 38.5418 59.0727 38.5418 59.1643 38.5418C59.3018 38.5418 59.4393 38.496 59.531 38.4502C59.6226 38.4043 59.7143 38.3127 59.8059 38.221C59.8976 38.1294 59.9434 37.9919 59.9893 37.8544L60.1726 37.3503L58.7061 33.6382Z"
      fill="white"
    />
    <path
      d="M64.3883 37.3046H63.7925V32.9051H62.2344V32.4009H65.9465V32.9051H64.3883V37.3046Z"
      fill="white"
    />
    <path
      d="M68.2836 33.5467C68.3752 33.5467 68.421 33.5467 68.5127 33.5467C68.6043 33.5467 68.6502 33.5467 68.7418 33.5925L68.6502 34.0966C68.6043 34.0966 68.5127 34.0508 68.4669 34.0508C68.3752 34.0508 68.3294 34.0508 68.2377 34.0508C68.1002 34.0508 67.9628 34.0966 67.8253 34.1425C67.6878 34.1883 67.5961 34.2799 67.4586 34.4174C67.367 34.5091 67.2753 34.6466 67.2295 34.8299C67.1837 34.9674 67.1378 35.1507 67.1378 35.334V37.3046H66.5879V33.6384H67.0462L67.092 34.3258H67.1378C67.2295 34.1883 67.3211 34.0508 67.4128 33.9591C67.5045 33.8675 67.6419 33.7758 67.7794 33.6842C67.9627 33.5925 68.1002 33.5467 68.2836 33.5467Z"
      fill="white"
    />
    <path
      d="M69.9796 33.6382V37.3044H69.4296V33.6382H69.9796ZM69.7046 32.2633C69.7962 32.2633 69.8879 32.3091 69.9337 32.355C69.9796 32.4008 70.0254 32.4924 70.0254 32.6299C70.0254 32.7674 69.9796 32.8591 69.9337 32.9049C69.8879 32.9507 69.7962 32.9966 69.7046 32.9966C69.6129 32.9966 69.5213 32.9507 69.4754 32.9049C69.4296 32.8591 69.3838 32.7674 69.3838 32.6299C69.3838 32.4924 69.4296 32.4008 69.4754 32.355C69.5671 32.2633 69.6129 32.2633 69.7046 32.2633Z"
      fill="white"
    />
    <path
      d="M72.9583 33.5467C73.4166 33.5467 73.7832 33.6842 74.0582 34.005C74.3331 34.3258 74.4706 34.7841 74.4706 35.4257C74.4706 35.8381 74.4248 36.2047 74.2873 36.4797C74.1498 36.7547 73.9665 36.9838 73.7374 37.1213C73.5082 37.2588 73.2332 37.3505 72.9124 37.3505C72.7291 37.3505 72.5458 37.3046 72.4083 37.2588C72.2709 37.213 72.1334 37.1213 72.0417 37.0297C71.9501 36.938 71.8584 36.8463 71.7667 36.7547H71.7209C71.7209 36.8463 71.7209 36.938 71.7667 37.0755C71.7667 37.213 71.7667 37.3046 71.7667 37.3963V38.9545H71.2168V33.6384H71.6751L71.7667 34.1883H71.8126C71.9042 34.0966 71.95 33.9592 72.0875 33.8675C72.1792 33.7758 72.3167 33.6842 72.4542 33.6384C72.5458 33.5925 72.7291 33.5467 72.9583 33.5467ZM72.8208 34.005C72.5458 34.005 72.3625 34.0508 72.1792 34.1425C72.0417 34.2341 71.9042 34.3716 71.8584 34.6007C71.7667 34.7841 71.7667 35.059 71.7209 35.334V35.4257C71.7209 35.7465 71.7667 36.0214 71.8126 36.2047C71.9042 36.4339 71.9959 36.5714 72.1334 36.7089C72.3167 36.8005 72.5 36.8922 72.775 36.8922C73.0041 36.8922 73.1874 36.8463 73.3707 36.7089C73.5082 36.5714 73.6457 36.4339 73.6915 36.2047C73.7832 35.9756 73.829 35.7465 73.829 35.4257C73.829 35.0132 73.7374 34.6466 73.5541 34.3716C73.4624 34.1425 73.1874 34.005 72.8208 34.005Z"
      fill="white"
    />
    <path
      d="M79.4671 34.7381H81.2085V37.1212C80.9336 37.2129 80.6586 37.2587 80.3836 37.3045C80.1087 37.3503 79.7879 37.3503 79.4671 37.3503C78.963 37.3503 78.5047 37.2587 78.1839 37.0295C77.8172 36.8462 77.5423 36.5254 77.359 36.1588C77.1756 35.7922 77.084 35.3339 77.084 34.8298C77.084 34.3257 77.1756 33.8674 77.4048 33.5007C77.5881 33.1341 77.9089 32.8133 78.2755 32.63C78.6421 32.4009 79.1004 32.3092 79.6504 32.3092C79.9253 32.3092 80.2003 32.355 80.4295 32.4009C80.6586 32.4467 80.8878 32.5383 81.1169 32.63L80.8878 33.1341C80.7044 33.0425 80.5211 32.9966 80.292 32.9508C80.0628 32.905 79.8337 32.8591 79.6046 32.8591C79.1921 32.8591 78.8713 32.9508 78.5505 33.0883C78.2755 33.2716 78.0464 33.5007 77.9089 33.7757C77.7714 34.0965 77.6798 34.4173 77.6798 34.8298C77.6798 35.2422 77.7256 35.6089 77.8631 35.8838C78.0006 36.2046 78.1839 36.4338 78.5047 36.5713C78.7796 36.7546 79.1463 36.8004 79.6046 36.8004C79.8337 36.8004 80.017 36.8004 80.2003 36.7546C80.3836 36.7087 80.5211 36.7087 80.6586 36.6629V35.1506H79.4671V34.7381Z"
      fill="white"
    />
    <path
      d="M84.0033 33.5467C84.0949 33.5467 84.1408 33.5467 84.2324 33.5467C84.3241 33.5467 84.3699 33.5467 84.4616 33.5925L84.3699 34.0966C84.3241 34.0966 84.2324 34.0508 84.1866 34.0508C84.0949 34.0508 84.0491 34.0508 83.9574 34.0508C83.82 34.0508 83.6825 34.0966 83.545 34.1425C83.4075 34.1883 83.3158 34.2799 83.1784 34.4174C83.0867 34.5091 82.995 34.6466 82.9492 34.8299C82.9034 34.9674 82.8576 35.1507 82.8576 35.334V37.3046H82.3076V33.6384H82.7659L82.8117 34.3258H82.8576C82.9492 34.1883 83.0409 34.0508 83.1325 33.9591C83.2242 33.8675 83.3617 33.7758 83.4992 33.6842C83.6366 33.5925 83.82 33.5467 84.0033 33.5467Z"
      fill="white"
    />
    <path
      d="M88.357 35.4713C88.357 35.7921 88.3112 36.0213 88.2195 36.2962C88.1278 36.5254 88.0362 36.7545 87.8987 36.892C87.7612 37.0753 87.5779 37.167 87.3488 37.2586C87.1196 37.3503 86.8905 37.3961 86.6155 37.3961C86.3864 37.3961 86.1572 37.3503 85.9281 37.2586C85.7448 37.167 85.5614 37.0295 85.3781 36.892C85.2406 36.7087 85.1032 36.5254 85.0115 36.2962C84.9199 36.0671 84.874 35.7921 84.874 35.4713C84.874 35.0589 84.9657 34.7381 85.1032 34.4631C85.2407 34.1881 85.424 33.959 85.6989 33.8215C85.9739 33.684 86.2489 33.5923 86.6155 33.5923C86.9363 33.5923 87.2571 33.684 87.4862 33.8215C87.7612 33.959 87.9445 34.1881 88.082 34.4631C88.2653 34.6922 88.357 35.0589 88.357 35.4713ZM85.5156 35.4713C85.5156 35.7463 85.5614 36.0213 85.6531 36.2504C85.7448 36.4795 85.8364 36.617 86.0197 36.7545C86.203 36.892 86.3864 36.9378 86.6613 36.9378C86.9363 36.9378 87.1196 36.892 87.3029 36.7545C87.4862 36.617 87.5779 36.4795 87.6696 36.2504C87.7612 36.0213 87.807 35.7921 87.807 35.4713C87.807 35.1963 87.7612 34.9214 87.6696 34.6922C87.5779 34.4631 87.4862 34.3256 87.3029 34.1881C87.1196 34.0506 86.9363 34.0048 86.6613 34.0048C86.2947 34.0048 85.9739 34.1423 85.7906 34.3714C85.6073 34.6464 85.5156 35.013 85.5156 35.4713Z"
      fill="white"
    />
    <path
      d="M92.3888 33.6384V37.3046H91.9305L91.8389 36.8005C91.7472 36.938 91.6555 37.0297 91.5181 37.1213C91.3806 37.213 91.2431 37.2588 91.1056 37.3046C90.9681 37.3505 90.7848 37.3505 90.6015 37.3505C90.3265 37.3505 90.0515 37.3046 89.8682 37.213C89.6849 37.1213 89.5016 36.9838 89.4099 36.7547C89.3183 36.5714 89.2725 36.2964 89.2725 35.9756V33.5467H89.8224V35.9298C89.8224 36.2506 89.9141 36.4797 90.0516 36.6172C90.189 36.7547 90.4182 36.8463 90.6931 36.8463C90.9681 36.8463 91.1973 36.8005 91.3347 36.7089C91.5181 36.6172 91.6097 36.4339 91.7014 36.2506C91.793 36.0673 91.793 35.7923 91.793 35.5173V33.5925H92.3888V33.6384Z"
      fill="white"
    />
    <path
      d="M95.3235 33.5467C95.7818 33.5467 96.1484 33.6842 96.4234 34.005C96.6984 34.3258 96.8359 34.7841 96.8359 35.4257C96.8359 35.8381 96.79 36.2047 96.6525 36.4797C96.5151 36.7547 96.3317 36.9838 96.1026 37.1213C95.8735 37.2588 95.5985 37.3505 95.2777 37.3505C95.0944 37.3505 94.9111 37.3046 94.7736 37.2588C94.6361 37.213 94.4986 37.1213 94.4069 37.0297C94.3153 36.938 94.2236 36.8463 94.132 36.7547H94.0861C94.0861 36.8463 94.0861 36.938 94.132 37.0755C94.132 37.213 94.132 37.3046 94.132 37.3963V38.9545H93.582V33.6384H94.0403L94.132 34.1883H94.1778C94.2695 34.0966 94.3153 33.9592 94.4528 33.8675C94.5444 33.7758 94.6819 33.6842 94.8194 33.6384C94.9569 33.5925 95.1402 33.5467 95.3235 33.5467ZM95.2319 34.005C94.9569 34.005 94.7736 34.0508 94.5903 34.1425C94.4528 34.2341 94.3153 34.3716 94.2695 34.6007C94.1778 34.7841 94.1778 35.059 94.132 35.334V35.4257C94.132 35.7465 94.1778 36.0214 94.2236 36.2047C94.3153 36.4339 94.4069 36.5714 94.5444 36.7089C94.7277 36.8005 94.9111 36.8922 95.186 36.8922C95.4152 36.8922 95.5985 36.8463 95.7818 36.7089C95.9193 36.5714 96.0568 36.4339 96.1026 36.2047C96.1943 35.9756 96.2401 35.7465 96.2401 35.4257C96.2401 35.0132 96.1484 34.6466 95.9651 34.3716C95.8276 34.1425 95.5985 34.005 95.2319 34.005Z"
      fill="white"
    />
    <path
      d="M101.83 32.8133C101.555 32.8133 101.326 32.8591 101.097 32.9508C100.868 33.0425 100.685 33.1799 100.547 33.3633C100.41 33.5466 100.272 33.7299 100.181 34.0049C100.089 34.234 100.043 34.5548 100.043 34.8298C100.043 35.2422 100.089 35.6089 100.226 35.8838C100.364 36.2046 100.547 36.4338 100.822 36.5713C101.097 36.7546 101.418 36.8004 101.785 36.8004C102.014 36.8004 102.197 36.8004 102.426 36.7546C102.61 36.7087 102.793 36.6629 103.022 36.6171V37.1212C102.839 37.167 102.655 37.2587 102.472 37.2587C102.289 37.3045 102.06 37.3045 101.785 37.3045C101.28 37.3045 100.868 37.2129 100.501 36.9837C100.181 36.7546 99.9056 36.4796 99.7223 36.113C99.539 35.7463 99.4932 35.2881 99.4932 34.7839C99.4932 34.4173 99.539 34.0965 99.6307 33.7757C99.7223 33.4549 99.9056 33.1799 100.089 32.9966C100.272 32.7675 100.547 32.5842 100.822 32.4925C101.097 32.355 101.464 32.3092 101.83 32.3092C102.06 32.3092 102.335 32.355 102.564 32.4009C102.793 32.4467 103.022 32.5383 103.205 32.63L102.976 33.1341C102.839 33.0425 102.655 32.9966 102.472 32.9508C102.243 32.8591 102.06 32.8133 101.83 32.8133Z"
      fill="white"
    />
    <path
      d="M107.239 35.4713C107.239 35.7921 107.193 36.0213 107.101 36.2962C107.01 36.5254 106.918 36.7545 106.781 36.892C106.643 37.0753 106.46 37.167 106.231 37.2586C106.001 37.3503 105.772 37.3961 105.497 37.3961C105.268 37.3961 105.039 37.3503 104.81 37.2586C104.627 37.167 104.443 37.0295 104.26 36.892C104.122 36.7087 103.985 36.5254 103.893 36.2962C103.802 36.0671 103.756 35.7921 103.756 35.4713C103.756 35.0589 103.848 34.7381 103.985 34.4631C104.122 34.1881 104.306 33.959 104.581 33.8215C104.856 33.684 105.131 33.5923 105.497 33.5923C105.818 33.5923 106.139 33.684 106.368 33.8215C106.643 33.959 106.826 34.1881 106.964 34.4631C107.147 34.6922 107.239 35.0589 107.239 35.4713ZM104.397 35.4713C104.397 35.7463 104.443 36.0213 104.535 36.2504C104.627 36.4795 104.718 36.617 104.902 36.7545C105.085 36.892 105.268 36.9378 105.543 36.9378C105.818 36.9378 106.001 36.892 106.185 36.7545C106.368 36.617 106.46 36.4795 106.551 36.2504C106.643 36.0213 106.689 35.7921 106.689 35.4713C106.689 35.1963 106.643 34.9214 106.551 34.6922C106.46 34.4631 106.368 34.3256 106.185 34.1881C106.001 34.0506 105.818 34.0048 105.543 34.0048C105.177 34.0048 104.856 34.1423 104.672 34.3714C104.489 34.6464 104.397 35.013 104.397 35.4713Z"
      fill="white"
    />
    <path
      d="M112.234 33.5467C112.647 33.5467 112.967 33.6384 113.151 33.8675C113.38 34.0966 113.472 34.4174 113.472 34.8757V37.3046H112.922V34.9215C112.922 34.6466 112.876 34.4174 112.738 34.2341C112.601 34.0966 112.417 34.005 112.142 34.005C111.776 34.005 111.501 34.0966 111.363 34.3258C111.18 34.5091 111.134 34.8299 111.134 35.2423V37.3046H110.584V34.9215C110.584 34.7382 110.538 34.5549 110.493 34.4174C110.447 34.2799 110.355 34.1883 110.218 34.1425C110.126 34.0966 109.943 34.0508 109.805 34.0508C109.576 34.0508 109.347 34.0966 109.209 34.1883C109.072 34.2799 108.934 34.4174 108.889 34.6466C108.843 34.8299 108.797 35.1049 108.797 35.3798V37.3046H108.247V33.6384H108.705L108.797 34.1425H108.843C108.934 34.005 109.026 33.9133 109.118 33.8217C109.209 33.73 109.347 33.6842 109.484 33.6384C109.622 33.5925 109.759 33.5925 109.943 33.5925C110.218 33.5925 110.447 33.6383 110.63 33.73C110.813 33.8217 110.951 34.005 111.043 34.1883H111.088C111.226 33.9591 111.363 33.8217 111.593 33.73C111.73 33.5925 111.959 33.5467 112.234 33.5467Z"
      fill="white"
    />
    <path
      d="M116.358 33.5467C116.816 33.5467 117.183 33.6842 117.458 34.005C117.733 34.3258 117.87 34.7841 117.87 35.4257C117.87 35.8381 117.824 36.2047 117.687 36.4797C117.549 36.7547 117.366 36.9838 117.137 37.1213C116.908 37.2588 116.633 37.3505 116.312 37.3505C116.129 37.3505 115.945 37.3046 115.808 37.2588C115.67 37.213 115.533 37.1213 115.441 37.0297C115.349 36.938 115.258 36.8463 115.166 36.7547H115.12C115.12 36.8463 115.12 36.938 115.166 37.0755C115.166 37.213 115.166 37.3046 115.166 37.3963V38.9545H114.616V33.6384H115.074L115.166 34.1883H115.212C115.304 34.0966 115.395 33.9592 115.487 33.8675C115.579 33.7758 115.716 33.6842 115.854 33.6384C115.991 33.5925 116.174 33.5467 116.358 33.5467ZM116.266 34.005C115.991 34.005 115.808 34.0508 115.624 34.1425C115.487 34.2341 115.349 34.3716 115.304 34.6007C115.212 34.7841 115.212 35.059 115.166 35.334V35.4257C115.166 35.7465 115.212 36.0214 115.258 36.2047C115.349 36.4339 115.441 36.5714 115.579 36.7089C115.762 36.8005 115.945 36.8922 116.22 36.8922C116.449 36.8922 116.633 36.8463 116.816 36.7089C116.953 36.5714 117.091 36.4339 117.137 36.2047C117.228 35.9756 117.274 35.7465 117.274 35.4257C117.274 35.0132 117.183 34.6466 116.999 34.3716C116.862 34.1425 116.633 34.005 116.266 34.005Z"
      fill="white"
    />
    <path
      d="M120.253 33.5467C120.712 33.5467 121.032 33.6384 121.262 33.8217C121.491 34.005 121.582 34.3258 121.582 34.7841V37.3046H121.17L121.078 36.7547H121.032C120.941 36.8922 120.803 36.9838 120.712 37.0755C120.62 37.1671 120.482 37.213 120.299 37.2588C120.162 37.3046 119.978 37.3046 119.749 37.3046C119.52 37.3046 119.337 37.2588 119.153 37.1671C118.97 37.0755 118.833 36.9838 118.741 36.8005C118.649 36.663 118.604 36.4339 118.604 36.2047C118.604 35.8381 118.741 35.5631 119.062 35.3798C119.337 35.1965 119.795 35.059 120.391 35.059L121.032 35.0132V34.7841C121.032 34.4633 120.987 34.2341 120.803 34.0966C120.666 33.9592 120.482 33.9133 120.208 33.9133C120.024 33.9133 119.841 33.9591 119.658 34.005C119.474 34.0508 119.291 34.1425 119.153 34.1883L118.97 33.7758C119.153 33.6842 119.337 33.6383 119.566 33.5467C119.795 33.5925 120.024 33.5467 120.253 33.5467ZM121.032 35.4715L120.482 35.5173C120.024 35.5173 119.703 35.609 119.52 35.7465C119.337 35.8839 119.245 36.0673 119.245 36.2964C119.245 36.5255 119.291 36.663 119.428 36.7547C119.566 36.8463 119.749 36.8922 119.933 36.8922C120.253 36.8922 120.528 36.8005 120.757 36.6172C120.987 36.4339 121.078 36.1589 121.078 35.7923V35.4715H121.032Z"
      fill="white"
    />
    <path
      d="M124.47 33.5467C124.928 33.5467 125.249 33.6384 125.478 33.8675C125.707 34.0966 125.799 34.4174 125.799 34.8757V37.2588H125.295V34.9215C125.295 34.6007 125.203 34.3716 125.066 34.2341C124.928 34.0966 124.699 34.005 124.424 34.005C124.012 34.005 123.737 34.1425 123.554 34.3716C123.37 34.6007 123.324 34.9215 123.324 35.3798V37.3046H122.774V33.6384H123.233L123.324 34.1883H123.37C123.462 34.0508 123.553 33.9592 123.691 33.8675C123.828 33.7758 123.966 33.73 124.103 33.6842C124.149 33.5925 124.333 33.5467 124.47 33.5467Z"
      fill="white"
    />
    <path
      d="M126.394 33.6382H126.989L127.814 35.7921C127.86 35.9296 127.906 36.0213 127.952 36.1588C127.998 36.2504 128.043 36.3879 128.043 36.4795C128.043 36.5712 128.089 36.7087 128.135 36.8004H128.181C128.227 36.7087 128.273 36.5254 128.318 36.3421C128.364 36.1588 128.456 35.9754 128.502 35.7921L129.281 33.684H129.877L128.273 37.9002C128.181 38.1294 128.089 38.3127 127.952 38.496C127.86 38.6793 127.723 38.771 127.539 38.8626C127.356 38.9543 127.173 39.0001 126.944 39.0001C126.852 39.0001 126.76 39.0001 126.669 39.0001C126.577 39.0001 126.531 38.9543 126.439 38.9543V38.496C126.485 38.496 126.531 38.496 126.623 38.5418C126.714 38.5418 126.76 38.5418 126.852 38.5418C126.989 38.5418 127.127 38.496 127.218 38.4502C127.31 38.4043 127.402 38.3127 127.493 38.221C127.585 38.1294 127.631 37.9919 127.677 37.8544L127.86 37.3503L126.394 33.6382Z"
      fill="white"
    />
  </svg>
);
